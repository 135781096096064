import { createEffect, createEvent, createStore } from 'effector';
import { isEqual } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Types

type AppReportsParams = Partial<Paths.Reports.Get.QueryParameters>;

// Constants

const defaultAppReportsParams: Paths.Reports.Get.QueryParameters = {
    pageIndex: 0,
    limit: 20,
    count: true
};

// Events

const addFilters = createEvent<AppReportsParams>();
const setOverwriteFilters = createEvent<AppReportsParams>();

const resetAppReports = createEvent();
const resetAppFilters = createEvent();

// Effects

const getAppReportsFx = createEffect({
    handler: async (params?: AppReportsParams) => {
        try {
            const { isSuccess, data } = await API.reports.getReports({
                ...defaultAppReportsParams,
                ...params
            });

            if (isSuccess && data) {
                return data;
            } else {
                throw new Error();
            }
        } catch (error) {
            notify('There was a problem receiving reports, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $appReportsResponse = createStore<YEAY.QueryReportsResponse>({})
    .on(getAppReportsFx.doneData, (state, reports) => {
        if (!reports) return;

        return {
            ...(state || {}),
            ...reports,
            items: reports.pageIndex === 0 ? reports.items : [...(state?.items || []), ...(reports.items || [])]
        };
    })
    .reset(resetAppReports);

const $appReportsFilters = createStore<AppReportsParams>(defaultAppReportsParams)
    .on(addFilters, (state, reports) => ({
        ...(state || defaultAppReportsParams),
        ...reports
    }))
    .on(setOverwriteFilters, (state, reports) => ({
        ...(state || defaultAppReportsParams),
        ...reports,
        pageIndex: 0
    }))
    .reset(resetAppFilters);

const $hasError = createStore<boolean>(false)
    .on(getAppReportsFx.fail, () => true)
    .reset(getAppReportsFx.done);

const $resetIsAvailable = $appReportsFilters.map(filters => !isEqual(filters, defaultAppReportsParams));

// Exports

export const appReportsEvents = {
    resetAppReports,
    addFilters,
    setOverwriteFilters,
    resetAppFilters
};

export const appReportsEffects = {
    getAppReportsFx
};

export const appReportsStores = {
    $appReportsResponse,
    $hasError,
    $appReportsFilters,
    $resetIsAvailable
};
