import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Tabs } from 'v2/components/ui/Tabs';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { CollaborationSingleTab } from 'v2/constants/services/collaboration';
import { collaborationEffects, collaborationEvents, collaborationStores } from 'v2/stores/collaboration';
import { Id } from 'v2/types/data';
import { CollaborationInfo } from './CollaborationInfo';
import { Engagements } from './Engagements';
import { Enrollments } from './Enrollments';
import { Requests } from './Requests';
import { Shippings } from './Shippings';
import { Submissions } from './Submissions';
import { ContentWrapper } from './styles';

const { getCollaborationFx } = collaborationEffects;

export const Collaboration = () => {
    const { id } = useParams<Partial<Id>>();
    const [activeTab, setActiveTab] = useState(CollaborationSingleTab.Info);
    const isLoading = useStore(getCollaborationFx.pending);

    const { data } = useStore(collaborationStores.$collaborationInfo);
    const shippingDetails = data?.shippingDetails;

    const handleTabsChange = (value: CollaborationSingleTab) => {
        setActiveTab(value);
    };

    useEffect(() => {
        if (id) {
            getCollaborationFx(id);
        }

        return () => {
            collaborationEvents.resetStore();
        };
    }, [id]);

    if (!id) {
        return null;
    }

    return (
        <MainLayout isHideHeader>
            <DetailsPageLayout>
                {isLoading ? (
                    <Section justifyCenter marginTop="24px">
                        <Spinner />
                    </Section>
                ) : (
                    <Tabs
                        activeTab={activeTab}
                        tabs={[
                            {
                                id: CollaborationSingleTab.Info,
                                title: 'COLLABORATION INFO',
                                content: (
                                    <ContentWrapper>
                                        <CollaborationInfo id={id} />
                                    </ContentWrapper>
                                )
                            },
                            {
                                id: CollaborationSingleTab.Requests,
                                title: 'REQUESTS',
                                content: (
                                    <ContentWrapper>
                                        <Requests id={id} />
                                    </ContentWrapper>
                                )
                            },
                            {
                                id: CollaborationSingleTab.ProductShipping,
                                title: 'PRODUCT SHIPPING',
                                hidden: !shippingDetails,
                                content: (
                                    <ContentWrapper>
                                        <Shippings id={id} />
                                    </ContentWrapper>
                                )
                            },
                            {
                                id: CollaborationSingleTab.Enrollments,
                                title: 'ENROLLMENTS',
                                content: (
                                    <ContentWrapper>
                                        <Enrollments />
                                    </ContentWrapper>
                                )
                            },
                            {
                                id: CollaborationSingleTab.Submissions,
                                title: 'SUBMISSIONS',
                                content: (
                                    <ContentWrapper>
                                        <Submissions id={id} />
                                    </ContentWrapper>
                                )
                            },
                            {
                                id: CollaborationSingleTab.Engagement,
                                title: 'ENGAGEMENT',
                                content: (
                                    <ContentWrapper>
                                        <Engagements id={id} />
                                    </ContentWrapper>
                                )
                            }
                        ]}
                        onChange={handleTabsChange}
                    />
                )}
            </DetailsPageLayout>
        </MainLayout>
    );
};
