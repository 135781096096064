import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { defaultVideoEngagementsQueryParams } from 'v2/constants/services/video-engagement';
import { API } from 'v2/services/yasy';

// Types

export type EngagementParams = Partial<YEAY.QueryVideoStatisticsRequest>;

// Events

const updateParams = createEvent<EngagementParams>();
const resetEngagement = createEvent();
const resetParams = createEvent();

// Effects

const getVideoEngagementFx = createEffect({
    handler: async (data: EngagementParams) => {
        try {
            const source = await API.video.getVideoEngagement({
                ...data,
                pageIndex: data.pageIndex || defaultVideoEngagementsQueryParams.pageIndex,
                limit: data.limit || defaultVideoEngagementsQueryParams.limit,
                returnQueryCount: defaultVideoEngagementsQueryParams.returnQueryCount,
                utcEnd: defaultVideoEngagementsQueryParams.utcEnd
            });

            if (!source) {
                throw new Error();
            }

            return source;
        } catch (e) {
            notify('There was a problem receiving engagement, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $videoEngagement = createStore<YEAY.QueryVideoStatisticsResponse | null>(null)
    .on(getVideoEngagementFx.doneData, (_, state) => state)

    .reset(resetEngagement);

const $engagementParams = createStore<EngagementParams>(defaultVideoEngagementsQueryParams)
    .on(updateParams, (state, params) => ({
        ...defaultVideoEngagementsQueryParams,
        ...state,
        ...params,
        pageIndex: params.pageIndex || defaultVideoEngagementsQueryParams.pageIndex
    }))
    .reset(resetParams);

// Exports

export const videoEngagementEvents = {
    resetEngagement,
    updateParams,
    resetParams
};

export const videoEngagementEffects = {
    getVideoEngagementFx
};

export const videoEngagementStores = {
    $videoEngagement,
    $engagementParams
};
