import React, { FC, useEffect } from 'react';
import { CollaborationInfo } from 'v2/pages/Collaboration/CollaborationInfo';
import { collaborationEffects } from 'v2/stores/collaboration';

interface Props {
    collaborationId?: string;
}

export const CollaborationTab: FC<Props> = ({ collaborationId }) => {
    useEffect(() => {
        collaborationId && collaborationEffects.getCollaborationFx(collaborationId);
    }, [collaborationId]);

    return <CollaborationInfo hideEngagements id={collaborationId || ''} />;
};
