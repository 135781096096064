export const columns = [
    {
        title: 'USERNAME',
        dataIndex: 'username',
        key: 'username',
        width: '20%'
    },
    {
        title: 'VIEWS',
        dataIndex: 'views',
        key: 'views',
        width: '20%'
    },
    {
        title: 'CLICKS',
        dataIndex: 'clicks',
        key: 'clicks',
        width: '20%'
    },
    {
        title: 'SHARES',
        dataIndex: 'shares',
        key: 'shares',
        width: '20%'
    },
    {
        title: 'JOINED',
        dataIndex: 'joined',
        key: 'joined',
        width: '20%'
    }
];
