import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOption, SelectOptionValue } from 'v2/components/ui/selects/types';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { externalNetworksEffects, externalNetworksStores } from 'v2/stores/external-networks';

export const AccountsFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { externalNetworkIds } = useStore($usersFilters);
    const externalNetworks = useStore(externalNetworksStores.$externalNetworks);

    const socialOptions: SelectOption[] = externalNetworks
        .filter(network => network.title?.toLowerCase() !== ExternalNetworkKey.Yeay)
        .map(network => ({
            label: network.title || '',
            value: network.id
        }));

    const handleChange = (networkIds: SelectOptionValue[]) => {
        addFilters({ externalNetworkIds: networkIds as string[] });
    };

    useEffect(() => {
        externalNetworksEffects.getExternalNetworksFx();
    }, []);

    return (
        <MultiSelect
            wide
            options={socialOptions}
            placement="bottom-start"
            title="Social Accounts"
            values={externalNetworkIds || undefined}
            onChange={handleChange}
        />
    );
};
