import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { useUserRolesOptions } from './hooks';

export const UserRoleFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { role } = useStore($usersFilters);

    const options = useUserRolesOptions();

    const handleChange = (option: SelectOption) => {
        addFilters({ role: option.value?.toString() });
    };

    return (
        <Select
            wide
            options={options}
            placement="bottom-start"
            title="Role"
            value={role || undefined}
            onChange={handleChange}
        />
    );
};
