import React, { FC } from 'react';
import { SadIcon, SmileIcon } from 'v2/assets/icons';
import { CurationVariant } from 'v2/components/modals/CurateVideoModal';
import { RadioButton } from 'v2/components/ui/buttons/RadioButton';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { VideoCurationReason, videoCurationReasons } from 'v2/constants/services/videos';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    variant: CurationVariant | null;
    onVariantChange: (variant: CurationVariant | null) => void;
    rejectReason: VideoCurationReason;
    onReasonChange: (reason: VideoCurationReason) => void;
    isAgreed: boolean;
    onIsAgreedChange: (value: boolean) => void;
}

export const CurateVideoForm: FC<Props> = ({
    variant,
    rejectReason,
    isAgreed,
    onVariantChange,
    onReasonChange,
    onIsAgreedChange
}) => {
    const handleVariantChange = (variant: CurationVariant) => () => {
        onVariantChange(variant);

        if (variant === CurationVariant.Accept && rejectReason !== VideoCurationReason.None) {
            onReasonChange(VideoCurationReason.None);
        }
    };

    const handleRejectReasonChange = (reason: VideoCurationReason) => () => {
        onReasonChange(reason);
    };

    return (
        <Column noWrap width="100%">
            <Section paddingTop="8px">
                <RepeatGrid columns={2} gap="16px" width="100%">
                    <RadioButton
                        wide
                        checked={variant === CurationVariant.Accept}
                        icon={<SmileIcon color={brandColors.black} />}
                        onClick={handleVariantChange(CurationVariant.Accept)}
                    >
                        Accept
                    </RadioButton>
                    <RadioButton
                        wide
                        checked={variant === CurationVariant.Reject}
                        colorType="red"
                        icon={<SadIcon color={brandColors.black} />}
                        onClick={handleVariantChange(CurationVariant.Reject)}
                    >
                        Reject
                    </RadioButton>
                </RepeatGrid>
            </Section>

            {variant === CurationVariant.Reject && (
                <Column noWrap marginTop="16px" width="100%">
                    <Text size="-1">Please provide a reason for rejecting the video.</Text>
                    <Column noWrap width="100%">
                        {videoCurationReasons.map(item => (
                            <Section key={item.value} marginTop="8px">
                                <RadioButton
                                    wide
                                    checked={item.value === rejectReason}
                                    colorType="red"
                                    onClick={handleRejectReasonChange(item.value)}
                                >
                                    {item.title}
                                </RadioButton>
                            </Section>
                        ))}
                    </Column>
                </Column>
            )}

            <Section marginTop="16px">
                <Checkbox checked={isAgreed} textSize="-2" onChange={onIsAgreedChange}>
                    Are you sure you want to curate this video?
                </Checkbox>
            </Section>
        </Column>
    );
};
