import styled from 'styled-components';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';

export const ButtonsWrapper = styled(Section)`
    padding: 8px 16px;
    border-top: 1px solid ${grey[400]};
`;

export const ContentWrapper = styled(Column)`
    padding: 20px 24px;
`;
