import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { LinkUserInfo } from './styles';

export const prepareDataForTable = (items: YEAY.GetReferralDetailedResponse[]) =>
    items.map(item => ({
        key: item.userId,
        user: (
            <LinkUserInfo to={`${routePaths.users}/${item.userId}`}>
                <Row alignCenter noWrap>
                    {item.profileImageUrl && (
                        <Row marginRight="8px">
                            <Avatar size="32px" src={item.profileImageUrl} />
                        </Row>
                    )}

                    <Text isSemibold size="-2">
                        @{item.username}
                    </Text>
                </Row>
            </LinkUserInfo>
        ),
        downloadDate: item.utcDownloaded ? <DateProperty alignCenter date={item.utcDownloaded} /> : '-',
        registrationDate: item.utcSignedUp ? <DateProperty alignCenter date={item.utcSignedUp} /> : '-'
    }));
