import React, { FC } from 'react';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Hashtag } from 'v2/components/ui/Hashtag';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ContentLayout } from 'v2/pages/Collaboration/DropdownMoreDetails/ContentLayout';
import { Tag } from 'v2/pages/Collaboration/DropdownMoreDetails/Tag';
import { ContentWrapper, DescriptionText, ElementWrapper, Point, TextWrapper } from './styles';

interface Props {
    data: YECM.CollaborationDetailedResponse;
}

export const DropdownMoreDetails: FC<Props> = ({ data }) => {
    const { shippingDetails, description, keywords, brandRecommendations, networks } = data;
    return (
        <AccordionSection title="More Details">
            <ContentWrapper>
                {shippingDetails?.description && (
                    <ContentLayout borderTop title="📦 Collaboration with product shipping">
                        <Text size="-1">{shippingDetails.description}</Text>
                    </ContentLayout>
                )}

                {description && (
                    <ContentLayout borderBottom borderTop title="💡 Description">
                        <DescriptionText dangerouslySetInnerHTML={{ __html: description || '-' }} size="-1" />
                    </ContentLayout>
                )}

                {keywords && keywords.length > 0 && (
                    <ContentLayout title="🗣️ Messaging">
                        {keywords.map(item => (
                            <TextWrapper key={item} alignCenter>
                                <Point />
                                <Text size="-1">{item}</Text>
                            </TextWrapper>
                        ))}
                    </ContentLayout>
                )}

                {brandRecommendations?.shouldDo && brandRecommendations.shouldDo?.length > 0 && (
                    <ContentLayout borderBottom borderTop title="👍  Do’s">
                        {brandRecommendations.shouldDo.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TextWrapper key={index} alignCenter>
                                <Point />
                                <Text size="-1">{item}</Text>
                            </TextWrapper>
                        ))}
                    </ContentLayout>
                )}

                {brandRecommendations?.shouldNotDo && brandRecommendations.shouldNotDo?.length > 0 && (
                    <ContentLayout title="👎  Don’ts">
                        {brandRecommendations.shouldNotDo.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TextWrapper key={index} alignCenter>
                                <Point />
                                <Text size="-1">{item}</Text>
                            </TextWrapper>
                        ))}
                    </ContentLayout>
                )}

                {networks && networks.length > 0 && (
                    <ContentLayout borderBottom borderTop title="Tags">
                        <Section>
                            {networks
                                .filter(item => item.networkTitle?.toLowerCase() !== 'yeay')
                                .map(({ networkId, networkTitle, requiredMentions }, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <ElementWrapper key={index}>
                                        <Tag
                                            id={networkId || ''}
                                            link={'/'}
                                            text={requiredMentions?.[0] || ''}
                                            title={networkTitle || ''}
                                        />
                                    </ElementWrapper>
                                ))}
                        </Section>
                    </ContentLayout>
                )}

                {brandRecommendations?.requiredHashtags && brandRecommendations.requiredHashtags?.length > 0 && (
                    <ContentLayout title="Hashtags">
                        <Section>
                            {brandRecommendations.requiredHashtags.map(item => (
                                <ElementWrapper key={item}>
                                    <Hashtag id={item} text={item} />
                                </ElementWrapper>
                            ))}
                        </Section>
                    </ContentLayout>
                )}

                {brandRecommendations?.requiredEmojis && brandRecommendations.requiredEmojis?.length > 0 && (
                    <ContentLayout borderTop title="Emojis">
                        <Section>
                            {brandRecommendations.requiredEmojis.map(item => (
                                <ElementWrapper key={item}>
                                    <Hashtag id={item} text={item} />
                                </ElementWrapper>
                            ))}
                        </Section>
                    </ContentLayout>
                )}
            </ContentWrapper>
        </AccordionSection>
    );
};
