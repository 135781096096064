import React, { FC } from 'react';
import { TextColorsType, backgroundColors, textColors } from 'v1/components/common/buttons/CardButton/constants';
import { noop } from 'v1/constants/functions';
import { grey11, grey7 } from 'v1/constants/styles/colors';
import {
    cardButtonHeight,
    cardButtonMarginBottom,
    cardButtonMinWidth,
    cardButtonTextFontSize
} from 'v1/constants/styles/sizes';
import { Disabled } from 'v1/types/form';
import { ReactClick } from 'v1/types/react';
import { Blocked, Margin, Sizes } from 'v1/types/styles';
import { Spinner } from 'v2/components/loaders/Spinner';
import { CardButtonStyled } from './styles';

export interface UserCardButtonProps extends Disabled, ReactClick<HTMLButtonElement>, Margin, Sizes, Blocked {
    children: string;
    type?: TextColorsType;
    fontSize?: string;
    padding?: string;
    isLoading?: boolean;
}

export const CardButton: FC<UserCardButtonProps> = ({
    children,
    isLoading = false,
    type = 'primary',
    disabled,
    width,
    fontSize,
    padding,
    onClick,
    ...props
}) => (
    <CardButtonStyled
        background={disabled ? grey11 : backgroundColors[type]}
        color={disabled ? grey7 : textColors[type]}
        disabled={disabled}
        fontSize={fontSize || cardButtonTextFontSize}
        height={cardButtonHeight}
        marginBottom={cardButtonMarginBottom}
        minWidth={width || cardButtonMinWidth}
        padding={padding}
        width={width}
        onClick={disabled ? noop : onClick}
        {...props}
    >
        {isLoading ? <Spinner /> : children}
    </CardButtonStyled>
);
