import styled from 'styled-components';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter, iconWrapperMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';
import { OptionsItemProps, SelectStylesProps } from '../types';

export const ArrowWrapper = styled.div<SelectStylesProps>`
    ${iconWrapperMixin};
    ${({ isOpened }) => isOpened && 'transform: rotate(180deg)'};
    transition: transform ${transitionTime} linear;
`;

export const OptionsItem = styled.button<OptionsItemProps>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 4px 8px;
    text-align: left;
    cursor: pointer;

    &:hover:not(:disabled) {
        background-color: ${grey[100]};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const CountWrapper = styled(Row)`
    ${flexCenter};
    margin-left: 4px;
    padding: 3px 5px;
    border-radius: 20px;
    background: ${grey[200]};
`;
