import { forward, sample } from 'effector';
import {
    ParticipationEndedReasonKey,
    ParticipationStatesKey,
    PublishingStateKey,
    SubmissionStatesKey
} from 'v2/constants/services/enrollments';
import {
    collaborationEnrollmentsEffects,
    collaborationEnrollmentsEvents,
    collaborationEnrollmentsStores
} from 'v2/stores/collaboration/enrollments';
import { loaderEffects } from 'v2/stores/loader';

const { setEnrollmentItems } = collaborationEnrollmentsEvents;
const { removeEnrollmentFx } = collaborationEnrollmentsEffects;
const { $enrollmentsResponse } = collaborationEnrollmentsStores;

forward({
    from: removeEnrollmentFx.pending,
    to: loaderEffects.loaderFx
});

sample({
    clock: removeEnrollmentFx.doneData,
    source: $enrollmentsResponse,
    fn: (data, params) => {
        if (data && params) {
            return (data?.items || []).map(i => {
                if (i.participantId === params.userId) {
                    return {
                        ...i,
                        acceptedSubmissionDeadlineUtc: '',
                        participationState: ParticipationStatesKey.Ended,
                        participationEndedReason: ParticipationEndedReasonKey.KickedOut,
                        publishingState: PublishingStateKey.None,
                        submissionState: SubmissionStatesKey.None
                    };
                }

                return i;
            });
        }

        return data?.items || [];
    },
    target: setEnrollmentItems
});
