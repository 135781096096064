import React from 'react';
import { ReportStatusTableTitle } from 'v2/pages/Reports/tabs/components/ReportStatusTableTile';

export const columns = [
    {
        title: 'REPORT TYPE',
        dataIndex: 'type',
        key: 'type',
        width: '15%'
    },
    {
        title: 'REPORT ID',
        dataIndex: 'reportId',
        key: 'reportId',
        width: '15%'
    },
    {
        title: 'ASSET ID',
        dataIndex: 'assetId',
        key: 'assetId',
        width: '15%'
    },
    {
        title: 'COLLAB NAME',
        dataIndex: 'collabName',
        key: 'collabName',
        width: '15%'
    },
    {
        title: 'DATE FIRST REPORTED',
        dataIndex: 'date',
        key: 'date',
        width: '20%'
    },
    {
        title: <ReportStatusTableTitle />,
        dataIndex: 'status',
        key: 'status',
        width: '20%'
    }
];
