import styled from 'styled-components';
import { white } from 'v1/constants/styles/colors';
import { flexEnd } from 'v1/constants/styles/mixins';
import { lg_1, padding, paginationHeight, sideBarWidth, smallSideBarWidth, xs } from 'v1/constants/styles/sizes';

export const Wrapper = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${paginationHeight};
    background-color: ${white};
    ${flexEnd};
    flex-direction: row;
    z-index: 9;
    padding: calc(2 * ${padding});
    padding-left: calc(2 * ${padding} + ${sideBarWidth});

    @media (max-width: ${lg_1}) {
        padding-left: calc(2 * ${padding} + ${smallSideBarWidth});
    }

    @media (max-width: ${xs}) {
        padding: 20px 0;
        height: auto;
    }
`;
