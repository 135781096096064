import { useStore } from 'effector-react';
import * as Popper from 'popper.js';
import React, { FC, useEffect, useState } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';

interface Props extends VideosFilterProps {
    placement?: Popper.Placement;
}

export const DateFilter: FC<Props> = ({ domain, placement }) => {
    const { $videosFilters } = domain.stores;
    const { setOverwriteFilters } = domain.events;

    const videosFilters = useStore($videosFilters);
    const [dateRange, setDateRange] = useState<DatePickerType>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange as Date[];

    useEffect(() => {
        if (videosFilters?.fromCreatedDateTime && videosFilters?.toCreatedDateTime) {
            setDateRange([new Date(videosFilters?.fromCreatedDateTime), new Date(videosFilters?.toCreatedDateTime)]);
        } else {
            setDateRange([new Date(), new Date()]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videosFilters?.fromCreatedDateTime, videosFilters?.toCreatedDateTime]);

    const onChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            setOverwriteFilters({
                fromCreatedDateTime: start.toISOString(),
                toCreatedDateTime: end.toISOString()
            });
        }
    };

    return (
        <DatePicker
            endDate={endDate}
            endPlaceholder=" 2020-12-26"
            placement={placement}
            startDate={startDate}
            startPlaceholder="2020-12-25 -"
            title="Dates"
            onChange={onChangeDate}
        />
    );
};
