import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { ClockIcon, CloseIcon, DownloadIcon } from 'v2/assets/icons';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Avatar } from 'v2/components/ui/Avatar';
import { Video } from 'v2/components/ui/Video';
import { Button } from 'v2/components/ui/buttons/Button';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { AbsoluteWrapper } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { ModalBackground } from 'v2/components/wrappers/ModalWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { VideoSourceState } from 'v2/constants/services/video';
import { brandColors } from 'v2/constants/styles/colors';
import { useBodyScroll } from 'v2/hooks/use-body-scroll';
import { authStores } from 'v2/stores/auth';
import { videoModal } from 'v2/stores/initialize-modals';
import { singleVideoEffects, singleVideoStores } from 'v2/stores/single-video';
import { videoModalEffects, videoModalEvents, videoModalStores } from 'v2/stores/videos/video-modal';
import { getFormattedDate } from 'v2/utils/datetime';
import { EmptyWrapper, RightContentWrapper, VideoWrapper, Wrapper } from './styles';

const { $modal, $state, closeModal } = videoModal;

interface Props {
    videoId: string;
}

const VideoModalContent: FC<Props> = ({ videoId }) => {
    const isAdmin = useStore(authStores.$isAdmin);

    const videoSource = useStore(singleVideoStores.$videoSource);
    const videoSourceIsLoading = useStore(singleVideoEffects.getVideoSourceFx.pending);

    const video = useStore(videoModalStores.$video);
    const videoIsLoading = useStore(videoModalEffects.getVideoByIdFx.pending);

    useEffect(() => {
        if (videoId) {
            videoModalEffects.getVideoByIdFx(videoId);
            singleVideoEffects.getVideoSourceFx(videoId);
        }

        return () => videoModalEvents.resetVideo();
    }, [videoId]);

    return (
        <Section alignCenter justifyCenter noWrap height="100%">
            {videoIsLoading ? (
                <Spinner />
            ) : (
                <>
                    {video ? (
                        <RelativeWrapper height="auto" width="auto">
                            <VideoWrapper>
                                <Video
                                    cover
                                    isFullscreen
                                    posterSrc={video.streaming?.details?.screenGrabUrl}
                                    videoSrc={video.streaming?.details?.hlsUrl}
                                />
                            </VideoWrapper>

                            <RightContentWrapper>
                                <Section>
                                    <Button
                                        wide
                                        align="start"
                                        buttonType="text-black"
                                        href={`${routePaths.users}/${video.ownerId}`}
                                        icon={<Avatar size="32px" src={video.profileImageUrl || undefined} />}
                                        iconSize="32px"
                                    >
                                        @{video.username}
                                    </Button>
                                </Section>

                                {videoSourceIsLoading ? (
                                    <Section justifyCenter marginTop="8px">
                                        <Spinner />
                                    </Section>
                                ) : (
                                    isAdmin &&
                                    video?.isPublic &&
                                    videoSource?.status === VideoSourceState.Processed &&
                                    videoSource?.url && (
                                        <Section marginTop="8px">
                                            <Button
                                                isExternal
                                                wide
                                                align="start"
                                                buttonType="text-black"
                                                href={videoSource.url}
                                                icon={<DownloadIcon height={12} width={12} />}
                                            >
                                                Download
                                            </Button>
                                        </Section>
                                    )
                                )}

                                {video.utcUploaded && (
                                    <Section noWrap marginTop="8px" padding="8px">
                                        <IconWrapper color={brandColors.black} size="16px">
                                            <ClockIcon />
                                        </IconWrapper>

                                        <Column marginLeft="8px">
                                            <EllipsisText size="-2">
                                                {getFormattedDate(video.utcUploaded, 'yyyy-MM-dd HH:mm:ss')} UTC
                                            </EllipsisText>
                                        </Column>
                                    </Section>
                                )}
                            </RightContentWrapper>
                        </RelativeWrapper>
                    ) : (
                        <EmptyWrapper>
                            <Empty title="No video" />
                        </EmptyWrapper>
                    )}
                </>
            )}
        </Section>
    );
};

export const VideoModal = () => {
    const { visible } = useStore($modal);
    const videoId = useStore($state);

    useBodyScroll(visible);

    const close = () => closeModal();

    if (!visible) {
        return null;
    }

    return (
        <>
            <ModalBackground zIndex={2000} />

            <Wrapper>
                <AbsoluteWrapper right="32px" top="32px" zIndex="1">
                    <IconButton
                        rounded
                        bgColor={brandColors.white}
                        bgHoverColor={brandColors.green}
                        color={brandColors.black}
                        size="40px"
                        onClick={close}
                    >
                        <CloseIcon height={13} width={13} />
                    </IconButton>
                </AbsoluteWrapper>

                <VideoModalContent videoId={videoId} />
            </Wrapper>
        </>
    );
};
