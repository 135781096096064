import EnglishFlagIcon from 'v2/assets/images/flags/english-flag.png';
import PortugueseFlagIcon from 'v2/assets/images/flags/portuguese-flag.png';
import SpanishFlagIcon from 'v2/assets/images/flags/spanish-flag.png';
import { LanguageType } from 'v2/constants/languages';

export const getLanguageImage = (language: string) => {
    switch (language.toLocaleLowerCase()) {
        case LanguageType.English:
            return EnglishFlagIcon;
        case LanguageType.Spain:
            return SpanishFlagIcon;
        case LanguageType.Portugal:
            return PortugueseFlagIcon;
        default:
            return '';
    }
};
