export enum NetworksType {
    None = 0,
    InstagramPost = 1,
    InstagramReel = 2,
    InstagramStory = 3,
    TwitterTweet = 4,
    YouTubeShorts = 5,
    YouTubeVideo = 6,
    TikTokVideo = 7,
    YeayVideo = 8,
    Twitch = 9
}

export const networksType: Record<NetworksType, string> = {
    [NetworksType.None]: 'None',
    [NetworksType.InstagramPost]: 'InstagramPost',
    [NetworksType.InstagramReel]: 'InstagramReel',
    [NetworksType.InstagramStory]: 'InstagramStory',
    [NetworksType.TwitterTweet]: 'TwitterTweet',
    [NetworksType.YouTubeShorts]: 'YouTubeShorts',
    [NetworksType.YouTubeVideo]: 'YouTubeVideo',
    [NetworksType.TikTokVideo]: 'TikTokVideo',
    [NetworksType.YeayVideo]: 'YeayVideo',
    [NetworksType.Twitch]: 'Twitch'
};

export const networksShortType: Record<NetworksType, string> = {
    [NetworksType.None]: 'None',
    [NetworksType.InstagramPost]: 'Post',
    [NetworksType.InstagramReel]: 'Reels',
    [NetworksType.InstagramStory]: 'Story',
    [NetworksType.TwitterTweet]: 'Twitter',
    [NetworksType.YouTubeShorts]: 'Shorts',
    [NetworksType.YouTubeVideo]: 'YouTube',
    [NetworksType.TikTokVideo]: 'TikTok',
    [NetworksType.YeayVideo]: 'YEAY',
    [NetworksType.Twitch]: 'Twitch'
};

// External networks keys
export enum ExternalNetworkKey {
    Yeay = 'yeay',
    Tiktok = 'tiktok',
    Instagram = 'instagram',
    Twitter = 'twitter',
    Youtube = 'youtube',
    Snapchat = 'snapchat',
    Twitch = 'twitch'
}
