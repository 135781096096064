import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { media } from 'v2/constants/styles/media';
import { flexCenter } from 'v2/constants/styles/mixins';

export const ContentWrapper = styled(Section)`
    padding: 16px;

    ${media.laptopSmall(`
        padding: 16px 32px 0 16px;
    `)};
`;

export const ButtonWrapper = styled(Section)`
    ${flexCenter};
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: ${brandColors.white};
    margin-top: 16px;
`;

export const SectionButton = styled(Section)`
    background-color: ${grey[200]};
`;

export const Wrapper = styled(Section)`
    padding: 16px 32px 0 16px;
`;
