import React from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import {
    ParticipationEndedReasonKey,
    ParticipationStatesKey,
    PublishingEndedReason,
    PublishingStateKey,
    SubmissionEndedReasonKey,
    SubmissionStatesKey
} from 'v2/constants/services/enrollments';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { brandColors, green, grey } from 'v2/constants/styles/colors';
import { StatusBadge } from 'v2/pages/Collaboration/Enrollments/StatusBadge';
import { StatusIcon } from 'v2/pages/Collaboration/Enrollments/StatusIcon';

// Participation states

const participationEndedReasons = (
    reason: YECM.ParticipationEndedReason = ParticipationEndedReasonKey.None,
    hasSharing = false
) => {
    switch (reason) {
        case ParticipationEndedReasonKey.SubmittedAndPublished:
            return (
                <Text isSemibold color={green[700]} size="-2">
                    Completed
                </Text>
            );
        case ParticipationEndedReasonKey.SubmittedOnly:
            return (
                <Text isSemibold color={hasSharing ? grey[700] : green[700]} size="-2">
                    {hasSharing ? 'Published' : 'Completed'}
                </Text>
            );
        case ParticipationEndedReasonKey.SubmissionFailed:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Failed
                </Text>
            );
        case ParticipationEndedReasonKey.DeadlineMissed:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Ended
                </Text>
            );
        case ParticipationEndedReasonKey.SubmissionReported:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Reported
                </Text>
            );
        case ParticipationEndedReasonKey.Leave:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Left
                </Text>
            );
        case ParticipationEndedReasonKey.KickedOut:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Kicked out
                </Text>
            );
        case ParticipationEndedReasonKey.ParticipationRequestRejected:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Request rejected
                </Text>
            );
        case ParticipationEndedReasonKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

const participationSubmissionEndedReason = (
    reason: YECM.SubmissionEndedReason = SubmissionEndedReasonKey.None,
    hasSharing = false
) => {
    switch (reason) {
        case SubmissionEndedReasonKey.Success:
            return (
                <Text isSemibold color={hasSharing ? grey[700] : green[700]} size="-2">
                    {hasSharing ? 'Published' : 'Completed'}
                </Text>
            );
        case SubmissionEndedReasonKey.CurationFailed:
        case SubmissionEndedReasonKey.Reported:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Reported
                </Text>
            );
        case SubmissionEndedReasonKey.DeadlineMissed:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Ended
                </Text>
            );
        case SubmissionEndedReasonKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

const participationSubmissionStatus = (
    state: YECM.SubmissionState = SubmissionStatesKey.None,
    endedReason: YECM.SubmissionEndedReason = SubmissionEndedReasonKey.None,
    hasSharing = false
) => {
    switch (state) {
        case SubmissionStatesKey.Pending:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Enrolled
                </Text>
            );
        case SubmissionStatesKey.Curating:
            return (
                <Text isSemibold color={brandColors.red} size="-2">
                    Review
                </Text>
            );
        case SubmissionStatesKey.Ended:
            return participationSubmissionEndedReason(endedReason, hasSharing);
        case SubmissionStatesKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

export const getParticipationStatus = (
    {
        participationState,
        participationEndedReason,
        submissionState,
        submissionEndedReason
    }: YECM.ParticipationResponse,
    hasSharing = false
) => {
    switch (participationState) {
        case ParticipationStatesKey.Enrolled:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Enrolled
                </Text>
            );
        case ParticipationStatesKey.EnrollmentRequested:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Requested
                </Text>
            );
        case ParticipationStatesKey.Submitted:
            return participationSubmissionStatus(submissionState, submissionEndedReason, hasSharing);
        case ParticipationStatesKey.Published:
            return (
                <Text isSemibold color={grey[700]} size="-2">
                    Published
                </Text>
            );
        case ParticipationStatesKey.Ended:
            return participationEndedReasons(participationEndedReason, hasSharing);
        case ParticipationStatesKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

// Submission states

const submissionEndedReasons = (
    reason: YECM.SubmissionEndedReason = SubmissionEndedReasonKey.None,
    userId?: string,
    mediaAssets?: YECM.MediaAssetResponse[] | null,
    isVideo?: boolean
) => {
    switch (reason) {
        case SubmissionEndedReasonKey.Success:
            return <StatusBadge isVideo={isVideo} mediaAssets={mediaAssets} userId={userId} />;
        case SubmissionEndedReasonKey.CurationFailed:
        case SubmissionEndedReasonKey.Reported:
            return <StatusIcon state="reported" />;
        case SubmissionEndedReasonKey.DeadlineMissed:
            return <StatusIcon state="ended" />;
        case SubmissionEndedReasonKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

export const getSubmissionStatus = (
    isVideo: boolean,
    { submissionState, submissionEndedReason, participantId, mediaAssets }: YECM.ParticipationResponse
) => {
    switch (submissionState) {
        case SubmissionStatesKey.Pending:
            return <StatusIcon state="pending" />;
        case SubmissionStatesKey.Curating:
            return <StatusBadge isReview isVideo mediaAssets={mediaAssets} />;
        case SubmissionStatesKey.Ended:
            return submissionEndedReasons(submissionEndedReason, participantId, mediaAssets, isVideo);
        case SubmissionStatesKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

// Share states

const publishingEndedReasons = (
    reason: YECM.PublishingEndedReason = PublishingEndedReason.None,
    publishingNetwork?: YECM.ExternalNetworkResponse,
    userId?: string,
    mediaAssets?: YECM.MediaAssetResponse[] | null
) => {
    switch (reason) {
        case PublishingEndedReason.Published:
            return (
                <StatusBadge
                    mediaAssets={mediaAssets}
                    networkName={publishingNetwork?.title || undefined}
                    userId={userId}
                />
            );
        case PublishingEndedReason.NotPublished:
        case PublishingEndedReason.CollaborationStopped:
            return <StatusIcon state="ended" />;
        case PublishingEndedReason.NotVerified:
            return (
                <StatusBadge isReview mediaAssets={mediaAssets} networkName={publishingNetwork?.title || undefined} />
            );
        case PublishingEndedReason.MediaRejected:
            return <StatusIcon state="reported" />;
        case PublishingEndedReason.None:
        default:
            return <StatusIcon state="none" />;
    }
};

export const getPublishingStatus = (
    {
        publishingState,
        publishingEndedReason,
        publishingNetwork,
        participantId,
        mediaAssets
    }: YECM.ParticipationResponse,
    hasSharing = false
) => {
    if (!hasSharing) {
        return <StatusIcon state="none" />;
    }

    switch (publishingState) {
        case PublishingStateKey.AwaitingSubmission:
        case PublishingStateKey.Pending:
        case PublishingStateKey.Verifying:
            return <StatusIcon state="pending" />;
        case PublishingStateKey.Ended:
            return publishingEndedReasons(publishingEndedReason, publishingNetwork, participantId, mediaAssets);
        case PublishingStateKey.None:
        default:
            return <StatusIcon state="none" />;
    }
};

// Enrollment statuses

export const getEnrollmentStatuses = (
    data: YECM.ParticipationResponse,
    collaboration?: YECM.CollaborationDetailedResponse | null
) => {
    const hasSharing = !!collaboration?.networks?.find(i => i.networkTitle?.toLowerCase() !== ExternalNetworkKey.Yeay);
    const isVideo = true;

    const participationStatus = getParticipationStatus(data, hasSharing);
    const submissionStatus = getSubmissionStatus(isVideo, data);
    const sharingStatus = getPublishingStatus(data, hasSharing);

    return { participationStatus, submissionStatus, sharingStatus };
};
