import { createEffect, createStore } from 'effector';
import { isEmpty } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { TrendingType, maximumCreatorsCount } from 'v2/constants/services/trendings';
import { API } from 'v2/services/yasy';
import { getSwapItems } from 'v2/stores/trendings/utils';

// Effects

const getTrendingCreatorsFx = createEffect({
    handler: async () => {
        try {
            const response = await API.trendings.getTrendings({
                pageIndex: 0,
                limit: maximumCreatorsCount,
                type: TrendingType.User,
                returnQueryCount: true
            });

            if (!response || isEmpty(response)) {
                throw new Error();
            }

            return response;
        } catch (e) {
            console.log('Failed to get trending users', e);
            return null;
        }
    }
});

const deleteCreatorFromTrendingFx = createEffect({
    handler: async (id: string) => {
        try {
            const { isSuccess } = await API.trendings.removeItemById({ id });

            if (!isSuccess) {
                throw new Error();
            }

            notify('Creator successfully removed from trending!');
            return id;
        } catch (e) {
            notify('There was a problem removing creator, try again.', 'error');
            console.log('There was a problem removing creator.', e);
            return null;
        }
    }
});

const deleteCreatorsFromTrendingFx = createEffect({
    handler: async (ids: string[]) => {
        try {
            if (ids.length === 0) {
                return [];
            }

            const result: string[] = [];

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];

                const { isSuccess } = await API.trendings.removeItemById({ id });

                if (isSuccess) {
                    result.push(id);
                }
            }

            return result;
        } catch (e) {
            console.log('There was a problem removing creators.', e);
            return [];
        }
    }
});

interface SwapCreatorsParams {
    fromItem: YEAY.UpdateTrendingOverridesRequest;
    toItem: YEAY.UpdateTrendingOverridesRequest | null;
}

const swapCreatorsFx = createEffect({
    handler: async ({ fromItem, toItem }: SwapCreatorsParams) => {
        try {
            await API.trendings.updateItemById(fromItem);

            if (toItem) {
                await API.trendings.updateItemById(toItem);
            }

            return { fromItem, toItem };
        } catch (e) {
            notify('Failed to swap creators. Try again.', 'error');
            console.log('Failed to swap creators.', e);
            return null;
        }
    }
});

const addCreatorsToTrendingFx = createEffect({
    handler: async (users: Array<Pick<YEAY.CreateTrendingOverridesRequest, 'targetId' | 'position'>>) => {
        try {
            if (users.length === 0) {
                return [];
            }

            const result: YEAY.CreateTrendingOverridesResponse[] = [];

            for (let i = 0; i < users.length; i++) {
                const user = users[i];

                const item = await API.trendings.createTrending({
                    ...user,
                    type: TrendingType.User
                });

                if (item) {
                    result.push(item);
                }
            }

            return result;
        } catch (e) {
            console.log('There was a problem adding creators.', e);
            return [];
        }
    }
});

// Stores

const $creatorsResponse = createStore<YEAY.QueryTrendingOverridesResponse | null>(null)
    .on(getTrendingCreatorsFx.doneData, (_, creatorsResponse) => creatorsResponse)
    .on(deleteCreatorFromTrendingFx.doneData, (creatorsResponse, itemId) => {
        if (!creatorsResponse || !itemId || !creatorsResponse.items || creatorsResponse.items.length === 0) {
            return creatorsResponse;
        }

        const newItems = creatorsResponse.items.filter(item => item.id !== itemId);

        return {
            ...creatorsResponse,
            totalRecords: newItems.length,
            returnedRecords: newItems.length,
            items: newItems
        };
    })
    .on(swapCreatorsFx.doneData, (creatorsResponse, items) => {
        if (!creatorsResponse || !items) {
            return creatorsResponse;
        }

        const newItems = getSwapItems(items, creatorsResponse.items);

        return {
            ...creatorsResponse,
            items: newItems
        };
    })
    .on(deleteCreatorsFromTrendingFx.doneData, (creatorsResponse, ids) => {
        if (!creatorsResponse || ids.length === 0) {
            return creatorsResponse;
        }

        const newItems = (creatorsResponse.items || []).filter(item => !!item.id && !ids.includes(item.id));

        return {
            ...creatorsResponse,
            items: newItems
        };
    });

const $creators = $creatorsResponse.map(creatorsResponse => creatorsResponse?.items || []);

// Exports

export const trendingCreatorsEffects = {
    getTrendingCreatorsFx,
    deleteCreatorFromTrendingFx,
    deleteCreatorsFromTrendingFx,
    swapCreatorsFx,
    addCreatorsToTrendingFx
};

export const trendingCreatorsStores = { $creatorsResponse, $creators };
