import styled, { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const DraggableWrapper = styled.div`
    ${({ draggable }) =>
        draggable &&
        css`
            cursor: grab;
        `}
`;

export const DragSpot = styled.div<{ bordered: boolean }>`
    ${flexCenter};
    background-color: ${grey[100]};
    width: 82px;
    height: 21px;
    border-radius: 32px;

    ${({ bordered }) =>
        bordered &&
        css`
            border: 1px solid ${brandColors.black};
        `}
`;
