import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { Pagination } from 'v2/components/ui/Pagination';
import { Table } from 'v2/components/ui/Table';
import { Title } from 'v2/components/ui/typography/Title';
import { SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { defaultBrandUsersParams } from 'v2/constants/services/brands';
import { brandUsersEffects, brandUsersStores } from 'v2/stores/brands/details/users';
import { columns } from './constants';
import { prepareUsersForTable } from './utils';

const { getBrandUsersFx } = brandUsersEffects;
const { $brandUsersResponse } = brandUsersStores;

interface Props {
    brandId: string;
}

export const BrandUsersTab: FC<Props> = ({ brandId }) => {
    const [limit, setLimit] = useState(defaultBrandUsersParams.limit);
    const [pageIndex, setPageIndex] = useState(defaultBrandUsersParams.pageIndex);

    const isLoading = useStore(getBrandUsersFx.pending);
    const brandUsersResponse = useStore($brandUsersResponse);

    const items = brandUsersResponse?.items || [];
    const currentPage = (brandUsersResponse?.currentPageIndex || 0) + 1;
    const totalRecords = brandUsersResponse?.totalRecords || 0;

    const tableData = prepareUsersForTable(items);

    const handlePageChange = (page: number) => {
        const index = page - 1;
        setPageIndex(index >= 0 ? index : 0);
    };

    const handleLimitChange = (size: number) => {
        setLimit(size);
    };

    useEffect(() => {
        getBrandUsersFx({ organizationId: brandId, pageIndex, limit });
    }, [brandId, limit, pageIndex]);

    return (
        <Table
            cellCenter
            bottomExtra={
                <SectionBorder borderTop padding="0 8px">
                    <Pagination
                        currentPage={currentPage}
                        size={limit}
                        totalItems={totalRecords}
                        onChange={handlePageChange}
                        onPageSizeChange={handleLimitChange}
                    />
                </SectionBorder>
            }
            columns={columns}
            data={tableData}
            emptyMessage="No Users Found"
            isLoading={isLoading}
            topExtra={
                <SectionBorder borderBottom padding="14px 16px">
                    <Title size="-2">Users</Title>
                </SectionBorder>
            }
        />
    );
};
