import { UserRole, userRoleTexts } from 'v2/constants/services/users';

export const roleOptions = [
    {
        value: UserRole.SuperAdministrator,
        text: userRoleTexts[UserRole.SuperAdministrator]
    },
    {
        value: UserRole.Administrator,
        text: userRoleTexts[UserRole.Administrator]
    },
    {
        value: UserRole.ContentManager,
        text: userRoleTexts[UserRole.ContentManager]
    },
    {
        value: UserRole.Curator,
        text: userRoleTexts[UserRole.Curator]
    }
];
