import styled from 'styled-components';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { ContentWrapper } from 'v2/components/wrappers/ContentWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const ActionBlockWrapper = styled(ContentWrapper)`
    height: 32px;
    min-width: 64px;
    width: 64px;
    background-color: ${brandColors.white};
    padding: 8px;
    ${flexCenter};
`;

export const Divider = styled.div`
    margin: 0px 5px;
    width: 1px;
    background-color: ${grey[400]};
    height: 10px;
    opacity: 0.8;
`;

export const StyledRelativeWrapper = styled(RelativeWrapper)`
    :hover ${CardHoverOpacityEffect} {
        opacity: 1;
    }
`;
