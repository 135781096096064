import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { ActivitySort } from 'v2/components/filter/users/ActivitySort';
import { LocaleFilter } from 'v2/components/filter/users/LocaleFilter';
import { TrustedFilter } from 'v2/components/filter/users/TrustedFilter';
import { UsersSearch } from 'v2/components/filter/users/UsersSearch';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { influencerUsersModalStore } from 'v2/stores/users';

const { events, stores } = influencerUsersModalStore;
const { resetFilters } = events;

interface Props {
    isLoading?: boolean;
}

export const Filters: FC<Props> = ({ isLoading }) => {
    const isAvailableReset = useStore(stores.$resetIsAvailable);
    const usersResponse = useStore(stores.$usersResponse);
    const totalRecords = usersResponse?.totalRecords || 0;

    const handleResetFilters = () => {
        resetFilters();
    };

    return (
        <Section>
            <Section alignCenter justifyBetween marginBottom="10px">
                <Section maxWidth="500px">
                    <UsersSearch store={influencerUsersModalStore} />
                </Section>

                <TrustedFilter store={influencerUsersModalStore} />

                <ActivitySort store={influencerUsersModalStore} />

                <Button
                    buttonType="text-black"
                    disabled={!isAvailableReset || isLoading}
                    textSize="small"
                    onClick={handleResetFilters}
                >
                    Reset
                </Button>
            </Section>

            <Row alignCenter noWrap>
                <Row marginRight="8px">
                    <TotalBadge count={totalRecords} />
                </Row>

                <LocaleFilter store={influencerUsersModalStore} />
            </Row>
        </Section>
    );
};
