import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { AccountsFilter } from 'v2/components/filter/users/AccountsFilter';
import { ActivitySort } from 'v2/components/filter/users/ActivitySort';
import { AgeFilter } from 'v2/components/filter/users/AgeFilter';
import { CommissionFilter } from 'v2/components/filter/users/CommissionFilter';
import { DateFilter } from 'v2/components/filter/users/DateFilter';
import { GenderFilter } from 'v2/components/filter/users/GenderFilter';
import { LocaleFilter } from 'v2/components/filter/users/LocaleFilter';
import { RegisteredTypeFilter } from 'v2/components/filter/users/RegisteredTypeFilter';
import { TrustedFilter } from 'v2/components/filter/users/TrustedFilter';
import { UserRoleFilter } from 'v2/components/filter/users/UserRoleFilter';
import { UsersSearch } from 'v2/components/filter/users/UsersSearch';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Pagination } from 'v2/components/ui/Pagination';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { defaultUsersParams } from 'v2/constants/services/users';
import { useQueryParams } from 'v2/hooks/use-query-params';
import { authStores } from 'v2/stores/auth';
import { UsersParamsWithUserId, userPageStore } from 'v2/stores/users';
import { getPageIndex } from 'v2/utils/services';
import { BottomWrapper, ChildrenWrapper, FiltersWrapper } from './styles';

const { events, stores } = userPageStore;

const { $usersFilters, $usersResponse, $resetIsAvailable } = stores;
const { setOverwriteFilters, setUserId, addFilters, resetFilters } = events;

interface Props {
    isLoading?: boolean;
}

export const UsersFilterLayout: FC<Props> = ({ children, isLoading }) => {
    const isAdmin = useStore(authStores.$isAdmin);

    const filters = useStore($usersFilters);
    const usersResponse = useStore($usersResponse);
    const resetIsAvailable = useStore($resetIsAvailable);

    const totalPages = usersResponse?.totalPages || 0;
    const currentPage = (usersResponse?.currentPageIndex || 0) + 1;
    const totalItems = usersResponse?.totalRecords || 0;
    const pageSize = filters.limit || defaultUsersParams.limit;

    const handlePageChange = (page: number) => {
        addFilters({ pageIndex: getPageIndex(page) });
    };

    const handlePageSizeChange = (size: number) => {
        addFilters({ limit: size });
    };

    const handleResetFilters = () => {
        resetFilters();
    };

    const [, setQueryParams] = useQueryParams(({ userId, ...params }: UsersParamsWithUserId) => {
        if (userId) {
            setUserId(userId);
        } else {
            setOverwriteFilters(params);
        }
    });

    useEffect(() => {
        if (filters.userId) {
            setQueryParams({
                userId: filters.userId
            });
        } else {
            setQueryParams(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <FiltersWrapper isLoading={isLoading}>
                <SectionBorder alignCenter borderBottom noWrap padding="12px 16px">
                    <Section maxWidth="500px">
                        <UsersSearch store={userPageStore} />
                    </Section>
                    <Row marginLeft="8px">
                        <TrustedFilter store={userPageStore} />
                    </Row>
                </SectionBorder>

                <SectionBorder alignCenter borderBottom justifyBetween noWrap padding="8px 16px">
                    <Row alignCenter>
                        <Column marginRight="8px">
                            <TotalBadge count={totalItems} />
                        </Column>

                        <Column marginRight="8px">
                            <UserRoleFilter store={userPageStore} />
                        </Column>

                        <Column marginRight="8px">
                            <RegisteredTypeFilter store={userPageStore} />
                        </Column>

                        <Column marginRight="8px">
                            <AccountsFilter store={userPageStore} />
                        </Column>

                        <LocaleFilter store={userPageStore} />
                    </Row>

                    <Row alignCenter>
                        <Column marginRight="8px">
                            <ActivitySort store={userPageStore} />
                        </Column>

                        <Button
                            buttonType="text-black"
                            disabled={!resetIsAvailable || isLoading}
                            textSize="small"
                            onClick={handleResetFilters}
                        >
                            Reset
                        </Button>
                    </Row>
                </SectionBorder>

                <Section alignCenter noWrap padding="8px 16px">
                    <Column marginRight="8px">
                        <DateFilter store={userPageStore} />
                    </Column>

                    <Column marginRight="8px">
                        <AgeFilter store={userPageStore} />
                    </Column>

                    <Column marginRight="8px">
                        <GenderFilter store={userPageStore} />
                    </Column>

                    {isAdmin && <CommissionFilter store={userPageStore} />}
                </Section>
            </FiltersWrapper>

            <ChildrenWrapper>
                {children}

                {!isLoading && totalPages > 1 && (
                    <BottomWrapper>
                        <Pagination
                            currentPage={currentPage}
                            pageSizes={[20, 50, 100]}
                            size={pageSize}
                            totalPages={totalPages}
                            onChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </BottomWrapper>
                )}
            </ChildrenWrapper>
        </>
    );
};
