import React, { FC, ReactNode } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { grey } from 'v2/constants/styles/colors';

interface Props {
    text: string | number;
    icon: ReactNode;
    iconColor?: string;
}

export const IconTextItem: FC<Props> = ({ text, icon, iconColor = grey[500] }) => (
    <Row alignCenter noWrap>
        <IconWrapper color={iconColor}>{icon}</IconWrapper>
        <Row marginLeft="4px">
            <Text isSemibold noTextWrap size="-2">
                {text}
            </Text>
        </Row>
    </Row>
);
