import styled from 'styled-components';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const Wrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding: 16px 32px 0 16px;
`;

export const BottomWrapper = styled(Section)`
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    border-radius: 8px;
    background-color: ${brandColors.white};
    padding: 10px;
`;
