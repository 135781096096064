import axios from './axios';

export const authenticateUser = (data: YEAY.UserAuthChallengeEmailOrUsernameOrPhoneRequest) =>
    axios<YEAY.UserJwtTokenResponse>(
        {
            url: '/user/authenticate',
            data
        },
        false
    );

export const getCurrentUser = (data: YEAY.GetYeayUserRequest = {}) =>
    axios<YEAY.GetUserResponse>({
        url: '/user/get',
        data
    });

export const getUserById = (data: YEAY.AdminGetUserRequest) =>
    axios<YEAY.AdminGetUserCommon>({
        url: '/admin/user/get',
        data
    });

export const blockUsersById = (data: YEAY.AdminDisableUsersRequest) =>
    axios<YEAY.MessageResponseBase>({
        url: '/admin/user/disable',
        data
    });

export const enableUsersById = (data: YEAY.AdminEnableUsersRequest) =>
    axios<YEAY.MessageResponseBase>({
        url: '/admin/user/enable',
        data
    });

export const updateUserById = (data: YEAY.AdminUpdateUserRequest) =>
    axios<YEAY.AdminGetUserCommon>({
        url: '/admin/user/update',
        data
    });

export const getUserNetworks = (data: YEAY.GetAllUserExternalNetworkRequest) =>
    axios<YEAY.GetAllUserExternalNetworkResponse>({
        url: '/user/get-all-user-external-networks',
        data
    });
