import axios, { CancelTokenSource } from 'axios';
import { createEffect, createEvent, createStore, forward, restore } from 'effector';
import { defaultDeletedUsersValues, sortModeTagsValuesDefault } from 'v1/constants/defaults/users';
import { API } from 'v1/services';
import { initializeToggleStore } from 'v1/stores/initialize/initialize.toggle.store';
import { SortType } from 'v1/types/types';

let cancelToken: CancelTokenSource | undefined;

const [loading, updateLoading] = initializeToggleStore();

const loadItems = createEffect({
    handler: async (values: YEAY.QueryDeletedUsersRequest) => {
        try {
            cancelToken && cancelToken.cancel();
            cancelToken = axios.CancelToken.source();

            updateLoading();
            const data = await API.adminUsers.getDeletedUsers(values, cancelToken.token);
            updateLoading();

            return data;
        } catch {
            updateLoading();
            return {
                currentPageIndex: 0,
                totalPages: 0,
                totalRecords: 0
            };
        }
    }
});

const setSortPostfix = createEvent<SortType>();
const sortPostfix = restore<SortType>(setSortPostfix, sortModeTagsValuesDefault);

const deletedUsers = createStore<YEAY.QueryDeletedUsersResponse>({}).on(loadItems.doneData, (_, state) => state);

const updateValues = createEvent<YEAY.QueryDeletedUsersRequest>();
const setDefaultValues = createEvent();

const values = createStore<YEAY.QueryDeletedUsersRequest>(defaultDeletedUsersValues)
    .on(updateValues, (state, values: YEAY.QueryDeletedUsersRequest) => ({
        ...state,
        ...values
    }))
    .on(setDefaultValues, () => defaultDeletedUsersValues);

forward({
    from: [values],
    to: [loadItems]
});

const setId = createEvent<string>();
const getRequestId = restore(setId, '');

export const deletedUsersStores = {
    deletedUsers,
    values,
    loading,
    getRequestId,
    sortPostfix
};

export const deletedUsersEvents = {
    updateValues,
    setDefaultValues,
    setId,
    setSortPostfix
};

export const deletedUsersEffects = {
    loadItems
};
