import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const FiltersWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    border-radius: 4px;
    background-color: ${brandColors.white};
    margin-bottom: 8px;
`;
