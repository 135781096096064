import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { authStores } from 'v2/stores/auth';
import { DropdownReferrals } from './components/DropdownReferrals';
import { DropdownVideos } from './components/DropdownVideos';
import { UserAdditionalInfo } from './components/UserAdditionalInfo';
import { UserMainInfo } from './components/UserMainInfo';
import { UserTransactions } from './components/UserTransactions';
import { Wrapper } from './styles';

interface Props {
    userId?: string;
    user: YEAY.AdminGetUserCommon | null;
    isLoading: boolean;
}

export const UserInfo: FC<Props> = ({ userId, user, isLoading }) => {
    const isAdmin = useStore(authStores.$isAdmin);

    return (
        <Column width="100%">
            {user && (
                <>
                    <Wrapper>
                        <UserMainInfo user={user} />
                        <UserAdditionalInfo user={user} />
                    </Wrapper>

                    {user.referralInfo && isAdmin && (
                        <Section marginTop="8px">
                            <AccordionSection title="User Referrals">
                                <DropdownReferrals data={user.referralInfo} />
                            </AccordionSection>
                        </Section>
                    )}

                    {userId && (
                        <>
                            <Section marginTop="8px">
                                <DropdownVideos id={userId} />
                            </Section>

                            {isAdmin && (
                                <Section marginBottom="8px" marginTop="8px">
                                    <AccordionSection title="User Transactions">
                                        <UserTransactions userId={userId} />
                                    </AccordionSection>
                                </Section>
                            )}
                        </>
                    )}
                </>
            )}

            {isLoading && !user && (
                <Section justifyCenter marginTop="16px">
                    <Spinner />
                </Section>
            )}

            {!isLoading && !user && <Empty title="User Not Found" />}
        </Column>
    );
};
