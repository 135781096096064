import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Title } from 'v2/components/ui/typography/Title';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { media } from 'v2/constants/styles/media';
import {
    disableDefaultButtonStyleMixin,
    disableScrollbarMixin,
    ellipsisMixin,
    flexCenter
} from 'v2/constants/styles/mixins';
import { sideBarWidth, sideBarZIndex } from 'v2/constants/styles/sizes';
import { transitionTime } from 'v2/constants/styles/special';
import { IsClosed } from 'v2/types/data';

export const LogoLink = styled(Link)`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`;

export const UserName = styled(Title)`
    ${ellipsisMixin};
    max-width: 120px;
    margin-left: 8px;
`;

export const PageName = styled(Title)`
    margin-left: 8px;
`;

export const IconWrapper = styled.div`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    ${flexCenter};
`;

export const SidebarLink = styled(NavLink)`
    width: 100%;
    min-width: 38px;
    height: 38px;
    display: flex;
    padding: 11px;
    border-radius: 8px;
    color: ${grey[500]};
    transition: background-color ${transitionTime} linear, color ${transitionTime} linear;
    margin-bottom: 1px;

    &:hover {
        color: ${grey[600]};
        background-color: ${grey[100]};
    }

    &.active {
        color: ${brandColors.black};
        background-color: ${grey[200]};
    }
`;

export const LogoutButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    border-radius: 8px;
    color: ${grey[500]};
    padding: 11px;
    transition: background-color ${transitionTime} linear, color ${transitionTime} linear;

    &:hover {
        color: ${grey[600]};
        background-color: ${grey[100]};
    }
`;

export const Wrapper = styled.aside<IsClosed>`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: ${brandColors.white};
    border-right: 1px solid ${brandColors.grey};
    ${disableScrollbarMixin};
    overflow-y: auto;
    overflow-x: hidden;
    transform: ${({ isClosed }) => (isClosed ? 'translateX(-100%)' : '')};
    transition: all ${transitionTime} linear;
    padding: 30px 16px;
    align-items: center;
    z-index: 101;
    width: ${sideBarWidth};

    ${media.laptopSmall(`
        padding: 16px;
        align-items: flex-start;
        z-index: ${sideBarZIndex};
    `)};
`;

export const BlackBackground = styled.div<IsClosed>`
    position: fixed;
    display: ${({ isClosed }) => (isClosed ? 'none' : 'block')};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.6);
`;

export const UserInfoWrapper = styled(Link)`
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    ${media.laptopSmall(`
        padding-left: 0;
        padding-right: 0;
    `)};
`;

export const CloseButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    position: fixed;
    top: 24px;
    right: 16px;
    ${flexCenter};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${brandColors.white};
    z-index: 102;
`;
