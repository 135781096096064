import { isUndefined } from 'lodash';
import { RangeInputValuesType } from 'v2/components/ui/inputs/RangeInputs';

export const validateValues = ([from, to]: RangeInputValuesType, minLimit: number, maxLimit: number) => {
    if (isUndefined(from) || isUndefined(to)) {
        return '';
    }

    if (from < minLimit) {
        return `The minimum value must be at least ${minLimit}`;
    }

    if (to < minLimit) {
        return `The maximum value must be at least ${minLimit}`;
    }

    if (from > maxLimit) {
        return `The minimum value must be no more than ${maxLimit}`;
    }

    if (to > maxLimit) {
        return `The maximum value must be no more than ${maxLimit}`;
    }

    if (from >= to) {
        return 'The minimum value must not exceed the maximum value';
    }

    return '';
};
