import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { ClearInputButton } from 'v1/components/common/buttons/ClearInputButton';
import { SearchButton } from 'v1/components/common/buttons/SearchButton';
import { InputWrapper, SearchWrapper } from 'v1/components/common/inputs/SearchInput/styles';
import { Select } from 'v1/components/common/inputs/Select';
import { IconWrapper, Input } from 'v1/components/common/inputs/TextInput/styles';
import { SearchParameters } from 'v1/types/data';
import { OnStringChange } from 'v1/types/form';
import { ReactClick, ReactKeyboard } from 'v1/types/react';
import { BackgroundColor, BorderProperties, Padding } from 'v1/types/styles';

export interface SearchInputProps
    extends Pick<Padding, 'padding'>,
        Pick<BorderProperties, 'border'>,
        OnStringChange,
        BackgroundColor,
        ReactKeyboard<HTMLInputElement>,
        ReactClick<HTMLButtonElement> {
    searchParameters: SearchParameters[];
    value?: string;
    disableClearButton?: boolean;
    disableEnterKeyDown?: boolean;
    selectPadding?: string;
    selectorWidth?: string;

    // byIdParameters?: string[];
}

export const SearchInput = ({
    border,
    padding,
    selectPadding,
    searchParameters,
    disableClearButton,
    disableEnterKeyDown,
    selectorWidth,
    backgroundColor
}: // byIdParameters
SearchInputProps) => {
    const filteredSearchParameters = searchParameters;
    const selectorItems = filteredSearchParameters.map(({ searchBy }) => searchBy);

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [isPressedEnter, setIsPressedEnter] = useState(false);

    const defaultValues = filteredSearchParameters.map(({ defaultValue }) => defaultValue || '');
    const onSearch = filteredSearchParameters[activeItemIndex].onSearch;

    const [values, setValues] = useState(defaultValues);

    const value = values[activeItemIndex];

    /* For setting selector to non empty value */
    const [isFirstUpdate, setIsFirstUpdate] = useState(true);

    useEffect(() => {
        /* If value have been changed - selector don't setting to old state (with non empty value) */
        if (isFirstUpdate && values.some(value => value !== '')) {
            values.forEach((value, i) => value !== '' && setActiveItemIndex(i));
            setIsFirstUpdate(false);
        }
    }, [values, isFirstUpdate]);

    const [isFocused, setIsFocused] = useState(false);

    const onInputFocus = () => {
        setIsFocused(true);
    };

    const onSearchClick = () => {
        value && onSearch(value);
        // TODO: refactor code*/
        setValues(() => {
            const returnedValues = filteredSearchParameters.map(_ => '');
            returnedValues[activeItemIndex] = value;
            return returnedValues;
        });
    };

    const onInputBlur = () => {
        setIsFocused(false);
        if (!isPressedEnter) {
            onSearchClick();
            setIsPressedEnter(true);
        }
    };

    const clearSearch = () => {
        setValues(filteredSearchParameters.map(_ => ''));
        onSearch('');
    };

    const onChangeSelector = (index: number) => {
        clearSearch();
        setActiveItemIndex(index);
    };

    const onChange = (value: string) => {
        // const isIdParameter = byIdParameters?.some(idParameter => selectorItems[activeItemIndex] === idParameter);
        // /* Select search by id if input data it is objectId (id from mongoDb) */
        // byIdParameters &&
        //     isObjectId(value) &&
        //     !isIdParameter &&
        //     onChangeSelector(selectorItems.findIndex(parameter => parameter === byIdParameters[0]));
        filteredSearchParameters.forEach(({ regExp, searchBy }) => {
            if (regExp && regExp.test(value)) {
                const matchedSelectorIndex = selectorItems.findIndex(parameter => parameter === searchBy);
                if (activeItemIndex !== matchedSelectorIndex) {
                    onChangeSelector(matchedSelectorIndex);
                }
                //TODO: refactor code
                setValues(values => {
                    const returnedValues = [...values];
                    returnedValues[matchedSelectorIndex] = value;
                    return returnedValues;
                });
            }
        });

        //TODO: refactor code
        setValues(values => {
            const returnedValues = [...values];
            returnedValues[activeItemIndex] = value;
            return returnedValues;
        });

        if (value === '' && defaultValues[activeItemIndex]) {
            onSearch('');
            setIsPressedEnter(true);
        }
    };
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsPressedEnter(false);
        onChange(e.currentTarget.value);
    };

    const searchStart = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') {
            onSearchClick();
            setIsPressedEnter(true);
        } else if (key === 'Backspace') {
            value.length === 1 && clearSearch();
        }
    };

    useEffect(() => {
        setValues(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters]);

    return (
        <SearchWrapper>
            <InputWrapper border={border} padding={padding}>
                <SearchButton active={isFocused} onClick={onSearchClick} />

                <Input
                    fontWeight="700"
                    placeholder={filteredSearchParameters[activeItemIndex].placeholder}
                    type="text"
                    value={value}
                    onBlur={onInputBlur}
                    onChange={onInputChange}
                    onFocus={onInputFocus}
                    onKeyDown={disableEnterKeyDown ? undefined : searchStart}
                />
                <IconWrapper>{value && !disableClearButton && <ClearInputButton onClick={clearSearch} />}</IconWrapper>
            </InputWrapper>

            {selectorItems.length > 1 && (
                <Select
                    backgroundColor={backgroundColor}
                    border={border}
                    defaultIndex={activeItemIndex}
                    minWidth="140px"
                    padding={selectPadding}
                    selector={selectorItems}
                    width={selectorWidth}
                    onChange={onChangeSelector}
                />
            )}
        </SearchWrapper>
    );
};
