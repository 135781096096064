import styled from 'styled-components';
import { media } from 'v2/constants/styles/media';

export const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;

    ${media.phoneLarge(`
        grid-template-columns: repeat(2, 1fr);
    `)};

    ${media.laptopSmall(`
        grid-template-columns: repeat(3, 1fr);
    `)};

    ${media.laptop(`
        grid-template-columns: repeat(4, 1fr);
    `)};

    ${media.desktopLarge(`
        grid-template-columns: repeat(5, 1fr);
    `)};
`;
