import React, { FC } from 'react';
import clearInputIcon from 'v1/assets/clear_input_icon.svg';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { ClickableWrapper } from 'v1/components/wrappers/ClickableWrapper/styles';
import { clearInputButtonWidthAndHeight } from 'v1/constants/styles/sizes';
import { ReactClick } from 'v1/types/react';

export const ClearInputButton: FC<ReactClick<HTMLButtonElement>> = props => (
    <ClickableWrapper height={clearInputButtonWidthAndHeight} width={clearInputButtonWidthAndHeight} {...props}>
        <CustomImg alt="clear search button" height="75%" src={clearInputIcon} width="75%" />
    </ClickableWrapper>
);
