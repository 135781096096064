import styled from 'styled-components';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';

export const ContentWrapper = styled(Column)`
    padding: 20px 24px;
    min-height: 170px;
    border-bottom: 1px solid ${grey[200]};
`;

export const ButtonsWrapper = styled(Section)`
    justify-content: flex-end;
    padding: 8px 16px;
`;
