import styled from 'styled-components';
import { blue } from 'v1/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v1/constants/styles/mixins';
import { primaryBorderRadius, xs } from 'v1/constants/styles/sizes';
import { TextProperties } from 'v1/types/styles';

export const ResetButton = styled.button<TextProperties>`
    ${disableDefaultButtonStyleMixin};
    width: 60px;
    background-color: transparent;
    border-radius: ${primaryBorderRadius};
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.04em;
    text-align: right;
    text-decoration: underline;
    color: ${blue};
    padding: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    @media (max-width: ${xs}) {
        font-size: ${({ fontSize }) => (fontSize ? fontSize : '11px')};
        line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '19px')};
    }
`;
