import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { CreatorReachBlock } from 'v2/components/cards/SingleCollaborationCard/TargetingBlock/CreatorReachBlock';
import { getRangeAge, getRangeRequirements } from 'v2/components/cards/SingleCollaborationCard/TargetingBlock/utils';
import { Category } from 'v2/components/ui/Category';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { LanguageType, languages } from 'v2/constants/languages';
import { LocationType, locations } from 'v2/constants/location';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { grey } from 'v2/constants/styles/colors';
import { externalNetworksEffects, externalNetworksStores } from 'v2/stores/external-networks';
import { getGenderName } from 'v2/utils/services';
import { TitleWrapper } from './styles';

interface Props {
    data: YECM.CollaborationDetailedResponse;
}

// TODO add button Edit to languages and age
export const TargetingBlock: FC<Props> = ({ data }) => {
    const externalNetworks = useStore(externalNetworksStores.$externalNetworks);

    const isAllCreators = !data.autoApproveMedia && data.autoApproveParticipants;

    const isVerifiedAndApproval = data.autoApproveMedia && !data.autoApproveParticipants;

    useEffect(() => {
        externalNetworksEffects.getExternalNetworksFx();
    }, []);

    return (
        <SectionBorder borderLeft>
            <TitleWrapper>
                <Title color={grey[700]} size="-2">
                    Targeting
                </Title>
            </TitleWrapper>

            <RepeatGrid columns={isVerifiedAndApproval ? 3 : 2} width="100%">
                <SectionBorder borderBottom borderRight>
                    <PropertyLayout title="Type of Creators">
                        <Text isSemibold size="-2">
                            {isAllCreators ? 'All Creators' : 'Only Verified Creators'}
                        </Text>
                    </PropertyLayout>
                </SectionBorder>
                {isVerifiedAndApproval && (
                    <SectionBorder borderBottom borderRight>
                        <PropertyLayout title="Creator Approval">
                            <Category text="Yes" />
                        </PropertyLayout>
                    </SectionBorder>
                )}
                <SectionBorder borderBottom>
                    <PropertyLayout title="Gender">
                        {data.participationRequirements?.genders &&
                        data.participationRequirements.genders.length > 0 ? (
                            data.participationRequirements.genders.map(gender => (
                                <Row key={gender} marginRight="4px">
                                    <Category text={getGenderName(gender)} />
                                </Row>
                            ))
                        ) : (
                            <Category text="All" />
                        )}
                    </PropertyLayout>
                </SectionBorder>
            </RepeatGrid>

            <RepeatGrid columns={2} width="100%">
                <SectionBorder borderBottom borderRight>
                    <PropertyLayout title="Languages">
                        <Section marginBottom="-8px">
                            {data.participationRequirements?.languages &&
                            data.participationRequirements.languages.length > 0
                                ? data.participationRequirements.languages.map(language => (
                                      <Row key={language} marginBottom="8px" marginRight="4px">
                                          <Category text={languages[language as LanguageType]} />
                                      </Row>
                                  ))
                                : '-'}
                        </Section>
                    </PropertyLayout>
                </SectionBorder>
                <SectionBorder borderBottom>
                    <PropertyLayout title="Location">
                        <Section marginBottom="-8px">
                            {data.participationRequirements?.locations &&
                            data.participationRequirements.locations.length > 0
                                ? data.participationRequirements.locations.map((location, index) => (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <Row key={index} marginBottom="8px" marginRight="4px">
                                          <Category text={locations[location as LocationType]} />
                                      </Row>
                                  ))
                                : '-'}
                        </Section>
                    </PropertyLayout>
                </SectionBorder>
            </RepeatGrid>

            <RepeatGrid columns={2} width="100%">
                <SectionBorder borderBottom borderRight>
                    <PropertyLayout title="Age">
                        {data.participationRequirements?.ageRanges &&
                        data.participationRequirements?.ageRanges.length > 0
                            ? data.participationRequirements?.ageRanges.map(({ minAge, maxAge }, index) =>
                                  minAge && maxAge ? (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <Row key={index} marginRight="4px">
                                          <Category text={getRangeAge(minAge, maxAge)} />
                                      </Row>
                                  ) : (
                                      '-'
                                  )
                              )
                            : '-'}
                    </PropertyLayout>
                </SectionBorder>

                <SectionBorder borderBottom>
                    <PropertyLayout title="Creator Reach">
                        {data.participationRequirements?.engagements &&
                        data.participationRequirements.engagements.length > 0
                            ? data.participationRequirements.engagements.map(
                                  ({ minFollowerCount, maxFollowerCount, networkId }, index) => {
                                      const networkName = externalNetworks
                                          ?.filter(item => item.title?.toLowerCase() !== ExternalNetworkKey.Yeay)
                                          .find(item => item.id === networkId);
                                      return (
                                          networkName && (
                                              // eslint-disable-next-line react/no-array-index-key
                                              <Row key={index} marginRight="4px">
                                                  <CreatorReachBlock
                                                      text={getRangeRequirements(minFollowerCount, maxFollowerCount)}
                                                      title={networkName?.title || ''}
                                                  />
                                              </Row>
                                          )
                                      );
                                  }
                              )
                            : '-'}
                    </PropertyLayout>
                </SectionBorder>
            </RepeatGrid>
        </SectionBorder>
    );
};
