import styled from 'styled-components';
import { Margin, MaxSizes, MinSizes, Overflow, Padding, Sizes, ZIndex } from 'v2/types/styles';

export interface GridProps extends Margin, Padding, MaxSizes, Sizes, MinSizes, ZIndex, Overflow {
    gridTemplateColumns?: string;
    gap?: string;
}

export const Grid = styled.div<GridProps>`
    display: grid;
    ${({ gridTemplateColumns }) => gridTemplateColumns && `grid-template-columns: ${gridTemplateColumns};`};
    ${({ gap }) => gap && `gap: ${gap};`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
    ${({ width }) => width && `width: ${width};`};
    ${({ height }) => height && `height: ${height};`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ padding }) => padding && `padding: ${padding};`};
    ${({ zIndex }) => zIndex !== undefined && `z-index: ${zIndex};`};
    ${({ overflow }) => overflow && `overflow: ${overflow}`};
    ${({ overflowX }) => overflowX && `overflow-x: ${overflowX}`};
    ${({ overflowY }) => overflowY && `overflow-x: ${overflowY}`};
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`};
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight};`};
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft};`};
    ${({ padding }) => padding && `padding: ${padding};`};
`;
