export const columns = [
    {
        title: 'USER ID',
        dataIndex: 'userId',
        key: 'userId',
        width: '30%'
    },
    {
        title: 'USER EMAIL',
        dataIndex: 'email',
        key: 'email',
        width: '30%'
    },
    {
        title: 'TYPE',
        dataIndex: 'role',
        key: 'role',
        width: '20%'
    },
    {
        title: 'LAST LOGIN',
        dataIndex: 'date',
        key: 'date',
        width: '20%'
    }
];
