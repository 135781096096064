import * as agent from './agent';
import * as inactiveUsers from './inactive-users';
import * as location from './location';
import * as playlist from './playlist';
import * as referralUsers from './referral-users';
import * as report from './report';
import * as reports from './reports';
import * as trendings from './trendings';
import * as user from './user';
import * as users from './users';
import * as video from './video';
import * as videos from './videos';

export const API = {
    referralUsers,
    trendings,
    inactiveUsers,
    reports,
    report,
    agent,
    user,
    videos,
    users,
    location,
    video,
    playlist
};
