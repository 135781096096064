import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Category } from 'v2/components/ui/Category';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { LanguageType, languages } from 'v2/constants/languages';
import { UserGender, UserRole, userRoleTexts } from 'v2/constants/services/users';
import { AgentProperty } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/AgentProperty';
import { authStores } from 'v2/stores/auth';
import { editUserRoleModal } from 'v2/stores/initialize-modals';
import { getAge } from 'v2/utils/datetime';
import { formatMoney } from 'v2/utils/formats';
import { AddressSection } from './AddressSection';
import { EditUserRoleModal } from './EditUserRoleModal';
import { GridItem, PropertiesGrid } from './styles';

interface Props {
    user: YEAY.AdminGetUserCommon;
    isSingleVideo?: boolean;
}

// TODO edit locale
export const UserAdditionalInfo: FC<Props> = ({ user, isSingleVideo = false }) => {
    const isSuperAdmin = useStore(authStores.$isSuperAdmin);
    const isAdmin = useStore(authStores.$isAdmin);

    const editRole = () => {
        if (user.id && user.roles) {
            editUserRoleModal.openModal({
                userId: user.id,
                roles: user.roles
            });
        }
    };

    return (
        <Section>
            <PropertiesGrid>
                <GridItem column={isSingleVideo ? '0' : '1/2'}>
                    <PropertyLayout textCopy={user.id || ''} title="User ID">
                        <Text isSemibold size="-2">
                            {user.id || '-'}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                {isSingleVideo && (
                    <GridItem column="0">
                        <PropertyLayout textCopy={user.vatId || ''} title="Tax ID">
                            <Text isSemibold size="-2">
                                {user.vatId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                )}

                {isAdmin && (
                    <GridItem noBorderRight column={isSingleVideo ? '0' : '2/4'}>
                        <AgentProperty user={user} />
                    </GridItem>
                )}

                {isSuperAdmin && (
                    <>
                        <GridItem column="1/2">
                            <PropertyLayout title="Phone">
                                <Text isSemibold size="-2">
                                    {user.mobileNumber || '-'}
                                </Text>
                            </PropertyLayout>
                        </GridItem>

                        <GridItem column="2/4">
                            <PropertyLayout title="Email">
                                <Text isSemibold size="-2">
                                    {user.email || '-'}
                                </Text>
                            </PropertyLayout>
                        </GridItem>
                    </>
                )}

                <GridItem column="1/2">
                    <PropertyLayout title="Age">
                        <Text isSemibold size="-2">
                            {getAge(user.dateOfBirth)}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem column="2/4">
                    <PropertyLayout title="Gender">
                        <Text isSemibold size="-2">
                            {user.gender !== undefined ? UserGender[user.gender] : '-'}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Locale">
                        <Text isSemibold size="-2">
                            {user.locale || '-'}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Region">
                        <Text isSemibold size="-2">
                            {user.location?.area?.region || '-'}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Country">
                        <Text isSemibold size="-2">
                            {user.location?.countryName || '-'}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <DateProperty date={user.utcCreated} title="Created" />
                </GridItem>

                <GridItem>
                    <DateProperty date={user.utcLastAuthentication} title="Last Logged In" />
                </GridItem>

                <GridItem>
                    <DateProperty date={user.utcUpdated} title="Last Updated" />
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Collaborations Completed">
                        <Text isSemibold size="-2">
                            {user.collaborationCompletedCount || 0}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Collaborations Incomplete">
                        <Text isSemibold size="-2">
                            {user.collaborationIncompleteCount || 0}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <GridItem>
                    <PropertyLayout title="Current Balance">
                        <Text isSemibold size="-2">
                            {formatMoney({ value: user.currentBalance })}
                        </Text>
                    </PropertyLayout>
                </GridItem>

                <AddressSection user={user} />

                <GridItem noBorderBottom column={!isSingleVideo ? '1/2' : '1/4'} noBorderRight={isSingleVideo}>
                    <PropertyLayout
                        extra={
                            isAdmin ? (
                                <Button buttonType="text-black" textSize="small" onClick={editRole}>
                                    Edit
                                </Button>
                            ) : undefined
                        }
                        title="Roles"
                    >
                        {user.roles
                            ? user.roles.map(role => (
                                  <MarginWrapper key={role} marginRight="4px" marginTop="4px">
                                      <Category text={userRoleTexts[role as UserRole] || role} />
                                  </MarginWrapper>
                              ))
                            : '-'}
                    </PropertyLayout>
                </GridItem>

                {!isSingleVideo && (
                    <GridItem noBorderBottom noBorderRight column="2/4">
                        <PropertyLayout title="Languages">
                            {user.languages && user.languages.length > 0
                                ? user.languages.map(language => (
                                      <Row key={language} marginRight="4px">
                                          <Category text={languages[language as LanguageType]} />
                                      </Row>
                                  ))
                                : '-'}
                        </PropertyLayout>
                    </GridItem>
                )}
            </PropertiesGrid>

            {isAdmin && <EditUserRoleModal />}
        </Section>
    );
};
