import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOption, SelectOptionValue } from 'v2/components/ui/selects/types';
import { collaborationsStateOptions } from 'v2/constants/services/collaborations';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    options?: SelectOption[];
    store: GenericCollaborationsType;
}

export const CollaborationState: FC<Props> = ({ options, store }) => {
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);

    const onChangeCollabState = (state: SelectOptionValue[]) => {
        store.events.overwriteFilters({
            states: state as number[]
        });
    };

    return (
        <MultiSelect
            options={options || collaborationsStateOptions}
            title="Collab State"
            values={collaborationsFilters?.states}
            onChange={onChangeCollabState}
        />
    );
};
