export const columns = [
    {
        title: 'USER',
        dataIndex: 'user',
        key: 'user',
        width: '30%'
    },
    {
        title: 'DOWNLOAD DATE',
        dataIndex: 'downloadDate',
        key: 'downloadDate',
        width: '35%'
    },
    {
        title: 'REGISTRATION DATE',
        dataIndex: 'registrationDate',
        key: 'registrationDate',
        width: '35%'
    }
];
