import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';

export const useQueryParams = <T extends {}>(callback: (queryParams: T) => void): [T, (params: T) => void] => {
    const location = useLocation();
    const history = useHistory();

    const [queryParams, setQueryParams] = useState<T>(
        (queryString.parse(location.search, {
            arrayFormat: 'index',
            parseNumbers: true,
            parseBooleans: true
        }) as unknown) as T
    );

    const setURLQueryParams = useCallback(
        (params: T) => {
            history.push({ search: queryString.stringify(params, { skipNull: true, arrayFormat: 'index' }) });
            setQueryParams(params);
        },
        [setQueryParams, history]
    );

    useEffect(() => {
        callback(queryParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [queryParams, setURLQueryParams];
};
