import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { reportedReason } from 'v2/constants/services/report';
import { VideoCurationReason } from 'v2/constants/services/videos';
import { LinkUserInfo } from 'v2/pages/Report/common/styles';

export const prepareDataForTable = (items: YEAY.ReportDetailResponse[]) =>
    items.map(item => ({
        key: item.id,
        user: (
            <LinkUserInfo to={`${routePaths.users}/${item.id}`}>
                <Row alignCenter noWrap>
                    {item.profileImageUrl && (
                        <Row marginRight="8px">
                            <Avatar size="32px" src={item.profileImageUrl || ''} />
                        </Row>
                    )}

                    <Text isSemibold size="-2">
                        @{item.username}
                    </Text>
                </Row>
            </LinkUserInfo>
        ),
        date: item.utcReported ? (
            <Section alignCenter>
                <DateProperty alignCenter date={item.utcReported} />
            </Section>
        ) : (
            '-'
        ),
        reason: item.reportedReason ? (
            <Text size="-1">{reportedReason[item.reportedReason as VideoCurationReason]}</Text>
        ) : (
            '-'
        )
    }));
