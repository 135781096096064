import styled, { css } from 'styled-components';
import { brandColors, textColors } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface StyledButtonProps {
    size?: string;
    color?: string;
    hoverColor?: string;
    rotate?: number;
    bgColor?: string;
    bgHoverColor?: string;
    rounded?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
    ${disableDefaultButtonStyleMixin};
    width: ${({ size }) => size || '24px'};
    height: ${({ size }) => size || '24px'};
    ${flexCenter};
    flex-shrink: 0;
    color: ${({ color }) => color || textColors.subtext};
    ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`};
    transition: color ${transitionTime} linear, background-color ${transitionTime} linear;
    ${({ bgColor, rounded }) =>
        bgColor &&
        css`
            background-color: ${bgColor};
            ${rounded && 'border-radius: 50px'};
        `};

    &:disabled {
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        color: ${({ hoverColor, color }) => hoverColor || color || brandColors.black};
        ${({ bgHoverColor }) => bgHoverColor && `background-color: ${bgHoverColor}`};
    }
`;
