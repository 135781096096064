export const columns = [
    {
        title: 'USER',
        dataIndex: 'user',
        key: 'user',
        width: '35%'
    },
    {
        title: 'AGENT VIDEOS CREATED',
        dataIndex: 'videosCount',
        key: 'videosCount',
        width: '35%'
    },
    {
        dataIndex: 'delete',
        key: 'delete',
        width: '30%'
    }
];
