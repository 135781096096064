import React from 'react';
import searchIcon from 'v1/assets/search_icon.svg';
import { SearchButtonWrapper } from 'v1/components/common/buttons/SearchButton/styles';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { Active } from 'v1/types/global';
import { ReactClick } from 'v1/types/react';

interface Props extends ReactClick<HTMLButtonElement>, Active {}

export const SearchButton = ({ active, ...props }: Props) => (
    <SearchButtonWrapper active={active} {...props}>
        <CustomImg alt="search button" height="16px" src={searchIcon} width="16px" />
    </SearchButtonWrapper>
);
