import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Pagination } from 'v2/components/ui/Pagination';
import { Table } from 'v2/components/ui/Table';
import { Column, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { engagementsEffects, engagementsEvents, engagementsStores } from 'v2/stores/collaboration/details/engagements';
import { Id } from 'v2/types/data';
import { columns } from './constants';
import { prepareDataForTable } from './utils';

const { getEngagementsFx } = engagementsEffects;
const { updateParams, resetEngagements } = engagementsEvents;

export const DropdownEngagements: FC<Id> = ({ id }) => {
    const engagements = useStore(engagementsStores.$engagements);

    const isLoading = useStore(getEngagementsFx.pending);
    const totalRecords = engagements?.totalCount || 0;

    const params = useStore(engagementsStores.$engagementsParams);
    const limit = params.limit;
    const currentPage = (params.pageIndex || 0) + 1;

    const tableData = prepareDataForTable(engagements?.items || []);

    const handlePageChange = (page: number) => {
        const index = page - 1;
        updateParams({ pageIndex: index >= 0 ? index : 0 });
    };

    const handleLimitChange = (size: number) => {
        updateParams({ limit: size });
    };

    useEffect(() => {
        getEngagementsFx({ collaborationId: id });

        return () => {
            resetEngagements();
        };
    }, [id]);

    return (
        <AccordionSection title="Engagement">
            <Column noWrap width="100%">
                <Table
                    cellCenter
                    bottomExtra={
                        <SectionBorder borderTop padding="0 8px">
                            <Pagination
                                currentPage={currentPage}
                                size={limit}
                                totalItems={totalRecords}
                                onChange={handlePageChange}
                                onPageSizeChange={handleLimitChange}
                            />
                        </SectionBorder>
                    }
                    columns={columns}
                    data={tableData}
                    emptyMessage="No engagements Found"
                    isLoading={isLoading}
                />
            </Column>
        </AccordionSection>
    );
};
