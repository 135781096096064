/**
 * Return print category
 * @param categoryDetails
 * @param fallback
 * @returns category
 */
export const getCategoryForOutput = (
    categoryDetails?: YEAY.GetTrendingCategoryResponse | null,
    fallback?: string | null
) => {
    if (!categoryDetails || !categoryDetails.translations || categoryDetails.translations.length === 0) {
        return fallback || '';
    }

    const categoryName = categoryDetails.translations[0].displayName || '';
    const categoryEmoji = categoryDetails.emojiCode || '';

    return `${categoryEmoji}${categoryName}`;
};
