import { useMemo } from 'react';
import { PaginationProps } from 'v2/components/ui/Pagination';
import { defaultPageSize, defaultSiblingCount, previewDots } from 'v2/components/ui/Pagination/constants';

const range = (start: number, end: number) => {
    const length = end - start + 1;

    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
    currentPage = 0,
    totalItems = 0,
    totalPages = 0,
    size = defaultPageSize,
    siblingCount = defaultSiblingCount
}: Pick<PaginationProps, 'currentPage' | 'totalItems' | 'totalPages' | 'size' | 'siblingCount'>) => {
    const totalPageCount = useMemo(() => {
        if (totalPages) return totalPages;
        if (totalItems === 0) return 0;

        return Math.ceil(totalItems / size);
    }, [size, totalItems, totalPages]);

    const paginationRange = useMemo(() => {
        if (totalPageCount === 0) return [];

        const totalPageNumbers = siblingCount + 5;

        // Case 1:
        // If the number of pages is less than the page numbers we want to show in our
        // paginationComponent, we return the range [1..totalPageCount]
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

        // We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
        // Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        // Case 2: No left dots to show, but rights dots to be shown
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);

            return [...leftRange, previewDots, totalPageCount];
        }

        // Case 3: No right dots to show, but left dots to be shown
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, previewDots, ...rightRange];
        }

        // Case 4: Both left and right dots to be shown
        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, previewDots, ...middleRange, previewDots, lastPageIndex];
        }

        // Default
        return [];
    }, [siblingCount, totalPageCount, currentPage]);

    // Get start index items on current page
    const resultsStart = useMemo(() => {
        if (totalItems === 0) return 0;

        return currentPage * size - size + 1;
    }, [currentPage, size, totalItems]);

    // Get end index items on current page
    const resultsEnd = useMemo(() => {
        if (totalItems === 0) return 0;

        const result = currentPage * size;
        if (result > totalItems) return totalItems;
        return result;
    }, [currentPage, size, totalItems]);

    return { totalPageCount, pagination: paginationRange, start: resultsStart, end: resultsEnd };
};
