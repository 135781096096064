export const transactionsTableColumns = [
    {
        title: 'TRANSACTION ID',
        dataIndex: 'id',
        key: 'id',
        width: '20%'
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        width: '20%'
    },
    {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
        width: '20%'
    },
    {
        title: 'TIME',
        dataIndex: 'time',
        key: 'time',
        width: '20%'
    },
    {
        title: 'TYPE',
        dataIndex: 'type',
        key: 'type',
        width: '20%'
    }
];

export const transactionLabels: Record<YecmPaths.AdminTransactions.Get.Parameters.TransactionType, string> = {
    '0': 'None',
    '10': 'PayPal Deposit',
    '20': 'Withdrawal To Mint',
    '21': 'PayPal Fee Burn To Mint',
    '30': 'Organization Funding',
    '40': 'Collaboration Funding',
    '45': 'Collaboration Escrow Funding',
    '50': 'Service Fee Revenue',
    '60': 'PayPal Withdrawal Fee Revenue',
    '70': 'Collaboration Video Reward',
    '80': 'Collaboration Social Post Reward',
    '90': 'PayPal Withdrawal Escrow Transfer',
    '100': 'Agent Reward',
    '110': 'Manual',
    '120': 'Collaboration Remaining Funds Transfer To Organization',
    '130': 'Collaboration Wallet To Escrow Wallet Transfer',
    '140': 'Collaboration Escrow Wallet To Organization Transfer',
    '150': 'Collaboration Escrow Vat Transfer'
};
