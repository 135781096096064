import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const TitleWrapper = styled(SectionBorder)`
    border-radius: 4px 4px 0px 0px;
    background-color: ${brandColors.white};
`;

export const Username = styled(Text)`
    margin-left: 8px;
`;

export const UsernameWrapper = styled(Link)`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: all ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};

        ${Username} {
            text-decoration: underline;
        }
    }
`;
