import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { LanguageIcon } from 'v2/assets/icons';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOptionValue } from 'v2/components/ui/selects/types';
import { LanguageType, languages } from 'v2/constants/languages';
import { targetLanguageEffects, targetLanguageStores } from 'v2/stores/target/languages';

export const LanguageFilter: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { addFilters } = domain.events;

    const filters = useStore($videosFilters);
    const langCodes = useStore(targetLanguageStores.$targetLanguages);

    const languageOptions = langCodes.map(langCode => ({
        label: languages[langCode as LanguageType] || langCode,
        value: langCode
    }));

    const handleChange = (values: SelectOptionValue[]) => {
        addFilters({
            languages: values as string[]
        });
    };

    useEffect(() => {
        if (langCodes.length === 0) {
            targetLanguageEffects.getTargetLanguagesFx();
        }
    }, [langCodes]);

    if (languageOptions.length === 0) {
        return null;
    }

    return (
        <MultiSelect
            wide
            icon={<LanguageIcon />}
            options={languageOptions}
            placement="bottom-end"
            values={filters.languages || undefined}
            onChange={handleChange}
        />
    );
};
