import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Tooltip } from 'v2/components/ui/Tooltip';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { shippingsEffects, shippingsStores } from 'v2/stores/collaboration/details/shippings';
import { Id } from 'v2/types/data';
import { ViewAddressInfoModal } from './AddressInfoModal';
import { DownloadButton } from './DownloadButton';
import { columns } from './constants';
import { prepareDataForTable } from './utils';

const { getShippingsFx } = shippingsEffects;

export const Shippings: FC<Id> = ({ id }) => {
    const isLoading = useStore(getShippingsFx.pending);

    const shippings = useStore(shippingsStores.$productShippings);
    const items = useStore(shippingsStores.$shippingsItems);
    const currentPageIndex = useStore(shippingsStores.$shippingsCurrentPage);
    const hasNext = useStore(shippingsStores.$shippingsHasNext);

    const shippingsCount = shippings?.totalCount;

    const tableData = prepareDataForTable(items || []);

    const loadMore = () => {
        if (id && hasNext) {
            getShippingsFx({ collaborationId: id, pageIndex: currentPageIndex + 1 });
        }
    };

    useEffect(() => {
        if (id) {
            shippingsEffects.getShippingsFx({ collaborationId: id });
        }
    }, [id]);

    return (
        <>
            <Table
                cellCenter
                bottomExtra={hasNext ? <LoadMoreSection onClick={loadMore} /> : undefined}
                columns={columns}
                data={tableData}
                emptyMessage="No Shippings Found"
                isLoading={isLoading}
                isTextNoWrap={false}
                topExtra={
                    <SectionBorder alignCenter borderBottom padding="14px 16px">
                        <Title size="-2">{`Product Shipping (${shippingsCount})`}</Title>
                        <Tooltip
                            maxWidth="116px"
                            overlay={
                                <Text color={brandColors.white} size="-2">
                                    Download as CSV
                                </Text>
                            }
                            placement="topLeft"
                        >
                            <Row marginLeft="8px">
                                <DownloadButton collaborationId={id} />
                            </Row>
                        </Tooltip>
                    </SectionBorder>
                }
            />
            <ViewAddressInfoModal />
        </>
    );
};
