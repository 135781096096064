import React, { FC } from 'react';
import { DraggableProps } from 'v2/types/react';
import { Noop } from 'v2/types/types';
import { CardLayout } from '../CardLayout';
import { Background } from './styles';

interface Props extends DraggableProps {
    positionIndex?: number;
    video?: YEAY.GetTrendVideosResponse;
    draggable?: boolean;
    onInfoClick?: Noop;
    onDeleteClick?: Noop;
}

export const VideoCard: FC<Props> = ({ video, positionIndex, onInfoClick, onDeleteClick, ...draggableProps }) => (
    <CardLayout showActions positionIndex={positionIndex} onDeleteClick={onDeleteClick} onInfoClick={onInfoClick}>
        <Background src={video?.thumbnailUrl || ''} {...draggableProps} />
    </CardLayout>
);
