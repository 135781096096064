import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';

import { DownloadIcon } from 'v2/assets/icons';
import { notify } from 'v2/components/ui/Toasts';
import { engagementsEffects, engagementsStores } from 'v2/stores/collaboration/details/engagements';
import { getFormattedDate } from 'v2/utils/datetime';
import { StyledLink } from './styles';

const { downloadCollaborationEngagementsFx } = engagementsEffects;

interface Props {
    collaborationId: string;
}

export const DownloadEngagementButton: FC<Props> = ({ collaborationId }) => {
    const engagementsResponse = useStore(engagementsStores.$engagements);
    const items = engagementsResponse?.items || [];

    const downloadData = useStore(engagementsStores.$downloadedEngagements);

    const fileName = `engagement-${collaborationId}-${getFormattedDate(new Date().toISOString())}.csv`;

    const isDisabled = items.length === 0;

    const handleClick = () => {
        if (isDisabled) {
            return false;
        }

        notify('Successfully downloaded');
    };

    useEffect(() => {
        downloadCollaborationEngagementsFx({ collaborationId });
    }, [collaborationId]);

    return (
        <StyledLink
            data={downloadData || ''}
            disabled={isDisabled}
            enclosingCharacter={`'`}
            filename={fileName}
            onClick={handleClick}
        >
            <DownloadIcon />
        </StyledLink>
    );
};
