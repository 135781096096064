import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { useQueryParams } from 'v2/hooks/use-query-params';
import { TransactionsDate } from 'v2/pages/Transactions/TransactionsFilters/TransactionsDate';
import { TransactionsSearch } from 'v2/pages/Transactions/TransactionsFilters/TransactionsSearch';
import { TransactionsType } from 'v2/pages/Transactions/TransactionsFilters/TransactionsType';
import { transactionsEvents, transactionsStores } from 'v2/stores/transactions';
import { FiltersWrapper } from './styles';

export const TransactionsFilters = () => {
    const transactionsFilters = useStore(transactionsStores.$transactionsFilters);
    const [, setQueryParams] = useQueryParams((params: Partial<YecmPaths.AdminTransactions.Get.QueryParameters>) => {
        transactionsEvents.setOverwriteFilters(params);
    });

    useEffect(() => {
        setQueryParams(transactionsFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionsFilters]);

    const handleReset = () => {
        transactionsEvents.setDefaultFilters();
    };

    return (
        <FiltersWrapper>
            <SectionBorder borderBottom padding="34px 16px" />
            <SectionBorder borderBottom noWrap padding="12px 16px">
                <Section maxWidth="500px">
                    <TransactionsSearch />
                </Section>
            </SectionBorder>
            <SectionBorder borderBottom justifyBetween noWrap padding="12px 16px">
                <Row alignCenter noWrap>
                    <Row marginRight="24px">
                        <TransactionsType />
                    </Row>
                    <TransactionsDate />
                </Row>
                <Button buttonType="text-black" onClick={handleReset}>
                    Reset
                </Button>
            </SectionBorder>
        </FiltersWrapper>
    );
};
