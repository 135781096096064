import React, { FC } from 'react';
import { Video } from 'v2/components/ui/Video';
import { videoModal } from 'v2/stores/initialize-modals';
import { Noop } from 'v2/types/types';
import { CardWrapper } from './styles';

interface Props {
    data: YEAY.AdminGetVideoResponse;
    selected?: boolean;
    disabled?: boolean;
    onClick?: Noop;
}

export const VideoCard: FC<Props> = ({ data, selected, disabled, onClick }) => {
    const { id, streaming } = data;

    const openFullscreen = () => {
        if (id) {
            videoModal.openModal(id);
        }
    };

    return (
        <CardWrapper disabled={disabled} selected={selected} onClick={onClick}>
            <Video
                cover
                posterSrc={streaming?.details?.screenGrabUrl}
                videoSrc={streaming?.details?.hlsUrl}
                onFullscreen={openFullscreen}
            />
        </CardWrapper>
    );
};
