import { VideoCurationReason } from 'v2/constants/services/videos';

export type ReportedReasonType = Record<VideoCurationReason, string>;

export const reportedReason: ReportedReasonType = {
    [VideoCurationReason.None]: 'None',
    [VideoCurationReason.DangerousActsAndChallenges]: 'Dangerous acts and challenges',
    [VideoCurationReason.ViolentAndGraphicContent]: 'Violent and graphic content',
    [VideoCurationReason.BullyingAndHarassment]: 'Bullying and harassment',
    [VideoCurationReason.IntellectualPropertyInfringement]: 'Intellectual property infringement',
    [VideoCurationReason.NudityAndSexualActivities]: 'Nudity and sexual activities',
    [VideoCurationReason.Other]: 'Other'
};

export const reportStatus = {
    review: 'Review',
    accepted: 'Accepted',
    rejected: 'Rejected'
};

export const defaultReportsListParams: Paths.ReportsReportList.Get.QueryParameters = {
    pageIndex: 0,
    limit: 5,
    count: true
};
