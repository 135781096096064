import styled from 'styled-components';
import { IsClosed } from 'v2/types/data';
import { AbsoluteLocation, Sizes, ZIndex } from 'v2/types/styles';

export interface AbsoluteWrapperProps extends AbsoluteLocation, IsClosed, Sizes, ZIndex {}

export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
    position: absolute;
    ${({ top, bottom }) => (top ? `top: ${top}` : bottom ? `bottom: ${bottom}` : `top: 10px`)};
    ${({ left, right }) => (left ? `left: ${left}` : right ? `right: ${right}` : `left: 10px`)};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    display: ${({ isClosed }) => (isClosed ? 'none' : 'block')};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;

export const AbsoluteCenterAlignment = styled.div<ZIndex>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;
