import { useState } from 'react';

export const useDragItems = (onDragEnded: (fromIndex: number, toIndex: number) => void) => {
    const [firstPosition, setFirstPosition] = useState<number | undefined>();
    const [secondPosition, setSecondPosition] = useState<number | undefined>();

    const onDragStart = (newPosition: number) => {
        setFirstPosition(newPosition);
    };

    const onDragEnd = () => {
        if (firstPosition !== secondPosition && firstPosition !== undefined && secondPosition !== undefined) {
            onDragEnded(firstPosition, secondPosition);
        }
        setFirstPosition(undefined);
        setSecondPosition(undefined);
    };

    const onDragOver = (newPosition: number) => {
        if (firstPosition !== undefined) {
            setSecondPosition(newPosition);
        }
    };

    return { onDragStart, onDragEnd, onDragOver, firstPosition, secondPosition };
};
