// TODO remove deprecated pages/components

import 'v2/assets/styles/global.css';
import 'v2/stores/init';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AsyncModal } from 'v1/components/modals/AsyncModal';
import { NotificationModal } from 'v1/components/modals/Notification';
import { Blacklisted } from 'v1/pages/Blacklisted';
import { BlockedUsers } from 'v1/pages/BlockedUsers';
import { BlockedUser } from 'v1/pages/BlockedUsers/BlockedUser';
import { DeletedUsers } from 'v1/pages/DeletedUsers';
import { DeletedUser } from 'v1/pages/DeletedUsers/DeletedUser';
import { Statistics } from 'v1/pages/Statistics';
import { ScrollToTopButton } from 'v2/components/common/ScrollToTopButton';
import { AppModals } from 'v2/components/modals/AppModals';
import { routePaths } from 'v2/constants/routes';
import { UserRole } from 'v2/constants/services/users';
import { GlobalStyle } from 'v2/constants/styles/global';
import { useAppStart } from 'v2/hooks/use-app-start';
import { usePathChange } from 'v2/hooks/use-path-change';
import { Brand } from 'v2/pages/Brand';
import { Brands } from 'v2/pages/Brands';
import { Collaboration } from 'v2/pages/Collaboration';
import { Collaborations } from 'v2/pages/Collaborations';
import { Error } from 'v2/pages/Error';
import { InactiveUsers } from 'v2/pages/InactiveUsers';
import { Login } from 'v2/pages/Login';
import { ReportSingleAccount } from 'v2/pages/Report/ReportSingleAccount';
import { ReportSingleCollaboration } from 'v2/pages/Report/ReportSingleCollaboration';
import { ReportSingleVideo } from 'v2/pages/Report/ReportSingleVideo';
import { Reports } from 'v2/pages/Reports';
import { Transactions } from 'v2/pages/Transactions';
import { Trendings } from 'v2/pages/Trendings';
import { User } from 'v2/pages/User';
import { Users } from 'v2/pages/Users';
import { Video } from 'v2/pages/Video';
import { Videos } from 'v2/pages/Videos';
import { PrivateRoute } from 'v2/routes/PrivateRoute';
import { PublicRoute } from 'v2/routes/PublicRoute';

const App = () => {
    useAppStart();
    usePathChange();

    return (
        <>
            {/* global */}

            <GlobalStyle />
            <ScrollToTopButton />

            {/* modals */}

            <NotificationModal />
            <AppModals />
            <AsyncModal />

            <Switch>
                {/* public */}

                <PublicRoute exact component={Login} path={routePaths.login} />

                {/* private */}

                <PrivateRoute
                    exact
                    component={Videos}
                    path={routePaths.videos}
                    roles={[
                        UserRole.SuperAdministrator,
                        UserRole.Administrator,
                        UserRole.Curator,
                        UserRole.ContentManager
                    ]}
                />
                <PrivateRoute
                    exact
                    component={Video}
                    path={routePaths.video}
                    roles={[
                        UserRole.SuperAdministrator,
                        UserRole.Administrator,
                        UserRole.Curator,
                        UserRole.ContentManager
                    ]}
                />

                <PrivateRoute
                    exact
                    component={Users}
                    path={routePaths.users}
                    roles={[UserRole.SuperAdministrator, UserRole.Administrator, UserRole.ContentManager]}
                />
                <PrivateRoute
                    exact
                    component={User}
                    path={routePaths.user}
                    roles={[UserRole.SuperAdministrator, UserRole.Administrator, UserRole.ContentManager]}
                />

                {/* admin */}

                <PrivateRoute exact component={Transactions} path={routePaths.transactions} />
                <PrivateRoute exact component={Collaborations} path={routePaths.collaborations} />
                <PrivateRoute exact component={Collaboration} path={routePaths.collaboration} />
                <PrivateRoute exact component={Brands} path={routePaths.brands} />
                <PrivateRoute exact component={Brand} path={routePaths.brand} />
                <PrivateRoute exact component={Reports} path={routePaths.reports} />
                <PrivateRoute exact component={ReportSingleVideo} path={routePaths.reportVideo} />
                <PrivateRoute exact component={ReportSingleAccount} path={routePaths.reportAccount} />
                <PrivateRoute exact component={ReportSingleCollaboration} path={routePaths.reportCollaboration} />
                <PrivateRoute exact component={InactiveUsers} path={routePaths.inactive} />
                <PrivateRoute exact component={Trendings} path={routePaths.trending} />

                <PrivateRoute exact component={Statistics} path={routePaths.registeredEvents} />
                <PrivateRoute exact component={Blacklisted} path={routePaths.blacklistedUsers} />
                <PrivateRoute exact component={DeletedUsers} path={routePaths.deletedUsers} />
                <PrivateRoute exact component={DeletedUser} path={routePaths.deletedUser} />
                <PrivateRoute exact component={BlockedUsers} path={routePaths.blockedUsers} />
                <PrivateRoute exact component={BlockedUser} path={routePaths.blockedUser} />

                {/* redirects */}

                <Route exact path={routePaths.main} render={() => <Redirect to={routePaths.login} />} />

                {/* error pages */}

                <Route exact path={routePaths.error401} render={() => <Error status={401} />} />
                <Route exact path={routePaths.error403} render={() => <Error status={403} />} />
                <Route path="*" render={() => <Error status={404} />} />
            </Switch>
        </>
    );
};

export default App;
