import styled from 'styled-components';
import { mobileHeaderHeight } from 'v2/components/grid/Header/styles';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';
import { media } from 'v2/constants/styles/media';
import { sideBarWidth } from 'v2/constants/styles/sizes';

export const Container = styled(Column)`
    width: 100%;
    min-height: 100%;
    flex-wrap: nowrap;
    background-color: ${grey[200]};
    padding-left: 0;
    padding-right: 0;
    padding-top: ${mobileHeaderHeight};

    ${media.laptopSmall(`
        padding-top: 0;
        padding-left: ${sideBarWidth};
        padding-bottom: 30px;
    `)};
`;
