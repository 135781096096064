import React, { FC } from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { CollaborationStatusStringKey } from 'v2/constants/services/collaborations';
import { formatMoney } from 'v2/utils/formats';
import { CardLink, StatesGrid } from './styles';

interface Props {
    data: YECM.OrganizationResponse;
}

export const BrandCard: FC<Props> = ({ data }) => {
    const {
        organizationId,
        profileImageUrl,
        title,
        websiteLink,
        utcCreated,
        collaborationStates,
        totalSpent,
        balance,
        valueInEscrow
    } = data;

    return (
        <CardLink to={`${routePaths.brands}/${organizationId}`}>
            <SectionBorder alignCenter borderBottom padding="8px">
                <Avatar size="32px" src={profileImageUrl || ''} />
                <Column marginLeft="8px">
                    <Text isSemibold size="-1">
                        {title}
                    </Text>
                </Column>
            </SectionBorder>

            <SectionBorder borderBottom padding="6px 0">
                <PropertyLayout direction="row" textCopy={organizationId} title="Brand ID">
                    <Text isSemibold size="-2">
                        {organizationId || '-'}
                    </Text>
                </PropertyLayout>
            </SectionBorder>

            <SectionBorder borderBottom padding="6px 0">
                <PropertyLayout direction="row" textCopy={websiteLink || ''} title="Website">
                    <Text isSemibold size="-2">
                        {websiteLink || '-'}
                    </Text>
                </PropertyLayout>
            </SectionBorder>

            <SectionBorder borderBottom>
                <StatesGrid>
                    <SectionBorder borderRight padding="0 4px">
                        <PropertyLayout title="Balance">
                            <Text isSemibold size="-2">
                                {formatMoney({ value: balance })}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderRight padding="0 4px">
                        <PropertyLayout title="In Escrow">
                            <Text isSemibold size="-2">
                                {formatMoney({ value: valueInEscrow })}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder padding="0 4px">
                        <PropertyLayout title="Total Spent">
                            <Text isSemibold size="-2">
                                {formatMoney({ value: totalSpent })}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                </StatesGrid>
            </SectionBorder>

            <SectionBorder borderBottom>
                <StatesGrid>
                    <SectionBorder borderRight padding="0 4px">
                        <PropertyLayout title="Draft">
                            <Text isSemibold size="-2">
                                {collaborationStates?.[CollaborationStatusStringKey.Draft] || 0}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderRight padding="0 4px">
                        <PropertyLayout title="Live">
                            <Text isSemibold size="-2">
                                {collaborationStates?.[CollaborationStatusStringKey.ActiveOpen] || 0}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder padding="0 4px">
                        <PropertyLayout title="Ended">
                            <Text isSemibold size="-2">
                                {collaborationStates?.[CollaborationStatusStringKey.Ended] || 0}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                </StatesGrid>
            </SectionBorder>

            <SectionBorder borderBottom>
                <DateProperty date={utcCreated} direction="row" title="Created" />
            </SectionBorder>
        </CardLink>
    );
};
