import { forward } from 'effector';
import { loaderEffects } from 'v2/stores/loader';
import { trendingVideosEffects } from 'v2/stores/trendings/videos';

const {
    getTrendingVideosFx,
    deleteVideoFromTrendingFx,
    swapVideosFx,
    deleteVideosFromTrendingFx,
    addVideosToTrendingFx
} = trendingVideosEffects;

forward({
    from: [
        deleteVideoFromTrendingFx.pending,
        deleteVideosFromTrendingFx.pending,
        addVideosToTrendingFx.pending,
        swapVideosFx.pending
    ],
    to: loaderEffects.loaderFx
});

forward({
    from: addVideosToTrendingFx.done,
    to: getTrendingVideosFx
});
