import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';

export const StickyWrapper = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    background-color: ${brandColors.white};
    z-index: 1;
`;
