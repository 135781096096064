import { useStore } from 'effector-react';
import React from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { mainLoader } from 'v2/stores/initialize-modals';
import { MainLoaderWrapper } from './styles';

const { $modal } = mainLoader;

export const MainLoader = () => {
    const { visible } = useStore($modal);

    if (!visible) {
        return null;
    }

    return (
        <MainLoaderWrapper>
            <Spinner size={32} />
        </MainLoaderWrapper>
    );
};
