import { useStore } from 'effector-react';
import React from 'react';
import { InputCommission } from 'v2/components/modals/AddEditCommissionModal/InputCommission';
import { CommissionFormValues } from 'v2/components/modals/AddEditCommissionModal/InputCommission/validation';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { agentEffects } from 'v2/stores/agent';
import { addEditCommissionModal } from 'v2/stores/initialize-modals';

export const AddEditCommissionModal = () => {
    const { visible } = useStore(addEditCommissionModal.$modal);
    const state = useStore(addEditCommissionModal.$state);

    const handleCloseModal = () => {
        addEditCommissionModal.closeModal();
    };

    const handleCommission = (stateCommission: CommissionFormValues) => {
        if (stateCommission?.commission && state.id) {
            agentEffects.updateAgentInfoFx({
                data: {
                    earningFee: +stateCommission.commission / 100
                },
                agentId: state.id
            });
        }
        handleCloseModal();
    };

    return (
        <ModalWrapper visible={visible} onClose={handleCloseModal}>
            <InputCommission
                commission={state.commission?.toString()}
                handleChangeCommission={handleCommission}
                handleCloseModal={handleCloseModal}
                isEdit={state.isEdit}
            />
        </ModalWrapper>
    );
};
