import axios from './axios';

export const getReferralInvitedUsers = (
    referralId: string,
    params: Paths.AdminReferrals$ReferralId.Get.QueryParameters
) =>
    axios<YEAY.GetReferralsDetailedResponseApiResponse>({
        url: `/admin/referrals/${referralId}`,
        method: 'GET',
        params
    });
