import { requiredMessage } from 'v2/constants/messages';
import * as Yup from 'yup';

export const yupDefault = Yup.string().required(requiredMessage);

export const yupHttpsUrl = Yup.string()
    .matches(
        // eslint-disable-next-line no-useless-escape
        /https:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}/,
        'Invalid URL'
    )
    .required(requiredMessage);
