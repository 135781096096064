import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { noop } from 'v2/constants/functions';
import { ParticipationStatesKey } from 'v2/constants/services/enrollments';
import { collaborationStores } from 'v2/stores/collaboration';
import { collaborationEnrollmentsEffects, collaborationEnrollmentsStores } from 'v2/stores/collaboration/enrollments';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { getEnrollmentStatuses } from 'v2/utils/enrollments';
import { DeadlineCell } from './DeadlineCell';
import { StatusIcon } from './StatusIcon';
import { UserCell } from './UserCell';
import { getEnrollmentTableColumns } from './utils';

const { $collaborationInfo } = collaborationStores;
const { getEnrollmentsFx, removeEnrollmentFx } = collaborationEnrollmentsEffects;
const { $enrollmentsItems, $enrollmentsCurrentPage, $enrollmentsHasNext } = collaborationEnrollmentsStores;

export const Enrollments = () => {
    const { data: collaboration } = useStore($collaborationInfo);
    const collaborationId = collaboration?.id;

    const isLoading = useStore(getEnrollmentsFx.pending);
    const items = useStore($enrollmentsItems);
    const currentPageIndex = useStore($enrollmentsCurrentPage);
    const hasNext = useStore($enrollmentsHasNext);

    const removeUser = (userId?: string) => () => {
        if (!userId || !collaborationId) {
            return;
        }

        confirmEvents.openConfirm({
            title: 'Remove User',
            description: 'Are you sure you want to remove this user from the collaboration?',
            cancelButtonText: 'Cancel',
            cancelCallback: noop,
            okButtonText: 'Remove User',
            okCallback: () => removeEnrollmentFx({ userId, collaborationId })
        });
    };

    const tableData = (() =>
        items
            .filter(i => (i.participationState || 0) > ParticipationStatesKey.EnrollmentRequested)
            .map(item => {
                const { participationStatus, submissionStatus, sharingStatus } = getEnrollmentStatuses(
                    item,
                    collaboration
                );

                return {
                    key: item.id,
                    username: <UserCell user={item.creatorProfile} />,
                    timeLeft: <DeadlineCell data={item} />,
                    status: <Section justifyCenter>{participationStatus}</Section>,
                    video: <Section justifyCenter>{submissionStatus}</Section>,
                    post: <Section justifyCenter>{sharingStatus}</Section>,
                    action:
                        (item?.participationState || 0) <= ParticipationStatesKey.Enrolled ? (
                            <Section justifyCenter>
                                <Button buttonType="text-red" textSize="small" onClick={removeUser(item.participantId)}>
                                    Remove
                                </Button>
                            </Section>
                        ) : (
                            <StatusIcon state="none" />
                        )
                };
            }))();
    const columns = getEnrollmentTableColumns(collaboration?.networks);

    const loadMore = () => {
        if (collaborationId && hasNext) {
            getEnrollmentsFx({ collaborationId, pageIndex: currentPageIndex + 1 });
        }
    };

    useEffect(() => {
        if (collaborationId) {
            getEnrollmentsFx({ collaborationId });
        }
    }, [collaborationId]);

    return (
        <Table
            cellCenter
            bottomExtra={hasNext ? <LoadMoreSection onClick={loadMore} /> : undefined}
            columns={columns}
            data={tableData}
            emptyMessage="No Enrollments Found"
            isLoading={isLoading}
            topExtra={
                <SectionBorder borderBottom padding="14px 16px">
                    <Title size="-2">Enrollments</Title>
                </SectionBorder>
            }
        />
    );
};
