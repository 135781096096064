import { useStore } from 'effector-react';
import React from 'react';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { appReportsEvents, appReportsStores } from 'v2/stores/reports/app';
import { ReportSearchType } from '../../constants';

const { setOverwriteFilters } = appReportsEvents;

export const ReportSearch = () => {
    const filters = useStore(appReportsStores.$appReportsFilters);

    const searchItems = [
        {
            option: {
                value: ReportSearchType.ReportId,
                label: 'Report ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportSearchType.ReportId
                })
        },
        {
            option: {
                value: ReportSearchType.VideoId,
                label: 'Video ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportSearchType.VideoId
                })
        },
        {
            option: {
                value: ReportSearchType.AccountId,
                label: 'User ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportSearchType.AccountId
                })
        },
        {
            option: {
                value: ReportSearchType.CollabId,
                label: 'Collab ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportSearchType.CollabId
                })
        }
    ];

    return <SearchInputSelect defaultSearchText={filters?.SearchById} items={searchItems} />;
};
