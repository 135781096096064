import { SelectorsItem } from './types';

export const findItemOfSelector = (selectors: SelectorsItem[], searchValue: string): SelectorsItem | null => {
    if (selectors.length === 0 || !searchValue) {
        return null;
    }

    for (let i = 0; i < selectors.length; i++) {
        if (selectors[i].value.trim().toLowerCase() === searchValue.trim().toLowerCase()) {
            return selectors[i];
        }

        const nestedSelectors = selectors[i].nestedSelectors;
        if (nestedSelectors && nestedSelectors.length > 0) {
            const foundedItem = findItemOfSelector(nestedSelectors, searchValue);

            if (foundedItem) {
                return foundedItem;
            }
        }
    }

    return null;
};

export const findItemsByString = (selectors: SelectorsItem[], searchValue: string): SelectorsItem[] =>
    selectors.filter(item => {
        const searchQuery = searchValue.toLowerCase().trim();

        return (
            item.value.toLowerCase().includes(searchQuery) ||
            (item.title && item.title.toLowerCase().includes(searchQuery))
        );
    });
