import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { defaultDownloadEngagementsParams, defaultEngagementsQueryParams } from 'v2/constants/services/engagements';
import { yecmAPI } from 'v2/services/yecm';

// Types

export type EngagementsParams = Partial<YecmPaths.CollaborationsEngagements.Get.QueryParameters>;

//Events

const updateParams = createEvent<EngagementsParams>();

const resetEngagements = createEvent();
const resetParams = createEvent();

//Effects
const getEngagementsFx = createEffect({
    handler: async (params?: EngagementsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.collaboration.getCollaborationEngagements({
                ...defaultEngagementsQueryParams,
                ...params
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (error) {
            notify('There was a problem receiving engagements, try to refresh the page.', 'error');
            return null;
        }
    }
});

const downloadCollaborationEngagementsFx = createEffect({
    handler: async (params: YecmPaths.CollaborationsEngagementsDownload.Get.QueryParameters) =>
        await yecmAPI.collaboration.downloadCollaborationEngagements({
            ...defaultDownloadEngagementsParams,
            ...params
        })
});

//Stores

const $engagements = createStore<YECM.MediaAssetsResponse | null>(null)
    .on(getEngagementsFx.doneData, (_, payload) => payload)
    .reset(resetEngagements);

const $engagementsParams = createStore<EngagementsParams>(defaultEngagementsQueryParams)
    .on(updateParams, (state, params) => ({
        ...defaultEngagementsQueryParams,
        ...state,
        ...params
    }))
    .reset(resetParams);

const $downloadedEngagements = createStore<string>('').on(
    downloadCollaborationEngagementsFx.doneData,
    (_, payload) => payload
);

//Exports
export const engagementsEffects = {
    getEngagementsFx,
    downloadCollaborationEngagementsFx
};

export const engagementsStores = {
    $engagements,
    $engagementsParams,
    $downloadedEngagements
};

export const engagementsEvents = {
    resetEngagements,
    resetParams,
    updateParams
};
