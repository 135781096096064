import styled from 'styled-components';
import { padding } from 'v1/constants/styles/sizes';
import { IsClosed } from 'v1/types/data';
import { AbsoluteLocation, Sizes, ZIndex } from 'v1/types/styles';

export interface AbsoluteWrapperProps extends AbsoluteLocation, IsClosed, Sizes, ZIndex {}

export const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
    position: absolute;
    ${({ top, bottom }) => (top ? `top: ${top}` : bottom ? `bottom: ${bottom}` : `top: ${padding}`)};
    ${({ left, right }) => (left ? `left: ${left}` : right ? `right: ${right}` : `left: ${padding}`)};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    display: ${({ isClosed }) => (isClosed ? 'none' : 'block')};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
`;
