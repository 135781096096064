import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ButtonAlignType, ButtonType, TextButtonType } from 'v2/components/ui/buttons/Button';
import { brandColors, green, grey, red, textColors } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

// Types

interface Props {
    buttontype?: ButtonType | TextButtonType;
    wide?: number;
    align?: ButtonAlignType;
    nopadding?: number;
    istext?: number;
}

// Base styles

const commonStyles = css`
    display: flex;
    align-items: center;
    text-align: center;
    transition: all ${transitionTime} linear;
`;

const baseStyles = css`
    ${commonStyles};
    padding: 6px 12px;
    color: ${textColors.main};
    border-radius: 50px;

    &:disabled {
        background: none;
        border: 1px solid ${brandColors.black};
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const baseTextStyles = css`
    ${commonStyles};
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;

    &:disabled {
        background: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const getBaseStyles = (props: Props) => {
    if (!!props.istext) {
        return baseTextStyles;
    }

    return baseStyles;
};

// Type styles

const primaryTypeStyles = css`
    background-color: ${brandColors.green};
    border: 1px solid transparent;

    &:hover:not(:disabled) {
        color: ${textColors.main};
        background-color: ${green[300]};
    }

    &:active:not(:disabled) {
        background-color: ${green[700]};
    }
`;

const secondaryTypeStyles = css`
    background: none;
    border: 1px solid ${brandColors.black};

    &:hover:not(:disabled) {
        color: ${textColors.main};
        opacity: 0.7;
    }

    &:active:not(:disabled) {
        opacity: 0.9;
    }
`;

const blackTextTypeStyles = css`
    color: ${textColors.main};

    &:hover:not(:disabled) {
        color: ${textColors.main};
        background-color: ${grey[100]};
    }

    &:active:not(:disabled) {
        background-color: ${grey[200]};
        color: ${grey[800]};
    }
`;

const redTextTypeStyles = css`
    color: ${brandColors.red};

    &:hover:not(:disabled) {
        color: ${brandColors.red};
        background-color: ${red[50]};
    }

    &:active:not(:disabled) {
        color: ${red[700]};
    }
`;

const greenTextTypeStyles = css`
    color: ${green[700]};

    &:hover:not(:disabled) {
        color: ${green[700]};
        background-color: ${green[50]};
    }

    &:active:not(:disabled) {
        color: ${green[900]};
    }
`;

const getTypeStyles = (props: Props) => {
    switch (props.buttontype) {
        case 'primary':
        default:
            return primaryTypeStyles;
        case 'secondary':
            return secondaryTypeStyles;
        case 'text-black':
            return blackTextTypeStyles;
        case 'text-red':
            return redTextTypeStyles;
        case 'text-green':
            return greenTextTypeStyles;
        case 'text-reset':
            return undefined;
    }
};

const getAlignStyles = (props: Props) => {
    switch (props.align) {
        case 'center':
        default:
            return css`
                justify-content: center;
            `;
        case 'start':
            return css`
                justify-content: flex-start;
            `;
        case 'end':
            return css`
                justify-content: flex-end;
            `;
    }
};

const styles = css<Props>`
    ${disableDefaultButtonStyleMixin};
    ${getBaseStyles};
    ${getTypeStyles};
    ${getAlignStyles};
    width: ${({ wide }) => (!!wide ? ' 100%' : 'fit-content')};
    ${({ nopadding }) => !!nopadding && 'padding: 0'};
`;

// Components

export const StyledButton = styled.button<Props>`
    ${styles};
`;

export const StyledLink = styled(Link)<Props>`
    ${styles};
`;

export const StyledA = styled.a<Props>`
    ${styles};
`;
