import { useStore } from 'effector-react';
import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { registerTypeFilterOptions } from 'v2/constants/services/users';

export const RegisteredTypeFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { registrationType } = useStore($usersFilters);

    const handleChange = (option: SelectOption) => {
        const type = isNumber(option.value) ? (option.value as YEAY.RegistrationType) : undefined;
        addFilters({ registrationType: type });
    };

    return (
        <Select
            wide
            options={registerTypeFilterOptions}
            placement="bottom-start"
            title="Registration"
            value={registrationType || undefined}
            onChange={handleChange}
        />
    );
};
