import styled from 'styled-components';
import { Span } from 'v1/components/common/typography/Span/styles';
import { pastelPurple, violet } from 'v1/constants/styles/colors';
import { xs } from 'v1/constants/styles/sizes';
import { MarginRightBottom } from 'v1/types/styles';

export interface ClosableTagWrapperProps extends MarginRightBottom {}

export const ClosableTagWrapper = styled.div<ClosableTagWrapperProps>`
    padding: 8px 16px;
    background-color: ${pastelPurple};
    border-radius: 32px;
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
`;

export const ClosableTagSpan = styled(Span)`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${violet};

    @media (max-width: ${xs}) {
        font-size: 11px;
    }
`;
