import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Table } from 'v2/components/ui/Table';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { TransactionsFilters } from 'v2/pages/Transactions/TransactionsFilters';
import { transactionsEffects, transactionsEvents, transactionsStores } from 'v2/stores/transactions';
import { transactionsTableColumns } from './constants';
import { ContentWrapper } from './styles';
import { getTransactionsTableData } from './utils';

export const Transactions = () => {
    const transactions = useStore(transactionsStores.$transactions);
    const isLoading = useStore(transactionsEffects.getTransactionsFx.pending);
    const tableData = getTransactionsTableData(transactions?.items);

    useEffect(
        () => () => {
            transactionsEvents.resetTransactionsStores();
        },
        []
    );

    const loadMore = () => {
        if (!transactions?.hasNext) return;

        const newPageIndex = (transactions?.pageIndex || 0) + 1;
        transactionsEvents.addFilters({ pageIndex: newPageIndex });
    };

    return (
        <MainLayout>
            <TransactionsFilters />
            <ContentWrapper>
                <Table
                    cellCenter
                    columns={transactionsTableColumns}
                    data={tableData}
                    emptyMessage="No Transactions Found"
                    isLoading={isLoading}
                />
                {!isLoading && transactions?.hasNext && (
                    <Section marginTop="16px">
                        <LoadMoreSection onClick={loadMore} />
                    </Section>
                )}
            </ContentWrapper>
        </MainLayout>
    );
};
