import React, { FC, MouseEvent } from 'react';
import { InfoIcon, TrashIcon } from 'v2/assets/icons';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Noop } from 'v2/types/types';
import { AbsoluteWrapper, ActionBlockWrapper, Divider, StyledRelativeWrapper } from './styles';

interface Props {
    onClick?: Noop;
    onDelete?: Noop;
}

export const HoverWrapper: FC<Props> = ({ children, onClick, onDelete }) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick?.();
    };

    const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onDelete?.();
    };

    return (
        <StyledRelativeWrapper>
            <AbsoluteWrapper>
                <CardHoverOpacityEffect>
                    <ActionBlockWrapper>
                        <IconButton size="16px" onClick={handleClick}>
                            <InfoIcon />
                        </IconButton>

                        <Divider />

                        <IconButton size="16px" onClick={handleDelete}>
                            <TrashIcon />
                        </IconButton>
                    </ActionBlockWrapper>
                </CardHoverOpacityEffect>
            </AbsoluteWrapper>
            {children}
        </StyledRelativeWrapper>
    );
};
