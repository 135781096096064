import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    min-height: 340px;
`;

export const UsersGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(140px, 1fr));
    gap: 12px;
`;
