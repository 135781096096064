import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { engagementsEffects, engagementsStores } from 'v2/stores/collaboration/details/engagements';
import { Id } from 'v2/types/data';
import { DownloadEngagementButton } from './DownloadEngagementButton';
import { columns } from './constants';
import { prepareEngagementItems } from './utils';

const { getEngagementsFx } = engagementsEffects;

export const Engagements: FC<Id> = ({ id }) => {
    const engagementsResponse = useStore(engagementsStores.$engagements);
    const isLoading = useStore(getEngagementsFx.pending);

    const tableData = prepareEngagementItems(engagementsResponse?.items);
    const hasNext = !!engagementsResponse?.hasNext;
    const currentPage = engagementsResponse?.pageIndex || 0;

    const loadMore = () => {
        if (id && hasNext) {
            getEngagementsFx({
                collaborationId: id,
                pageIndex: currentPage + 1
            });
        }
    };

    useEffect(() => {
        if (id) {
            getEngagementsFx({
                collaborationId: id
            });
        }
    }, [id]);

    return (
        <Column noWrap width="100%">
            <Table
                cellCenter
                bottomExtra={hasNext ? <LoadMoreSection onClick={loadMore} /> : undefined}
                columns={columns}
                data={tableData}
                isLoading={isLoading}
                topExtra={
                    id && (
                        <SectionBorder alignCenter borderBottom noWrap padding="14px 16px">
                            <Title size="-2">Engagement</Title>
                            <Row marginLeft="8px">
                                <DownloadEngagementButton collaborationId={id} />
                            </Row>
                        </SectionBorder>
                    )
                }
            />
        </Column>
    );
};
