import React, { FC } from 'react';
import { Grid, GridProps } from './styles';

interface RepeatGridProps extends Omit<GridProps, 'gridTemplateColumns'> {
    columns: number;
}

export const RepeatGrid: FC<RepeatGridProps> = ({ columns, children, ...props }) => (
    <Grid gridTemplateColumns={`repeat(${columns}, 1fr)`} {...props}>
        {children}
    </Grid>
);
