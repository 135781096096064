import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const TabsWrapper = styled(Section)`
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: ${brandColors.white};
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    position: relative;
`;

export const TabsButtons = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;

    &::-webkit-scrollbar {
        height: 0px;
    }
`;

interface TabsButtonProps {
    isActive?: boolean;
    width?: string;
}

export const TabsButton = styled.button<TabsButtonProps>`
    ${disableDefaultButtonStyleMixin};
    white-space: nowrap;
    margin-left: 16px;
    ${flexCenter};
    flex-grow: 1;
    text-align: center;
    padding: 12px 8px;
    position: relative;
    border-radius: 4px;
    color: ${brandColors.black};
    transition: background-color ${transitionTime} linear, color ${transitionTime} linear;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        transition: background-color ${transitionTime} linear;
    }

    &:hover {
        background-color: ${grey[100]};
    }

    ${({ width }) =>
        width &&
        css`
            width: ${width};
            flex-grow: unset;
        `};

    ${({ isActive }) =>
        isActive &&
        css`
            &:after {
                background-color: ${brandColors.black};
            }
        `};

    ${({ isActive }) =>
        !isActive &&
        css`
            color: ${grey[700]};
        `};
`;
