import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { SearchIcon } from 'v2/assets/icons';
import { Button } from 'v2/components/ui/buttons/Button';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { FlexGrow, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { Disabled, Placeholder } from 'v2/types/form';
import { Sizes } from 'v2/types/styles';
import { Input, InputWrapper } from './styles';

interface SearchInputProps extends Sizes, Disabled, Placeholder {
    onSearch: (value: string) => void;
    allowClear?: boolean;
    defaultValue?: string;
    withoutIcon?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
    placeholder,
    disabled = false,
    onSearch,
    withoutIcon,
    defaultValue,
    allowClear,
    ...rest
}) => {
    const [searchValue, setSearchValue] = useState(defaultValue || '');
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') onSearch(searchValue);
    };

    const onSubmit = () => {
        onSearch(searchValue);
    };

    const onClear = () => {
        onSearch('');
        setSearchValue('');
    };

    useEffect(() => {
        setSearchValue(defaultValue || '');
    }, [defaultValue]);

    return (
        <InputWrapper {...rest}>
            {!withoutIcon && (
                <Row marginRight="8px">
                    <IconButton onClick={onSubmit}>
                        <SearchIcon height="16px" width="16px" />
                    </IconButton>
                </Row>
            )}

            <Input
                disabled={disabled}
                placeholder={placeholder}
                type="search"
                value={searchValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />

            {allowClear && searchValue.length > 0 && (
                <FlexGrow flexGrow="1" flexShrink="0" marginLeft="4px">
                    <Button noPadding buttonType="text-black" textSize="small" onClick={onClear}>
                        Clear
                    </Button>
                </FlexGrow>
            )}
        </InputWrapper>
    );
};
