import React, { FC } from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { UserJoinedType } from 'v2/constants/services/users';
import { EllipsisName } from './styles';

interface TextBlockProps {
    contentValue?: number | string;
}

const TextBlock: FC<TextBlockProps> = ({ contentValue }) => (
    <Text isSemibold size="-2">
        {contentValue}
    </Text>
);

export const prepareDataForTable = (items: YECM.CollaborationEngagementResponse[]) =>
    items.map(item => ({
        key: item.userId,
        username: (
            <Row alignCenter noWrap>
                {item.profileImageUrl && (
                    <Row marginRight="8px">
                        <Avatar size="32px" src={item.profileImageUrl} />
                    </Row>
                )}

                <EllipsisName isSemibold size="-2">
                    @{item.username}
                </EllipsisName>
            </Row>
        ),
        views: <TextBlock contentValue={item.views} />,
        // TODO add clicks
        clicks: <TextBlock contentValue={'-'} />,
        shares: <TextBlock contentValue={item.shares} />,
        joined: <TextBlock contentValue={item.userType === UserJoinedType.Joined ? 'Yes' : 'No'} />
    }));
