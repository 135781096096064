import { forward } from 'effector';
import { loaderEffects } from 'v2/stores/loader';
import { trendingCreatorsEffects } from 'v2/stores/trendings/creators';

const {
    getTrendingCreatorsFx,
    deleteCreatorFromTrendingFx,
    deleteCreatorsFromTrendingFx,
    swapCreatorsFx,
    addCreatorsToTrendingFx
} = trendingCreatorsEffects;

forward({
    from: [
        deleteCreatorFromTrendingFx.pending,
        deleteCreatorsFromTrendingFx.pending,
        swapCreatorsFx.pending,
        addCreatorsToTrendingFx.pending
    ],
    to: loaderEffects.loaderFx
});

forward({
    from: addCreatorsToTrendingFx.done,
    to: getTrendingCreatorsFx
});
