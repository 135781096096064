import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Events

const resetReportVideo = createEvent();
const setReport = createEvent<YEAY.GetReportedVideoResponse>();

// Effects

const getReportVideoInfoFx = createEffect({
    handler: async (data: YEAY.GetReportedVideoRequest) => {
        try {
            const response = await API.report.getReportVideoInfo(data);

            if (!response) {
                throw new Error();
            }

            return response;
        } catch (error) {
            notify('There was a problem get report info, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $reportVideoInfo = createStore<YEAY.GetReportedVideoResponse | null>({})
    .on(getReportVideoInfoFx.doneData, (_, newState) => newState)
    .on(setReport, (_, newState) => newState)
    .reset(resetReportVideo);

// Exports

export const reportVideoEvents = {
    resetReportVideo,
    setReport
};

export const reportVideoEffects = {
    getReportVideoInfoFx
};

export const reportVideoStores = {
    $reportVideoInfo
};
