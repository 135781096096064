import React, { FC } from 'react';
import emptyResultSrc from 'v2/assets/images/empty.png';
import { EmptyWrapper, ImageWrapper } from 'v2/components/common/Empty/styles';
import { Image } from 'v2/components/ui/Image';
import { Text } from 'v2/components/ui/typography/Text';

interface Props {
    title?: string;
    yPadding?: string;
    image?: string;
}

export const Empty: FC<Props> = ({ yPadding, image = emptyResultSrc, title = 'No data' }) => (
    <EmptyWrapper yPadding={yPadding}>
        <ImageWrapper>
            <Image alt="Empty result" height="100%" src={image} width="100%" />
        </ImageWrapper>

        <Text alignTextCenter isSemibold size="-1">
            {title}
        </Text>
    </EmptyWrapper>
);
