import { grey3 } from 'v1/constants/styles/colors';

export const propertyBlockBackground = grey3;
export const propertyBlockMarginBottom = '4px';

export const incorrectText = 'Incorrect Format';

// export const titleFontSize = '12px';
// export const subtitleFontSize = '14px';

// export const titleColor = dirtyBlue;
// export const subTitleColor = black;
