import { isNull, isUndefined } from 'lodash';
import { SortState } from 'v2/components/ui/buttons/SortButton/constants';

export const getSortOrderState = (sortIsAsc?: boolean | null) => {
    if (isUndefined(sortIsAsc) || isNull(sortIsAsc)) {
        return SortState.None;
    }

    return sortIsAsc ? SortState.Asc : SortState.Desc;
};

export const getNextSortValue = (currentOrder: SortState) => {
    switch (currentOrder) {
        case SortState.Desc:
            return true;
        case SortState.None:
            return false;
        case SortState.Asc:
        default:
            return undefined;
    }
};
