import { sample } from 'effector';
import { reportStatus } from 'v2/constants/services/report';
import { actionsReportEffects } from 'v2/stores/report/details/actions/init';
import { reportVideoEvents, reportVideoStores } from 'v2/stores/report/details/video';

sample({
    clock: actionsReportEffects.curateReportFx.done,
    source: reportVideoStores.$reportVideoInfo,
    fn: (report, params) => {
        if (report && params.result?.isSuccess) {
            const newReport: YEAY.GetReportedVideoResponse = {
                ...report,
                reportStatus: params.params.isApproved ? reportStatus.accepted : reportStatus.rejected
            };
            return { ...newReport };
        }
        return { ...report };
    },
    target: reportVideoEvents.setReport
});
