import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { videoModal } from 'v2/stores/initialize-modals';

export const usePathChange = () => {
    const location = useLocation();

    useEffect(() => {
        videoModal.closeModal();
    }, [location.pathname]);

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
};
