import { BooleanCheckbox } from 'v1/components/common/inputs/Checkbox';
import { CheckboxProps } from 'v1/components/common/inputs/Checkbox/types';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import React from 'react';
import { TitleSpan } from './styles';

export interface CheckboxFilterProps extends CheckboxProps {
    children: string;
    onChange?: (checked: boolean) => void;
}

export const CheckboxFilter = ({ children, ...props }: CheckboxFilterProps) => (
    <Row alignCenter noWrap>
        <MarginWrapper marginRight="10px">
            <BooleanCheckbox {...props} />
        </MarginWrapper>
        <TitleSpan>{children}</TitleSpan>
    </Row>
);
