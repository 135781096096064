import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { ellipsisMixin } from 'v2/constants/styles/mixins';
import { BorderRadius, Sizes } from 'v2/types/styles';

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding: 4px;
    width: 100%;
    background-color: ${brandColors.white};
`;

export const ImageInfo = styled(Column)`
    padding: 16px;
    background-color: ${brandColors.white};
`;

interface Props extends BorderRadius, Sizes {
    image: string;
}

export const ImageWrapper = styled.div<Props>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: cover;
    background-color: ${brandColors.white};
    background-repeat: no-repeat;
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
`;

export const GridWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
`;

export const EllipsesText = styled(Text)`
    ${ellipsisMixin};
    max-width: 300px;
`;

export const RewardWrapper = styled(Row)`
    &:not(:first-child) {
        margin-left: 12px;
    }
`;
