import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { authStores } from 'v2/stores/auth';

export const AdministratorLayout: FC = ({ children }) => {
    const isAdmin = useStore(authStores.$isAdmin);

    if (!isAdmin) {
        return null;
    }

    return <>{children}</>;
};

export const ManagerLayout: FC = ({ children }) => {
    const isManager = useStore(authStores.$isManager);

    if (!isManager) {
        return null;
    }

    return <>{children}</>;
};
