import { createEffect, createEvent, createStore, guard, sample } from 'effector';
import { isEmpty, isEqual, isUndefined, pickBy } from 'lodash';
import { CurationVariant } from 'v2/components/modals/CurateVideoModal';
import { notify } from 'v2/components/ui/Toasts';
import { VideoCurationState, defaultVideosParams, trendingVideosParams } from 'v2/constants/services/videos';
import { API } from 'v2/services/yasy';

// Types

export interface VideosParams extends Partial<YEAY.QueryAllVideosRequest> {}
export interface VideosParamsWithVideoId extends VideosParams {
    videoId?: string;
}

// Effects

const curateVideoFx = createEffect({
    handler: async ({ ...values }: YEAY.SubmitVideoCurationRequest) => {
        try {
            const response = await API.videos.curateVideo({
                ...values,
                isApproved: values.reason === CurationVariant.None
            });

            if (!response) {
                throw new Error();
            }

            notify('Video successfully curated!');
            return response;
        } catch (error) {
            notify('There was a problem curate video, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Generic

export const initializeVideosDomain = (defaultParams: VideosParamsWithVideoId = defaultVideosParams) => {
    // Events

    const addFilters = createEvent<VideosParamsWithVideoId>();
    const setOverwriteFilters = createEvent<VideosParamsWithVideoId>();
    const setVideoId = createEvent<string | undefined>();

    const resetVideos = createEvent();
    const resetFilters = createEvent();
    const setVideo = createEvent<YEAY.QueryAllVideosResponse | null>();

    // Effects

    const getVideosFx = createEffect({
        handler: async ({ videoId, pageIndex, limit, ...params }: VideosParamsWithVideoId = defaultParams) => {
            try {
                if (videoId) {
                    const video = await API.video.getVideoById({ id: videoId });

                    const hasVideo = !isEmpty(video);
                    const data = hasVideo ? [video] : [];
                    const dataCount = hasVideo ? 1 : 0;

                    return {
                        currentPageIndex: 0,
                        totalPages: dataCount,
                        returnedRecords: dataCount,
                        totalRecords: dataCount,
                        items: data
                    };
                } else {
                    const data = await API.videos.getVideos({
                        pageIndex: pageIndex || defaultVideosParams.pageIndex,
                        limit: limit || defaultVideosParams.limit,
                        returnQueryCount: defaultVideosParams.returnQueryCount,
                        hasHlsStream: defaultVideosParams.hasHlsStream,
                        isDeleted: defaultVideosParams.isDeleted,
                        isReported: defaultVideosParams.isReported,
                        ...params
                    });

                    if (!data) {
                        throw new Error();
                    }

                    return data;
                }
            } catch (error) {
                notify('There was a problem receiving videos, try to refresh the page.', 'error');
                return null;
            }
        }
    });

    // Stores

    const $videos = createStore<YEAY.QueryAllVideosResponse | null>(null)
        .on(getVideosFx.doneData, (_, payload) => payload)
        .on(setVideo, (_, newState) => newState)
        .reset(resetVideos);

    const $videosFilters = createStore<VideosParamsWithVideoId>(defaultParams)
        .on(addFilters, (state, newFilters) => {
            const filters: VideosParamsWithVideoId = {
                ...(state || defaultParams),
                ...newFilters,
                videoId: undefined
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .on(setOverwriteFilters, (_, newFilters) => {
            const filters: VideosParamsWithVideoId = {
                ...defaultParams,
                ...newFilters,
                videoId: undefined
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .on(setVideoId, (state, videoId) => {
            const filters: VideosParamsWithVideoId = {
                ...state,
                videoId: videoId || undefined
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .reset(resetFilters);

    const $resetIsAvailable = $videosFilters.map(filters => !isEqual(filters, defaultParams));

    guard({
        clock: $videosFilters,
        filter: data => !!data,
        target: getVideosFx
    });

    sample({
        clock: curateVideoFx.done,
        source: $videos,
        fn: (source, { params }) => {
            if (source && params) {
                const newItems = (source.items || []).map(video => {
                    if (video.id === params.videoId) {
                        return {
                            ...video,
                            validation: {
                                ...video?.validation,
                                yeay: {
                                    ...video?.validation?.yeay,
                                    curationState:
                                        params.reason === VideoCurationState.None
                                            ? VideoCurationState.Accepted
                                            : VideoCurationState.Rejected,
                                    curationEndedReason: params.reason || video?.validation?.yeay?.curationEndedReason
                                }
                            }
                        };
                    }

                    return video;
                });

                return {
                    ...source,
                    items: newItems
                };
            }

            return source;
        },
        target: setVideo
    });

    const effects = { getVideosFx };

    const stores = {
        $videos,
        $videosFilters,
        $resetIsAvailable
    };

    const events = {
        addFilters,
        setOverwriteFilters,
        setVideoId,
        resetVideos,
        resetFilters,
        setVideo
    };

    return { stores, effects, events };
};

export type DomainVideosType = ReturnType<typeof initializeVideosDomain>;

// Exports

export const videosEffects = { curateVideoFx };

export const videosPageDomain = initializeVideosDomain();
export const agentVideosDomain = initializeVideosDomain();
export const userVideosDomain = initializeVideosDomain();
export const trendingVideosDomain = initializeVideosDomain(trendingVideosParams);
