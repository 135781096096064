import React, { FC } from 'react';
import { CalendarIcon, ClockIcon } from 'v2/assets/icons';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { PropertyLayout, PropertyLayoutProps } from 'v2/components/ui/PropertyLayout';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { textColors } from 'v2/constants/styles/colors';
import { getFormattedDate } from 'v2/utils/datetime';

interface DatePropertyProps extends Omit<PropertyLayoutProps, 'children'> {
    date?: string | null;
    fallbackValue?: string;
    hideDate?: boolean;
    hideTime?: boolean;
}

export const DateProperty: FC<DatePropertyProps> = ({ date, hideDate, hideTime, fallbackValue = '-', ...props }) => {
    const propertyDate = date ? getFormattedDate(date) : null;
    const propertyTime = date ? `${getFormattedDate(date, 'hh:mm:ss')} (UTC)` : null;

    return (
        <PropertyLayout {...props}>
            <Row alignCenter noWrap>
                {!hideDate && (
                    <Row marginRight="2px">
                        <IconTextItem
                            icon={<CalendarIcon />}
                            iconColor={textColors.subtext}
                            text={propertyDate || fallbackValue}
                        />
                    </Row>
                )}

                {!hideTime && (
                    <Row marginLeft="2px">
                        <IconTextItem
                            icon={<ClockIcon color={textColors.subtext} />}
                            iconColor={textColors.subtext}
                            text={propertyTime || fallbackValue}
                        />
                    </Row>
                )}
            </Row>
        </PropertyLayout>
    );
};
