import { useStore } from 'effector-react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';

export const useVideosSearch = ({ domain }: VideosFilterProps) => {
    const { $videosFilters } = domain.stores;
    const { setVideoId, setOverwriteFilters } = domain.events;

    const { creatorId, collaborationId, videoId } = useStore($videosFilters);

    const searchItems = [
        {
            option: {
                value: 'videoId',
                label: 'Video ID'
            },
            onSearch: (videoId: string) => setVideoId(videoId.trim() || undefined)
        },
        {
            option: {
                value: 'userId',
                label: 'User ID'
            },
            onSearch: (userId: string) =>
                setOverwriteFilters({
                    creatorId: userId || undefined
                })
        },
        {
            option: {
                value: 'collabId',
                label: 'Collab ID'
            },
            onSearch: (collaborationId: string) =>
                setOverwriteFilters({
                    collaborationId: collaborationId || undefined
                })
        }
    ];

    return {
        searchItems,
        values: { collaborationId, creatorId, videoId }
    };
};
