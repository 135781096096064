import { Placement } from '@floating-ui/react';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { Dropdown } from 'v2/components/ui/Dropdown';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { SelectOption, SelectOptionValue } from '../types';
import { DefaultOption, OptionWrapper } from './styles';

export interface SelectProps<T> {
    options: T[];
    renderOption?: (props: T) => ReactNode;
    value?: SelectOptionValue;
    onChange?: (value: T) => void;
    placeholder?: string;
    wide?: boolean;
    disabled?: boolean;
    placement?: Placement;
    error?: string;
    title?: string;
    withBackground?: boolean;
    icon?: ReactNode;
    hideAfterChange?: boolean;
    minWidth?: number;
}

export const Select = <T extends SelectOption>({
    value,
    options,
    renderOption,
    onChange,
    title,
    error,
    wide,
    disabled,
    withBackground,
    icon,
    minWidth,
    hideAfterChange = true,
    placement = 'bottom-start',
    placeholder = 'All'
}: SelectProps<T>) => {
    const [isOpened, setIsOpened] = useState(false);
    const selectedValue = options.find(option => option.value === value);

    const handleValueChange = (value: T) => (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onChange && onChange(value);
        hideAfterChange && setIsOpened(false);
    };

    return (
        <Column width={wide ? '100%' : undefined}>
            <Dropdown
                disabled={disabled}
                icon={icon}
                isOpened={isOpened}
                label={selectedValue?.label}
                minWidth={minWidth}
                placeholder={placeholder}
                placement={placement}
                title={title}
                wide={wide}
                withBackground={withBackground}
                onOpenedChange={setIsOpened}
            >
                {options.length > 0 &&
                    options.map(option => (
                        <OptionWrapper
                            key={option.value || 'key'}
                            disabled={disabled || option.value === value}
                            type="button"
                            onClick={handleValueChange(option)}
                        >
                            {renderOption ? (
                                renderOption(option)
                            ) : (
                                <DefaultOption isSelected={option.value === value}>
                                    <Text noTextWrap color="inherit" size="-2">
                                        {option.label}
                                    </Text>
                                </DefaultOption>
                            )}
                        </OptionWrapper>
                    ))}
            </Dropdown>

            {error && (
                <Row marginTop="2px" width="100%">
                    <Text color={brandColors.red} size="-2">
                        {error}
                    </Text>
                </Row>
            )}
        </Column>
    );
};
