import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { ResetSearchButton } from 'v1/components/common/buttons/ResetButton';
import { SearchInput } from 'v1/components/common/inputs/SearchInput';
import { Span } from 'v1/components/common/typography/Span/styles';
import { FlexGrow, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { defaultBlacklistedUsersValues } from 'v1/constants/defaults/users';
import { searchInputBorder, searchInputPadding, selectPadding } from 'v1/pages/Blacklisted/constants';
import { LayoutContentWrapper } from 'v1/pages/Blacklisted/styles';
import { blacklistedUsersEvents, blacklistedUsersStores } from 'v1/stores/users/blacklistedUsers';
import { SearchParameters } from 'v1/types/data';
import { emailRegExp, mobileNumberRegExp } from 'v2/constants/reg-exp';

const { updateValues } = blacklistedUsersEvents;

const BlacklistedUsersFilterLayout: FC = ({ children }) => {
    const { searchText } = useStore(blacklistedUsersStores.values);

    const resetSearch = () => {
        updateValues({
            ...defaultBlacklistedUsersValues,
            type: 0,
            searchText: undefined
        });
    };

    const onSearchByEmail = (email: string) => {
        updateValues({
            ...defaultBlacklistedUsersValues,
            type: 1,
            searchText: email || undefined
        });
    };
    const onSearchMobileNumber = (mobileNumber: string) => {
        updateValues({
            ...defaultBlacklistedUsersValues,
            type: 2,
            searchText: mobileNumber || undefined
        });
    };

    const onSearchByUsername = (username: string) => {
        updateValues({
            ...defaultBlacklistedUsersValues,
            type: 3,
            searchText: username || undefined
        });
    };

    const searchParameters: SearchParameters[] = [
        {
            searchBy: 'Email Address',
            defaultValue: searchText,
            placeholder: 'Search by Email Address',
            onSearch: onSearchByEmail,
            regExp: emailRegExp
        },
        {
            searchBy: 'Username',
            defaultValue: searchText,
            placeholder: 'Search by Username',
            onSearch: onSearchByUsername
        },
        {
            searchBy: 'Mobile Number',
            defaultValue: searchText,
            placeholder: 'Search by Mobile Number',
            onSearch: onSearchMobileNumber,
            regExp: mobileNumberRegExp
        }
    ];

    return (
        <LayoutContentWrapper>
            <Section alignCenter justifyBetween marginBottom="12px">
                <Span fontSize="16px" fontWeight="700" lineHeight="18px">
                    Blacklisted Users
                </Span>
            </Section>

            <Section marginBottom="8px">
                <FlexGrow flexGrow="1" marginRight="20px">
                    <SearchInput
                        border={searchInputBorder}
                        padding={searchInputPadding}
                        searchParameters={searchParameters}
                        selectPadding={selectPadding}
                    />
                </FlexGrow>
                <ResetSearchButton onClick={resetSearch} />
            </Section>
            {children}
        </LayoutContentWrapper>
    );
};

export default BlacklistedUsersFilterLayout;
