import React, { FC } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { DraggableProps } from 'v2/types/react';
import { InfluencerCardWrapper } from './styles';

interface Props extends DraggableProps {
    username?: string | null;
    isTrusted?: boolean;
    profileImageUrl?: string | null;
    draggable?: boolean;
}

export const InfluencerCard: FC<Props> = ({ profileImageUrl, isTrusted, username, ...draggableProps }) => (
    <InfluencerCardWrapper {...draggableProps}>
        <Column alignCenter justifyCenter noWrap height="100%">
            <Avatar src={profileImageUrl || ''} />
            <Row alignCenter noWrap marginTop="8px">
                <EllipsisText size="-2">@{username}</EllipsisText>
                {isTrusted && (
                    <Row marginLeft="4px">
                        <DoneIcon height={14} width={14} />
                    </Row>
                )}
            </Row>
        </Column>
    </InfluencerCardWrapper>
);
