import styled from 'styled-components';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { headerWidth, xs } from 'v1/constants/styles/sizes';

export const SearchWrapperLayout = styled(Section)`
    padding-right: ${headerWidth};
    margin-bottom: 10px;

    @media (max-width: ${xs}) {
        padding-right: 0;
        margin-bottom: 0;
    }
`;
