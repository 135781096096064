import axios from 'v2/services/yasy/axios';

export const getUserReports = (data: YEAY.AdminGetReportedUserRequest) =>
    axios<YEAY.AdminGetReportedUserResponse>({
        url: '/admin/user/get-reported',
        data
    });

export const getReportVideoInfo = (data: YEAY.GetReportedVideoRequest) =>
    axios<YEAY.GetReportedVideoResponse>({
        url: '/admin/video/get-reported',
        method: 'POST',
        data
    });

export const curateReport = (data: YEAY.UpdateReportStatusRequest) =>
    axios<YEAY.ApiResponse>({
        url: '/reports/curate',
        method: 'PATCH',
        data
    });

export const getReportsList = (params: Paths.ReportsReportList.Get.QueryParameters) =>
    axios<YEAY.GetReportDetailsResponseApiResponse>({
        url: '/reports/reportList',
        method: 'GET',
        params
    });
