import { Form } from 'formik';
import styled from 'styled-components';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const FormWrapper = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderWrapper = styled(Column)`
    padding: 8px 16px;
    width: 100%;
    border-bottom: 1px solid ${brandColors.grey};
`;

export const FooterWrapper = styled(Section)`
    padding: 8px 16px;
    border-top: 1px solid ${brandColors.grey};
`;
