import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const LinkUserInfo = styled(Link)`
    padding: 4px;
    border-radius: 4px;
    display: flex;
    width: 100%;
    transition: background-color ${transitionTime} linear, text-decoration ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
        text-decoration: underline;
        text-decoration-color: ${brandColors.black};
    }
`;

export const TrashButtonWrapper = styled(Section)`
    padding-right: 40px;
`;
