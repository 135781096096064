import React, { FC, MouseEvent } from 'react';
import { FullscreenIcon, PauseIcon, PlayFillIcon, VolumeOffIcon, VolumeOnIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { AbsoluteWrapper } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { playerEvents } from 'v2/stores/player';
import { Timeline } from '../Timeline';
import { getPlayerTime } from '../utils';

interface Props {
    currentTime: number;
    totalDuration: number;
    isMuted?: boolean;
    isPlaying?: boolean;
    isFullscreen?: boolean;
    onTogglePlay?: (event: MouseEvent<HTMLButtonElement>) => void;
    onFullscreen?: (event: MouseEvent<HTMLButtonElement>) => void;
    onChangeTime?: (value: number) => void;
}

const buttonStyleParams = {
    rounded: true,
    bgColor: brandColors.black,
    bgHoverColor: grey[800],
    color: brandColors.white
};

export const Toolbar: FC<Props> = ({
    isPlaying,
    isMuted,
    isFullscreen,
    currentTime,
    totalDuration,
    onTogglePlay,
    onFullscreen,
    onChangeTime
}) => {
    const toggleMute = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        playerEvents.toggleMute();
    };

    return (
        <AbsoluteWrapper bottom="0" left="0" right="0" width="100%" zIndex="1">
            <Column
                noWrap
                paddingBottom={isFullscreen ? '16px' : '8px'}
                paddingLeft="8px"
                paddingRight="8px"
                width="100%"
            >
                <Section alignCenter justifyBetween noWrap>
                    {isFullscreen ? (
                        <Row alignCenter>
                            <Column marginRight="12px">
                                <IconButton {...buttonStyleParams} onClick={onTogglePlay}>
                                    {isPlaying ? (
                                        <PauseIcon height={9} width={6} />
                                    ) : (
                                        <PlayFillIcon height={8} width={7} />
                                    )}
                                </IconButton>
                            </Column>

                            <Text color={brandColors.white} size="-2">
                                {getPlayerTime(currentTime)}/{getPlayerTime(totalDuration)}
                            </Text>
                        </Row>
                    ) : (
                        <div /> // for justify-between
                    )}

                    <Row alignCenter justifyEnd>
                        {(isPlaying || isFullscreen) && (
                            <Column marginLeft="4px">
                                <IconButton {...buttonStyleParams} onClick={toggleMute}>
                                    {isMuted ? <VolumeOffIcon /> : <VolumeOnIcon />}
                                </IconButton>
                            </Column>
                        )}

                        {!isFullscreen && (
                            <Column marginLeft="4px">
                                <IconButton {...buttonStyleParams} onClick={onFullscreen}>
                                    <FullscreenIcon />
                                </IconButton>
                            </Column>
                        )}
                    </Row>
                </Section>

                {isFullscreen && (
                    <Section marginTop="8px">
                        <Timeline currentTime={currentTime} totalDuration={totalDuration} onChange={onChangeTime} />
                    </Section>
                )}
            </Column>
        </AbsoluteWrapper>
    );
};
