import { useStore } from 'effector-react';
import React from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { campaignManagerReportsEvents, campaignManagerReportsStores } from 'v2/stores/reports/campaign-manager';
import { reportStatusOptions } from '../../constants';

export const ReportStatus = () => {
    const { ReportStatus: status } = useStore(campaignManagerReportsStores.$campaignManagerReportsFilters);

    const onChangeStatus = (status: SelectOption) => {
        campaignManagerReportsEvents.setOverwriteFilters({
            ReportStatus: status.value
        });
    };

    return <Select wide options={reportStatusOptions} title="Report Status" value={status} onChange={onChangeStatus} />;
};
