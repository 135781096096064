import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { asyncErrorMessage } from 'v2/constants/common';
import { KickOutReason } from 'v2/constants/services/enrollments';
import { yecmAPI } from 'v2/services/yecm';
import { UserCollaborationParams } from 'v2/services/yecm/admin';

// Events

const setEnrollmentItems = createEvent<YECM.ParticipationResponse[] | null>();
const resetStores = createEvent();

// Effects

interface GetEnrollmentsParams {
    collaborationId: string;
    pageIndex?: number;
}

const getEnrollmentsFx = createEffect({
    handler: async ({ collaborationId, pageIndex = 0 }: GetEnrollmentsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.collaboration.getCollaborationEnrollments(collaborationId, {
                limit: 20,
                pageIndex
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (e) {
            return null;
        }
    }
});

const removeEnrollmentFx = createEffect({
    handler: async (params: UserCollaborationParams) => {
        try {
            const { isSuccess } = await yecmAPI.admin.removeUserFromCollaboration(params, {
                kickOutReason: KickOutReason.GenericNoReason
            });

            if (!isSuccess) {
                throw new Error();
            }

            return params;
        } catch (e) {
            notify(asyncErrorMessage, 'error');
            return null;
        }
    }
});

// Stores

const $enrollmentsResponse = createStore<YECM.ParticipationsResponse | null>(null)
    .on(getEnrollmentsFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        items: payload?.pageIndex === 0 ? payload?.items : [...(state?.items || []), ...(payload?.items || [])]
    }))
    .on(setEnrollmentItems, (state, payload) => {
        if (!state) {
            return null;
        }

        return {
            ...state,
            items: payload
        };
    })
    .reset(resetStores);

const $enrollmentsItems = $enrollmentsResponse.map(state => state?.items || []);
const $enrollmentsHasNext = $enrollmentsResponse.map(state =>
    !state || state.hasNext === undefined ? true : state.hasNext
);
const $enrollmentsCurrentPage = $enrollmentsResponse.map(state =>
    !state || state.pageIndex === undefined ? -1 : state.pageIndex
);

// Exports

export const collaborationEnrollmentsEvents = {
    setEnrollmentItems,
    resetStores
};

export const collaborationEnrollmentsEffects = {
    getEnrollmentsFx,
    removeEnrollmentFx
};

export const collaborationEnrollmentsStores = {
    $enrollmentsResponse,
    $enrollmentsItems,
    $enrollmentsHasNext,
    $enrollmentsCurrentPage
};
