// App reports

export enum AppReportTypeState {
    None,
    Video,
    Account,
    Collab
}

export const reportType = {
    [AppReportTypeState.None]: '',
    [AppReportTypeState.Video]: 'Video',
    [AppReportTypeState.Collab]: 'Collab',
    [AppReportTypeState.Account]: 'Account'
};

// Campaign manager reports

export enum CampaignManagerReportTypeState {
    None,
    Video,
    Post
}

export const campaignManagerReportType = {
    [CampaignManagerReportTypeState.None]: '',
    [CampaignManagerReportTypeState.Video]: 'Video',
    [CampaignManagerReportTypeState.Post]: 'Post'
};
