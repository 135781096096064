import React, { useEffect, useState } from 'react';
import checkmarkIcon from 'v1/assets/checkmark_icon.svg';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { HiddenCheckbox, Label, VisibleCheckbox, VisibleLabel } from 'v1/components/common/inputs/Checkbox/styles';
import { CheckboxProps } from 'v1/components/common/inputs/Checkbox/types';
import { AbsoluteWrapper } from 'v1/components/wrappers/grid/AbsoluteWrapper/styles';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { noop } from 'v1/constants/functions';

interface Props extends CheckboxProps {
    onChange?: (checked: boolean) => void;
    name?: string;
    label?: string;
    showName?: boolean;
}

export const BooleanCheckbox = ({
    defaultChecked = false,
    disabled = false,
    onChange = noop,
    showName = false,
    name = 'name',
    label
}: Props) => {
    const [checked, setChecked] = useState(defaultChecked);

    const onCheckboxChange = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    useEffect(() => setChecked(defaultChecked), [defaultChecked]);

    return (
        <Label>
            <Row alignCenter marginRight="4px">
                <VisibleCheckbox checked={checked} disabled={disabled}>
                    <AbsoluteWrapper bottom="4px" left="2px">
                        <CustomImg alt="Checkbox" height="13px" src={checkmarkIcon} width="19px" />
                    </AbsoluteWrapper>
                </VisibleCheckbox>
                {label && <VisibleLabel>{label}</VisibleLabel>}
                <HiddenCheckbox checked={checked} disabled={disabled} name={name} onChange={onCheckboxChange} />
            </Row>
            {showName && name}
        </Label>
    );
};
