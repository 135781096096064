import { createEffect, createEvent, createStore } from 'effector';
import { isUndefined, pickBy } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { UserRole, defaultAgentUsersParams } from 'v2/constants/services/users';
import { API } from 'v2/services/yasy';
import { addAgentModal, editAgentModal } from 'v2/stores/initialize-modals';

// Types

export type UsersParams = Partial<YEAY.QueryAllUsersRequest>;

interface AddMemberToAgentParams {
    agentUserId: string;
    memberUserId: string;
}

interface RemoveMemberFromAgentParams {
    agentId: string;
    memberUserId: string;
}

interface ChangeAgentParams {
    currentAgentId: string;
    memberUserId: string;
    newAgentUserId: string;
}

// Events

const setNewFilters = createEvent<UsersParams>();
const resetStores = createEvent();

// Effects

const getAgentsFx = createEffect({
    handler: async ({ pageIndex, limit, ...params }: UsersParams = defaultAgentUsersParams) => {
        try {
            const data = await API.users.getUsers({
                pageIndex: pageIndex || defaultAgentUsersParams.pageIndex,
                limit: limit || defaultAgentUsersParams.limit,
                ...params,
                role: UserRole.Agent
            });

            if (!data) {
                throw new Error();
            }

            return data;
        } catch {
            notify('There was a problem getting users, try to refresh the page.', 'error');
            return null;
        }
    }
});

const addMemberToAgentFx = createEffect({
    handler: async ({ agentUserId, memberUserId }: AddMemberToAgentParams) => {
        try {
            const agentInfoAboutUser = await API.agent.getUserAgentInfo(agentUserId);

            if (agentInfoAboutUser.data?.id) {
                const response = await API.agent.addMemberToAgent(
                    {
                        userIds: [memberUserId]
                    },
                    agentInfoAboutUser.data.id
                );

                if (!response?.isSuccess) {
                    throw new Error();
                }
                notify('User added to agent successfully');
                return memberUserId;
            } else {
                throw new Error();
            }
        } catch {
            notify('There was a problem adding member to agent, try to refresh the page.', 'error');
            return '';
        } finally {
            addAgentModal.closeModal();
        }
    }
});

const removeMemberFromAgentFx = createEffect({
    handler: async ({ agentId, memberUserId }: RemoveMemberFromAgentParams) => {
        try {
            const response = await API.agent.removeMemberFromAgent(memberUserId, agentId);

            if (!response?.isSuccess) {
                throw new Error();
            }

            notify('User removed from agent successfully');
            return memberUserId;
        } catch {
            notify('There was a problem removing member from agent, try to refresh the page.', 'error');
            return '';
        } finally {
            editAgentModal.closeModal();
        }
    }
});

const changeAgentFx = createEffect({
    handler: async ({ currentAgentId, memberUserId, newAgentUserId }: ChangeAgentParams) => {
        try {
            const response = await API.agent.removeMemberFromAgent(memberUserId, currentAgentId);

            if (response?.isSuccess) {
                const agentInfoAboutUser = await API.agent.getUserAgentInfo(newAgentUserId);

                if (agentInfoAboutUser.data?.id) {
                    const response = await API.agent.addMemberToAgent(
                        {
                            userIds: [memberUserId]
                        },
                        agentInfoAboutUser.data.id
                    );

                    if (!response?.isSuccess) {
                        throw new Error();
                    }
                    notify('Agent changed successfully');
                    return memberUserId;
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch {
            notify('There was a problem changing agent, try to refresh the page.', 'error');
            return '';
        } finally {
            editAgentModal.closeModal();
        }
    }
});

// Stores

const $agentsResponse = createStore<YEAY.QueryUsersResponse | null>(null)
    .on(getAgentsFx.doneData, (state, users) => {
        if (users?.currentPageIndex !== undefined && users?.currentPageIndex > 0) {
            return {
                ...state,
                currentPageIndex: state?.currentPageIndex !== undefined ? state.currentPageIndex + 1 : 0,
                items: [...(state?.items || []), ...(users?.items || [])]
            };
        }
        return users;
    })
    .reset(resetStores);

const $agentsFilters = createStore<UsersParams>(defaultAgentUsersParams).on(setNewFilters, (_, newFilters) => {
    const filters: UsersParams = {
        ...defaultAgentUsersParams,
        ...newFilters
    };

    return pickBy(filters, value => !isUndefined(value));
});

const $isLoading = createStore<boolean>(false).on(getAgentsFx.pending, (_, isPending) => isPending);

const $hasNext = $agentsResponse.map(state => {
    if (state) {
        return (
            state?.totalPages !== undefined &&
            state?.currentPageIndex !== undefined &&
            state?.currentPageIndex + 1 < state?.totalPages
        );
    }
    return false;
});

// Exports

export const addAgentStores = {
    $agentsResponse,
    $agentsFilters,
    $hasNext,
    $isLoading
};

export const addAgentEffects = {
    getAgentsFx,
    addMemberToAgentFx,
    removeMemberFromAgentFx,
    changeAgentFx
};

export const addAgentEvents = {
    setNewFilters,
    resetStores
};
