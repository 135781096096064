import axios from 'v2/services/yasy/axios';

export const getVideos = (data: YEAY.QueryAllVideosRequest) =>
    axios<YEAY.QueryAllVideosResponse>({
        url: '/admin/video/query',
        data
    });

export const curateVideo = (data: YEAY.SubmitVideoCurationRequest) =>
    axios<YEAY.SubmitVideoCurationResponse>({
        url: '/curation/submit',
        data
    });
