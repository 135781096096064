import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon, InstagramIcon, SnapchatIcon, TikTokIcon, TwitterIcon, YoutubeIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { notify } from 'v2/components/ui/Toasts';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { TagStyled, TagText } from './styles';

interface Props {
    id: string;
    text: string;
    title: string;
    link: string;
}

const Icon = ({ networkName }: { networkName: string }) => {
    switch (networkName.toLocaleLowerCase()) {
        case ExternalNetworkKey.Twitter:
            return <TwitterIcon height="10px" width="12px" />;
        case ExternalNetworkKey.Instagram:
            return <InstagramIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Tiktok:
            return <TikTokIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Youtube:
            return <YoutubeIcon height="8px" width="12px" />;
        case ExternalNetworkKey.Snapchat:
            return <SnapchatIcon height="10px" width="12px" />;
        default:
            return null;
    }
};

export const Tag: FC<Props> = ({ text, title, id, link = '/' }) => {
    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Copied');
        } else {
            notify('Error Processing Action', 'error');
        }
    };
    return (
        <TagStyled id={id}>
            <Row marginRight="8px">
                <Icon networkName={title || ''} />
            </Row>
            <TagText to={link}>
                <Text className="text" size="-2">
                    @{text}
                </Text>
            </TagText>
            <Row alignCenter noWrap marginLeft="8px">
                <CopyToClipboard text={text} onCopy={handleCopy}>
                    <IconButton size="10px">
                        <CopyIcon />
                    </IconButton>
                </CopyToClipboard>
            </Row>
        </TagStyled>
    );
};
