import React, { FC, ReactNode } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { DraggableProps } from 'v2/types/react';
import { Noop } from 'v2/types/types';
import { HoverWrapper } from './HoverWrapper';
import { Wrapper } from './styles';

interface Props extends DraggableProps {
    positionComponent?: ReactNode;
    user?: YEAY.GetTrendingUserResponse;
    draggable?: boolean;
    onInfoClick?: Noop;
    onDeleteClick?: Noop;
}

export const TrendingCreatorCard: FC<Props> = ({
    positionComponent,
    user,
    onInfoClick,
    onDeleteClick,
    ...draggableProps
}) => (
    <Wrapper {...draggableProps}>
        <HoverWrapper onClick={onInfoClick} onDelete={onDeleteClick}>
            <Section alignCenter noWrap height="100%" paddingRight="8px">
                {positionComponent}

                <Column marginLeft="8px">
                    <Avatar size="32px" src={user?.profileImageUrl || undefined} />
                </Column>

                <Column marginLeft="8px">
                    <EllipsisText size="-1">@{user?.username || ''}</EllipsisText>
                </Column>

                {user?.isTrusted && (
                    <Column marginLeft="8px">
                        <IconWrapper size="16px">
                            <DoneIcon />
                        </IconWrapper>
                    </Column>
                )}
            </Section>
        </HoverWrapper>
    </Wrapper>
);
