import React, { FC, ReactNode } from 'react';
import { SubmissionType } from 'v2/components/filter/collaboration/submissions/SubmissionType';
import { Title } from 'v2/components/ui/typography/Title';
import { SectionBorderWrapper, SubmissionsFilterWrapper } from './styles';

interface Props {
    children: ReactNode;
}

export const SubmissionsFilterLayout: FC<Props> = ({ children }) => (
    <SubmissionsFilterWrapper width="100%">
        <SectionBorderWrapper borderBottom padding="14px 16px">
            <Title size="-2">Submissions</Title>
        </SectionBorderWrapper>

        <SectionBorderWrapper borderTop noWrap padding="8px">
            <SubmissionType />
        </SectionBorderWrapper>

        {children}
    </SubmissionsFilterWrapper>
);
