import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { AgentList } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/AgentList';
import { addAgentEffects } from 'v2/stores/agent/add';
import { addAgentModal } from 'v2/stores/initialize-modals';

interface Props {
    userId: string;
}

const AddAgentModalInner: FC<Props> = ({ userId }) => {
    const [selectedAgentId, setSelectedAgentId] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const onSubmit = () => {
        addAgentEffects.addMemberToAgentFx({
            agentUserId: selectedAgentId,
            memberUserId: userId
        });
    };

    const onSelectAgent = (agentId: string) => {
        setSelectedAgentId(agentId);
    };

    const onCheckboxChange = () => {
        setIsAgreed(prev => !prev);
    };

    return (
        <ModalLayout
            noBodyPadding
            submitDisabled={!isAgreed || !selectedAgentId}
            submitText="Apply"
            title="Add Agent"
            onCancel={() => addAgentModal.closeModal()}
            onSubmit={onSubmit}
        >
            <Section marginTop="20px">
                <AgentList selectAgent={onSelectAgent} selectedAgentId={selectedAgentId} />
            </Section>
            <SectionBorder borderTop padding="10px 16px">
                <Checkbox checked={isAgreed} onChange={onCheckboxChange}>
                    <Text size="-2">Are you sure you want to add this Agent?</Text>
                </Checkbox>
            </SectionBorder>
        </ModalLayout>
    );
};

export const AddAgentModal = () => {
    const { visible } = useStore(addAgentModal.$modal);
    const { userId } = useStore(addAgentModal.$state);

    return (
        <ModalWrapper visible={visible} onClose={() => addAgentModal.closeModal()}>
            <AddAgentModalInner userId={userId} />
        </ModalWrapper>
    );
};
