import { createEffect, createEvent, createStore } from 'effector';
import { isEmpty } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Events

const resetVideo = createEvent();

// Effects

const getVideoByIdFx = createEffect({
    handler: async (videoId: string) => {
        try {
            const data = await API.video.getVideoById({
                id: videoId
            });

            if (!data || isEmpty(data)) {
                throw new Error();
            }

            return data;
        } catch (e) {
            notify('Failed to get video', 'error');
            console.log('Failed to get video', e);
            return null;
        }
    }
});

// Stores

const $video = createStore<YEAY.AdminGetVideoResponse | null>(null)
    .on(getVideoByIdFx.doneData, (_, state) => state)
    .reset([resetVideo, getVideoByIdFx.fail]);

// Exports

export const videoModalEvents = { resetVideo };

export const videoModalEffects = { getVideoByIdFx };

export const videoModalStores = { $video };
