import styled from 'styled-components';
import { blue } from 'v1/constants/styles/colors';
import { xs } from 'v1/constants/styles/sizes';

export const TitleText = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    color: ${blue};

    @media (max-width: ${xs}) {
        font-size: 14px;
        margin-bottom: 10px;
    }
`;

export const ContentText = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
`;
