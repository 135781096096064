export const errorEffectMessage = 'Error with loading data';
export const asyncErrorMessage = 'Something went wrong!';
export const defaultDebounceTime = 500;

export const userIdSearchPlaceholder = 'Search user by id';
export const usernameSearchPlaceholder = 'Search user by username';
export const emailSearchPlaceholder = 'Search user by email';
export const mobileNumberSearchPlaceholder = 'Search user by mobile number';

export enum KeyboardCode {
    Enter = 'Enter',
    Esc = 'Escape',
    Backspace = 'Backspace',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown'
}
