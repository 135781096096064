import { CSSProperties } from 'react';
import { brandColors, grey } from 'v2/constants/styles/colors';

const lineHeight = 4;

export const handleStyles: CSSProperties = {
    display: 'none'
};

export const trackStyles: CSSProperties = {
    height: lineHeight,
    backgroundColor: brandColors.green,
    borderRadius: 4,
    cursor: 'pointer'
};

export const railStyles: CSSProperties = {
    width: '100%',
    height: lineHeight,
    backgroundColor: grey[600],
    borderRadius: 4,
    cursor: 'pointer'
};
