import { SelectOption } from 'v2/components/ui/selects/types';

export enum SubmissionTypeLabel {
    Valid = 'Valid',
    Invalid = 'Invalid',
    Pending = 'Pending'
}

export enum SubmissionTypeValue {
    Pending = 100,
    Valid = 200,
    Invalid = 500
}

export const submissionTypes: SelectOption[] = [
    {
        value: SubmissionTypeValue.Valid,
        label: SubmissionTypeLabel.Valid
    },
    {
        value: SubmissionTypeValue.Invalid,
        label: SubmissionTypeLabel.Invalid
    },
    {
        value: SubmissionTypeValue.Pending,
        label: SubmissionTypeLabel.Pending
    }
];
