import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin, scrollbarMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 4px 16px;
    background-color: ${brandColors.white};
    transition: background-color ${transitionTime};

    &:hover {
        background-color: ${grey[100]};
    }
`;

export const AgentsWrapper = styled.div`
    height: 320px;
    max-height: 320px;
    width: 100%;
    overflow-y: scroll;
    background-color: ${brandColors.grey};
    position: relative;
    ${scrollbarMixin};
`;

export const NotFoundWrapper = styled.div`
    width: 100%;
    padding: 4px 16px;
    background-color: ${brandColors.white};
`;
