import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { UserRole, roleFilterOptions } from 'v2/constants/services/users';
import { authStores } from 'v2/stores/auth';

export const useUserRolesOptions = () => {
    const [options, setOptions] = useState(roleFilterOptions);

    const isSuperAdmin = useStore(authStores.$isSuperAdmin);
    const isAdmin = useStore(authStores.$isAdmin);

    useEffect(() => {
        setOptions(() => {
            let newOptions = roleFilterOptions;

            if (!isSuperAdmin) {
                newOptions = newOptions.filter(role => role.value !== UserRole.SuperAdministrator);
            }

            if (!isAdmin) {
                newOptions = newOptions.filter(role => role.value !== UserRole.Agent);
            }

            return newOptions;
        });
    }, [isAdmin, isSuperAdmin]);

    return options;
};
