import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { Tooltip, TooltipText } from 'v2/components/ui/Tooltip';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title/styles';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { noop } from 'v2/constants/functions';
import { UserRole } from 'v2/constants/services/users';
import { agentEffects } from 'v2/stores/agent';
import { authStores } from 'v2/stores/auth';
import { blockUserModal } from 'v2/stores/initialize-modals';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { singleUserActionsEffects } from 'v2/stores/single-user/actions';
import { userNetworksStores } from 'v2/stores/user-networks';
import { splitArrayIntoChunks } from 'v2/utils/array';
import { getCompactNumber } from 'v2/utils/formats';
import { BlockUserModal } from './BlockUserModal';
import { FollowersItem } from './FollowersItem';
import { FollowersGrid, MainInfoWrapper } from './styles';

interface Props {
    user: YEAY.AdminGetUserCommon;
}

export const UserMainInfo: FC<Props> = ({ user }) => {
    const isAdmin = useStore(authStores.$isAdmin);
    const userNetworks = useStore(userNetworksStores.$userNetworks);

    const splittedMedia = splitArrayIntoChunks(user?.socialMediaDetails || [], 3);
    const isAgentRoleAssigned = user.roles?.includes(UserRole.Agent);

    const unblockUser = () => {
        confirmEvents.openConfirm({
            title: 'Unblock the user',
            description: 'Are you sure you want to unblock the user?',
            cancelButtonText: 'Yes',
            okButtonText: 'Cancel',
            cancelCallback: () => {
                if (user.id) {
                    singleUserActionsEffects.enableUserByIdFx(user.id);
                }
            },
            okCallback: () => confirmEvents.closeConfirm()
        });
    };

    const blockUser = () => {
        blockUserModal.openModal({ userId: user.id || '' });
    };

    const toggleAgentRole = () => {
        confirmEvents.openConfirm({
            title: isAgentRoleAssigned ? 'Remove Agent' : 'Make Agent',
            description: `Are you sure you want to ${isAgentRoleAssigned ? 'remove' : 'make'} this user an Agent?`,
            cancelButtonText: isAgentRoleAssigned ? 'Remove Agent' : 'Make Agent',
            cancelCallback: () => {
                if (isAgentRoleAssigned && user.id) {
                    agentEffects.deleteAgentRoleFx(user.id);
                } else {
                    agentEffects.addAgentRoleFx({
                        userId: user.id,
                        earningFee: 0.1
                    });
                }
            },
            okButtonText: 'Cancel',
            okCallback: noop
        });
    };

    const handleVerify = () => {
        confirmEvents.openConfirm({
            title: user.isTrusted ? 'Unverify User' : 'Verify User',
            description: `Are you sure you want to ${user.isTrusted ? 'unverify' : 'verify'} this user?`,
            cancelButtonText: user.isTrusted ? 'Cancel' : 'Verify',
            okButtonText: user.isTrusted ? 'Unverify' : 'Cancel',
            cancelCallback: () => {
                if (user.id && !user.isTrusted) {
                    singleUserActionsEffects.editUserVerifyFx({ userId: user.id, isTrusted: !user.isTrusted });
                } else {
                    confirmEvents.closeConfirm();
                }
            },
            okCallback: () => {
                if (user.id && user.isTrusted) {
                    singleUserActionsEffects.editUserVerifyFx({ userId: user.id, isTrusted: !user.isTrusted });
                } else {
                    confirmEvents.closeConfirm();
                }
            }
        });
    };

    return (
        <MainInfoWrapper>
            <Column alignCenter marginBottom="18px">
                <Avatar src={user.profileImageUrl || ''} />
                <Row alignCenter justifyCenter marginTop="8px">
                    <Title alignTextCenter size="-1">
                        {user.username ? `@${user.username}` : '-'}
                    </Title>
                    {user?.isTrusted && (
                        <Row marginLeft="8px">
                            <Tooltip overlay={<TooltipText text="Account is trusted" />} placement="top">
                                <DoneIcon height={14} width={14} />
                            </Tooltip>
                        </Row>
                    )}
                </Row>
            </Column>

            {splittedMedia.length > 0 && (
                <Column alignCenter marginBottom="16px">
                    {splittedMedia.map((chunk, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <FollowersGrid key={index} columns={chunk.length}>
                            {chunk.map(item => {
                                const userNetwork = userNetworks?.find(network => network.networkId === item.networkId);

                                return (
                                    <MarginWrapper key={item.networkName}>
                                        <FollowersItem
                                            count={getCompactNumber(item.followersCount || 0)}
                                            link={userNetwork?.url || ''}
                                            networkName={item.networkName || ''}
                                        />
                                    </MarginWrapper>
                                );
                            })}
                        </FollowersGrid>
                    ))}
                </Column>
            )}

            <Column alignCenter marginBottom="20px" width="100%">
                <Text alignTextCenter size="-2" wordBreak="break-word">
                    {user.bio || '-'}
                </Text>
            </Column>

            {isAdmin && (
                <Column marginBottom="16px" width="100%">
                    <Section marginBottom="8px">
                        <Button
                            wide
                            buttonType={user.isTrusted ? 'secondary' : 'primary'}
                            disabled={user.isDisabled}
                            onClick={handleVerify}
                        >
                            {user.isTrusted ? 'Un-Verify' : 'Verify'}
                        </Button>
                    </Section>

                    <Button wide buttonType="secondary" onClick={user.isDisabled ? unblockUser : blockUser}>
                        {user.isDisabled ? 'Un-Block' : 'Block'}
                    </Button>
                </Column>
            )}

            {isAdmin && (
                <Button
                    buttonType="text-black"
                    disabled={user.isDisabled || !!user.agentInfo}
                    onClick={toggleAgentRole}
                >
                    {isAgentRoleAssigned ? 'Remove Agent' : 'Make Agent'}
                </Button>
            )}

            {isAdmin && <BlockUserModal />}
        </MainInfoWrapper>
    );
};
