import history from 'browserHistory';
import { createEffect, createEvent, createStore, guard, sample } from 'effector';
import lsConnect from 'effector-localstorage';
import { notify } from 'v2/components/ui/Toasts';
import { asyncErrorMessage } from 'v2/constants/common';
import { routePaths } from 'v2/constants/routes';
import { UserRole } from 'v2/constants/services/users';
import { userStorageName } from 'v2/constants/storage';
import { API } from 'v2/services/yasy';
import { LoginParams } from 'v2/types/services';
import { Noop } from 'v2/types/types';

interface LoginFxProps {
    params: LoginParams;
    onSuccess?: Noop;
}

// Events

const login = createEvent<LoginFxProps>();
const setLoginError = createEvent<string | null>();
const getAuthUser = createEvent();
const logout = createEvent();
const clearAuth = createEvent();

// Effects

const loginFx = createEffect({
    handler: async ({ params, onSuccess }: LoginFxProps) => {
        try {
            setLoginError(null);
            const data = await API.user.authenticateUser(params);
            onSuccess?.();

            return data || null;
        } catch (e: any) {
            const message = (e.data as YEAY.Error404NotFoundResponse).message || asyncErrorMessage;
            notify(message, 'error');
            setLoginError(message);

            return null;
        }
    }
});

const getAuthUserFx = createEffect({
    handler: async () => {
        try {
            const user = await API.user.getCurrentUser();

            return user || null;
        } catch (e) {
            console.log('Failed to get current auth user', e);

            return null;
        }
    }
});

const logoutFx = createEffect({
    handler: () => {
        clearAuth();
        history.push(routePaths.login);
    }
});

// Stores

const $auth = createStore<YEAY.UserJwtTokenResponse | null>(null)
    .on(loginFx.doneData, (_, user) => user)
    .on(getAuthUserFx.doneData, (state, user) => {
        if (!state || !user) {
            return state;
        }

        return {
            ...state,
            user: {
                ...(state.user || {}),
                ...(user || {})
            }
        };
    })
    .reset(clearAuth);

const $user = $auth.map(auth => auth?.user || null);
const $token = $auth.map(auth => auth?.token || null);

const $isAuth = $token.map(token => !!token);
const $isSuperAdmin = $user.map(state => (state?.roles || []).includes(UserRole.SuperAdministrator));
const $isAdmin = $user.map(state =>
    (state?.roles || []).some(role => [UserRole.SuperAdministrator, UserRole.Administrator].includes(role as UserRole))
);
const $isManager = $user.map(state =>
    (state?.roles || []).some(role =>
        [UserRole.SuperAdministrator, UserRole.Administrator, UserRole.ContentManager].includes(role as UserRole)
    )
);

const $loginIsLoading = createStore(false).on(loginFx.pending, (_, pending) => pending);
const $loginError = createStore<string | null>(null).on(setLoginError, (_, error) => error);

// Connects

lsConnect({ store: $auth, key: userStorageName });

sample({
    clock: login,
    target: loginFx
});

guard({
    clock: getAuthUser,
    source: $isAuth,
    filter: isAuth => isAuth,
    target: getAuthUserFx
});

sample({
    clock: logout,
    target: logoutFx
});

// Exports

export const authEvents = { login, getAuthUser, logout };

export const authStores = { $user, $token, $isAuth, $isSuperAdmin, $isAdmin, $isManager, $loginIsLoading, $loginError };
