import { useStore } from 'effector-react';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { InputField } from 'v2/components/form/InputField';
import { initialEditValues, validationSchema } from 'v2/components/modals/EditVideoHashtagsModal/constants';
import { Category } from 'v2/components/ui/Category';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { videoActionEffects, videoActionEvents, videoActionStores } from 'v2/stores/single-video/actions';
import { FooterWrapper, FormWrapper, HeaderWrapper } from './styles';
import { EditVideoHashtagsValues } from './types';

export const EditVideoHashtagsModal = () => {
    const data = useStore(videoActionStores.$hashtagsEditModal);

    const onClose = () => videoActionEvents.closeHashtagsEditModal();

    const handleChangeValues = (values: EditVideoHashtagsValues) => {
        if (data) {
            videoActionEffects.editVideoHashtagsFx({
                ownerId: data?.ownerId,
                videoId: data?.videoId,
                hashTags: [values.product, values.brand]
            });
        }
        onClose();
    };

    return (
        <ModalWrapper visible={!!data} onClose={onClose}>
            {!!data && (
                <Column width="100%">
                    <HeaderWrapper>
                        <Row marginBottom="12px">
                            <Title size="-1">Current Hashtags</Title>
                        </Row>
                        <Section marginBottom="-4px">
                            {data.currentHashtags?.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Row key={index} marginBottom="4px" marginRight="4px">
                                    <Category text={`#${item}`} />
                                </Row>
                            ))}
                        </Section>
                    </HeaderWrapper>

                    <Section>
                        <Formik
                            initialValues={initialEditValues}
                            validationSchema={validationSchema}
                            onSubmit={handleChangeValues}
                        >
                            {({ handleSubmit, isValid, dirty }: FormikProps<EditVideoHashtagsValues>) => (
                                <FormWrapper onSubmit={handleSubmit}>
                                    <Column padding="8px 16px" width="100%">
                                        <Section marginBottom="30px">
                                            <InputField wide label="Brand" name="brand" placeholder="Enter Brand" />
                                        </Section>

                                        <Section marginBottom="14px">
                                            <InputField
                                                wide
                                                label="Product"
                                                name="product"
                                                placeholder="Enter Product"
                                            />
                                        </Section>
                                    </Column>

                                    <FooterWrapper justifyEnd noWrap>
                                        <Button buttonType="text-black" textSize="small" onClick={onClose}>
                                            Cancel
                                        </Button>

                                        <Row marginLeft="12px">
                                            <Button disabled={!isValid || !dirty} type="submit">
                                                Save
                                            </Button>
                                        </Row>
                                    </FooterWrapper>
                                </FormWrapper>
                            )}
                        </Formik>
                    </Section>
                </Column>
            )}
        </ModalWrapper>
    );
};
