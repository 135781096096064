import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Events

const setUser = createEvent<YEAY.AdminGetUserCommon>();
const resetUser = createEvent();

// Effects

const getUserByIdFx = createEffect({
    handler: async (userId: string) => {
        try {
            const data = await API.user.getUserById({
                id: userId
            });

            if (data) {
                return data;
            } else {
                throw new Error();
            }
        } catch {
            notify('There was a problem receiving single user, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $user = createStore<YEAY.AdminGetUserCommon | null>(null)
    .on(getUserByIdFx.doneData, (_, state) => state)
    .on(setUser, (_, newState) => newState)
    .reset(resetUser);

// Exports

export const singleUserEvents = {
    resetUser,
    setUser
};

export const singleUserEffects = {
    getUserByIdFx
};

export const singleUserStores = {
    $user
};
