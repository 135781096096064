import * as adminUsers from './admin-users';
import * as adminVideos from './admin-video';
import * as curation from './curation';
import * as media from './media';
import * as playlist from './playlist';
import * as statistics from './statistics';
import * as user from './user';
import * as video from './video';

export const API = {
    video,
    media,
    user,
    playlist,
    adminVideos,
    adminUsers,
    curation,
    statistics
};
