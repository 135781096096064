import { useStore } from 'effector-react';
import React from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { campaignManagerReportsEvents, campaignManagerReportsStores } from 'v2/stores/reports/campaign-manager';
import { reportTypeOptions } from './constants';

export const ReportType = () => {
    const { ReportType: type } = useStore(campaignManagerReportsStores.$campaignManagerReportsFilters);

    const onChangeType = (type: SelectOption) => {
        campaignManagerReportsEvents.setOverwriteFilters({
            ReportType: type.value
        });
    };

    return <Select wide options={reportTypeOptions} title="Report Type" value={type} onChange={onChangeType} />;
};
