import styled, { css } from 'styled-components';
import { grey } from 'v2/constants/styles/colors';

interface Props {
    src?: string;
}

export const Background = styled.div<Props>`
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: ${grey[100]};
    ${({ src }) => src && `background-image: url(${src})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${({ draggable }) =>
        draggable &&
        css`
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        `};
`;
