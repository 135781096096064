import { sample } from 'effector';
import { requestsEffects, requestsEvents, requestsStores } from 'v2/stores/collaboration/details/requests';

const { deleteCollaborationRequestFx } = requestsEffects;
const { $requests } = requestsStores;
const { updateRequests } = requestsEvents;

sample({
    clock: deleteCollaborationRequestFx.doneData,
    source: $requests,
    fn: (sourceData: YECM.ParticipationsResponseApiResponse, userId) => {
        if (userId && sourceData?.data?.items) {
            const newItems = sourceData.data.items.filter(item => item.participantId !== userId);
            return { ...sourceData, data: { ...sourceData.data, items: newItems } };
        }

        return sourceData;
    },
    target: updateRequests
});
