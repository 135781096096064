import { useStore } from 'effector-react';
import React, { FC, MouseEvent } from 'react';
import { DownloadIcon } from 'v2/assets/icons';
import { Video } from 'v2/components/ui/Video';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { VideoSourceState } from 'v2/constants/services/video';
import { VideoCurationState } from 'v2/constants/services/videos';
import { textColors } from 'v2/constants/styles/colors';
import { VideoWrapper } from 'v2/pages/Video/tabs/VideoTab/VideoInfoLeft/styles';
import { authStores } from 'v2/stores/auth';
import { curateVideoModal, videoModal } from 'v2/stores/initialize-modals';
import { singleVideoStores } from 'v2/stores/single-video';

interface Props {
    video: YEAY.AdminGetVideoResponse | null;
}

export const VideoInfoLeft: FC<Props> = ({ video }) => {
    const isAdmin = useStore(authStores.$isAdmin);
    const videoSource = useStore(singleVideoStores.$videoSource);

    const curationState = (video?.validation?.yeay?.curationState || VideoCurationState.None) as VideoCurationState;

    const handleCurate = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (video?.id) {
            curateVideoModal.openModal(video?.id);
        }
    };

    const openVideoFullscreen = () => {
        if (video?.id) {
            videoModal.openModal(video.id);
        }
    };

    return (
        <SectionBorder borderRight padding="10px 16px">
            <Column>
                <VideoWrapper marginBottom="8px">
                    <Video
                        cover
                        posterSrc={video?.streaming?.details?.screenGrabUrl}
                        videoSrc={video?.streaming?.details?.hlsUrl}
                        onFullscreen={openVideoFullscreen}
                    />
                </VideoWrapper>

                <Button wide disabled={curationState !== VideoCurationState.Processing} onClick={handleCurate}>
                    Curate
                </Button>

                {isAdmin && video?.isPublic && videoSource?.status === VideoSourceState.Processed && videoSource?.url && (
                    <Section marginTop="8px">
                        <Button
                            isExternal
                            wide
                            buttonType="text-black"
                            href={videoSource.url}
                            icon={<DownloadIcon color={textColors.subtext} height={12} width={12} />}
                            iconPosition="right"
                        >
                            Download
                        </Button>
                    </Section>
                )}
            </Column>
        </SectionBorder>
    );
};
