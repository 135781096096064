import React, { FC } from 'react';
import { InstagramIcon, SnapchatIcon, TikTokIcon, TwitchIcon, TwitterIcon, YoutubeIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { Wrapper } from './styles';

interface Props {
    text: string;
    title: string;
}

const Icon = ({ networkName }: { networkName: string }) => {
    switch (networkName.toLocaleLowerCase()) {
        case ExternalNetworkKey.Twitter:
            return <TwitterIcon height="10px" width="12px" />;
        case ExternalNetworkKey.Instagram:
            return <InstagramIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Tiktok:
            return <TikTokIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Youtube:
            return <YoutubeIcon height="8px" width="12px" />;
        case ExternalNetworkKey.Snapchat:
            return <SnapchatIcon height="10px" width="12px" />;
        case ExternalNetworkKey.Twitch:
            return <TwitchIcon height="10px" width="10px" />;
        default:
            return null;
    }
};

export const CreatorReachBlock: FC<Props> = ({ text, title }) => (
    <Wrapper alignCenter noWrap>
        <Text size="-2">{text}</Text>
        <Row alignCenter marginLeft="6px">
            <Icon networkName={title || ''} />
        </Row>
    </Wrapper>
);
