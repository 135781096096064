import styled, { createGlobalStyle } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { PoppinsFontFamily, SfUiTextFontFamily } from 'v2/constants/styles/fonts';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    isOpen?: boolean;
}

export const ArrowWrapper = styled.div<Props>`
    ${flexCenter};
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};
    transition: transform ${transitionTime} linear;
`;

interface CustomInputWrapperProps {
    isDisabled?: boolean;
}

export const CustomInputWrapper = styled(Section)<CustomInputWrapperProps>`
    ${({ isDisabled }) => (isDisabled ? 'pointer-events: none' : 'cursor: pointer')};
`;

export const DataStyle = createGlobalStyle`
    .react-datepicker {
      border-radius: 8px;
      background-color: ${brandColors.white};
    }

    .react-datepicker__month-container {
      background-color: ${brandColors.white};
      border-radius: 8px !important;
      padding: 0 0 8px 0 !important;
    }

    .react-datepicker__header {
        padding: 0 !important;
        background-color: ${brandColors.white} !important;
        border-radius: 8px 8px 0px 0px !important;
    }

    .react-datepicker__current-month {
        padding: 18px 0 !important;
        font-family: ${PoppinsFontFamily};
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: ${brandColors.black};
        background-color: ${brandColors.white};
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        border-top: 1px solid ${grey[100]} !important;
    }

    .react-datepicker__day-name {
        ${flexCenter};
        width: 38px;
        margin: 0 !important;
        height: 38px !important;
        font-size: 12px !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        color: ${grey[400]} !important;
    }

    .react-datepicker__day {
        margin: 0 !important;
        width: 38px;
        height: 38px !important;
        font-family: ${SfUiTextFontFamily};
        font-size: 14px;
        line-height: 20px;
        color: ${brandColors.black};
        padding-top: 6px;
        border-radius: 8px;
        transition: color ${transitionTime} linear, background-color ${transitionTime} linear;
    }

    .react-datepicker__day--in-range {
        background-color: ${brandColors.green};
        box-shadow: none;
    }


    .react-datepicker__day--in-range:hover {
        background-color: ${brandColors.green};
        box-shadow: none;
    }

    .react-datepicker__day:hover {
        background-color: ${grey[200]};
        color: ${brandColors.black};
        border-radius: 8px;
        box-shadow: none;
    }

    .react-datepicker__day:focus {
        outline: none;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: ${brandColors.green};
        box-shadow: none;
    }

    .react-datepicker__day--keyboard-selected:hover {
        background-color: ${brandColors.green};
    }

    .react-datepicker__day--selected {
        background-color: ${brandColors.green};
        box-shadow: none;
    }

    .react-datepicker__day--selected:hover {
        background-color: ${brandColors.green};
    }

    .react-datepicker__day--disabled {
        color: ${grey[400]};
    }

    .react-datepicker__day--disabled:hover {
        background-color: ${brandColors.white};
        color: ${grey[400]};
    }

    .react-datepicker__navigation--previous {
        right: 10px;
        top: 14px;
    }

    .react-datepicker__navigation--previous:hover {
        top: 14px;
    }


    .react-datepicker__navigation--next {
        right: 10px;
        top: 14px;
    }

    .react-datepicker__navigation--next:hover {
        top: 14px;
    }

    .react-datepicker__navigation-icon::before {
        border-color: ${grey[400]};
        height: 6px;
        width: 6px;
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
        border-bottom-color: transparent;
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        border-bottom-color: transparent;
    }
`;
