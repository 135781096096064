import { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { SfUiTextFontFamily } from 'v2/constants/styles/fonts';
import { transitionTime } from 'v2/constants/styles/special';

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const defaultInputStyles = css`
    font-family: ${SfUiTextFontFamily};
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    color: ${brandColors.black};

    &::placeholder {
        color: ${grey[700]};
    }
`;

export const disableScrollbarMixin = css`
    ::-webkit-scrollbar {
        display: none;
    }
`;

// TODO remove important after deleting old global styles
export const scrollbarMixin = css`
    ::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
    }

    ::-webkit-scrollbar-track {
        background: none !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${grey[400]} !important;
        border-radius: 10px !important;

        &:hover {
            background-color: ${grey[600]} !important;
        }
    }
`;

export const fixedCenterAlignmentMixin = css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const absoluteCenterAlignmentMixin = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const defaultBoxShadow = css`
    box-shadow: 12px 12px 16px rgba(0, 0, 0, 0.16);
`;

export const iconWrapperMixin = css`
    width: 24px;
    height: 24px;
    ${flexCenter};
    flex-shrink: 0;
`;

export const cardStylesMixin = css`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    background-color: ${brandColors.white};
    border-radius: 8px;
    overflow: hidden;
    transition: background-color ${transitionTime} linear;
    cursor: pointer;

    &:hover {
        background-color: ${grey[100]};
    }
`;
