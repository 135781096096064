import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const CardWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 160px auto;
    border-radius: 4px;
    background-color: ${brandColors.white};
    overflow: hidden;
`;

export const VideoWrapper = styled(Column)`
    width: 125px;
    height: 190px;
    border-radius: 16px;
    overflow: hidden;
`;

export const PropertiesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

interface Props {
    column?: string;
}

export const GridItem = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    ${({ column }) => column && `grid-column: ${column}`};
    border-right: 1px solid ${brandColors.grey};
    border-bottom: 1px solid ${brandColors.grey};
`;

export const StyledLink = styled(Link)`
    text-decoration: underline;
    transition: all ${transitionTime} linear;

    &:hover {
        text-decoration: none;
    }
`;
