import styled, { css } from 'styled-components';
import { textColors } from 'v2/constants/styles/colors';
import { SfUiTextFontFamily } from 'v2/constants/styles/fonts';

export type Size = '-2' | '-1' | '0' | '1' | '2' | '3' | '4';

export interface TextProps {
    color?: string;
    size?: Size;
    isSemibold?: boolean;
    isUppercase?: boolean;
    isUnderline?: boolean;
    noTextWrap?: boolean;
    wordBreak?: string;
    alignTextCenter?: boolean;
}

export const getTitleStyles = (size: Size) => {
    switch (size) {
        case '-2':
            return css`
                font-size: 12px;
                line-height: 18px;
            `;
        case '-1':
            return css`
                font-size: 14px;
                line-height: 20px;
            `;
        case '0':
        default:
            return css`
                font-size: 16px;
                line-height: 24px;
            `;
        case '1':
            return css`
                font-size: 18px;
                line-height: 26px;
            `;
        case '2':
            return css`
                font-size: 20px;
                line-height: 30px;
            `;
        case '3':
            return css`
                font-size: 22px;
                line-height: 32px;
            `;
        case '4':
            return css`
                font-size: 24px;
                line-height: 36px;
            `;
    }
};

export const Text = styled.span<TextProps>`
    font-family: ${SfUiTextFontFamily};
    font-weight: ${({ isSemibold }) => (isSemibold ? 600 : 400)};
    color: ${({ color }) => color || textColors.main};
    ${({ size = '0' }) => getTitleStyles(size)};
    ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
    ${({ alignTextCenter }) => alignTextCenter && 'text-align: center'};
    ${({ isUnderline }) => isUnderline && 'text-decoration: underline'};
    ${({ noTextWrap }) => noTextWrap && 'white-space: nowrap'};
    ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};
`;
