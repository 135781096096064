import * as admin from './admin';
import * as brands from './brands';
import * as categories from './categories';
import * as collaboration from './collaboration';
import * as collaborations from './collaborations';
import * as externalNetworks from './external-networks';
import * as reports from './reports';
import * as target from './target';
import * as transactions from './transactions';

export const yecmAPI = {
    admin,
    collaborations,
    collaboration,
    categories,
    externalNetworks,
    brands,
    reports,
    transactions,
    target
};
