import { useStore } from 'effector-react';
import React from 'react';
import { VideoCard } from 'v2/components/cards/VideoCard';
import { Empty } from 'v2/components/common/Empty';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { videosPageDomain } from 'v2/stores/videos';
import { VideosFilterLayout } from './VideosFilterLayout';

const { effects, stores } = videosPageDomain;

const { getVideosFx } = effects;
const { $videos } = stores;

export const Videos = () => {
    const isLoading = useStore(getVideosFx.pending);
    const videosResponse = useStore($videos);
    const videos = videosResponse?.items || [];

    return (
        <MainLayout>
            <VideosFilterLayout isLoading={isLoading}>
                {isLoading ? (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                ) : (
                    <>
                        {videos.length > 0 ? (
                            <CardsGridLayout>
                                {videos.map(video => (
                                    <VideoCard key={video.id} data={video} />
                                ))}
                            </CardsGridLayout>
                        ) : (
                            <Empty title="No Videos Found" />
                        )}
                    </>
                )}
            </VideosFilterLayout>
        </MainLayout>
    );
};
