import styled from 'styled-components';
import { Column, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const SubmissionsFilterWrapper = styled(Column)`
    border-radius: 4px 4px 0 0;
`;

export const SectionBorderWrapper = styled(SectionBorder)`
    background-color: ${brandColors.white};
`;
