import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';

export const Wrapper = styled(Section)`
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: ${brandColors.white};
    padding: 10px;
`;

export const SectionButton = styled(Section)`
    background-color: ${grey[200]};
`;
