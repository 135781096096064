import styled, { css } from 'styled-components';
import { grey, red } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';
import { StatusBadgeProps } from 'v2/pages/Collaboration/Enrollments/StatusBadge';

const stylesMixin = css`
    ${disableDefaultButtonStyleMixin};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
`;

export const StyledLink = styled.a<StatusBadgeProps>`
    ${stylesMixin};
    padding: 6px 12px;
    border-radius: 4px;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${({ isReview }) => (isReview ? red[50] : grey[100])};
    }
`;
