import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const TextWrapper = styled(Row)`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const ElementWrapper = styled(Row)`
    &:not(:first-child) {
        margin-left: 8px;
    }
`;

export const Point = styled.div`
    width: 6px;
    height: 6px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: ${brandColors.black};
`;

export const ContentWrapper = styled(Column)`
    background-color: ${brandColors.white};
`;

export const DescriptionText = styled(Text)`
    word-break: break-all;
`;
