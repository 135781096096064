import styled from 'styled-components';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { grey12 } from 'v1/constants/styles/colors';
import { fullHeaderHeight, tableBorder } from 'v1/constants/styles/sizes';

export const BlacklistedLayoutWrapper = styled.div`
    background: ${grey12};
    min-height: calc(100vh - ${fullHeaderHeight} - 125px);
    width: 100%;
    padding: 20px 25px;
    margin-top: 70px;
    border-radius: 25px;
`;

export const LayoutContentWrapper = styled(ContentWrapper)`
    min-height: calc(100vh - ${fullHeaderHeight} - 125px);
    width: 800px;
    padding: 16px 30px;
    border-radius: 8px 8px 0px 0px;
    border: ${tableBorder};
`;
