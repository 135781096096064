import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const LinkUserInfo = styled(Link)`
    padding: 4px;
    border-radius: 4px;
    display: flex;
    width: 100%;
    transition: background-color ${transitionTime} linear, text-decoration ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
        text-decoration: underline;
        text-decoration-color: ${brandColors.black};
    }
`;

export const ContentWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 32px;
`;
