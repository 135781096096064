import styled from 'styled-components';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

interface FiltersWrapperProps {
    isLoading?: boolean;
}

export const FiltersWrapper = styled(Column)<FiltersWrapperProps>`
    width: 100%;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};

    ${({ isLoading }) => isLoading && 'pointer-events: none'};
`;

export const ChildrenWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding: 16px 32px 0 16px;
`;

export const BottomWrapper = styled(Section)`
    flex-wrap: nowrap;
    padding: 10px;
    border-radius: 8px;
    background-color: ${brandColors.white};
    margin-top: 8px;
`;
