export const statisticsTableColumns = [
    {
        title: 'User ID',
        dataIndex: 'userId',
        key: 'userId',
        width: 3
    },
    {
        title: 'Video ID',
        dataIndex: 'videoId',
        key: 'videoId',
        width: 2
    },
    {
        title: 'View',
        dataIndex: 'viewCount',
        key: 'viewCount',
        width: 1
    },
    {
        title: 'Total',
        dataIndex: 'totalCount',
        key: 'totalCount',
        width: 1
    },
    {
        title: 'Comments',
        dataIndex: 'commentsCount',
        key: 'commentsCount',
        width: 1
    },

    {
        title: 'Like',
        dataIndex: 'likeCount',
        key: 'likeCount',
        width: 1
    },
    {
        title: 'Save',
        dataIndex: 'saveCount',
        key: 'saveCount',
        width: 1
    },
    {
        title: 'Share',
        dataIndex: 'shareCount',
        key: 'shareCount',
        width: 1
    },
    {
        title: 'Click',
        dataIndex: 'clickCount',
        key: 'clickCount',
        width: 1
    }
];
