export enum StatusType {
    NotSet,
    Review,
    Accepted,
    Rejected
}

export const reportStatus = {
    [StatusType.NotSet]: '',
    [StatusType.Review]: 'Pending',
    [StatusType.Accepted]: 'Valid',
    [StatusType.Rejected]: 'Invalid'
};

export const reportTableStatus = {
    [StatusType.NotSet]: '',
    [StatusType.Review]: 'Pending',
    [StatusType.Accepted]: 'Accepted',
    [StatusType.Rejected]: 'Rejected'
};
