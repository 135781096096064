import styled, { css } from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors, green, red } from 'v2/constants/styles/colors';
import { CommonVideoStateType } from './types';

interface TextProps {
    type: CommonVideoStateType;
}

export const getTitleStyles = (type: CommonVideoStateType) => {
    switch (type) {
        case CommonVideoStateType.Pending:
            return css`
                color: ${brandColors.black};
            `;
        case CommonVideoStateType.Valid:
            return css`
                color: ${green[700]};
            `;
        case CommonVideoStateType.Invalid:
        default:
            return css`
                color: ${red[300]};
            `;
    }
};

export const TextWrapper = styled(Text)<TextProps>`
    margin-left: 6px;
    ${({ type }) => getTitleStyles(type)};
`;
