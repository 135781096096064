export const defaultBrandsParams: YECM.QueryOrganizationRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};

export const defaultBrandUsersParams: Omit<YECM.OrganizationQueryUsersRequest, 'organizationId'> = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};
