import React, { FC } from 'react';
import { VideoCard } from 'v2/components/cards/VideoCard';
import { Empty } from 'v2/components/common/Empty';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Pagination } from 'v2/components/ui/Pagination';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { defaultVideosParams } from 'v2/constants/services/videos';
import { VideosParamsWithVideoId, agentVideosDomain } from 'v2/stores/videos';
import { getPageIndex } from 'v2/utils/services';
import { PaginationWrapper } from './styles';

interface Props {
    isLoading: boolean;
    videoInfo: YEAY.QueryAllVideosResponse | null;
    filters: VideosParamsWithVideoId;
    agentId?: string;
}

const { addFilters } = agentVideosDomain.events;

export const AgentVideos: FC<Props> = ({ isLoading, videoInfo, filters, agentId }) => {
    const limit = filters.limit;
    const currentPage = (filters.pageIndex || 0) + 1;
    const totalRecords = videoInfo?.totalRecords || 0;

    const handlePageChange = (page: number) => {
        addFilters({ agentId, pageIndex: getPageIndex(page) });
    };

    const handleLimitChange = (size: number) => {
        addFilters({ agentId, limit: size, pageIndex: defaultVideosParams.pageIndex });
    };

    return (
        <Section>
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <>
                    {videoInfo?.items && videoInfo.items.length > 0 ? (
                        <>
                            <CardsGridLayout>
                                {videoInfo.items.map(video => (
                                    <VideoCard key={video.id} data={video} />
                                ))}
                            </CardsGridLayout>

                            <PaginationWrapper>
                                <SectionBorder borderTop padding="0 8px">
                                    <Pagination
                                        currentPage={currentPage}
                                        size={limit}
                                        totalItems={totalRecords}
                                        onChange={handlePageChange}
                                        onPageSizeChange={handleLimitChange}
                                    />
                                </SectionBorder>
                            </PaginationWrapper>
                        </>
                    ) : (
                        <Empty title="No Videos Found" />
                    )}
                </>
            )}
        </Section>
    );
};
