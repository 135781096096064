import { createEffect, createStore } from 'effector';
import { API } from 'v2/services/yasy';

// Effects

const getCountriesFx = createEffect({
    handler: async () => {
        try {
            const { countries } = await API.location.getCountries();

            if (!countries) {
                throw new Error();
            }

            return countries.sort((a, b) => {
                if ((a.countryName || '') < (b.countryName || '')) {
                    return -1;
                }

                if ((a.countryName || '') > (b.countryName || '')) {
                    return 1;
                }

                return 0;
            });
        } catch (e) {
            console.log('Failed to get countries', e);
            return [];
        }
    }
});

const getRegionsByCountryFx = createEffect({
    handler: async (country: string) => {
        try {
            const { regions } = await API.location.getRegionsByCountry({ country });

            if (!regions) {
                throw new Error();
            }

            return {
                country,
                regions: regions.sort()
            };
        } catch (e) {
            console.log('Failed to get regions', e);
            return null;
        }
    }
});

// Stores

interface Location extends YEAY.CountryResponse {
    regions?: string[];
}

const $locations = createStore<Location[]>([])
    .on(getCountriesFx.doneData, (_, locations) => locations)
    .on(getRegionsByCountryFx.doneData, (state, regionsResponse) => {
        if (!regionsResponse) {
            return state;
        }

        return state.map(location => {
            if (location.countryCode === regionsResponse.country) {
                return {
                    ...location,
                    regions: regionsResponse.regions
                };
            }

            return location;
        });
    });

const $countriesIsLoaded = createStore(false).on(getCountriesFx.finally, () => true);

// Exports

export const locationsEffects = { getCountriesFx, getRegionsByCountryFx };

export const locationsStores = { $locations, $countriesIsLoaded };
