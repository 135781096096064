import styled from 'styled-components';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import {
    modalContentWrapperPaddingLeft,
    modalContentWrapperPaddingRight,
    modalContentWrapperVerticalPadding
} from 'v1/components/wrappers/ModalWrapper/constant';
import { semitransparentBlack, white } from 'v1/constants/styles/colors';
import { absoluteCenterAlignmentMixin } from 'v1/constants/styles/mixins';
import { sideBarWidth, sideBarZIndex, xxl_1 } from 'v1/constants/styles/sizes';
import { Overflow } from 'v1/types/styles';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${semitransparentBlack};
    padding-left: ${sideBarWidth};
    padding: 220px ${modalContentWrapperPaddingRight} ${modalContentWrapperVerticalPadding}
        ${modalContentWrapperPaddingLeft};
    z-index: ${sideBarZIndex + 1};
    overflow: auto;

    @media (max-width: ${xxl_1}) {
        padding-left: ${modalContentWrapperVerticalPadding};
    }
`;

export const ModalContentWrapper = styled(ContentWrapper)<Overflow>`
    ${absoluteCenterAlignmentMixin};
    position: fixed;
    display: flex;
    flex-direction: column;
    background: ${white};
    min-width: 300px;
    max-width: 80vw;
    max-height: 80vh;
    overflow: ${({ overflow }) => overflow || 'auto'};
    z-index: ${sideBarZIndex + 2};
`;
