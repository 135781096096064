import { createEvent, restore } from 'effector';
import { AsyncModal } from 'v1/types/modals';

const initialAsyncModal: AsyncModal = { visible: false, title: '', content: '', subject: '' };

const openAsyncModal = createEvent<AsyncModal>();
const closeAsyncModal = createEvent();

const asyncModalStore = restore<AsyncModal>(openAsyncModal, initialAsyncModal).on(
    closeAsyncModal,
    () => initialAsyncModal
);

export const modalEvents = {
    openAsyncModal,
    closeAsyncModal
};

export const modalStores = {
    asyncModalStore
};
