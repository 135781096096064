export const blacklistedTableColumnTitles = ['User ID', 'Email Address', 'Mobile Number', 'Username'];
export const defaultTitles = [blacklistedTableColumnTitles[0], blacklistedTableColumnTitles[1]];

//* Mock Blacklist for storybook
export const testType = 3;
const testValuesArray = ['373jf7fh487444f89fyf2', 'test-email@gmail.com', '(704) 555-0127', 'TestUserName'];

export type SearchType = 1 | 2 | 3;

export const MockBlacklistedUsers: YEAY.GetBlacklistedUsersResponse[] = [
    {
        id: testValuesArray[0],
        type: testType,
        searchTextValue: testValuesArray[testType]
    },
    {
        id: testValuesArray[0],
        type: testType,
        searchTextValue: testValuesArray[testType]
    },
    {
        id: testValuesArray[0],
        type: testType,
        searchTextValue: testValuesArray[testType]
    },
    {
        id: testValuesArray[0],
        type: testType,
        searchTextValue: testValuesArray[testType]
    }
];
