import styled from 'styled-components';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const Wrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    border: 1px solid ${grey[100]};
`;

export const ActionBlockWrapper = styled.div`
    height: 32px;
    min-width: 64px;
    width: 64px;
    background-color: ${brandColors.white};
    padding: 8px;
    ${flexCenter};
    border-radius: 4px;
`;

export const Divider = styled.div`
    margin: 0 5px;
    width: 1px;
    background-color: ${grey[400]};
    height: 10px;
    opacity: 0.8;
`;

export const StyledRelativeWrapper = styled(RelativeWrapper)`
    &:hover ${CardHoverOpacityEffect} {
        opacity: 1;
    }
`;
