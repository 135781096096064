import { createEffect } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';
import { blockUserModal, editUserRoleModal } from 'v2/stores/initialize-modals';

// Types

interface EditUserRoles {
    userId: string;
    roles: string[];
}

interface EditUserVerify {
    userId: string;
    isTrusted: boolean;
}

// Effects

const blockUserByIdFx = createEffect({
    handler: async (data: YEAY.DisableUserInfo) => {
        try {
            const { isSuccess, message } = await API.user.blockUsersById({
                users: [data]
            });
            if (isSuccess && data.userId) {
                notify('User successfully blocked!');
                return data.userId;
            }
            if (!isSuccess && message) {
                notify(message);
            } else {
                throw new Error();
            }
            return '';
        } catch {
            notify('An error occurred while trying to block a user', 'error');
            return '';
        } finally {
            blockUserModal.closeModal();
        }
    }
});

const enableUserByIdFx = createEffect({
    handler: async (userId: string) => {
        try {
            const { isSuccess, message } = await API.user.enableUsersById({
                users: [userId]
            });
            if (isSuccess) {
                notify('User successfully unblocked!');
                return userId;
            }
            if (!isSuccess && message) {
                notify(message);
            } else {
                throw new Error();
            }
            return '';
        } catch {
            notify('An error occurred while trying to unblock a user', 'error');
            return '';
        }
    }
});

const editUserRolesFx = createEffect({
    handler: async ({ userId, roles }: EditUserRoles) => {
        try {
            const result = await API.user.updateUserById({
                id: userId,
                roles
            });
            if (result) {
                notify('User roles successfully edited!');
                return userId;
            } else {
                throw new Error();
            }
        } catch {
            notify('An error occurred while trying to edit user roles', 'error');
            return '';
        } finally {
            editUserRoleModal.closeModal();
        }
    }
});

const editUserVerifyFx = createEffect({
    handler: async ({ userId, isTrusted }: EditUserVerify) => {
        try {
            const result = await API.user.updateUserById({
                id: userId,
                isTrusted
            });
            if (!result) {
                throw new Error();
            }
            return userId;
        } catch {
            notify('An error occurred while trying to edit user verify', 'error');
            return '';
        } finally {
            editUserRoleModal.closeModal();
        }
    }
});

// Exports

export const singleUserActionsEffects = {
    blockUserByIdFx,
    enableUserByIdFx,
    editUserRolesFx,
    editUserVerifyFx
};
