import React, { FC, ReactNode } from 'react';
import { arrowPath } from 'v2/assets/icons';
import { Image } from 'v2/components/ui/Image';
import { Title } from 'v2/components/ui/typography/Title';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { useToggle } from 'v2/hooks/use-toggle';
import { DropdownSectionButton, DropdownSectionWrapper } from './styles';

interface Props {
    title: string;
    defaultIsOpened?: boolean;
    extra?: ReactNode;
}

export const AccordionSection: FC<Props> = ({ extra, children, title, defaultIsOpened = false }) => {
    const [isOpened, toggleIsOpened] = useToggle(defaultIsOpened);

    return (
        <DropdownSectionWrapper>
            <DropdownSectionButton onClick={toggleIsOpened}>
                <Title size="-2">{title}</Title>

                <Row alignCenter>
                    {extra && <Row marginRight="20px">{extra}</Row>}
                    <Image height="6px" rotate={isOpened ? -180 : 0} src={arrowPath} width="10px" />
                </Row>
            </DropdownSectionButton>
            {isOpened && children}
        </DropdownSectionWrapper>
    );
};
