import styled, { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    selected?: boolean;
}

export const CardWrapper = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background-color: ${brandColors.grey};
    border-radius: 8px;
    border: 2px solid ${({ selected }) => (selected ? brandColors.green : 'transparent')};
    transition: border ${transitionTime} linear;

    &:disabled {
        cursor: not-allowed;
    }

    ${({ selected }) =>
        !selected &&
        css`
            &:hover:not(:disabled) {
                border-color: ${grey[600]};
            }
        `};
`;
