import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { appReportsEvents, appReportsStores } from 'v2/stores/reports/app';

export const ReportDate = () => {
    const { DateFirstReportedStart, DateFirstReportedEnd } = useStore(appReportsStores.$appReportsFilters);

    const initialRange = [new Date(), new Date()] as DatePickerType;
    const [dateRange, setDateRange] = useState<DatePickerType>(initialRange);
    const [startDate, endDate] = dateRange as Date[];

    useEffect(() => {
        if (DateFirstReportedStart && DateFirstReportedEnd) {
            setDateRange([new Date(DateFirstReportedStart), new Date(DateFirstReportedEnd)]);
        } else {
            setDateRange(initialRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DateFirstReportedStart, DateFirstReportedEnd]);

    const onChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            appReportsEvents.setOverwriteFilters({
                DateFirstReportedStart: start.toISOString(),
                DateFirstReportedEnd: end.toISOString()
            });
        }
    };

    return (
        <DatePicker
            endDate={endDate}
            endPlaceholder=" 2020-12-26"
            startDate={startDate}
            startPlaceholder="2020-12-25 -"
            title="Dates"
            onChange={onChangeDate}
        />
    );
};
