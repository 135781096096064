import React, { FC } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { LinkUserInfo } from './styles';

interface TextBlockProps {
    contentValue?: number | string;
}

const TextBlock: FC<TextBlockProps> = ({ contentValue }) => <Text size="-1">{contentValue}</Text>;

export const prepareDataForTable = (items: YEAY.VideoStatisticsResponse[]) =>
    items.map(item => ({
        key: item.userId,
        user: (
            <LinkUserInfo to={`${routePaths.users}/${item.userId}`}>
                <Row alignCenter noWrap>
                    <Row marginRight="8px">
                        <Avatar size="32px" src={item?.userInfo?.profileImageUrl || ''} />
                    </Row>

                    <Text isSemibold size="-2">
                        @{item?.userInfo?.username}
                    </Text>

                    {item.userInfo?.isTrusted && (
                        <Row marginLeft="8px">
                            <DoneIcon />
                        </Row>
                    )}
                </Row>
            </LinkUserInfo>
        ),
        views: <TextBlock contentValue={item.viewCount} />,
        clicks: <TextBlock contentValue={item.clickCount} />,
        shares: <TextBlock contentValue={item.shareCount} />
    }));
