import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'v2/constants/styles/mixins';

export const ButtonWrapper = styled(Section)`
    ${flexCenter};
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: ${brandColors.white};
`;

export const SectionButton = styled(Section)`
    background-color: ${grey[200]};
`;

export const UserCardsWrapper = styled.div`
    width: 100%;
    margin-top: 16px;
`;

export const UserCardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 82px;
    grid-gap: 16px 12px;
    max-height: 400px;
    overflow-y: auto;
`;

export const SelectWrapper = styled.button<{ isSelected?: boolean }>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    height: 100%;
    border-radius: 8px;

    ${({ isSelected }) =>
        isSelected
            ? css`
                  border: 4px solid ${brandColors.green};
              `
            : css`
                  &:hover {
                      border: 4px solid ${grey[600]};
                  }
              `}
`;

export const StyledRelativeWrapper = styled(RelativeWrapper)`
    overflow: hidden;
`;

export const BottomWrapper = styled(Section)`
    flex-wrap: nowrap;
    border-radius: 8px;
    background-color: ${brandColors.white};
`;

export const Wrapper = styled(Section)`
    padding: 10px;
`;
