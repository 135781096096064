import styled, { css } from 'styled-components';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const CardRow = styled(Row)`
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
`;

export const StyledColumn = styled(Column)`
    width: 180px;
`;

export const CardWrapper = styled.div`
    padding: 8px;
    width: 100%;
    height: 250px;
    border-radius: 16px;
`;

export const CountFlag = styled.div`
    background-color: ${grey[300]};
    border-bottom-right-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
`;

export const DragSpot = styled.div<{ isHovered: boolean }>`
    ${flexCenter};
    background-color: ${grey[100]};
    height: 100%;
    border-radius: 16px;

    ${({ isHovered }) =>
        isHovered &&
        css`
            border: 1px solid ${brandColors.black};
        `}
`;
