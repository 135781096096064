import React, { FC } from 'react';
import { InstagramIcon, SnapchatIcon, TikTokIcon, TwitchIcon, TwitterIcon, YoutubeIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { ExternalNetworkKey } from 'v2/constants/services/networks';

const Icon = ({ networkName }: { networkName: string }) => {
    switch (networkName.toLowerCase()) {
        case ExternalNetworkKey.Twitter:
            return <TwitterIcon height="10px" width="12px" />;
        case ExternalNetworkKey.Instagram:
            return <InstagramIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Tiktok:
            return <TikTokIcon height="10px" width="10px" />;
        case ExternalNetworkKey.Youtube:
            return <YoutubeIcon height="8px" width="12px" />;
        case ExternalNetworkKey.Snapchat:
            return <SnapchatIcon height="10px" width="12px" />;
        case ExternalNetworkKey.Twitch:
            return <TwitchIcon height="12px" width="12px" />;
        default:
            return null;
    }
};

interface Props {
    networkName: string;
    link: string;
    count: string;
}

export const FollowersItem: FC<Props> = ({ count, networkName, link }) => (
    <a href={link} rel="noopener noreferrer" target="_blank">
        <Row alignCenter noWrap padding="4px">
            <Row marginRight="6px">
                <Icon networkName={networkName || ''} />
            </Row>
            <Text size="-2">{count || 0}</Text>
        </Row>
    </a>
);
