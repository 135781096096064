import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const TagStyled = styled.div`
    width: fit-content;
    padding: 4px 8px;
    border-radius: 32px;
    text-align: start;
    align-items: center;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid ${brandColors.black};
    background-color: ${brandColors.white};
    cursor: pointer;
    transition: background-color ${transitionTime} linear;
`;

export const TagText = styled(Link)`
    ${flexCenter}
`;
