import { useStore } from 'effector-react';
import React from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { appReportsEvents, appReportsStores } from 'v2/stores/reports/app';
import { reportTypeOptions } from './constants';

export const ReportType = () => {
    const { ReportType: type } = useStore(appReportsStores.$appReportsFilters);

    const onChangeType = (type: SelectOption) => {
        appReportsEvents.setOverwriteFilters({
            ReportType: type.value
        });
    };

    return <Select wide options={reportTypeOptions} title="Report Type" value={type} onChange={onChangeType} />;
};
