import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'v2/assets/icons';
import { Spinner } from 'v2/components/loaders/Spinner';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { KeyboardCode } from 'v2/constants/common';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { usePagination } from 'v2/hooks/use-pagination';
import { SizeSelect } from './SizeSelect';
import { defaultPageSize, defaultPageSizes, defaultSiblingCount } from './constants';
import { LoadingWrapper, PageButton, SearchInput, SearchWrapper, Wrapper } from './styles';

/**
 * currentPage - page index starting from 1;
 * totalItems - total count of items;
 * totalPages - total pages;
 * onChange - callback accepted by the selected page;
 * size - number of items per page (optional);
 * siblingCount - min number of page buttons to be shown on each side of the current page button (optional);
 * isLoading - loading state (optional);
 */
export interface PaginationProps {
    currentPage: number;
    onChange: (page: number) => void;
    totalItems?: number;
    totalPages?: number;
    size?: number;
    pageSizes?: number[];
    onPageSizeChange?: (size: number) => void;
    siblingCount?: number;
    isLoading?: boolean;
}

export const Pagination: FC<PaginationProps> = ({
    currentPage,
    onChange,
    onPageSizeChange,
    totalItems = 0,
    totalPages = 0,
    size = defaultPageSize,
    pageSizes = defaultPageSizes,
    siblingCount = defaultSiblingCount,
    isLoading = false
}) => {
    const [searchValue, setSearchValue] = useState<string | number>(currentPage);
    const { totalPageCount, pagination } = usePagination({
        currentPage,
        totalItems,
        totalPages,
        size,
        siblingCount
    });

    const handlePageChange = (page: string | number) => () => {
        typeof page === 'number' && onChange(page);
    };

    const toNextPage = () => {
        currentPage + 1 <= totalPageCount && onChange(currentPage + 1);
    };

    const toPrevPage = () => {
        currentPage - 1 >= 1 && onChange(currentPage - 1);
    };

    const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleSearchPage = () => {
        const searchNumber = +searchValue;
        const value = searchNumber > totalPageCount ? totalPageCount : searchNumber < 1 ? 1 : searchNumber;
        setSearchValue(value);

        if (currentPage !== value) {
            onChange(value);
        }
    };

    const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        event.persist();
        if (event.nativeEvent.code === KeyboardCode.Enter) {
            event.preventDefault();
            handleSearchPage();
        }
    };

    useEffect(() => {
        setSearchValue(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (totalPageCount > 0 && currentPage > totalPageCount) {
            onChange(totalPageCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPageCount]);

    if (pagination.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            {isLoading && (
                <LoadingWrapper>
                    <Spinner size={18} />
                </LoadingWrapper>
            )}

            {onPageSizeChange ? (
                <SizeSelect activeSize={size} disabled={isLoading} sizes={pageSizes} onChange={onPageSizeChange} />
            ) : (
                <Text size="-1">{size}/Page</Text>
            )}

            <Row alignCenter>
                <Column marginRight="32px">
                    <IconButton
                        color={grey[500]}
                        disabled={isLoading || currentPage === 1}
                        hoverColor={brandColors.black}
                        onClick={toPrevPage}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Column>

                {pagination.length > 0 &&
                    pagination.map((page, index) => (
                        <PageButton
                            key={index}
                            disabled={currentPage === page || typeof page === 'string' || isLoading}
                            isCurrent={typeof page === 'number' && currentPage === page}
                            type="button"
                            onClick={handlePageChange(page)}
                        >
                            <Text color="inherit" size="-1">
                                {page}
                            </Text>
                        </PageButton>
                    ))}

                <Column marginLeft="32px">
                    <IconButton
                        color={grey[500]}
                        disabled={isLoading || currentPage === totalPageCount}
                        hoverColor={brandColors.black}
                        onClick={toNextPage}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Column>
            </Row>

            <SearchWrapper>
                <Column marginRight="8px">
                    <Text size="-1">Go to page</Text>
                </Column>

                <SearchInput
                    disabled={isLoading}
                    max={totalPageCount}
                    min={1}
                    placeholder="№"
                    type="number"
                    value={searchValue}
                    onBlur={handleSearchPage}
                    onChange={handleSearchValueChange}
                    onKeyDown={handleEnter}
                />
            </SearchWrapper>
        </Wrapper>
    );
};
