import { createEffect, createStore } from 'effector';
import { yecmAPI } from 'v2/services/yecm';

// Effects

const getTargetLanguagesFx = createEffect({
    handler: async () => {
        try {
            const { isSuccess, data } = await yecmAPI.target.getTargetLanguages();

            if (!isSuccess || !data?.languages) {
                throw new Error();
            }

            return data.languages;
        } catch {
            return [];
        }
    }
});

// Stores

const $targetLanguages = createStore<string[]>([]).on(getTargetLanguagesFx.doneData, (_, languages) => languages);

// Effects

export const targetLanguageEffects = { getTargetLanguagesFx };
export const targetLanguageStores = { $targetLanguages };
