import React, { FC } from 'react';
import defaultAvatarSrc from 'v2/assets/images/default-avatar.svg';
import { defaultImgDiameter } from './constants';
import { StyledAvatar } from './styles';

export interface AvatarProps {
    size?: string;
    src?: string;
    hasBorder?: boolean;
}

export const Avatar: FC<AvatarProps> = ({ src, size = defaultImgDiameter, hasBorder = false }) => (
    <StyledAvatar hasBorder={hasBorder} size={size} src={src || defaultAvatarSrc} />
);
