import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import history from 'browserHistory';
import { baseURL } from 'v1/constants/services';
import { routePaths } from 'v2/constants/routes';
import { authEvents, authStores } from 'v2/stores/auth';

const yeayAxiosInstance = axios.create();

yeayAxiosInstance.defaults.baseURL = baseURL;
yeayAxiosInstance.defaults.method = 'POST';
yeayAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;

        if (status === 403) history.push(routePaths.error403);
        else if (status === 401) {
            authEvents.logout();
        }

        return Promise.reject(config.response);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = yeayAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${authStores.$token.getState() || ''}`
              }
            : {}
    });

    return (request as any) as Promise<T>;
};
