export enum LocationType {
    Argentina = 'AR',
    Bolivia = 'BO',
    Brazil = 'BR',
    Canada = 'CA',
    Chile = 'CL',
    Colombia = 'CO',
    CostaRica = 'CR',
    Ecuador = 'EC',
    ElSalvador = 'SV',
    Guatemala = 'GT',
    Guyana = 'GY',
    Honduras = 'HN',
    Mexico = 'MX',
    Nicaragua = 'NI',
    Panama = 'PA',
    Paraguay = 'PY',
    Peru = 'PE',
    Suriname = 'SR',
    UnitedStates = 'US',
    Uruguay = 'UY',
    Venezuela = 'VE',
    Korea = 'KR',
    Belize = 'BZ'
}

export const locations = {
    [LocationType.Argentina]: 'Argentine',
    [LocationType.Bolivia]: 'Bolivia',
    [LocationType.Brazil]: 'Brazil',
    [LocationType.Canada]: 'Canada',
    [LocationType.Chile]: 'Chile',
    [LocationType.Colombia]: 'Colombia',
    [LocationType.CostaRica]: 'Costa Rica',
    [LocationType.Ecuador]: 'Ecuador',
    [LocationType.ElSalvador]: 'El Salvador',
    [LocationType.Guatemala]: 'Guatemala',
    [LocationType.Guyana]: 'Guyana',
    [LocationType.Honduras]: 'Honduras',
    [LocationType.Mexico]: 'Mexico',
    [LocationType.Nicaragua]: 'Nicaragua',
    [LocationType.Panama]: 'Panama',
    [LocationType.Paraguay]: 'Paraguay',
    [LocationType.Peru]: 'Peru',
    [LocationType.Suriname]: 'Suriname',
    [LocationType.UnitedStates]: 'United States',
    [LocationType.Uruguay]: 'Uruguay',
    [LocationType.Venezuela]: 'Venezuela',
    [LocationType.Korea]: 'Korea',
    [LocationType.Belize]: 'Belize'
};
