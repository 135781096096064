import { createEffect, restore } from 'effector';
import { yecmAPI } from 'v2/services/yecm';

// Constants

const defaultCategoriesParams = {
    limit: 100
};

// Effects
const getCategoriesFx = createEffect({
    handler: async (params?: YecmPaths.Categories.Get.QueryParameters) => {
        const { isSuccess, data } = await yecmAPI.categories.getCategories(params || defaultCategoriesParams);

        if (isSuccess) {
            return data?.items || [];
        }

        return [];
    }
});

// Stores
const $categories = restore(getCategoriesFx, []);

// Exports
export const categoriesEffects = {
    getCategoriesFx
};

export const categoriesStores = {
    $categories
};
