import history from 'browserHistory';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { SimpleButton } from 'v1/components/common/buttons/SimpleButton/styles';
import { DropdownColumn, DropdownSection } from 'v1/components/common/dropdowns/SectionDropdown';
import { StatisticsTable } from 'v1/components/common/tables/StatisticsTable';
import { ClosableTag } from 'v1/components/common/tags/ClosableTag';
import { ContentText } from 'v1/components/common/typography/ContentText/styles';
import { Span } from 'v1/components/common/typography/Span/styles';
import { Pagination } from 'v1/components/layouts/Pagination';
import { AdministratorLayout } from 'v1/components/layouts/RolesLayouts';
import { PropertyBlock } from 'v1/components/layouts/blocks/PropertyBlock';
import {
    deletedUserPrimaryMargin,
    deletedUserSinglePadding,
    largePropertyWidth,
    propertyHeight,
    propertyWidth
} from 'v1/components/layouts/cards/DeletedUserCard/constants';
import {
    copyBlockerId,
    copyEmailMessage,
    copyPhoneMessage,
    copyUserIdMessage,
    copyUsernameMessage
} from 'v1/components/layouts/cards/UserCard/constants';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { Column, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { defaultLimit } from 'v1/constants/defaults/filterSettings';
import { ReasonDeletion, defaultDeletedUsersValues } from 'v1/constants/defaults/users';
import { black, dirtyBlue } from 'v1/constants/styles/colors';
import { statisticsEvents, statisticsStores } from 'v1/stores/statistics/statistics';
import { deletedUsersEffects, deletedUsersStores } from 'v1/stores/users/deletedUsers';
import { ChevronLeftIcon } from 'v2/assets/icons';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { routePaths } from 'v2/constants/routes';
import { deletedUserMarginRight } from './constants';

interface ParamsProps {
    deletedUserId: string;
}

export const DeletedUser = () => {
    const { deletedUserId } = useParams<ParamsProps>();
    const { items } = useStore(deletedUsersStores.deletedUsers);
    const user = items && items[0];
    const loading = useStore(deletedUsersStores.loading);
    const loadingStatistics = useStore(statisticsStores.initialLoading);
    const { items: itemsStatistics, totalRecords: totalRecordsStatistics } = useStore(statisticsStores.statistics);
    const { pageIndex: statisticsPageIndex, limit: statisticsLimit } = useStore(statisticsStores.values);

    const userId = user?.userId;
    const userName = `@${user?.userName}`;
    const email = user?.email;
    const deleterName = user?.deleterInfo?.userName;
    const deleterId = user?.deleterInfo?.userId;
    const deleterIsTrust = user?.deleterInfo?.isTrusted;
    const comment = user?.comment;
    const mobileNumber = user?.mobileNumber;
    const reasons = user?.reasons;
    const videos = user?.videoIds;

    useEffect(() => {
        deletedUsersEffects.loadItems({ ...defaultDeletedUsersValues, deletedUserId });
    }, [deletedUserId]);

    const onBack = () => history.goBack();

    const redirectTo = `${routePaths.users}/${deleterId}`;
    const moreInfoHandleClick = () => {
        if (deleterId !== '0'.repeat(24)) {
            history.push(redirectTo);
        }
    };

    const onStatisticsCurrentPageChange = (page: number, pageSize: number | undefined) =>
        statisticsEvents.updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    return (
        <MainLayout>
            <DetailsPageLayout>
                <Section marginRight={deletedUserMarginRight}>
                    <ContentWrapper
                        marginBottom={deletedUserPrimaryMargin}
                        padding={deletedUserSinglePadding}
                        width="100%"
                    >
                        {loading ? (
                            <Section justifyCenter>
                                <Spinner />
                            </Section>
                        ) : (
                            <>
                                <Section alignCenter marginBottom="28px">
                                    <MarginWrapper marginRight="24px">
                                        <IconButton onClick={onBack}>
                                            <ChevronLeftIcon />
                                        </IconButton>
                                    </MarginWrapper>
                                    <Span color={black} fontSize="18px" fontWeight="600" lineHeight="21px">
                                        Deleted User info
                                    </Span>
                                </Section>
                                <Section marginBottom="17px">
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={userId}
                                        success={copyUserIdMessage}
                                        title="user id"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={userName || '-'}
                                        success={copyUsernameMessage}
                                        title="user name"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={email || '-'}
                                        success={copyEmailMessage}
                                        title="Email Address"
                                        width={propertyWidth}
                                    />
                                    <SimpleButton
                                        background="none"
                                        marginBottom="4px"
                                        marginRight={deletedUserPrimaryMargin}
                                        padding="0"
                                        onClick={moreInfoHandleClick}
                                    >
                                        <PropertyBlock
                                            isTrusted={deleterIsTrust || false}
                                            marginRight={deletedUserPrimaryMargin}
                                            minHeight={propertyHeight}
                                            subtitle={deleterName || '-'}
                                            title="Deleted by"
                                            width={propertyWidth}
                                        />
                                    </SimpleButton>
                                </Section>
                                <Section marginBottom="16px">
                                    <PropertyBlock
                                        marginRight="8px"
                                        minHeight={propertyHeight}
                                        subtitle={comment || '-'}
                                        title="Comment"
                                        width={largePropertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight="8px"
                                        minHeight={propertyHeight}
                                        subtitle={mobileNumber || '-'}
                                        success={copyPhoneMessage}
                                        title="Phone Number"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        minHeight={propertyHeight}
                                        subtitle={deleterId || '-'}
                                        success={copyBlockerId}
                                        title="Deleter ID"
                                        width={propertyWidth}
                                    />
                                </Section>
                                <ContentText
                                    uppercase
                                    color={dirtyBlue}
                                    fontSize="10px"
                                    fontWeight="500"
                                    lineHeight="12px"
                                >
                                    Reason
                                </ContentText>
                                <Section marginTop={deletedUserPrimaryMargin}>
                                    {reasons?.map(reason => (
                                        <ClosableTag
                                            key={reason}
                                            untouchable
                                            marginRight="4px"
                                            subject={ReasonDeletion[reason]}
                                        />
                                    ))}
                                </Section>
                            </>
                        )}
                    </ContentWrapper>
                </Section>
                <Section marginBottom={deletedUserPrimaryMargin}>
                    <AdministratorLayout>
                        <DropdownColumn>
                            <DropdownSection border="none" title="User statistics">
                                {loadingStatistics ? (
                                    <Section justifyCenter>
                                        <Spinner />
                                    </Section>
                                ) : itemsStatistics?.length ? (
                                    <>
                                        <Section marginBottom="20px">
                                            <StatisticsTable items={itemsStatistics} removeItem="userId" />
                                        </Section>
                                        <Pagination
                                            currentIndex={statisticsPageIndex + 1}
                                            defaultSize={statisticsLimit}
                                            totalItems={totalRecordsStatistics}
                                            onSizeChange={onStatisticsCurrentPageChange}
                                        />
                                    </>
                                ) : null}
                            </DropdownSection>
                        </DropdownColumn>
                    </AdministratorLayout>
                </Section>
                <DropdownColumn>
                    <DropdownSection title={`User Videos ${videos?.length || '0'}`}>
                        {loading ? (
                            <Section justifyCenter marginBottom="20px">
                                <Spinner />
                            </Section>
                        ) : (
                            videos && videos?.map(it => <Column key={it}>{it}</Column>)
                        )}
                    </DropdownSection>
                </DropdownColumn>
            </DetailsPageLayout>
        </MainLayout>
    );
};
