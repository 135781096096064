import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 250px auto;
    border-radius: 4px;
    background-color: ${brandColors.white};
    overflow: hidden;
`;

export const CommonWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-right: 1px solid ${brandColors.grey};
`;

export const PropertiesWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

export const PropertyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${brandColors.grey};

    &:nth-child(3n + 1) {
        border-right: 1px solid ${brandColors.grey};
    }

    &:nth-child(3n + 2) {
        border-right: 1px solid ${brandColors.grey};
    }
`;
