import { blue, brightRed, lightSkyBlue, white } from 'v1/constants/styles/colors';

export const textColors = {
    primary: blue,
    secondary: white,
    danger: white
};
type TextColorsObjectType = typeof textColors;
export type TextColorsType = keyof TextColorsObjectType;

export const backgroundColors: TextColorsObjectType = {
    primary: lightSkyBlue,
    secondary: brightRed,
    danger: brightRed
};
