import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';

export const TrustedFilter: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { addFilters } = domain.events;

    const { isTrusted } = useStore($videosFilters);

    const handleChange = (value: boolean) => {
        addFilters({ isTrusted: value || undefined });
    };

    return (
        <Checkbox checked={!!isTrusted} textSize="-2" onChange={handleChange}>
            is Trusted
        </Checkbox>
    );
};
