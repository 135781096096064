import React from 'react';
import { useMediaQuery } from 'react-responsive';
import emptyLogo from 'v1/assets/empty_logo.svg';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { Column, Row, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { xs } from 'v1/constants/styles/sizes';
import { Title } from 'v1/types/data';
import { TitleSpan } from './styles';

interface ImageProps {
    imageHeight?: string;
    imageWidth?: string;
    imageSrc?: string;
    imageWrapperBorderRadius?: string;
    imageWrapperBackgroundColor?: string;
    imageWrapperHeight?: string;
    imageWrapperWidth?: string;
    emptyLayoutMarginTop?: string;
    titleWidth?: string;
    titleFontSize?: string;
    titleFontWeight?: string;
    titleLineHeight?: string;
}

export interface EmptyProps extends Title, ImageProps {}

export const Empty = ({
    title,
    titleWidth,
    titleFontSize,
    titleFontWeight,
    titleLineHeight,
    imageHeight = '347px',
    imageSrc = emptyLogo,
    imageWidth = '495px',
    imageWrapperBorderRadius,
    imageWrapperBackgroundColor,
    imageWrapperHeight,
    imageWrapperWidth,
    emptyLayoutMarginTop
}: EmptyProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <Column alignCenter={!isMobile} marginTop={emptyLayoutMarginTop || '20px'} width="100%">
            <ContentWrapper
                backgroundColor={imageWrapperBackgroundColor || 'transparent'}
                borderRadius={imageWrapperBorderRadius || '0px'}
                height={imageWrapperHeight || 'fit-content'}
                minWidth="fit-content"
                width={isMobile ? '100%' : imageWrapperWidth || 'fit-content'}
            >
                <Section alignCenter justifyCenter height="100%">
                    <CustomImg
                        height={isMobile ? '150px' : imageHeight}
                        src={imageSrc}
                        width={isMobile ? '200px' : imageWidth}
                    />
                </Section>
            </ContentWrapper>

            <Row
                justifyCenter
                marginBottom="23px"
                marginTop="37px"
                width={isMobile ? '100%' : titleWidth || 'fit-content'}
            >
                <TitleSpan fontSize={titleFontSize} fontWeight={titleFontWeight} lineHeight={titleLineHeight}>
                    {title}
                </TitleSpan>
            </Row>
        </Column>
    );
};
