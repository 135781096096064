import { forward, guard } from 'effector';
import { loaderEffects } from 'v2/stores/loader';
import { singleUserEffects } from 'v2/stores/single-user';
import { singleUserActionsEffects } from 'v2/stores/single-user/actions';

const { blockUserByIdFx, enableUserByIdFx, editUserRolesFx, editUserVerifyFx } = singleUserActionsEffects;

guard({
    clock: [blockUserByIdFx.doneData, enableUserByIdFx.doneData, editUserRolesFx.doneData, editUserVerifyFx.doneData],
    filter: userId => !!userId,
    target: singleUserEffects.getUserByIdFx
});

forward({
    from: [blockUserByIdFx.pending, enableUserByIdFx.pending, editUserRolesFx.pending, editUserVerifyFx.pending],
    to: loaderEffects.loaderFx
});
