import { useStore } from 'effector-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { YeayBigIcon } from 'v2/assets/icons';
import { InputField } from 'v2/components/form/InputField';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { authEvents, authStores } from 'v2/stores/auth';
import { LoginParams } from 'v2/types/services';
import { loginInitialValues, loginSchema } from './constants';
import { FormWrapper, FormikForm, Wrapper } from './styles';

export const Login = () => {
    const error = useStore(authStores.$loginError);
    const isLoading = useStore(authStores.$loginIsLoading);

    const handleSubmit = (params: LoginParams, { resetForm }: FormikHelpers<LoginParams>) => {
        authEvents.login({
            params,
            onSuccess: () => {
                resetForm();
            }
        });
    };

    return (
        <Wrapper>
            <FormWrapper>
                <Column noWrap padding="16px" width="100%">
                    <Section justifyCenter marginBottom="12px">
                        <YeayBigIcon width={48} />
                    </Section>

                    <Section justifyCenter>
                        <Title alignTextCenter size="-1">
                            Admin System
                        </Title>
                    </Section>
                </Column>

                <Section marginTop="16px">
                    <Formik
                        validateOnChange
                        initialValues={loginInitialValues}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, isValid, dirty }: FormikProps<LoginParams>) => (
                            <FormikForm onSubmit={handleSubmit}>
                                <Section marginBottom="32px">
                                    <InputField
                                        wide
                                        error={error || undefined}
                                        label="Email"
                                        name="usernameOrEmail"
                                        placeholder="Enter email"
                                    />
                                </Section>

                                <Section marginBottom="38px">
                                    <InputField
                                        wide
                                        autoComplete="on"
                                        error={error || undefined}
                                        label="Password"
                                        name="password"
                                        placeholder="Enter your password"
                                        type="password"
                                    />
                                </Section>

                                <Section maxWidth="130px">
                                    <Button wide disabled={!isValid || !dirty || isLoading} type="submit">
                                        Sign in
                                    </Button>
                                </Section>
                            </FormikForm>
                        )}
                    </Formik>
                </Section>
            </FormWrapper>
        </Wrapper>
    );
};
