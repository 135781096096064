import React from 'react';
import { InfoIcon } from 'v2/assets/icons';
import { HeadCell } from 'v2/components/ui/Table/HeadCell';
import { Tooltip } from 'v2/components/ui/Tooltip';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const ReportStatusTableTitle = () => (
    <HeadCell center title="REPORT STATUS">
        <Tooltip
            maxWidth="282px"
            overlay={
                <Text color={brandColors.white} size="-2">
                    This is the report status. If you approve the report, the status will be “accepted” and the asset
                    hidden to all users. If you reject the report, the status will be “rejected” and the asset available
                    to all users.
                </Text>
            }
            placement="bottom"
        >
            <Row marginLeft="8px">
                <InfoIcon />
            </Row>
        </Tooltip>
    </HeadCell>
);
