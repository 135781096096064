import React, { FC } from 'react';
import { StyledButton } from 'v2/components/ui/buttons/SelectButton/styles';
import { CheckMark } from 'v2/components/ui/inputs/Checkbox';
import { Disabled, HTMLButtonType } from 'v2/types/form';
import { ReactClick } from 'v2/types/react';

export interface SelectButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    selected: boolean;
}

export const SelectButton: FC<SelectButtonProps> = ({ children, selected, ...props }) => (
    <StyledButton {...props}>
        {children}
        <CheckMark checked={selected} />
    </StyledButton>
);
