import React from 'react';
import { black, grey12 } from 'v1/constants/styles/colors';
import { tableBorder } from 'v1/constants/styles/sizes';
import { DataTable } from 'v1/types/data';
import { AlignmentType } from 'v1/types/types';
import { Cell, RowWrapper, TableBody, TableHeader, TableHeaderColumnSpan } from './styles';

export interface TableProps {
    columns: string[];
    columnSizes?: number[];
    data?: DataTable[];
    cellAlignment?: AlignmentType;
}

export const Table = ({
    columns,
    columnSizes = new Array<number>(columns.length).fill(1),
    data,
    cellAlignment = 'center'
}: TableProps) => {
    const flexBasisValues = columnSizes?.map(value => (100 * value) / columnSizes?.reduce((a, b) => a + b) + '%');

    return (
        <>
            <TableHeader alignCenter backgroundColor={grey12} height="45px">
                {columns.map((title, index) => (
                    <Cell key={title} justifyCenter columnAlignment={cellAlignment} flexBasis={flexBasisValues[index]}>
                        <TableHeaderColumnSpan color={black}>{title}</TableHeaderColumnSpan>
                    </Cell>
                ))}
            </TableHeader>
            <TableBody>
                {data?.map(({ cells, alignment = new Array<AlignmentType>(columns.length).fill('start') }, index) => (
                    <RowWrapper key={index.toString()} alignCenter justifyAround border={tableBorder}>
                        {cells.map((cell, index) => (
                            <Cell
                                key={index.toString()}
                                columnAlignment={alignment[index]}
                                flexBasis={flexBasisValues[index]}
                            >
                                {cell}
                            </Cell>
                        ))}
                    </RowWrapper>
                ))}
            </TableBody>
        </>
    );
};
