import styled, { css } from 'styled-components';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    bordered: boolean;
}

export const CategoryWrapper = styled(Row)<Props>`
    padding: 2px 8px;
    width: fit-content;
    border-radius: 32px;
    background-color: ${brandColors.grey};
    white-space: nowrap;
    ${({ bordered }) =>
        bordered &&
        css`
            padding: 1px 7px;
            border: 1px solid ${brandColors.black};
        `}
`;
