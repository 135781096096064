import { guard } from 'effector';
import { addAgentEffects, addAgentStores } from 'v2/stores/agent/add';
import { singleUserEffects } from 'v2/stores/single-user';

guard({
    clock: addAgentStores.$agentsFilters,
    filter: data => !!data,
    target: addAgentEffects.getAgentsFx
});

guard({
    clock: [
        addAgentEffects.changeAgentFx.doneData,
        addAgentEffects.addMemberToAgentFx.doneData,
        addAgentEffects.removeMemberFromAgentFx.doneData
    ],
    filter: clock => !!clock,
    target: singleUserEffects.getUserByIdFx
});
