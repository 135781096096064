import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { depthBreadcrumb } from './constants';
import { toUpperFirstElement } from './utils';

export const Breadcrumb = () => {
    const location = useLocation();
    const history = useHistory();

    const locationsArray = location.pathname.split('/');

    const lastPage = toUpperFirstElement(locationsArray[1]).substring(locationsArray[1].length - 1, 0);
    const middlePage = toUpperFirstElement(locationsArray[2]);

    const handleGoBack = () => history.goBack();

    return (
        <Row alignCenter>
            <IconButton onClick={handleGoBack}>
                <ArrowLeftIcon height={15} width={16} />
            </IconButton>

            <Row marginLeft="8px">
                <Text size="-2">
                    {lastPage}/{locationsArray.length === depthBreadcrumb && `${middlePage}/`}
                    <Title size="-2">Single</Title>
                </Text>
            </Row>
        </Row>
    );
};
