export enum TransactionType {
    None = 0,
    PayPalDeposit = 10,
    WithdrawalToMint = 20,
    PayPalFeeBurnToMint = 21,
    OrganizationFunding = 30,
    CollaborationFunding = 40,
    ServiceFeeRevenue = 50,
    PayPalWithdrawalFeeRevenue = 60,
    CollaborationVideoReward = 70,
    CollaborationSocialPostReward = 80,
    PayPalWithdrawalEscrowTransfer = 90
}
