import styled from 'styled-components';
import { grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const UsersGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 6px;
    row-gap: 1px;
    padding: 8px;
    user-select: none;
`;

export const PositionWrapper = styled.div`
    width: fit-content;
    padding: 4px 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${grey[300]};
    ${flexCenter};
    text-align: center;
`;
