import { format } from 'date-fns';
import { useStore } from 'effector-react';
import React from 'react';
import emptyStateImage from 'v1/assets/icons/blacklist_empty_state_icon.svg';
import { SortableUsersTable } from 'v1/components/common/tables/SortableUsersTable';
import { CatalogContainer } from 'v1/components/layouts/containers/CatalogContainer';
import { DeletedUsersFilterLayout } from 'v1/components/layouts/filterLayouts/DeletedUsersFilterLayout';
import { Empty } from 'v1/components/layouts/resultLayouts/Empty';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { ReasonDeletion } from 'v1/constants/defaults/users';
import { grey25 } from 'v1/constants/styles/colors';
import { useSortableData } from 'v1/hooks/useSortableData';
import { columns, columnSizes, defaultMessage, emptyStateImageWrapperDiameter } from 'v1/pages/DeletedUsers/constants';
import { deletedUsersStores } from 'v1/stores/users/deletedUsers';
import { DataTable } from 'v1/types/data';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { routePaths } from 'v2/constants/routes';
import { Reason, TableDataSpan, TableWrapper } from './styles';

type ReasonTypes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const DeletedUsers = () => {
    const { items, totalRecords } = useStore(deletedUsersStores.deletedUsers);
    const loading = useStore(deletedUsersStores.loading);
    const sortableItems = items ? items.map(item => ({ ...item, deleterName: item.deleterInfo?.userName })) : [];
    const { sortedItems, requestSort, sortConfig } = useSortableData(sortableItems);

    const dataTable: DataTable[] | undefined = sortedItems?.map(
        ({ userId, email, mobileNumber, deleterName, comment, reasons, utcDeleted }) => {
            const msUTC = Date.parse(utcDeleted);
            const deletionDate = format(new Date(msUTC), 'dd.MM.yyyy');

            return {
                cells: [
                    <Section key={userId} alignCenter justifyCenter>
                        <TableDataSpan>{userId}</TableDataSpan>
                    </Section>,

                    <Section key={utcDeleted} alignCenter justifyCenter>
                        <TableDataSpan>{deletionDate}</TableDataSpan>
                    </Section>,

                    <Section key={userId} alignCenter justifyCenter>
                        <TableDataSpan>{email}</TableDataSpan>
                    </Section>,

                    <Section key={userId} alignCenter justifyCenter>
                        <TableDataSpan>{mobileNumber}</TableDataSpan>
                    </Section>,
                    <Section key={userId} alignCenter justifyCenter>
                        <TableDataSpan>{deleterName}</TableDataSpan>
                    </Section>,

                    <Section key={userId} alignCenter justifyCenter>
                        <TableDataSpan>{comment}</TableDataSpan>
                    </Section>,

                    <Section key={userId} alignCenter justifyCenter noWrap>
                        {reasons?.map((reason: ReasonTypes) => (
                            <Reason key={reason}>{ReasonDeletion[reason]} </Reason>
                        ))}
                    </Section>
                ],
                route: `${routePaths.deletedUsers}/${userId}`
            };
        }
    );

    return (
        <MainLayout>
            <DeletedUsersFilterLayout totalRecords={totalRecords}>
                <CatalogContainer totalRecords={totalRecords}>
                    {loading ? (
                        <Section justifyCenter>
                            <Spinner />
                        </Section>
                    ) : items?.length !== 0 ? (
                        <TableWrapper>
                            <SortableUsersTable
                                columnSizes={columnSizes}
                                columns={columns}
                                data={dataTable}
                                sortState={sortConfig}
                                onSort={requestSort}
                            ></SortableUsersTable>
                        </TableWrapper>
                    ) : (
                        <Empty
                            emptyLayoutMarginTop="100px"
                            imageHeight="18px"
                            imageSrc={emptyStateImage}
                            imageWidth="22px"
                            imageWrapperBackgroundColor={grey25}
                            imageWrapperBorderRadius="50%"
                            imageWrapperHeight={emptyStateImageWrapperDiameter}
                            imageWrapperWidth={emptyStateImageWrapperDiameter}
                            title={defaultMessage}
                            titleFontSize="16px"
                            titleFontWeight="500"
                            titleLineHeight="26px"
                            titleWidth="390px"
                        />
                    )}
                </CatalogContainer>
            </DeletedUsersFilterLayout>
        </MainLayout>
    );
};
