import { TableProps } from 'rc-table/lib/Table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React, { ReactNode } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { LoadingWrapper, StyledTable, Wrapper } from './styles';

interface Props extends TableProps<DefaultRecordType> {
    rowPointer?: boolean;
    cellCenter?: boolean;
    topExtra?: ReactNode;
    bottomExtra?: ReactNode;
    isLoading?: boolean;
    emptyMessage?: string;
    isTextNoWrap?: boolean;
}

export const Table = ({
    columns,
    data,
    emptyMessage,
    topExtra,
    bottomExtra,
    rowPointer = false,
    isLoading = false,
    isTextNoWrap = true,
    ...props
}: Props) => {
    const isEmpty = !data || data?.length === 0;

    return (
        <Wrapper disabled={isLoading}>
            {topExtra}

            <StyledTable
                columns={columns}
                data={data}
                emptyText={<Empty title={emptyMessage} yPadding="100px" />}
                isEmpty={isEmpty}
                isTextNoWrap={isTextNoWrap}
                rowPointer={rowPointer}
                {...props}
            />

            {isLoading && (
                <LoadingWrapper>
                    <Spinner />
                </LoadingWrapper>
            )}

            {bottomExtra}
        </Wrapper>
    );
};
