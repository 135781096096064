import styled, { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    selected?: boolean;
    disabled?: boolean;
}

export const CardWrapper = styled.div<Props>`
    width: 100%;
    height: 190px;
    background-color: ${brandColors.white};
    border-radius: 16px;
    border: 4px solid ${({ selected }) => (selected ? brandColors.green : 'transparent')};
    transition: border ${transitionTime} linear;
    overflow: hidden;
    cursor: pointer;

    ${({ selected, disabled }) => !selected && disabled && 'cursor: not-allowed'};

    ${({ selected, disabled }) =>
        !selected &&
        !disabled &&
        css`
            &:hover:not(:disabled) {
                border-color: ${grey[600]};
            }
        `};
`;
