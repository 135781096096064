import { grey11 } from 'v1/constants/styles/colors';
import { dividePixels, pixelsAddition } from 'v1/utils/parsers';

// * padding
export const padding = '10px';
export const filterMargin = '8px';
export const cardMargin = '16px';
export const descriptionPadding = '8px';
export const smallDescriptionPadding = '8px';

export const modalHorizontalPadding = '32px';
export const modalVerticalPadding = '40px';

export const selectorHorizontalPadding = '12px';
export const selectorVerticalPadding = '8px';
export const selectPadding = '12px 5px';
export const titleMarginBottom = '0px';

export const ellipsisTextPadding = '5px';

// * others
export const primaryBorderRadius = '8px';
export const absoluteWrapperBorderRadius = '0 0 8px 8px';

export const borderWidth = '1px';
export const borderRadius = '4px';
export const containerBorderRadius = '24px';

export const opacity = 0.8;
export const disabledOpacity = 0.5;
export const textInfoFontSize = '14px';
export const textInfoLineHeight = '16px';

export const clearInputButtonWidthAndHeight = '24px';
export const searchButtonWidthAndHeight = '24px';
export const searchIconWidthAndHeight = clearInputButtonWidthAndHeight;
export const copyButtonDiameter = '18px';

export const buttonsFontSize = '10px';
export const buttonsFontWeight = '400';

export const tableBorder = `1px solid ${grey11}`;

export const propertyBlockHorizontalPadding = '4px';

export const closeIconDiameter = '12px';
export const popoverArrowDiameter = '30px';

export const selectZIndex = 10;
export const sideBarZIndex = 99;

export const noProductImageIconHeight = '41px';

export const cardButtonTextFontSize = '11px';
export const cardButtonMinWidth = '103px';
export const cardButtonHeight = '45px';
export const cardButtonMarginBottom = '8px';

// * layouts
export const headerHeight = '80px';
export const headerWidth = '40px';
export const headerPaddingBottom = '10px';
export const fullHeaderHeight = parseInt(headerHeight) + parseInt(headerPaddingBottom) + 'px';
export const sideBarWidth = '235px';
export const smallSideBarWidth = '150px';
export const miniPlayerHeight = '200px';
export const pinnedSphereDiameter = '32px';
export const featureHeight = '40px';
export const avatarDiameter = '40px';
export const iconsFontSIze = '20px';
export const footerHeight = '105px';
export const CloseButtonDiameter = '40px';
export const hashtagsSliderHeight = '30px';
export const featureIconSize = '25px';
export const paginationHeight = '65px';
export const graphicInfoBlockHeight = '50px';

// * cards
export const antdCardWidth = '250px';
export const antdCardHeight = '250px';
export const antdTrendingCardWidth = dividePixels(antdCardWidth, 2);
export const antdTrendingCardHeight = pixelsAddition(dividePixels(antdCardHeight, 2), '50px');
export const antdCardBottomBarHeight = '48px';
export const ellipsisRowWidth = `calc(${antdCardWidth} - 2 * ${padding})`;
export const antdCardAvatarWidth = '100px';
export const antdCardStyle = { width: antdCardWidth, marginBottom: padding, marginRight: padding };
export const antdTrendingCardStyle = {
    width: antdTrendingCardWidth,
    height: antdTrendingCardHeight,
    marginBottom: padding,
    marginRight: padding
};

// * adaptive
export const xss = '320px';
export const phoneSize = '390px';
export const xs = '480px';
export const sm = '576px';
export const md = '768px';
export const lg = '992px';
export const xl = '1200px';
export const xxl = '1600px';

export const xss_1 = '321px';
export const xs_1 = '479px';
export const sm_1 = '575px';
export const md_1 = '767px';
export const lg_1 = '991px';
export const xl_1 = '1199px';
export const xxl_1 = '1599px';

export const ipadProMaxResolution = '1367px';

export const scrollBarWidth = '5px';
