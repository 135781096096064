import React, { FC } from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';

interface Props {
    user?: YECM.CreatorProfile;
}

export const UserCell: FC<Props> = ({ user }) => (
    <Row alignCenter noWrap>
        <Avatar size="32px" src={user?.profileImageUrl || ''} />

        <Column marginLeft="8px">
            <Text isSemibold size="-2">
                @{user?.userName}
            </Text>
        </Column>
    </Row>
);
