import { useStore } from 'effector-react';
import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { RangeFilter } from 'v2/components/filter/RangeFilter';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { RangeInputValuesType } from 'v2/components/ui/inputs/RangeInputs';
import { convertDecimalFromPercent, convertDecimalToPercent } from 'v2/utils/calculators';
import { maxCommission, minCommission } from './constants';

export const CommissionFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { fromCommission, toCommission } = useStore($usersFilters);
    const fromValue = isNumber(fromCommission) ? convertDecimalToPercent(fromCommission) : undefined;
    const toValue = isNumber(toCommission) ? convertDecimalToPercent(toCommission) : undefined;

    const handleChange = ([minCommission, maxCommission]: RangeInputValuesType) => {
        const from = isNumber(minCommission) ? convertDecimalFromPercent(minCommission) : undefined;
        const to = isNumber(maxCommission) ? convertDecimalFromPercent(maxCommission) : undefined;

        addFilters({
            fromCommission: from,
            toCommission: to
        });
    };

    return (
        <RangeFilter
            dropdownTitle="Commission"
            fromValue={fromValue}
            maxLimit={maxCommission}
            minLimit={minCommission}
            toValue={toValue}
            unit="%"
            onChange={handleChange}
        />
    );
};
