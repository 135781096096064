import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routePaths } from 'v2/constants/routes';
import { UserRole } from 'v2/constants/services/users';
import { authStores } from 'v2/stores/auth';

interface PrivateRoute extends RouteProps {
    roles?: UserRole[];
}

export const PrivateRoute: FC<PrivateRoute> = ({
    roles = [UserRole.SuperAdministrator, UserRole.Administrator],
    ...props
}) => {
    const user = useStore(authStores.$user);
    const userRoles = user?.roles || [];

    const hasAccess = userRoles.some(userRole => roles.includes(userRole as UserRole));

    if (!hasAccess) {
        return <Redirect to={routePaths.error403} />;
    }

    return <Route {...props} />;
};
