import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { columns } from 'v2/pages/User/UserInfo/components/UserTransactions/constants';
import { prepareUserTransactions } from 'v2/pages/User/UserInfo/components/UserTransactions/utils';
import {
    defaultUserTransactionsParams,
    userTransactionsEffects,
    userTransactionsEvents,
    userTransactionsStores
} from 'v2/stores/transactions/user';

interface Props {
    userId: string;
}

export const UserTransactions: FC<Props> = ({ userId }) => {
    const isLoading = useStore(userTransactionsEffects.getUserTransactionsFx.pending);
    const userTransactions = useStore(userTransactionsStores.$userTransactions);

    const tableData = prepareUserTransactions(userTransactions?.items || []);

    useEffect(() => {
        userTransactionsEffects.getUserTransactionsFx({ params: defaultUserTransactionsParams, remoteUserId: userId });

        return () => {
            userTransactionsEvents.resetStores();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMore = () => {
        if (!userTransactions?.hasNext) return;

        const newPageIndex = (userTransactions?.pageIndex || 0) + 1;
        userTransactionsEvents.updateFilters({ pageIndex: newPageIndex });
    };

    return (
        <Section noWrap>
            <SectionBorder borderBottom borderTop>
                <Table
                    cellCenter
                    bottomExtra={
                        userTransactions?.hasNext && (
                            <Section justifyCenter marginTop="16px">
                                <LoadMoreSection onClick={loadMore} />
                            </Section>
                        )
                    }
                    columns={columns}
                    data={tableData}
                    emptyMessage="No Transactions"
                    isLoading={isLoading}
                />
            </SectionBorder>
        </Section>
    );
};
