import ycmAxios from './yecm.axios';

export interface UserCollaborationParams {
    userId: string;
    collaborationId: string;
}

export const removeUserFromCollaboration = (
    { userId, collaborationId }: UserCollaborationParams,
    data: YECM.KickOutParticipantRequest
) =>
    ycmAxios<YECM.ApiResponse>({
        url: `/admin/participants/${userId}/collaborations/${collaborationId}`,
        method: 'DELETE',
        data
    });
