import styled, { css } from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';

export const PropertiesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

interface Props {
    column?: string;
    noBorderRight?: boolean;
    noBorderBottom?: boolean;
}

export const GridItem = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    ${({ column }) =>
        column &&
        css`
            grid-column: ${column};
        `}
    ${({ noBorderRight }) => !noBorderRight && `border-right: 1px solid ${brandColors.grey}`};
    ${({ noBorderBottom }) => !noBorderBottom && `border-bottom: 1px solid ${brandColors.grey}`};
`;
