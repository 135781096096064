import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title/styles';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { ExternalNetworkKey } from 'v2/constants/services/networks';
import { reportStatus } from 'v2/constants/services/report';
import { FollowersItem } from 'v2/pages/User/UserInfo/components/UserMainInfo/FollowersItem';
import { externalNetworksEffects, externalNetworksStores } from 'v2/stores/external-networks';
import { reviewReportModal } from 'v2/stores/initialize-modals';
import { userNetworksStores } from 'v2/stores/user-networks';
import { splitArrayIntoChunks } from 'v2/utils/array';
import { getCompactNumber } from 'v2/utils/formats';
import { FollowersGrid, MainInfoWrapper } from './styles';

interface Props {
    user: YEAY.AdminGetReportedUserResponse;
}

export const ReportUserMainInfo: FC<Props> = ({ user }) => {
    const externalNetworks = useStore(externalNetworksStores.$externalNetworks);
    const userNetworks = useStore(userNetworksStores.$userNetworks);

    const splittedMedia = splitArrayIntoChunks(user?.profileInfo?.linkedSocialNetworks || [], 3);

    const handleReviewReport = () => {
        user.reportId && reviewReportModal.openModal(user.reportId);
    };

    useEffect(() => {
        externalNetworksEffects.getExternalNetworksFx();
    }, []);

    return (
        <MainInfoWrapper>
            <Column alignCenter marginBottom="18px">
                <Avatar src={user.profileInfo?.avatar || ''} />
                <Row justifyCenter marginTop="8px">
                    <Title alignTextCenter size="-1">
                        {user.profileInfo?.username ? `@${user.profileInfo?.username}` : '-'}
                    </Title>
                </Row>
            </Column>

            {splittedMedia.length > 0 && (
                <Column alignCenter marginBottom="16px">
                    {splittedMedia.map((chunk, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <FollowersGrid key={index} columns={chunk.length}>
                            {chunk.map(item => {
                                const networkName = externalNetworks
                                    ?.filter(
                                        externalNetwork =>
                                            externalNetwork.title?.toLowerCase() !== ExternalNetworkKey.Yeay
                                    )
                                    .find(externalNetwork => externalNetwork.id === item.networkId);
                                const userNetwork = userNetworks?.find(network => network.networkId === item.networkId);

                                return (
                                    <Row key={item.networkId}>
                                        <FollowersItem
                                            count={getCompactNumber(item.followersCount || 0)}
                                            link={userNetwork?.url || ''}
                                            networkName={networkName?.title || ''}
                                        />
                                    </Row>
                                );
                            })}
                        </FollowersGrid>
                    ))}
                </Column>
            )}

            <Column alignCenter marginBottom="20px" width="100%">
                <Text alignTextCenter size="-2" wordBreak="break-word">
                    {user.profileInfo?.bio || '-'}
                </Text>
            </Column>

            <Button disabled={user.reportStatus !== reportStatus.review} onClick={handleReviewReport}>
                Review Report
            </Button>
        </MainInfoWrapper>
    );
};
