import React, { FC } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { Engagement } from 'v2/pages/Video/tabs/VideoTab/Engagement';
import { VideoInfoLeft } from 'v2/pages/Video/tabs/VideoTab/VideoInfoLeft';
import { VideoInfoRight } from 'v2/pages/Video/tabs/VideoTab/VideoInfoRight';
import { Wrapper } from './styles';

interface Props {
    video: YEAY.AdminGetVideoResponse | null;
    isLoading?: boolean;
}

export const VideoTab: FC<Props> = ({ video, isLoading }) => (
    <Section>
        {video && (
            <Wrapper>
                <VideoInfoLeft video={video} />
                <VideoInfoRight video={video} />
            </Wrapper>
        )}
        {isLoading && !video && (
            <Section justifyCenter marginTop="16px">
                <Spinner />
            </Section>
        )}
        {!isLoading && !video && <Empty title="Video Not Found" />}

        {video?.id && (
            <Section marginTop="8px">
                <AccordionSection title="Engagement">
                    <Engagement id={video.id} />
                </AccordionSection>
            </Section>
        )}
    </Section>
);
