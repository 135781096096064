import React, { useEffect, useState } from 'react';
import { ArrowUpIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { brandColors } from 'v2/constants/styles/colors';
import { FixedWrapper } from './styles';

const scrollFromTop = 500;

export const ScrollToTopButton = () => {
    const [buttonIsVisible, setButtonIsVisible] = useState(false);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            setButtonIsVisible(window.scrollY > scrollFromTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!buttonIsVisible) {
        return null;
    }

    return (
        <FixedWrapper>
            <IconButton rounded bgColor={brandColors.white} size="36px" onClick={goToTop}>
                <ArrowUpIcon />
            </IconButton>
        </FixedWrapper>
    );
};
