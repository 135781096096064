import { createEffect, createEvent, createStore } from 'effector';
import { isEmpty } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';
import { editWebsiteModal } from 'v2/stores/initialize-modals';

// Types

export interface CategoryEditParams {
    ownerId: string;
    videoId: string;
    currentCategoryId?: string;
}

interface EditVideoCategoryParams {
    ownerId: string;
    videoId: string;
    categoryTagId: string;
}

export interface HashtagsEditParams {
    ownerId: string;
    videoId: string;
    currentHashtags?: string[];
}

interface EditVideoHashtagsParams {
    ownerId: string;
    videoId: string;
    hashTags: string[];
}

interface EditWebsiteParams {
    ownerId: string;
    videoId: string;
    websiteUrl: string;
}

// Events

const openCategoryEditModal = createEvent<CategoryEditParams>();
const closeCategoryEditModal = createEvent();

const openHashtagsEditModal = createEvent<HashtagsEditParams>();
const closeHashtagsEditModal = createEvent();

// Effects

const editVideoCategoryFx = createEffect({
    handler: async ({ videoId: id, ownerId, categoryTagId }: EditVideoCategoryParams) => {
        try {
            const video = await API.video.editVideoInfo({
                id,
                ownerId,
                categoryTagId
            });

            if (!video || isEmpty(video)) {
                throw new Error();
            }

            notify('Category updated successfully');
            closeCategoryEditModal();
            return {
                categoryDetails: video.categoryDetails,
                hashTags: video.hashTags
            };
        } catch (e) {
            console.log('Failed to update category:', e);
            notify('Failed to update category', 'error');
            return null;
        }
    }
});

const editVideoHashtagsFx = createEffect({
    handler: async ({ videoId: id, ownerId, hashTags }: EditVideoHashtagsParams) => {
        try {
            const video = await API.video.editVideoInfo({
                id,
                ownerId,
                hashTags
            });

            if (!video || isEmpty(video)) {
                throw new Error();
            }

            notify('Hashtags updated successfully');
            closeCategoryEditModal();
            return {
                hashTags: video.hashTags
            };
        } catch (e) {
            console.log('Failed to update hashtags:', e);
            notify('Failed to update hashtags', 'error');
            return null;
        }
    }
});

const editWebsiteFx = createEffect({
    handler: async ({ videoId: id, ownerId, websiteUrl }: EditWebsiteParams) => {
        try {
            const video = await API.video.editVideoInfo({
                id,
                ownerId,
                websiteUrl
            });

            if (!video || isEmpty(video)) {
                throw new Error();
            }

            notify('Website updated successfully');

            return websiteUrl;
        } catch (e) {
            console.log('Failed to update website:', e);
            notify('Failed to update website', 'error');
            return null;
        } finally {
            editWebsiteModal.closeModal();
        }
    }
});

// Stores

const $categoryEditModal = createStore<CategoryEditParams | null>(null)
    .on(openCategoryEditModal, (_, data) => data)
    .reset(closeCategoryEditModal);

const $hashtagsEditModal = createStore<HashtagsEditParams | null>(null)
    .on(openHashtagsEditModal, (_, data) => data)
    .reset(closeHashtagsEditModal);

// Exports

export const videoActionEffects = { editVideoCategoryFx, editVideoHashtagsFx, editWebsiteFx };

export const videoActionEvents = {
    openCategoryEditModal,
    closeCategoryEditModal,
    openHashtagsEditModal,
    closeHashtagsEditModal
};

export const videoActionStores = { $categoryEditModal, $hashtagsEditModal };
