import { yupDefault } from 'v2/constants/yup';
import { LoginParams } from 'v2/types/services';
import * as Yup from 'yup';

export const loginInitialValues: LoginParams = {
    usernameOrEmail: '',
    password: ''
};

export const loginSchema = Yup.object().shape({
    usernameOrEmail: yupDefault,
    password: yupDefault
});
