import React from 'react';
import { ClosableButton } from 'v1/components/common/buttons/ClosableButton';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { noop } from 'v1/constants/functions';
import { Disabled } from 'v1/types/form';
import { MarginRightBottom } from 'v1/types/styles';
import { ClosableTagSpan, ClosableTagWrapper } from './styled';

export interface ClosableTagProps extends Disabled, MarginRightBottom {
    subject: string;
    onClose?: (subject: string) => void;
    text?: string;
    untouchable?: boolean;
}

export const ClosableTag = ({ subject, text = subject, onClose = noop, untouchable, ...props }: ClosableTagProps) => (
    <ClosableTagWrapper {...props}>
        <Row alignCenter noWrap marginBottom="0">
            <ClosableTagSpan>{text}</ClosableTagSpan>
            {!untouchable && (
                <MarginWrapper marginLeft="10px">
                    <ClosableButton onClick={() => onClose(subject)} />
                </MarginWrapper>
            )}
        </Row>
    </ClosableTagWrapper>
);
