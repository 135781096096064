import { SelectOption } from 'v2/components/ui/selects/types';

export enum UserRegisterType {
    None,
    Phone,
    Email
}

export enum UserGender {
    NotGiven,
    Male,
    Female,
    NonBinary
}

export enum UserRole {
    Registered = 'Registered',
    Curator = 'Curator',
    Administrator = 'Administrator',
    SuperAdministrator = 'SuperAdministrator',
    ContentManager = 'ContentManager',
    Facilitator = 'Facilitator',
    Agent = 'Agent',
    Tracked = 'Tracked',
    AgentMember = 'AgentMember'
}

export const userRoleTexts: Record<UserRole, string> = {
    [UserRole.Registered]: 'Registered',
    [UserRole.Curator]: 'Curator',
    [UserRole.Administrator]: 'Administrator',
    [UserRole.SuperAdministrator]: 'Super Administrator',
    [UserRole.ContentManager]: 'Content Manager',
    [UserRole.Facilitator]: 'Facilitator',
    [UserRole.Agent]: 'Agent',
    [UserRole.Tracked]: 'Tracked',
    [UserRole.AgentMember]: 'AgentMember'
};

export enum UserJoinedType {
    All = 0,
    Joined = 1,
    NotJoined = 2
}

export const defaultUsersParams: YEAY.QueryAllUsersRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true,
    sortByActivityAsc: false,
    role: UserRole.Registered
};

export const defaultAgentUsersParams: YEAY.QueryAllUsersRequest = {
    pageIndex: 0,
    limit: 10,
    returnQueryCount: true,
    role: UserRole.Agent
};

const allOption: SelectOption = {
    label: 'All',
    value: undefined
};

export const roleFilterOptions: SelectOption[] = [
    allOption,
    {
        label: userRoleTexts[UserRole.Registered],
        value: UserRole.Registered
    },
    {
        label: userRoleTexts[UserRole.Administrator],
        value: UserRole.Administrator
    },
    {
        label: userRoleTexts[UserRole.SuperAdministrator],
        value: UserRole.SuperAdministrator
    },
    {
        label: userRoleTexts[UserRole.Curator],
        value: UserRole.Curator
    },
    {
        label: userRoleTexts[UserRole.ContentManager],
        value: UserRole.ContentManager
    },
    {
        label: userRoleTexts[UserRole.Tracked],
        value: UserRole.Tracked
    },
    {
        label: userRoleTexts[UserRole.Agent],
        value: UserRole.Agent
    }
];

export const registerTypeFilterOptions: SelectOption[] = [
    allOption,
    {
        label: 'Registered Phone',
        value: UserRegisterType.Phone
    },
    {
        label: 'Registered Email',
        value: UserRegisterType.Email
    }
];

export const genderFilterOptions: SelectOption[] = [
    { value: UserGender.Male, label: 'Male' },
    { value: UserGender.Female, label: 'Female' },
    { value: UserGender.NotGiven, label: 'None' }
];

export enum UsersDateType {
    None = 'None',
    UtcCreated = 'UtcCreated',
    UtcLastAuthentication = 'UtcLastAuthentication',
    UtcUpdated = 'UtcUpdated'
}

export const dateTypeTitles: Record<UsersDateType, string> = {
    [UsersDateType.None]: 'None',
    [UsersDateType.UtcCreated]: 'UTC Created',
    [UsersDateType.UtcLastAuthentication]: 'UTC Last Authentication',
    [UsersDateType.UtcUpdated]: 'UTC Updated'
};

export const dateTypeOptions: SelectOption[] = (Object.keys(UsersDateType) as Array<keyof typeof UsersDateType>).map(
    type => ({
        label: dateTypeTitles[type],
        value: type
    })
);

export enum UserSingleTab {
    UserInfo,
    AgentInfo
}
