import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { defaultReferralUserParams } from 'v2/constants/services/referral-users';
import { API } from 'v2/services/yasy';

// Types

export type ReferralUsersParams = Partial<Paths.AdminReferrals$ReferralId.Get.QueryParameters>;

// Events

const updateParams = createEvent<ReferralUsersParams>();

const resetStores = createEvent();
const resetParams = createEvent();

// Effects

const getInvitedUsersFx = createEffect({
    handler: async ({ referralId, params }: { referralId: string; params?: ReferralUsersParams }) => {
        try {
            const { isSuccess, data } = await API.referralUsers.getReferralInvitedUsers(referralId, {
                ...defaultReferralUserParams,
                ...params
            });

            if (isSuccess && data) {
                return data;
            } else {
                throw new Error();
            }
        } catch (error) {
            notify('There was a problem receiving referral users, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $invitedUsers = createStore<YEAY.GetReferralsDetailedResponse | null>(null)
    .on(getInvitedUsersFx.doneData, (_, payload) => payload)
    .reset(resetStores);

const $referralUsersParams = createStore<ReferralUsersParams>(defaultReferralUserParams)
    .on(updateParams, (state, params) => ({
        ...defaultReferralUserParams,
        ...state,
        ...params
    }))
    .reset(resetParams);

// Exports

export const referralUsersEvents = { resetStores, updateParams, resetParams };

export const referralUsersEffects = { getInvitedUsersFx };

export const referralUsersStores = { $invitedUsers, $referralUsersParams };
