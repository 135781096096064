import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { ReportDate } from 'v2/components/filter/reports/appReports/ReportDate';
import { ReportSearch } from 'v2/components/filter/reports/appReports/ReportSearch';
import { ReportStatus } from 'v2/components/filter/reports/appReports/ReportStatus';
import { ReportType } from 'v2/components/filter/reports/appReports/ReportType';
import { Spinner } from 'v2/components/loaders/Spinner';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { appReportsEffects, appReportsEvents, appReportsStores } from 'v2/stores/reports/app';
import { FiltersWrapper } from '../../../styles';

const { resetAppFilters } = appReportsEvents;

interface Props {
    totalCount?: number;
    isLoading: boolean;
}

export const ReportsYeayAppTabFilterLayout: FC<Props> = ({ totalCount = 0, children, isLoading }) => {
    const appReportsFilters = useStore(appReportsStores.$appReportsFilters);

    const resetIsAvailable = useStore(appReportsStores.$resetIsAvailable);

    const handleReset = () => {
        resetAppFilters();
    };

    useEffect(() => {
        appReportsEffects.getAppReportsFx(appReportsFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column noWrap width="100%">
            <FiltersWrapper>
                <SectionBorder borderBottom noWrap padding="12px 16px">
                    <Section maxWidth="500px">
                        <ReportSearch />
                    </Section>
                </SectionBorder>

                <SectionBorder alignCenter justifyBetween noWrap minHeight="48px" padding="8px 16px">
                    <Row alignCenter>
                        <TotalBadge count={totalCount} />

                        <Row marginLeft="8px">
                            <ReportType />
                        </Row>

                        <Row marginLeft="8px">
                            <ReportStatus />
                        </Row>

                        <Column marginLeft="16px">
                            <ReportDate />
                        </Column>
                    </Row>

                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Button
                            buttonType="text-black"
                            disabled={!resetIsAvailable || isLoading}
                            textSize="small"
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    )}
                </SectionBorder>
            </FiltersWrapper>

            {children}
        </Column>
    );
};
