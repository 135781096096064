import { Placement } from '@floating-ui/react';
import React, { FC, ReactNode, useState } from 'react';
import { ArrowIcon } from 'v2/assets/icons';
import { Dropdown } from 'v2/components/ui/Dropdown';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { SelectOption, SelectOptionValue } from '../types';
import { ArrowWrapper, CountWrapper, OptionsItem } from './styles';

export interface MultiSelectProps {
    options: SelectOption[];
    values?: SelectOptionValue[];
    onChange: (values: SelectOptionValue[]) => void;
    wide?: boolean;
    disabled?: boolean;
    placeholder?: string;
    placement?: Placement;
    title?: string;
    showArrow?: boolean;
    showPlaceholder?: boolean;
    maximumToChoose?: number;
    resetOption?: boolean;
    withBackground?: boolean;
    icon?: ReactNode;
}

export const MultiSelect: FC<MultiSelectProps> = ({
    options,
    wide = false,
    disabled = false,
    placeholder = 'All',
    placement = 'bottom-end',
    values = [],
    onChange,
    title,
    withBackground,
    icon,
    showArrow = true,
    showPlaceholder = true,
    maximumToChoose,
    resetOption = true
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const color = disabled ? grey[400] : values.length > 0 ? brandColors.black : grey[500];

    const handleChooseItem = (option: SelectOption) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let newSelectedOptions: SelectOptionValue[];

        if (values.includes(option.value)) {
            newSelectedOptions = values.filter(value => value !== option.value);
        } else {
            newSelectedOptions = [...values, option.value];
        }
        if (maximumToChoose === undefined || maximumToChoose >= newSelectedOptions.length) {
            onChange(newSelectedOptions);
        }
    };

    const handleResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (values.length === options.length && values.length > 0) {
            onChange([]);
        } else {
            onChange(options.map(option => option.value));
        }
    };

    return (
        <Dropdown
            disabled={disabled}
            isOpened={isOpened}
            placeholder={placeholder}
            placement={placement}
            titleComponent={
                <Section alignCenter justifyBetween noWrap>
                    {icon && <Row marginRight="8px">{icon}</Row>}

                    <Row marginRight="8px">
                        <Title noTextWrap color={disabled ? grey[400] : brandColors.black} size="-2">
                            {title}
                        </Title>
                    </Row>

                    {showPlaceholder && (
                        <>
                            {values.length > 0 ? (
                                <Row alignCenter noWrap>
                                    <CountWrapper>
                                        <Text noTextWrap color={color} size="-2">{`+ ${values.length}`}</Text>
                                    </CountWrapper>
                                </Row>
                            ) : (
                                <Text color={grey[500]} size="-2">
                                    {placeholder}
                                </Text>
                            )}
                        </>
                    )}

                    {showArrow && (
                        <ArrowWrapper isOpened={isOpened}>
                            <ArrowIcon color={color} />
                        </ArrowWrapper>
                    )}
                </Section>
            }
            wide={wide}
            withBackground={withBackground}
            onOpenedChange={setIsOpened}
        >
            {isOpened && (
                <>
                    {resetOption && (
                        <OptionsItem type="button" onClick={handleResetClick}>
                            <Text noTextWrap size="-2">
                                All
                            </Text>
                        </OptionsItem>
                    )}

                    {options.map(option => {
                        const isSelected = values.findIndex(value => value === option.value) !== -1;

                        return (
                            <OptionsItem
                                key={option.label}
                                disabled={disabled}
                                type="button"
                                onClick={handleChooseItem(option)}
                            >
                                <Text noTextWrap size="-2">
                                    {option.label}
                                </Text>
                                <Checkbox checked={isSelected} disabled={disabled} />
                            </OptionsItem>
                        );
                    })}
                </>
            )}
        </Dropdown>
    );
};
