import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const DropdownSectionWrapper = styled.div`
    width: 100%;
    height: fit-content;
`;

export const DropdownSectionButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 14px 40px 14px 16px;
    border-radius: 4px;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${brandColors.white};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
    }
`;
