export const registerSuccessMessage = 'You successfully registered a user!';
export const editSuccessMessage = 'Editing was successful!';
export const swapSuccessMessage = 'You successfully updated trending!';

export const errorDataMessage = 'Your data is incorrect!';
export const errorEmptyMessage = 'Fill the fields!';
export const errorNotEntryAllowed = 'You have no rights to enter!';
export const asyncError = 'Something was wrong!';
export const errorUserBanOrRemove = 'This user has been removed or banned earlier';

export const videosNotFoundMessage = 'Videos not found';

export const noContentMessage = 'No content';
export const curateVideoSuccessMessage = 'Video successfully curated!';
export const uploadNewVideoSuccessMessage = 'You successfully uploaded a video';
export const createAffiliateLinkSuccessMessage = 'You successfully created affiliate link!';
export const changeAffiliateLinkSuccessMessage = 'You successfully changed affiliate link!';

export const copyProductIDMessage = 'Product Id was copied!';

export const deleteTitle = 'Delete';

export const parseDeleteVideoModalContent = (name: string) => 'Are you sure you want to delete a video ' + name + '?';
export const parseDeleteVideoSuccessMessage = (name: string) => 'Video ' + name + ' successfully deleted';

export const parseDeleteSuccessMessageTeam = (name: string) => 'Team ' + name + ' successfully deleted';
export const parseDeleteModalContentTeam = (name: string) => 'Are you sure you want to delete a team ' + name + '?';

export const parseDeleteProductModalContent = (name: string) =>
    'Are you sure you want to delete a product "' + name + '"?';

export const parseDeleteProductSuccessMessage = (name: string) => 'Product ' + name + ' successfully deleted';
