import styled from 'styled-components';
import { transitionTime } from 'v2/constants/styles/special';
import { Active } from 'v2/types/global';
import { Opacity } from 'v2/types/styles';

interface OpacityActiveEffectProps extends Active, Opacity {}

export const OpacityActiveEffect = styled.div<OpacityActiveEffectProps>`
    opacity: ${({ active, opacity }) => (active ? '1' : opacity !== undefined ? opacity : '0.4')};
`;

export const CardHoverOpacityEffect = styled.div`
    transition: opacity ${transitionTime};
    opacity: 0;
`;
