import { StatusEndedIcon, StatusNotPublishedIcon, StatusPendingIcon, StatusVideoReportedIcon } from 'v2/assets/icons';
import React, { FC } from 'react';

interface Props {
    state: 'none' | 'pending' | 'reported' | 'ended';
}

export const StatusIcon: FC<Props> = ({ state }) => {
    switch (state) {
        case 'none':
            return <StatusNotPublishedIcon height={24} width={24} />;
        case 'reported':
            return <StatusVideoReportedIcon height={24} width={24} />;
        case 'ended':
            return <StatusEndedIcon height={24} width={24} />;
        case 'pending':
        default:
            return <StatusPendingIcon height={24} width={24} />;
    }
};
