import styled from 'styled-components';
import { flexCenter } from 'v2/constants/styles/mixins';

interface IconWrapperProps {
    size?: string;
    color?: string;
}

const defaultIconSize = '16px';

export const IconWrapper = styled.div<IconWrapperProps>`
    ${flexCenter};
    width: ${({ size }) => size || defaultIconSize};
    height: ${({ size }) => size || defaultIconSize};
    ${({ color }) => color && `color: ${color}`};
    flex-shrink: 0;
`;
