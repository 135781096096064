import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { Pagination } from 'v2/components/ui/Pagination';
import { Table } from 'v2/components/ui/Table';
import { Column, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { defaultVideoEngagementsQueryParams } from 'v2/constants/services/video-engagement';
import {
    videoEngagementEffects,
    videoEngagementEvents,
    videoEngagementStores
} from 'v2/stores/single-video/engagement';
import { Id } from 'v2/types/data';
import { getPageIndex } from 'v2/utils/services';
import { columns } from './constants';
import { prepareDataForTable } from './utils';

const { getVideoEngagementFx } = videoEngagementEffects;
const { updateParams, resetEngagement } = videoEngagementEvents;

export const Engagement: FC<Id> = ({ id }) => {
    const engagement = useStore(videoEngagementStores.$videoEngagement);
    const isLoading = useStore(getVideoEngagementFx.pending);

    const totalPages = engagement?.totalPages || 0;

    const params = useStore(videoEngagementStores.$engagementParams);
    const limit = params.limit;
    const currentPage = (params.pageIndex || 0) + 1;

    const tableData = prepareDataForTable(engagement?.items || []);

    const handlePageChange = (page: number) => {
        updateParams({ videoId: id, pageIndex: getPageIndex(page) });
    };

    const handleLimitChange = (size: number) => {
        updateParams({ videoId: id, limit: size });
    };

    useEffect(() => {
        id && getVideoEngagementFx({ videoId: id, ...defaultVideoEngagementsQueryParams });

        return () => {
            resetEngagement();
        };
    }, [id]);

    return (
        <Column noWrap width="100%">
            <Table
                cellCenter
                bottomExtra={
                    <SectionBorder borderTop padding="0 8px">
                        <Pagination
                            currentPage={currentPage}
                            size={limit}
                            totalPages={totalPages}
                            onChange={handlePageChange}
                            onPageSizeChange={handleLimitChange}
                        />
                    </SectionBorder>
                }
                columns={columns}
                data={tableData}
                emptyMessage="No engagements Found"
                isLoading={isLoading}
            />
        </Column>
    );
};
