import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { allOption } from 'v2/constants/services/videos';
import { categoriesEffects, categoriesStores } from 'v2/stores/categories';

const { getCategoriesFx } = categoriesEffects;

export const CategoryFilter: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { setOverwriteFilters } = domain.events;

    const categories = useStore(categoriesStores.$categories);
    const videosFilters = useStore($videosFilters);

    const categoryOptions: SelectOption[] = [
        allOption,
        ...categories.map(category => ({
            label: `${category.emojiCode} ${category.translation?.displayName}`,
            value: category.tagId || ''
        }))
    ];

    const onChangeCategory = (category: SelectOption) => {
        setOverwriteFilters({
            categoryTag: category.value as string
        });
    };
    useEffect(() => {
        getCategoriesFx();
    }, []);

    return (
        <Select
            wide
            options={categoryOptions}
            placement="bottom-start"
            title="Category"
            value={videosFilters.categoryTag || undefined}
            onChange={onChangeCategory}
        />
    );
};
