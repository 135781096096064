export enum TrendingType {
    None = 0,
    Tag = 100,
    Video = 200,
    User = 300,
    Category = 400,
    Collaboration = 500
}

export const maximumCollaborationsCount = 5;
export const maximumCategoriesCount = 8;
export const maximumCreatorsCount = 15;
export const maximumVideosCount = 50;
