import { useStore } from 'effector-react';
import { isUndefined, noop } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'v2/components/loaders/Spinner';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { maximumVideosCount } from 'v2/constants/services/trendings';
import { useDragItems } from 'v2/hooks/use-drag-items';
import { addTrendingVideosModal } from 'v2/stores/initialize-modals';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { trendingVideosEffects, trendingVideosStores } from 'v2/stores/trendings/videos';
import { getArrayWithPositions } from 'v2/utils/array';
import { BlockWrapper, Header } from '../styles';
import { AddVideosModal } from './AddVideosModal';
import { DragSpot } from './DragSpot';
import { VideoCard } from './VideoCard';
import { VideosGrid } from './styles';

const { getTrendingVideosFx, deleteVideoFromTrendingFx, swapVideosFx } = trendingVideosEffects;
const { $videos } = trendingVideosStores;

export const TrendingVideos = () => {
    const history = useHistory();

    const videos = useStore($videos);
    const isLoading = useStore(getTrendingVideosFx.pending);

    const handleInfoClick = (videoId?: string) => () => {
        if (videoId) {
            history.push(`${routePaths.videos}/${videoId}`);
        }
    };

    const handleDeleteClick = (id?: string) => () => {
        if (id) {
            confirmEvents.openConfirm({
                title: 'Delete video',
                description: 'Are you sure you want to remove the video from trending?',
                cancelButtonText: 'Yes',
                okButtonText: 'Cancel',
                cancelCallback: () => {
                    deleteVideoFromTrendingFx(id);
                },
                okCallback: noop
            });
        }
    };

    const openAddModal = () => {
        addTrendingVideosModal.openModal();
    };

    const onDragEnded = (fromIndex: number, toIndex: number) => {
        const fromItem = videos.find(video => video.position === fromIndex);
        const toItem = videos.find(video => video.position === toIndex);

        if (fromItem) {
            swapVideosFx({
                fromItem: { id: fromItem.id, position: toIndex },
                toItem: toItem ? { id: toItem.id, position: fromIndex } : null
            });
        }
    };

    const { onDragEnd, onDragOver, onDragStart, firstPosition, secondPosition } = useDragItems(onDragEnded);

    const renderCard = (item: YEAY.GetTrendingOverridesResponse, position: number) => {
        if (!isUndefined(firstPosition) && firstPosition !== position) {
            return (
                <DragSpot
                    isHovered={position === secondPosition}
                    positionIndex={position}
                    onDragEnd={onDragEnd}
                    onDragOver={() => onDragOver(position)}
                />
            );
        }

        return (
            <VideoCard
                draggable
                positionIndex={position}
                video={item.video}
                onDeleteClick={handleDeleteClick(item.id)}
                onDragEnd={onDragEnd}
                onDragOver={() => onDragOver(position)}
                onDragStart={() => onDragStart(position)}
                onInfoClick={handleInfoClick(item.video?.videoId)}
            />
        );
    };

    useEffect(() => {
        getTrendingVideosFx();
    }, []);

    return (
        <>
            <BlockWrapper>
                <Header>
                    <Row alignCenter>
                        <Title size="-2">Videos</Title>

                        <Column marginLeft="12px">
                            <TotalBadge count={videos.length} />
                        </Column>
                    </Row>

                    <Button buttonType="text-black" textSize="small" onClick={openAddModal}>
                        Add Videos
                    </Button>
                </Header>

                {isLoading ? (
                    <Section justifyCenter padding="8px">
                        <Spinner />
                    </Section>
                ) : (
                    <VideosGrid>
                        {getArrayWithPositions(maximumVideosCount).map(position => {
                            const item = videos.find(item => item.position === position);

                            return (
                                <Section key={position}>
                                    {item ? (
                                        renderCard(item, position)
                                    ) : (
                                        <DragSpot
                                            isHovered={position === secondPosition}
                                            positionIndex={position}
                                            textIsVisible={!isUndefined(firstPosition)}
                                            onClick={openAddModal}
                                            onDragEnd={onDragEnd}
                                            onDragOver={() => onDragOver(position)}
                                        />
                                    )}
                                </Section>
                            );
                        })}
                    </VideosGrid>
                )}
            </BlockWrapper>

            <AddVideosModal trendingVideos={videos} />
        </>
    );
};
