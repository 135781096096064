import { useStore } from 'effector-react';
import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { VideoSingleTab } from 'v2/constants/services/video';
import { CollaborationTab } from 'v2/pages/Video/tabs/CollaborationTab';
import { VideoTab } from 'v2/pages/Video/tabs/VideoTab';
import { singleVideoEffects, singleVideoStores } from 'v2/stores/single-video';
import { ContentWrapper } from './styles';
import { UserTab } from './tabs/UserTab';

interface VideoPageParams {
    videoId?: string;
}

export const Video = () => {
    const { videoId } = useParams<VideoPageParams>();
    const video = useStore(singleVideoStores.$video);
    const isLoading = useStore(singleVideoEffects.getVideoByIdFx.pending);
    const [activeTab, setActiveTab] = useState(VideoSingleTab.Video);

    const handleTabsChange = (value: VideoSingleTab) => {
        setActiveTab(value);
    };

    useLayoutEffect(() => {
        if (videoId) {
            singleVideoEffects.getVideoByIdFx(videoId);
            singleVideoEffects.getVideoSourceFx(videoId);
            singleVideoEffects.getVideoFromPlaylistFx(videoId);
        }
    }, [videoId]);

    return (
        <MainLayout>
            <DetailsPageLayout>
                <Tabs
                    activeTab={activeTab}
                    tabs={[
                        {
                            id: VideoSingleTab.Video,
                            title: 'VIDEO INFO',
                            content: (
                                <ContentWrapper>
                                    <VideoTab isLoading={isLoading} video={video} />
                                </ContentWrapper>
                            )
                        },
                        {
                            id: VideoSingleTab.User,
                            title: 'USER INFO',
                            content: (
                                <ContentWrapper>
                                    <UserTab userId={video?.ownerId} />
                                </ContentWrapper>
                            )
                        },
                        ...(video?.collaborationId
                            ? [
                                  {
                                      id: VideoSingleTab.Collaboration,
                                      title: 'COLLABORATION INFO',
                                      content: (
                                          <ContentWrapper>
                                              <CollaborationTab collaborationId={video?.collaborationId || ''} />
                                          </ContentWrapper>
                                      )
                                  }
                              ]
                            : [])
                    ]}
                    onChange={handleTabsChange}
                />
            </DetailsPageLayout>
        </MainLayout>
    );
};
