export enum LanguageType {
    English = 'en',
    Spain = 'es',
    Portugal = 'pt',
    Korea = 'ko',
    Argentina = 'ar',
    France = 'fr',
    Germany = 'de',
    Denmark = 'dk',
    Indonesia = 'id',
    Italy = 'it',
    Netherlands = 'nl',
    Norway = 'no',
    Poland = 'pl',
    Russia = 'ru',
    Slovakia = 'sk',
    Thailand = 'th',
    Turkey = 'tr',
    Czech = 'cs',
    Israel = 'he',
    Japan = 'ja',
    ElSalvador = 'sv',
    China = 'zh',
    Ukraine = 'uk',
    Montserrat = 'ms'
}

export const languages = {
    [LanguageType.English]: 'English',
    [LanguageType.Spain]: 'Spanish',
    [LanguageType.Portugal]: 'Portuguese',
    [LanguageType.Korea]: 'Korean',
    [LanguageType.Argentina]: 'Argentine',
    [LanguageType.France]: 'French',
    [LanguageType.Germany]: 'German',
    [LanguageType.Denmark]: 'Danish',
    [LanguageType.Indonesia]: 'Indonesian',
    [LanguageType.Italy]: 'Italian',
    [LanguageType.Netherlands]: 'Dutch',
    [LanguageType.Norway]: 'Norwegian',
    [LanguageType.Poland]: 'Polish',
    [LanguageType.Russia]: 'Russian',
    [LanguageType.Slovakia]: 'Slovak',
    [LanguageType.Thailand]: 'Thai',
    [LanguageType.Turkey]: 'Turkish',
    [LanguageType.Czech]: 'Czech',
    [LanguageType.Israel]: 'Hebrew',
    [LanguageType.Japan]: 'Japanese',
    [LanguageType.ElSalvador]: 'Salvadoran',
    [LanguageType.China]: 'Chinese',
    [LanguageType.Ukraine]: 'Ukrainian',
    [LanguageType.Montserrat]: 'English'
};

export const defaultLocaleCode = 'en-US';
