export const getEnrollmentTableColumns = (networks: YECM.TiltedPublishingNetworkRequirement[] | null | undefined) => [
    {
        title: 'USERNAME',
        dataIndex: 'username',
        key: 'username',
        width: '18%'
    },
    {
        title: 'TIME LEFT',
        dataIndex: 'timeLeft',
        key: 'timeLeft',
        width: '18%'
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
        width: '18%'
    },
    {
        title: 'VIDEO',
        dataIndex: 'video',
        key: 'video',
        width: '18%'
    },
    ...(networks && networks.length > 1
        ? [
              {
                  title: 'POST',
                  dataIndex: 'post',
                  key: 'post',
                  width: '18%'
              }
          ]
        : []),
    {
        title: 'ACTION',
        dataIndex: 'action',
        key: 'action',
        width: '10%'
    }
];
