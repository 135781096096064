import { useStore } from 'effector-react';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { maximumVideosCount } from 'v2/constants/services/trendings';
import { addTrendingVideosModal } from 'v2/stores/initialize-modals';
import { trendingVideosEffects } from 'v2/stores/trendings/videos';
import { trendingVideosDomain } from 'v2/stores/videos';
import { getArrayWithPositions } from 'v2/utils/array';
import { FilterLayout } from './FilterLayout';
import { VideoCard } from './VideoCard';
import { VideosGrid } from './styles';

const { deleteVideosFromTrendingFx, addVideosToTrendingFx } = trendingVideosEffects;

const { effects, stores, events } = trendingVideosDomain;

const { getVideosFx } = effects;
const { $videos } = stores;
const { resetFilters } = events;

interface Props {
    trendingVideos: YEAY.GetTrendingOverridesResponse[];
}

const AddVideosModalInner: FC<Props> = ({ trendingVideos }) => {
    const trendingVideosIds = trendingVideos.map(item => item.video?.videoId || '').filter(item => !!item);

    const [selectedIds, setSelectedIds] = useState<string[]>(trendingVideosIds);
    const [deleteVideoIds, setDeleteVideoIds] = useState<string[]>([]);

    const isLoading = useStore(getVideosFx.pending);
    const videosResponse = useStore($videos);
    const videos = videosResponse?.items || [];

    const submitIsDisabled =
        selectedIds.length > maximumVideosCount || isEqual(trendingVideosIds.sort(), selectedIds.sort());

    const close = () => {
        addTrendingVideosModal.closeModal();
    };

    const handleSelect = (videoId?: string) => () => {
        if (videoId) {
            if (trendingVideosIds.includes(videoId)) {
                const newDeleteIds = deleteVideoIds.includes(videoId)
                    ? deleteVideoIds.filter(id => id !== videoId)
                    : [...deleteVideoIds, videoId];

                setDeleteVideoIds(newDeleteIds);
            }

            if (selectedIds.includes(videoId)) {
                setSelectedIds(ids => ids.filter(id => id !== videoId));
            } else if (selectedIds.length < maximumVideosCount) {
                setSelectedIds(ids => [...ids, videoId]);
            }
        }
    };

    const deleteUsers = async () => {
        if (deleteVideoIds.length === 0) {
            return;
        }

        const ids = deleteVideoIds
            .map(id => trendingVideos.find(item => item.video?.videoId === id)?.id || '')
            .filter(id => !!id);

        await deleteVideosFromTrendingFx(ids);
    };

    const addUsers = async () => {
        if (selectedIds.length === 0) {
            return;
        }

        const allPositions = getArrayWithPositions(maximumVideosCount);
        const currentPositions =
            trendingVideos
                .filter(item => !!item.video?.videoId && !deleteVideoIds.includes(item.video?.videoId))
                .map(item => item.position) || [];

        const newIds = selectedIds.filter(id => !trendingVideosIds.includes(id));

        const requestData = newIds.map(id => {
            const newPosition = allPositions.find(position => !currentPositions.includes(position));
            currentPositions.push(newPosition);

            return {
                position: newPosition,
                targetId: id
            };
        });

        await addVideosToTrendingFx(requestData);
    };

    const handleSubmit = async () => {
        if (submitIsDisabled) {
            return;
        }

        await deleteUsers();
        await addUsers();

        close();
    };

    useEffect(() => {
        getVideosFx();

        return () => {
            resetFilters();
        };
    }, []);

    return (
        <ModalLayout
            footerExtra={
                <Text>
                    {selectedIds.length}/{maximumVideosCount}
                </Text>
            }
            submitDisabled={submitIsDisabled}
            submitText={deleteVideoIds.length > 0 ? 'Save' : 'Add'}
            title="Add Trending Videos"
            onCancel={close}
            onSubmit={handleSubmit}
        >
            <FilterLayout isLoading={isLoading}>
                {isLoading ? (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                ) : (
                    <>
                        {videos.length > 0 ? (
                            <VideosGrid>
                                {videos.map(video => (
                                    <VideoCard
                                        key={video.id}
                                        data={video}
                                        disabled={selectedIds.length >= maximumVideosCount}
                                        selected={!!video.id && selectedIds.includes(video.id)}
                                        onClick={handleSelect(video.id)}
                                    />
                                ))}
                            </VideosGrid>
                        ) : (
                            <Empty yPadding="0" />
                        )}
                    </>
                )}
            </FilterLayout>
        </ModalLayout>
    );
};

export const AddVideosModal: FC<Props> = ({ trendingVideos }) => {
    const { visible } = useStore(addTrendingVideosModal.$modal);

    const close = () => {
        addTrendingVideosModal.closeModal();
    };

    return (
        <ModalWrapper size="large" visible={visible} onClose={close}>
            <AddVideosModalInner trendingVideos={trendingVideos} />
        </ModalWrapper>
    );
};
