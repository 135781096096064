import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { transactionsEvents, transactionsStores } from 'v2/stores/transactions';

export const TransactionsDate = () => {
    const { UtcFrom, UtcTo } = useStore(transactionsStores.$transactionsFilters);
    const [dateRange, setDateRange] = useState<DatePickerType>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange as Date[];

    useEffect(() => {
        if (UtcFrom && UtcTo) {
            setDateRange([new Date(UtcFrom), new Date(UtcTo)]);
        } else {
            setDateRange([new Date(), new Date()]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UtcFrom, UtcTo]);

    const onChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            transactionsEvents.setOverwriteFilters({
                UtcFrom: start.toISOString(),
                UtcTo: end.toISOString()
            });
        }
    };

    return (
        <DatePicker
            endDate={endDate}
            endPlaceholder=" 2020-12-26"
            startDate={startDate}
            startPlaceholder="2020-12-25 -"
            title="Dates"
            onChange={onChangeDate}
        />
    );
};
