import format from 'date-fns/format';
import { dateBadgeFormat } from 'v1/constants/defaults/formats';
import { Roles, accessRoles } from 'v1/constants/defaults/users';
import { cardPaddingMultiplier } from 'v1/constants/styles/others';
import { padding } from 'v1/constants/styles/sizes';

// INPUT NUMBER WHICH POINTING THE QUANTITY OF CARDS IN A ROW FOR SELECTED WIDTH INTERVAL (FROM TO)
export const adaptiveCard: (n: number, from: string, to: string) => string = (n, from, to) => {
    if (Number.isInteger(n))
        if (n > 0)
            return `@media (min-width: ${from}) and (max-width: ${to}) {
       // width: calc(100% / ${n} - ${cardPaddingMultiplier} * ${padding} * ${n} / ${n});
         width: calc(100% / ${n} - ${cardPaddingMultiplier} * ${padding} * ${n - 1} / ${n});
         &:nth-child(${n}n) {
          margin-right: 0;
       }
    }`;

    return '';
};

export const toCamelCaseRole = (role: string) =>
    role
        .toLowerCase()
        .split(/(manager|super)/)
        .filter(word => word !== '')
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join('');

// TRIGGER COPY TO CLIPBOARD
export const triggerCopy: (text: string) => void = text => navigator.clipboard.writeText(text);

// give access a user as natural (-1 - no any access) number (the less number, the more rights user has)
export const giveAccess: (user: YEAY.UserAuthorizeResponse) => number = user => {
    const roles = user?.user?.roles?.map(role => toCamelCaseRole(role));

    let access: Roles = Roles.Unknown;

    if (roles && roles.length) {
        accessRoles.forEach(role => {
            // !!! Because role - it is key for Roles enum, but I was unable to type the accessRoles array !!!
            // @ts-ignore
            roles.includes(role) && (access > Roles[role] || access === Roles.Unknown) && (access = Roles[role]);
        });
    }
    return access;
};

export const getStoriesTitle = (base: string) => base.split('/').slice(-3, -1).join('/');

export const getDateFromString = (dateISOString?: string) => {
    if (!dateISOString) return '';

    //2021-01-09T13:57:15.832Z -> 2021-01-09
    return dateISOString.split('T')[0];
};

export const formatDateISOString = (dateISOString?: string) => {
    const date = dateISOString && Date.parse(dateISOString);

    return date ? format(date, dateBadgeFormat) : '';
};

export const getTimeFromString = (dateISOString?: string) => {
    if (!dateISOString) return '';
    //2021-01-09T13:57:15.832Z -> 13:57:15.832Z

    const time = dateISOString.split('T')[1];
    if (!time) return '';

    //13:57:15.832Z => 13:57:15
    return time.split('.')[0];
};

export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};

export const formatId = (id: string) => (id.length > 10 ? `...${id.substring(12)}` : id);

export const toggleValueToArray = (array: string[], value: string) =>
    array.some(item => item === value) ? array.filter(item => item !== value) : [...array, value];
