import styled from 'styled-components';

interface Props {
    cover?: boolean;
}

export const StyledVideo = styled.video<Props>`
    width: 100%;
    height: 100%;

    ${({ cover }) => cover && 'object-fit: cover'};
`;
