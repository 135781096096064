import React from 'react';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { TrendingCategories } from './TrendingCategories';
import { TrendingCollaborations } from './TrendingCollaborations';
import { TrendingCreators } from './TrendingCreators';
import { TrendingVideos } from './TrendingVideos';
import { TopBlock } from './styles';

export const Trendings = () => (
    <MainLayout>
        <TopBlock />

        <Column noWrap padding="16px 32px 32px 16px" width="100%">
            <Section marginBottom="16px">
                <TrendingCollaborations />
            </Section>

            <Section marginBottom="16px">
                <TrendingCategories />
            </Section>

            <Section marginBottom="16px">
                <TrendingCreators />
            </Section>

            <Section marginBottom="16px">
                <TrendingVideos />
            </Section>
        </Column>
    </MainLayout>
);
