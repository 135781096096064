import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { metrics } from 'v2/components/cards/VideoCard/constants';
import { VideoStatus } from 'v2/components/common/VideoStatus';
import { EditVideoCategoryModal } from 'v2/components/modals/EditVideoCategoryModal';
import { EditVideoHashtagsModal } from 'v2/components/modals/EditVideoHashtagsModal';
import { Avatar } from 'v2/components/ui/Avatar';
import { Category } from 'v2/components/ui/Category';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { Image } from 'v2/components/ui/Image';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Tooltip, TooltipText } from 'v2/components/ui/Tooltip';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { LanguageType, languages } from 'v2/constants/languages';
import { routePaths } from 'v2/constants/routes';
import { VideoCurationState, videoState, videoStateTitles } from 'v2/constants/services/videos';
import { GridItem, PropertiesGrid, StyledLink, StyledText } from 'v2/pages/Video/tabs/VideoTab/VideoInfoRight/styles';
import { authStores } from 'v2/stores/auth';
import { editWebsiteModal } from 'v2/stores/initialize-modals';
import { singleVideoStores } from 'v2/stores/single-video';
import { videoActionEvents } from 'v2/stores/single-video/actions';
import { getCategoryForOutput } from 'v2/utils/categories';
import { getCompactNumber } from 'v2/utils/formats';
import { getLanguageImage } from 'v2/utils/languages';
import { prepareHashtags } from 'v2/utils/string';
import { EditWebsiteModal } from './EditWebsiteModal';

interface Props {
    video: YEAY.AdminGetVideoResponse | null;
}

export const VideoInfoRight: FC<Props> = ({ video }) => {
    const isAdmin = useStore(authStores.$isAdmin);
    const videoDataFromPlaylist = useStore(singleVideoStores.$videoFromPlaylist);

    const curationState = (video?.validation?.yeay?.curationState || VideoCurationState.None) as VideoCurationState;
    const language = video?.audioLanguages && video?.audioLanguages.length > 0 ? video?.audioLanguages[0] : '';
    const tags = prepareHashtags(video?.hashTags);
    const category = getCategoryForOutput(video?.categoryDetails, video?.category);
    const website = !!video?.collaborationId ? video?.brandWebsite : videoDataFromPlaylist?.websiteUrl;

    const editWebsite = () => {
        if (video?.id && video?.ownerId) {
            editWebsiteModal.openModal({
                videoId: video.id,
                ownerId: video.ownerId,
                currentUrl: videoDataFromPlaylist?.websiteUrl || ''
            });
        }
    };

    const editCategory = () => {
        if (video?.id && video?.ownerId) {
            videoActionEvents.openCategoryEditModal({
                ownerId: video.ownerId,
                videoId: video.id,
                currentCategoryId: video.categoryDetails?.tagId || ''
            });
        }
    };

    const editHashtag = () => {
        if (video?.id && video?.ownerId) {
            videoActionEvents.openHashtagsEditModal({
                ownerId: video.ownerId,
                videoId: video.id,
                currentHashtags: video.hashTags || []
            });
        }
    };

    return (
        <>
            <Section>
                <PropertiesGrid>
                    <GridItem>
                        <PropertyLayout textCopy={video?.id || ''} title="Video ID">
                            <Text isSemibold size="-2">
                                {video?.id || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Video Status">
                            <VideoStatus status={videoState[curationState]} titles={videoStateTitles} />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Created">
                            <DateProperty date={video?.utcUploaded} padding="0" />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={video?.ownerId || ''} title="User ID">
                            <Text isSemibold size="-2">
                                {video?.ownerId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Username">
                            <StyledLink to={`${routePaths.users}/${video?.ownerId}`}>
                                <Text isSemibold size="-2">
                                    @{video?.username || '-'}
                                </Text>
                            </StyledLink>
                            {video?.isTrusted && (
                                <Row marginLeft="8px">
                                    <Tooltip overlay={<TooltipText text="Account is trusted" />} placement="top">
                                        <DoneIcon height={14} width={14} />
                                    </Tooltip>
                                </Row>
                            )}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Language">
                            {language ? (
                                <Row alignCenter noWrap>
                                    <Row marginRight="4px">
                                        <Text size="-2">{languages[language as LanguageType]}</Text>
                                    </Row>
                                    <Image height="12px" src={getLanguageImage(language)} width="16px" />
                                </Row>
                            ) : (
                                <Text isSemibold size="-2">
                                    -
                                </Text>
                            )}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={video?.brandId || ''} title="Brand ID">
                            <Text isSemibold size="-2">
                                {video?.brandId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Brand Name">
                            <Text isSemibold size="-2">
                                {video?.brandName || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Brand Image">
                            <Avatar size="24px" src={video?.brandImage || ''} />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={video?.collaborationId || ''} title="Collab ID">
                            <Text isSemibold size="-2">
                                {video?.collaborationId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout
                            extra={
                                isAdmin ? (
                                    <Button noPadding buttonType="text-black" textSize="small" onClick={editCategory}>
                                        Edit
                                    </Button>
                                ) : undefined
                            }
                            title="Category"
                        >
                            {category ? (
                                <Category text={category} />
                            ) : (
                                <Text isSemibold size="-2">
                                    -
                                </Text>
                            )}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout
                            extra={
                                isAdmin && !video?.collaborationId ? (
                                    <Button noPadding buttonType="text-black" textSize="small" onClick={editWebsite}>
                                        Edit
                                    </Button>
                                ) : undefined
                            }
                            textCopy={website || ''}
                            title="Website"
                        >
                            <StyledText isSemibold size="-2">
                                {website || '-'}
                            </StyledText>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem column="1/4">
                        <PropertyLayout
                            extra={
                                isAdmin && !video?.collaborationId ? (
                                    <Button noPadding buttonType="text-black" textSize="small" onClick={editHashtag}>
                                        Edit
                                    </Button>
                                ) : undefined
                            }
                            title="Hashtags"
                        >
                            <Row noWrap overflowX="auto" paddingRight="8px">
                                {tags.map(tag => (
                                    <Row key={tag} marginRight="4px">
                                        <Category text={tag} />
                                    </Row>
                                ))}
                            </Row>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem column="1/4">
                        <Section padding="12px 14px">
                            {metrics.map(item => (
                                <Row key={item.key} marginRight="40px">
                                    <IconTextItem
                                        icon={item.icon}
                                        text={getCompactNumber(video?.engagementStatistics?.[item.key])}
                                    />
                                </Row>
                            ))}
                        </Section>
                    </GridItem>
                </PropertiesGrid>
            </Section>

            {isAdmin && <EditVideoCategoryModal />}
            {isAdmin && <EditWebsiteModal />}
            {isAdmin && !video?.collaborationId && <EditVideoHashtagsModal />}
        </>
    );
};
