import React from 'react';
import { ReportType } from 'v2/components/common/ReportType';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { StartEllipsisProperty } from 'v2/components/ui/PropertyLayout/components/StartEllipsisProperty';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { reportType } from 'v2/constants/services/reports';
import { ReportStatus } from 'v2/pages/Reports/tabs/components/ReportStatus';

export const prepareReportsForTable = (reports: YEAY.ReportResponse[]) =>
    reports.map(report => {
        const type = report?.reportType || 0;

        return {
            key: report.assetId,
            type: <ReportType type={type} />,
            reportId: (
                <Section justifyCenter>
                    <Row maxWidth="250px">
                        <StartEllipsisProperty isSemibold id={report.id} title="Report ID" />
                    </Row>
                </Section>
            ),
            assetId: (
                <Section justifyCenter>
                    <Row maxWidth="250px">
                        <StartEllipsisProperty isSemibold id={report.assetId} title={`${reportType[type]} ID`} />
                    </Row>
                </Section>
            ),
            count: <Text size="-1">{report.reportCount}</Text>,
            date: <DateProperty alignCenter date={report.dateFirstReported} direction="row" />,
            status: (
                <Section justifyCenter>
                    <ReportStatus isTableReport status={report.reportStatus || 0} />
                </Section>
            )
        };
    });
