import { primaryBorderRadius, titleMarginBottom } from 'v1/constants/styles/sizes';

export const mobileItemHeight = 32;

const beforeBorderRadiusHeight = '5px';
export const lastItemBorderHeight = parseInt(primaryBorderRadius) + parseInt(beforeBorderRadiusHeight) + 'px';

export const absoluteWrapperTop = '50px';
export const absoluteWrapperWithTitleTop = parseInt(absoluteWrapperTop) + parseInt(titleMarginBottom) + 'px';

export const selectorRightPadding = '12px';
export const selectorLeftPadding = selectorRightPadding;
export const selectorVerticalPadding = '8px';
