import styled from 'styled-components';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { defaultInputStyles, disableDefaultButtonStyleMixin, flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const Wrapper = styled(Section)`
    padding: 12px 8px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
`;

interface PageButtonProps {
    isCurrent?: boolean;
}

export const PageButton = styled.button<PageButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    padding: 2px 7px;
    border-radius: 2px;
    text-align: center;
    color: ${({ isCurrent }) => (isCurrent ? brandColors.black : grey[400])};
    margin-right: 8px;
    transition: all ${transitionTime} linear;

    &:disabled {
        cursor: default;
    }

    &:hover:not(:disabled) {
        background-color: ${grey[100]};
        color: ${brandColors.black};
    }

    &:last-of-type {
        margin-right: 0;
    }
`;

export const SearchWrapper = styled(Row)`
    justify-content: flex-end;
    align-items: center;
`;

export const SearchInput = styled.input`
    ${defaultInputStyles};
    width: 52px;
    height: 24px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid ${grey[400]};
    padding-left: 8px;
    padding-right: 8px;
    appearance: none;
    transition: all ${transitionTime} linear;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        border-color: ${grey[600]};
    }

    &:focus {
        border-color: ${brandColors.black};
    }
`;
