import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { media } from 'v2/constants/styles/media';

export const ContentWrapper = styled(Section)`
    padding: 16px;

    ${media.laptopSmall(`
        padding: 24px 32px 24px 16px;
    `)};
`;
