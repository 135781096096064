import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { Wrapper } from './styles';

interface Props {
    index?: number;
}

export const Position: FC<Props> = ({ index = 0 }) => (
    <Wrapper>
        <Text size="-2">{`#${index + 1}`}</Text>
    </Wrapper>
);
