import { createEffect, createEvent, createStore } from 'effector';
import { yecmAPI } from 'v2/services/yecm';

// Events

const resetStores = createEvent();

// Effects

const getBrandFx = createEffect({
    handler: async (organizationId: string) => {
        try {
            const { isOk, response } = await yecmAPI.brands.getBrandById({ organizationId });

            if (!isOk || !response) {
                throw new Error();
            }

            return response;
        } catch {
            return null;
        }
    }
});

// Stores

const $brandInfo = createStore<YECM.OrganizationResponse | null>(null)
    .on(getBrandFx.doneData, (_, payload) => payload)
    .reset(resetStores);

// Exports

export const brandDetailsEvents = {
    resetStores
};

export const brandDetailsEffects = {
    getBrandFx
};

export const brandDetailsStores = {
    $brandInfo
};
