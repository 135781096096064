import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'v2/services/yasy';

// Events

const resetUserNetworks = createEvent();

// Effects
const getUserNetworksFx = createEffect({
    handler: async (userId: string) => {
        try {
            const { items } = await API.user.getUserNetworks({ userId });

            if (!items) {
                throw new Error();
            }

            return items;
        } catch (error) {
            console.log('There was a problem receiving user networks.');
            return [];
        }
    }
});

// Stores
const $userNetworks = createStore<YEAY.GetUserExternalNetworkResponse[] | null>(null)
    .on(getUserNetworksFx.doneData, (_, newState) => newState)
    .reset(resetUserNetworks);

// Exports
export const userNetworksEffects = {
    getUserNetworksFx
};

export const userNetworksStores = {
    $userNetworks
};

export const userNetworksEvents = {
    resetUserNetworks
};
