import React, { ChangeEvent, FC } from 'react';
import { HiddenCheckbox } from 'v2/components/ui/inputs/Checkbox/styles';
import { Disabled } from 'v2/types/form';
import { Label } from './styles';

export interface SwitchProps extends Disabled {
    name?: string;
    checked?: boolean;
    small?: boolean;
    onChange?: (checked: boolean) => void;
}

export const Switch: FC<SwitchProps> = ({ name, checked, disabled, small, onChange }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked);
    };

    return (
        <Label checked={checked} disabled={disabled} small={small}>
            <HiddenCheckbox checked={checked} disabled={disabled} name={name} type="checkbox" onChange={handleChange} />
        </Label>
    );
};
