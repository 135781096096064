import { SelectorsItem } from 'v2/components/ui/selects/NestedSelect/types';

export const localeSelectors: SelectorsItem = {
    title: 'Locale',
    value: 'locale',
    searchPlaceholder: 'Input a locale',
    nestedSelectors: [
        { value: 'az-AZ', type: 'locale' },
        { value: 'en-AE', type: 'locale' },
        { value: 'en-AT', type: 'locale' },
        { value: 'en-AU', type: 'locale' },
        { value: 'en-BE', type: 'locale' },
        { value: 'en-CA', type: 'locale' },
        { value: 'en-CH', type: 'locale' },
        { value: 'en-CY', type: 'locale' },
        { value: 'en-DE', type: 'locale' },
        { value: 'en-DK', type: 'locale' },
        { value: 'en-FI', type: 'locale' },
        { value: 'en-GB', type: 'locale' },
        { value: 'en-HK', type: 'locale' },
        { value: 'en-IE', type: 'locale' },
        { value: 'en-IN', type: 'locale' },
        { value: 'en-JM', type: 'locale' },
        { value: 'en-KE', type: 'locale' },
        { value: 'en-MT', type: 'locale' },
        { value: 'en-MY', type: 'locale' },
        { value: 'en-NA', type: 'locale' },
        { value: 'en-NG', type: 'locale' },
        { value: 'en-NL', type: 'locale' },
        { value: 'en-NZ', type: 'locale' },
        { value: 'en-PH', type: 'locale' },
        { value: 'en-PK', type: 'locale' },
        { value: 'en-SE', type: 'locale' },
        { value: 'en-SG', type: 'locale' },
        { value: 'en-UG', type: 'locale' },
        { value: 'en-US', type: 'locale' },
        { value: 'en-ZA', type: 'locale' },
        { value: 'es-MX', type: 'locale' },
        { value: 'et-EE', type: 'locale' },
        { value: 'fi-FI', type: 'locale' },
        { value: 'fr-FR', type: 'locale' },
        { value: 'id-ID', type: 'locale' },
        { value: 'it-IT', type: 'locale' },
        { value: 'ja-JP', type: 'locale' },
        { value: 'ko-KR', type: 'locale' },
        { value: 'lv-LV', type: 'locale' },
        { value: 'nb-NO', type: 'locale' },
        { value: 'nl-NL', type: 'locale' },
        { value: 'pt-BR', type: 'locale' },
        { value: 'ru-RU', type: 'locale' },
        { value: 'tr-TR', type: 'locale' },
        { value: 'zh-HK', type: 'locale' }
    ]
};
