import { createEvent, createStore } from 'effector';

// Events

const toggleMute = createEvent();

// Stores

const $isMuted = createStore(true).on(toggleMute, isMuted => !isMuted);

// Exports

export const playerEvents = { toggleMute };

export const playerStores = { $isMuted };
