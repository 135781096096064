// green
export const primaryColor = 'rgb(67, 160, 71)';
export const greenYellow = '#00ff44';
export const brightGreen = '#03a400';
export const yeayColor = '#86f796';

// white
export const white = '#ffffff';

// black
export const black = '#000000';
export const lightBlack = '#060c07';
export const semitransparentBlack = 'rgba(0, 0, 0, 0.2)';

//semitransparent

// orange
export const orange = '#ff8000';
export const pastelOrange = '#fdf6ef';

// red
export const brightRed = '#ff3333';
export const lightRed = '#f56c6c';
export const powderPink = '#ffe7e7';
export const pastelCoral = '#fff1f1';
export const lightCoral = '#fdf3f3';

// purple
export const purple = '#9d41ac';
export const pastelPurple = '#f1e3fd';

// violet
export const violet = '#6519ca';
export const lightViolet = '#8240ec';
export const pastelViolet = '#d6d6ff';

// blue
export const blue = '#3360ff';
export const brightBlue = '#0f52ff';
export const pastelBlue = '#eff3ff';
export const lightPastelBlue = '#f5f8ff';
export const lightSkyBlue = '#e7f3ff';
export const dirtyBlue = '#7b8193';

// grey
export const grey27 = '#fdfdfd';
export const grey3 = '#f8f8f8';
export const grey8 = '#fafafa';
export const grey12 = '#f5f7f9';
export const grey15 = '#f7f7f7';

export const grey25 = '#F0F0F0';

export const grey5 = '#eaeaea';
export const grey11 = '#eeeeee';

export const grey2 = '#d3d3d3';
export const grey24 = '#cccccc';
export const grey10 = '#c4c4c4';

export const grey21 = '#aeaeae';
export const grey14 = '#a6a6a6';
export const grey7 = '#949494';

export const grey4 = '#666666';
export const grey23 = '#161616';

export const darkGrey = '#3A3A42';

export const grey28 = '#f9f9f9';
