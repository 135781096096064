import React from 'react';
import closeIcon from 'v1/assets/close_closable_tag.svg';
import { NoopClick } from 'v1/types/global';
import { ClosableButtonImg } from './styled';

export interface ClosableButtonProps extends NoopClick {}

export const ClosableButton = ({ onClick }: ClosableButtonProps) => (
    <ClosableButtonImg pointer src={closeIcon} onClick={onClick} />
);
