import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { ButtonSort } from 'v2/components/filter/videos/ButtonSort';
import { CategoryFilter } from 'v2/components/filter/videos/CategoryFilter';
import { DateFilter } from 'v2/components/filter/videos/DateFilter';
import { EngagementFilter } from 'v2/components/filter/videos/EngagementFilter';
import { LanguageFilter } from 'v2/components/filter/videos/LanguageFilter';
import { StatusFilter } from 'v2/components/filter/videos/StatusFilter';
import { TrustedFilter } from 'v2/components/filter/videos/TrustedFilter';
import { VideosSearch } from 'v2/components/filter/videos/VideosSearch';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Pagination } from 'v2/components/ui/Pagination';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, FlexGrow, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { trendingVideosParams } from 'v2/constants/services/videos';
import { trendingVideosDomain } from 'v2/stores/videos';
import { getPageIndex } from 'v2/utils/services';

const { events, stores } = trendingVideosDomain;

const { $videos, $resetIsAvailable } = stores;
const { addFilters, resetFilters } = events;

interface Props {
    isLoading?: boolean;
}

export const FilterLayout: FC<Props> = ({ isLoading, children }) => {
    const videosResponse = useStore($videos);
    const resetIsAvailable = useStore($resetIsAvailable);

    const totalPages = videosResponse?.totalPages || 0;
    const currentPage = (videosResponse?.currentPageIndex || 0) + 1;
    const totalItems = videosResponse?.totalRecords || 0;
    const pageSize = trendingVideosParams.limit;

    const handlePageChange = (page: number) => {
        addFilters({ pageIndex: getPageIndex(page) });
    };

    const handleResetFilters = () => {
        resetFilters();
    };

    return (
        <Column noWrap minHeight="380px" paddingBottom="12px" paddingTop="12px" width="100%">
            <Section alignCenter justifyBetween>
                <FlexGrow>
                    <Section alignCenter noWrap>
                        <Section maxWidth="440px">
                            <VideosSearch domain={trendingVideosDomain} />
                        </Section>

                        <Row marginLeft="16px">
                            <TrustedFilter domain={trendingVideosDomain} />
                        </Row>
                    </Section>
                </FlexGrow>

                <Row alignCenter justifyEnd noWrap>
                    <Column marginRight="8px">
                        <LanguageFilter domain={trendingVideosDomain} />
                    </Column>

                    <Column marginRight="8px">
                        <ButtonSort domain={trendingVideosDomain} />
                    </Column>

                    <Button
                        buttonType="text-black"
                        disabled={!resetIsAvailable || isLoading}
                        textSize="small"
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                </Row>
            </Section>

            <Section alignCenter paddingBottom="8px" paddingTop="8px">
                <Column marginRight="8px">
                    <TotalBadge count={totalItems} />
                </Column>

                <Column marginRight="8px">
                    <CategoryFilter domain={trendingVideosDomain} />
                </Column>

                <Column marginRight="8px">
                    <StatusFilter domain={trendingVideosDomain} />
                </Column>

                <Column marginRight="8px">
                    <EngagementFilter domain={trendingVideosDomain} />
                </Column>
            </Section>

            <Section alignCenter paddingBottom="8px" paddingTop="8px">
                <DateFilter domain={trendingVideosDomain} placement="bottom-start" />
            </Section>

            <Section marginTop="8px">{children}</Section>

            {!isLoading && totalPages > 1 && (
                <Section justifyCenter marginTop="8px">
                    <Pagination
                        currentPage={currentPage}
                        size={pageSize}
                        totalPages={totalPages}
                        onChange={handlePageChange}
                    />
                </Section>
            )}
        </Column>
    );
};
