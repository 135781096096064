import { differenceInYears, format, formatDistanceToNowStrict } from 'date-fns';

const dateFormat = 'dd.MM.yyyy';

/**
 * Return a date in the given format
 * @param date
 * @param pattern default (dd-MM-yyyy)
 * @returns
 */
export const getFormattedDate = (date?: string | null, pattern = dateFormat) => {
    if (!date) return '';

    const parsedDate = Date.parse(date);

    if (!parsedDate) return '';
    return format(parsedDate, pattern);
};

/**
 * Returns age by date of birth
 * @param date
 * @returns
 */
export const getAge = (date?: string | null) => {
    if (!date) return 0;

    const parsedDate = Date.parse(date);

    if (!parsedDate) return 0;

    return differenceInYears(new Date(), parsedDate);
};

export const differenceFromCurrentDate = (dateISOString: string) =>
    formatDistanceToNowStrict(Date.parse(dateISOString));
