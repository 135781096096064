import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Events

const resetReportUser = createEvent();
const setReport = createEvent<YEAY.AdminGetReportedUserResponse>();

// Effects

const getReportUserInfoFx = createEffect({
    handler: async (data: YEAY.AdminGetReportedUserRequest) => {
        try {
            const response = await API.report.getUserReports(data);

            if (!response) {
                throw new Error();
            }

            return response;
        } catch (error) {
            notify('There was a problem get user reports, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $reportUserInfo = createStore<YEAY.AdminGetReportedUserResponse | null>({})
    .on(getReportUserInfoFx.doneData, (_, newState) => newState)
    .on(setReport, (_, newState) => newState)
    .reset(resetReportUser);

// Exports

export const reportUserEvents = {
    resetReportUser,
    setReport
};

export const reportUserEffects = {
    getReportUserInfoFx
};

export const reportUserStores = {
    $reportUserInfo
};
