export const columns = [
    {
        title: 'USERNAME',
        dataIndex: 'username',
        key: 'username',
        width: '20%'
    },
    {
        title: 'POST',
        dataIndex: 'post',
        key: 'post',
        width: '20%'
    },
    {
        title: 'VIEWS',
        dataIndex: 'views',
        key: 'views',
        width: '20%'
    },
    {
        title: 'LIKES',
        dataIndex: 'likes',
        key: 'likes',
        width: '20%'
    },
    {
        title: 'SHARES',
        dataIndex: 'shares',
        key: 'shares',
        width: '20%'
    }
];
