import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { NestedSelect } from 'v2/components/ui/selects/NestedSelect';
import { NestedSelectParameters } from 'v2/components/ui/selects/NestedSelect/types';
import { locationsEffects, locationsStores } from 'v2/stores/locations';
import { localeSelectors } from './constants';

export const LocaleFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const locations = useStore(locationsStores.$locations);
    const countriesIsLoaded = useStore(locationsStores.$countriesIsLoaded);

    const { region, locale, country } = useStore($usersFilters);

    const locationsLoading = useStore(locationsEffects.getCountriesFx.pending);
    const regionsLoading = useStore(locationsEffects.getRegionsByCountryFx.pending);

    const selectors = [
        localeSelectors,
        {
            title: 'Country / Region',
            value: 'country-region',
            searchPlaceholder: 'Type country',
            switch: 'Region',
            isFetched: countriesIsLoaded,
            onFetch: () => locationsEffects.getCountriesFx(),
            nestedSelectors: locations
                .filter(c => !!c.countryCode && !!c.countryName)
                .map(({ countryCode, countryName, regions }) => ({
                    title: countryName || '-',
                    value: countryCode || '',
                    type: 'country',
                    searchPlaceholder: 'Type Region',
                    isFetched: false,
                    onFetch: ({ value }: NestedSelectParameters) => locationsEffects.getRegionsByCountryFx(value),
                    nestedSelectors: regions
                        ? regions.map(region => ({
                              value: region,
                              type: 'region'
                          }))
                        : []
                }))
        }
    ];

    const handleSelect = ({ type, value }: NestedSelectParameters) => {
        if (['region', 'locale', 'country'].includes(type)) {
            addFilters({
                region: undefined,
                locale: undefined,
                country: undefined,
                [type]: value
            });
        }
    };

    return (
        <NestedSelect
            isLoading={locationsLoading || regionsLoading}
            selectors={selectors}
            title="Locale/Region"
            value={region || locale || country || ''}
            onSelect={handleSelect}
        />
    );
};
