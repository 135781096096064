import { createEvent, createStore } from 'effector';
import { Noop } from 'v2/types/types';

interface Confirm {
    title?: string;
    description?: string;
    cancelButtonText?: string;
    cancelCallback?: Noop;
    okButtonText?: string;
    okCallback?: Noop;
}

const defaultConfirmValues: Confirm = {
    cancelButtonText: 'Cancel',
    okButtonText: 'Ok'
};

// Events
const openConfirm = createEvent<Confirm>();
const closeConfirm = createEvent();

// Stores
const $confirmInfo = createStore<Confirm | null>(null)
    .on(openConfirm, (_, payload) => ({
        ...defaultConfirmValues,
        ...payload
    }))
    .reset(closeConfirm);

// Exports
export const confirmStores = {
    $confirmInfo
};

export const confirmEvents = {
    openConfirm,
    closeConfirm
};
