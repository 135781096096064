import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import history from 'browserHistory';
import { routePaths } from 'v2/constants/routes';
import { authEvents, authStores } from 'v2/stores/auth';

const apiUrl = process.env.REACT_APP_YECM_API_URL as string;
const apiVersion = process.env.REACT_APP_YECM_API_VERSION as string;

const yecmAxiosInstance = axios.create();

yecmAxiosInstance.defaults.baseURL = apiUrl;
yecmAxiosInstance.defaults.method = 'POST';

// @ts-ignore
yecmAxiosInstance.defaults['api-version'] = apiVersion;

yecmAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status as number;
        const data = config.response.data;

        if (status === 403) {
            history.push(routePaths.error403);
        } else if (status === 401) {
            authEvents.logout();
        }

        return Promise.reject(data);
    }
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = yecmAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${authStores.$token.getState() || ''}`
              }
            : {}
    });

    return (request as any) as Promise<T>;
};
