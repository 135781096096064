import styled from 'styled-components';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const Wrapper = styled(Row)`
    padding: 2px 8px;
    width: fit-content;
    border-radius: 32px;
    background-color: ${brandColors.grey};
    white-space: nowrap;
`;
