export const countHoverModalPosition = (containerSize: string, modalSize: string) =>
    `${(parseInt(containerSize) - parseInt(modalSize)) / 2}px`;

export const convertDecimalToPercent = (value?: number) => {
    if (!value) {
        return 0;
    }

    return value * 100;
};

export const convertDecimalFromPercent = (value?: number) => {
    if (!value) {
        return 0;
    }

    return value / 100;
};
