import { createEffect, createEvent, createStore } from 'effector';
import { isEmpty } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Events

const setVideo = createEvent<YEAY.AdminGetVideoResponse>();
const setVideoFromPlaylist = createEvent<YEAY.CollaborationPlaylistContent>();
const updateVideo = createEvent<Partial<YEAY.AdminGetVideoResponse>>();
const resetVideo = createEvent();

// Effects

const getVideoByIdFx = createEffect({
    handler: async (videoId: string) => {
        try {
            const data = await API.video.getVideoById({
                id: videoId
            });

            if (data) {
                return data;
            } else {
                throw new Error();
            }
        } catch {
            notify('There was a problem receiving single video, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getVideoSourceFx = createEffect({
    handler: async (videoId: string) => {
        try {
            return await API.video.getVideoSourceFile({ videoId });
        } catch (e) {
            console.log('An error occurred while getting the video source', e);
            return null;
        }
    }
});

const getVideoFromPlaylistFx = createEffect({
    handler: async (videoId: string) => {
        try {
            const { isSuccess, data } = await API.playlist.getPlaylist({ videoId });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data?.items && data?.items.length > 0 ? data.items[0] : null;
        } catch (e) {
            console.log('An error occurred while getting the video from playlist', e);
            return null;
        }
    }
});

// Stores

const $video = createStore<YEAY.AdminGetVideoResponse | null>(null)
    .on(getVideoByIdFx.doneData, (_, state) => state)
    .on(setVideo, (_, newState) => newState)
    .on(updateVideo, (video, newVideoData) => {
        if (!video || isEmpty(newVideoData)) {
            return video;
        }

        return {
            ...video,
            ...newVideoData
        };
    })
    .reset(resetVideo);

const $videoSource = createStore<YEAY.GetVideoPreviewResponse | null>(null)
    .on(getVideoSourceFx.doneData, (_, state) => state)
    .reset(resetVideo);

const $videoFromPlaylist = createStore<YEAY.CollaborationPlaylistContent | null>(null)
    .on(getVideoFromPlaylistFx.doneData, (_, state) => state)
    .on(setVideoFromPlaylist, (_, newState) => newState)
    .reset(resetVideo);

// Exports

export const singleVideoEvents = {
    resetVideo,
    setVideo,
    updateVideo,
    setVideoFromPlaylist
};

export const singleVideoEffects = {
    getVideoByIdFx,
    getVideoSourceFx,
    getVideoFromPlaylistFx
};

export const singleVideoStores = {
    $video,
    $videoSource,
    $videoFromPlaylist
};
