import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { SingleCollaborationCard } from 'v2/components/cards/SingleCollaborationCard';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { DropdownMoreDetails } from 'v2/pages/Collaboration/DropdownMoreDetails';
import { collaborationEffects, collaborationStores } from 'v2/stores/collaboration';
import { Id } from 'v2/types/data';
import { DropdownEngagements } from '../DropdownEngagements';

const { $collaborationInfo } = collaborationStores;
const { getCollaborationFx } = collaborationEffects;

interface Props extends Id {
    hideEngagements?: boolean;
    withAction?: boolean;
}

export const CollaborationInfo: FC<Props> = ({ id, hideEngagements = false, withAction = false }) => {
    const { data } = useStore($collaborationInfo);
    const isLoading = useStore(getCollaborationFx.pending);

    return (
        <Section>
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : data ? (
                <>
                    <Section marginBottom="10px">
                        <SingleCollaborationCard data={data} withAction={withAction} />
                    </Section>
                    <Section marginBottom="10px">
                        <DropdownMoreDetails data={data} />
                    </Section>
                    {!hideEngagements && <DropdownEngagements id={id} />}
                </>
            ) : (
                <Empty title="Collaboration Info not found" />
            )}
        </Section>
    );
};
