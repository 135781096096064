import React, { FC, useEffect, useState } from 'react';
import { SearchInput } from 'v2/components/ui/inputs/SearchInput';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { SearchWrapper } from './styles';

interface SearchItem {
    option: SelectOption;
    onSearch: (value: string) => void;
}

export interface SearchInputProps {
    items: SearchItem[];
    defaultSearchText?: string;
    onSearch?: (searchText: string) => void;
}

export const SearchInputSelect: FC<SearchInputProps> = ({ items, defaultSearchText = '', onSearch }) => {
    const [searchText, setSearchText] = useState(defaultSearchText);
    const [selectorValue, setSelectorValue] = useState<SelectOption>(items[0].option);

    const placeholder = `Search by ${selectorValue.label}`;
    const options = items.map(it => it.option);
    const currentSearchItem = items.find(it => it.option.value === selectorValue.value);

    const onChange = (value: SelectOption) => {
        if (value) {
            setSelectorValue(value);
        }
    };

    const onSearchSubmit = (searchText: string) => {
        currentSearchItem?.onSearch(searchText);
        onSearch?.(searchText);
    };

    useEffect(() => {
        setSearchText(defaultSearchText);
    }, [defaultSearchText]);

    return (
        <SearchWrapper>
            <SearchInput defaultValue={searchText} placeholder={placeholder} onSearch={onSearchSubmit} />
            <Select
                options={options}
                placement="bottom-end"
                title="Search by"
                value={selectorValue.value}
                onChange={onChange}
            />
        </SearchWrapper>
    );
};
