import { SelectOption } from 'v2/components/ui/selects/types';

export enum TargetAudience {
    None,
    Trusted,
    Everyone
}

// CollaborationStatus
export enum CollaborationStatusesUi {
    None = 0,
    Draft = 10,
    Published = 15,
    ActiveOpen = 20,
    ActiveClosed = 24,
    AwaitingSubmissions = 28,
    Ending = 30,
    Deleted = 40,
    Ended = 1000
}

export const collaborationStatusTitles: Record<CollaborationStatusesUi, string> = {
    [CollaborationStatusesUi.None]: 'None',
    [CollaborationStatusesUi.Draft]: 'Draft',
    [CollaborationStatusesUi.Published]: 'Starting soon',
    [CollaborationStatusesUi.ActiveOpen]: 'Live',
    [CollaborationStatusesUi.ActiveClosed]: 'Live',
    [CollaborationStatusesUi.AwaitingSubmissions]: 'Finalizing',
    [CollaborationStatusesUi.Ending]: 'Ending',
    [CollaborationStatusesUi.Deleted]: 'Deleted',
    [CollaborationStatusesUi.Ended]: 'Ended'
};

export enum CollaborationStatusStringKey {
    None = 'none',
    Draft = 'draft',
    Published = 'published',
    ActiveOpen = 'activeOpen',
    ActiveClosed = 'activeClosed',
    AwaitingSubmissions = 'awaitingSubmissions',
    Ending = 'ending',
    Deleted = 'deleted',
    Ended = 'ended'
}

export enum CollaborationStatusFilter {
    All = 0,
    Draft = 1,
    StartingSoon = 2,
    Active = 3,
    Finalizing = 4,
    Ending = 5,
    Ended = 6,
    Deleted = 7
}

export const collaborationsFilterList = [
    { value: CollaborationStatusFilter.All, label: 'All' },
    { value: CollaborationStatusFilter.Draft, label: 'Drafts' },
    {
        value: CollaborationStatusFilter.StartingSoon,
        label: 'Coming Soon'
    },
    {
        value: CollaborationStatusFilter.Active,
        label: 'Live'
    },
    { value: CollaborationStatusFilter.Ended, label: 'Ended' }
];

export const collaborationsStateOptions: SelectOption[] = [
    { value: CollaborationStatusFilter.Draft, label: 'Draft' },
    { value: CollaborationStatusFilter.Active, label: 'Live' },
    { value: CollaborationStatusFilter.StartingSoon, label: 'Starting Soon' },
    { value: CollaborationStatusFilter.Ended, label: 'Ended' },
    { value: CollaborationStatusFilter.Ending, label: 'Ending' },
    { value: CollaborationStatusFilter.Finalizing, label: 'Completed' },
    { value: CollaborationStatusFilter.Deleted, label: 'Deleted' }
];

export const trendingCollaborationsStateOptions: SelectOption[] = [
    { value: CollaborationStatusFilter.Active, label: 'Live' },
    { value: CollaborationStatusFilter.StartingSoon, label: 'Starting Soon' },
    { value: CollaborationStatusFilter.Ended, label: 'Ended' },
    { value: CollaborationStatusFilter.Finalizing, label: 'Completed' }
];

// Requests params
export const defaultCollaborationsQueryParams: Partial<YecmPaths.Collaborations.Get.QueryParameters> = {
    pageIndex: 0,
    limit: 20,
    count: true,
    states: [CollaborationStatusFilter.Active, CollaborationStatusFilter.StartingSoon, CollaborationStatusFilter.Ending]
};

export const defaultTrendingCollaborationsQueryParams = {
    pageIndex: 0,
    limit: 10
};

//Target options
export enum Target {
    All = 'all',
    VerifiedOnly = 'verifiedOnly',
    VerifiedApproval = 'verifiedApproval',
    AllYeayUsers = 'allYeayUsers'
}

export const collaborationsTargetOptions: SelectOption[] = [
    {
        label: 'All',
        value: Target.All
    },
    {
        label: 'Verified Only',
        value: Target.VerifiedOnly
    },
    {
        label: 'Verified w/Approval',
        value: Target.VerifiedApproval
    },
    {
        label: 'All YEAY Users',
        value: Target.AllYeayUsers
    }
];
