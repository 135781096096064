import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { iconWrapperMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    disabled?: boolean;
}

export const StyledLink = styled(CSVLink)<Props>`
    ${iconWrapperMixin};
    color: ${brandColors.black};
    transition: color ${transitionTime} linear;
    ${({ disabled }) => disabled && 'pointer-events: none'};

    &:hover {
        color: ${grey[700]};
    }
`;
