import axios from './axios';

export const getCountries = () =>
    axios<YEAY.AdminAllCountriesResponse>({
        url: '/admin/location/query-countries',
        data: {}
    });

export const getRegionsByCountry = (data: YEAY.AdminGetRegionsByCountryRequest) =>
    axios<YEAY.AdminAllRegionsByCountryResponse>({
        url: '/admin/location/query-regions',
        data
    });
