import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const ModalFooter = styled(Section)`
    padding: 8px 16px;
    position: sticky;
    bottom: 0;
    background-color: ${brandColors.white};
    border-top: 1px solid ${brandColors.grey};
    align-items: center;
    justify-content: space-between;
    z-index: 20;
`;
