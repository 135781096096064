import { useField } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import { Input, InputProps } from 'v2/components/ui/inputs/Input';

interface Props extends Omit<InputProps, 'name'> {
    name: string;
    customHandle?: (value: string) => void;
}

export const InputField: FC<Props> = ({ name, error: errorMessage, customHandle, onChange, ...props }) => {
    const [field, { error, touched }, { setTouched, setValue }] = useField(name);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        !touched && setTouched(true);
        if (customHandle) {
            setValue(customHandle(e.target.value));
        } else {
            field.onChange(e);
        }
        onChange?.(e);
    };

    return <Input {...props} {...field} error={touched && error ? error : errorMessage} onChange={handleChange} />;
};
