import { SortState } from 'v2/components/ui/buttons/SortButton/constants';

export const defaultSelectValues = {
    value: '',
    label: ''
};

export enum SortValues {
    CreationDateAsc = '+utcCreated',
    CreationDateDesc = '-utcCreated'
}

export const sortByDate = {
    [SortState.Asc]: SortValues.CreationDateAsc,
    [SortState.Desc]: SortValues.CreationDateDesc
};

export const sortAssociation = {
    [SortValues.CreationDateAsc]: SortState.Asc,
    [SortValues.CreationDateDesc]: SortState.Desc
};
