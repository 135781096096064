import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Pagination } from 'v2/components/ui/Pagination';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { AbsoluteWrapper } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { UserRole, defaultUsersParams } from 'v2/constants/services/users';
import { agentEffects } from 'v2/stores/agent';
import { addInfluencerToAgentModal } from 'v2/stores/initialize-modals';
import { influencerUsersModalStore } from 'v2/stores/users';
import { getPageIndex } from 'v2/utils/services';
import { Filters } from './Filters';
import { InfluencerCard } from './InfluencerCard';
import {
    BottomWrapper,
    SelectWrapper,
    StyledRelativeWrapper,
    UserCardsGrid,
    UserCardsWrapper,
    Wrapper
} from './styles';

const { effects, events, stores } = influencerUsersModalStore;
const { getUsersFx } = effects;
const { addFilters, resetFilters } = events;

interface Props {
    agentId?: string;
}

const AddInfluencerToAgentModalInner: FC<Props> = ({ agentId }) => {
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

    const isLoading = useStore(getUsersFx.pending);
    const filters = useStore(stores.$usersFilters);
    const usersResponse = useStore(stores.$usersResponse);

    const users = (usersResponse?.items || []).filter(item => !item.roles?.includes(UserRole.Agent) && !item.agentInfo);
    const totalPages = usersResponse?.totalPages || 0;
    const currentPage = (usersResponse?.currentPageIndex || 0) + 1;
    const pageSize = filters.limit || defaultUsersParams.limit;

    const handlePageChange = (page: number) => {
        addFilters({ pageIndex: getPageIndex(page) });
    };

    const handlePageSizeChange = (size: number) => {
        addFilters({ limit: size });
    };

    const onAddClick = () => {
        if (agentId) {
            agentEffects.addMembersToAgentFx({
                data: {
                    userIds: selectedUserIds
                },
                agentId
            });
        }
        addInfluencerToAgentModal.closeModal();
    };

    useEffect(() => {
        getUsersFx();

        return () => {
            resetFilters();
        };
    }, []);

    return (
        <Wrapper>
            <Section marginBottom="26px">
                <Title size="-1">Add Influencers</Title>
            </Section>

            <Filters isLoading={isLoading} />

            <UserCardsWrapper>
                {!isLoading && users.length > 0 && (
                    <>
                        <UserCardsGrid>
                            {users.map(item => {
                                const itemId = item.id;
                                const isSelected = !!selectedUserIds.find(id => id === itemId);

                                const onSelect = () => {
                                    if (itemId) {
                                        if (selectedUserIds.includes(itemId)) {
                                            setSelectedUserIds(prev => prev.filter(id => id !== itemId));
                                        } else {
                                            setSelectedUserIds(prev => [...prev, itemId]);
                                        }
                                    }
                                };

                                return (
                                    <StyledRelativeWrapper key={itemId}>
                                        <AbsoluteWrapper height="100%" left="0" top="0" width="100%" zIndex="10">
                                            <SelectWrapper isSelected={isSelected} onClick={onSelect} />
                                        </AbsoluteWrapper>
                                        <InfluencerCard {...item} />
                                    </StyledRelativeWrapper>
                                );
                            })}
                        </UserCardsGrid>
                    </>
                )}

                {isLoading && (
                    <Section alignCenter justifyCenter height="100%">
                        <Spinner />
                    </Section>
                )}

                {!isLoading && users.length === 0 && <Empty title="No Users Found" yPadding="100px" />}
            </UserCardsWrapper>

            {totalPages > 1 && (
                <BottomWrapper>
                    <Pagination
                        currentPage={currentPage}
                        size={pageSize}
                        totalPages={totalPages}
                        onChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </BottomWrapper>
            )}

            <Section alignCenter justifyEnd marginTop="8px">
                <Row marginRight="12px">
                    <Button buttonType="text-black" onClick={() => addInfluencerToAgentModal.closeModal()}>
                        Cancel
                    </Button>
                </Row>

                <Section maxWidth="110px">
                    <Button wide disabled={selectedUserIds.length === 0} onClick={onAddClick}>
                        Add
                    </Button>
                </Section>
            </Section>
        </Wrapper>
    );
};

export const AddInfluencerToAgentModal: FC<Props> = ({ agentId }) => {
    const { visible } = useStore(addInfluencerToAgentModal.$modal);

    return (
        <ModalWrapper size="large" visible={visible} onClose={() => addInfluencerToAgentModal.closeModal()}>
            <AddInfluencerToAgentModalInner agentId={agentId} />
        </ModalWrapper>
    );
};
