import React, { FC } from 'react';
import { PropertyLayout, PropertyLayoutProps } from 'v2/components/ui/PropertyLayout';
import { StartEllipsisText, Wrapper } from './styles';

// TODO rename id -> text

interface StartEllipsisPropertyProps extends PropertyLayoutProps {
    id?: string;
    isSemibold?: boolean;
}

export const StartEllipsisProperty: FC<StartEllipsisPropertyProps> = ({ title, id, isSemibold }) => (
    <PropertyLayout noWrap direction="row" textCopy={id} title={title}>
        <Wrapper>
            <StartEllipsisText isSemibold={isSemibold}>{id || '-'}</StartEllipsisText>
        </Wrapper>
    </PropertyLayout>
);
