import { useStore } from 'effector-react';
import React from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { appReportsEvents, appReportsStores } from 'v2/stores/reports/app';
import { reportStatusOptions } from '../../constants';

export const ReportStatus = () => {
    const { ReportStatus: status } = useStore(appReportsStores.$appReportsFilters);

    const onChangeStatus = (status: SelectOption) => {
        appReportsEvents.setOverwriteFilters({
            ReportStatus: status.value
        });
    };

    return <Select wide options={reportStatusOptions} title="Report Status" value={status} onChange={onChangeStatus} />;
};
