import { useStore } from 'effector-react';
import React from 'react';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { inactiveUsersEvents, inactiveUsersStores } from 'v2/stores/inactive-users';

export const InactiveUsersSearch = () => {
    const { disabledUserId, mobileNumber, email } = useStore(inactiveUsersStores.$inactiveUsersFilters);

    const searchItems = [
        {
            option: {
                value: 'disabledUserId',
                label: 'User ID'
            },
            onSearch: (disabledUserId: string) =>
                inactiveUsersEvents.setOverwriteFilters({
                    disabledUserId
                })
        },
        {
            option: {
                value: 'mobileNumber',
                label: 'Phone Number'
            },
            onSearch: (mobileNumber: string) =>
                inactiveUsersEvents.setOverwriteFilters({
                    mobileNumber
                })
        },
        {
            option: {
                value: 'email',
                label: 'Email'
            },
            onSearch: (email: string) =>
                inactiveUsersEvents.setOverwriteFilters({
                    email
                })
        }
    ];

    return <SearchInputSelect defaultSearchText={disabledUserId || mobileNumber || email || ''} items={searchItems} />;
};
