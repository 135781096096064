// returns array [0, 1, ..., count]
export const getArrayWithPositions = (count: number) => [...new Array(count)].map((_, position) => position);

/**
 * Returns array of chunks
 * @param array
 * @param chunkSize
 * @returns chunks
 */
export const splitArrayIntoChunks = <T>(array: T[], chunkSize: number): T[][] => {
    let size = chunkSize > 0 ? chunkSize : array.length;
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        result.push(chunk);
    }
    return result;
};
