import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    yPadding?: string;
}

export const EmptyWrapper = styled(Column)<Props>`
    width: 100%;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};
    padding-top: ${({ yPadding }) => yPadding || '150px'};
    padding-bottom: ${({ yPadding }) => yPadding || '150px'};
`;

export const ImageWrapper = styled.div`
    width: 60px;
    height: 60px;
    margin-bottom: 12px;
`;
