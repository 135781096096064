import React from 'react';
import { CopyableText } from 'v2/components/ui/typography/CopyableText';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { transactionLabels } from 'v2/pages/Transactions/constants';
import { getFormattedDate } from 'v2/utils/datetime';
import { formatMoney } from 'v2/utils/formats';

export const getTransactionsTableData = (transactions: YECM.AdminTransactionResponse[] | null | undefined) =>
    transactions?.map(item => {
        const amount = formatMoney({ value: item.amount, signDisplay: 'always' });
        const date = getFormattedDate(item.utcCreated, 'dd-MMM-yyyy');
        const time = getFormattedDate(item.utcCreated, 'hh:mm:ss');

        return {
            key: item.transactionId,
            amount: (
                <Section justifyCenter>
                    <Text size="-1">{amount}</Text>
                </Section>
            ),
            date: (
                <Section justifyCenter>
                    <Text isUppercase size="-1">
                        {date}
                    </Text>
                </Section>
            ),
            time: (
                <Section justifyCenter>
                    <Text size="-1">{time}</Text>
                </Section>
            ),
            id: (
                <Section>
                    <CopyableText isSemibold size="-2" subject={item.transactionId} text={item.transactionId} />
                </Section>
            ),
            type: (
                <Section justifyCenter>
                    <Text size="-1">{item?.transactionType ? transactionLabels[item.transactionType] : '-'}</Text>
                </Section>
            )
        };
    });
