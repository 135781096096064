import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors } from 'v2/constants/styles/colors';
import { GlobalStyle } from './styles';

interface Props extends TooltipProps {
    maxWidth?: string;
}

export const Tooltip: FC<Props> = ({ children, overlay, maxWidth, ...tooltipProps }) => (
    <>
        <RcTooltip destroyTooltipOnHide overlay={overlay} overlayClassName="custom-tooltip" {...tooltipProps}>
            {children}
        </RcTooltip>
        <GlobalStyle maxWidth={maxWidth} />
    </>
);

interface TooltipTextProps {
    text: string;
}

export const TooltipText: FC<TooltipTextProps> = ({ text }) => (
    <Text color={brandColors.white} size="-2">
        {text}
    </Text>
);
