import { sample } from 'effector';
import { collaborationStores } from 'v2/stores/collaboration';
import { submissionsEffects, submissionsStores } from 'v2/stores/collaboration/details/submissions';

const { $submissionsFilters } = submissionsStores;
const { getSubmissionsFx } = submissionsEffects;

sample({
    clock: $submissionsFilters,
    source: collaborationStores.$collaborationId,
    fn: (collaborationId, params) => ({
        collaborationId,
        params
    }),
    target: getSubmissionsFx
});
