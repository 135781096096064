import React, { FC } from 'react';
import { CollaborationState } from 'v2/components/filter/collaboration/CollaborationState';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Title } from 'v2/components/ui/typography/Title';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { collaborationsStateOptions } from 'v2/constants/services/collaborations';
import { GenericCollaborationsType } from 'v2/stores/collaborations';
import { FiltersWrapper } from './styles';

interface Props {
    store: GenericCollaborationsType;
    isLoading?: boolean;
}

export const BrandCollaborationsFilterLayout: FC<Props> = ({ store, isLoading, children }) => (
    <>
        <FiltersWrapper>
            <SectionBorder borderBottom padding="14px 16px">
                <Title size="-2">Collaborations</Title>
            </SectionBorder>

            <Section alignCenter justifyBetween noWrap padding="14px 16px">
                <CollaborationState options={collaborationsStateOptions} store={store} />
                {isLoading && <Spinner />}
            </Section>
        </FiltersWrapper>

        {children}
    </>
);
