import React from 'react';
import { CopyableText } from 'v2/components/ui/typography/CopyableText';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { userTransactionTypeText } from 'v2/pages/User/UserInfo/components/UserTransactions/constants';
import { getFormattedDate } from 'v2/utils/datetime';
import { formatMoney } from 'v2/utils/formats';

export const prepareUserTransactions = (items: YECM.TransactionDto[]) =>
    items.map(item => {
        const type = item.kind ? userTransactionTypeText[item.kind] : '-';
        const amount = formatMoney({ value: item.amount, signDisplay: 'always' });
        const date = getFormattedDate(item.utcCreated, 'dd-MMM-yyyy');
        const time = getFormattedDate(item.utcCreated, 'hh:mm:ss');

        return {
            key: item.transactionId,
            type: (
                <Section>
                    <Text size="-1">{type}</Text>
                </Section>
            ),
            amount: (
                <Section justifyCenter>
                    <Text isUppercase size="-1">
                        {amount}
                    </Text>
                </Section>
            ),
            date: (
                <Section justifyCenter>
                    <Text isUppercase size="-1">
                        {date}
                    </Text>
                </Section>
            ),
            time: (
                <Section justifyCenter>
                    <Text size="-1">{time}</Text>
                </Section>
            ),
            collaborationName: (
                <Section justifyCenter>
                    <Text size="-1">{item.collaborationName || '-'}</Text>
                </Section>
            ),
            transactionId: (
                <Section justifyCenter>
                    <CopyableText isSemibold size="-2" subject={item.transactionId} text={item.transactionId} />
                </Section>
            )
        };
    });
