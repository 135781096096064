import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';

export const TrustedFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { isTrusted } = useStore($usersFilters);

    const handleChange = (value: boolean) => {
        addFilters({ isTrusted: value || undefined });
    };

    return (
        <Checkbox checked={!!isTrusted} textSize="-2" onChange={handleChange}>
            is Trusted
        </Checkbox>
    );
};
