import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 250px auto;
    border-radius: 4px;
    background-color: ${brandColors.white};
    overflow: hidden;
`;
