import React, { FC } from 'react';
import { AccountIcon, CollaborationsIcon, VideosIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { AppReportTypeState, reportType } from 'v2/constants/services/reports';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    type: AppReportTypeState;
}

const Icon = ({ type }: { type: AppReportTypeState }) => {
    switch (type) {
        case AppReportTypeState.Video:
            return <VideosIcon color={brandColors.black} height="14px" width="12px" />;
        case AppReportTypeState.Collab:
            return <CollaborationsIcon color={brandColors.black} height="14px" width="16px" />;
        case AppReportTypeState.Account:
            return <AccountIcon color={brandColors.black} height="10px" width="10px" />;
        case AppReportTypeState.None:
        default:
            return null;
    }
};

export const ReportType: FC<Props> = ({ type }) => (
    <Row alignCenter noWrap>
        {type && <Icon type={type || 0} />}
        <Row marginLeft="10px">
            <Text size="-2">{reportType[type]}</Text>
        </Row>
    </Row>
);
