import styled, { css } from 'styled-components';
import { textColors } from 'v2/constants/styles/colors';
import { PoppinsFontFamily } from 'v2/constants/styles/fonts';

type Size = '-2' | '-1' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';

interface TitleProps {
    color?: string;
    size?: Size;
    isUppercase?: boolean;
    isUnderline?: boolean;
    noTextWrap?: boolean;
    alignTextCenter?: boolean;
}

const getTitleStyles = (size: Size) => {
    switch (size) {
        case '-2':
            return css`
                font-size: 12px;
                line-height: 18px;
            `;
        case '-1':
            return css`
                font-size: 14px;
                line-height: 20px;
            `;
        case '0':
        default:
            return css`
                font-size: 16px;
                line-height: 24px;
            `;
        case '1':
            return css`
                font-size: 18px;
                line-height: 26px;
            `;
        case '2':
            return css`
                font-size: 20px;
                line-height: 24px;
            `;
        case '3':
            return css`
                font-size: 22px;
                line-height: 26px;
            `;
        case '4':
            return css`
                font-size: 24px;
                line-height: 28px;
            `;
        case '5':
            return css`
                font-size: 32px;
                line-height: 36px;
            `;
        case '6':
            return css`
                font-size: 48px;
                line-height: 56px;
            `;
        case '7':
            return css`
                font-size: 60px;
                line-height: 70px;
            `;
        case '8':
            return css`
                font-size: 82px;
                line-height: 94px;
            `;
    }
};

export const Title = styled.span<TitleProps>`
    font-family: ${PoppinsFontFamily};
    font-weight: 700;
    color: ${({ color }) => color || textColors.main};
    ${({ size = '0' }) => getTitleStyles(size)};
    ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
    ${({ alignTextCenter }) => alignTextCenter && 'text-align: center'};
    ${({ isUnderline }) => isUnderline && 'text-decoration: underline'};
    ${({ noTextWrap }) => noTextWrap && 'white-space: nowrap'};
`;
