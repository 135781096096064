import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { yecmAPI } from 'v2/services/yecm';

// Types

type UserTransactionsParams = Partial<YecmPaths.Users$RemoteUserIdTransactions.Get.QueryParameters>;

interface GetUserTransactionsParams {
    remoteUserId: string;
    params?: UserTransactionsParams;
}

// Constants

export const defaultUserTransactionsParams: UserTransactionsParams = {
    pageIndex: 0,
    limit: 5,
    count: true
};

// Events

const updateFilters = createEvent<UserTransactionsParams>();
const resetStores = createEvent();

// Effects

const getUserTransactionsFx = createEffect({
    handler: async ({ remoteUserId, params }: GetUserTransactionsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.transactions.getUserTransactions(remoteUserId, {
                ...defaultUserTransactionsParams,
                ...params
            });

            if (isSuccess && data) {
                return data;
            } else {
                throw new Error();
            }
        } catch (error) {
            notify('There was a problem receiving user transactions, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $userTransactions = createStore<YECM.TransactionsDto | null>(null)
    .on(getUserTransactionsFx.doneData, (state, response) => {
        if (!response) return;

        return {
            ...(state || {}),
            ...response,
            items: response.pageIndex === 0 ? response.items : [...(state?.items || []), ...(response.items || [])]
        };
    })
    .reset(resetStores);

const $userTransactionsFilters = createStore<UserTransactionsParams>(defaultUserTransactionsParams)
    .on(updateFilters, (state, params) => ({
        ...defaultUserTransactionsParams,
        ...state,
        ...params
    }))
    .reset(resetStores);

// Exports

export const userTransactionsEvents = {
    resetStores,
    updateFilters
};

export const userTransactionsEffects = {
    getUserTransactionsFx
};

export const userTransactionsStores = {
    $userTransactions,
    $userTransactionsFilters
};
