import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { CollaborationInfo } from 'v2/pages/Collaboration/CollaborationInfo';
import { ContentWrapper } from 'v2/pages/Report/common/styles';
import { collaborationEffects } from 'v2/stores/collaboration';
import { Id } from 'v2/types/data';

const { getCollaborationFx } = collaborationEffects;

enum ReportCollaborationPageTab {
    ReportInfo
}

export const ReportSingleCollaboration = () => {
    const { id } = useParams<Partial<Id>>();

    useEffect(() => {
        if (id) {
            getCollaborationFx(id);
        }
    }, [id]);

    return (
        <MainLayout isHideHeader>
            <DetailsPageLayout>
                <Tabs
                    activeTab={ReportCollaborationPageTab.ReportInfo}
                    tabs={[
                        {
                            id: ReportCollaborationPageTab.ReportInfo,
                            title: 'REPORT INFO',
                            content: (
                                <ContentWrapper>
                                    <CollaborationInfo hideEngagements withAction id={id || ''} />
                                </ContentWrapper>
                            )
                        }
                    ]}
                />
            </DetailsPageLayout>
        </MainLayout>
    );
};
