import styled, { css } from 'styled-components';
import { Span } from 'v1/components/common/typography/Span/styles';
import { AbsoluteWrapper } from 'v1/components/wrappers/grid/AbsoluteWrapper/styles';
import { black, grey3, semitransparentBlack, white } from 'v1/constants/styles/colors';
import { defaultTextColor } from 'v1/constants/styles/default';
import {
    disableDefaultButtonStyleMixin,
    disableDefaultInputStyleMixin,
    flexCenter,
    flexStart
} from 'v1/constants/styles/mixins';
import { transitionTime } from 'v1/constants/styles/others';
import { md, primaryBorderRadius, xs } from 'v1/constants/styles/sizes';
import { Active } from 'v1/types/global';
import { PaddingLeft } from 'v1/types/styles';
import {
    itemHeight,
    paginationCellMarginRight,
    paginationInputHeight,
    paginationInputPadding,
    paginationInputWidth,
    paginationWrapperMarginRight
} from './constants';
import { ArrowProps, PaginationCellProps } from './types';

export const PaginationCell = styled.button<PaginationCellProps>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    border-radius: ${primaryBorderRadius};
    min-width: 27px;
    padding: 8px 8px;
    background-color: ${({ active }) => (active ? black : grey3)};
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: ${({ active }) => (active ? white : black)};
    margin-right: ${paginationCellMarginRight};
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `};
`;

export const Arrow = styled(PaginationCell)<ArrowProps>`
    visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

export const PaginationWrapper = styled.div`
    ${flexStart};
    margin-right: ${paginationWrapperMarginRight};
    border-radius: ${primaryBorderRadius};

    @media (max-width: ${md}) {
        margin-right: 13px;
    }
    @media (max-width: ${xs}) {
        margin-right: 0;
    }
`;

export const PaginationInput = styled.input`
    ${disableDefaultInputStyleMixin};
    height: ${paginationInputHeight};
    width: ${paginationInputWidth};
    padding: 2px ${paginationInputPadding};
    box-sizing: border-box;
    border-radius: ${primaryBorderRadius};
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    background-color: ${grey3};
    border: 1px solid rgba(0, 0, 0, 0);

    :focus-within {
        border: 1px solid #000;
    }

    @media screen and (max-width: ${md}) {
        width: 30px;
        height: 29px;
    }
`;

export const ItemsAbsoluteWrapper = styled(AbsoluteWrapper)`
    background-color: ${white};
    border-radius: ${primaryBorderRadius} ${primaryBorderRadius} 0 0;
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.24);
`;

interface ItemWrapperProps extends PaddingLeft, Active {}

export const ItemSpan = styled(Span)`
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${itemHeight};
    width: 100%;
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
    background-color: ${({ active }) => (active ? black : 'transparent')};
    border-bottom: 1px solid ${semitransparentBlack};
    transition: background-color ${transitionTime}, color ${transitionTime};

    ${ItemSpan} {
        color: ${({ active }) => (active ? white : defaultTextColor)};
    }

    :hover {
        background-color: ${black};
        cursor: pointer;

        ${ItemSpan} {
            color: ${white};
        }
    }

    :first-child {
        border-bottom: 0;
        border-radius: ${primaryBorderRadius} ${primaryBorderRadius} 0 0;
    }
`;
