import { useStore } from 'effector-react';
import { isUndefined } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'v2/components/loaders/Spinner';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { noop } from 'v2/constants/functions';
import { routePaths } from 'v2/constants/routes';
import { maximumCreatorsCount } from 'v2/constants/services/trendings';
import { useDragItems } from 'v2/hooks/use-drag-items';
import { addTrendingCreatorsModal } from 'v2/stores/initialize-modals';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { trendingCreatorsEffects, trendingCreatorsStores } from 'v2/stores/trendings/creators';
import { getArrayWithPositions } from 'v2/utils/array';
import { BlockWrapper, Header } from '../styles';
import { AddCreatorsModal } from './AddCreatorsModal';
import { CreatorDragSpot } from './CreatorDragSpot';
import { TrendingCreatorCard } from './TrendingCreatorCard';
import { PositionWrapper, UsersGrid } from './styles';

const { getTrendingCreatorsFx, deleteCreatorFromTrendingFx, swapCreatorsFx } = trendingCreatorsEffects;
const { $creators } = trendingCreatorsStores;

interface PositionProps {
    index?: number;
}

export const Position: FC<PositionProps> = ({ index = 0 }) => (
    <PositionWrapper>
        <Text size="-2">{`#${index + 1}`}</Text>
    </PositionWrapper>
);

export const TrendingCreators = () => {
    const history = useHistory();

    const creators = useStore($creators);
    const creatorsIsLoading = useStore(getTrendingCreatorsFx.pending);

    const onDragEnded = (fromIndex: number, toIndex: number) => {
        const fromItem = creators.find(creator => creator.position === fromIndex);
        const toItem = creators.find(creator => creator.position === toIndex);

        if (fromItem) {
            swapCreatorsFx({
                fromItem: { id: fromItem.id, position: toIndex },
                toItem: toItem ? { id: toItem.id, position: fromIndex } : null
            });
        }
    };

    const { onDragEnd, onDragOver, onDragStart, firstPosition, secondPosition } = useDragItems(onDragEnded);

    const handleInfoClick = (userId?: string) => () => {
        if (userId) {
            history.push(`${routePaths.users}/${userId}`);
        }
    };

    const handleDeleteClick = (id?: string) => () => {
        if (id) {
            confirmEvents.openConfirm({
                title: 'Delete creator',
                description: 'Are you sure you want to remove the user from trending?',
                cancelButtonText: 'Yes',
                okButtonText: 'Cancel',
                cancelCallback: () => {
                    deleteCreatorFromTrendingFx(id);
                },
                okCallback: noop
            });
        }
    };

    const openAddModal = () => {
        addTrendingCreatorsModal.openModal();
    };

    const renderCard = (item: YEAY.GetTrendingOverridesResponse, position: number) => {
        if (!isUndefined(firstPosition) && firstPosition !== position) {
            return (
                <CreatorDragSpot
                    isHovered={position === secondPosition}
                    positionIndex={position}
                    onDragEnd={onDragEnd}
                    onDragOver={() => onDragOver(position)}
                />
            );
        }

        return (
            <TrendingCreatorCard
                draggable
                positionComponent={<Position index={position} />}
                user={item.user}
                onDeleteClick={handleDeleteClick(item.id)}
                onDragEnd={onDragEnd}
                onDragOver={() => onDragOver(position)}
                onDragStart={() => onDragStart(position)}
                onInfoClick={handleInfoClick(item.user?.userId)}
            />
        );
    };

    useEffect(() => {
        getTrendingCreatorsFx();
    }, []);

    return (
        <>
            <BlockWrapper>
                <Header>
                    <Row alignCenter>
                        <Title size="-2">Creators</Title>
                        <Column marginLeft="12px">
                            <TotalBadge text={`${creators.length}/${maximumCreatorsCount}`} />
                        </Column>
                    </Row>

                    <Button buttonType="text-black" textSize="small" onClick={openAddModal}>
                        Add Creators
                    </Button>
                </Header>

                {creatorsIsLoading ? (
                    <Section justifyCenter padding="8px">
                        <Spinner />
                    </Section>
                ) : (
                    <UsersGrid>
                        {getArrayWithPositions(maximumCreatorsCount).map(position => {
                            const item = creators.find(item => item.position === position);

                            return (
                                <Section key={position}>
                                    {item ? (
                                        renderCard(item, position)
                                    ) : (
                                        <CreatorDragSpot
                                            isHovered={position === secondPosition}
                                            positionIndex={position}
                                            textIsVisible={!isUndefined(firstPosition)}
                                            onClick={openAddModal}
                                            onDragEnd={onDragEnd}
                                            onDragOver={() => onDragOver(position)}
                                        />
                                    )}
                                </Section>
                            );
                        })}
                    </UsersGrid>
                )}
            </BlockWrapper>

            <AddCreatorsModal trendingCreators={creators} />
        </>
    );
};
