import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { CollaborationCard } from 'v2/components/cards/CollaborationCard';
import { Empty } from 'v2/components/common/Empty';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { GenericCollaborationsType } from 'v2/stores/collaborations';
import { BrandCollaborationsFilterLayout } from './BrandCollaborationsFilterLayout';

interface Props {
    store: GenericCollaborationsType;
}

export const BrandCollaborationsTab: FC<Props> = ({ store }) => {
    const { effects, events, stores } = store;

    const data = useStore(stores.$collaborations);
    const isLoading = useStore(effects.getCollaborationsFx.pending);

    const collaborations = data?.items;
    const hasNext = data?.hasNext;

    const loadMoreCollaborations = () => {
        if (!hasNext) return;

        const newPageIndex = (data?.pageIndex || 0) + 1;
        events.addFilters({ pageIndex: newPageIndex });
    };

    useEffect(() => {
        effects.getCollaborationsFx();

        return () => {
            events.resetCollaborations();
            events.resetFilters();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BrandCollaborationsFilterLayout isLoading={isLoading} store={store}>
            {collaborations && collaborations.length > 0 && (
                <CardsGridLayout>
                    {collaborations.map(item => (
                        <CollaborationCard key={item.id} data={item} />
                    ))}
                </CardsGridLayout>
            )}

            {isLoading && (
                <Section justifyCenter marginTop="16px">
                    <Spinner />
                </Section>
            )}

            {!isLoading && collaborations && collaborations.length === 0 && <Empty title="No Collaborations Found" />}

            {!isLoading && hasNext && (
                <Section marginTop="16px">
                    <LoadMoreSection onClick={loadMoreCollaborations} />
                </Section>
            )}
        </BrandCollaborationsFilterLayout>
    );
};
