import React, { ChangeEvent, FC } from 'react';
import { CheckMarkIcon } from 'v2/assets/icons';
import { Size, Text } from 'v2/components/ui/typography/Text';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { Disabled } from 'v2/types/form';
import { HiddenCheckbox, Label, StyledMark } from './styles';

export interface CheckboxProps extends Disabled {
    name?: string;
    checked?: boolean;
    error?: string;
    isRadio?: boolean;
    isLarge?: boolean;
    isRounded?: boolean;
    textSize?: Size;
    onChange?: (checked: boolean) => void;
}

export interface CheckMarkProps
    extends Pick<CheckboxProps, 'checked' | 'disabled' | 'isLarge' | 'isRounded' | 'textSize'> {
    hasError?: boolean;
}

export const CheckMark: FC<CheckMarkProps> = ({ checked, ...props }) => (
    <StyledMark checked={checked} {...props}>
        {checked && <CheckMarkIcon />}
    </StyledMark>
);

export const Checkbox: FC<CheckboxProps> = ({
    checked,
    isRadio,
    name,
    disabled,
    isLarge,
    error,
    isRounded,
    children,
    onChange,
    textSize = '-1'
}) => {
    const styledProps: CheckMarkProps = {
        checked,
        disabled,
        isLarge,
        isRounded,
        hasError: !!error
    };

    const inputType = isRadio ? 'radio' : 'checkbox';

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked);
    };

    return (
        <Label {...styledProps}>
            <CheckMark {...styledProps} />
            <HiddenCheckbox
                checked={checked}
                disabled={disabled}
                name={name}
                type={inputType}
                onChange={handleChange}
            />

            {children && (
                <Column marginLeft="8px">
                    <Text color="inherit" size={textSize}>
                        {children}
                    </Text>
                </Column>
            )}
        </Label>
    );
};
