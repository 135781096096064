import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Avatar } from 'v2/components/ui/Avatar';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { CollaborationStatusStringKey } from 'v2/constants/services/collaborations';
import { brandDetailsEffects, brandDetailsStores } from 'v2/stores/brands/details';
import { formatMoney } from 'v2/utils/formats';
import { CommonWrapper, PropertiesWrapper, PropertyWrapper, Wrapper } from './styles';

const { getBrandFx } = brandDetailsEffects;
const { $brandInfo } = brandDetailsStores;

interface Props {
    brandId: string;
}

// TODO Block organization flow

export const BrandInfoTab: FC<Props> = ({ brandId }) => {
    const isLoading = useStore(getBrandFx.pending);
    const brandInfo = useStore($brandInfo);

    useEffect(() => {
        if (!brandInfo || brandInfo.organizationId !== brandId) {
            getBrandFx(brandId);
        }
    }, [brandId, brandInfo]);

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    if (!brandInfo) {
        return <Empty title="Brand not found" />;
    }

    return (
        <Wrapper>
            <CommonWrapper>
                <Column alignCenter marginBottom="16px">
                    <Avatar src={brandInfo.profileImageUrl || ''} />
                    <Row justifyCenter marginTop="8px">
                        <Title alignTextCenter size="-1">
                            {brandInfo.title || '-'}
                        </Title>
                    </Row>
                </Column>

                {/* <Button buttonType="text-black" textSize="small">
                    Block
                </Button> */}
            </CommonWrapper>

            <PropertiesWrapper>
                <PropertyWrapper>
                    <PropertyLayout textCopy={brandInfo.organizationId || ''} title="Brand ID">
                        <Text isSemibold size="-2">
                            {brandInfo.organizationId || '-'}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout textCopy={brandInfo.websiteLink || ''} title="Website">
                        <Text isSemibold size="-2">
                            {brandInfo.websiteLink || '-'}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <DateProperty date={brandInfo.utcCreated} title="Created" />
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Current Balance">
                        <Text isSemibold size="-2">
                            {formatMoney({ value: brandInfo.balance })}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="In Escrow">
                        <Text isSemibold size="-2">
                            {formatMoney({ value: brandInfo.valueInEscrow })}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Total Spent">
                        <Text isSemibold size="-2">
                            {formatMoney({ value: brandInfo.totalSpent })}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Business Name">
                        <Text isSemibold size="-2">
                            {brandInfo.companyInfo?.businessName || '-'}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Business Country">
                        <Text isSemibold size="-2">
                            {brandInfo.companyInfo?.country || '-'}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Tax ID">
                        <Text isSemibold size="-2">
                            {brandInfo.companyInfo?.vatNumber || '-'}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Collaborations Draft">
                        <Text isSemibold size="-2">
                            {brandInfo.collaborationStates?.[CollaborationStatusStringKey.Draft] || 0}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Collaborations Live">
                        <Text isSemibold size="-2">
                            {brandInfo.collaborationStates?.[CollaborationStatusStringKey.ActiveOpen] || 0}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>

                <PropertyWrapper>
                    <PropertyLayout title="Collaborations Ended">
                        <Text isSemibold size="-2">
                            {brandInfo.collaborationStates?.[CollaborationStatusStringKey.Ended] || 0}
                        </Text>
                    </PropertyLayout>
                </PropertyWrapper>
            </PropertiesWrapper>
        </Wrapper>
    );
};
