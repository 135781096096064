export const inactiveUsersTableColumns = [
    {
        title: 'USER',
        dataIndex: 'username',
        key: 'username',
        width: '20%'
    },
    {
        title: 'INACTIVE TYPE',
        dataIndex: 'type',
        key: 'type',
        width: '20%'
    },
    {
        title: 'USER ID',
        dataIndex: 'userId',
        key: 'userId',
        width: '20%'
    },
    {
        title: 'ACTION BY',
        dataIndex: 'actionBy',
        key: 'actionBy',
        width: '20%'
    },
    {
        title: 'REASON',
        dataIndex: 'reason',
        key: 'reason',
        width: '20%'
    }
];
