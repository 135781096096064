import { useStore } from 'effector-react';
import { isUndefined } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { YeayIcon } from 'v2/assets/icons';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { maximumCollaborationsCount } from 'v2/constants/services/trendings';
import { useDragItems } from 'v2/hooks/use-drag-items';
import { AddCollaborationModal } from 'v2/pages/Trendings/TrendingCollaborations/AddCollaborationModal';
import { CardHoverWrapper } from 'v2/pages/Trendings/TrendingCollaborations/CardHoverWrapper';
import { addCollaborationModal } from 'v2/stores/initialize-modals';
import { trendingCollaborationEffects, trendingCollaborationStores } from 'v2/stores/trendings/collaborations';
import { getArrayWithPositions } from 'v2/utils/array';
import { BlockWrapper, Header } from '../styles';
import { TrendingCollaborationCard } from './TrendingCollaborationCard';
import { CardRow, CardWrapper, CountFlag, DragSpot, StyledColumn } from './styles';

export const TrendingCollaborations = () => {
    const history = useHistory();
    const [{ items, totalRecords }] = useStore(trendingCollaborationStores.$trendingCollaborations);

    useEffect(() => {
        trendingCollaborationEffects.getTrendingCollaborationsFx();
    }, []);

    const onDragEnded = (fromIndex: number, toIndex: number) => {
        const firstElement = items?.find(item => item.position === fromIndex);
        const secondElement = items?.find(item => item.position === toIndex);
        if (firstElement) {
            trendingCollaborationEffects
                .swapCollaborationsFx({
                    firstElement: { id: firstElement.id, position: toIndex },
                    secondElement: { id: secondElement?.id || '', position: fromIndex }
                })
                .then(() => {
                    trendingCollaborationEffects.getTrendingCollaborationsFx();
                });
        }
    };

    const { onDragEnd, onDragOver, onDragStart, secondPosition, firstPosition } = useDragItems(onDragEnded);

    const onRemoveFromTrending = (id?: string | null) => () => {
        if (id) {
            trendingCollaborationEffects.deleteCollaborationFromTrendingFx(id);
        }
    };

    return (
        <BlockWrapper>
            <Header>
                <Row alignCenter>
                    <Title size="-2">Collaborations</Title>
                    <MarginWrapper marginLeft="12px">
                        <TotalBadge count={totalRecords} />
                    </MarginWrapper>
                </Row>
                <Button
                    buttonType="text-black"
                    disabled={items?.length === maximumCollaborationsCount}
                    textSize="small"
                    onClick={() => addCollaborationModal.openModal()}
                >
                    Add Collaboration
                </Button>
            </Header>
            <CardRow>
                {getArrayWithPositions(maximumCollaborationsCount).map(position => {
                    const currentItem = items?.find(item => item.position === position);
                    const dragPropsForSecondPosition = {
                        draggable: false,
                        isHovered: position === secondPosition,
                        onDragEnd: onDragEnd,
                        onDragOver: () => onDragOver(position)
                    };
                    return (
                        <StyledColumn key={position}>
                            <CountFlag>
                                <Text size="-2">{`#${position + 1}`}</Text>
                            </CountFlag>
                            <CardWrapper>
                                {currentItem ? (
                                    !isUndefined(firstPosition) && firstPosition !== position ? (
                                        <DragSpot {...dragPropsForSecondPosition}>
                                            <Text isSemibold size="-2">
                                                Drag Here
                                            </Text>
                                        </DragSpot>
                                    ) : (
                                        <CardHoverWrapper
                                            onClick={() =>
                                                history.push(
                                                    `${routePaths.collaborations}/${currentItem.collaboration?.id}`
                                                )
                                            }
                                            onRemove={onRemoveFromTrending(currentItem.id)}
                                        >
                                            <TrendingCollaborationCard
                                                {...currentItem.collaboration}
                                                draggable
                                                budget={currentItem.collaboration?.budgetAccepted}
                                                participantsEnrolledLimit={
                                                    currentItem.collaboration?.participationLimits?.enrolled
                                                }
                                                state={currentItem.collaboration?.collaborationState}
                                                onDragEnd={onDragEnd}
                                                onDragOver={() => onDragOver(position)}
                                                onDragStart={() => onDragStart(position)}
                                            />
                                        </CardHoverWrapper>
                                    )
                                ) : (
                                    <DragSpot {...dragPropsForSecondPosition}>
                                        {!isUndefined(firstPosition) ? (
                                            <Text isSemibold size="-2">
                                                Drag Here
                                            </Text>
                                        ) : (
                                            <YeayIcon height={31} width={20} />
                                        )}
                                    </DragSpot>
                                )}
                            </CardWrapper>
                        </StyledColumn>
                    );
                })}
            </CardRow>
            <AddCollaborationModal trendingCollaborations={items} />
        </BlockWrapper>
    );
};
