import styled, { css } from 'styled-components';
import { Button } from 'v2/components/ui/buttons/Button';
import { RadioButtonProps } from 'v2/components/ui/buttons/RadioButton';
import { brandColors, grey, red, textColors } from 'v2/constants/styles/colors';

const baseStyles = css`
    color: ${textColors.main};
    background-color: ${grey[200]};
`;

const greenStyles = css`
    &:hover:not(:disabled) {
        background-color: ${grey[100]};
    }
`;

const greenCheckedStyles = css`
    color: ${textColors.main};
    background-color: ${brandColors.green};
`;

const redStyles = css`
    &:hover:not(:disabled) {
        color: ${brandColors.red};
        background-color: ${red[50]};
    }
`;

const redCheckedStyles = css`
    color: ${red[800]};
    background-color: ${red[100]};
`;

const getTypeStyles = (props: RadioButtonProps) => {
    switch (props.colorType) {
        case 'green':
        default:
            return props.checked ? greenCheckedStyles : greenStyles;
        case 'red':
            return props.checked ? redCheckedStyles : redStyles;
    }
};

export const StyledButton = styled(Button)<RadioButtonProps>`
    ${baseStyles};
    ${getTypeStyles};
`;
