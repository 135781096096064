import { createEffect } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { noop } from 'v2/constants/functions';
import { API } from 'v2/services/yasy';
import { confirmEvents } from 'v2/stores/modals/confirm';

// Effects

const curateReportFx = createEffect({
    handler: async ({ ...values }: YEAY.UpdateReportStatusRequest) => {
        try {
            const response = await API.report.curateReport({
                ...values
            });

            if (!response) {
                throw new Error();
            }

            notify('Report successfully curated!');
            return response;
        } catch (error) {
            console.log('There was a problem reviewing the report', error);
            confirmEvents.openConfirm({
                title: 'Something Went Wrong',
                description: 'There was a problem reviewing the report. Please try again.',
                cancelButtonText: 'Try again',
                cancelCallback: noop,
                okButtonText: 'Cancel',
                okCallback: noop
            });
            return null;
        }
    }
});

// Exports

export const actionsReportEffects = {
    curateReportFx
};
