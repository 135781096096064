import { useStore } from 'effector-react';
import React from 'react';
import { UserCard } from 'v2/components/cards/UserCard';
import { Empty } from 'v2/components/common/Empty';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { UsersFilterLayout } from 'v2/pages/Users/UsersFilterLayout';
import { userPageStore } from 'v2/stores/users';

const { effects, stores } = userPageStore;

const { $usersResponse } = stores;
const { getUsersFx } = effects;

export const Users = () => {
    const isLoading = useStore(getUsersFx.pending);
    const usersResponse = useStore($usersResponse);
    const users = usersResponse?.items || [];

    return (
        <MainLayout>
            <UsersFilterLayout isLoading={isLoading}>
                {isLoading ? (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                ) : (
                    <>
                        {users.length > 0 ? (
                            <CardsGridLayout>
                                {users.map(user => (
                                    <UserCard key={user.id} {...user} />
                                ))}
                            </CardsGridLayout>
                        ) : (
                            <Empty title="No Users Found" />
                        )}
                    </>
                )}
            </UsersFilterLayout>
        </MainLayout>
    );
};
