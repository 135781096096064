import React from 'react';
import { ReactComponent as LinkIcon } from 'v1/assets/link_icon.svg';
import { goToLinkedPage } from 'v1/components/common/buttons/LinkButton/constants';
import { defaultMongoDBId } from 'v1/constants/defaults/formats';
import { CopyButtonWrapper } from './styles';

export interface LinkButtonProps {
    id?: string;
    linkRoute: string;
}

export const LinkButton = ({ id, linkRoute }: LinkButtonProps) => {
    const disabled = id === defaultMongoDBId || !id;

    const openLinkInANewTab = () => {
        if (!disabled) {
            goToLinkedPage(linkRoute, id);
        }
    };

    return (
        <CopyButtonWrapper pointer disabled={disabled} onClick={openLinkInANewTab}>
            <LinkIcon />
        </CopyButtonWrapper>
    );
};
