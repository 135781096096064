import React, { FC } from 'react';
import { InfoIcon, TrashIcon } from 'v2/assets/icons';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { AbsoluteCenterAlignment } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { ClickableWrapper } from 'v2/components/wrappers/ClickableWrapper/styles';
import {
    ActionBlockWrapper,
    Divider,
    StyledRelativeWrapper
} from 'v2/pages/Trendings/TrendingCollaborations/CardHoverWrapper/styles';
import { ReactClick } from 'v2/types/react';
import { Noop } from 'v2/types/types';

interface Props extends ReactClick<HTMLButtonElement> {
    onRemove: Noop;
}

export const CardHoverWrapper: FC<Props> = ({ onRemove, onClick, children }) => (
    <StyledRelativeWrapper>
        <AbsoluteCenterAlignment zIndex="5">
            <CardHoverOpacityEffect>
                <ActionBlockWrapper>
                    <ClickableWrapper height="16px" width="16px" onClick={onClick}>
                        <InfoIcon />
                    </ClickableWrapper>

                    <Divider />

                    <ClickableWrapper height="16px" width="16px" onClick={onRemove}>
                        <TrashIcon />
                    </ClickableWrapper>
                </ActionBlockWrapper>
            </CardHoverOpacityEffect>
        </AbsoluteCenterAlignment>
        {children}
    </StyledRelativeWrapper>
);
