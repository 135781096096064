interface BlockReason {
    value: YEAY.UserDisablingReason;
    text: string;
}

export enum BlockReasons {
    Spamming,
    Copyright,
    Inappropriate,
    Collusion,
    FakeAccount,
    DeleteRequest
}

export const blockReasons: BlockReason[] = [
    {
        value: BlockReasons.Spamming,
        text: 'Spamming'
    },
    {
        value: BlockReasons.Copyright,
        text: 'Copyright'
    },
    {
        value: BlockReasons.Inappropriate,
        text: 'Inappropriate'
    },
    {
        value: BlockReasons.Collusion,
        text: 'Collusion'
    },
    {
        value: BlockReasons.FakeAccount,
        text: 'Fake Account'
    },
    {
        value: BlockReasons.DeleteRequest,
        text: 'Delete Request'
    }
];
