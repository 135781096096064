import styled from 'styled-components';
import { flexCenter } from 'v2/constants/styles/mixins';

export const MainLoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    ${flexCenter};
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
`;
