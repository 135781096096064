import React from 'react';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { ReportInfo } from './tabs/ReportInfo';

enum ReportAccountPageTab {
    ReportInfo
}

export const ReportSingleAccount = () => (
    <MainLayout isHideHeader>
        <DetailsPageLayout>
            <Tabs
                activeTab={ReportAccountPageTab.ReportInfo}
                tabs={[
                    {
                        id: ReportAccountPageTab.ReportInfo,
                        title: 'REPORT INFO',
                        content: <ReportInfo />
                    }
                ]}
            />
        </DetailsPageLayout>
    </MainLayout>
);
