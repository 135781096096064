import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const LinkItem = styled(Link)`
    min-width: 172px;
    padding: 6px 8px;
    transition: background-color ${transitionTime} linear;
    background-color: ${brandColors.white};

    &:hover {
        background-color: ${grey[100]};
    }
`;

export const Divider = styled(Section)`
    background-color: ${grey[300]};
    height: 1px;
    margin: 8px;
    width: calc(100% - 16px);
`;

export const Wrapper = styled(Row)`
    padding-left: 8px;
`;
