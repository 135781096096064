export const columns = [
    {
        title: 'USER REPORTED',
        dataIndex: 'user',
        key: 'user',
        width: '30%'
    },
    {
        title: 'REPORTED AT',
        dataIndex: 'date',
        key: 'date',
        width: '35%'
    },
    {
        title: 'REPORTED REASON',
        dataIndex: 'reason',
        key: 'reason',
        width: '35%'
    }
];
