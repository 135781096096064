import styled, { css } from 'styled-components';
import { PropertyLayoutProps } from 'v2/components/ui/PropertyLayout';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { NoWrap, Padding } from 'v2/types/styles';

interface Props extends Pick<PropertyLayoutProps, 'direction'>, Padding, NoWrap {}

export const TitleWrapper = styled(Row)``;

const rowDirection = css<Props>`
    flex-direction: row;
    padding: ${({ padding }) => padding || '12px'};
    justify-content: space-between;
`;

const columnDirection = css<Props>`
    flex-direction: column;
    padding: ${({ padding }) => padding || '8px'};

    ${TitleWrapper} {
        margin-bottom: 6px;
    }
`;

const getDirectionType = (props: Props) => {
    switch (props.direction) {
        case 'row':
            return rowDirection;
        case 'column':
        default:
            return columnDirection;
    }
};

export const BlockWrapper = styled(Section)<Props>`
    background: none;
    ${({ noWrap }) => noWrap !== 'unset' && `flex-wrap: ${noWrap ? 'nowrap' : 'wrap'}`};
    ${props => getDirectionType(props)};
    ${({ alignCenter }) => alignCenter && `align-items: center;`};
`;
