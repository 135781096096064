import { Span } from 'v1/components/common/typography/Span/styles';

import styled from 'styled-components';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { AbsoluteWrapper } from 'v1/components/wrappers/grid/AbsoluteWrapper/styles';
import { black, grey25, grey27, white } from 'v1/constants/styles/colors';
import { defaultTextColor } from 'v1/constants/styles/default';
import { transitionTime } from 'v1/constants/styles/others';
import {
    absoluteWrapperBorderRadius,
    borderRadius,
    selectPadding,
    selectZIndex,
    selectorHorizontalPadding,
    sideBarZIndex,
    xs
} from 'v1/constants/styles/sizes';
import { IsClosed } from 'v1/types/data';
import { Active } from 'v1/types/global';
import { BorderProperties, MaxSizes, Padding, PaddingLeft, TextProperties, Visibility } from 'v1/types/styles';
export interface SelectWrapperProps extends Pick<Padding, 'padding'>, Pick<BorderProperties, 'border'> {}

export const SelectWrapper = styled(ContentWrapper)<SelectWrapperProps>`
    position: relative;
    min-width: 170px;
    background-color: transparent;
    ${({ border }) => border && `border: ${border}`};
    padding: ${({ padding }) => (padding ? padding : selectPadding)};
    transition: background-color ${transitionTime};

    @media (min-width: 1270px) {
        padding: ${({ padding }) => (padding ? padding : selectorHorizontalPadding)};
    }

    @media (max-width: ${xs}) {
        background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
        padding: 4px 8px;
        border-radius: ${borderRadius};
        z-index: ${selectZIndex};
    }

    :hover {
        background-color: ${grey25};
        cursor: pointer;
    }
`;

interface MobileItemsWrapper extends IsClosed {
    customMargin?: number;
    maxHeight?: string;
}

export const MobileItemsWrapper = styled.div<MobileItemsWrapper>`
    margin-top: ${({ isClosed, customMargin }) => (isClosed ? `-${customMargin}px` : '0')};
    transform: ${({ isClosed }) => (isClosed ? 'scaleY(0)' : 'scaleY(1)')};
    background-color: ${grey27};
    width: 100%;
    transition-property: margin-top, transform;
    transition-duration: ${transitionTime};
    max-height: ${({ maxHeight }) => maxHeight || `none`};
    overflow: auto;
`;

interface ItemsAbsoluteWrapperProps extends Visibility, MaxSizes {}

export const ItemsAbsoluteWrapper = styled(AbsoluteWrapper)<ItemsAbsoluteWrapperProps>`
    width: 100%;
    left: 0;
    background-color: ${white};
    max-height: ${({ maxHeight }) => maxHeight || '189px'};
    border-radius: ${absoluteWrapperBorderRadius};
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.24);
    overflow: auto;
    z-index: ${sideBarZIndex + 2};
    ${({ visible }) => !visible && 'visibility: hidden;'};
`;

interface ItemWrapperProps extends PaddingLeft, Active, TextProperties {}

export const TitleSpan = styled(Span)`
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    color: ${black};
    opacity: 0.4;

    @media (max-width: ${xs}) {
        font-size: 11px;
        line-height: 19px;
    }
`;

export const ItemSpan = styled.span<ItemWrapperProps>`
    font-size: 12px;
    font-style: ${({ fontStyle }) => fontStyle || 'normal'};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;

    @media (max-width: ${xs}) {
        font-size: 11px;
        line-height: 21px;
    }
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 27px;
    width: 100%;
    padding: 0 12px;
    transition: background-color ${transitionTime}, color ${transitionTime};

    background-color: ${({ active }) => (active ? black : white)};

    ${ItemSpan} {
        color: ${({ active }) => (active ? white : defaultTextColor)};
    }

    @media (max-width: ${xs}) {
        padding: 16px 20px;

        ${({ active }) => active && `background-color: ${grey25}`}

        :last-child {
            border-radius: 0;
        }
    }

    :hover {
        background-color: ${grey25};
        cursor: pointer;
        ${ItemSpan} {
            color: ${black};
        }
    }

    :last-child {
        border-bottom: 0;
        border-radius: ${absoluteWrapperBorderRadius};
    }
`;
