import { ParticipationStatesKey } from 'v2/constants/services/enrollments';

export enum CollaborationSingleTab {
    Info,
    Requests,
    ProductShipping,
    Enrollments,
    Submissions,
    Engagement
}

export const defaultRequestsQueryParams: YecmPaths.AdminCollaboration$CollaborationIdParticipants.Get.QueryParameters = {
    pageIndex: 0,
    limit: 10,
    ParticipationState: ParticipationStatesKey.EnrollmentRequested
};
