import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { Category } from 'v2/components/ui/Category';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { DeliveryState, ShipmentState } from 'v2/constants/services/shippings';
import { grey } from 'v2/constants/styles/colors';
import { LinkUserInfo } from 'v2/pages/Report/common/styles';
import { viewAddressInfoModal } from 'v2/stores/initialize-modals';
import { EllipsisLines } from './styles';

const prepareStatus = (shipment: ShipmentState, delivery: DeliveryState) => {
    if (shipment === ShipmentState.None && delivery === DeliveryState.None) {
        return 'Brand not shipped';
    }

    if (shipment === ShipmentState.NotShipped) {
        return 'Brand not shipped';
    }

    if (shipment === ShipmentState.Shipped && delivery === DeliveryState.Waiting) {
        return 'Brand shipped';
    }

    if (shipment === ShipmentState.Shipped && delivery === DeliveryState.Confirmed) {
        return 'Creator Received';
    }
};

export const prepareDataForTable = (items: YECM.ProductShippingResponse[]) =>
    items.map(item => ({
        key: item.id,
        user: (
            <LinkUserInfo to={`${routePaths.users}/${item.creatorProfile?.remoteCreatorId}`}>
                <Row alignCenter noWrap>
                    <Row marginRight="8px">
                        <Avatar size="32px" src={item.creatorProfile?.profileImageUrl || ''} />
                    </Row>

                    <Text isSemibold size="-2">
                        @{item.creatorProfile?.userName}
                    </Text>
                </Row>
            </LinkUserInfo>
        ),
        address: item.shippingAddress?.street ? (
            <Section alignCenter justifyBetween noWrap>
                <EllipsisLines isSemibold size="-2">
                    {`${item.shippingAddress.fullName}, ${item.shippingAddress.street}, ${item.shippingAddress.zipCode}, ${item.shippingAddress.country}, ${item.shippingAddress.city}`}
                </EllipsisLines>

                {item.shippingAddress && (
                    <Row marginLeft="10px" minWidth="46px">
                        <Button
                            buttonType="text-green"
                            textSize="small"
                            onClick={() => viewAddressInfoModal.openModal(item.shippingAddress)}
                        >
                            View
                        </Button>
                    </Row>
                )}
            </Section>
        ) : (
            '-'
        ),
        options:
            item.productDetails && Object.values(item.productDetails).length > 0 ? (
                <Section justifyCenter marginBottom="-4px" paddingBottom="6px" paddingTop="6px">
                    {Object.values(item.productDetails).map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index} marginBottom="4px" marginRight="4px">
                            <Category text={item} />
                        </Row>
                    ))}
                </Section>
            ) : (
                '-'
            ),
        status: (
            <Text isSemibold color={grey[400]} size="-2">
                {prepareStatus(item.shipmentState || 0, item.deliveryState || 0)}
            </Text>
        )
    }));
