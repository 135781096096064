import React, { FC } from 'react';
import { Breadcrumb } from 'v2/components/grid/Breadcrumb';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { BreadcrumbWrapper } from './styles';

export const DetailsPageLayout: FC = ({ children }) => (
    <>
        <BreadcrumbWrapper borderBottom padding="18px 16px">
            <Breadcrumb />
        </BreadcrumbWrapper>

        <Column noWrap width="100%">
            {children}
        </Column>
    </>
);
