import { primaryBorderRadius } from 'v1/constants/styles/sizes';

export const paginationCellMarginRight = '8px';

export const paginationInputWidth = '93px';
export const paginationInputHeight = '29px';
export const paginationInputPadding = '10px';

export const arrowImgWidth = '6px';
export const arrowImgHeight = '10px';

export const paginationWrapperHorizontalMargin = '32px';

export const testArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

// * only odd whole non zero number
export const paginationLimit = 5;
export const paginationLimitMobile = 1;

export const pagination = Array.from({ length: paginationLimit }, () => 1);
export const paginationMobile = Array.from({ length: paginationLimitMobile }, () => 1);

export const sizeValues = ['10', '20', '50', '100'];

export const selectorHorizontalPadding = '10px';
export const selectorVerticalPadding = '8px';

export const itemHeight = '30px';

const beforeBorderRadiusHeight = '5px';
export const lastItemBorderHeight = parseInt(primaryBorderRadius) + parseInt(beforeBorderRadiusHeight) + 'px';

export const expandButtonHeight = '10px';

export const threeDots = '...';

export const paginationWrapperMarginRight =
    parseInt(paginationWrapperHorizontalMargin) - parseInt(paginationCellMarginRight) + 'px';
