import failureSrc from 'v2/assets/images/failure.png';
import warningSrc from 'v2/assets/images/warning.png';
import { PageStatus } from './types';

export const statusEmoji: Record<PageStatus, string> = {
    401: failureSrc,
    403: warningSrc,
    404: failureSrc
};

export const statusTitles: Record<PageStatus, string> = {
    401: 'Unauthorized Access',
    403: 'Forbidden Access',
    404: 'Page Not Found'
};

export const statusTexts: Record<PageStatus, string> = {
    401: 'It seems like you don`t have the proper credentials to access this resource. If you believe you should have access, contact the administrator for assistance.',
    403: 'We`re sorry, but you do not have permission to view the content you requested. If you believe this is an error, please get in touch with the site administrator for further assistance.',
    404: 'Uh-oh! It looks like the page you are trying to reach has vanished into the digital abyss. This might be due to a mistyped URL, an outdated link, or the page may have been removed.'
};
