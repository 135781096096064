import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { CloseIcon, LogoutIcon, YeayBigIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths, routesArray } from 'v2/constants/routes';
import { UserRole } from 'v2/constants/services/users';
import { brandColors } from 'v2/constants/styles/colors';
import { sizes } from 'v2/constants/styles/media';
import { useBodyScroll } from 'v2/hooks/use-body-scroll';
import { authEvents, authStores } from 'v2/stores/auth';
import { mobileHeaderEvents, mobileHeaderStores } from 'v2/stores/mobile-header';
import {
    BlackBackground,
    CloseButton,
    IconWrapper,
    LogoLink,
    LogoutButton,
    PageName,
    SidebarLink,
    UserInfoWrapper,
    UserName,
    Wrapper
} from './styles';

const { $sidebarVisible } = mobileHeaderStores;
const { hideSidebar } = mobileHeaderEvents;

export const SideBar = () => {
    const location = useLocation();

    const sidebarVisible = useStore($sidebarVisible);
    const user = useStore(authStores.$user);

    const userName = user?.username || '-';
    const userAvatar = user?.profile?.profileImageUrl || '';
    const userId = user?.userId || '';
    const userRoles = user?.roles || [];

    const isDesktop = useMediaQuery({ query: `(min-width: ${sizes.laptopSmall}px)` });

    const menuItems = routesArray.filter(route =>
        userRoles.some(userRole => route.roles.includes(userRole as UserRole))
    );

    const handleLogout = () => authEvents.logout();

    const handleClose = () => {
        hideSidebar();
    };

    useBodyScroll(sidebarVisible);

    useEffect(() => {
        hideSidebar();
    }, [location.pathname]);

    return isDesktop ? (
        <Wrapper>
            <Column noWrap width="100%">
                <Section alignCenter noWrap marginBottom="40px">
                    <LogoLink to={routePaths.videos}>
                        <YeayBigIcon height="24px" width="48px" />
                        <Row marginLeft="12px">
                            <Title noTextWrap>Admin System</Title>
                        </Row>
                    </LogoLink>
                </Section>

                <UserInfoWrapper to={`${routePaths.users}/${userId}`}>
                    <Avatar src={userAvatar} />
                    <UserName>@{userName}</UserName>
                </UserInfoWrapper>

                <Column width="100%">
                    {menuItems.map(({ path, name, icon }) => (
                        <SidebarLink key={path} to={path}>
                            <IconWrapper>{icon}</IconWrapper>

                            <PageName isUppercase noTextWrap color="inherit" size="-2">
                                {name}
                            </PageName>
                        </SidebarLink>
                    ))}
                </Column>
            </Column>

            <LogoutButton type="button" onClick={handleLogout}>
                <LogoutIcon color="inherit" height="11px" width="11px" />
                <Row marginLeft="10px">
                    <Title isUppercase noTextWrap color="inherit" size="-2">
                        Log Out
                    </Title>
                </Row>
            </LogoutButton>
        </Wrapper>
    ) : (
        <>
            <BlackBackground isClosed={!sidebarVisible}>
                <CloseButton type="button" onClick={handleClose}>
                    <CloseIcon color={brandColors.black} height="14px" width="14px" />
                </CloseButton>
            </BlackBackground>

            <Wrapper isClosed={!sidebarVisible}>
                <Column noWrap width="100%">
                    <Section alignCenter justifyCenter marginBottom="40px">
                        <LogoLink to={routePaths.videos}>
                            <YeayBigIcon height="24px" width="48px" />
                            <Row marginLeft="12px">
                                <Title>Admin System</Title>
                            </Row>
                        </LogoLink>
                    </Section>

                    <UserInfoWrapper to={`${routePaths.users}/${userId}`}>
                        <Avatar src={userAvatar} />
                        <UserName>@{userName}</UserName>
                    </UserInfoWrapper>

                    <Column alignCenter width="100%">
                        {routesArray.map(({ path, name, icon }) => (
                            <SidebarLink key={path} to={path}>
                                <IconWrapper>{icon}</IconWrapper>

                                <PageName isUppercase color="inherit" size="-2">
                                    {name}
                                </PageName>
                            </SidebarLink>
                        ))}
                    </Column>
                </Column>

                <LogoutButton type="button" onClick={handleLogout}>
                    <Section alignCenter>
                        <LogoutIcon color="transition" height="11px" width="11px" />
                        <Row marginLeft="10px">
                            <Title isUppercase color="transition" size="-2">
                                Log Out
                            </Title>
                        </Row>
                    </Section>
                </LogoutButton>
            </Wrapper>
        </>
    );
};
