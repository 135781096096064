import React, { FC } from 'react';
import { ArrowRightSmallIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { Noop } from 'v2/types/types';
import { OptionWrapper, StyledButton } from './styles';

interface Props {
    isActive?: boolean;
    isNested?: boolean;
    onClick?: Noop;
    onArrowClick?: Noop;
}

export const NestedOption: FC<Props> = ({ isActive, isNested, onClick, onArrowClick, children }) => (
    <OptionWrapper>
        <StyledButton disabled={isActive} isActive={isActive} type="button" onClick={onClick}>
            <Text size="-2">{children}</Text>
        </StyledButton>

        {isNested && (
            <Column flexShrink="0">
                <IconButton
                    bgColor={brandColors.white}
                    bgHoverColor={brandColors.grey}
                    color={grey[500]}
                    hoverColor={brandColors.black}
                    size="32px"
                    onClick={onArrowClick}
                >
                    <ArrowRightSmallIcon height={10} width={6} />
                </IconButton>
            </Column>
        )}
    </OptionWrapper>
);
