import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';

export const EllipsisLines = styled(Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    text-align: left;
`;
