import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { notify } from 'v2/components/ui/Toasts';
import { TextProps } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { StyledText, TextWrapper } from './styles';

interface Props extends TextProps {
    subject?: string;
    text?: string;
    successText?: string;
}

export const CopyableText: FC<Props> = ({ subject, text = '-', successText = 'Copied', ...props }) => {
    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify(successText);
        }
    };

    return (
        <Row alignCenter noWrap>
            <TextWrapper>
                <StyledText {...props}>{text}</StyledText>
            </TextWrapper>

            {subject && (
                <Column marginLeft="6px">
                    <CopyToClipboard text={subject} onCopy={handleCopy}>
                        <IconButton size="10px" onClick={e => e.preventDefault()}>
                            <CopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Column>
            )}
        </Row>
    );
};
