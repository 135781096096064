import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { CategoryWrapper } from './styles';

interface Props {
    text: string;
    bordered?: boolean;
}

export const Category: FC<Props> = ({ text, bordered = false }) => (
    <CategoryWrapper bordered={bordered}>
        <Text size="-2">{text}</Text>
    </CategoryWrapper>
);
