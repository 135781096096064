import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { SingleReportInfoCard } from 'v2/components/cards/SingleReportInfoCard';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { ReviewReportModal } from 'v2/components/modals/ReviewReportModal';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ContentWrapper } from 'v2/pages/Report/common/styles';
import { reportsListEffects } from 'v2/stores/report/details/reports-list';
import { reportVideoEffects, reportVideoEvents, reportVideoStores } from 'v2/stores/report/details/video';
import { ReportsTable } from '../../../common/ReportsTable';

const { getReportVideoInfoFx } = reportVideoEffects;

const { getReportsListFx } = reportsListEffects;

interface Props {
    id?: string;
}

export const ReportInfo: FC<Props> = ({ id }) => {
    const reportVideoInfo = useStore(reportVideoStores.$reportVideoInfo);
    const isLoading = useStore(getReportVideoInfoFx.pending);

    const isLoadingReports = useStore(getReportsListFx.pending);

    useEffect(() => {
        if (id) {
            getReportVideoInfoFx({ videoId: id });
        }

        return () => {
            reportVideoEvents.resetReportVideo();
        };
    }, [id]);

    return (
        <ContentWrapper>
            {isLoading ? (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            ) : (
                <>
                    {reportVideoInfo ? (
                        <Section>
                            <SingleReportInfoCard data={reportVideoInfo} />
                        </Section>
                    ) : (
                        <Empty title="No Report info found" />
                    )}
                </>
            )}

            <Section marginTop="10px">
                <AccordionSection title="Reports">
                    <ReportsTable id={id} isLoading={isLoadingReports} />
                </AccordionSection>
            </Section>

            <ReviewReportModal />
        </ContentWrapper>
    );
};
