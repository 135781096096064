import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';

export const TitleWrapper = styled(Section)`
    padding: 5px 8px;
`;

export const RangeWrapper = styled(Section)`
    padding: 8px;
`;
