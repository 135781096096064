import React from 'react';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { StartEllipsisProperty } from 'v2/components/ui/PropertyLayout/components/StartEllipsisProperty';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { campaignManagerReportType } from 'v2/constants/services/reports';
import { ReportStatus } from 'v2/pages/Reports/tabs/components/ReportStatus';
import { CampaignManagerReportType } from './CampaignManagerReportType';

export const prepareReportsForTable = (reports: YECM.ReportResponse[]) =>
    reports.map(report => {
        const type = report?.reportType || 0;

        return {
            key: report.assetId,
            // TODO check reportType for Post
            type: <CampaignManagerReportType type={type} />,
            reportId: (
                <Section justifyCenter>
                    <Row maxWidth="250px">
                        <StartEllipsisProperty isSemibold id={report.id} title="Report ID" />
                    </Row>
                </Section>
            ),
            assetId: (
                <Section justifyCenter>
                    <Row maxWidth="250px">
                        <StartEllipsisProperty
                            isSemibold
                            id={report.assetId}
                            title={`${campaignManagerReportType[type]} ID`}
                        />
                    </Row>
                </Section>
            ),
            collabName: <EllipsisText size="-1">{report.collaborationName}</EllipsisText>,
            date: <DateProperty alignCenter date={report.dateFirstReported} direction="row" />,
            status: (
                <Section justifyCenter>
                    <ReportStatus isTableReport status={report.reportStatus || 0} />
                </Section>
            ),
            collaborationId: report.collaborationId
        };
    });
