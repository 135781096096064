import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { CopyableText } from 'v2/components/ui/typography/CopyableText';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { EllipsisText, LinkItem } from './styles';

export const getInactiveUsersTableData = (inactiveUsers: YEAY.AdminGetUserCommon[] | null | undefined) =>
    inactiveUsers?.map(item => ({
        key: item.id,
        username: (
            <LinkItem to={`${routePaths.users}/${item.id}`}>
                <Section alignCenter noWrap>
                    <MarginWrapper marginRight="8px">
                        <Avatar size="32px" />
                    </MarginWrapper>
                    <EllipsisText isSemibold size="-2">
                        @{item.username}
                    </EllipsisText>
                </Section>
            </LinkItem>
        ),
        type: (
            <Section justifyCenter>
                {/* TODO change to dynamic data when endpoint is ready */}
                <Text size="-1">Blocked</Text>
            </Section>
        ),
        userId: (
            <Section justifyCenter>
                <CopyableText isSemibold size="-2" subject={item.id} text={item.id} />
            </Section>
        ),
        actionBy: (
            <Section justifyCenter>
                <Text size="-1">@{item.disablingInfo?.[0].disablerInfo?.userName || ''}</Text>
            </Section>
        ),
        reason: (
            <Section justifyCenter>
                {/* TODO change to dynamic data when endpoint is ready */}
                <Text size="-1">No data</Text>
            </Section>
        )
    }));
