import { createEffect, createStore } from 'effector';
import { isEmpty } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { TrendingType, maximumVideosCount } from 'v2/constants/services/trendings';
import { API } from 'v2/services/yasy';
import { getSwapItems } from 'v2/stores/trendings/utils';

// Effects

const getTrendingVideosFx = createEffect({
    handler: async () => {
        try {
            const response = await API.trendings.getTrendings({
                pageIndex: 0,
                limit: maximumVideosCount,
                type: TrendingType.Video,
                returnQueryCount: true
            });

            if (!response || isEmpty(response)) {
                throw new Error();
            }

            return response;
        } catch (e) {
            console.log('Failed to get trending videos', e);
            return null;
        }
    }
});

const deleteVideoFromTrendingFx = createEffect({
    handler: async (id: string) => {
        try {
            const { isSuccess } = await API.trendings.removeItemById({ id });

            if (!isSuccess) {
                throw new Error();
            }

            notify('Video successfully removed from trending!');
            return id;
        } catch (e) {
            notify('There was a problem removing video, try again.', 'error');
            console.log('There was a problem removing creator.', e);
            return null;
        }
    }
});

interface SwapVideosParams {
    fromItem: YEAY.UpdateTrendingOverridesRequest;
    toItem: YEAY.UpdateTrendingOverridesRequest | null;
}

const swapVideosFx = createEffect({
    handler: async ({ fromItem, toItem }: SwapVideosParams) => {
        try {
            await API.trendings.updateItemById(fromItem);

            if (toItem) {
                await API.trendings.updateItemById(toItem);
            }

            return { fromItem, toItem };
        } catch (e) {
            notify('Failed to swap videos. Try again.', 'error');
            console.log('Failed to swap videos.', e);
            return null;
        }
    }
});

const deleteVideosFromTrendingFx = createEffect({
    handler: async (ids: string[]) => {
        try {
            if (ids.length === 0) {
                return [];
            }

            const result: string[] = [];

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];

                const { isSuccess } = await API.trendings.removeItemById({ id });

                if (isSuccess) {
                    result.push(id);
                }
            }

            return result;
        } catch (e) {
            console.log('There was a problem removing videos.', e);
            return [];
        }
    }
});

const addVideosToTrendingFx = createEffect({
    handler: async (videos: Array<Pick<YEAY.CreateTrendingOverridesRequest, 'targetId' | 'position'>>) => {
        try {
            if (videos.length === 0) {
                return [];
            }

            const result: YEAY.CreateTrendingOverridesResponse[] = [];

            for (let i = 0; i < videos.length; i++) {
                const video = videos[i];

                const item = await API.trendings.createTrending({
                    ...video,
                    type: TrendingType.Video
                });

                if (item) {
                    result.push(item);
                }
            }

            return result;
        } catch (e) {
            console.log('There was a problem adding videos.', e);
            return [];
        }
    }
});

// Stores

const $videosResponse = createStore<YEAY.QueryTrendingOverridesResponse | null>(null)
    .on(getTrendingVideosFx.doneData, (_, videosResponse) => videosResponse)
    .on(deleteVideoFromTrendingFx.doneData, (videosResponse, itemId) => {
        if (!videosResponse || !itemId || !videosResponse.items || videosResponse.items.length === 0) {
            return videosResponse;
        }

        const newItems = videosResponse.items.filter(item => item.id !== itemId);

        return {
            ...videosResponse,
            totalRecords: newItems.length,
            returnedRecords: newItems.length,
            items: newItems
        };
    })
    .on(swapVideosFx.doneData, (videosResponse, items) => {
        if (!videosResponse || !items) {
            return videosResponse;
        }

        const newItems = getSwapItems(items, videosResponse.items);

        return {
            ...videosResponse,
            items: newItems
        };
    })
    .on(deleteVideosFromTrendingFx.doneData, (videosResponse, ids) => {
        if (!videosResponse || ids.length === 0) {
            return videosResponse;
        }

        const newItems = (videosResponse.items || []).filter(item => !!item.id && !ids.includes(item.id));

        return {
            ...videosResponse,
            items: newItems
        };
    });

const $videos = $videosResponse.map(videosResponse => videosResponse?.items || []);

// Exports

export const trendingVideosEffects = {
    getTrendingVideosFx,
    deleteVideoFromTrendingFx,
    deleteVideosFromTrendingFx,
    swapVideosFx,
    addVideosToTrendingFx
};

export const trendingVideosStores = { $videosResponse, $videos };
