import styled from 'styled-components';
import { SimpleButton } from 'v1/components/common/buttons/SimpleButton/styles';
import { black } from 'v1/constants/styles/colors';
import { transitionTime } from 'v1/constants/styles/others';

export const CardButtonStyled = styled(SimpleButton)`
    transition: ${transitionTime} linear;

    &:hover:not(:disabled) {
        color: ${black};
    }
`;
