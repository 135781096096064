import React, { FC } from 'react';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { addEditCommissionModal } from 'v2/stores/initialize-modals';
import { GridInfoBlocks, PropertyLayoutWrapper } from './styles';

interface Props {
    agentInfo: YEAY.AgentDto;
}

export const InfoBlocks: FC<Props> = ({ agentInfo }) => {
    const commission = agentInfo?.earningFee ? agentInfo?.earningFee * 100 : 0;

    const emptyEarningFee = agentInfo?.earningFee && agentInfo.earningFee === 0;

    const openModalCommission = () => {
        if (emptyEarningFee) {
            addEditCommissionModal.openModal({ id: agentInfo.userId });
        } else {
            const state = {
                id: agentInfo.userId,
                isEdit: true,
                commission: commission
            };

            addEditCommissionModal.openModal(state);
        }
    };

    return (
        <GridInfoBlocks>
            <PropertyLayoutWrapper>
                <PropertyLayout title="Total Influencer Count">
                    <Text isSemibold size="-2">
                        {agentInfo.membersCount || '-'}
                    </Text>
                </PropertyLayout>
            </PropertyLayoutWrapper>
            <PropertyLayoutWrapper>
                <PropertyLayout
                    extra={
                        <Button buttonType="text-black" textSize="small" onClick={openModalCommission}>
                            {emptyEarningFee ? 'Add Commission' : 'Edit'}
                        </Button>
                    }
                    title="Commission per Influencer"
                >
                    <Text isSemibold size="-2">
                        {commission}
                        {commission !== 0 ? '%' : ''}
                    </Text>
                </PropertyLayout>
            </PropertyLayoutWrapper>
            <PropertyLayoutWrapper>
                <PropertyLayout textCopy={agentInfo.downloadCode || ''} title="Agent Download Link">
                    <Text isSemibold size="-2">
                        {agentInfo.downloadCode || '-'}
                    </Text>
                </PropertyLayout>
            </PropertyLayoutWrapper>
        </GridInfoBlocks>
    );
};
