import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';

import { DownloadIcon } from 'v2/assets/icons';
import { notify } from 'v2/components/ui/Toasts';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { shippingsStores } from 'v2/stores/collaboration/details/shippings';
import { actionsShippingsEffects, actionsShippingsStores } from 'v2/stores/collaboration/details/shippings/actions';
import { getFormattedDate } from 'v2/utils/datetime';

const { downloadShippingsCsvFx } = actionsShippingsEffects;

interface Props {
    collaborationId: string;
}

export const DownloadButton: FC<Props> = ({ collaborationId }) => {
    const downloadData = useStore(actionsShippingsStores.$downloadedShippings);
    const items = useStore(shippingsStores.$shippingsItems);

    const isDisabled = !items || items?.length === 0;

    const downLoadFile = () => {
        const url = window.URL.createObjectURL(new Blob([downloadData]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `shipping-${collaborationId}-${getFormattedDate(new Date().toISOString())}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    const handleClick = () => {
        if (isDisabled) {
            return false;
        }

        downLoadFile();
        notify('Successfully downloaded');
    };

    useEffect(() => {
        downloadShippingsCsvFx(collaborationId);
    }, [collaborationId]);

    return (
        <IconButton disabled={isDisabled} onClick={handleClick}>
            <DownloadIcon />
        </IconButton>
    );
};
