import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Types

export type InactiveUsersParams = YEAY.QueryDisabledUsersRequest;

// Constants

export const defaultInactiveUsersParams: InactiveUsersParams = {
    pageIndex: 0,
    limit: 10
};

// Events

const setDefaultFilters = createEvent();
const addFilters = createEvent<Partial<InactiveUsersParams>>();
const setOverwriteFilters = createEvent<Partial<InactiveUsersParams>>();
const resetInactiveUsersStores = createEvent();

// Effects

const getInactiveUsersFx = createEffect({
    handler: async (params?: InactiveUsersParams) => {
        try {
            // TODO need to change this endpoint to a new one
            const data = await API.inactiveUsers.getBlockedUsers({
                ...defaultInactiveUsersParams,
                ...params
            });

            return data;
        } catch (error) {
            notify('There was a problem receiving inactive users, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $inactiveUsers = createStore<YEAY.QueryDisabledUsersResponse | null>(null)
    .on(getInactiveUsersFx.doneData, (state, response) => {
        if (!response) return;

        return {
            ...(state || {}),
            ...response,
            items:
                response.currentPageIndex === 0 ? response.items : [...(state?.items || []), ...(response.items || [])]
        };
    })
    .reset(resetInactiveUsersStores);

const $inactiveUsersFilters = createStore<InactiveUsersParams>(defaultInactiveUsersParams)
    .on(setDefaultFilters, () => defaultInactiveUsersParams)
    .on(addFilters, (state, newFilters) => ({
        ...(state || defaultInactiveUsersParams),
        ...newFilters
    }))
    .on(setOverwriteFilters, (_, newFilters) => ({
        ...defaultInactiveUsersParams,
        ...newFilters,
        pageIndex: 0
    }));

// Exports

export const inactiveUsersEvents = {
    setDefaultFilters,
    addFilters,
    setOverwriteFilters,
    resetInactiveUsersStores
};

export const inactiveUsersEffects = {
    getInactiveUsersFx
};

export const inactiveUsersStores = {
    $inactiveUsers,
    $inactiveUsersFilters
};
