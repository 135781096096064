import { useStore } from 'effector-react';
import React from 'react';
import emptyStateImage from 'v1/assets/icons/blacklist_empty_state_icon.svg';
import { BlacklistedUsersTable } from 'v1/components/common/tables/BlacklistedUsersTable';
import BlacklistedUsersFilterLayout from 'v1/components/layouts/filterLayouts/BlacklistedUsersFilterLayout';
import { Empty } from 'v1/components/layouts/resultLayouts/Empty';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { grey25 } from 'v1/constants/styles/colors';
import { defaultMessage, emptyStateImageWrapperDiameter } from 'v1/pages/Blacklisted/constants';
import { BlacklistedLayoutWrapper } from 'v1/pages/Blacklisted/styles';
import { blacklistedUsersStores } from 'v1/stores/users/blacklistedUsers';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';

export const Blacklisted = () => {
    const { items } = useStore(blacklistedUsersStores.blacklistedUsers);
    const { type } = useStore(blacklistedUsersStores.values);
    const loading = useStore(blacklistedUsersStores.loading);

    return (
        <MainLayout>
            <BlacklistedLayoutWrapper>
                <BlacklistedUsersFilterLayout>
                    <Section>
                        {loading ? (
                            <Section justifyCenter>
                                <Spinner />
                            </Section>
                        ) : (
                            <Section>
                                {items?.length && type ? (
                                    <BlacklistedUsersTable items={items} type={type} />
                                ) : (
                                    <Empty
                                        emptyLayoutMarginTop="100px"
                                        imageHeight="18px"
                                        imageSrc={emptyStateImage}
                                        imageWidth="22px"
                                        imageWrapperBackgroundColor={grey25}
                                        imageWrapperBorderRadius="50%"
                                        imageWrapperHeight={emptyStateImageWrapperDiameter}
                                        imageWrapperWidth={emptyStateImageWrapperDiameter}
                                        title={defaultMessage}
                                        titleFontSize="16px"
                                        titleFontWeight="500"
                                        titleLineHeight="26px"
                                        titleWidth="390px"
                                    />
                                )}
                            </Section>
                        )}
                    </Section>
                </BlacklistedUsersFilterLayout>
            </BlacklistedLayoutWrapper>
        </MainLayout>
    );
};
