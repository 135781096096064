import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { DownloadIcon } from 'v2/assets/icons';
import { metrics } from 'v2/components/cards/VideoCard/constants';
import { VideoStatus } from 'v2/components/common/VideoStatus';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Avatar } from 'v2/components/ui/Avatar';
import { Category } from 'v2/components/ui/Category';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { Image } from 'v2/components/ui/Image';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Video } from 'v2/components/ui/Video';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { LanguageType, languages } from 'v2/constants/languages';
import { routePaths } from 'v2/constants/routes';
import { reportStatus } from 'v2/constants/services/report';
import { VideoSourceState } from 'v2/constants/services/video';
import { VideoCurationState, videoState, videoStateTitles } from 'v2/constants/services/videos';
import { authStores } from 'v2/stores/auth';
import { reviewReportModal, videoModal } from 'v2/stores/initialize-modals';
import { singleVideoEffects, singleVideoStores } from 'v2/stores/single-video';
import { getCompactNumber } from 'v2/utils/formats';
import { getLanguageImage } from 'v2/utils/languages';
import { CardWrapper, GridItem, PropertiesGrid, StyledLink, VideoWrapper } from './styles';

interface Props {
    data: YEAY.GetReportedVideoResponse;
}

export const SingleReportInfoCard: FC<Props> = ({ data }) => {
    const isAdmin = useStore(authStores.$isAdmin);

    const videoSource = useStore(singleVideoStores.$videoSource);
    const videoSourceIsLoading = useStore(singleVideoEffects.getVideoSourceFx.pending);

    const curationState = (data?.yeay?.curationState || VideoCurationState.None) as VideoCurationState;
    const language = data?.audioLanguages && data?.audioLanguages.length > 0 ? data?.audioLanguages[0] : '';

    const handleReviewReport = () => {
        data.reportId && reviewReportModal.openModal(data.reportId);
    };

    const openVideoFullscreen = () => {
        if (data?.videoId) {
            videoModal.openModal(data.videoId);
        }
    };

    useEffect(() => {
        if (data.videoId) {
            singleVideoEffects.getVideoSourceFx(data.videoId);
        }
    }, [data.videoId]);

    return (
        <CardWrapper>
            <SectionBorder borderRight padding="10px 16px">
                <Column alignCenter>
                    <VideoWrapper marginBottom="8px">
                        <Video
                            cover
                            posterSrc={data?.streaming?.details?.screenGrabUrl}
                            videoSrc={data?.streaming?.details?.hlsUrl}
                            onFullscreen={openVideoFullscreen}
                        />
                    </VideoWrapper>
                    <Button disabled={data.reportStatus !== reportStatus.review} onClick={handleReviewReport}>
                        Review Report
                    </Button>

                    {videoSourceIsLoading ? (
                        <Section justifyCenter marginTop="8px">
                            <Spinner />
                        </Section>
                    ) : (
                        isAdmin &&
                        videoSource?.status === VideoSourceState.Processed &&
                        videoSource?.url && (
                            <Section marginTop="8px">
                                <Button
                                    isExternal
                                    wide
                                    buttonType="text-black"
                                    href={videoSource.url}
                                    icon={<DownloadIcon height={12} width={12} />}
                                    iconPosition="right"
                                >
                                    Download
                                </Button>
                            </Section>
                        )
                    )}
                </Column>
            </SectionBorder>

            <Section>
                <PropertiesGrid>
                    <GridItem>
                        <PropertyLayout textCopy={data?.videoId || ''} title="Video ID">
                            <Text isSemibold size="-2">
                                {data?.videoId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Video Status">
                            <VideoStatus status={videoState[curationState]} titles={videoStateTitles} />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Created">
                            <DateProperty date={data?.utcUploaded} padding="0" />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={data?.ownerId || ''} title="User ID">
                            <Text isSemibold size="-2">
                                {data?.ownerId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Username">
                            <StyledLink to={`${routePaths.users}/${data?.ownerId}`}>
                                <Text isSemibold size="-2">
                                    @{data?.username || '-'}
                                </Text>
                            </StyledLink>
                            {/* TODO need to fix */}
                            {/* {data?.isTrusted && (
                                <Row marginLeft="8px">
                                    <Tooltip overlay={<TooltipText text="Account is trusted" />} placement="top">
                                        <DoneIcon height={14} width={14} />
                                    </Tooltip>
                                </Row>
                            )} */}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Language">
                            {language ? (
                                <Row alignCenter noWrap>
                                    <Row marginRight="4px">
                                        <Text size="-2">{languages[language as LanguageType]}</Text>
                                    </Row>
                                    <Image height="12px" src={getLanguageImage(language)} width="16px" />
                                </Row>
                            ) : (
                                <Text isSemibold size="-2">
                                    -
                                </Text>
                            )}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={data?.brandId || ''} title="Brand ID">
                            <Text isSemibold size="-2">
                                {data?.brandId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Brand Name">
                            <Text isSemibold size="-2">
                                {data?.brandName || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Brand Image">
                            <Avatar size="24px" src={data?.profileImageUrl || ''} />
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={data?.collaborationId || ''} title="Collab ID">
                            <Text isSemibold size="-2">
                                {data?.collaborationId || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout title="Category">
                            {data?.category ? (
                                <Category text={data?.category} />
                            ) : (
                                <Text isSemibold size="-2">
                                    -
                                </Text>
                            )}
                        </PropertyLayout>
                    </GridItem>
                    <GridItem>
                        <PropertyLayout textCopy={data?.brandWebsite || ''} title="Website">
                            <Text isSemibold size="-2">
                                {data?.brandWebsite || '-'}
                            </Text>
                        </PropertyLayout>
                    </GridItem>
                    <GridItem column="1/4">
                        <Section padding="12px 14px">
                            {metrics.map(item => (
                                <Row key={item.key} marginRight="40px">
                                    <IconTextItem
                                        icon={item.icon}
                                        text={getCompactNumber(data?.engagementStatistics?.[item.key])}
                                    />
                                </Row>
                            ))}
                        </Section>
                    </GridItem>
                </PropertiesGrid>
            </Section>
        </CardWrapper>
    );
};
