import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOption, SelectOptionValue } from 'v2/components/ui/selects/types';
import { categoriesEffects, categoriesStores } from 'v2/stores/categories';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    store: GenericCollaborationsType;
}

export const CollaborationCategories: FC<Props> = ({ store }) => {
    const categories = useStore(categoriesStores.$categories);
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    const categoryOptions: SelectOption[] = categories.map(category => ({
        label: `${category.emojiCode} ${category.translation?.displayName}`,
        value: category.tagId || ''
    }));

    const onChangeCategory = (category: SelectOptionValue[]) => {
        store.events.overwriteFilters({
            categories: category as string[]
        });
    };

    return (
        <MultiSelect
            wide
            options={categoryOptions}
            title="Category"
            values={collaborationsFilters?.categories}
            onChange={onChangeCategory}
        />
    );
};
