import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { transitionTime } from 'v2/constants/styles/special';

export const StyledLink = styled(Link)`
    text-decoration: underline;
    transition: all ${transitionTime} linear;

    &:hover {
        text-decoration: none;
    }
`;
