import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Category } from 'v2/components/ui/Category';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOption, SelectOptionValue } from 'v2/components/ui/selects/types';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { ContentWrapper } from 'v2/components/wrappers/ContentWrapper/styles';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { TrendingType, maximumCategoriesCount } from 'v2/constants/services/trendings';
import { useDragItems } from 'v2/hooks/use-drag-items';
import { categoriesEffects, categoriesStores } from 'v2/stores/categories';
import { trendingCategoriesEffects, trendingCategoriesStores } from 'v2/stores/trendings/categories';
import { getArrayWithPositions } from 'v2/utils/array';
import { capitalizeFirstLetter } from 'v2/utils/string';
import { BlockWrapper, Header } from '../styles';
import { DragSpot, DraggableWrapper } from './styles';

export const TrendingCategories = () => {
    const { items, totalRecords } = useStore(trendingCategoriesStores.$categories);
    const isLoading = useStore(trendingCategoriesEffects.getTrendingCategoriesFx.pending);
    const categories = useStore(categoriesStores.$categories);
    const [selectedCategories, setSelectedCategories] = useState<SelectOptionValue[]>([]);

    useEffect(() => {
        trendingCategoriesEffects.getTrendingCategoriesFx();
        categoriesEffects.getCategoriesFx();
    }, []);

    useEffect(() => {
        if (items) {
            setSelectedCategories(items.map(trendingItem => trendingItem.category?.tagId || ''));
        }
    }, [items]);

    const categoryOptions: SelectOption[] = categories.map(category => ({
        label: `${category.emojiCode} ${category.translation?.displayName}`,
        value: category.tagId || ''
    }));

    const onChangeCategory = (categories: SelectOptionValue[]) => {
        const addCategories = () => {
            const newCategory = categories.find(
                category => category !== items?.find(item => item.category?.tagId === category)?.category?.tagId
            );
            const currentPositions = items?.map(item => item.position) || [];
            const allPositions = getArrayWithPositions(maximumCategoriesCount);
            const newPosition = allPositions.find(position => !currentPositions.includes(position));
            if (newCategory && typeof newPosition === 'number') {
                trendingCategoriesEffects.addCategoryToTrendingFx({
                    type: TrendingType.Category,
                    position: newPosition,
                    // TODO need to refactor type
                    categoryTagId: newCategory as string
                });
            }
        };

        const deleteCategories = () => {
            const categoryToDelete = items?.find(
                trendingCategory =>
                    trendingCategory.category?.tagId !==
                    categories?.find(item => item === trendingCategory.category?.tagId)
            );

            if (categoryToDelete && categoryToDelete.id) {
                trendingCategoriesEffects.deleteCategoryFromTrendingFx(categoryToDelete.id);
            }
        };

        addCategories();
        deleteCategories();
    };

    const onDragEnded = (fromIndex: number, toIndex: number) => {
        const firstElement = items?.find(item => item.position === fromIndex);
        const secondElement = items?.find(item => item.position === toIndex);
        if (firstElement) {
            trendingCategoriesEffects.swapCategoriesFx({
                firstElement: { id: firstElement.id, position: toIndex },
                secondElement: { id: secondElement?.id || '', position: fromIndex }
            });
        }
    };

    const { onDragEnd, onDragOver, onDragStart, secondPosition, firstPosition } = useDragItems(onDragEnded);

    return (
        <BlockWrapper>
            <Header>
                <Row alignCenter>
                    <Title size="-2">Categories</Title>
                    <MarginWrapper marginLeft="12px">
                        <TotalBadge count={totalRecords} />
                    </MarginWrapper>
                </Row>
                <MultiSelect
                    maximumToChoose={maximumCategoriesCount}
                    options={categoryOptions}
                    resetOption={false}
                    showArrow={false}
                    showPlaceholder={false}
                    title="Add Category"
                    values={selectedCategories}
                    onChange={onChangeCategory}
                />
            </Header>
            <ContentWrapper padding="14px 10px">
                {isLoading ? (
                    <Section alignCenter justifyCenter>
                        <Spinner />
                    </Section>
                ) : (
                    <Section>
                        {getArrayWithPositions(maximumCategoriesCount).map(position => {
                            const currentItem: YEAY.GetTrendingOverridesResponse | undefined = items?.find(
                                item => item.position === position
                            );
                            const categoryText = `${currentItem?.category?.emojiCode} ${capitalizeFirstLetter(
                                currentItem?.category?.tagId || ''
                            )}`;

                            return (
                                <MarginWrapper key={position} marginRight="8px">
                                    <DraggableWrapper
                                        draggable={!!currentItem}
                                        onDragEnd={onDragEnd}
                                        onDragOver={() => onDragOver(position)}
                                        onDragStart={() => onDragStart(position)}
                                    >
                                        {currentItem ? (
                                            <Category bordered={position === secondPosition} text={categoryText} />
                                        ) : (
                                            <DragSpot bordered={position === secondPosition}>
                                                <Text isSemibold size="-2">
                                                    {firstPosition ? 'Drag here' : ''}
                                                </Text>
                                            </DragSpot>
                                        )}
                                    </DraggableWrapper>
                                </MarginWrapper>
                            );
                        })}
                    </Section>
                )}
            </ContentWrapper>
        </BlockWrapper>
    );
};
