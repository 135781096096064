import styled from 'styled-components';
import { disableDefaultButtonStyleMixin, flexCenter } from 'v2/constants/styles/mixins';
import { Round, Sizes } from 'v2/types/styles';

type Props = Round & Sizes;

export const ClickableWrapper = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    ${({ round }) => round && 'border-radius: 50%;'};
    width: ${({ width }) => width || 'fit-content'};
    height: ${({ height }) => height || 'fit-content'};
    ${({ disabled }) => disabled && 'cursor: default;'};
    z-index: 3;
`;
