import React, { FC, ReactNode } from 'react';
import { CopyButton } from 'v1/components/common/buttons/CopyButton';
import { LinkButton } from 'v1/components/common/buttons/LinkButton';
import { NotificationBadge } from 'v1/components/common/typography/NotificationBadge/styles';
import { Column, Row, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { defaultMongoDBId, utcDefaultDate } from 'v1/constants/defaults/formats';
import { black, grey4 } from 'v1/constants/styles/colors';
import { Success, Title } from 'v1/types/data';
import { Disabled } from 'v1/types/form';
import { HorizontalPadding, MarginRightBottom, MinSizes, Sizes } from 'v1/types/styles';
import { PropertyBlockType } from 'v1/types/types';
import { formatDateISOString, formatId, getTimeFromString } from 'v1/utils/usefulFunctions';
import { DoneIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { BlockSubTitle, BlockTitle, BlockWrapper, EllipsisRow, StyledLink } from './styles';

export interface DateContentProps extends Sizes {
    children?: string;
}

export const DateContent = ({ children }: DateContentProps) =>
    children && children !== utcDefaultDate ? (
        <Row alignCenter>
            <MarginWrapper marginRight="10px">
                <BlockSubTitle>{formatDateISOString(children)}</BlockSubTitle>
            </MarginWrapper>
            <Row>
                <BlockSubTitle color={grey4}>{getTimeFromString(children)}</BlockSubTitle>
            </Row>
        </Row>
    ) : (
        <Row alignCenter>
            <BlockSubTitle color={grey4}>Unknown</BlockSubTitle>
        </Row>
    );

export interface SubtitleLinkProps extends Disabled {
    id?: string;
    linkRoute?: string;
}

export const SubtitleIdLink: FC<SubtitleLinkProps> = ({ id, linkRoute, children }) => {
    const disabled = id === defaultMongoDBId || !id;

    return linkRoute && !disabled ? (
        <StyledLink to={linkRoute + '/' + id}>{children}</StyledLink>
    ) : (
        <EllipsisRow alignCenter> {children} </EllipsisRow>
    );
};

export interface PropertyBlockProps
    extends Title,
        Partial<Success>,
        Sizes,
        MinSizes,
        MarginRightBottom,
        HorizontalPadding,
        Pick<YEAY.GetUserProfileResponse, 'isTrusted'> {
    type?: PropertyBlockType;
    notVerified?: boolean;
    copiable?: boolean;
    linkRoute?: string;
    isDate?: boolean;
    //titleUppercase?: boolean;
    backgroundColor?: string;
    fontSize?: string;
    isIdType?: boolean;
    isAvatar?: boolean;
    extra?: ReactNode;
}

export const PropertyBlock = ({
    isIdType = false,
    title,
    subtitle,
    isDate,
    success = '',
    notVerified,
    copiable,
    linkRoute,
    //titleUppercase,
    isTrusted,
    isAvatar,
    extra,
    ...props
}: PropertyBlockProps) => {
    const subtitleValue =
        subtitle && subtitle !== defaultMongoDBId ? (isIdType ? formatId(subtitle) : subtitle) : 'Empty';
    const subtitleColor = subtitle && subtitle !== defaultMongoDBId ? black : grey4;

    return (
        <BlockWrapper {...props}>
            <Column justifyBetween height="100%">
                <Section justifyBetween noWrap marginBottom="8px">
                    <BlockTitle>{title}</BlockTitle>

                    <Row noWrap>
                        {notVerified && (
                            <MarginWrapper marginRight="5px">
                                <NotificationBadge>Not verified</NotificationBadge>
                            </MarginWrapper>
                        )}

                        {copiable && <CopyButton subject={subtitle} success={success} />}

                        <MarginWrapper marginLeft="10px">
                            {linkRoute && subtitle && <LinkButton id={subtitle} linkRoute={linkRoute} />}
                        </MarginWrapper>
                    </Row>
                    {extra && <MarginWrapper marginRight="5px">{extra}</MarginWrapper>}
                </Section>

                <Section>
                    {isAvatar ? (
                        <Avatar size="24px" src={subtitle} />
                    ) : isDate ? (
                        <DateContent>{subtitle}</DateContent>
                    ) : (
                        <SubtitleIdLink id={subtitle} linkRoute={linkRoute}>
                            <BlockSubTitle color={subtitleColor} isIdType={isIdType}>
                                {subtitleValue}
                            </BlockSubTitle>
                            {isTrusted && (
                                <MarginWrapper marginLeft="8px">
                                    <DoneIcon />
                                </MarginWrapper>
                            )}
                        </SubtitleIdLink>
                    )}
                </Section>
            </Column>
        </BlockWrapper>
    );
};
