import { useStore } from 'effector-react';
import React from 'react';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { campaignManagerReportsEvents, campaignManagerReportsStores } from 'v2/stores/reports/campaign-manager';
import { ReportCampaignManagerSearchType } from '../../constants';

const { setOverwriteFilters } = campaignManagerReportsEvents;

export const ReportSearch = () => {
    const filters = useStore(campaignManagerReportsStores.$campaignManagerReportsFilters);

    const searchItems = [
        {
            option: {
                value: ReportCampaignManagerSearchType.ReportId,
                label: 'Report ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportCampaignManagerSearchType.ReportId
                })
        },
        {
            option: {
                value: ReportCampaignManagerSearchType.VideoId,
                label: 'Video ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportCampaignManagerSearchType.VideoId
                })
        },
        {
            option: {
                value: ReportCampaignManagerSearchType.PostId,
                label: 'Post ID'
            },
            onSearch: (value: string) =>
                setOverwriteFilters({
                    SearchById: value || undefined,
                    SearchByReportType: ReportCampaignManagerSearchType.PostId
                })
        }
    ];

    return <SearchInputSelect defaultSearchText={filters?.SearchById} items={searchItems} />;
};
