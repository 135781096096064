import React, { FC } from 'react';
import { PostIcon, VideosIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { CampaignManagerReportTypeState, campaignManagerReportType } from 'v2/constants/services/reports';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    type: CampaignManagerReportTypeState;
}

const Icon = ({ type }: { type: CampaignManagerReportTypeState }) => {
    switch (type) {
        case CampaignManagerReportTypeState.Video:
            return <VideosIcon color={brandColors.black} height="14px" width="12px" />;
        case CampaignManagerReportTypeState.Post:
            return <PostIcon color={brandColors.black} height="14px" width="12px" />;
        case CampaignManagerReportTypeState.None:
        default:
            return null;
    }
};

export const CampaignManagerReportType: FC<Props> = ({ type }) => (
    <Row alignCenter noWrap>
        {type && <Icon type={type || 0} />}
        <Row marginLeft="10px">
            <Text size="-2">{campaignManagerReportType[type]}</Text>
        </Row>
    </Row>
);
