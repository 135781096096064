import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { UsersDateType, dateTypeOptions } from 'v2/constants/services/users';
import { getDateType } from './utils';

type DateRangeType = [Date | undefined, Date | undefined];

const defaultDates: DateRangeType = [undefined, undefined];

interface Props extends UsersFilterProps {
    title?: string;
    defaultType?: UsersDateType;
}

export const DateFilter: FC<Props> = ({ store, defaultType, title = 'From-To' }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { filterByDate, fromUtcDateTime, toUtcDateTime } = useStore($usersFilters);

    const [dateType, setDateType] = useState<UsersDateType>(defaultType || getDateType(filterByDate));
    const [dateRange, setDateRange] = useState<DateRangeType>(defaultDates);
    const [startDate, endDate] = dateRange;

    const handleDateTypeChange = (option: SelectOption) => {
        const type = getDateType(option.value);
        setDateType(type);

        if (type === UsersDateType.None && startDate && endDate) {
            addFilters({
                filterByDate: undefined,
                fromUtcDateTime: undefined,
                toUtcDateTime: undefined
            });

            return;
        }

        if (startDate && endDate) {
            addFilters({
                filterByDate: type,
                fromUtcDateTime: startDate.toISOString(),
                toUtcDateTime: endDate.toISOString()
            });
        }
    };

    const handleDateChange = (date: DatePickerType) => {
        const newDate = date as DateRangeType;

        setDateRange(newDate);
        const [start, end] = newDate;

        if (start && end) {
            addFilters({
                filterByDate: dateType,
                fromUtcDateTime: start.toISOString(),
                toUtcDateTime: end.toISOString()
            });
        }
    };

    useEffect(() => {
        if (!defaultType) {
            setDateType(getDateType(filterByDate));
        }
    }, [defaultType, filterByDate]);

    useEffect(() => {
        const from = fromUtcDateTime ? new Date(fromUtcDateTime) : undefined;
        const to = toUtcDateTime ? new Date(toUtcDateTime) : undefined;
        setDateRange([from, to]);
    }, [fromUtcDateTime, toUtcDateTime]);

    return (
        <Row alignCenter>
            {!defaultType && (
                <Column marginRight="8px">
                    <Select
                        wide
                        options={dateTypeOptions}
                        placement="bottom-start"
                        title="Date"
                        value={dateType}
                        onChange={handleDateTypeChange}
                    />
                </Column>
            )}

            <DatePicker
                disabled={dateType === UsersDateType.None}
                endDate={endDate}
                endPlaceholder=" 2020-12-26"
                startDate={startDate}
                startPlaceholder="2020-12-25 -"
                title={title}
                onChange={handleDateChange}
            />
        </Row>
    );
};
