import React, { ChangeEvent, FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { StyledInput } from './styles';

enum RangeFieldName {
    From = 'from',
    To = 'to'
}

export type RangeInputValueType = number | undefined;
export type RangeInputValuesType = [RangeInputValueType, RangeInputValueType];

interface Props {
    min?: number;
    max?: number;
    error?: string;
    values?: RangeInputValuesType;
    onChange?: ([from, to]: RangeInputValuesType) => void;
}

export const RangeInputs: FC<Props> = ({ min, max, error, values = [undefined, undefined], onChange }) => {
    const [from, to] = values;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return;
        }

        const { name, value } = e.target;
        const newValue = value.trim() === '' ? undefined : Number(value);
        const range: RangeInputValuesType = name === RangeFieldName.From ? [newValue, to] : [from, newValue];

        onChange(range);
    };

    return (
        <Column noWrap width="100%">
            <Section alignCenter justifyBetween noWrap>
                <Row paddingRight="8px">
                    <StyledInput
                        hasError={!!error}
                        max={max}
                        min={min}
                        name={RangeFieldName.From}
                        type="number"
                        value={from}
                        onChange={handleChange}
                    />
                </Row>
                <Row alignCenter justifyCenter paddingLeft="10px" paddingRight="10px">
                    <Text alignTextCenter size="-2">
                        to
                    </Text>
                </Row>
                <Row paddingLeft="8px">
                    <StyledInput
                        hasError={!!error}
                        max={max}
                        min={min}
                        name={RangeFieldName.To}
                        type="number"
                        value={to}
                        onChange={handleChange}
                    />
                </Row>
            </Section>

            {error && (
                <Section marginTop="4px">
                    <Text color={brandColors.red} size="-2">
                        {error}
                    </Text>
                </Section>
            )}
        </Column>
    );
};
