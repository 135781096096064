import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ResetSearchButton } from 'v1/components/common/buttons/ResetButton';
import { DateRangePicker } from 'v1/components/common/inputs/DateRangePicker';
import { SearchInput } from 'v1/components/common/inputs/SearchInput';
import { Select } from 'v1/components/common/inputs/Select';
import { SortSelector } from 'v1/components/common/inputs/SortSelector';
import { Footer } from 'v1/components/grid/Footer';
import { Pagination } from 'v1/components/layouts/Pagination';
import { SearchWrapperLayout } from 'v1/components/layouts/blocks/SearchWrapperLayout/styles';
import {
    searchUserByEmailParameter,
    searchUserByIdParameter,
    searchUserByMobileNumberParameter
} from 'v1/components/layouts/filterLayouts/DeletedUsersFilterLayout/constants';
import {
    DateRangePickerWrapper,
    FiltersWrapper,
    StyledRow,
    Wrapper
} from 'v1/components/layouts/filterLayouts/DeletedUsersFilterLayout/styles';
import { FlexGrow } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { defaultLimit, defaultPage } from 'v1/constants/defaults/filterSettings';
import { ReasonDeletion, Roles, defaultDeletedUsersValues } from 'v1/constants/defaults/users';
import { sortModeTagsValues } from 'v1/constants/filters/sorts';
import { mongoDbObjectIdRegExp } from 'v1/constants/regularExpressions';
import { filterMargin, md } from 'v1/constants/styles/sizes';
import { useQueryParams } from 'v1/hooks/queryParams';
import { deletedUsersEvents, deletedUsersStores } from 'v1/stores/users/deletedUsers';
import { SearchParameters, TotalRecords } from 'v1/types/data';
import { emailSearchPlaceholder, mobileNumberSearchPlaceholder, userIdSearchPlaceholder } from 'v2/constants/common';
import { emailRegExp, mobileNumberRegExp } from 'v2/constants/reg-exp';

const { setId, updateValues, setSortPostfix } = deletedUsersEvents;

interface DeletedUsersQueryParams extends YEAY.QueryDeletedUsersRequest {}

const updateQueryValues = ({ deletedUserId, ...params }: DeletedUsersQueryParams) => {
    if (deletedUserId) {
        setId(deletedUserId);
        updateValues({ ...defaultDeletedUsersValues, deletedUserId });
    } else {
        setId('');
        updateValues(params);
    }
};

interface Props extends TotalRecords {
    trendingUserFilter?: boolean;
}

export const DeletedUsersFilterLayout: FC<Props> = ({ totalRecords, children }) => {
    const { pageIndex, limit, fromUtcDeleted, toUtcDeleted, deletionReasons, email, mobileNumber } = useStore(
        deletedUsersStores.values
    );

    const sortPostfix = useStore(deletedUsersStores.sortPostfix);

    const defaultId = useStore(deletedUsersStores.getRequestId);
    const [queryParams, setQueryParams] = useQueryParams<DeletedUsersQueryParams>(updateQueryValues);
    const deletionReasonsSelectors = Object.values(ReasonDeletion);
    const isMobile = useMediaQuery({ query: `(max-width: ${md})` });

    const onIdSearch = (id: string) => {
        setId(id);
        if (id) {
            updateValues({ ...queryParams, deletedUserId: id, pageIndex: defaultPage });
        } else {
            updateValues({ ...queryParams, deletedUserId: undefined });
        }
    };

    const onEmailSearch = (email: string) => {
        setId('');

        if (email) {
            updateValues({ ...queryParams, email, pageIndex: defaultPage });
        } else {
            updateValues({ ...queryParams, email: undefined });
        }
    };

    const onMobileNumberSearch = (mobileNumber: string) => {
        setId('');
        if (mobileNumber) {
            updateValues({ ...queryParams, mobileNumber, pageIndex: defaultPage });
        } else {
            updateValues({ ...queryParams, mobileNumber: undefined });
        }
    };

    const onSortModeChange = (isAscending: boolean) => {
        const mode = isAscending ? sortModeTagsValues[0] : sortModeTagsValues[1];
        setSortPostfix(mode);
        updateValues({
            ...queryParams,
            sortByUtcDeletedAsc: isAscending
        });
    };

    const onDateRangeClick = (dateRange: [string, string]) =>
        updateValues({
            ...queryParams,
            fromUtcDeleted: dateRange[0],
            toUtcDeleted: dateRange[1]
        });

    const onFilterByReasonsDeletion = (index: number) => {
        updateValues({
            ...queryParams,
            deletionReasons: index === 0 ? undefined : [index as YEAY.UserDeletionReason]
        });
    };

    const resetFilters = () => {
        setId('');
        updateValues(defaultDeletedUsersValues);
    };

    const onCurrentPageChange = (page: number, pageSize: number | undefined) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const searchParameters: SearchParameters[] = [
        {
            searchBy: searchUserByIdParameter,
            defaultValue: defaultId,
            placeholder: userIdSearchPlaceholder,
            onSearch: onIdSearch,
            regExp: mongoDbObjectIdRegExp
        },
        {
            searchBy: searchUserByEmailParameter,
            defaultValue: email,
            placeholder: emailSearchPlaceholder,
            onSearch: onEmailSearch,
            accessFilter: [Roles.SuperAdministrator],
            regExp: emailRegExp
        },
        {
            searchBy: searchUserByMobileNumberParameter,
            defaultValue: mobileNumber,
            placeholder: mobileNumberSearchPlaceholder,
            onSearch: onMobileNumberSearch,
            accessFilter: [Roles.SuperAdministrator],
            regExp: mobileNumberRegExp
        }
    ];

    useEffect(() => {
        setQueryParams({
            pageIndex,
            limit,
            deletedUserId: defaultId !== '' ? defaultId : undefined
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, limit, defaultId]);

    return (
        <>
            <SearchWrapperLayout alignCenter>
                <FlexGrow flexGrow="1" marginRight={filterMargin}>
                    <SearchInput searchParameters={searchParameters} selectorWidth={isMobile ? '100%' : undefined} />
                </FlexGrow>
            </SearchWrapperLayout>
            <FiltersWrapper>
                <Wrapper>
                    <Select
                        defaultIndex={deletionReasons ? deletionReasons[0] : 0}
                        selector={deletionReasonsSelectors}
                        title="Filter by reason for deletion"
                        width={isMobile ? '100% ' : '182px'}
                        onChange={onFilterByReasonsDeletion}
                    />
                </Wrapper>
                <DateRangePickerWrapper>
                    <DateRangePicker
                        dateRange={[fromUtcDeleted || '', toUtcDeleted || '']}
                        onChange={onDateRangeClick}
                    />
                </DateRangePickerWrapper>

                <StyledRow>
                    <MarginWrapper marginRight="30px">
                        <SortSelector type={sortPostfix} onChange={onSortModeChange} />
                    </MarginWrapper>
                    <ResetSearchButton onClick={resetFilters} />
                </StyledRow>
            </FiltersWrapper>

            {children}

            <Footer>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    pagesLimit={100}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Footer>
        </>
    );
};
