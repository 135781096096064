import React, { Key } from 'react';
import { Title } from 'v2/components/ui/typography/Title';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { noop } from 'v2/constants/functions';
import { TabsButton, TabsButtons, TabsWrapper } from './styles';
import { TabProps } from './types';

interface Props<T extends Key> {
    tabs: Array<TabProps<T>>;
    onChange?: (value: T) => void;
    activeTab: T;
}

export const Tabs = <T extends Key>({ tabs, onChange = noop, activeTab }: Props<T>) => {
    const activeTabData = tabs.find(item => !item.hidden && item.id === activeTab);

    return (
        <Column noWrap width="100%">
            <TabsWrapper>
                <TabsButtons>
                    {tabs
                        .filter(item => !item.hidden)
                        .map((tab: TabProps<T>) => (
                            <TabsButton
                                key={tab.id}
                                isActive={activeTab === tab.id}
                                width={tab.width}
                                onClick={() => onChange(tab.id)}
                            >
                                <Title alignTextCenter color="inherit" size="-2">
                                    {tab.title}
                                </Title>
                            </TabsButton>
                        ))}
                </TabsButtons>
            </TabsWrapper>

            {activeTabData && (
                <Column noWrap role="tabpanel" width="100%">
                    {activeTabData.content}
                </Column>
            )}
        </Column>
    );
};
