import queryString from 'query-string';
import ycmAxios from './yecm.axios';

export const getCollaborationsRequests = (
    collaborationId: string,
    params?: YecmPaths.AdminCollaboration$CollaborationIdParticipants.Get.QueryParameters
) =>
    ycmAxios<YECM.CollaborationsResponseApiResponse>({
        url: `/collaborations/${collaborationId}/participations`,
        method: 'GET',
        params
    });

export const getCollaboration = (id: string) =>
    ycmAxios<YECM.CollaborationDetailedResponseApiResponse>({
        url: `/collaborations/${id}`,
        method: 'GET'
    });

export const deleteCollaborationRequest = (collaborationId: string, userId: string) =>
    ycmAxios<YECM.ApiResponse>({
        url: `/collaborations/${collaborationId}/participations/${userId}/reject`
    });

export const getSubmissions = (
    collaborationId: string,
    params?: YecmPaths.Collaborations$CollaborationIdMediaAssets.Get.QueryParameters
) =>
    ycmAxios<YECM.MediaAssetsResponseApiResponse>({
        url: `/collaborations/${collaborationId}/media-assets`,
        method: 'GET',
        params,
        paramsSerializer: params => queryString.stringify(params)
    });

export const getCollaborationEnrollments = (
    collaborationId: string,
    params?: YecmPaths.AdminCollaboration$CollaborationIdParticipants.Get.QueryParameters
) =>
    ycmAxios<YECM.ParticipationsResponseApiResponse>({
        url: `/admin/collaboration/${collaborationId}/participants`,
        method: 'GET',
        params,
        paramsSerializer: params => queryString.stringify(params)
    });

export const getCollaborationEngagements = (params?: YecmPaths.CollaborationsEngagements.Get.QueryParameters) =>
    ycmAxios<YECM.CollaborationEngagementsResponseApiResponse>({
        url: '/collaborations/engagements',
        method: 'GET',
        params
    });

export const getCollaborationShippings = (
    collaborationId: string,
    params?: YecmPaths.AdminShippings$CollaborationId.Get.QueryParameters
) =>
    ycmAxios<YECM.ProductShippingsResponseApiResponse>({
        url: `/admin/shippings/${collaborationId}`,
        method: 'GET',
        params
    });

export const confirmCollaborationShipping = (
    userId: string,
    collaborationId: string,
    data: YECM.ConfirmShipmentRequest
) =>
    ycmAxios<YECM.ApiResponse>({
        url: `/admin/${userId}/confirm/shipping/${collaborationId}`,
        method: 'POST',
        data
    });

export const downloadShippingsCsv = (collaborationId: string) =>
    ycmAxios<string>({
        url: `/collaborations/shippings/report/${collaborationId}`,
        method: 'GET',
        responseType: 'blob'
    });

export const downloadCollaborationEngagements = (
    params: YecmPaths.CollaborationsEngagementsDownload.Get.QueryParameters
) =>
    ycmAxios<string>({
        url: `/collaborations/engagements/download`,
        method: 'GET',
        params
    });
