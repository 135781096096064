import React, { FC, MouseEvent, ReactNode } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from 'v2/assets/icons';
import { notify } from 'v2/components/ui/Toasts';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { FlexBooleanAlignment, NoWrap, Padding } from 'v2/types/styles';
import { BlockWrapper, TitleWrapper } from './styles';

export interface PropertyLayoutProps extends Padding, NoWrap, FlexBooleanAlignment {
    title?: string;
    direction?: 'row' | 'column';
    extra?: ReactNode;
    textCopy?: string;
}

export const PropertyLayout: FC<PropertyLayoutProps> = ({
    title,
    extra,
    textCopy,
    children,
    noWrap,
    direction = 'column',
    alignCenter,
    ...paddingProps
}) => {
    const isColumn = direction === 'column';

    const handleCopy = (_text: string, result: boolean) => {
        if (result) {
            notify('Copied');
        } else {
            notify('Error Processing Action', 'error');
        }
    };

    const handleClickIconCopy = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <BlockWrapper
            alignCenter={alignCenter}
            direction={direction}
            height={isColumn ? '100%' : undefined}
            justifyBetween={isColumn}
            noWrap={noWrap}
            {...paddingProps}
        >
            {title && (
                <TitleWrapper
                    alignCenter={!!extra && isColumn}
                    justifyBetween={!!extra && isColumn}
                    noWrap={isColumn}
                    width={isColumn ? '100%' : undefined}
                >
                    <Title noTextWrap size="-2">
                        {title}
                    </Title>
                    {extra}
                </TitleWrapper>
            )}
            <Section alignCenter noWrap={noWrap}>
                {children}
                {textCopy && (
                    <Row alignCenter noWrap marginLeft="8px">
                        <CopyToClipboard text={textCopy} onCopy={handleCopy}>
                            <IconButton size="10px" onClick={handleClickIconCopy}>
                                <CopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </Row>
                )}
            </Section>
        </BlockWrapper>
    );
};
