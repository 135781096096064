import styled from 'styled-components';
import wavePath from 'v2/assets/images/wave.png';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    padding: 24px;
    background-color: ${brandColors.grey};
    overflow-y: auto;
`;

export const ContentWrapper = styled(Column)`
    width: 100%;
    max-width: 500px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 64px;
    padding-bottom: 32px;
    border-radius: 16px;
    background-color: ${brandColors.white};
    background-image: url(${wavePath});
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
`;

export const Img = styled.img`
    width: 60px;
    height: 60px;
`;
