import { defaultPage } from 'v1/constants/defaults/filterSettings';
import { getDateBeforeAndReturnISO } from 'v1/utils/parsers';

export const defaultStatisticsValues: YEAY.QueryVideoStatisticsRequest = {
    pageIndex: defaultPage,
    limit: 100,
    returnQueryCount: true,
    utcStart: getDateBeforeAndReturnISO(7),
    utcEnd: getDateBeforeAndReturnISO(-1),
    sortByViewCountAsc: false,
    isUserDisabled: false,
    isVideoDeleted: false
};
