import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Table } from 'v2/components/ui/Table';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { InactiveUsersFilters } from 'v2/pages/InactiveUsers/InactiveUsersFilters';
import { inactiveUsersEffects, inactiveUsersEvents, inactiveUsersStores } from 'v2/stores/inactive-users';
import { inactiveUsersTableColumns } from './constants';
import { ContentWrapper } from './styles';
import { getInactiveUsersTableData } from './utils';

export const InactiveUsers = () => {
    const inactiveUsers = useStore(inactiveUsersStores.$inactiveUsers);
    const isLoading = useStore(inactiveUsersEffects.getInactiveUsersFx.pending);
    const tableData = getInactiveUsersTableData(inactiveUsers?.items);
    const hasNext =
        inactiveUsers?.currentPageIndex !== undefined &&
        inactiveUsers?.totalPages !== undefined &&
        inactiveUsers?.currentPageIndex + 1 < inactiveUsers?.totalPages;

    useEffect(
        () => () => {
            inactiveUsersEvents.resetInactiveUsersStores();
        },
        []
    );

    const loadMore = () => {
        if (hasNext) {
            const newPageIndex = (inactiveUsers?.currentPageIndex || 0) + 1;
            inactiveUsersEvents.addFilters({ pageIndex: newPageIndex });
        }
    };

    return (
        <MainLayout>
            <InactiveUsersFilters totalCount={inactiveUsers?.totalRecords || 0} />
            <ContentWrapper>
                <Table
                    cellCenter
                    columns={inactiveUsersTableColumns}
                    data={tableData}
                    emptyMessage="No Inactive Users Found"
                    isLoading={isLoading}
                />
                {!isLoading && hasNext && (
                    <Section marginTop="16px">
                        <LoadMoreSection onClick={loadMore} />
                    </Section>
                )}
            </ContentWrapper>
        </MainLayout>
    );
};
