import styled, { css } from 'styled-components';
import { BadgeProps } from 'v2/components/ui/Badge';
import { Text } from 'v2/components/ui/typography/Text';
import { CollaborationStatusesUi } from 'v2/constants/services/collaborations';
import { brandColors, grey, orange, textColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const getTagStyle = (status?: number) => {
    switch (status) {
        case CollaborationStatusesUi.ActiveOpen:
            return css`
                background-color: ${brandColors.green};
                color: ${textColors.main};
                text-transform: uppercase;
            `;
        case CollaborationStatusesUi.ActiveClosed:
            return css`
                background-color: ${orange[800]};
                color: ${brandColors.white};
                text-transform: uppercase;
            `;
        case CollaborationStatusesUi.Ended:
            return css`
                background-color: ${grey[800]};
                color: ${brandColors.white};
            `;
        case CollaborationStatusesUi.Published:
            return css`
                background-color: ${grey[500]};
                color: ${brandColors.white};
            `;
        default:
            return css`
                background-color: ${grey[500]};
                color: ${brandColors.white};
            `;
    }
};

export const BadgeWrapper = styled(Text)<BadgeProps>`
    text-align: center;
    width: fit-content;
    display: inline-flex;
    padding: 2px 8px;
    border-radius: 4px;
    ${({ isUppercase }) => isUppercase && 'text-transform: uppercase'};
    ${({ status }) => getTagStyle(status)};
`;

export const TotalBadgeWrapper = styled.div`
    ${flexCenter};
    width: fit-content;
    padding: 2px 8px;
    background-color: ${brandColors.black};
    border-radius: 50px;
    text-align: center;
`;
