import React, { FC } from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Noop } from 'v2/types/types';
import { SectionButton, Wrapper } from './styles';

interface Props {
    onClick: Noop;
    disabled?: boolean;
}

export const LoadMoreSection: FC<Props> = ({ onClick, disabled }) => (
    <Wrapper>
        <SectionButton maxWidth="280px">
            <Button wide buttonType="text-black" disabled={disabled} onClick={onClick}>
                Load More
            </Button>
        </SectionButton>
    </Wrapper>
);
