import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { defaultCollaborationsQueryParams } from 'v2/constants/services/collaborations';
import { brandDetailsEvents } from 'v2/stores/brands/details';
import { brandUsersEvents } from 'v2/stores/brands/details/users';
import { initializeCollaborationsStore } from 'v2/stores/collaborations';
import { Id } from 'v2/types/data';
import { ContentWrapper } from './styles';
import { BrandCollaborationsTab } from './tabs/BrandCollaborationsTab';
import { BrandInfoTab } from './tabs/BrandInfoTab';
import { BrandUsersTab } from './tabs/BrandUsersTab';

enum BrandPageTab {
    Info,
    Users,
    Collaborations
}

export const Brand = () => {
    const { id } = useParams<Partial<Id>>();

    const brandCollaborationsPageStore = initializeCollaborationsStore({
        ...defaultCollaborationsQueryParams,
        organizationId: id
    });

    const [activeTab, setActiveTab] = useState(BrandPageTab.Info);

    const handleChangeTab = (tab: BrandPageTab) => {
        setActiveTab(tab);
    };

    useEffect(
        () => () => {
            brandDetailsEvents.resetStores();
            brandUsersEvents.resetStores();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (!id) {
        return null;
    }

    return (
        <MainLayout>
            <DetailsPageLayout>
                <Tabs
                    activeTab={activeTab}
                    tabs={[
                        {
                            id: BrandPageTab.Info,
                            title: 'BRAND INFO',
                            content: (
                                <ContentWrapper>
                                    <BrandInfoTab brandId={id} />
                                </ContentWrapper>
                            )
                        },
                        {
                            id: BrandPageTab.Users,
                            title: 'BRAND USERS',
                            content: (
                                <ContentWrapper>
                                    <BrandUsersTab brandId={id} />
                                </ContentWrapper>
                            )
                        },
                        {
                            id: BrandPageTab.Collaborations,
                            title: 'BRAND COLLABS',
                            content: (
                                <ContentWrapper>
                                    <BrandCollaborationsTab store={brandCollaborationsPageStore} />
                                </ContentWrapper>
                            )
                        }
                    ]}
                    onChange={handleChangeTab}
                />
            </DetailsPageLayout>
        </MainLayout>
    );
};
