import styled, { css } from 'styled-components';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { buttonEffectMixin } from 'v1/constants/styles/mixins';
import { Sizes } from 'v1/types/styles';

import { SimpleButton } from 'v1/components/common/buttons/SimpleButton/styles';
import { blue, lightSkyBlue } from 'v1/constants/styles/colors';
import { copyButtonDiameter } from 'v1/constants/styles/sizes';

interface ButtonImgProps extends Sizes {
    disabled?: boolean;
}

interface CopyButtonWrapperProps extends Sizes {
    pointer?: boolean;
    disabled?: boolean;
}

export const CopyButtonWrapper = styled.div<CopyButtonWrapperProps>`
    height: ${({ height }) => height || copyButtonDiameter};
    width: ${({ width }) => width || copyButtonDiameter};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
    ${({ disabled }) =>
        !disabled
            ? buttonEffectMixin
            : css`
                  cursor: not-allowed;
              `};
    path {
        stroke: ${blue};
    }
    &:hover {
        opacity: 1;

        rect {
            fill: ${lightSkyBlue};
        }
    }
`;

export const ButtonImg = styled(CustomImg)<ButtonImgProps>`
    height: ${({ height }) => height || copyButtonDiameter};
    width: ${({ width }) => width || copyButtonDiameter};

    ${({ disabled }) => disabled && 'opacity: 0.4'};

    ${({ disabled }) =>
        !disabled
            ? buttonEffectMixin
            : css`
                  cursor: not-allowed;
              `};
`;

export const Button = styled(SimpleButton)`
    ${({ disabled }) => disabled && 'opacity: 0.4'};

    ${({ disabled }) =>
        !disabled
            ? buttonEffectMixin
            : css`
                  cursor: not-allowed;
              `};
`;
