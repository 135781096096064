import styled, { css } from 'styled-components';
import { AlertProps } from 'v2/components/ui/Alert';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, textColors } from 'v2/constants/styles/colors';

const getTypeStyles = ({ type }: Pick<AlertProps, 'type'>) => {
    switch (type) {
        case 'success':
        default:
            return css`
                color: ${textColors.main};
                background-color: ${brandColors.green};
            `;
        case 'error':
            return css`
                color: ${brandColors.white};
                background-color: ${brandColors.red};
            `;
    }
};

export const AlertWrapper = styled(Section)<Pick<AlertProps, 'type'>>`
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    padding: 10px 100px;
    border-radius: 8px;
    ${props => getTypeStyles(props)};
    cursor: pointer;
`;
