import { SortState } from 'v2/components/ui/buttons/SortButton/constants';

export const getSortOrderState = (sortIsAsc?: string | null) => {
    const newSort = sortIsAsc?.split('+');

    if (!newSort) {
        return SortState.Asc;
    }

    return newSort?.[1] === 'asc' ? SortState.Asc : SortState.Desc;
};

export const getNextSortValue = (currentOrder: SortState) => {
    switch (currentOrder) {
        case SortState.Desc:
            return 'asc';
        case SortState.None:
            return 'asc';
        case SortState.Asc:
            return 'desc';
    }
};
