import React, { FC } from 'react';
import { EyesIcon, StarIcon } from 'v2/assets/icons';
import { TargetingBlock } from 'v2/components/cards/SingleCollaborationCard/TargetingBlock';
import { EmojiProperty } from 'v2/components/cards/UserCard/EmojiProperty';
import { CollaborationTooltip } from 'v2/components/common/CollaborationTooltip';
import { NetworkReward } from 'v2/components/common/NetworkReward';
import { Badge } from 'v2/components/ui/Badge';
import { Category } from 'v2/components/ui/Category';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { TargetAudience, collaborationStatusTitles } from 'v2/constants/services/collaborations';
import { CardWrapper, EllipsesText, GridWrapper, ImageInfo, ImageWrapper, RewardWrapper } from './styles';

interface Props {
    data: YECM.CollaborationDetailedResponse;
    withAction?: boolean;
}

export const SingleCollaborationCard: FC<Props> = ({ data, withAction = false }) => {
    const {
        coverImageUrl,
        name,
        categoryInfo,
        organizationInfo,
        websiteUrl,
        targetAudience,
        autoApproveMedia,
        autoApproveParticipants,
        state,
        participationLimits,
        mediaAssetsCount,
        budget,
        networks,
        utcStart,
        shippingDetails,
        utcCreated,
        utcEnd,
        id
    } = data;

    const isApproveBrandCreators =
        !autoApproveParticipants && autoApproveMedia && targetAudience === TargetAudience.Trusted;
    const isApprove = autoApproveParticipants && autoApproveMedia && targetAudience === TargetAudience.Trusted;

    // TODO add logic review report
    const handleReviewReport = () => {
        console.log('handleReviewReport');
    };

    return (
        <CardWrapper>
            <ImageInfo alignCenter>
                <Row marginBottom="16px">
                    <ImageWrapper borderRadius="16px" height="190px" image={coverImageUrl || ''} width="124px" />
                </Row>

                {isApproveBrandCreators && (
                    <Row noWrap marginBottom="12px">
                        <CollaborationTooltip
                            icon={<EyesIcon height={18} width={20} />}
                            placement="topRight"
                            text="Brand set approved creators only"
                        />

                        <CollaborationTooltip
                            icon={<StarIcon height={20} width={20} />}
                            text="Brand set verified creators only"
                        />
                    </Row>
                )}

                {isApprove && (
                    <Row noWrap marginBottom="12px">
                        <CollaborationTooltip
                            icon={<StarIcon height={20} width={20} />}
                            text="Brand set verified creators only"
                        />
                    </Row>
                )}

                {withAction && (
                    <Section marginBottom="12px">
                        <Button disabled={false} isEllipsis={false} onClick={handleReviewReport}>
                            Review Report
                        </Button>
                    </Section>
                )}

                {categoryInfo?.categoryEmoji && categoryInfo.categoryId && (
                    <Category text={`${categoryInfo.categoryEmoji} ${categoryInfo.categoryId}`} />
                )}

                {shippingDetails && (
                    <Section marginTop="12px">
                        <EmojiProperty isSemibold emoji="📦" value="Product shipping" />
                    </Section>
                )}
            </ImageInfo>

            <Column width="100%">
                <GridWrapper>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout textCopy={id} title="Collab ID">
                            <Text size="-2">{id}</Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Collaboration Status">
                            {state && (
                                <Badge isUppercase status={state}>
                                    {collaborationStatusTitles[state]}
                                </Badge>
                            )}
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Collaboration Name">
                            <EllipsesText size="-2">{name}</EllipsesText>
                        </PropertyLayout>
                    </SectionBorder>

                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout textCopy={organizationInfo?.id} title="Brand ID">
                            <Text size="-2">{organizationInfo?.id}</Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Brand Name">
                            <EllipsesText size="-2">{organizationInfo?.title || '-'}</EllipsesText>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout textCopy={websiteUrl || ''} title="Website">
                            <Text size="-2">{websiteUrl}</Text>
                        </PropertyLayout>
                    </SectionBorder>

                    {utcCreated && (
                        <SectionBorder borderBottom borderLeft>
                            <DateProperty date={utcCreated} title="Creation Date" />
                        </SectionBorder>
                    )}
                    {utcStart && (
                        <SectionBorder borderBottom borderLeft>
                            <DateProperty date={utcStart} title="Accept From" />
                        </SectionBorder>
                    )}
                    {utcEnd && (
                        <SectionBorder borderBottom borderLeft>
                            <DateProperty date={utcEnd} title="Accept Until" />
                        </SectionBorder>
                    )}

                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Total Collaboration Budget">
                            <Text size="-2">{budget?.priceWithFee}</Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Total Submissions">
                            <Text size="-2">{mediaAssetsCount || 0}</Text>
                        </PropertyLayout>
                    </SectionBorder>
                    <SectionBorder borderBottom borderLeft>
                        <PropertyLayout title="Participation Limit">
                            <Text size="-2">{participationLimits?.enrolled}</Text>
                        </PropertyLayout>
                    </SectionBorder>
                </GridWrapper>

                <TargetingBlock data={data} />

                <SectionBorder>
                    {networks && (
                        <SectionBorder borderLeft>
                            <PropertyLayout title="Rewards">
                                {networks.map((item, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <RewardWrapper key={index}>
                                        <NetworkReward count={item.rewardOnSubmitted} type={item.postType} />
                                    </RewardWrapper>
                                ))}
                            </PropertyLayout>
                        </SectionBorder>
                    )}
                </SectionBorder>
            </Column>
        </CardWrapper>
    );
};
