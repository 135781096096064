import ycmAxios from './yecm.axios';

export const brandsQuery = (data: YECM.QueryOrganizationRequest) =>
    ycmAxios<YECM.OrganizationsResponseWrapped>({
        url: '/organizations/query',
        data
    });

export const getBrandById = (data: YECM.GetOrganizationRequest) =>
    ycmAxios<YECM.OrganizationResponseWrapped>({
        url: '/organizations/get',
        data
    });

export const brandUsersQuery = (data: YECM.OrganizationQueryUsersRequest) =>
    ycmAxios<YECM.OrganizationQueryUsersResponseApiResponse>({
        url: '/organization/users/query',
        data
    });
