import { sample } from 'effector';
import { UserRole } from 'v2/constants/services/users';
import { singleUserEvents, singleUserStores } from 'v2/stores/single-user';
import { agentEffects, agentEvents, agentStores } from './index';

sample({
    clock: agentEffects.addAgentRoleFx.done,
    source: singleUserStores.$user,
    fn: user => ({ ...user, roles: [...(user?.roles || []), UserRole.Agent] }),
    target: singleUserEvents.setUser
});

sample({
    clock: agentEffects.deleteAgentRoleFx.done,
    source: singleUserStores.$user,
    fn: user => ({ ...user, roles: user?.roles?.filter(role => role !== UserRole.Agent) }),
    target: singleUserEvents.setUser
});

sample({
    clock: agentEffects.getPotentialAgentInfoFx.done,
    fn: clock => ({ data: { userId: clock.result?.userId }, agentId: clock.result?.data?.id || '' }),
    target: agentEffects.addMembersToAgentFx
});

sample({
    clock: agentEffects.getMemberInfoAboutAgentFx.done,
    fn: clock => clock.result?.data?.agentInfo?.userId || '',
    target: agentEffects.getMemberAgentInfoFx
});

sample({
    clock: agentEffects.removeMemberFromAgentFx.done,
    fn: clock => clock.params.userId || '',
    target: agentEffects.getMemberInfoAboutAgentFx
});

sample({
    clock: agentEffects.removeMemberFromAgentFx.done,
    source: agentStores.$currentUserAgentInfo,
    fn: source => source?.userId || '',
    target: agentEffects.getUserAgentInfoFx
});

sample({
    clock: agentEvents.setAgentMembersParams,
    source: agentStores.$agentMembersParams,
    fn: (source, clock) => ({ ...source, ...clock }),
    target: agentEffects.getAgentMembersFx
});

sample({
    clock: agentEffects.addMembersToAgentFx.done,
    source: agentStores.$currentUserAgentInfo,
    fn: source => source?.userId || '',
    target: agentEffects.getUserAgentInfoFx
});

sample({
    clock: agentEffects.updateAgentInfoFx.done,
    source: agentStores.$currentUserAgentInfo,
    fn: source => source?.userId || '',
    target: agentEffects.getUserAgentInfoFx
});
