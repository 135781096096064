import React, { FC } from 'react';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';

interface Props {
    title?: string;
    borderBottom?: boolean;
    borderTop?: boolean;
}

export const ContentLayout: FC<Props> = ({ title, children, borderBottom, borderTop }) => (
    <SectionBorder borderBottom={borderBottom} borderTop={borderTop} padding="8px 16px">
        <Column>
            <Row marginBottom="14px">
                <Title size="-2">{title}</Title>
            </Row>
            {children}
        </Column>
    </SectionBorder>
);
