import React from 'react';
import { Avatar } from 'v2/components/ui/Avatar';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { StatusBadge } from 'v2/pages/Collaboration/Enrollments/StatusBadge';
import { getCompactNumber } from 'v2/utils/formats';

export const prepareEngagementItems = (items?: YECM.CollaborationEngagementResponse[] | null) =>
    (items || []).map(item => ({
        key: `${item.userId}-${item.networkId}`,
        username: (
            <Row alignCenter noWrap>
                <Avatar size="32px" src={item.profileImageUrl || ''} />
                <Column marginLeft="8px">
                    <Text isSemibold size="-2">
                        @{item.username}
                    </Text>
                </Column>
            </Row>
        ),
        post: (
            <Section justifyCenter>
                <StatusBadge networkName={item.networkName || undefined} postUrl={item.postUri || undefined} />
            </Section>
        ),
        views: (
            <Text alignTextCenter isSemibold size="-2">
                {getCompactNumber(item.views)}
            </Text>
        ),
        likes: (
            <Text alignTextCenter isSemibold size="-2">
                {getCompactNumber(item.likes)}
            </Text>
        ),
        shares: (
            <Text alignTextCenter isSemibold size="-2">
                {getCompactNumber(item.shares)}
            </Text>
        )
    }));
