import { useStore } from 'effector-react';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { InputField } from 'v2/components/form/InputField';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { editWebsiteModal } from 'v2/stores/initialize-modals';
import { videoActionEffects } from 'v2/stores/single-video/actions';
import { EditWebsiteModalProps } from 'v2/types/modals';
import { Noop } from 'v2/types/types';
import { editWebsiteSchema, getInitialValues } from './constants';

interface Props extends EditWebsiteModalProps {
    onClose: Noop;
}

const EditWebsiteModalInner: FC<Props> = ({ onClose, ownerId, videoId, currentUrl }) => {
    const formik = useFormik({
        initialValues: getInitialValues(currentUrl),
        validationSchema: editWebsiteSchema,
        onSubmit: values => {
            videoActionEffects.editWebsiteFx({
                ...values,
                ownerId,
                videoId
            });
        }
    });

    return (
        <ModalLayout
            submitDisabled={!formik.dirty || !formik.isValid}
            submitText="Save"
            title="Edit Website"
            onCancel={onClose}
            onSubmit={formik.handleSubmit}
        >
            <FormikProvider value={formik}>
                <Form>
                    <Section paddingBottom="48px" paddingTop="12px">
                        <InputField wide name="websiteUrl" placeholder="Enter Website URL" />
                    </Section>
                </Form>
            </FormikProvider>
        </ModalLayout>
    );
};

export const EditWebsiteModal = () => {
    const { visible } = useStore(editWebsiteModal.$modal);
    const state = useStore(editWebsiteModal.$state);

    const handleClose = () => editWebsiteModal.closeModal();

    return (
        <ModalWrapper visible={visible} onClose={handleClose}>
            <EditWebsiteModalInner onClose={handleClose} {...state} />
        </ModalWrapper>
    );
};
