import { Form } from 'formik';
import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: ${brandColors.grey};
`;

export const FormWrapper = styled(Column)`
    width: 100%;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 24px;
    border-radius: 16px;
    background-color: ${brandColors.white};
`;

export const FormikForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
`;
