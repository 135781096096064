import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ItemsAbsoluteWrapper, ItemWrapper } from 'v1/components/common/inputs/Select/styles';
import { Span } from 'v1/components/common/typography/Span/styles';
import { PropertyBlockProps } from 'v1/components/layouts/blocks/PropertyBlock';
import { propertyBlockMarginBottom } from 'v1/components/layouts/blocks/PropertyBlock/constants';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { Row, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { black, brightRed, grey3 } from 'v1/constants/styles/colors';
import { disableDefaultButtonStyleMixin, ellipsisMixin } from 'v1/constants/styles/mixins';
import { transitionTime } from 'v1/constants/styles/others';
import { xs } from 'v1/constants/styles/sizes';
import { Pointer } from 'v1/types/styles';

export const BlockWrapper = styled(ContentWrapper)<PropertyBlockProps>`
    position: relative;
    min-height: ${({ minHeight }) => minHeight || '65px'};
    min-width: 112px;
    ${({ width }) => width && `width: ${width}`};
    padding: 8px ${({ horizontalPadding }) => horizontalPadding || '8px'};
    margin-bottom: ${({ marginBottom }) => marginBottom || propertyBlockMarginBottom};
    background-color: ${({ backgroundColor }) => backgroundColor || grey3};
`;
export const BlockTitle = styled(Span)`
    text-transform: uppercase;
    line-height: 14px;
    font-weight: 500;
    font-size: 10px;
    color: #7b8193;
    margin-right: 5px;
    ${ellipsisMixin};

    @media (max-width: ${xs}) {
        font-size: 12px;
        line-height: 14px;
    }
`;

interface BlockSubTitleProps {
    isIdType?: boolean;
}
export const BlockSubTitle = styled(Span)<BlockSubTitleProps>`
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: ${({ color }) => color || black};
    ${({ isIdType }) => !isIdType && ellipsisMixin};

    @media (min-width: ${xs}) {
        font-size: 14px;
        line-height: 16px;
    }
    @media (max-width: ${xs}) {
        font-size: 14px;
        line-height: 16px;
    }
`;

export const DateSpanWrapper = styled(Row)`
    overflow: hidden;
`;

export const StyledLink = styled(Link)`
    transition: text-decoration ${transitionTime}, text-decoration-color ${transitionTime};

    :hover {
        text-decoration: underline;
        text-decoration-color: ${({ color }) => color || black};
    }
    ${ellipsisMixin};
`;

export const EllipsisRow = styled(Row)`
    ${ellipsisMixin};
`;

export const InteractionButton = styled.button`
    ${disableDefaultButtonStyleMixin};
`;

export const SelectableItemWrapper = styled(Section)<Pointer>`
    ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')};
`;

export const StyledItemsAbsoluteWrapper = styled(ItemsAbsoluteWrapper)`
    background-color: ${grey3};
`;

export const StyledItemWrapper = styled(ItemWrapper)`
    background-color: ${grey3};
`;

export const IncorrectText = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${brightRed};
    padding-top: 2px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
`;
