import ycmAxios from './yecm.axios';

export const getTransactions = (params: YecmPaths.AdminTransactions.Get.QueryParameters) =>
    ycmAxios<YECM.AdminTransactionsResponseApiResponse>({
        url: '/admin/transactions',
        method: 'GET',
        params
    });

export const getUserTransactions = (
    remoteUserId: string,
    params: YecmPaths.Users$RemoteUserIdTransactions.Get.QueryParameters
) =>
    ycmAxios<YECM.TransactionsDtoApiResponse>({
        url: `/users/${remoteUserId}/transactions`,
        method: 'GET',
        params
    });
