import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { SortButton } from 'v2/components/ui/buttons/SortButton';
import { getNextSortValue, getSortOrderState } from 'v2/pages/Videos/VideosFilterLayout/utils';

export const ButtonSort: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { addFilters } = domain.events;

    const { sort } = useStore($videosFilters);
    const sortOrder = getSortOrderState(sort);

    const handleChange = () => {
        const newSort = getNextSortValue(sortOrder);

        addFilters({ sort: `${sort?.split('+')[0]}+${newSort}` });
    };

    return <SortButton sortState={sortOrder} onClick={handleChange} />;
};
