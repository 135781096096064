import React, { FC, ReactNode } from 'react';
import { TooltipIconWrapper } from 'v2/components/common/CollaborationTooltip/styles';
import { Tooltip } from 'v2/components/ui/Tooltip';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors } from 'v2/constants/styles/colors';

interface Props {
    text: string;
    icon: ReactNode;
    placement?: string;
    maxWidth?: string;
}

export const CollaborationTooltip: FC<Props> = ({ text, icon, maxWidth = '130px', placement = 'bottomRight' }) => (
    <Tooltip
        maxWidth={maxWidth}
        overlay={
            <Text color={brandColors.white} size="-2">
                {text}
            </Text>
        }
        placement={placement}
    >
        <TooltipIconWrapper>{icon}</TooltipIconWrapper>
    </Tooltip>
);
