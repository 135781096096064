import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const MainInfoWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;
    border-right: 1px solid ${brandColors.grey};
`;

interface Props {
    columns: number;
}

export const FollowersGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, min-content);
    grid-gap: 10px;
`;
