import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';

export const GridInfoBlocks = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1px;
`;

export const PropertyLayoutWrapper = styled.div`
    background-color: ${brandColors.white};
`;
