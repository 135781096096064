import { createEffect, createEvent, createStore, sample } from 'effector';
import { SelectOption } from 'v2/components/ui/selects/types';
import {
    defaultCollaborationsQueryParams,
    defaultTrendingCollaborationsQueryParams,
    trendingCollaborationsStateOptions
} from 'v2/constants/services/collaborations';
import { yecmAPI } from 'v2/services/yecm';

type CollaborationsParams = Partial<YecmPaths.Collaborations.Get.QueryParameters>;

export const initializeCollaborationsStore = (defaultParams: CollaborationsParams, stateOptions?: SelectOption[]) => {
    const addFilters = createEvent<CollaborationsParams>();
    const overwriteFilters = createEvent<CollaborationsParams>();

    const resetCollaborations = createEvent();
    const resetFilters = createEvent();

    //Effects
    const getCollaborationsFx = createEffect({
        handler: async (params?: CollaborationsParams) => {
            try {
                const states = (() => {
                    if (params?.states && params?.states?.length > 0) {
                        return params?.states;
                    } else {
                        if (stateOptions) {
                            return stateOptions.map(item => item.value);
                        } else {
                            return [];
                        }
                    }
                })();

                const { isSuccess, data } = await yecmAPI.collaborations.getCollaborations({
                    ...defaultParams,
                    ...params,
                    states
                });

                if (!isSuccess || !data) {
                    throw new Error();
                }

                return data;
            } catch {
                return null;
            }
        }
    });

    //Stores
    const $collaborations = createStore<YECM.CollaborationsResponse | null>(null)
        .on(getCollaborationsFx.doneData, (state, payload) => {
            if (!payload) return;

            return {
                ...(state || {}),
                ...payload,
                items: payload.pageIndex === 0 ? payload.items : [...(state?.items || []), ...(payload.items || [])]
            };
        })
        .reset(resetCollaborations);

    const $collaborationsFilters = createStore<CollaborationsParams>(defaultParams)
        .on(addFilters, (state, filters) => ({
            ...(state || defaultParams),
            ...filters
        }))
        .on(overwriteFilters, (_, filters) => ({
            ...defaultParams,
            ...filters
        }))
        .reset(resetFilters);

    sample({
        clock: $collaborationsFilters,
        target: getCollaborationsFx,
        fn: (clock: CollaborationsParams) => ({
            ...clock,
            states: clock.states && clock.states?.length > 0 ? clock.states : defaultParams.states
        })
    });

    const $hasError = createStore<boolean>(false)
        .on(getCollaborationsFx.fail, () => true)
        .reset(getCollaborationsFx.done);

    //Exports
    const effects = {
        getCollaborationsFx
    };

    const stores = {
        $collaborations,
        $hasError,
        $collaborationsFilters
    };

    const events = {
        addFilters,
        overwriteFilters,
        resetFilters,
        resetCollaborations
    };

    return { stores, effects, events };
};

export type GenericCollaborationsType = ReturnType<typeof initializeCollaborationsStore>;

// Generic stores

export const collaborationsPageStore = initializeCollaborationsStore(defaultCollaborationsQueryParams);
export const trendingCollaborationsPageStore = initializeCollaborationsStore(
    defaultTrendingCollaborationsQueryParams,
    trendingCollaborationsStateOptions
);
