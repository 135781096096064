import history from 'browserHistory';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { CardButton } from 'v1/components/common/buttons/CardButton';
import { SimpleButton } from 'v1/components/common/buttons/SimpleButton/styles';
import { ClosableTag } from 'v1/components/common/tags/ClosableTag';
import { ContentText } from 'v1/components/common/typography/ContentText/styles';
import { Span } from 'v1/components/common/typography/Span/styles';
import { PropertyBlock } from 'v1/components/layouts/blocks/PropertyBlock';
import {
    deletedUserPrimaryMargin,
    deletedUserSinglePadding,
    largePropertyWidth,
    propertyHeight,
    propertyWidth
} from 'v1/components/layouts/cards/DeletedUserCard/constants';
import {
    copyBlockerId,
    copyEmailMessage,
    copyPhoneMessage,
    copyUserIdMessage,
    copyUsernameMessage,
    parseDisableDescription,
    parseDisableTitle
} from 'v1/components/layouts/cards/UserCard/constants';
import { ContentWrapper } from 'v1/components/wrappers/ContentWrapper/styles';
import { Row, Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { ReasonDeletion, defaultDisabledUsersValues } from 'v1/constants/defaults/users';
import { black, dirtyBlue } from 'v1/constants/styles/colors';
import { modalEvents } from 'v1/stores/modals/asyncModal';
import { blockedUsersEffects, blockedUsersStores } from 'v1/stores/users/disabledUsers';
import { usersEffects } from 'v1/stores/users/users';
import { SubjectType } from 'v1/types/types';
import { ChevronLeftIcon } from 'v2/assets/icons';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { routePaths } from 'v2/constants/routes';
import { deletedUserMarginRight } from './constants';

interface ParamsProps {
    blockedUserId: string;
}

export const BlockedUser = () => {
    const { blockedUserId } = useParams<ParamsProps>();
    const { items } = useStore(blockedUsersStores.blockedUsers);
    const user = items && items[0];
    const loading = useStore(blockedUsersStores.loading);
    const userId = user?.id;
    const userName = `@${user?.username}`;
    const isDisabled = user?.isDisabled;
    const comment = user?.disablingInfo?.length ? user?.disablingInfo[user.disablingInfo.length - 1].comment : '';
    const reasons = user?.disablingInfo?.length ? user?.disablingInfo[user.disablingInfo.length - 1].reasons : [];
    const email = user?.email;
    const mobileNumber = user?.mobileNumber;

    const disablerInfoState = user?.disablingInfo?.length
        ? user?.disablingInfo[user.disablingInfo.length - 1]?.disablerInfo
        : null;
    const disablerId = disablerInfoState?.disablerId;
    const disablerIsTrust = disablerInfoState?.isTrusted;
    const disablerName = disablerInfoState?.userName;

    const onBack = () => history.goBack();

    const disableOkHandler = async (id: SubjectType) => {
        if (typeof id !== 'string') return;

        await usersEffects.enableUsersById({ users: [id] });

        onBack();

        modalEvents.closeAsyncModal();
    };

    const redirectTo = `${routePaths.users}/${disablerId}`;

    const moreInfoHandleClick = () => {
        if (disablerId !== '0'.repeat(24)) {
            history.push(redirectTo);
        }
    };

    const callEnableModal = (id: SubjectType) =>
        modalEvents.openAsyncModal({
            visible: true,
            title: parseDisableTitle(false),
            content: parseDisableDescription(false, userName || ''),
            subject: id,
            onOk: disableOkHandler
        });

    useEffect(() => {
        blockedUsersEffects.loadItems({ ...defaultDisabledUsersValues, disabledUserId: blockedUserId });
    }, [blockedUserId]);

    return (
        <MainLayout>
            <DetailsPageLayout>
                <Section marginRight={deletedUserMarginRight}>
                    <ContentWrapper
                        marginBottom={deletedUserPrimaryMargin}
                        marginRight={deletedUserMarginRight}
                        padding={deletedUserSinglePadding}
                        width="100%"
                    >
                        {loading ? (
                            <Section justifyCenter>
                                <Spinner />
                            </Section>
                        ) : (
                            <>
                                <Section justifyBetween marginBottom="28px">
                                    <Row>
                                        <MarginWrapper marginRight="24px">
                                            <IconButton onClick={onBack}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </MarginWrapper>
                                        <Span color={black} fontSize="18px" fontWeight="600" lineHeight="21px">
                                            Blocked User info
                                        </Span>
                                    </Row>
                                    {isDisabled && (
                                        <CardButton
                                            marginRight="8px"
                                            type="danger"
                                            onClick={() => {
                                                callEnableModal(blockedUserId);
                                            }}
                                        >
                                            Unblock
                                        </CardButton>
                                    )}
                                </Section>
                                <Section marginBottom="17px">
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={userId}
                                        success={copyUserIdMessage}
                                        title="user id"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={userName || '-'}
                                        success={copyUsernameMessage}
                                        title="user name"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight={deletedUserPrimaryMargin}
                                        minHeight={propertyHeight}
                                        subtitle={email || '-'}
                                        success={copyEmailMessage}
                                        title="Email Address"
                                        width={propertyWidth}
                                    />
                                    <SimpleButton
                                        background="none"
                                        marginBottom="4px"
                                        marginRight={deletedUserPrimaryMargin}
                                        padding="0"
                                        onClick={moreInfoHandleClick}
                                    >
                                        <PropertyBlock
                                            isTrusted={disablerIsTrust || false}
                                            marginBottom="0"
                                            minHeight={deletedUserPrimaryMargin}
                                            subtitle={disablerName || '-'}
                                            title="Blocked by"
                                            width={propertyWidth}
                                        />
                                    </SimpleButton>
                                </Section>
                                <Section marginBottom="16px">
                                    <PropertyBlock
                                        marginRight="8px"
                                        minHeight={propertyHeight}
                                        subtitle={comment || '-'}
                                        title="Comment"
                                        width={largePropertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        marginRight="8px"
                                        minHeight={propertyHeight}
                                        subtitle={mobileNumber || '-'}
                                        success={copyPhoneMessage}
                                        title="Phone Number"
                                        width={propertyWidth}
                                    />
                                    <PropertyBlock
                                        copiable
                                        minHeight={propertyHeight}
                                        subtitle={disablerId || '-'}
                                        success={copyBlockerId}
                                        title="Blocker ID"
                                        width={propertyWidth}
                                    />
                                </Section>
                                <ContentText
                                    uppercase
                                    color={dirtyBlue}
                                    fontSize="10px"
                                    fontWeight="500"
                                    lineHeight="12px"
                                >
                                    Reason
                                </ContentText>
                                <Section marginTop={deletedUserPrimaryMargin}>
                                    {reasons?.map(reason => (
                                        <ClosableTag
                                            key={reason}
                                            untouchable
                                            marginRight="4px"
                                            subject={ReasonDeletion[reason]}
                                        />
                                    ))}
                                </Section>
                            </>
                        )}
                    </ContentWrapper>
                </Section>
            </DetailsPageLayout>
        </MainLayout>
    );
};
