import styled from 'styled-components';
import { grey } from 'v2/constants/styles/colors';
import { defaultInputStyles } from 'v2/constants/styles/mixins';
import { Sizes } from 'v2/types/styles';

export const InputWrapper = styled.div<Sizes>`
    position: relative;
    width: ${({ width }) => width ?? '100%'};
    max-width: 465px;
    min-width: 250px;
    height: ${({ height }) => height ?? '100%'};
    background-color: inherit;
    display: flex;
    align-items: center;
`;

export const Input = styled.input`
    ${defaultInputStyles};
    width: 100%;

    &::placeholder {
        color: ${grey[700]};
    }
`;
