import React, { FC } from 'react';
import { DoneIcon, WarningIcon } from 'v2/assets/icons';
import { maxAvailableAge } from 'v2/components/filter/users/AgeFilter/constants';
import { Avatar } from 'v2/components/ui/Avatar';
import { Category } from 'v2/components/ui/Category';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Tooltip, TooltipText } from 'v2/components/ui/Tooltip';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { defaultLocaleCode } from 'v2/constants/languages';
import { routePaths } from 'v2/constants/routes';
import { brandColors } from 'v2/constants/styles/colors';
import { getAge } from 'v2/utils/datetime';
import { getGenderEmoji, getGenderName } from 'v2/utils/services';
import { EmojiProperty } from './EmojiProperty';
import { CardLink } from './styles';

interface Props extends YEAY.AdminGetUserCommon {}

export const UserCard: FC<Props> = ({
    id,
    profileImageUrl,
    username,
    isTrusted,
    isAccountVerified,
    locale,
    gender,
    dateOfBirth,
    utcCreated,
    utcLastAuthentication,
    location,
    roles
}) => (
    <CardLink to={`${routePaths.users}/${id}`}>
        <SectionBorder alignCenter borderBottom justifyBetween noWrap padding="8px">
            <Row alignCenter noWrap>
                <Avatar size="32px" src={profileImageUrl || ''} />
                <Column marginLeft="8px">
                    <EllipsisText isSemibold size="-1">
                        @{username || '-'}
                    </EllipsisText>
                </Column>

                {isTrusted && (
                    <Column flexShrink="0" marginLeft="8px">
                        <Tooltip overlay={<TooltipText text="Account is trusted" />} placement="top">
                            <DoneIcon height={14} width={14} />
                        </Tooltip>
                    </Column>
                )}
            </Row>

            {!isAccountVerified && (
                <Column flexShrink="0" marginLeft="8px">
                    <Tooltip overlay={<TooltipText text="Account is not verified" />} placement="top">
                        <WarningIcon color={brandColors.red} height={20} width={20} />
                    </Tooltip>
                </Column>
            )}
        </SectionBorder>

        <SectionBorder alignCenter borderBottom justifyCenter padding="8px">
            <Tooltip overlay={<TooltipText text="Age" />} placement="top">
                <Row alignCenter justifyCenter marginLeft="12px" marginRight="12px">
                    <EmojiProperty
                        emoji="🎂"
                        value={!dateOfBirth || getAge(dateOfBirth) > maxAvailableAge ? '-' : getAge(dateOfBirth)}
                    />
                </Row>
            </Tooltip>

            <Tooltip overlay={<TooltipText text="Gender" />} placement="top">
                <Row alignCenter justifyCenter marginLeft="12px" marginRight="12px">
                    <EmojiProperty emoji={getGenderEmoji(gender)} value={getGenderName(gender)} />
                </Row>
            </Tooltip>

            <Tooltip overlay={<TooltipText text="Locale" />} placement="top">
                <Row alignCenter justifyCenter marginLeft="12px" marginRight="12px">
                    <EmojiProperty emoji="🌎" value={locale || defaultLocaleCode} />
                </Row>
            </Tooltip>
        </SectionBorder>

        <SectionBorder borderBottom padding="6px 0">
            <PropertyLayout direction="row" textCopy={id} title="User ID">
                <Text isSemibold size="-2">
                    {id || '-'}
                </Text>
            </PropertyLayout>
        </SectionBorder>

        <SectionBorder borderBottom padding="0 6px">
            <RepeatGrid columns={2} width="100%">
                <SectionBorder borderRight>
                    <PropertyLayout title="Country">
                        <Text isSemibold size="-2">
                            {location?.countryName || '-'}
                        </Text>
                    </PropertyLayout>
                </SectionBorder>

                <PropertyLayout title="Region">
                    <Text isSemibold size="-2">
                        {location?.area?.region || '-'}
                    </Text>
                </PropertyLayout>
            </RepeatGrid>
        </SectionBorder>

        <SectionBorder borderBottom>
            <DateProperty date={utcCreated} direction="row" title="Created" />
        </SectionBorder>

        <DateProperty date={utcLastAuthentication} direction="row" title="Last Login" />

        {roles && roles.length > 0 && (
            <SectionBorder borderTop noWrap overflowX="auto" padding="8px">
                {roles.map(role => (
                    <Row key={role} marginRight="4px">
                        <Category text={role} />
                    </Row>
                ))}
            </SectionBorder>
        )}
    </CardLink>
);
