import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { authStores } from 'v2/stores/auth';
import { statusEmoji, statusTexts, statusTitles } from './constants';
import { ContentWrapper, Img, PageWrapper } from './styles';
import { PageStatus } from './types';

interface Props {
    status: PageStatus;
}

export const Error: FC<Props> = ({ status }) => {
    const isAuth = useStore(authStores.$isAuth);

    return (
        <PageWrapper>
            <ContentWrapper>
                <Section justifyCenter marginBottom="16px">
                    <Img alt={`Error ${status}`} src={statusEmoji[status]} />
                </Section>
                <Section justifyCenter marginBottom="16px">
                    <Title alignTextCenter size="4">
                        Error {status}:<br />
                        {statusTitles[status]}
                    </Title>
                </Section>

                <Section justifyCenter marginBottom="16px">
                    <Text alignTextCenter size="-1">
                        {statusTexts[status]}
                    </Text>
                </Section>

                <Button wide href={routePaths[isAuth ? 'videos' : 'login']}>
                    Back to {isAuth ? 'dashboard' : 'login'}
                </Button>
            </ContentWrapper>
        </PageWrapper>
    );
};
