import styled from 'styled-components';
import { ImgWrapperStyledProps } from 'v2/components/ui/Image/types';

export const Img = styled.img<ImgWrapperStyledProps>`
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : '100%')};
    ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius}; overflow: hidden` : ``)};
    overflow: hidden;
    ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg);` : ``)};
    ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')};
    object-fit: ${({ contain }) => (contain ? 'contain' : 'cover')};
`;
