import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { defaultSubmissionsQueryParams } from 'v2/constants/services/submissions';
import { yecmAPI } from 'v2/services/yecm';

export type SubmissionsParams = Partial<YecmPaths.Collaborations$CollaborationIdMediaAssets.Get.QueryParameters>;

//Events
const resetStore = createEvent();
const setDefaultFilters = createEvent();
const addFilters = createEvent<SubmissionsParams>();
const setOverwriteFilters = createEvent<SubmissionsParams>();

//Effects
const getSubmissionsFx = createEffect({
    handler: async ({ collaborationId, params }: { collaborationId: string; params?: SubmissionsParams }) => {
        try {
            const { isSuccess, data } = await yecmAPI.collaboration.getSubmissions(collaborationId, {
                ...defaultSubmissionsQueryParams,
                ...params
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (error) {
            notify('There was a problem receiving submissions, try to refresh the page.', 'error');
            return null;
        }
    }
});

//Stores

const $submissions = createStore<YECM.MediaAssetsResponse>({})
    .on(getSubmissionsFx.doneData, (state, submissions) => {
        if (!submissions) return;

        return {
            ...(state || {}),
            ...submissions,
            items:
                submissions?.pageIndex === 0
                    ? submissions?.items
                    : [...(state?.items || []), ...(submissions?.items || [])]
        };
    })
    .reset(resetStore);

const $hasError = createStore<boolean>(false)
    .on(getSubmissionsFx.fail, () => true)
    .reset([getSubmissionsFx.done]);

const $submissionsFilters = createStore<SubmissionsParams>(defaultSubmissionsQueryParams)
    .on(setDefaultFilters, () => defaultSubmissionsQueryParams)
    .on(addFilters, (state, submissionType) => ({
        ...(state || defaultSubmissionsQueryParams),
        ...submissionType
    }))
    .on(setOverwriteFilters, (state, submissionType) => ({
        ...(state || defaultSubmissionsQueryParams),
        ...submissionType,
        pageIndex: 0
    }))
    .reset(resetStore);

//Exports
export const submissionsEffects = {
    getSubmissionsFx
};

export const submissionsStores = {
    $submissions,
    $hasError,
    $submissionsFilters
};

export const submissionsEvents = {
    resetStore,
    setOverwriteFilters,
    addFilters
};
