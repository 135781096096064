import styled from 'styled-components';
import { inputBorderBottom, inputPadding } from 'v1/components/common/inputs/SearchInput/constants';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { md } from 'v1/constants/styles/sizes';
import { BorderProperties, Padding, Sizes } from 'v1/types/styles';

interface InputProps extends Pick<Padding, 'padding'>, Sizes, Pick<BorderProperties, 'border'> {}

export const InputWrapper = styled.div<InputProps>`
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    padding: ${({ padding }) => (padding ? padding : inputPadding)};
    margin-right: 12px;
    border-bottom: ${inputBorderBottom};
    ${({ border }) => border && `border: ${border}; border-radius: 8px`};
    min-width: 200px;

    @media (max-width: ${md}) {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
`;

export const SearchWrapper = styled(Section)`
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 50px;

    @media (max-width: ${md}) {
        flex-direction: column;
        width: 100%;
        margin-right: 0px;
    }
`;
