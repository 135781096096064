import axios from 'v2/services/yasy/axios';

export const getVideoById = (data: YEAY.GetVideoRequest) =>
    axios<YEAY.AdminGetVideoResponse>({
        url: '/admin/video/get',
        data
    });

export const editVideoInfo = (data: YEAY.UpdateVideoRequest) =>
    axios<YEAY.AdminGetVideoResponse>({
        url: '/admin/video/update',
        data
    });

export const getVideoSourceFile = (data: YEAY.GetVideoMetaRequest) =>
    axios<YEAY.GetVideoPreviewResponse>({
        url: '/admin/video/get-source-file',
        data
    });

export const getVideoEngagement = (data: YEAY.QueryVideoStatisticsRequest) =>
    axios<YEAY.QueryVideoStatisticsResponse>({
        url: '/admin/video/query-statistics',
        data
    });
