import React, { FC } from 'react';
import { DoneIcon } from 'v2/assets/icons';
import { EllipsesText } from 'v2/components/cards/SingleCollaborationCard/styles';
import { Avatar } from 'v2/components/ui/Avatar';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { Noop } from 'v2/types/types';
import { CardWrapper } from './styles';

interface Props {
    data: YEAY.AdminGetUserCommon;
    selected?: boolean;
    disabled?: boolean;
    onClick?: Noop;
}

export const CreatorCard: FC<Props> = ({ data, selected, disabled, onClick }) => {
    const { username, profileImageUrl, isTrusted } = data;

    return (
        <CardWrapper disabled={!selected && disabled} selected={selected} type="button" onClick={onClick}>
            <Avatar src={profileImageUrl || undefined} />

            <Section alignCenter justifyCenter noWrap>
                <EllipsesText size="-2">@{username || ''}</EllipsesText>

                {isTrusted && (
                    <Column marginLeft="4px">
                        <IconWrapper size="16px">
                            <DoneIcon height={15} width={15} />
                        </IconWrapper>
                    </Column>
                )}
            </Section>
        </CardWrapper>
    );
};
