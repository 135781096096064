export enum VideoSingleTab {
    Video,
    User,
    Collaboration
}

export enum VideoSourceState {
    None,
    NotCreated,
    Processing,
    Processed,
    Failed
}
