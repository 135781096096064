import React, { FC, useEffect, useMemo, useState } from 'react';
import { ArrowLeftSmallIcon } from 'v2/assets/icons';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Dropdown } from 'v2/components/ui/Dropdown';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Switch } from 'v2/components/ui/inputs/Switch';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { NestedOption } from './NestedOption';
import { SearchInput } from './SearchInput';
import { defaultTitle } from './constants';
import { StickyWrapper } from './styles';
import { NestedSelectParameters, SelectorsItem } from './types';
import { findItemOfSelector, findItemsByString } from './utils';

interface Props {
    selectors: SelectorsItem[];
    onSelect: (params: NestedSelectParameters) => void;
    title?: string;
    value?: string;
    isLoading?: boolean;
}

export const NestedSelect: FC<Props> = ({ value, title, selectors, isLoading, onSelect }) => {
    const [path, setPath] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [showNested, setShowNested] = useState(true);
    const [isVisible, setIsVisible] = useState(false);

    const currentSelectorItem = useMemo(() => {
        if (path.length === 0) {
            return null;
        }

        return findItemOfSelector(selectors, path[path.length - 1]);
    }, [path, selectors]);

    const filteredSelectorList = useMemo(() => {
        const selectorList = currentSelectorItem?.nestedSelectors || selectors;

        if (!searchValue || path.length === 0) {
            return selectorList;
        }

        return findItemsByString(selectorList, searchValue);
    }, [searchValue, path.length, currentSelectorItem, selectors]);

    const setInitialState = () => {
        setPath([]);
        setSearchValue('');
        setShowNested(true);
    };

    const handleBackClick = () => {
        const newPath = [...path].slice(0, -1);

        if (newPath.length === 0) {
            setInitialState();
            return;
        }

        setPath(newPath);
    };

    const goToNextLevel = ({ nestedSelectors, value, type, title, isFetched, onFetch }: SelectorsItem) => {
        if ((nestedSelectors && nestedSelectors.length > 0) || (onFetch && !isFetched)) {
            setPath(state => [...state, value]);

            if (!isFetched && onFetch && (!nestedSelectors || nestedSelectors.length === 0)) {
                onFetch({ value, title, type: type || '' });
            }
        }
    };

    const handleOptionClick = (item: SelectorsItem) => () => {
        const { title, value, type } = item;

        if (type) {
            onSelect({ title, value, type });
        } else {
            goToNextLevel(item);
        }
    };

    const handleOptionArrowClick = (item: SelectorsItem) => () => {
        goToNextLevel(item);
    };

    useEffect(() => {
        setSearchValue('');

        if (!currentSelectorItem) {
            setShowNested(true);
        }
    }, [currentSelectorItem]);

    useEffect(() => {
        if (!isVisible) {
            setInitialState();
        }
    }, [isVisible]);

    return (
        <Dropdown isOpened={isVisible} label={value} placeholder="All" title={title} onOpenedChange={setIsVisible}>
            <Column noWrap width="100%">
                <StickyWrapper>
                    <Section alignCenter noWrap padding="4px 8px">
                        {path.length > 0 && (
                            <Column flexShrink="0" marginRight="8px">
                                <IconButton
                                    color={grey[500]}
                                    hoverColor={brandColors.black}
                                    size="16px"
                                    onClick={handleBackClick}
                                >
                                    <ArrowLeftSmallIcon height={10} width={6} />
                                </IconButton>
                            </Column>
                        )}

                        <Text isSemibold size="-2">
                            {currentSelectorItem?.title || defaultTitle}
                        </Text>
                    </Section>

                    {currentSelectorItem && currentSelectorItem.searchPlaceholder && (
                        <SectionBorder borderBottom borderTop>
                            <SearchInput
                                disabled={isLoading}
                                placeholder={currentSelectorItem.searchPlaceholder}
                                value={searchValue}
                                onChange={setSearchValue}
                            />
                        </SectionBorder>
                    )}

                    {filteredSelectorList.length > 0 && currentSelectorItem && currentSelectorItem.switch && (
                        <Section alignCenter justifyBetween noWrap padding="8px">
                            <Text size="-2">{currentSelectorItem.switch}</Text>
                            <Switch small checked={showNested} onChange={setShowNested} />
                        </Section>
                    )}
                </StickyWrapper>

                <Column noWrap width="100%">
                    {isLoading ? (
                        <Section justifyCenter paddingBottom="4px" paddingTop="4px">
                            <IconWrapper size="36px">
                                <Spinner />
                            </IconWrapper>
                        </Section>
                    ) : (
                        <>
                            {filteredSelectorList.length > 0 ? (
                                filteredSelectorList.map(selector => (
                                    <NestedOption
                                        key={selector.value}
                                        isActive={selector.value === value}
                                        isNested={
                                            ((!!selector.nestedSelectors && selector.nestedSelectors.length > 0) ||
                                                (selector.onFetch && !selector.isFetched)) &&
                                            showNested
                                        }
                                        onArrowClick={handleOptionArrowClick(selector)}
                                        onClick={handleOptionClick(selector)}
                                    >
                                        {selector.title || selector.value}
                                    </NestedOption>
                                ))
                            ) : (
                                <Section padding="4px 8px">
                                    <Text color={brandColors.red} size="-2">
                                        {!!searchValue ? 'Incorrect Format' : 'No Data'}
                                    </Text>
                                </Section>
                            )}
                        </>
                    )}
                </Column>
            </Column>
        </Dropdown>
    );
};
