import styled, { css } from 'styled-components';
import { SwitchProps } from 'v2/components/ui/inputs/Switch';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

interface Props extends Pick<SwitchProps, 'checked' | 'disabled' | 'small'> {}

const smallMarkSize = '12px';
const largeMarkSize = '16px';

const getMarkSize = (props: Props) => (props.small ? smallMarkSize : largeMarkSize);
const getMarkLeft = (props: Props) => {
    if (props.small) {
        return props.checked ? `calc(100% - ${smallMarkSize} - 1px)` : '1px';
    }

    return props.checked ? `calc(100% - ${largeMarkSize} - 2px)` : '2px';
};

export const Label = styled.label<Props>`
    width: ${({ small }) => (small ? '28px' : '40px')};
    height: ${({ small }) => (small ? '14px' : '20px')};
    border-radius: 14px;
    background-color: ${({ checked }) => (checked ? brandColors.black : grey[400])};
    position: relative;
    transition: background-color ${transitionTime} linear;
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
    ${({ disabled }) => disabled && 'opacity: 0.5'};

    ${({ checked, disabled }) =>
        !checked &&
        !disabled &&
        css`
            &:hover {
                background-color: ${grey[500]};
            }
        `};

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: ${getMarkLeft};
        width: ${getMarkSize};
        height: ${getMarkSize};
        transform: translateY(-50%);
        background-color: ${brandColors.white};
        border-radius: 50%;
        z-index: 1;
        transition: left 0.1s ease-out;
    }
`;
