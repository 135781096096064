import React, { FC } from 'react';
import { ErrorIcon, PendingIcon, SuccessIcon } from 'v2/assets/icons';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { videoStateTitles } from 'v2/constants/services/videos';
import { green, grey, red } from 'v2/constants/styles/colors';
import { TextWrapper } from './styles';
import { CommonVideoStateType, VideoStatusTitles } from './types';

interface Props {
    status: CommonVideoStateType;
    titles?: VideoStatusTitles;
}

const Icon: FC<Pick<Props, 'status'>> = ({ status }) => {
    switch (status) {
        case CommonVideoStateType.Pending:
            return <PendingIcon color={grey[700]} height="16px" width="16px" />;
        case CommonVideoStateType.Valid:
            return <SuccessIcon color={green[700]} height="8px" width="12px" />;
        case CommonVideoStateType.Invalid:
            return <ErrorIcon color={red[300]} height="10px" width="10px" />;
        case CommonVideoStateType.None:
        default:
            return null;
    }
};

export const VideoStatus: FC<Props> = ({ status, titles = videoStateTitles }) => (
    <Row alignCenter noWrap>
        <IconWrapper>
            <Icon status={status || 0} />
        </IconWrapper>

        <TextWrapper isSemibold size="-2" type={status}>
            {titles[status]}
        </TextWrapper>
    </Row>
);
