import { differenceInSeconds, intervalToDuration } from 'date-fns';

export const addZeros = (value?: number | string) => {
    if (!value) return '00';
    return value.toString().padStart(2, '0');
};

export const getTimeLeft = (date?: string | null) => {
    if (!date) return null;
    if (!Date.parse(date)) return null;

    let startTime = new Date(date);
    startTime = new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);

    const differenceSeconds = differenceInSeconds(startTime, Date.now());
    if (differenceSeconds <= 0)
        return {
            expired: true,
            value: '00 d 00 hr 00 mins'
        };

    const { days, hours, minutes } = intervalToDuration({
        start: startTime,
        end: Date.now()
    });

    return {
        expired: false,
        value: `${addZeros(days)} d ${addZeros(hours)} hr ${addZeros(minutes)} mins`
    };
};
