import React, { FC } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { agentEffects, defaultMembersParams } from 'v2/stores/agent';
import { columns } from './constants';
import { prepareAgentMembers } from './utils';

interface Props {
    isLoading: boolean;
    agentMembers: YEAY.AgentMembersDto;
    agentId?: string;
}

export const AgentMembers: FC<Props> = ({ isLoading, agentMembers, agentId }) => {
    const tableData = prepareAgentMembers(agentMembers?.items || [], agentId);

    const loadMore = () => {
        if (!agentMembers?.hasNext) return;

        const newPageIndex = (agentMembers?.pageIndex || 0) + 1;
        agentId &&
            agentEffects.getAgentMembersFx({
                agentId,
                params: { pageIndex: newPageIndex, limit: defaultMembersParams.limit }
            });
    };

    return (
        <Section noWrap>
            <SectionBorder borderBottom borderTop>
                <Table
                    cellCenter
                    bottomExtra={
                        agentMembers?.hasNext && (
                            <Section justifyCenter marginTop="16px">
                                <LoadMoreSection onClick={loadMore} />
                            </Section>
                        )
                    }
                    columns={columns}
                    data={tableData}
                    emptyMessage="No Agent Influencers"
                    isLoading={isLoading}
                />
            </SectionBorder>
        </Section>
    );
};
