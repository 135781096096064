import React, { FC, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { useOutsideClick } from 'v2/hooks/use-outside-click';
import { SizeButton, SizeMainButton, SizesDropdown, Wrapper } from './styles';

interface Props {
    sizes: number[];
    activeSize: number;
    onChange: (size: number) => void;
    disabled?: boolean;
}

export const SizeSelect: FC<Props> = ({ sizes, activeSize, onChange, disabled }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    const items = [...Array.from(new Set([...sizes, activeSize]))].sort((a, b) => a - b);
    const activeIndex = items.findIndex(item => item === activeSize);

    const close = () => {
        setIsVisible(false);
    };

    const handleChange = (value: number) => () => {
        onChange(value);
        close();
    };

    useOutsideClick(ref, close);

    return (
        <Wrapper ref={ref}>
            <SizeMainButton disabled={disabled} type="button" onClick={() => setIsVisible(!isVisible)}>
                <Text size="-1">{activeSize}/Page</Text>

                <Row alignCenter justifyEnd marginLeft="8px">
                    {isVisible ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Row>
            </SizeMainButton>

            {isVisible && !disabled && items.length > 0 && (
                <SizesDropdown>
                    {items.map((item, index) => (
                        <SizeButton
                            key={item}
                            disabled={index === activeIndex}
                            type="button"
                            onClick={handleChange(item)}
                        >
                            <Text size="-2">{item}</Text>
                        </SizeButton>
                    ))}
                </SizesDropdown>
            )}
        </Wrapper>
    );
};
