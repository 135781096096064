import { useStore } from 'effector-react';
import React from 'react';
import { MainLoader } from 'v2/components/loaders/MainLoader';
import { ConfirmModal } from 'v2/components/modals/ConfirmModal';
import { CurateVideoModal } from 'v2/components/modals/CurateVideoModal';
import { VideoModal } from 'v2/components/modals/VideoModal';
import { Toasts } from 'v2/components/ui/Toasts';
import { authStores } from 'v2/stores/auth';

export const AppModals = () => {
    const isAuth = useStore(authStores.$isAuth);

    return (
        <>
            <MainLoader />
            <ConfirmModal />
            <Toasts />

            {isAuth && (
                <>
                    <CurateVideoModal />
                    <VideoModal />
                </>
            )}
        </>
    );
};
