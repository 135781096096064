import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { CheckMarkOption } from 'v2/components/ui/options/CheckMarkOption';
import { Select } from 'v2/components/ui/selects/Select';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { categoriesEffects, categoriesStores } from 'v2/stores/categories';

export interface ICategoryOption {
    label: string;
    value: string;
}

interface Props {
    value?: string;
    onChange?: (value: string) => void;
}

export const CategorySelect: FC<Props> = ({ value, onChange }) => {
    const categories = useStore(categoriesStores.$categories);
    const isLoading = useStore(categoriesEffects.getCategoriesFx.pending);

    const options: ICategoryOption[] = categories.map(category => ({
        label: `${category.emojiCode} ${category.translation?.displayName}`,
        value: category.tagId || ''
    }));

    const handleChange = ({ value }: ICategoryOption) => {
        onChange?.(value);
    };

    useEffect(() => {
        if (categories.length === 0) {
            categoriesEffects.getCategoriesFx();
        }
    }, [categories]);

    if (isLoading) {
        return (
            <Section justifyCenter>
                <Spinner />
            </Section>
        );
    }

    return (
        <Select
            wide
            withBackground
            options={options}
            placeholder="Select category"
            renderOption={item => (
                <CheckMarkOption isRounded isSelected={value === item.value}>
                    {item.label}
                </CheckMarkOption>
            )}
            value={value}
            onChange={handleChange}
        />
    );
};
