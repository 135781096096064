import React from 'react';
import { TrashIcon } from 'v2/assets/icons';
import { Avatar } from 'v2/components/ui/Avatar';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { agentEffects } from 'v2/stores/agent';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { LinkUserInfo, TrashButtonWrapper } from './styles';

export const prepareAgentMembers = (items: YEAY.AgentMemberDto[], agentId?: string) => {
    const openModalDelete = (id?: string) => {
        confirmEvents.openConfirm({
            title: 'Delete Influencer',
            description: 'Are you sure you want to delete this influencer?',
            okButtonText: 'Cancel',
            cancelButtonText: 'Delete Influencer',
            cancelCallback: () => {
                if (id && agentId) {
                    agentEffects.removeMemberFromAgentFx({
                        agentId,
                        userId: id
                    });
                }
            },
            okCallback: () => {
                confirmEvents.closeConfirm();
            }
        });
    };

    return items.map(item => ({
        key: item.userId,
        user: (
            <LinkUserInfo to={`${routePaths.users}/${item.userId}`}>
                <Row alignCenter noWrap>
                    {item.profileImageUrl && (
                        <Row marginRight="8px">
                            <Avatar size="32px" src={item.profileImageUrl} />
                        </Row>
                    )}

                    <Text isSemibold size="-2">
                        @{item.username}
                    </Text>
                </Row>
            </LinkUserInfo>
        ),
        videosCount: <Text size="-2">{item.createdVideosCount || 0}</Text>,
        delete: (
            <TrashButtonWrapper alignCenter justifyEnd>
                <IconButton onClick={() => openModalDelete(item.userId)}>
                    <TrashIcon />
                </IconButton>
            </TrashButtonWrapper>
        )
    }));
};
