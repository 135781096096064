import React, { FC } from 'react';
import { InputField } from 'v2/components/form/InputField';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';

interface Props {
    title?: string;
}

export const Form: FC<Props> = ({ title }) => (
    <Column width="100%">
        <Row marginBottom="12px">
            <Title size="-1">{title}</Title>
        </Row>

        <Section marginBottom="34px">
            <InputField wide name="commission" placeholder="Enter percentage here" prefix="%" />
        </Section>
    </Column>
);
