import { UserGender } from 'v2/constants/services/users';

export const getGenderName = (gender?: YEAY.UserGender | null) => {
    switch (gender) {
        case UserGender.Female:
            return 'Female';
        case UserGender.Male:
            return 'Male';
        case UserGender.NonBinary:
            return 'Non binary';
        case UserGender.NotGiven:
        default:
            return 'Not given';
    }
};

export const getGenderEmoji = (gender?: YEAY.UserGender | null) => {
    switch (gender) {
        case UserGender.Female:
            return '👩';
        case UserGender.Male:
            return '👨';
        case UserGender.NonBinary:
        case UserGender.NotGiven:
        default:
            return '👤';
    }
};

export const getPageIndex = (page?: number) => {
    if (!page) {
        return 0;
    }

    const pageIndex = page - 1;
    return pageIndex < 0 ? 0 : pageIndex;
};
