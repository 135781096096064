export const grey = {
    50: '#fbfaff',
    100: '#f6f6ff',
    200: '#f1f1fa',
    300: '#e7e6f0',
    400: '#c4c4cd',
    500: '#a6a6af',
    600: '#7d7d85',
    700: '#686870',
    800: '#494950',
    900: '#27272E'
};

export const green = {
    50: '#EAFFE8',
    100: '#CAFFC6',
    200: '#A1FF9F',
    300: '#6BFF70',
    400: '#00FF44',
    500: '#00FC00',
    600: '#00EA00',
    700: '#00D300',
    800: '#00BE00',
    900: '#009800'
};

export const orange = {
    50: '#FFF3E0',
    100: '#FFE0B3',
    200: '#FFCD81',
    300: '#FFB84F',
    400: '#FFA82A',
    500: '#FF990A',
    600: '#FF8D0A',
    700: '#F97D09',
    800: '#F36C09',
    900: '#EA5108'
};

export const red = {
    50: '#FFEAED',
    100: '#FFCBD0',
    200: '#FB9694',
    300: '#F46B6A',
    400: '#FF4343',
    500: '#FF2922',
    600: '#F61924',
    700: '#E4001E',
    800: '#D70016',
    900: '#C70006'
};

export const brandColors = {
    white: grey[50],
    grey: grey[200],
    black: grey[900],
    green: green[400],
    red: red[400]
};

export const textColors = {
    main: grey[900],
    subtext: grey[700],
    disabled: grey[400]
};

export const gradients = {
    orange: {
        from: '#FBDE94',
        to: '#FF9D0A'
    }
};
