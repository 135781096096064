import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { AbsoluteWrapper } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { TrendingType, maximumCollaborationsCount } from 'v2/constants/services/trendings';
import { Filters } from 'v2/pages/Trendings/TrendingCollaborations/AddCollaborationModal/Filters';
import { TrendingCollaborationCard } from 'v2/pages/Trendings/TrendingCollaborations/TrendingCollaborationCard';
import { trendingCollaborationsPageStore } from 'v2/stores/collaborations';
import { addCollaborationModal } from 'v2/stores/initialize-modals';
import { trendingCollaborationEffects } from 'v2/stores/trendings/collaborations';
import { getArrayWithPositions } from 'v2/utils/array';
import { CollaborationsGrid, CollaborationsWrapper, SelectWrapper, StyledRelativeWrapper } from './styles';

const { effects, events, stores } = trendingCollaborationsPageStore;

interface Props {
    trendingCollaborations: YEAY.GetTrendingOverridesResponse[] | null | undefined;
}

const AddCollaborationModalInner: FC<Props> = ({ trendingCollaborations }) => {
    const data = useStore(stores.$collaborations);
    const isLoading = useStore(effects.getCollaborationsFx.pending);
    const hasError = useStore(stores.$hasError);
    const [selectedCollaborationIds, setSelectedCollaborationIds] = useState<string[]>([]);
    const collaborations = data?.items;
    const hasNext = data?.hasNext;

    const loadMoreCollaborations = () => {
        if (!hasNext) return;

        const newPageIndex = (data?.pageIndex || 0) + 1;
        events.addFilters({ pageIndex: newPageIndex });
    };

    useEffect(() => {
        effects.getCollaborationsFx();

        return () => {
            events.resetCollaborations();
            events.resetFilters();
        };
    }, []);

    const onAddClick = () => {
        const currentPositions = trendingCollaborations?.map(item => item.position) || [];
        const allPositions = getArrayWithPositions(maximumCollaborationsCount);
        selectedCollaborationIds.forEach(id => {
            const newPosition = allPositions.find(position => !currentPositions.includes(position));
            currentPositions.push(newPosition);
            trendingCollaborationEffects.addCollaborationToTrendingFx({
                type: TrendingType.Collaboration,
                position: newPosition,
                targetId: id
            });
        });
        addCollaborationModal.closeModal();
    };

    return (
        <ModalLayout
            submitDisabled={selectedCollaborationIds.length === 0}
            submitText="Add"
            title="Add Collaboration"
            onCancel={() => addCollaborationModal.closeModal()}
            onSubmit={onAddClick}
        >
            <Section marginBottom="30px" marginTop="16px">
                <Filters />
            </Section>

            <CollaborationsWrapper>
                {collaborations && collaborations?.length > 0 && (
                    <>
                        <CollaborationsGrid>
                            {collaborations?.map(item => {
                                const itemId = item.id;
                                const isSelected =
                                    !!selectedCollaborationIds.find(id => id === itemId) ||
                                    !!trendingCollaborations?.find(
                                        trendingItem => trendingItem.collaboration?.id === itemId
                                    );

                                const onSelect = () => {
                                    if (itemId) {
                                        if (selectedCollaborationIds.includes(itemId)) {
                                            setSelectedCollaborationIds(prev => prev.filter(id => id !== itemId));
                                        } else {
                                            if (
                                                (trendingCollaborations?.length || 0) +
                                                    selectedCollaborationIds.length !==
                                                    maximumCollaborationsCount &&
                                                !trendingCollaborations?.find(item => item.collaboration?.id === itemId)
                                            ) {
                                                setSelectedCollaborationIds(prev => [...prev, itemId]);
                                            }
                                        }
                                    }
                                };

                                return (
                                    <StyledRelativeWrapper key={itemId}>
                                        <AbsoluteWrapper height="100%" left="0" top="0" width="100%" zIndex="10">
                                            <SelectWrapper isSelected={isSelected} onClick={onSelect} />
                                        </AbsoluteWrapper>
                                        <TrendingCollaborationCard
                                            {...item}
                                            budget={item.budget?.priceWithFee}
                                            participantsEnrolledLimit={item.participationLimits?.enrolled}
                                        />
                                    </StyledRelativeWrapper>
                                );
                            })}
                        </CollaborationsGrid>

                        {isLoading && (
                            <Section justifyCenter marginTop="16px">
                                <Spinner />
                            </Section>
                        )}

                        {!isLoading && collaborations && collaborations.length === 0 && (
                            <Empty title="No Collaborations Found" />
                        )}

                        {!isLoading && hasNext && (
                            <Section marginTop="16px">
                                <LoadMoreSection disabled={hasError} onClick={loadMoreCollaborations} />
                            </Section>
                        )}
                    </>
                )}
            </CollaborationsWrapper>
        </ModalLayout>
    );
};

export const AddCollaborationModal: FC<Props> = ({ trendingCollaborations }) => {
    const { visible } = useStore(addCollaborationModal.$modal);

    return (
        <ModalWrapper size="large" visible={visible} onClose={() => addCollaborationModal.closeModal()}>
            <AddCollaborationModalInner trendingCollaborations={trendingCollaborations} />
        </ModalWrapper>
    );
};
