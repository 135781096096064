import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { yecmAPI } from 'v2/services/yecm';

// Types

interface ShippingsParams {
    collaborationId: string;
    pageIndex?: number;
    count?: boolean;
}

//Events

const resetShippings = createEvent();

const setShippingsItems = createEvent<YECM.ProductShippingResponse[] | null>();
const updateShippings = createEvent<YECM.ProductShippingsResponse | null>();

//Effects

const getShippingsFx = createEffect({
    handler: async ({ collaborationId, pageIndex = 0 }: ShippingsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.collaboration.getCollaborationShippings(collaborationId, {
                limit: 20,
                count: true,
                pageIndex
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (e) {
            notify('There was a problem receiving product shippings, try to refresh the page.', 'error');
            return null;
        }
    }
});

const confirmCollaborationShippingFx = createEffect({
    handler: async ({
        userId,
        collaborationId,
        data
    }: {
        userId: string;
        collaborationId: string;
        data: YECM.ConfirmShipmentRequest;
    }) => {
        try {
            const response = await yecmAPI.collaboration.confirmCollaborationShipping(userId, collaborationId, data);

            return response;
        } catch (e) {
            notify('There was a problem receiving product shippings, try to refresh the page.', 'error');
            return null;
        }
    }
});

//Stores

const $productShippings = createStore<YECM.ProductShippingsResponse | null>(null)
    .on([getShippingsFx.doneData, updateShippings], (state, payload) => ({
        ...state,
        ...payload,
        items: payload?.pageIndex === 0 ? payload?.items : [...(state?.items || []), ...(payload?.items || [])]
    }))
    .on(setShippingsItems, (state, payload) => {
        if (!state) {
            return null;
        }

        return {
            ...state,
            items: payload
        };
    })
    .reset(resetShippings);

const $shippingsItems = $productShippings.map(state => state?.items || []);
const $shippingsHasNext = $productShippings.map(state =>
    !state || state.hasNext === undefined ? true : state.hasNext
);
const $shippingsCurrentPage = $productShippings.map(state =>
    !state || state.pageIndex === undefined ? -1 : state.pageIndex
);

//Exports
export const shippingsEffects = {
    getShippingsFx,
    confirmCollaborationShippingFx
};

export const shippingsStores = {
    $productShippings,
    $shippingsItems,
    $shippingsHasNext,
    $shippingsCurrentPage
};

export const shippingsEvents = {
    resetShippings,
    setShippingsItems,
    updateShippings
};
