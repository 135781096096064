import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';

export const Wrapper = styled(Section)`
    height: 48px;
    border-radius: 4px;
    border: 1px solid transparent;
    overflow: hidden;

    ${({ draggable }) =>
        draggable &&
        css`
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        `};
`;
