import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { BrandCard } from 'v2/components/cards/BrandCard';
import { Empty } from 'v2/components/common/Empty';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandsEffects, brandsEvents, brandsStores } from 'v2/stores/brands';
import { BrandsFilterLayout } from './BrandsFilterLayout';
import { Wrapper } from './styles';

const { $brandsResponse } = brandsStores;
const { getBrandsFx } = brandsEffects;
const { resetStores, addFilters, setDefaultFilters } = brandsEvents;

export const Brands = () => {
    const isLoading = useStore(getBrandsFx.pending);
    const brandsResponse = useStore($brandsResponse);

    const items = brandsResponse?.items || [];
    const hasNext = (brandsResponse?.currentPageIndex || 0) + 1 < (brandsResponse?.totalPages || 0);

    const loadMore = () => {
        if (!hasNext) return;

        const newPageIndex = (brandsResponse?.currentPageIndex || 0) + 1;
        addFilters({ pageIndex: newPageIndex });
    };

    useEffect(() => {
        setDefaultFilters();

        return () => {
            resetStores();
        };
    }, []);

    return (
        <MainLayout>
            <BrandsFilterLayout totalCount={brandsResponse?.totalRecords}>
                <Wrapper>
                    {items.length > 0 && (
                        <CardsGridLayout>
                            {items.map(item => (
                                <BrandCard key={item.organizationId} data={item} />
                            ))}
                        </CardsGridLayout>
                    )}

                    {isLoading && (
                        <Section justifyCenter marginTop="16px">
                            <Spinner />
                        </Section>
                    )}

                    {!isLoading && items.length === 0 && <Empty title="No Brands Found" />}

                    {!isLoading && hasNext && (
                        <Section justifyCenter marginTop="16px">
                            <LoadMoreSection onClick={loadMore} />
                        </Section>
                    )}
                </Wrapper>
            </BrandsFilterLayout>
        </MainLayout>
    );
};
