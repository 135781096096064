import React, { FC } from 'react';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { textColors } from 'v2/constants/styles/colors';
import { GridItem } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/styles';

interface Props {
    address?: YEAY.UserAddressInfo;
}

export const AddressSection: FC<Props> = ({ address }) => (
    <>
        <GridItem noBorderBottom noBorderRight column="1/4">
            <MarginWrapper margin="8px">
                <Title color={textColors.subtext} size="-2">
                    User Address
                </Title>
            </MarginWrapper>
        </GridItem>
        <GridItem>
            <PropertyLayout title="Full Name">
                <Text isSemibold size="-2">
                    {address?.fullName || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem>
            <PropertyLayout title="Country">
                <Text isSemibold size="-2">
                    {address?.country || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem noBorderRight>
            <PropertyLayout title="Street Name">
                <Text isSemibold size="-2">
                    {address?.street || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem>
            <PropertyLayout title="House Number">
                <Text isSemibold size="-2">
                    {address?.houseNumber || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem>
            <PropertyLayout title="Postal Code">
                <Text isSemibold size="-2">
                    {address?.zipCode || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem noBorderRight>
            <PropertyLayout title="Town/City">
                <Text isSemibold size="-2">
                    {address?.city || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
        <GridItem noBorderRight column="1/4">
            <PropertyLayout title="State/Province/Region">
                <Text isSemibold size="-2">
                    {address?.state || '-'}
                </Text>
            </PropertyLayout>
        </GridItem>
    </>
);
