import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';

export const ModalContent = styled(Column)`
    padding: 16px;
`;

export const GreedWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    margin-bottom: 8px;
`;
