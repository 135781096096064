import * as Yup from 'yup';

export interface CommissionFormValues {
    commission?: string;
}

export const createCommissionInitialValues: CommissionFormValues = {
    commission: ''
};

export const createCommissionSchema = Yup.object().shape({
    commission: Yup.number().typeError('Please enter digits 0-9').max(50, 'Maximum 50%').required('Required')
});
