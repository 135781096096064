import styled from 'styled-components';
import { defaultInputStyles } from 'v2/constants/styles/mixins';

interface Props {
    value?: string;
}

export const StyledInput = styled.input<Props>`
    ${defaultInputStyles};
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 36px;
    padding-right: ${({ value }) => (!!value ? '32px' : '8px')};
`;
