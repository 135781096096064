import React, { FC } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { ReactClick } from 'v2/types/react';
import { SortState } from './constants';

interface Props extends ReactClick<HTMLButtonElement> {
    sortState?: SortState;
}

export const SortButton: FC<Props> = ({ onClick, sortState = 'no' }) => {
    const upArrowColor = sortState === SortState.Desc ? brandColors.black : grey[500];
    const downArrowColor = sortState === SortState.Asc ? brandColors.black : grey[500];

    return (
        <IconButton onClick={onClick}>
            <ArrowDownIcon color={downArrowColor} />
            <Row marginLeft="4px">
                <ArrowUpIcon color={upArrowColor} />
            </Row>
        </IconButton>
    );
};
