import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { HiddenScrollBlock } from 'v2/components/common/HiddenScrollBlock/styles';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Avatar } from 'v2/components/ui/Avatar';
import { CheckMark } from 'v2/components/ui/inputs/Checkbox';
import { SearchInput } from 'v2/components/ui/inputs/SearchInput';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { addAgentEffects, addAgentEvents, addAgentStores } from 'v2/stores/agent/add';
import { AgentsWrapper, NotFoundWrapper, StyledButton } from './styles';

interface Props {
    selectedAgentId: string;
    selectAgent: (selectedAgentId: string) => void;
}

export const AgentList: FC<Props> = ({ selectAgent, selectedAgentId }) => {
    const agentsResponse = useStore(addAgentStores.$agentsResponse);
    const hasNextPage = useStore(addAgentStores.$hasNext);
    const isLoading = useStore(addAgentStores.$isLoading);

    useEffect(() => {
        addAgentEffects.getAgentsFx();

        return () => addAgentEvents.resetStores();
    }, []);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: () => {
            if (agentsResponse?.currentPageIndex !== undefined) {
                addAgentEvents.setNewFilters({
                    pageIndex: agentsResponse?.currentPageIndex + 1
                });
            }
        },
        disabled: false,
        rootMargin: '0px'
    });

    const onSelectAgent = (agentId: string | undefined) => () => {
        if (agentId) {
            selectAgent(agentId);
        }
    };

    const onSearchAgent = (username: string) => {
        addAgentEvents.setNewFilters({
            username
        });
    };

    return (
        <>
            <SectionBorder borderBottom borderTop padding="5px 16px">
                <SearchInput allowClear placeholder="Search Agent" onSearch={onSearchAgent} />
            </SectionBorder>
            <AgentsWrapper>
                {agentsResponse?.items &&
                    agentsResponse?.items.length > 0 &&
                    agentsResponse?.items.map(user => (
                        <StyledButton key={user.id} onClick={onSelectAgent(user.id)}>
                            <Row alignCenter>
                                <Row marginRight="8px">
                                    <Avatar size="32px" src={user.profileImageUrl || ''} />
                                </Row>
                                <Text size="-2">@{user.username}</Text>
                            </Row>
                            <CheckMark isRounded checked={selectedAgentId === user.id} />
                        </StyledButton>
                    ))}

                {!isLoading && agentsResponse?.items && agentsResponse?.items.length === 0 && (
                    <NotFoundWrapper>
                        <Text color={brandColors.red} size="-2">
                            Not Found
                        </Text>
                    </NotFoundWrapper>
                )}

                {isLoading && (
                    <Section justifyCenter marginBottom="8px" marginTop="8px">
                        <Spinner />
                    </Section>
                )}

                <HiddenScrollBlock ref={loadRef} />
            </AgentsWrapper>
        </>
    );
};
