export const defaultShippingsQueryParams: YecmPaths.AdminShippings$CollaborationId.Get.QueryParameters = {
    pageIndex: 0,
    limit: 2,
    count: true
};

export enum ShipmentState {
    None = 0,
    NotShipped = 10,
    Shipped = 20
}

export enum DeliveryState {
    None = 0,
    Waiting = 10,
    NotConfirmed = 20,
    Confirmed = 30
}
