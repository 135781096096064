import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { SubmissionCard } from 'v2/components/cards/SubmissionCard';
import { Empty } from 'v2/components/common/Empty';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { NetworksType } from 'v2/constants/services/networks';
import { SubmissionsFilterLayout } from 'v2/pages/Collaboration/Submissions/SubmissionsFilterLayout';
import { collaborationStores } from 'v2/stores/collaboration';
import { submissionsEffects, submissionsEvents, submissionsStores } from 'v2/stores/collaboration/details/submissions';
import { Id } from 'v2/types/data';

const { getSubmissionsFx } = submissionsEffects;

export const Submissions: FC<Id> = ({ id }) => {
    const submissions = useStore(submissionsStores.$submissions);
    const { data: collaborationInfo } = useStore(collaborationStores.$collaborationInfo);
    const hasError = useStore(submissionsStores.$hasError);
    const isLoading = useStore(getSubmissionsFx.pending);

    const hasNext = submissions?.hasNext;
    const pageIndex = submissions?.pageIndex;

    const loadMore = () => {
        if (hasNext && !hasError) {
            const newPageIndex = (pageIndex || 0) + 1;

            submissionsEvents.addFilters({ pageIndex: newPageIndex });
        }
    };

    useEffect(() => {
        id && getSubmissionsFx({ collaborationId: id });
    }, [id]);

    useEffect(() => submissionsEvents.resetStore(), []);

    return (
        <Column width="100%">
            <SubmissionsFilterLayout>
                {isLoading && (
                    <Section justifyCenter>
                        <Spinner />
                    </Section>
                )}

                {submissions?.items && submissions?.items.length > 0 && (
                    <Section marginTop="8px">
                        <CardsGridLayout>
                            {submissions &&
                                collaborationInfo &&
                                submissions?.items
                                    ?.filter(item => item.postType === NetworksType.YeayVideo)
                                    .map(item => (
                                        <SubmissionCard
                                            key={item.id}
                                            collaborationInfo={collaborationInfo}
                                            data={item}
                                        />
                                    ))}
                        </CardsGridLayout>
                    </Section>
                )}

                {!isLoading && hasNext && (
                    <Section marginTop="16px">
                        <LoadMoreSection disabled={hasError} onClick={loadMore} />
                    </Section>
                )}

                {submissions?.items?.length === 0 && <Empty title="No Submissions Found" />}
            </SubmissionsFilterLayout>
        </Column>
    );
};
