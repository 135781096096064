import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';
import { defaultImgDiameter } from './constants';
import { AvatarProps } from './index';

export const StyledAvatar = styled.div<AvatarProps>`
    width: ${({ size }) => size || defaultImgDiameter};
    min-width: ${({ size }) => size || defaultImgDiameter};
    height: ${({ size }) => size || defaultImgDiameter};
    min-height: ${({ size }) => size || defaultImgDiameter};
    border-radius: 50%;
    ${({ hasBorder }) => hasBorder && `border: 1px solid ${brandColors.white}`};
    ${({ src }) => src && `background-image: url("${src}")`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
`;
