import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { defaultInputStyles } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

const inputHeight = '40px';

interface LabelProps {
    wide?: boolean;
}

export const Label = styled.label<LabelProps>`
    width: 100%;
    max-width: ${({ wide }) => (wide ? '100%' : '320px')};
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
`;

interface InputWrapperProps {
    isFocused?: boolean;
    hasError?: boolean;
    isDisabled?: boolean;
}

const getBorderColor = ({ isFocused, hasError }: InputWrapperProps, color: string) => {
    if (hasError) {
        return brandColors.red;
    }

    if (isFocused) {
        return brandColors.black;
    }

    return color;
};

export const InputWrapper = styled(Section)<InputWrapperProps>`
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid ${props => getBorderColor(props, grey[400])};
    background-color: ${brandColors.white};
    border-radius: 4px;
    transition: border ${transitionTime} linear;
    overflow: hidden;

    ${({ isDisabled }) =>
        !isDisabled
            ? css`
                  &:hover {
                      border-color: ${grey[600]};
                  }
              `
            : css`
                  cursor: not-allowed;
              `}
`;

export const StyledInput = styled.input`
    ${defaultInputStyles};
    width: 100%;
    height: ${inputHeight};
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const BottomWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: -24px;
    left: 8px;
`;
