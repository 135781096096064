import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const TopBlock = styled.div`
    width: 100%;
    height: 60px;
    background-color: ${brandColors.white};
`;

export const BlockWrapper = styled.div`
    width: 100%;
    background-color: ${brandColors.white};
    border-radius: 4px;
`;

export const Header = styled(Section)`
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-right: 32px;
    border-bottom: 1px solid ${brandColors.grey};
`;
