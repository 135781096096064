import { Strategy } from '@floating-ui/react';
import styled from 'styled-components';
import { SelectStylesProps } from 'v2/components/ui/selects/types';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { defaultBoxShadow, disableDefaultButtonStyleMixin, iconWrapperMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const DropdownWrapper = styled(Column)`
    flex-wrap: nowrap;
    position: relative;
`;

interface FloatingWrapperProps {
    position: Strategy;
    top: number;
    left: number;
    minWidth?: number;
}

export const FloatingWrapper = styled.div<FloatingWrapperProps>`
    min-width: ${({ minWidth }) => `${minWidth || 160}px`};
    position: ${({ position }) => position};
    top: ${({ top }) => `${top}px`};
    left: ${({ left }) => `${left}px`};
    background-color: ${brandColors.white};
    border-radius: 8px;
    ${defaultBoxShadow};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1002;
`;

export const SelectTitle = styled.button<SelectStylesProps>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    text-align: left;
    padding: ${({ withBackground }) => (withBackground ? '8px' : '4px 8px')};
    border-radius: 4px;
    border: 1px solid ${({ error }) => (!!error ? brandColors.red : 'transparent')};
    background-color: ${({ withBackground }) => (withBackground ? brandColors.grey : 'transparent')};
    transition: background-color ${transitionTime} linear;
    cursor: pointer;

    &:hover:not(:disabled) {
        background-color: ${grey[100]};
    }

    &:active:not(:disabled) {
        background-color: ${grey[300]};
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ArrowWrapper = styled.div<SelectStylesProps>`
    ${iconWrapperMixin};
    color: ${brandColors.black};
    ${({ isOpened }) => isOpened && 'transform: rotate(180deg)'};
    transition: transform ${transitionTime} linear;
`;
