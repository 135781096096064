import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { SortState } from 'v2/components/ui/buttons/SortButton/constants';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { engagementFilterOptions } from 'v2/constants/services/videos';
import { getSortOrderState } from 'v2/pages/Videos/VideosFilterLayout/utils';

export const EngagementFilter: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { addFilters } = domain.events;

    const { sort } = useStore($videosFilters);
    const sortOrder = getSortOrderState(sort);

    const handleChange = (option: SelectOption) => {
        const value = option.value;
        const newSortOrder = sortOrder === SortState.Desc ? 'desc' : 'asc';

        addFilters({ sort: `${value}+${newSortOrder}` });
    };

    return (
        <Select
            wide
            options={engagementFilterOptions}
            placement="bottom-start"
            title="Engagement"
            value={sort?.split('+')[0] || undefined}
            onChange={handleChange}
        />
    );
};
