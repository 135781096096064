import { useStore } from 'effector-react';
import React, { FC, MouseEvent, useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Avatar } from 'v2/components/ui/Avatar';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Table } from 'v2/components/ui/Table';
import { notify } from 'v2/components/ui/Toasts';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { noop } from 'v2/constants/functions';
import { routePaths } from 'v2/constants/routes';
import { defaultRequestsQueryParams } from 'v2/constants/services/collaboration';
import { requestsEffects, requestsStores } from 'v2/stores/collaboration/details/requests';
import { confirmEvents } from 'v2/stores/modals/confirm';
import { Id } from 'v2/types/data';
import { columns } from './constants';
import { Username, UsernameWrapper } from './styles';

const { $requests, $hasErrorRequests } = requestsStores;
const { getCollaborationsRequestsFx, loadMoreCollaborationsRequestsFx, deleteCollaborationRequestFx } = requestsEffects;

export const Requests: FC<Id> = ({ id }) => {
    const isLoading = useStore(getCollaborationsRequestsFx.pending);
    const requests = useStore($requests);
    const isMoreLoading = useStore(loadMoreCollaborationsRequestsFx.pending);
    const hasErrorRequests = useStore($hasErrorRequests);

    const requestItems = requests?.data?.items;
    const hasNext = requests?.data?.hasNext;
    const pageIndex = requests?.data?.pageIndex;

    const deleteCollaboration = (userId: string) => () => {
        deleteCollaborationRequestFx({ collaborationId: id, userId })
            .then(() => {
                notify('User successfully removed');
            })
            .catch(() => {
                notify('Error removing user', 'error');
            });
    };

    const onTrashClick = (collaborationId?: string) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (collaborationId) {
            confirmEvents.openConfirm({
                title: 'Delete',
                description: 'Are you sure you want to delete this user?',
                okButtonText: 'Delete User',
                okCallback: deleteCollaboration(collaborationId),
                cancelCallback: noop
            });
        }
    };

    const params = {
        ...defaultRequestsQueryParams,
        pageIndex: pageIndex !== undefined ? pageIndex + 1 : defaultRequestsQueryParams.pageIndex
    };

    const loadMoreCollaborationRequests = () => {
        if (hasNext && !hasErrorRequests) {
            loadMoreCollaborationsRequestsFx({ collaborationId: id, params });
        }
    };

    const getItems = () =>
        requestItems?.map(item => ({
            key: item.id,
            user: (
                <UsernameWrapper to={`${routePaths.users}/${item.creatorProfile?.remoteCreatorId}`}>
                    <Avatar size="32px" src={item.creatorProfile?.profileImageUrl || ''} />
                    <Username isSemibold size="-2">
                        @{item.creatorProfile?.userName || '-'}
                    </Username>
                </UsernameWrapper>
            ),
            requested: (
                <Section>
                    <DateProperty date={item.createdUtc} direction="row" padding="0" />
                </Section>
            ),
            delete: (
                <Section>
                    <Button buttonType="text-red" onClick={onTrashClick(item.participantId)}>
                        Delete
                    </Button>
                </Section>
            )
        }));

    useEffect(() => {
        id && getCollaborationsRequestsFx(id);
    }, [id]);

    return (
        <Table
            bottomExtra={
                hasNext &&
                (isMoreLoading ? (
                    <Section justifyCenter marginTop="16px">
                        <Spinner />
                    </Section>
                ) : (
                    <Section justifyCenter marginTop="16px">
                        <LoadMoreSection disabled={hasErrorRequests} onClick={loadMoreCollaborationRequests} />
                    </Section>
                ))
            }
            columns={columns}
            data={getItems()}
            emptyMessage="No Users Found"
            isLoading={isLoading}
            topExtra={
                <SectionBorder borderBottom padding="14px 16px">
                    <Title size="-2">Requests</Title>
                </SectionBorder>
            }
        />
    );
};
