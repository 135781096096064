import greyCopyIcon from 'v1/assets/grey_copy_icon.svg';
import verifiedImg from 'v1/assets/is_trusted.svg';
import { CopyButton } from 'v1/components/common/buttons/CopyButton';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { Span } from 'v1/components/common/typography/Span/styles';
import { OverflowAutoLayout } from 'v1/components/layouts/OverflowAutoLayout';
import { SubtitleIdLink } from 'v1/components/layouts/blocks/PropertyBlock';

import React from 'react';
import {
    EllipsisTableText,
    Table,
    TableData,
    TableHeader,
    TableRow,
    TableWrapper
} from 'v1/components/layouts/descriptionLayouts/ProductDescription/styles';
import { Column, Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { noContentMessage } from 'v1/constants/notifications';
import { dirtyBlue } from 'v1/constants/styles/colors';
import { primaryBorderRadius, tableBorder } from 'v1/constants/styles/sizes';
import { Title } from 'v2/components/ui/typography/Title';
import { routePaths } from 'v2/constants/routes';
import { statisticsTableColumns } from './constants';
import { ItemSpan, ItemWrapper } from './styles';

interface Props extends YEAY.QueryVideoStatisticsResponse {
    removeItem?: keyof YEAY.VideoStatisticsResponse;
}
interface DataTable extends YEAY.QueryVideoStatisticsResponse {
    [key: string]: any;
}
export const StatisticsTable = ({ items, removeItem }: Props) => {
    const data = items?.map(({ userId, videoId, userInfo, videoInfo, ...rest }) => {
        const dataTable: DataTable = {
            ...rest,
            userId: userId ? (
                <ItemWrapper alignCenter justifyCenter noWrap marginBottom="0" opacity={userInfo?.isDisabled ? 0.3 : 1}>
                    <Column alignCenter justifyCenter marginRight="10px">
                        <SubtitleIdLink id={userId} linkRoute={routePaths.users}>
                            <EllipsisTableText width={'52px'}>
                                <ItemSpan
                                    fontSize="10px"
                                    fontWeight="bold"
                                    lineThrough={userInfo?.isDisabled || undefined}
                                >
                                    {userId}
                                </ItemSpan>
                            </EllipsisTableText>
                        </SubtitleIdLink>
                        <EllipsisTableText width={'52px'}>
                            <ItemSpan fontSize="10px" fontWeight="bold" lineThrough={userInfo?.isDisabled || undefined}>
                                {userInfo?.username}
                            </ItemSpan>
                        </EllipsisTableText>
                    </Column>
                    {userInfo?.isTrusted && (
                        <MarginWrapper marginRight="10px">
                            <CustomImg height="12px" src={verifiedImg} width="12px" />
                        </MarginWrapper>
                    )}
                    <MarginWrapper marginRight="10px">
                        <CopyButton
                            customCopyIcon={greyCopyIcon}
                            diameter="30px"
                            subject={userId}
                            success={`Success copy User ID (${userId})`}
                        />
                    </MarginWrapper>
                </ItemWrapper>
            ) : (
                <Title>{noContentMessage}</Title>
            ),
            videoId: videoId ? (
                <ItemWrapper alignCenter justifyCenter noWrap marginBottom="0" opacity={videoInfo?.isDeleted ? 0.3 : 1}>
                    <MarginWrapper marginRight="10px">
                        <SubtitleIdLink id={videoId} linkRoute={routePaths.videos}>
                            <EllipsisTableText width="52px">
                                <ItemSpan
                                    fontSize="10px"
                                    fontWeight="bold"
                                    lineThrough={videoInfo?.isDeleted || undefined}
                                >
                                    {videoId}
                                </ItemSpan>
                            </EllipsisTableText>
                        </SubtitleIdLink>
                    </MarginWrapper>
                    <CopyButton
                        customCopyIcon={greyCopyIcon}
                        diameter="30px"
                        subject={videoId}
                        success={`Success copy Video ID ( ${videoId})`}
                    />
                </ItemWrapper>
            ) : (
                <Title>{noContentMessage}</Title>
            )
        };

        removeItem && delete dataTable[removeItem];
        return dataTable;
    });

    const sumPartsOfTableWidth = statisticsTableColumns
        .map(({ width }) => width)
        .reduce((prev, curr) => prev + curr, 0);

    return (
        <OverflowAutoLayout>
            <TableWrapper border={tableBorder} borderRadius={primaryBorderRadius}>
                <Table>
                    <TableRow>
                        {statisticsTableColumns
                            .filter(({ key }) => removeItem !== key)
                            .map(({ title, width }) => (
                                <TableHeader
                                    key={title}
                                    padding="0 10px"
                                    width={(width * 100) / sumPartsOfTableWidth + '%'}
                                >
                                    {title}
                                </TableHeader>
                            ))}
                    </TableRow>
                    {data &&
                        data.map((dataRow, i) => (
                            <TableRow key={i.toString()} borderTop={tableBorder} color={dirtyBlue}>
                                {statisticsTableColumns
                                    .filter(({ key }) => removeItem !== key)
                                    .map(({ key, width }, i) => (
                                        <TableData
                                            key={i.toString()}
                                            width={(width * 100) / sumPartsOfTableWidth + '%'}
                                        >
                                            <Row alignCenter justifyCenter width="100%">
                                                {typeof dataRow[key] === 'number' ? (
                                                    <Span fontSize="10px" fontWeight="bold">
                                                        {dataRow[key]}
                                                    </Span>
                                                ) : (
                                                    dataRow[key]
                                                )}
                                            </Row>
                                        </TableData>
                                    ))}
                            </TableRow>
                        ))}
                </Table>
            </TableWrapper>
        </OverflowAutoLayout>
    );
};
