import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { defaultBoxShadow, disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const Wrapper = styled.div`
    position: relative;
`;

export const SizeMainButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: ${grey[100]};
    transition: background-color ${transitionTime} linear;

    &:disabled {
        cursor: not-allowed;
    }

    &:hover:not(:disabled) {
        background-color: ${grey[200]};
    }

    &:active:not(:disabled) {
        background-color: ${grey[300]};
    }
`;

export const SizesDropdown = styled(Column)`
    position: absolute;
    bottom: calc(100% + 1px);
    left: 0;
    right: 0;
    width: 100%;
    max-height: 40vh;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};
    ${defaultBoxShadow};
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
`;

export const SizeButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    padding: 8px 10px;
    text-align: left;
    transition: background-color ${transitionTime} linear;

    &:disabled {
        cursor: default;
    }

    &:hover:not(:disabled) {
        background-color: ${grey[100]};
    }

    &:active:not(:disabled) {
        background-color: ${grey[200]};
    }
`;
