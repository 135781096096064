import React, { FC } from 'react';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { textColors } from 'v2/constants/styles/colors';

interface Props {
    title: string;
    center?: boolean;
}

export const HeadCell: FC<Props> = ({ title, center, children }) => (
    <Section alignCenter noWrap justifyCenter={center}>
        <Title isUppercase color={textColors.subtext} size="-2">
            {title}
        </Title>
        <Row marginLeft="2px">{children}</Row>
    </Section>
);
