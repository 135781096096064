import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cardStylesMixin } from 'v2/constants/styles/mixins';

export const CardLink = styled(Link)`
    ${cardStylesMixin};
`;

export const StatesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;
