import { createEffect, createEvent, createStore } from 'effector';
import { defaultRequestsQueryParams } from 'v2/constants/services/collaboration';
import { yecmAPI } from 'v2/services/yecm';

//Events
const getByDefaultParams = createEvent();
const updateRequests = createEvent<YECM.ParticipationsResponseApiResponse>();
const resetStore = createEvent();

//Effects
const getCollaborationsRequestsFx = createEffect({
    handler: async (
        collaborationId: string,
        params: YecmPaths.AdminCollaboration$CollaborationIdParticipants.Get.QueryParameters
    ) =>
        await yecmAPI.collaboration.getCollaborationsRequests(collaborationId, {
            ...params,
            ...defaultRequestsQueryParams
        })
});

const loadMoreCollaborationsRequestsFx = createEffect({
    handler: async ({
        collaborationId,
        params
    }: {
        collaborationId: string;
        params: YecmPaths.AdminCollaboration$CollaborationIdParticipants.Get.QueryParameters;
    }) => await yecmAPI.collaboration.getCollaborationsRequests(collaborationId, params)
});

const deleteCollaborationRequestFx = createEffect({
    handler: async ({ collaborationId, userId }: { collaborationId: string; userId: string }) => {
        try {
            const { isSuccess } = await yecmAPI.collaboration.deleteCollaborationRequest(collaborationId, userId);

            if (!isSuccess) {
                throw new Error();
            }
            return userId;
        } catch {
            return null;
        }
    }
});

//Stores
const $requests = createStore<YECM.ParticipationsResponseApiResponse>({})
    .on([getCollaborationsRequestsFx.doneData, updateRequests], (_, newState) => newState)
    .on(loadMoreCollaborationsRequestsFx.doneData, (state, payload) => ({
        ...payload,
        data: { ...state.data, ...payload.data, items: [...(state.data?.items || []), ...(payload.data?.items || [])] }
    }))
    .reset(resetStore);

const $hasErrorRequests = createStore<boolean>(false)
    .on(getCollaborationsRequestsFx.fail, () => true)
    .on(loadMoreCollaborationsRequestsFx.fail, () => true)
    .reset([getCollaborationsRequestsFx.done, loadMoreCollaborationsRequestsFx.done, getByDefaultParams]);

//Exports
export const requestsEffects = {
    getCollaborationsRequestsFx,
    loadMoreCollaborationsRequestsFx,
    deleteCollaborationRequestFx
};

export const requestsStores = {
    $requests,
    $hasErrorRequests
};

export const requestsEvents = {
    updateRequests
};
