import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
    ${flexCenter};
`;

export const VideoWrapper = styled.div`
    width: 100vw;
    max-width: 360px;
    height: 90vh;
    max-height: 650px;
    border-radius: 8px;
    overflow: hidden;
`;

export const RightContentWrapper = styled(Column)`
    position: absolute;
    left: calc(100% + 8px);
    bottom: 0;
    width: 230px;
    max-height: 650px;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};
    padding: 8px;
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const EmptyWrapper = styled.div`
    width: 100vw;
    max-width: 360px;
    border-radius: 8px;
    overflow: hidden;
    ${flexCenter};
`;
