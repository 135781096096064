import React, { FC } from 'react';
import { SimpleButton } from 'v1/components/common/buttons/SimpleButton/styles';
import { white } from 'v1/constants/styles/colors';
import { Disabled } from 'v1/types/form';
import { NoopClick } from 'v1/types/global';
import { Background, Sizes } from 'v1/types/styles';

interface ButtonProps extends NoopClick, Background, Disabled, Sizes {}

export const ModalButton: FC<ButtonProps> = ({ width, ...props }) => (
    <SimpleButton color={white} fontSize="11px" fontWeight="500" {...props} height="45px" width={width || '103px'} />
);
