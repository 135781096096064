import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors } from 'v2/constants/styles/colors';
import { formatNumber } from 'v2/utils/formats';
import { BadgeWrapper, TotalBadgeWrapper } from './styles';

export interface BadgeProps {
    isUppercase?: boolean;
    status?: number;
}

export const Badge: FC<BadgeProps> = ({ children, status, isUppercase }) => (
    <BadgeWrapper alignTextCenter isUppercase={isUppercase} size="-2" status={status}>
        {children}
    </BadgeWrapper>
);

interface TotalBadgeProps {
    title?: string;
    count?: number;
    text?: string;
}

export const TotalBadge: FC<TotalBadgeProps> = ({ text, title = 'Total', count = 0 }) => (
    <TotalBadgeWrapper>
        <Text alignTextCenter color={brandColors.white} size="-2">
            {text || `${title} ${formatNumber(count)}`}
        </Text>
    </TotalBadgeWrapper>
);
