import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { SortButton } from 'v2/components/ui/buttons/SortButton';
import { getNextSortValue, getSortOrderState } from './utils';

export const ActivitySort: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { sortByActivityAsc, pageIndex } = useStore($usersFilters);
    const sortOrder = getSortOrderState(sortByActivityAsc);

    const handleChange = () => {
        const newSortValue = getNextSortValue(sortOrder);
        addFilters({ sortByActivityAsc: newSortValue, pageIndex });
    };

    return <SortButton sortState={sortOrder} onClick={handleChange} />;
};
