import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { authStores } from 'v2/stores/auth';

export const useUsersSearch = ({ store }: UsersFilterProps) => {
    const { $usersFilters } = store.stores;
    const { setOverwriteFilters, setUserId } = store.events;

    const isSuperAdmin = useStore(authStores.$isSuperAdmin);

    const { username, email, mobileNumber, userId } = useStore($usersFilters);

    const [searchItems, setSearchItems] = useState([
        {
            option: {
                value: 'userID',
                label: 'User ID'
            },
            onSearch: (userId: string) => setUserId(userId.trim() || undefined)
        },
        {
            option: {
                value: 'username',
                label: 'Username'
            },
            onSearch: (username: string) =>
                setOverwriteFilters({
                    username: username.trim() || undefined,
                    mobileNumber: undefined,
                    email: undefined
                })
        }
    ]);

    useEffect(() => {
        if (isSuperAdmin) {
            setSearchItems(items => [
                ...items,
                {
                    option: {
                        value: 'mobileNumber',
                        label: 'Phone'
                    },
                    onSearch: (mobileNumber: string) =>
                        setOverwriteFilters({
                            mobileNumber: mobileNumber.trim() || undefined,
                            email: undefined,
                            username: undefined
                        })
                },
                {
                    option: {
                        value: 'email',
                        label: 'Email'
                    },
                    onSearch: (email: string) =>
                        setOverwriteFilters({
                            email: email.trim() || undefined,
                            mobileNumber: undefined,
                            username: undefined
                        })
                }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuperAdmin]);

    return {
        searchItems,
        values: { username, email, mobileNumber, userId }
    };
};
