// Participation States
export enum ParticipationStatesKey {
    None = 0,
    EnrollmentRequested = 5,
    Enrolled = 10,
    Submitted = 20,
    Published = 30,
    Ended = 1000
}

// Participation Ended Reason
export enum ParticipationEndedReasonKey {
    None = 0,
    SubmittedAndPublished = 200,
    SubmittedOnly = 201,
    SubmissionFailed = 500,
    DeadlineMissed = 501,
    SubmissionReported = 502,
    Leave = 503,
    KickedOut = 504,
    ParticipationRequestRejected = 505
}

// Submission States
export enum SubmissionStatesKey {
    None = 0,
    Pending = 10,
    Curating = 20,
    Ended = 1000
}

// Submission Ended Reason
export enum SubmissionEndedReasonKey {
    None = 0,
    Success = 200,
    CurationFailed = 500,
    Reported = 501,
    DeadlineMissed = 503
}

// Publishing State
export enum PublishingStateKey {
    None = 0,
    AwaitingSubmission = 10,
    Pending = 20,
    Verifying = 30,
    Ended = 1000
}

// Publishing Ended Reason
export enum PublishingEndedReason {
    None = 0,
    Published = 200,
    NotPublished = 500,
    CollaborationStopped = 501,
    NotVerified = 503,
    MediaRejected = 504
}

// KickOut Reason
export enum KickOutReason {
    None = 0,
    GenericNoReason = 1,
    LanguageRequirements = 2,
    RejectedMediaAsset = 3
}
