import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { routePaths } from 'v2/constants/routes';
import { UserRole } from 'v2/constants/services/users';
import { AddAgentModal } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/AddAgentModal';
import { EditAgentModal } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/EditAgentModal';
import { authStores } from 'v2/stores/auth';
import { addAgentModal, editAgentModal } from 'v2/stores/initialize-modals';
import { StyledLink } from './styles';

interface Props {
    user: YEAY.AdminGetUserCommon;
}

export const AgentProperty: FC<Props> = ({ user }) => {
    const isSuperAdmin = useStore(authStores.$isSuperAdmin);

    const hasAgent = !!user.agentInfo;
    const isAgent = user.roles?.includes(UserRole.Agent);

    const addAgent = () => {
        addAgentModal.openModal({ userId: user.id || '' });
    };

    const editAgent = () => {
        editAgentModal.openModal({ user });
    };

    return (
        <PropertyLayout
            extra={
                isSuperAdmin && !isAgent ? (
                    <Button
                        noPadding
                        buttonType="text-black"
                        textSize="small"
                        onClick={hasAgent ? editAgent : addAgent}
                    >
                        {hasAgent ? 'Edit agent' : 'Add agent'}
                    </Button>
                ) : undefined
            }
            title="Agent"
        >
            {hasAgent ? (
                <StyledLink to={`${routePaths.users}/${user.agentInfo?.userId}`}>
                    <Text isSemibold size="-2">
                        @{user.agentInfo?.username}
                    </Text>
                </StyledLink>
            ) : (
                <Text isSemibold size="-2">
                    -
                </Text>
            )}
            {hasAgent ? <EditAgentModal /> : <AddAgentModal />}
        </PropertyLayout>
    );
};
