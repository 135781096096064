import styled from 'styled-components';
import { grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';

export const Wrapper = styled.div`
    width: fit-content;
    padding: 4px 8px;
    border-bottom-right-radius: 4px;
    background-color: ${grey[300]};
    ${flexCenter};
    text-align: center;
`;
