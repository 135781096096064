import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';
import { OptionsItemProps } from '../types';

export const OptionWrapper = styled.button<OptionsItemProps>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
`;

export const DefaultOption = styled.div<OptionsItemProps>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    padding: 4px 8px;
    text-align: left;
    background-color: ${({ isSelected }) => (isSelected ? brandColors.black : brandColors.white)};
    color: ${({ isSelected }) => (isSelected ? brandColors.white : brandColors.black)};
    transition: background-color ${transitionTime} linear, color ${transitionTime} linear;

    &:hover:not(:disabled) {
        background-color: ${grey[100]};
        color: ${brandColors.black};
    }
`;
