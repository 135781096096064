import styled from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';
import { SfUiTextFontFamily } from 'v2/constants/styles/fonts';
import { ellipsisMixin } from 'v2/constants/styles/mixins';

interface Props {
    isSemibold?: boolean;
}

export const Wrapper = styled.div`
    display: table;
    table-layout: fixed;
    width: 100%;
    min-width: 50px;
    margin-left: 10px;
`;

export const StartEllipsisText = styled.div<Props>`
    ${ellipsisMixin};
    display: table-cell;
    direction: rtl;
    text-align: right;
    font-family: ${SfUiTextFontFamily};
    font-size: 12px;
    line-height: 18px;
    color: ${brandColors.black};
    font-weight: ${({ isSemibold }) => (isSemibold ? 600 : 400)};
`;
