import { useStore } from 'effector-react';
import { isNull } from 'lodash';
import React, { FC, useState } from 'react';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { VideoCurationReason } from 'v2/constants/services/videos';
import { curateVideoModal } from 'v2/stores/initialize-modals';
import { videosEffects } from 'v2/stores/videos';
import { Noop } from 'v2/types/types';
import { CurateVideoForm } from './CurateVideoForm';

const { $modal, $state, closeModal } = curateVideoModal;

export enum CurationVariant {
    None,
    Accept,
    Reject
}

interface Props {
    videoId: string;
    onCancel: Noop;
}

const CurateVideoModalInner: FC<Props> = ({ videoId, onCancel }) => {
    const [selectedVariant, setSelectedVariant] = useState<CurationVariant | null>(null);
    const [rejectReason, setRejectReason] = useState<VideoCurationReason>(VideoCurationReason.None);
    const [isAgreed, setIsAgreed] = useState(false);

    const isDisabled =
        !isAgreed ||
        isNull(selectedVariant) ||
        (selectedVariant === CurationVariant.Reject && rejectReason === VideoCurationReason.None);

    const handleVariantChange = (variant: CurationVariant | null) => {
        setSelectedVariant(variant);
    };

    const handleRejectReasonChange = (reason: VideoCurationReason) => {
        setRejectReason(reason);
    };

    const handleIsAgreedChange = (value: boolean) => {
        setIsAgreed(value);
    };

    const handleSubmit = () => {
        if (isDisabled || !videoId) {
            return;
        }

        videosEffects.curateVideoFx({ reason: rejectReason, videoId });
        closeModal();
    };

    return (
        <ModalLayout submitDisabled={isDisabled} title="Curate Video" onCancel={onCancel} onSubmit={handleSubmit}>
            <CurateVideoForm
                isAgreed={isAgreed}
                rejectReason={rejectReason}
                variant={selectedVariant}
                onIsAgreedChange={handleIsAgreedChange}
                onReasonChange={handleRejectReasonChange}
                onVariantChange={handleVariantChange}
            />
        </ModalLayout>
    );
};

export const CurateVideoModal = () => {
    const { visible } = useStore($modal);
    const videoId = useStore($state);

    const close = () => closeModal();

    return (
        <ModalWrapper visible={visible} onClose={close}>
            <CurateVideoModalInner videoId={videoId} onCancel={close} />
        </ModalWrapper>
    );
};
