import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { Noop } from 'v2/types/types';
import { AlertWrapper } from './styles';

export type AlertType = 'success' | 'error';

export interface AlertProps {
    type?: AlertType;
    onClick?: Noop;
}

export const Alert: FC<AlertProps> = ({ type = 'success', onClick, children }) => {
    if (!children) {
        return null;
    }

    return (
        <AlertWrapper type={type} onClick={onClick}>
            <Text color="inherit" size="-1">
                {children}
            </Text>
        </AlertWrapper>
    );
};
