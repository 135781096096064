import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    hasClick?: boolean;
}

export const TagStyled = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    width: fit-content;
    padding: 4px 8px;
    border-radius: 32px;
    text-align: start;
    align-items: center;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: ${brandColors.white};
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    .close-icon {
        color: ${brandColors.black};
    }

    &:focus {
        background-color: ${brandColors.black};
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.7);

        .close-icon {
            color: ${brandColors.white};
        }
    }

    &:hover:not(:focus) {
        background-color: ${grey[100]};
    }
`;

export const CloseWrapper = styled.div`
    margin-left: 8px;
    width: 8px;
    height: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;
