import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { CollaborationCard } from 'v2/components/cards/CollaborationCard';
import { Empty } from 'v2/components/common/Empty';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { CollaborationsFilterLayout } from 'v2/pages/Collaborations/CollaborationsFilterLayout';
import { collaborationsPageStore } from 'v2/stores/collaborations';
import { ContentWrapper } from './styles';

const { effects, events, stores } = collaborationsPageStore;

export const Collaborations = () => {
    const data = useStore(stores.$collaborations);
    const isLoading = useStore(effects.getCollaborationsFx.pending);
    const hasError = useStore(stores.$hasError);

    const collaborations = data?.items;
    const hasNext = data?.hasNext;
    const totalCount = data?.totalCount || 0;

    const loadMoreCollaborations = () => {
        if (!hasNext) return;

        const newPageIndex = (data?.pageIndex || 0) + 1;
        events.addFilters({ pageIndex: newPageIndex });
    };

    useEffect(
        () => () => {
            events.resetCollaborations();
        },
        []
    );

    return (
        <MainLayout>
            <CollaborationsFilterLayout totalCount={totalCount}>
                <ContentWrapper>
                    {collaborations && collaborations?.length > 0 && (
                        <CardsGridLayout>
                            {collaborations &&
                                collaborations.map(item => <CollaborationCard key={item.id} data={item} />)}
                        </CardsGridLayout>
                    )}
                    {isLoading && (
                        <Section justifyCenter marginTop="16px">
                            <Spinner />
                        </Section>
                    )}

                    {!isLoading && collaborations && collaborations.length === 0 && (
                        <Empty title="No Collaborations Found" />
                    )}

                    <Section marginTop="16px">
                        {!isLoading && hasNext && (
                            <LoadMoreSection disabled={hasError} onClick={loadMoreCollaborations} />
                        )}
                    </Section>
                </ContentWrapper>
            </CollaborationsFilterLayout>
        </MainLayout>
    );
};
