import { format } from 'date-fns';
import { useStore } from 'effector-react';
import React from 'react';
import emptyStateImage from 'v1/assets/icons/blacklist_empty_state_icon.svg';
import { SortableUsersTable } from 'v1/components/common/tables/SortableUsersTable';
import { CatalogContainer } from 'v1/components/layouts/containers/CatalogContainer';
import { BlockedUsersFilterLayout } from 'v1/components/layouts/filterLayouts/BlockedUsersFilterLayout';
import { Empty } from 'v1/components/layouts/resultLayouts/Empty';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { ReasonDeletion } from 'v1/constants/defaults/users';
import { grey25 } from 'v1/constants/styles/colors';
import { useSortableData } from 'v1/hooks/useSortableData';
import { columns, columnSizes, defaultMessage, emptyStateImageWrapperDiameter } from 'v1/pages/BlockedUsers/constants';
import { blockedUsersStores } from 'v1/stores/users/disabledUsers';
import { DataTable } from 'v1/types/data';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { routePaths } from 'v2/constants/routes';
import { Reason, TableDataSpan, TableWrapper } from './styles';

type ReasonTypes = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const BlockedUsers = () => {
    const { items, totalRecords } = useStore(blockedUsersStores.blockedUsers);
    const loading = useStore(blockedUsersStores.loading);
    const sortableItems = items ? items : [];
    const { sortedItems, requestSort, sortConfig } = useSortableData(sortableItems);

    const dataTable: DataTable[] | undefined = sortedItems?.map(
        ({ id, email, mobileNumber, disablingInfo, utcUpdated }) => {
            const comment = disablingInfo.length ? disablingInfo[disablingInfo.length - 1].comment : '';
            const disablerId = disablingInfo.length ? disablingInfo[disablingInfo.length - 1].disablerId : '';
            const reasons = disablingInfo.length ? disablingInfo[disablingInfo.length - 1].reasons : [];
            const msUTC = Date.parse(utcUpdated);
            const creationDate = format(new Date(msUTC), 'dd.MM.yyyy');

            return {
                cells: [
                    <Section key={id} alignCenter justifyCenter>
                        <TableDataSpan>{id}</TableDataSpan>
                    </Section>,

                    <Section key={utcUpdated} alignCenter justifyCenter>
                        <TableDataSpan>{creationDate}</TableDataSpan>
                    </Section>,

                    <Section key={id} alignCenter justifyCenter>
                        <TableDataSpan>{email}</TableDataSpan>
                    </Section>,

                    <Section key={id} alignCenter justifyCenter>
                        <TableDataSpan>{mobileNumber}</TableDataSpan>
                    </Section>,
                    <Section key={id} alignCenter justifyCenter>
                        <TableDataSpan>{disablerId}</TableDataSpan>
                    </Section>,

                    <Section key={id} alignCenter justifyCenter>
                        <TableDataSpan>{comment}</TableDataSpan>
                    </Section>,

                    <Section key={id} alignCenter justifyCenter noWrap>
                        {reasons.map((reason: ReasonTypes) => (
                            <Reason key={reason}>{ReasonDeletion[reason]} </Reason>
                        ))}
                    </Section>
                ],
                route: `${routePaths.blockedUsers}/${id}`
            };
        }
    );

    return (
        <MainLayout>
            <BlockedUsersFilterLayout totalRecords={totalRecords}>
                <CatalogContainer totalRecords={totalRecords}>
                    {loading ? (
                        <Section justifyCenter>
                            <Spinner />
                        </Section>
                    ) : items?.length !== 0 ? (
                        <TableWrapper>
                            <SortableUsersTable
                                columnSizes={columnSizes}
                                columns={columns}
                                data={dataTable}
                                sortState={sortConfig}
                                onSort={requestSort}
                            ></SortableUsersTable>
                        </TableWrapper>
                    ) : (
                        <Empty
                            emptyLayoutMarginTop="100px"
                            imageHeight="18px"
                            imageSrc={emptyStateImage}
                            imageWidth="22px"
                            imageWrapperBackgroundColor={grey25}
                            imageWrapperBorderRadius="50%"
                            imageWrapperHeight={emptyStateImageWrapperDiameter}
                            imageWrapperWidth={emptyStateImageWrapperDiameter}
                            title={defaultMessage}
                            titleFontSize="16px"
                            titleFontWeight="500"
                            titleLineHeight="26px"
                            titleWidth="390px"
                        />
                    )}
                </CatalogContainer>
            </BlockedUsersFilterLayout>
        </MainLayout>
    );
};
