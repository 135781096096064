import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';

interface Props {
    emoji?: string;
    value?: string | number | null;
    isSemibold?: boolean;
}

export const EmojiProperty: FC<Props> = ({ emoji, value, isSemibold = false }) => (
    <Row alignCenter>
        {emoji && (
            <Column marginRight="4px">
                <Text size="-2">
                    <span role="img">{emoji}</span>
                </Text>
            </Column>
        )}

        <Text isSemibold={isSemibold} size="-2">
            {value || '-'}
        </Text>
    </Row>
);
