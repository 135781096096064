import { useStore } from 'effector-react';
import React from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { TransactionType } from 'v2/constants/services/transactions';
import { transactionsEvents, transactionsStores } from 'v2/stores/transactions';

const typeOptions: SelectOption[] = [
    { value: undefined, label: 'All' },
    { value: TransactionType.PayPalDeposit, label: 'PayPal Deposit' },
    { value: TransactionType.ServiceFeeRevenue, label: 'Service Fee Revenue' },
    { value: TransactionType.PayPalWithdrawalFeeRevenue, label: 'PayPal Withdrawal Fee Revenue' },
    { value: TransactionType.CollaborationVideoReward, label: 'Collaboration Video Reward' },
    { value: TransactionType.CollaborationSocialPostReward, label: 'Collaboration Social Post Reward' },
    { value: TransactionType.PayPalWithdrawalEscrowTransfer, label: 'PayPal Withdrawal Escrow Transfer' }
];

export const TransactionsType = () => {
    const { TransactionType: type } = useStore(transactionsStores.$transactionsFilters);

    const onChangeType = (type: SelectOption) => {
        transactionsEvents.setOverwriteFilters({
            TransactionType: type.value
        });
    };

    return <Select wide minWidth={220} options={typeOptions} title="Type" value={type} onChange={onChangeType} />;
};
