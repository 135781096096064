import React, { FC, ReactNode } from 'react';
import { CheckMark } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { Wrapper } from './styles';

interface Props {
    isSelected?: boolean;
    isRounded?: boolean;
    leftExtra?: ReactNode;
}

export const CheckMarkOption: FC<Props> = ({ isSelected, isRounded, leftExtra, children }) => (
    <Wrapper>
        <Row alignCenter noWrap>
            {leftExtra && <Column marginRight="8px">{leftExtra}</Column>}

            <Column paddingRight="8px">
                <Text size="-2">{children}</Text>
            </Column>
        </Row>

        <CheckMark checked={isSelected} isRounded={isRounded} />
    </Wrapper>
);
