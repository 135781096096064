import React, { FC, FocusEvent, InputHTMLAttributes, MouseEvent, ReactNode, useState } from 'react';
import { PasswordOffIcon, PasswordOnIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { BottomWrapper, InputWrapper, Label, StyledInput } from './styles';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label?: string;
    error?: string;
    hint?: string;
    prefix?: ReactNode;
    suffix?: ReactNode;
    wide?: boolean;
    regExp?: RegExp;
}

export const Input: FC<InputProps> = ({
    label,
    error,
    regExp,
    prefix,
    suffix,
    wide = false,
    type = 'text',
    required,
    disabled,
    onFocus,
    onBlur,
    value,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [visibleType, setVisibleType] = useState(type);

    const isPassword = type === 'password';
    const inputValue = regExp && value ? value.toString().replace(regExp, '') : value;

    const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    const handleVisiblePassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisibleType(state => (state === 'password' ? 'text' : 'password'));
    };

    return (
        <Label wide={wide}>
            {label && (
                <Section padding="4px 8px">
                    <Text size="-1">{label}</Text>
                </Section>
            )}

            <InputWrapper hasError={!!error} isDisabled={disabled} isFocused={isFocused}>
                {prefix && (
                    <Column marginLeft="10px">
                        <Text color={grey[400]} size="2">
                            {prefix}
                        </Text>
                    </Column>
                )}

                <StyledInput
                    disabled={disabled}
                    required={required}
                    type={visibleType}
                    value={inputValue}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    {...props}
                />

                {suffix && <Column marginRight="10px">{suffix}</Column>}

                {value && isPassword && (
                    <Column marginRight="10px">
                        <IconButton disabled={disabled} type="button" onClick={handleVisiblePassword}>
                            {visibleType === 'password' ? <PasswordOffIcon /> : <PasswordOnIcon />}
                        </IconButton>
                    </Column>
                )}
            </InputWrapper>

            {!!error && (
                <BottomWrapper>
                    {error && (
                        <Text color={brandColors.red} size="-2">
                            {error}
                        </Text>
                    )}
                </BottomWrapper>
            )}
        </Label>
    );
};
