import Table from 'rc-table';
import styled, { css } from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey, textColors } from 'v2/constants/styles/colors';
import { PoppinsFontFamily, SfUiTextFontFamily } from 'v2/constants/styles/fonts';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const tableHeadTextStyles = css`
    font-family: ${PoppinsFontFamily};
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
`;

export const tableCellTextStyles = css`
    font-family: ${SfUiTextFontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`;

interface WrapperProps {
    disabled?: boolean;
}

export const Wrapper = styled(Column)<WrapperProps>`
    width: 100%;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};
    border-radius: 4px;
    position: relative;
    ${({ disabled }) => disabled && 'pointer-events: none'};
`;

interface Props {
    rowPointer?: boolean;
    isEmpty?: boolean;
    cellCenter?: boolean;
    isTextNoWrap?: boolean;
}

export const StyledTable = styled(Table)<Props>`
    width: 100%;

    .rc-table-container,
    .rc-table-content {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        .rc-table-thead {
            height: 38px;
            text-transform: uppercase;
            color: ${textColors.subtext};
            border-bottom: 1px solid ${brandColors.grey};

            ${({ isEmpty }) =>
                isEmpty &&
                css`
                    display: none;
                `};

            .rc-table-cell {
                ${tableHeadTextStyles};
                padding-left: 12px;
                padding-right: 12px;
                white-space: pre;

                ${({ cellCenter }) =>
                    cellCenter
                        ? css`
                              text-align: center !important;
                              &:first-child {
                                  text-align: left !important;
                              }
                          `
                        : css`
                              text-align: left !important;
                              &:last-child {
                                  text-align: right !important;
                              }
                          `}
            }
        }

        .rc-table-content {
            overflow-x: auto;
        }

        .rc-table-row {
            width: 100%;
            height: 48px;
            transition: background-color ${transitionTime} linear;

            .rc-table-cell {
                ${tableCellTextStyles};
                padding-left: 12px;
                padding-right: 12px;
                height: inherit;
                white-space: ${({ isTextNoWrap }) => (isTextNoWrap ? 'pre' : 'pre-line')};
                color: ${textColors.main};

                ${({ cellCenter }) =>
                    cellCenter
                        ? css`
                              text-align: center;
                              &:first-of-type {
                                  text-align: left;
                              }
                          `
                        : css`
                              text-align: left;
                              &:last-of-type {
                                  text-align: right;
                              }
                          `}
            }

            &:not(:last-child) {
                border-bottom: 1px solid ${brandColors.grey};
            }

            ${({ rowPointer }) =>
                rowPointer &&
                css`
                    cursor: pointer;
                    &:hover {
                        background-color: ${grey[100]};
                    }
                `};
        }

        &::-webkit-scrollbar {
            width: 2px;
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${grey[400]};
            border-radius: 8px;
        }
    }
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    z-index: 2;
`;
