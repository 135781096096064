import React, { FC, ReactNode } from 'react';
import { EllipsisText } from 'v2/components/ui/typography/EllipsisText';
import { Text } from 'v2/components/ui/typography/Text';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { Disabled, HTMLButtonType } from 'v2/types/form';
import { ReactClick } from 'v2/types/react';
import { StyledA, StyledButton, StyledLink } from './styles';

export type ButtonType = 'primary' | 'secondary';
export type TextButtonType = 'text-green' | 'text-red' | 'text-black' | 'text-reset';
export type ButtonTextSize = 'default' | 'small';
export type ButtonAlignType = 'start' | 'center' | 'end';

export interface ButtonProps extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    buttonType?: ButtonType | TextButtonType;
    textSize?: ButtonTextSize;
    wide?: boolean;
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
    iconSize?: string;
    isUppercase?: boolean;
    noPadding?: boolean;
    align?: ButtonAlignType;
    href?: string;
    isExternal?: boolean;
    isEllipsis?: boolean;
}

const ButtonWrapper: FC<Omit<ButtonProps, 'icon' | 'iconPosition' | 'isUppercase' | 'textSize'>> = ({
    children,
    href,
    isExternal,
    disabled,
    wide,
    onClick,
    type = 'button',
    buttonType = 'primary',
    noPadding = false,
    align = 'center',
    ...props
}) => {
    const isText = buttonType.includes('text');

    const commonProps = {
        align,
        buttontype: buttonType,
        istext: isText ? 1 : 0,
        nopadding: noPadding ? 1 : 0,
        wide: wide ? 1 : 0,
        ...props
    };

    if (href) {
        return isExternal ? (
            <StyledA href={href} rel="noopener" target="_blank" {...commonProps}>
                {children}
            </StyledA>
        ) : (
            <StyledLink to={href} {...commonProps}>
                {children}
            </StyledLink>
        );
    }

    return (
        <StyledButton disabled={disabled} type={type} onClick={onClick} {...commonProps}>
            {children}
        </StyledButton>
    );
};

export const Button: FC<ButtonProps> = ({
    icon,
    isUppercase,
    children,
    textSize = 'default',
    iconPosition = 'left',
    iconSize = '16px',
    isEllipsis = true,
    ...props
}) => (
    <ButtonWrapper {...props}>
        {icon && iconPosition === 'left' && (
            <Column marginRight="8px">
                <IconWrapper size={iconSize}>{icon}</IconWrapper>
            </Column>
        )}

        {isEllipsis ? (
            <EllipsisText
                alignTextCenter
                isSemibold
                color="inherit"
                isUppercase={isUppercase}
                size={textSize === 'default' ? '-1' : '-2'}
            >
                {children}
            </EllipsisText>
        ) : (
            <Text
                alignTextCenter
                isSemibold
                noTextWrap
                color="inherit"
                isUppercase={isUppercase}
                size={textSize === 'default' ? '-1' : '-2'}
            >
                {children}
            </Text>
        )}

        {icon && iconPosition === 'right' && (
            <Column marginLeft="8px">
                <IconWrapper size={iconSize}>{icon}</IconWrapper>
            </Column>
        )}
    </ButtonWrapper>
);
