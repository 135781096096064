import styled, { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { defaultInputStyles } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface InputProps {
    hasError?: boolean;
}

export const StyledInput = styled.input<InputProps>`
    ${defaultInputStyles};
    width: 100%;
    padding: 10px 8px;
    text-align: center;
    border: 1px solid ${({ hasError }) => (hasError ? brandColors.red : grey[400])};
    border-radius: 4px;
    transition: border-color ${transitionTime} linear;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ${({ hasError }) =>
        !hasError &&
        css`
            &:hover,
            &:focus {
                border-color: ${brandColors.black};
            }
        `}
`;
