export const defaultEngagementsQueryParams: YecmPaths.CollaborationsEngagements.Get.QueryParameters = {
    pageIndex: 0,
    limit: 20,
    count: true
};

export const defaultDownloadEngagementsParams: Omit<
    YecmPaths.CollaborationsEngagementsDownload.Get.QueryParameters,
    'collaborationId'
> = {
    pageIndex: 0,
    limit: 100,
    count: true
};
