import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    isFocus?: boolean;
}

export const SearchWrapper = styled(Section)<Props>`
    min-height: 58px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 8px 18px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color ${transitionTime} linear, border-color ${transitionTime} linear;

    &:hover {
        ${({ isFocus }) =>
            !isFocus &&
            css`
                background-color: ${grey[100]};
                border-color: ${grey[400]};
            `};
    }

    ${({ isFocus }) =>
        isFocus &&
        css`
            background-color: ${grey[200]};
            border-color: ${brandColors.black};
        `};
`;
