import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { grey } from 'v2/constants/styles/colors';
import { cardStylesMixin, ellipsisMixin } from 'v2/constants/styles/mixins';
import { BorderRadius, Sizes } from 'v2/types/styles';

interface EllipsisTextProps {
    maxWidth?: string;
}

export const EllipsisText = styled(Text)<EllipsisTextProps>`
    ${ellipsisMixin};
    max-width: ${({ maxWidth }) => maxWidth || 'fit-content'};
`;

interface Props extends BorderRadius, Sizes {
    image?: string;
}

export const ImageWrapper = styled.div<Props>`
    background-image: url(${({ image }) => image});
    background-position: center;
    background-size: cover;
    background-color: ${grey[100]};
    background-repeat: no-repeat;
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
`;

export const CollaborationCardWrapper = styled(Link)`
    ${cardStylesMixin};
`;

export const RewardWrapper = styled(Row)`
    margin-right: 8px;
    margin-bottom: 8px;
`;
