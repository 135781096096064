import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    store: GenericCollaborationsType;
}

export const CollaborationSearch: FC<Props> = ({ store }) => {
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);

    const searchItems = [
        {
            option: {
                value: 'collabId',
                label: 'Collab ID'
            },
            onSearch: (collabId: string) =>
                store.events.overwriteFilters({
                    collaborationId: collabId || undefined
                })
        },
        {
            option: {
                value: 'collabName',
                label: 'Collab Name'
            },
            onSearch: (name: string) =>
                store.events.overwriteFilters({
                    Name: name || undefined
                })
        },
        {
            option: {
                value: 'brandId',
                label: 'Brand ID'
            },
            onSearch: (brandId: string) =>
                store.events.overwriteFilters({
                    organizationId: brandId || undefined
                })
        }
    ];

    return (
        <SearchInputSelect
            defaultSearchText={
                collaborationsFilters.collaborationId ||
                collaborationsFilters.organizationId ||
                collaborationsFilters.Name
            }
            items={searchItems}
        />
    );
};
