import styled from 'styled-components';
import { white } from 'v1/constants/styles/colors';
import { primaryBorderRadius } from 'v1/constants/styles/sizes';
import {
    BackgroundColor,
    BorderProperties,
    BorderRadius,
    Margin,
    MaxSizes,
    MinSizes,
    Padding,
    Sizes
} from 'v1/types/styles';

export interface ContentWrapperProps
    extends Sizes,
        Padding,
        MinSizes,
        MaxSizes,
        BackgroundColor,
        BorderRadius,
        Margin,
        BorderProperties {}

export const ContentWrapper = styled.div<ContentWrapperProps>`
    ${({ height }) => height && `height: ${height}`};
    ${({ width }) => width && `width: ${width}`};
    min-width: ${({ minWidth }) => minWidth || '140px'};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
    ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    border-radius: ${({ borderRadius }) => borderRadius || primaryBorderRadius};
    background-color: ${({ backgroundColor }) => backgroundColor || white};
    ${({ border }) => border && `border: ${border}`};
`;
