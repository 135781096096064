import { createEffect, createStore } from 'effector';
import { yecmAPI } from 'v2/services/yecm';

// Effects
const getExternalNetworksFx = createEffect({
    handler: async () => {
        try {
            const { isSuccess, data } = await yecmAPI.externalNetworks.getExternalNetworks();

            if (isSuccess) {
                return data?.items || [];
            }

            return [];
        } catch (error) {
            return [];
        }
    }
});

// Stores
const $externalNetworks = createStore<YECM.ExternalNetworkResponse[]>([]).on(
    getExternalNetworksFx.doneData,
    (_, newState) => newState
);

// Exports
export const externalNetworksEffects = {
    getExternalNetworksFx
};

export const externalNetworksStores = {
    $externalNetworks
};
