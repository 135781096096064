import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { UserRole, UserSingleTab } from 'v2/constants/services/users';
import { ContentWrapper } from 'v2/pages/User/styles';
import { authStores } from 'v2/stores/auth';
import { singleUserEffects, singleUserEvents, singleUserStores } from 'v2/stores/single-user';
import { userNetworksEffects, userNetworksEvents } from 'v2/stores/user-networks';
import { AgentInfo } from './AgentInfo';
import { UserInfo } from './UserInfo';

interface UserPageParams {
    userId: string | undefined;
}

export const User = () => {
    const { userId } = useParams<UserPageParams>();

    const isAdmin = useStore(authStores.$isAdmin);
    const user = useStore(singleUserStores.$user);
    const isLoading = useStore(singleUserEffects.getUserByIdFx.pending);

    const [activeTab, setActiveTab] = useState(UserSingleTab.UserInfo);
    const isAgentRoleAssigned = user?.roles?.includes(UserRole.Agent);

    const handleTabsChange = (value: UserSingleTab) => {
        setActiveTab(value);
    };

    useEffect(() => {
        setActiveTab(UserSingleTab.UserInfo);

        if (userId) {
            singleUserEffects.getUserByIdFx(userId);
            userNetworksEffects.getUserNetworksFx(userId);
        }

        return () => {
            singleUserEvents.resetUser();
            userNetworksEvents.resetUserNetworks();
        };
    }, [userId]);

    return (
        <MainLayout>
            <DetailsPageLayout>
                <Tabs
                    activeTab={activeTab}
                    tabs={[
                        {
                            id: UserSingleTab.UserInfo,
                            title: 'USER INFO',
                            content: (
                                <ContentWrapper>
                                    <UserInfo isLoading={isLoading} user={user} userId={userId} />
                                </ContentWrapper>
                            )
                        },
                        {
                            hidden: !isAdmin || !isAgentRoleAssigned,
                            id: UserSingleTab.AgentInfo,
                            title: 'AGENT INFO',
                            content: (
                                <ContentWrapper>
                                    <AgentInfo
                                        isAgentRoleAssigned={isAgentRoleAssigned}
                                        userId={user?.id || undefined}
                                    />
                                </ContentWrapper>
                            )
                        }
                    ]}
                    onChange={handleTabsChange}
                />
            </DetailsPageLayout>
        </MainLayout>
    );
};
