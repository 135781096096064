import styled from 'styled-components';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { ContentWrapper } from 'v2/components/wrappers/ContentWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const ActionBlockWrapper = styled(ContentWrapper)`
    height: 32px;
    min-width: 64px;
    width: 64px;
    background-color: ${brandColors.white};
    padding: 8px;
    ${flexCenter};
`;

export const Divider = styled.div`
    margin: 0 5px;
    width: 1px;
    background-color: ${grey[400]};
    height: 10px;
    opacity: 0.8;
`;

export const AbsoluteWrapper = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: background-color ${transitionTime} linear;
`;

export const StyledRelativeWrapper = styled(RelativeWrapper)`
    &:hover {
        ${AbsoluteWrapper} {
            background-color: rgba(0, 0, 0, 0.3);
        }

        ${CardHoverOpacityEffect} {
            opacity: 1;
        }
    }
`;
