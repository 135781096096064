import { combine, createEffect, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { TrendingType, maximumCollaborationsCount } from 'v2/constants/services/trendings';
import { API } from 'v2/services/yasy';
import { sortTrendingItems } from 'v2/stores/trendings/utils';

// Effects

const getTrendingCollaborationsFx = createEffect({
    handler: async () => {
        try {
            const response = await API.trendings.getTrendings({
                pageIndex: 0,
                limit: maximumCollaborationsCount,
                type: TrendingType.Collaboration,
                returnQueryCount: true
            });
            return response;
        } catch {
            return {};
        }
    }
});

const swapCollaborationsFx = createEffect({
    handler: async ({
        firstElement,
        secondElement
    }: {
        firstElement: YEAY.UpdateTrendingOverridesRequest;
        secondElement: YEAY.UpdateTrendingOverridesRequest;
    }) => {
        try {
            await API.trendings.updateItemById(firstElement);
            if (secondElement?.id) {
                await API.trendings.updateItemById(secondElement);
            }
            return { firstElement, secondElement };
        } catch {
            return {};
        }
    }
});

const deleteCollaborationFromTrendingFx = createEffect({
    handler: async (id: string) => {
        try {
            const response = await API.trendings.removeItemById({ id });

            if (response.isSuccess) {
                notify('Collaboration successfully removed from trending!');
            } else {
                throw new Error();
            }

            return id;
        } catch {
            notify('There was a problem removing collaboration, try again.', 'error');
            return '';
        }
    }
});

const addCollaborationToTrendingFx = createEffect({
    handler: async (data: YEAY.CreateTrendingOverridesRequest) => {
        try {
            const response = await API.trendings.createTrending(data);

            return response;
        } catch {
            notify('There was a problem adding collaboration, try again.', 'error');
            return {};
        }
    }
});

// Stores

const $collaborations = createStore<YEAY.QueryTrendingOverridesResponse>({})
    .on(getTrendingCollaborationsFx.doneData, (_, newState) => ({
        ...newState,
        items: (newState?.items || []).sort(sortTrendingItems)
    }))
    .on(deleteCollaborationFromTrendingFx.doneData, (state, payload) => ({
        ...state,
        items: state.items?.filter(item => item.id !== payload),
        totalRecords: typeof state?.totalRecords === 'number' ? state?.totalRecords - 1 : 0
    }))
    .on(addCollaborationToTrendingFx.doneData, (state, payload) => ({
        ...state,
        items: [...(state?.items || []), payload].sort(sortTrendingItems),
        totalRecords: typeof state?.totalRecords === 'number' ? state?.totalRecords + 1 : 0
    }));
const $loading = createStore(getTrendingCollaborationsFx.pending);

const $trendingCollaborations = combine($collaborations, $loading);

// Exports

export const trendingCollaborationStores = {
    $trendingCollaborations
};

export const trendingCollaborationEffects = {
    getTrendingCollaborationsFx,
    swapCollaborationsFx,
    deleteCollaborationFromTrendingFx,
    addCollaborationToTrendingFx
};
