import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { columns } from 'v2/pages/Report/common/constants';
import { reportsListEffects, reportsListEvents, reportsListStores } from 'v2/stores/report/details/reports-list';
import { prepareDataForTable } from './utils';

interface Props {
    isLoading: boolean;
    id?: string;
}

const { getReportsListFx } = reportsListEffects;

export const ReportsTable: FC<Props> = ({ isLoading, id }) => {
    const hasNext = useStore(reportsListStores.$reportsListHasNext);
    const items = useStore(reportsListStores.$reportsListItems);
    const currentPageIndex = useStore(reportsListStores.$reportsListCurrentPage);

    const tableData = prepareDataForTable(items || []);

    const loadMore = () => {
        if (id && hasNext) {
            getReportsListFx({ AssetId: id, pageIndex: currentPageIndex + 1 });
        }
    };

    useEffect(() => {
        id && getReportsListFx({ AssetId: id });

        return () => {
            reportsListEvents.resetReportsList();
        };
    }, [id]);

    return (
        <Column noWrap width="100%">
            <Table
                cellCenter
                bottomExtra={hasNext && <LoadMoreSection onClick={loadMore} />}
                columns={columns}
                data={tableData}
                emptyMessage="No Reports"
                isLoading={isLoading}
            />
        </Column>
    );
};
