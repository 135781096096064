import { createGlobalStyle } from 'styled-components';
import { brandColors, green } from 'v2/constants/styles/colors';
import { SfUiTextFontFamily } from 'v2/constants/styles/fonts';
import { scrollbarMixin } from 'v2/constants/styles/mixins';

export const GlobalStyle = createGlobalStyle`
    body, html, #root {
        width: 100%;
        height: 100%;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: ${SfUiTextFontFamily};

        &::selection {
            background: ${green[900]};
            color: ${brandColors.white};
        }
    }

    form {
        width: 100%;
    }

    ${scrollbarMixin};
`;
