import React from 'react';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { Button } from 'v2/components/ui/buttons/Button';
import { CopyableText } from 'v2/components/ui/typography/CopyableText';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';

// TODO block flow

export const prepareUsersForTable = (users: YECM.UserResponse[]) =>
    users.map(u => {
        const role = u?.roles?.some((value: string) => value.toLowerCase().includes('administrator'))
            ? 'Admin'
            : 'Manager';

        return {
            key: u.userId,
            userId: <CopyableText isSemibold size="-2" subject={u.userId} text={u.userId} />,
            email: u.email,
            role: role,
            date: <DateProperty alignCenter date={u.utcLastAuthentication} />,
            action: (
                <Section justifyCenter>
                    <Button disabled buttonType="text-black" textSize="small">
                        Block
                    </Button>
                </Section>
            )
        };
    });
