import { useStore } from 'effector-react';
import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { statusFilterOptions } from 'v2/constants/services/videos';

export const StatusFilter: FC<VideosFilterProps> = ({ domain }) => {
    const { $videosFilters } = domain.stores;
    const { addFilters } = domain.events;

    const { videoCurationState } = useStore($videosFilters);

    const handleChange = (option: SelectOption) => {
        const type = isNumber(option.value) ? (option.value as YEAY.VideoCurationState) : undefined;
        addFilters({ videoCurationState: type });
    };

    return (
        <Select
            wide
            options={statusFilterOptions}
            placement="bottom-start"
            title="Status"
            value={videoCurationState || undefined}
            onChange={handleChange}
        />
    );
};
