import React, { FC, MouseEvent } from 'react';
import { InfoIcon, TrashIcon } from 'v2/assets/icons';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { AbsoluteCenterAlignment } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { Noop } from 'v2/types/types';
import { Position } from '../Position';
import { ActionBlockWrapper, Divider, StyledRelativeWrapper, Wrapper } from './styles';

interface Props {
    positionIndex?: number;
    showActions?: boolean;
    onInfoClick?: Noop;
    onDeleteClick?: Noop;
}

export const CardLayout: FC<Props> = ({ children, onInfoClick, onDeleteClick, showActions, positionIndex = 0 }) => {
    const handleInfoClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onInfoClick?.();
    };

    const handleDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onDeleteClick?.();
    };

    return (
        <Wrapper>
            <Section paddingBottom="4px">
                <Position index={positionIndex} />
            </Section>

            <StyledRelativeWrapper>
                {showActions && (
                    <AbsoluteCenterAlignment zIndex="5">
                        <CardHoverOpacityEffect>
                            <ActionBlockWrapper>
                                <IconButton size="16px" onClick={handleInfoClick}>
                                    <InfoIcon />
                                </IconButton>

                                <Divider />

                                <IconButton size="16px" onClick={handleDeleteClick}>
                                    <TrashIcon />
                                </IconButton>
                            </ActionBlockWrapper>
                        </CardHoverOpacityEffect>
                    </AbsoluteCenterAlignment>
                )}

                <Column noWrap height="190px" padding="8px 10px" width="100%">
                    {children}
                </Column>
            </StyledRelativeWrapper>
        </Wrapper>
    );
};
