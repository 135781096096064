import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandsEffects, brandsEvents, brandsStores } from 'v2/stores/brands';
import { getFormattedDate } from 'v2/utils/datetime';
import { Wrapper } from './styles';

const { overwriteFilters, setDefaultFilters } = brandsEvents;
const { $brandsFilters } = brandsStores;
const { getBrandsFx } = brandsEffects;

interface Props {
    totalCount?: number;
}

export const BrandsFilterLayout: FC<Props> = ({ totalCount = 0, children }) => {
    const isLoading = useStore(getBrandsFx.pending);
    const filters = useStore($brandsFilters);

    const [dateRange, setDateRange] = useState<DatePickerType>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange as Date[];

    const searchItems = [
        {
            option: {
                value: 'brandId',
                label: 'Brand ID'
            },
            onSearch: (value: string) =>
                overwriteFilters({
                    organizationId: value || undefined
                })
        },
        {
            option: {
                value: 'brandName',
                label: 'Brand Name'
            },
            onSearch: (value: string) =>
                overwriteFilters({
                    organizationName: value || undefined
                })
        }
    ];

    const handleChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            overwriteFilters({
                utcCreatedFrom: getFormattedDate(start.toISOString(), 'yyyy-MM-dd'),
                utcCreatedTo: getFormattedDate(end.toISOString(), 'yyyy-MM-dd')
            });
        }
    };

    const resetFilters = () => {
        setDateRange([new Date(), new Date()]);
        setDefaultFilters();
    };

    return (
        <Column noWrap width="100%">
            <Wrapper>
                <SectionBorder borderBottom noWrap padding="12px 16px">
                    <Section maxWidth="500px">
                        <SearchInputSelect
                            defaultSearchText={filters?.organizationId || filters?.organizationName || ''}
                            items={searchItems}
                        />
                    </Section>
                </SectionBorder>

                <SectionBorder alignCenter justifyBetween noWrap minHeight="48px" padding="8px 16px">
                    <Row alignCenter>
                        <TotalBadge count={totalCount} />

                        <Column marginLeft="16px">
                            <DatePicker
                                endDate={endDate}
                                endPlaceholder=" 2020-12-26"
                                startDate={startDate}
                                startPlaceholder="2020-12-25 -"
                                title="Dates"
                                onChange={handleChangeDate}
                            />
                        </Column>
                    </Row>

                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Button buttonType="text-black" disabled={isLoading} textSize="small" onClick={resetFilters}>
                            Reset
                        </Button>
                    )}
                </SectionBorder>
            </Wrapper>

            {children}
        </Column>
    );
};
