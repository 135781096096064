import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { reviewReportModal } from 'v2/stores/initialize-modals';
import { actionsReportEffects } from 'v2/stores/report/details/actions/init';
import { ButtonsWrapper, ContentWrapper } from './styles';

export const ReviewReportModal = () => {
    const { visible } = useStore(reviewReportModal.$modal);
    const reportId = useStore(reviewReportModal.$state);
    const [isAgreed, setIsAgreed] = useState(false);

    const handleCloseModal = () => {
        reviewReportModal.closeModal();
    };

    const handleReport = (state: boolean) => {
        actionsReportEffects.curateReportFx({
            reportId: reportId,
            isApproved: state
        });
        setIsAgreed(false);
        handleCloseModal();
    };

    const handleIsAgreedChange = (value: boolean) => {
        setIsAgreed(value);
    };

    return (
        <ModalWrapper visible={visible} onClose={handleCloseModal}>
            <ContentWrapper noWrap width="100%">
                <Section marginBottom="30px">
                    <Title size="1">Review Report</Title>
                </Section>

                <Section marginBottom="40px">
                    <Text size="-1">Please review this report carefully before giving feedback.</Text>
                </Section>

                <Checkbox checked={isAgreed} textSize="-2" onChange={handleIsAgreedChange}>
                    Are you sure you want to review this report??
                </Checkbox>
            </ContentWrapper>

            <ButtonsWrapper noWrap>
                <Button buttonType="text-black" disabled={!isAgreed} onClick={() => handleReport(false)}>
                    Reject Report
                </Button>

                <Row marginLeft="20px">
                    <Button buttonType="primary" disabled={!isAgreed} onClick={() => handleReport(true)}>
                        Accept Report
                    </Button>
                </Row>
            </ButtonsWrapper>
        </ModalWrapper>
    );
};
