import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { API } from 'v2/services/yasy';

// Types

interface AgentMembersParams {
    agentId: string;
    params: Paths.AdminAgents$AgentIdMembers.Get.QueryParameters;
}

// Const

export const defaultMembersParams = {
    pageIndex: 0,
    limit: 20
};

// Events

const resetCurrentUserAgentInfo = createEvent();
const resetMemberInfoAboutAgent = createEvent();
const resetMemberAgentInfo = createEvent();
const resetAgentMembers = createEvent();
const resetAgentMembersParams = createEvent();
const resetAgentVideos = createEvent();
const setAgentMembersParams = createEvent<Partial<AgentMembersParams>>();
const setAgentMembers = createEvent<YEAY.AgentMembersDto>();
const setCurrentUserAgentInfo = createEvent<YEAY.AgentDtoApiResponse>();

// Effects

const addAgentRoleFx = createEffect({
    handler: async (data: YEAY.CreateAgentRequest) => {
        try {
            const response = await API.agent.addAgentRole(data);

            if (!response) {
                throw new Error();
            }
            notify('User was successfully made an agent');
            return response;
        } catch (error) {
            notify('There was a problem add role agent, try to refresh the page.', 'error');
            return null;
        }
    }
});

const deleteAgentRoleFx = createEffect({
    handler: async (userId: string) => {
        try {
            const response = await API.agent.deleteAgentRole(userId);

            if (!response) {
                throw new Error();
            }

            notify('User stops being an agent');
            return response;
        } catch (error) {
            notify('There was a problem remove role agent, try to refresh the page.', 'error');
            return null;
        }
    }
});

const addMembersToAgentFx = createEffect({
    handler: async ({ data, agentId }: { data: YEAY.AddMemberToAgentRequest; agentId: string }) => {
        try {
            const response = await API.agent.addMemberToAgent(data, agentId);

            if (!response.isSuccess) {
                throw new Error();
            }

            return response;
        } catch (error) {
            console.log('There was a problem user added to agent, try to refresh the page.', error);
            return null;
        }
    }
});

const removeMemberFromAgentFx = createEffect({
    handler: async ({ userId, agentId }: { userId: string; agentId: string }) => {
        try {
            const response = await API.agent.removeMemberFromAgent(userId, agentId);

            if (!response) {
                throw new Error();
            }

            notify('User removed from agent successfully');
            return response;
        } catch (error) {
            notify('There was a problem removed user from agent, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getUserAgentInfoFx = createEffect({
    handler: async (userId: string) => {
        try {
            const response = await API.agent.getUserAgentInfo(userId);

            if (!response) {
                throw new Error();
            }

            return response;
        } catch (error) {
            notify('There was a problem get user agent info, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getPotentialAgentInfoFx = createEffect({
    handler: async ({ agentId, userId }: { agentId: string; userId: string }) => {
        try {
            const result = await API.agent.getUserAgentInfo(agentId);

            if (!result) {
                throw new Error();
            }

            return { userId, ...result };
        } catch (error) {
            notify('There was a problem get potential agent info, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getMemberInfoAboutAgentFx = createEffect({
    handler: async (userId: string) => {
        try {
            const response = await API.agent.getMemberInfoAboutAgent(userId);

            if (!response) {
                throw new Error();
            }
            return response;
        } catch (error) {
            notify('There was a problem get member info about agent, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getMemberAgentInfoFx = createEffect({
    handler: async (userId: string) => {
        try {
            if (userId) {
                const response = await API.agent.getUserAgentInfo(userId);

                if (!response) {
                    throw new Error();
                }

                return response;
            }
            return null;
        } catch (error) {
            notify('There was a problem agent members info, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getAgentMembersFx = createEffect({
    handler: async ({
        agentId,
        params
    }: {
        agentId: string;
        params: Paths.AdminAgents$AgentIdMembers.Get.QueryParameters;
    }) => {
        try {
            const { data, isSuccess } = await API.agent.getAgentMembers(agentId, params);

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (error) {
            notify('There was a problem get agent members, try to refresh the page.', 'error');
            return null;
        }
    }
});

const updateAgentInfoFx = createEffect({
    handler: async ({ agentId, data }: { agentId: string; data: YEAY.UpdateAgentRequest }) => {
        try {
            const response = await API.agent.updateAgentInfo(agentId, data);

            if (!response) {
                throw new Error();
            }

            notify('Agent commission updated');
            return response;
        } catch (error) {
            notify('There was a problem update agent commission, try to refresh the page.', 'error');
            return null;
        }
    }
});

const getAgentVideosFx = createEffect({
    handler: async ({
        agentId,
        params
    }: {
        agentId: string;
        params: Paths.AdminAgents$AgentIdVideos.Get.QueryParameters;
    }) => {
        try {
            const { data, isSuccess } = await API.agent.getAgentVideos(agentId, params);

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (error) {
            notify('There was a problem get agent videos, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $currentUserAgentInfo = createStore<YEAY.AgentDto>({})
    .on(getUserAgentInfoFx.doneData, (_, payload) => payload?.data)
    .on(setCurrentUserAgentInfo, (state, payload) => ({ ...state, ...payload.data }))
    .reset(resetCurrentUserAgentInfo);

const $memberAgentInfo = createStore<YEAY.AgentDto>({})
    .on(getMemberAgentInfoFx.doneData, (prevState, payload) => (payload ? payload.data : prevState))
    .reset(resetMemberAgentInfo);

const $memberInfoAboutAgent = createStore<YEAY.GetAssignedAgentResponse>({})
    .on(getMemberInfoAboutAgentFx.doneData, (_, payload) => payload?.data)
    .reset(resetMemberInfoAboutAgent);

const $agentMembers = createStore<YEAY.AgentMembersDto>({})
    .on(getAgentMembersFx.doneData, (state, agentMembers) => {
        if (!agentMembers) return;

        return {
            ...(state || {}),
            ...agentMembers,
            items:
                agentMembers?.pageIndex === 0
                    ? agentMembers?.items
                    : [...(state?.items || []), ...(agentMembers?.items || [])]
        };
    })
    .on(setAgentMembers, (_, payload) => payload)
    .reset(resetAgentMembers);

const $agentMembersParams = createStore<AgentMembersParams>({
    agentId: '',
    params: defaultMembersParams
})
    .on(setAgentMembersParams, (state, payload) => ({ ...state, ...payload }))
    .reset(resetAgentMembersParams);

const $hasErrorAgentMembers = createStore<boolean>(false)
    .on(getAgentMembersFx.fail, () => true)
    .reset(getAgentMembersFx.done);

const $agentVideos = createStore<YEAY.GetAgentMemberVideosResponse>({})
    .on(getAgentVideosFx.doneData, (state, agentVideos) => {
        if (!agentVideos) return;

        return {
            ...(state || {}),
            ...agentVideos,
            items:
                agentVideos?.pageIndex === 0
                    ? agentVideos?.items
                    : [...(state?.items || []), ...(agentVideos?.items || [])]
        };
    })
    .reset(resetAgentVideos);

const $hasErrorAgentVideos = createStore<boolean>(false)
    .on(getAgentVideosFx.fail, () => true)
    .reset(getAgentVideosFx.done);

// Exports

export const agentEffects = {
    addAgentRoleFx,
    deleteAgentRoleFx,
    getUserAgentInfoFx,
    addMembersToAgentFx,
    getMemberInfoAboutAgentFx,
    getPotentialAgentInfoFx,
    removeMemberFromAgentFx,
    getMemberAgentInfoFx,
    getAgentMembersFx,
    updateAgentInfoFx,
    getAgentVideosFx
};

export const agentStores = {
    $currentUserAgentInfo,
    $memberInfoAboutAgent,
    $memberAgentInfo,
    $agentMembers,
    $agentMembersParams,
    $hasErrorAgentMembers,
    $agentVideos,
    $hasErrorAgentVideos
};

export const agentEvents = {
    resetCurrentUserAgentInfo,
    resetMemberInfoAboutAgent,
    resetMemberAgentInfo,
    resetAgentMembers,
    setAgentMembersParams,
    resetAgentMembersParams,
    setAgentMembers,
    setCurrentUserAgentInfo,
    resetAgentVideos
};
