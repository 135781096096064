import { isNumber } from 'lodash';

export const sortTrendingItems = (a: YEAY.GetTrendingOverridesResponse, b: YEAY.GetTrendingOverridesResponse) => {
    if (a && b && a.position !== undefined && b.position !== undefined) {
        if (a.position > b.position) {
            return 1;
        } else if (a?.position < b.position) {
            return -1;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
};

interface SwapItems {
    fromItem: YEAY.UpdateTrendingOverridesRequest;
    toItem: YEAY.UpdateTrendingOverridesRequest | null;
}

export const getSwapItems = (swapItems: SwapItems, items?: YEAY.GetTrendingOverridesResponse[] | null) => {
    const { fromItem, toItem } = swapItems;

    return (items || []).map(item => {
        if (item.id === fromItem.id && isNumber(fromItem.position)) {
            return {
                ...item,
                position: fromItem.position
            };
        }

        if (toItem && item.id === toItem.id && isNumber(toItem.position)) {
            return {
                ...item,
                position: toItem.position
            };
        }

        return item;
    });
};
