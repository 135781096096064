import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Header } from 'v2/components/grid/Header';
import { SideBar } from 'v2/components/grid/SideBar';
import { sizes } from 'v2/constants/styles/media';
import { Container } from './styles';

interface Props {
    isHideHeader?: boolean;
}

export const MainLayout: FC<Props> = ({ children, isHideHeader = false }) => {
    const isDesktop = useMediaQuery({ query: `(min-width: ${sizes.laptopSmall}px)` });

    return (
        <>
            {(!isHideHeader || !isDesktop) && <Header />}
            <SideBar />
            <Container>{children}</Container>
        </>
    );
};
