import styled from 'styled-components';
import { grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const TooltipIconWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
    }
`;
