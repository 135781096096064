import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { FlexGrow } from 'v2/components/wrappers/FlexWrapper/styles';
import { textColors } from 'v2/constants/styles/colors';
import { Position } from 'v2/pages/Trendings/TrendingCreators';
import { Noop } from 'v2/types/types';
import { DragSpot } from './styles';

interface Props {
    positionIndex: number;
    textIsVisible?: boolean;
    isHovered?: boolean;
    onClick?: Noop;
    onDragEnd?: Noop;
    onDragOver?: Noop;
}

export const CreatorDragSpot: FC<Props> = ({ positionIndex, textIsVisible = true, ...props }) => (
    <DragSpot {...props}>
        <Position index={positionIndex} />

        {textIsVisible && (
            <FlexGrow alignCenter>
                <Text isSemibold color={textColors.disabled} size="-2">
                    Drag Here
                </Text>
            </FlexGrow>
        )}
    </DragSpot>
);
