import { createGlobalStyle } from 'styled-components';
import { brandColors } from 'v2/constants/styles/colors';
import { SfUiTextFontFamily } from 'v2/constants/styles/fonts';

interface Props {
    maxWidth?: string;
}

export const GlobalStyle = createGlobalStyle<Props>`
.custom-tooltip {
    border-radius: 2px;
    background-color: ${brandColors.black};

  .rc-tooltip-inner {
      max-width: ${({ maxWidth }) => maxWidth || '180px'};
      padding: 9px 8px !important;
      border: none !important;
      font-family: ${SfUiTextFontFamily};
      font-size: 12px;
      line-height: 18px;
      color: ${brandColors.white};
      background: ${brandColors.black} !important;
  }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
      border-bottom-color: ${brandColors.black} !important;
  }

  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
      right: 6%;
  }

  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
      right: 8%;
  }

  &.rc-tooltip-placement-top .rc-tooltip-arrow,
  &.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
      border-top-color: ${brandColors.black} !important;
  }

  &.rc-tooltip-placement-left .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
      border-left-color: ${brandColors.black} !important;
  }

  &.rc-tooltip-placement-right .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
      border-right-color: ${brandColors.black} !important;
  }
}
`;
