import { createEffect, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { yecmAPI } from 'v2/services/yecm';

//Effects

const confirmCollaborationShippingFx = createEffect({
    handler: async ({
        userId,
        collaborationId,
        data
    }: {
        userId: string;
        collaborationId: string;
        data: YECM.ConfirmShipmentRequest;
    }) => {
        try {
            const response = await yecmAPI.collaboration.confirmCollaborationShipping(userId, collaborationId, data);

            return response;
        } catch (e) {
            notify('There was a problem confirm product shipping, try to refresh the page.', 'error');
            return null;
        }
    }
});

const downloadShippingsCsvFx = createEffect({
    handler: async (collaborationId: string) => await yecmAPI.collaboration.downloadShippingsCsv(collaborationId)
});

// Stores

const $downloadedShippings = createStore<string>('').on(downloadShippingsCsvFx.doneData, (_, payload) => payload);

//Exports
export const actionsShippingsEffects = {
    confirmCollaborationShippingFx,
    downloadShippingsCsvFx
};

export const actionsShippingsStores = {
    $downloadedShippings
};
