import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const PropertiesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
`;

interface Props {
    column?: string;
    noBorderRight?: boolean;
    noBorderBottom?: boolean;
}

export const GridItem = styled.div<Props>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    ${({ column }) => column && `grid-column: ${column}`};
    ${({ noBorderRight }) => !noBorderRight && `border-right: 1px solid ${brandColors.grey}`};
    ${({ noBorderBottom }) => !noBorderBottom && `border-bottom: 1px solid ${brandColors.grey}`};
`;

export const StyledLink = styled(Link)`
    text-decoration: underline;
    transition: all ${transitionTime} linear;

    &:hover {
        text-decoration: none;
    }
`;

export const StyledText = styled(Text)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 75%;
`;
