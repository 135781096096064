import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const Wrapper = styled(Section)`
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
    background-color: ${brandColors.white};
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
    }

    &:active {
        background-color: ${brandColors.grey};
    }
`;
