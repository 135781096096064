import { createEffect, createEvent, createStore } from 'effector';
import { defaultBrandUsersParams } from 'v2/constants/services/brands';
import { yecmAPI } from 'v2/services/yecm';

// Events

const resetStores = createEvent();

// Effects

interface GetBrandUsersParams
    extends Partial<Omit<YECM.OrganizationQueryUsersRequest, 'organizationId'>>,
        Pick<YECM.OrganizationQueryUsersRequest, 'organizationId'> {}

const getBrandUsersFx = createEffect({
    handler: async (params: GetBrandUsersParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.brands.brandUsersQuery({
                ...defaultBrandUsersParams,
                ...params
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch {
            return null;
        }
    }
});

// Stores

const $brandUsersResponse = createStore<YECM.OrganizationQueryUsersResponse | null>(null)
    .on(getBrandUsersFx.doneData, (_, payload) => payload)
    .reset(resetStores);

// Exports

export const brandUsersEvents = { resetStores };

export const brandUsersEffects = { getBrandUsersFx };

export const brandUsersStores = { $brandUsersResponse };
