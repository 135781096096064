import React, { useState } from 'react';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { ReportsCampaignManagerTab } from './tabs/ReportsCampaignManagerTab';
import { ReportsYeayAppTab } from './tabs/ReportsYeayAppTab';

enum ReportsPageTab {
    YeayApp,
    YeayCampaignManager
}

export const Reports = () => {
    const [activeTab, setActiveTab] = useState(ReportsPageTab.YeayApp);

    const handleChangeTab = (tab: ReportsPageTab) => {
        setActiveTab(tab);
    };

    return (
        <MainLayout>
            <Tabs
                activeTab={activeTab}
                tabs={[
                    {
                        id: ReportsPageTab.YeayApp,
                        title: 'YEAY APP',
                        content: <ReportsYeayAppTab />
                    },
                    {
                        id: ReportsPageTab.YeayCampaignManager,
                        title: 'YEAY CAMPAIGN MANAGER',
                        content: <ReportsCampaignManagerTab />
                    }
                ]}
                onChange={handleChangeTab}
            />
        </MainLayout>
    );
};
