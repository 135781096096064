import { Formik, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import { FormWrapper } from 'v2/components/form/styles';
import { Button } from 'v2/components/ui/buttons/Button';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { Form } from '../Form';
import { ButtonsWrapper, ContentWrapper } from './styles';
import { CommissionFormValues, createCommissionInitialValues, createCommissionSchema } from './validation';

interface Props {
    handleChangeCommission: (commission: CommissionFormValues) => void;
    commission?: string;
    isEdit?: boolean;
    handleCloseModal: () => void;
}

export const InputCommission: FC<Props> = ({ handleChangeCommission, commission, isEdit, handleCloseModal }) => {
    const editCommissionInitialValues: CommissionFormValues = {
        commission: commission || ''
    };

    const [checked, setChecked] = useState(false);
    const onChangeCheckbox = () => {
        setChecked(!checked);
    };

    if (commission && isEdit) {
        return (
            <Formik
                initialValues={editCommissionInitialValues}
                validationSchema={createCommissionSchema}
                onSubmit={handleChangeCommission}
            >
                {({ handleSubmit, isValid, dirty }: FormikProps<CommissionFormValues>) => (
                    <FormWrapper onSubmit={handleSubmit}>
                        <ContentWrapper>
                            <Form title="Edit commission" />

                            <Section alignCenter noWrap marginBottom="26px">
                                <Row marginRight="8px">
                                    <Checkbox
                                        checked={checked}
                                        disabled={!isValid || !dirty}
                                        onChange={onChangeCheckbox}
                                    />
                                </Row>
                                <Text size="-2">Are you sure you want to add this commission?</Text>
                            </Section>
                        </ContentWrapper>

                        <ButtonsWrapper justifyEnd>
                            <Button buttonType="text-black" onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Row marginLeft="20px">
                                <Button disabled={!isValid || !dirty || !checked} type="submit">
                                    Apply
                                </Button>
                            </Row>
                        </ButtonsWrapper>
                    </FormWrapper>
                )}
            </Formik>
        );
    }

    return (
        <Formik
            initialValues={createCommissionInitialValues}
            validationSchema={createCommissionSchema}
            onSubmit={handleChangeCommission}
        >
            {({ handleSubmit, isValid, dirty }: FormikProps<CommissionFormValues>) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <ContentWrapper>
                        <Form title="Add commission" />

                        <Section alignCenter noWrap marginBottom="26px">
                            <Row marginRight="8px">
                                <Checkbox checked={checked} disabled={!isValid || !dirty} onChange={onChangeCheckbox} />
                            </Row>
                            <Text size="-2">Are you sure you want to add this commission?</Text>
                        </Section>
                    </ContentWrapper>

                    <ButtonsWrapper justifyEnd>
                        <Button buttonType="text-black" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Row marginLeft="20px">
                            <Button disabled={!isValid || !dirty || !checked} type="submit">
                                Apply
                            </Button>
                        </Row>
                    </ButtonsWrapper>
                </FormWrapper>
            )}
        </Formik>
    );
};
