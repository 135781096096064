export const formatNumber = (value?: number) =>
    new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(value || 0);

export const formatMoney = ({
    value,
    currency = 'USD',
    signDisplay
}: {
    value?: number | null;
    currency?: string | undefined;
    signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
}) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        signDisplay
    }).format(value || 0);

/**
 * Returns number value with postfix: 10000 => 10k
 * @param value
 * @returns
 */
export const getCompactNumber = (value = 0) => {
    if (value < 1000) {
        return `${value}`;
    }

    return Intl.NumberFormat('en-US', {
        notation: 'compact',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1
    })
        .format(value)
        .toLowerCase();
};
