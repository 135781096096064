import { createEffect, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { maximumCategoriesCount, TrendingType } from 'v2/constants/services/trendings';
import { API } from 'v2/services/yasy';
import { isEqualTrendingCategoriesId } from 'v2/stores/trendings/categories/utils';
import { sortTrendingItems } from 'v2/stores/trendings/utils';

// Effects

const getTrendingCategoriesFx = createEffect({
    handler: async () => {
        try {
            const response = await API.trendings.getTrendings({
                pageIndex: 0,
                limit: maximumCategoriesCount,
                type: TrendingType.Category,
                returnQueryCount: true
            });
            return response;
        } catch (e) {
            console.log('There was a problem receiving trending categories', e);
            return {};
        }
    }
});

const swapCategoriesFx = createEffect({
    handler: async ({
        firstElement,
        secondElement
    }: {
        firstElement: YEAY.UpdateTrendingOverridesRequest;
        secondElement: YEAY.UpdateTrendingOverridesRequest;
    }) => {
        try {
            await API.trendings.updateItemById(firstElement);
            if (secondElement?.id) {
                await API.trendings.updateItemById(secondElement);
            }
            return { firstElement, secondElement };
        } catch (e) {
            console.log('There was a problem swapping trending categories', e);
            return null;
        }
    }
});

const deleteCategoryFromTrendingFx = createEffect({
    handler: async (id: string) => {
        try {
            const response = await API.trendings.removeItemById({ id });

            if (response.isSuccess) {
                notify('Category successfully removed from trendings!');
            } else {
                throw new Error();
            }

            return id;
        } catch {
            notify('There was a problem removing category, try again.', 'error');
            return '';
        }
    }
});

const addCategoryToTrendingFx = createEffect({
    handler: async (data: YEAY.CreateTrendingOverridesRequest) => {
        try {
            const response = await API.trendings.createTrending(data);

            if (response) {
                notify('Category successfully added to trendings!');
            } else {
                throw new Error();
            }

            return response;
        } catch {
            notify('There was a problem adding category, try again.', 'error');
            return {};
        }
    }
});

// Stores

const $categories = createStore<YEAY.QueryTrendingOverridesResponse>({})
    .on(getTrendingCategoriesFx.doneData, (_, newState) => ({
        ...newState,
        items: (newState?.items || []).sort(sortTrendingItems)
    }))
    .on(deleteCategoryFromTrendingFx.doneData, (state, itemId) => ({
        ...state,
        items: state.items?.filter(item => item.id !== itemId),
        totalRecords: state?.totalRecords ? state?.totalRecords - 1 : 0
    }))
    .on(addCategoryToTrendingFx.doneData, (state, newItem) => ({
        ...state,
        items: [...(state?.items || []), newItem].sort(sortTrendingItems),
        totalRecords: state?.totalRecords ? state?.totalRecords + 1 : 0
    }))
    .on(swapCategoriesFx.doneData, (state, payload) => {
        if (payload && state.items) {
            const firstElementPosition = payload.firstElement.position;
            const secondElementPosition = payload.secondElement.position;
            return {
                ...state,
                items: state.items
                    .map(item => {
                        // TODO refactor isEqualTrendingCategoriesId
                        if (isEqualTrendingCategoriesId(payload.firstElement.id, item.id, firstElementPosition)) {
                            return { ...item, position: firstElementPosition };
                        }
                        if (isEqualTrendingCategoriesId(payload.secondElement.id, item.id, secondElementPosition)) {
                            return { ...item, position: secondElementPosition };
                        }

                        return item;
                    })
                    .sort(sortTrendingItems)
            };
        }
        return state;
    });

// Exports

export const trendingCategoriesStores = {
    $categories
};

export const trendingCategoriesEffects = {
    getTrendingCategoriesFx,
    swapCategoriesFx,
    deleteCategoryFromTrendingFx,
    addCategoryToTrendingFx
};
