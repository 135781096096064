import styled from 'styled-components';
import { disableDefaultButtonStyleMixin } from 'v1/constants/styles/mixins';
import { filterMargin } from 'v1/constants/styles/sizes';
import { Active } from 'v1/types/global';

export const StyledButton = styled.button<Active>`
    ${disableDefaultButtonStyleMixin};
    padding-bottom: 12px;
    opacity: 0.5;
    ${({ active }) => active && 'border-bottom: 1px solid black; opacity: 1;'};
    //margin-right: ${filterMargin};
    //margin-bottom: ${filterMargin};
    margin: 15px 15px 0px;
`;
