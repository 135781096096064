import styled, { css } from 'styled-components';
import { CardHoverOpacityEffect } from 'v2/components/dynamic/CardHoverEffect/styles';
import { StyledAvatar } from 'v2/components/ui/Avatar/styles';
import { Text } from 'v2/components/ui/typography/Text';
import { brandColors, textColors } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';

interface Props {
    image: string;
}

export const CollaborationCardWrapper = styled.div<Props>`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 12px 8px;
    border-radius: 16px;
    background-color: ${brandColors.white};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 90%), url(${({ image }) => image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover ${CardHoverOpacityEffect} {
        opacity: 1;
    }

    ${({ draggable }) =>
        draggable &&
        css`
            cursor: grab;
        `};
`;

export const CountWrapper = styled.div`
    padding: 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${brandColors.black};
    background-color: ${brandColors.white};
`;

export const PriceBadge = styled.div`
    width: fit-content;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${textColors.main};
`;

export const AvatarsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
`;

export const Avatar = styled(StyledAvatar)`
    &:not(:first-of-type) {
        margin-left: -10px;
    }
`;

export const TextWrapperEllipsis = styled(Text)`
    color: ${brandColors.white};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
`;

export const ModalButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 22px;
    opacity: 0.95;
    border-radius: 8px;
    background: ${brandColors.white};
`;
