import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { MenuIcon, ThreeDotsIcon, YeayBigIcon } from 'v2/assets/icons';
import { DropDownContent } from 'v2/components/grid/Header/DropDownContent';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { appVersion, environmentYEAY } from 'v2/constants/services';
import { brandColors } from 'v2/constants/styles/colors';
import { sizes } from 'v2/constants/styles/media';
import { useOutsideClick } from 'v2/hooks/use-outside-click';
import { mobileHeaderEvents } from 'v2/stores/mobile-header';
import { DotsWrapper, HeaderWrapper, MobileHeaderWrapper, OptionsWrapper } from './styles';

const { showSidebar } = mobileHeaderEvents;

// TODO add links
const HeaderDropdown = () => (
    <OptionsWrapper>
        <DropDownContent
            appVersion={appVersion}
            createProductLink={'/'}
            createUserLink={'/'}
            deleteUserLink={'/'}
            environmentYEAY={environmentYEAY}
        />
    </OptionsWrapper>
);

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const isDesktop = useMediaQuery({ query: `(min-width: ${sizes.laptopSmall}px)` });

    const handleBurgerClick = () => {
        showSidebar();
    };

    const closeOptions = () => {
        setIsOpen(false);
    };

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    useOutsideClick(ref, closeOptions);

    return isDesktop ? (
        <HeaderWrapper>
            <DotsWrapper ref={ref}>
                <IconButton rounded bgColor={brandColors.white} size="32px" onClick={toggleIsOpen}>
                    <ThreeDotsIcon />
                </IconButton>

                {isOpen && <HeaderDropdown />}
            </DotsWrapper>
        </HeaderWrapper>
    ) : (
        <MobileHeaderWrapper>
            <IconButton onClick={handleBurgerClick}>
                <MenuIcon height="24px" width="24px" />
            </IconButton>

            <YeayBigIcon height="38px" width="75px" />

            <DotsWrapper ref={ref}>
                <IconButton onClick={toggleIsOpen}>
                    <ThreeDotsIcon />
                </IconButton>

                {isOpen && <HeaderDropdown />}
            </DotsWrapper>
        </MobileHeaderWrapper>
    );
};
