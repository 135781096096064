import { useStore } from 'effector-react';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import {
    campaignManagerReportsEffects,
    campaignManagerReportsEvents,
    campaignManagerReportsStores
} from 'v2/stores/reports/campaign-manager';
import { ContentWrapper } from '../../styles';
import { CampaignManagerTabFilterLayout } from './CampaignManagerTabFilterLayout';
import { columns } from './constants';
import { prepareReportsForTable } from './utils';

const { getCampaignManagerReportsFx } = campaignManagerReportsEffects;
const { addFilters } = campaignManagerReportsEvents;

export const ReportsCampaignManagerTab = () => {
    const campaignManagerReportsResponse = useStore(campaignManagerReportsStores.$campaignManagerReportsResponse);
    const isLoading = useStore(getCampaignManagerReportsFx.pending);
    const hasError = useStore(campaignManagerReportsStores.$hasError);

    const history = useHistory();

    const totalCount = campaignManagerReportsResponse?.totalCount || 0;
    const hasNext = campaignManagerReportsResponse.hasNext;

    const loadMoreReports = () => {
        if (!hasNext) return;

        const newPageIndex = (campaignManagerReportsResponse?.pageIndex || 0) + 1;

        addFilters({ pageIndex: newPageIndex });
    };

    const tableData = prepareReportsForTable(campaignManagerReportsResponse?.items || []);

    const handleRowClick = (record: DefaultRecordType) => {
        history.push(`${routePaths.reports}/collaboration/${record.collaborationId}`);
    };

    return (
        <CampaignManagerTabFilterLayout isLoading={isLoading} totalCount={totalCount}>
            <ContentWrapper noWrap width="100%">
                <Table
                    cellCenter
                    rowPointer
                    columns={columns}
                    data={tableData}
                    emptyMessage="No Reports Found"
                    isLoading={isLoading}
                    onRow={record => ({
                        onClick: () => handleRowClick(record)
                    })}
                />

                {!isLoading && hasNext && (
                    <Section marginTop="16px">
                        <LoadMoreSection disabled={hasError} onClick={loadMoreReports} />
                    </Section>
                )}
            </ContentWrapper>
        </CampaignManagerTabFilterLayout>
    );
};
