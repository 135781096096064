import React from 'react';
import {
    InstagramIcon,
    InstagramReelsIcon,
    InstagramStoryIcon,
    TikTokIcon,
    TwitchIcon,
    TwitterIcon,
    YeayIcon,
    YoutubeIcon,
    YoutubeShortsIcon
} from 'v2/assets/icons';
import { NetworksType } from 'v2/constants/services/networks';
import { brandColors } from 'v2/constants/styles/colors';

export const NetworkIcon = ({ postType }: { postType: number }) => {
    switch (postType) {
        case NetworksType.YeayVideo:
            return <YeayIcon color={brandColors.black} height="12px" width="8px" />;
        case NetworksType.TwitterTweet:
            return <TwitterIcon color={brandColors.black} height="10px" width="12px" />;
        case NetworksType.InstagramPost:
            return <InstagramIcon color={brandColors.black} height="10px" width="10px" />;
        case NetworksType.InstagramReel:
            return <InstagramReelsIcon color={brandColors.black} height="10px" width="10px" />;
        case NetworksType.InstagramStory:
            return <InstagramStoryIcon color={brandColors.black} height="12px" width="12px" />;
        case NetworksType.TikTokVideo:
            return <TikTokIcon color={brandColors.black} height="10px" width="10px" />;
        case NetworksType.YouTubeVideo:
            return <YoutubeIcon color={brandColors.black} height="8px" width="12px" />;
        case NetworksType.YouTubeShorts:
            return <YoutubeShortsIcon color={brandColors.black} height="12px" width="10px" />;
        case NetworksType.Twitch:
            return <TwitchIcon color={brandColors.black} height="12px" width="12px" />;
        default:
            return null;
    }
};
