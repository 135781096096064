import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { defaultBoxShadow } from 'v2/constants/styles/mixins';

export const mobileHeaderHeight = '80px';

export const HeaderWrapper = styled.div`
    position: fixed;
    top: 14px;
    right: 28px;
    z-index: 100;
`;

export const OptionsWrapper = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: ${brandColors.white};
    ${defaultBoxShadow};
    border-radius: 8px;
    z-index: 10;
`;

export const MobileHeaderWrapper = styled(Section)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
    height: ${mobileHeaderHeight};
    padding: 0 16px;
    background-color: ${brandColors.white};
    border-bottom: 1px solid ${brandColors.grey};
    z-index: 100;
`;

export const DotsWrapper = styled.div`
    position: relative;
`;
