import styled from 'styled-components';
import { xs } from 'v1/constants/styles/sizes';
import { Sizes } from 'v1/types/styles';

interface Props extends Sizes {}

export const AdaptiveFixedWidthWrapper = styled.div<Props>`
    width: 100%;

    @media (max-width: ${({ width }) => width || '1200px'}) {
        width: ${({ width }) => width || '1200px'};
    }

    @media (max-width: ${xs}) {
        width: 100%;
    }
`;
