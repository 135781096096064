import { CommonVideoStateType, VideoStatusTitles } from 'v2/components/common/VideoStatus/types';
import { SelectOption } from 'v2/components/ui/selects/types';

export enum VideoCurationState {
    None,
    Processing,
    Accepted,
    Rejected
}

export const videoState: Record<VideoCurationState, CommonVideoStateType> = {
    [VideoCurationState.None]: CommonVideoStateType.None,
    [VideoCurationState.Processing]: CommonVideoStateType.Pending,
    [VideoCurationState.Accepted]: CommonVideoStateType.Valid,
    [VideoCurationState.Rejected]: CommonVideoStateType.Invalid
};

export const videoStateTitles: VideoStatusTitles = {
    [CommonVideoStateType.None]: 'None',
    [CommonVideoStateType.Pending]: 'Processing',
    [CommonVideoStateType.Valid]: 'Accepted',
    [CommonVideoStateType.Invalid]: 'Rejected'
};

export enum VideoCurationReason {
    None,
    DangerousActsAndChallenges,
    ViolentAndGraphicContent,
    BullyingAndHarassment,
    IntellectualPropertyInfringement,
    NudityAndSexualActivities,
    Other
}

export const videoCurationReasons = [
    {
        value: VideoCurationReason.DangerousActsAndChallenges,
        title: 'Dangerous acts and challenges'
    },
    {
        value: VideoCurationReason.ViolentAndGraphicContent,
        title: 'Violent and graphic content'
    },
    {
        value: VideoCurationReason.BullyingAndHarassment,
        title: 'Bullying and harassment'
    },
    {
        value: VideoCurationReason.IntellectualPropertyInfringement,
        title: 'Intellectual property infringement'
    },
    {
        value: VideoCurationReason.NudityAndSexualActivities,
        title: 'Nudity and sexual activities'
    },
    {
        value: VideoCurationReason.Other,
        title: 'Other'
    }
];

export const defaultVideosParams: YEAY.QueryAllVideosRequest = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true,
    hasHlsStream: true,
    isDeleted: false,
    isReported: false
};

export const trendingVideosParams: YEAY.QueryAllVideosRequest = {
    ...defaultVideosParams,
    isPublic: true,
    limit: 12
};

export const allOption: SelectOption = {
    label: 'All',
    value: undefined
};

export const statusFilterOptions: SelectOption[] = [
    allOption,
    {
        label: 'Processing',
        value: VideoCurationState.Processing
    },
    {
        label: 'Accepted',
        value: VideoCurationState.Accepted
    },
    {
        label: 'Rejected',
        value: VideoCurationState.Rejected
    }
];

export enum EngagementState {
    Views = 'views',
    Clicks = 'clicks',
    Likes = 'likes',
    Saves = 'saves',
    Shares = 'shares',
    Comments = 'comments'
}

export const engagementFilterOptions: SelectOption[] = [
    allOption,
    {
        label: 'Views',
        value: EngagementState.Views
    },
    {
        label: 'Clicks',
        value: EngagementState.Clicks
    },
    {
        label: 'Likes',
        value: EngagementState.Likes
    },
    {
        label: 'Saves',
        value: EngagementState.Saves
    },
    {
        label: 'Shares',
        value: EngagementState.Shares
    },
    {
        label: 'Comments',
        value: EngagementState.Comments
    }
];
