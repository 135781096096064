import { useStore } from 'effector-react';
import React from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { confirmEvents, confirmStores } from 'v2/stores/modals/confirm';
import { ButtonsWrapper, ContentWrapper } from './styles';

const { $confirmInfo } = confirmStores;
const { closeConfirm } = confirmEvents;

export const ConfirmModal = () => {
    const confirmInfo = useStore($confirmInfo);

    const handleOkClick = () => {
        confirmInfo?.okCallback?.();
        closeConfirm();
    };

    const handleCancelClick = () => {
        confirmInfo?.cancelCallback?.();
        closeConfirm();
    };

    return (
        <ModalWrapper visible={!!confirmInfo}>
            <ContentWrapper noWrap width="100%">
                {confirmInfo?.title && (
                    <Section marginBottom="30px">
                        <Title size="1">{confirmInfo.title}</Title>
                    </Section>
                )}

                {confirmInfo?.description && <Text size="-1">{confirmInfo.description}</Text>}
            </ContentWrapper>

            <ButtonsWrapper noWrap>
                {confirmInfo?.cancelCallback && (
                    <Button buttonType="text-black" onClick={handleCancelClick}>
                        {confirmInfo.cancelButtonText}
                    </Button>
                )}

                {confirmInfo?.okCallback && (
                    <Row marginLeft="12px" minWidth="108px">
                        <Button wide buttonType="primary" onClick={handleOkClick}>
                            {confirmInfo.okButtonText}
                        </Button>
                    </Row>
                )}
            </ButtonsWrapper>
        </ModalWrapper>
    );
};
