import React, { FC } from 'react';
import { Text } from 'v2/components/ui/typography/Text';
import { ParticipationStatesKey } from 'v2/constants/services/enrollments';
import { brandColors } from 'v2/constants/styles/colors';
import { StatusIcon } from 'v2/pages/Collaboration/Enrollments/StatusIcon';
import { getTimeLeft } from './utils';

interface Props {
    data: YECM.ParticipationResponse;
}

export const DeadlineCell: FC<Props> = ({ data }) => {
    const { acceptedSubmissionDeadlineUtc, participationState } = data;

    const timeLeft = getTimeLeft(acceptedSubmissionDeadlineUtc);

    if (participationState === undefined || participationState !== ParticipationStatesKey.Enrolled || !timeLeft) {
        return <StatusIcon state="none" />;
    }

    return (
        <Text isSemibold color={timeLeft.expired ? brandColors.red : brandColors.black} size="-2">
            {timeLeft.value}
        </Text>
    );
};
