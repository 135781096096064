import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { CategorySelect } from 'v2/components/common/CategorySelect';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import {
    CategoryEditParams,
    videoActionEffects,
    videoActionEvents,
    videoActionStores
} from 'v2/stores/single-video/actions';
import { Noop } from 'v2/types/types';

interface Props extends CategoryEditParams {
    onClose: Noop;
}

const EditVideoCategoryModalInner: FC<Props> = ({ ownerId, videoId, currentCategoryId, onClose }) => {
    const [selectedCategory, setSelectedCategory] = useState(currentCategoryId);
    const isLoading = useStore(videoActionEffects.editVideoCategoryFx.pending);

    const submitIsDisabled = !selectedCategory || selectedCategory === currentCategoryId || isLoading;

    const handleChange = (value: string) => {
        setSelectedCategory(value);
    };

    const handleSubmit = () => {
        if (selectedCategory) {
            videoActionEffects.editVideoCategoryFx({
                ownerId,
                videoId,
                categoryTagId: selectedCategory
            });
        }
    };

    return (
        <ModalLayout submitDisabled={submitIsDisabled} title="Category" onCancel={onClose} onSubmit={handleSubmit}>
            <Section paddingBottom="8px" paddingTop="8px">
                <CategorySelect value={selectedCategory || undefined} onChange={handleChange} />
            </Section>
        </ModalLayout>
    );
};

export const EditVideoCategoryModal = () => {
    const data = useStore(videoActionStores.$categoryEditModal);

    const close = () => videoActionEvents.closeCategoryEditModal();

    return (
        <ModalWrapper visible={!!data} onClose={close}>
            {!!data && <EditVideoCategoryModalInner onClose={close} {...data} />}
        </ModalWrapper>
    );
};
