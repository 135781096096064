import { yupHttpsUrl } from 'v2/constants/yup';
import * as Yup from 'yup';

export const getInitialValues = (website: string) => ({
    websiteUrl: website || ''
});

export const editWebsiteSchema = Yup.object().shape({
    websiteUrl: yupHttpsUrl
});
