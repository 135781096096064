import { createEffect } from 'effector';
import { mainLoader } from 'v2/stores/initialize-modals';

const loaderFx = createEffect({
    handler: (pending?: boolean) => {
        if (pending) {
            mainLoader.openModal();
        } else {
            mainLoader.closeModal();
        }
    }
});

export const loaderEffects = {
    loaderFx
};
