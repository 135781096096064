import { yupDefault } from 'v2/constants/yup';
import * as Yup from 'yup';
import { EditVideoHashtagsValues } from './types';

export const validationSchema = Yup.object().shape({
    brand: yupDefault,
    product: yupDefault
});

export const initialEditValues: EditVideoHashtagsValues = {
    brand: '',
    product: ''
};
