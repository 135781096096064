import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { handleStyles, railStyles, trackStyles } from './styles';

interface Props {
    totalDuration: number;
    currentTime: number;
    onChange?: (value: number) => void;
    disabled?: boolean;
}

export const Timeline: FC<Props> = ({ disabled, totalDuration, currentTime, onChange }) => {
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const [value, setValue] = useState(0);
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        if (!isChanging) {
            setValue(currentTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime]);

    const handleValueChange = (value: number | number[]) => {
        if (typeof value === 'number' && onChange) {
            setValue(value);
            setIsChanging(true);

            if (timer.current) {
                clearTimeout(timer.current);
            }

            timer.current = setTimeout(() => {
                onChange(value);
                setIsChanging(false);
            }, 100);
        }
    };

    return (
        <Section>
            <RcSlider
                disabled={disabled}
                handleStyle={handleStyles}
                max={totalDuration}
                min={0}
                railStyle={railStyles}
                step={0.1}
                trackStyle={trackStyles}
                value={value}
                onChange={handleValueChange}
            />
        </Section>
    );
};
