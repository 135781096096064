import styled from 'styled-components';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { filterMargin, md, xs } from 'v1/constants/styles/sizes';

export const StyledRow = styled(Row)`
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 2;
    flex-shrink: 0;

    justify-content: flex-end;

    @media (max-width: ${xs}) {
        width: 100%;
    }
`;

export const CheckboxesWrapper = styled(Row)`
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 2;
    flex-shrink: 0;

    justify-content: space-between;

    @media (max-width: ${md}) {
        padding: 20px;
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    margin-right: ${filterMargin};

    @media (max-width: ${xs}) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
`;
