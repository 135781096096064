import { useStore } from 'effector-react';
import React from 'react';
import { CloseIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { viewAddressInfoModal } from 'v2/stores/initialize-modals';
import { GreedWrapper, ModalContent } from './styles';

const { $modal, closeModal, $state } = viewAddressInfoModal;

export const ViewAddressInfoModal = () => {
    const { visible } = useStore($modal);
    const address = useStore($state);

    const close = () => closeModal();

    return (
        <ModalWrapper visible={visible} onClose={close}>
            <ModalContent>
                <Section alignCenter justifyBetween marginBottom="46px">
                    <Title size="-1">User Address</Title>
                    <IconButton color={brandColors.black} hoverColor={grey[700]} onClick={() => closeModal()}>
                        <CloseIcon height={14} width={14} />
                    </IconButton>
                </Section>

                <Column marginBottom="30px">
                    <Row marginBottom="20px">
                        <Title size="-2">Full Name</Title>
                    </Row>
                    <Text isSemibold size="-2">
                        {address?.fullName || '-'}
                    </Text>
                </Column>

                <GreedWrapper>
                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">Street Name</Title>
                        </Row>
                        <Text isSemibold size="-2">
                            {address?.street || '-'}
                        </Text>
                    </Column>

                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">House Number</Title>
                        </Row>
                        <Text isSemibold size="-2">
                            {address?.houseNumber || '-'}
                        </Text>
                    </Column>

                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">Country</Title>
                        </Row>
                        <Text isSemibold size="-2">
                            {address?.country || '-'}
                        </Text>
                    </Column>

                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">Postal Code</Title>
                        </Row>

                        <Text isSemibold size="-2">
                            {address?.zipCode || '-'}
                        </Text>
                    </Column>

                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">Town/City</Title>
                        </Row>
                        <Text isSemibold size="-2">
                            {address?.city || '-'}
                        </Text>
                    </Column>

                    <Column>
                        <Row marginBottom="16px">
                            <Title size="-2">State/Province/Region</Title>
                        </Row>
                        <Text isSemibold size="-2">
                            {address?.state || '-'}
                        </Text>
                    </Column>
                </GreedWrapper>
            </ModalContent>
        </ModalWrapper>
    );
};
