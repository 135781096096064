import styled, { css } from 'styled-components';
import { blue, lightSkyBlue } from 'v1/constants/styles/colors';
import { buttonEffectMixin } from 'v1/constants/styles/mixins';
import { copyButtonDiameter } from 'v1/constants/styles/sizes';
import { Sizes } from 'v1/types/styles';

interface LinkButtonWrapperProps extends Sizes {
    pointer?: boolean;
    disabled?: boolean;
}

export const CopyButtonWrapper = styled.div<LinkButtonWrapperProps>`
    height: ${({ height }) => height || copyButtonDiameter};
    width: ${({ width }) => width || copyButtonDiameter};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
    ${({ disabled }) =>
        !disabled
            ? buttonEffectMixin
            : css`
                  cursor: not-allowed;
              `};
    path {
        stroke: ${blue};
    }
    &:hover {
        opacity: 1;

        rect,
        circle {
            fill: ${lightSkyBlue};
        }
    }
`;
