import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Section } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { filterMargin, xs } from 'v1/constants/styles/sizes';
import { TotalRecords } from 'v1/types/data';
import { Breadcrumb } from 'v2/components/grid/Breadcrumb';
import { TotalBadge } from 'v2/components/ui/Badge';
import { CatalogContainerWrapper } from './styles';

interface Props extends TotalRecords {}

export const CatalogContainer: FC<Props> = ({ children, totalRecords }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <CatalogContainerWrapper>
            <Section alignCenter marginBottom={filterMargin}>
                <MarginWrapper marginRight={isMobile ? '8px' : '10px'}>
                    <Breadcrumb />
                </MarginWrapper>
                {totalRecords !== undefined && <TotalBadge count={totalRecords} />}
            </Section>
            {children}
        </CatalogContainerWrapper>
    );
};
