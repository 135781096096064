import styled, { css } from 'styled-components';
import { SimpleButtonProps } from 'v1/components/common/buttons/SimpleButton/types';
import { black, greenYellow, grey11, grey7 } from 'v1/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v1/constants/styles/mixins';
import { transitionTime } from 'v1/constants/styles/others';

const disableButtonMixin = css`
    background: ${grey11};
    color: ${grey7};
`;

export const SimpleButton = styled.button<SimpleButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${({ width }) => width && `width: ${width}`};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    ${({ height }) => height && `height:  ${height}`};
    font-size: ${({ fontSize }) => fontSize || '12px'};
    font-weight: ${({ fontWeight }) => fontWeight || '500'};
    line-height: ${({ lineHeight }) => lineHeight || '15px'};
    color: ${({ color }) => color || black};
    background-color: ${({ background }) => background || greenYellow};
    ${({ border }) => border && `border: ${border}`};
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    padding: ${({ padding }) => padding || '11px 16px'};
    ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
    ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
    ${({ margin }) => margin && `margin: ${margin}`};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    ${({ noWrap }) => (noWrap ? 'white-space: nowrap' : '')};
    transition: color ${transitionTime}, background-color ${transitionTime};

    :hover {
        background-color: ${({ backgroundHover, background, disabled }) =>
            !disabled && (backgroundHover || background || greenYellow)};
        color: ${({ textHover, color, disabled }) => !disabled && (textHover || color || black)};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            ${disableButtonMixin};
            cursor: not-allowed;
        `};

    ${({ blocked }) =>
        blocked &&
        css`
            ${disableButtonMixin};
        `};
`;
