import React, { FC } from 'react';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { Divider, LinkItem, Wrapper } from './styles';

interface Props {
    createProductLink: string;
    createUserLink: string;
    deleteUserLink: string;
    appVersion: string;
    environmentYEAY: string;
}

export const DropDownContent: FC<Props> = ({
    createProductLink,
    createUserLink,
    deleteUserLink,
    appVersion,
    environmentYEAY
}) => (
    <Column>
        <Wrapper marginBottom="4px">
            <Title isUppercase color={grey[500]} size="-2">
                User
            </Title>
        </Wrapper>

        <LinkItem to={createProductLink}>
            <Text size="-2">Create product</Text>
        </LinkItem>

        <LinkItem to={createUserLink}>
            <Text size="-2">Create user</Text>
        </LinkItem>

        <LinkItem to={deleteUserLink}>
            <Text color={brandColors.red} size="-2">
                Delete users in bulk
            </Text>
        </LinkItem>

        <Divider />

        <Wrapper marginBottom="4px">
            <Title isUppercase color={grey[500]} size="-2">
                About
            </Title>
        </Wrapper>

        <Button buttonType="text-black" textSize="small" onClick={() => window.ivwt?.show?.()}>
            What’s new
        </Button>

        <Wrapper marginTop="4px">
            <Text color={grey[400]} size="-2">
                Version: {appVersion} YEAY: {environmentYEAY}
            </Text>
        </Wrapper>
    </Column>
);
