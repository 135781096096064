export const columns = [
    {
        title: 'TYPE',
        dataIndex: 'type',
        key: 'type',
        width: '15%'
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        width: '15%'
    },
    {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
        width: '15%'
    },
    {
        title: 'TIME',
        dataIndex: 'time',
        key: 'time',
        width: '15%'
    },
    {
        title: 'COLLABORATION',
        dataIndex: 'collaborationName',
        key: 'collaborationName',
        width: '20%'
    },
    {
        title: 'TRANSACTION ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: '20%'
    }
];

export enum UserTransactionType {
    None = 0,
    CollaborationVideoReward = 10,
    CollaborationSocialPostReward = 20,
    ReferralReward = 30,
    AgentReward = 40,
    Withdrawal = 50
}

export const userTransactionTypeText: Record<UserTransactionType, string> = {
    [UserTransactionType.None]: 'None',
    [UserTransactionType.CollaborationVideoReward]: 'Collaboration Video Reward',
    [UserTransactionType.CollaborationSocialPostReward]: 'Collaboration Social Post Reward',
    [UserTransactionType.ReferralReward]: 'Referral Reward',
    [UserTransactionType.AgentReward]: 'Agent Reward',
    [UserTransactionType.Withdrawal]: 'Withdrawal'
};
