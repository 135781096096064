import styled from 'styled-components';
import { FlexGrow } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { white } from 'v1/constants/styles/colors';
import { ellipsisMixin } from 'v1/constants/styles/mixins';
import { xs } from 'v1/constants/styles/sizes';
import {
    BackgroundColor,
    BorderProperties,
    BorderRadius,
    Color,
    Padding,
    Sizes,
    TextAlignment,
    TextProperties
} from 'v1/types/styles';

interface TableProps
    extends BackgroundColor,
        BorderRadius,
        BorderProperties,
        Sizes,
        Padding,
        Color,
        TextProperties,
        TextAlignment {
    center?: boolean;
}

export const EllipsisTableText = styled.span<TableProps>`
    display: inline-block;
    text-align: ${({ alignTextCenter }) => (alignTextCenter && ' center') || 'start'};
    font-size: ${({ fontSize }) => fontSize || '12px'};
    ${({ color }) => color && `color: ${color}`};
    ${({ width }) => width && `width: ${width}`};
    ${ellipsisMixin};
`;

export const TableWrapper = styled.div<TableProps>`
    margin-top: 8px;
    width: 100%;
    ${({ backgroundColor }) => backgroundColor && `background-color: ${white}`};
`;

export const Table = styled.table<TableProps>`
    border-collapse: collapse;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    tr:first-child {
        th:first-child {
            border-top-left-radius: 10px;
        }

        th:last-child {
            border-top-right-radius: 10px;
        }
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 10px;
        }

        td:last-child {
            border-bottom-right-radius: 10px;
        }
    }
    @media (max-width: ${xs}) {
        ${({ height }) => `height: ${height || '34px'}`};
    }
`;
export const TableRow = styled.tr<TableProps>`
    width: 100%;
    height: 60px;
    padding: 24px 0px;
    ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
    background-color: ${({ backgroundColor }) => backgroundColor || white};
    ${({ color }) => color && `color: ${color}`};
    text-align: center;

    @media (max-width: ${xs}) {
        ${({ height }) => `height: ${height || '34px'}`};
        th:first-child {
            border-top-left-radius: 10px;
        }

        th:last-child {
            border-top-right-radius: 10px;
        }
    }
`;

export const TableHeader = styled.th<TableProps>`
    text-align: center;
    font-size: 14px;
    ${({ width }) => width && `width: ${width}`};

    @media (max-width: ${xs}) {
        font-size: 10px;
    }
`;
export const TableData = styled.td<TableProps>`
    text-align: center;
    ${({ color }) => color && `color: ${color}`};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ width }) => width && `width: ${width}`};
`;

export const DeleteButtonWrapper = styled(FlexGrow)`
    align-self: flex-start;
`;
