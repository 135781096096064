import React, { FC, MouseEvent } from 'react';
import { CloseIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { CloseWrapper, TagStyled } from './styles';

interface Props {
    id: string;
    hasPrefix?: boolean;
    removeIcon?: boolean;
    text: string;
    onClick?: (text: string) => void;
    removeClick?: (id: string) => void;
}

export const Hashtag: FC<Props> = ({ text, hasPrefix, removeIcon, id, removeClick, onClick }) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick && onClick(text);
    };

    return (
        <TagStyled hasClick={!!onClick} id={id} type="button" onClick={handleClick}>
            <Text className="text" size="-2">
                {hasPrefix && '#'}
                {text}
            </Text>
            {removeIcon && (
                <CloseWrapper className="close-icon" onClick={() => removeClick && removeClick(id)}>
                    <CloseIcon color="transition" />
                </CloseWrapper>
            )}
        </TagStyled>
    );
};
