import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { useQueryParams } from 'v2/hooks/use-query-params';
import { InactiveUsersDate } from 'v2/pages/InactiveUsers/InactiveUsersFilters/InactiveUsersDate';
import { InactiveUsersSearch } from 'v2/pages/InactiveUsers/InactiveUsersFilters/InactiveUsersSearch';
import { InactiveUsersParams, inactiveUsersEvents, inactiveUsersStores } from 'v2/stores/inactive-users';
import { FiltersWrapper } from './styles';

interface Props {
    totalCount: number;
}

// TODO add isTrusted, sort and type filters
export const InactiveUsersFilters: FC<Props> = ({ totalCount }) => {
    const inactiveUsersFilters = useStore(inactiveUsersStores.$inactiveUsersFilters);
    const [, setQueryParams] = useQueryParams((params: InactiveUsersParams) => {
        inactiveUsersEvents.setOverwriteFilters(params);
    });

    useEffect(() => {
        setQueryParams(inactiveUsersFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactiveUsersFilters]);

    const handleReset = () => {
        inactiveUsersEvents.setDefaultFilters();
    };

    return (
        <FiltersWrapper>
            <SectionBorder borderBottom noWrap padding="6px 16px">
                <Section maxWidth="500px">
                    <InactiveUsersSearch />
                </Section>
            </SectionBorder>
            <SectionBorder borderBottom justifyBetween noWrap padding="12px 16px">
                <Row alignCenter noWrap>
                    <Row marginRight="16px">
                        <TotalBadge count={totalCount} />
                    </Row>
                    <InactiveUsersDate />
                </Row>
                <Button buttonType="text-black" onClick={handleReset}>
                    Reset
                </Button>
            </SectionBorder>
        </FiltersWrapper>
    );
};
