import React, { useEffect, useState } from 'react';
import {
    SearchType,
    blacklistedTableColumnTitles,
    defaultTitles
} from 'v1/components/common/tables/BlacklistedUsersTable/constants';
import { Table } from 'v1/components/common/tables/Table';
import { ContentText } from 'v1/components/common/typography/ContentText/styles';
import { TableWrapper } from 'v1/components/layouts/descriptionLayouts/ProductDescription/styles';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { primaryBorderRadius, tableBorder } from 'v1/constants/styles/sizes';
import { DataTable } from 'v1/types/data';

export interface BlacklistedUsersTableProps {
    items: YEAY.GetBlacklistedUsersResponse[];
    type: SearchType;
}

export const BlacklistedUsersTable = ({ items, type = 1 }: BlacklistedUsersTableProps) => {
    const [tableColumnTitles, setTableColumns] = useState<string[]>(defaultTitles);
    //const [tableColumnSizes, setTableColumnSizes] = useState<number[]>();

    const dataTable: DataTable[] | undefined = items?.map(({ id = '', searchTextValue }) => ({
        cells: [
            <Row key={id} alignCenter noWrap>
                <ContentText>{id}</ContentText>
            </Row>,

            <Row key={id}>
                <ContentText>{searchTextValue}</ContentText>
            </Row>
        ],
        alignment: [...new Array(2).fill('center')]
    }));

    useEffect(() => {
        const newColumTitles = blacklistedTableColumnTitles.filter((_, index) => index === 0 || index === type);
        setTableColumns(newColumTitles);

        // const newColumSizes = blacklistedTableColumnSizes.filter((_, index) => index === 0 || index === type);
        // setTableColumnSizes(newColumSizes);
    }, [type]);

    return (
        <TableWrapper border={tableBorder} borderRadius={primaryBorderRadius}>
            <Table columns={tableColumnTitles} data={dataTable} />
        </TableWrapper>
    );
};
