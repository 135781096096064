import React, { FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import arrowRight from 'v1/assets/arrow_right.svg';
import {
    DropdownSectionButton,
    DropdownSectionProps,
    DropdownSectionWrapper
} from 'v1/components/common/dropdowns/SectionDropdown/style';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { ContentText } from 'v1/components/common/typography/ContentText/styles';
import { Column, Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { filterMargin, xs } from 'v1/constants/styles/sizes';
import { useToggle } from 'v1/hooks/toggle';
import { DefaultValueBoolean } from 'v1/types/form';

export const DropdownColumn: FC = ({ children }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const dropdownWrapperWidth = `calc(100% - ${filterMargin})`;

    return <Column width={isMobile ? '100%' : dropdownWrapperWidth}>{children}</Column>;
};

export interface Props extends DropdownSectionProps, DefaultValueBoolean {
    title: string;
    extra?: ReactNode;
}

export const DropdownSection: FC<Props> = ({ extra, children, title, defaultValue = false, ...rest }) => {
    const [isOpened, toggleIsOpened] = useToggle(defaultValue);

    return (
        <DropdownSectionWrapper isOpened={isOpened} {...rest}>
            <DropdownSectionButton onClick={toggleIsOpened}>
                <ContentText fontSize="14px" lineHeight="16px" padding="0px 10px 0px 0px">
                    {title}
                </ContentText>

                <Row alignCenter>
                    {extra && <Row marginRight="20px">{extra}</Row>}
                    <CustomImg alt="Arrow" height="12px" rotate={isOpened ? -90 : 0} src={arrowRight} width="7px" />
                </Row>
            </DropdownSectionButton>
            {isOpened && children}
        </DropdownSectionWrapper>
    );
};
