import { createEvent, createStore } from 'effector';
import {
    AddInfluencerToAgentModalProps,
    BlockUserModalProps,
    CommissionModalProps,
    EditShippingStatusModalProps,
    EditUserRoleModalProps,
    EditWebsiteModalProps
} from 'v2/types/modals';

const initializeStoreModal = <T = void>(initialState: T = {} as T) => {
    const openModal = createEvent<T>();
    const closeModal = createEvent();

    const $modal = createStore({
        visible: false
    })
        .on(openModal, () => ({
            visible: true
        }))
        .on(closeModal, () => ({
            visible: false
        }));

    const $state = createStore<T>(initialState)
        .on(openModal, (_, newState) => newState)
        .reset(closeModal);

    return { $modal, $state, openModal, closeModal };
};

export const addCollaborationModal = initializeStoreModal();
export const mainLoader = initializeStoreModal();
export const addEditCommissionModal = initializeStoreModal<CommissionModalProps>();
export const blockUserModal = initializeStoreModal<BlockUserModalProps>();
export const editUserRoleModal = initializeStoreModal<EditUserRoleModalProps>();
export const curateVideoModal = initializeStoreModal<string>('');
export const reviewReportModal = initializeStoreModal<string>('');
export const videoModal = initializeStoreModal<string>('');
export const addTrendingCreatorsModal = initializeStoreModal();
export const addAgentModal = initializeStoreModal<{ userId: string }>();
export const editAgentModal = initializeStoreModal<{ user: YEAY.AdminGetUserCommon }>();
export const addTrendingVideosModal = initializeStoreModal();
export const addInfluencerToAgentModal = initializeStoreModal<AddInfluencerToAgentModalProps>();
export const viewAddressInfoModal = initializeStoreModal<YECM.ShippingAddress | undefined>();
export const editShippingStatusModal = initializeStoreModal<EditShippingStatusModalProps>();
export const editWebsiteModal = initializeStoreModal<EditWebsiteModalProps>();
