export const columns = [
    {
        title: 'USER',
        dataIndex: 'user',
        key: 'user',
        width: '40%'
    },
    {
        title: 'REQUESTED',
        dataIndex: 'requested',
        key: 'requested',
        width: '40%'
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        width: '20%'
    }
];
