import { useStore } from 'effector-react';
import React, { FC, ReactNode, useEffect } from 'react';
import { CollaborationCategories } from 'v2/components/filter/collaboration/CollaborationCategories';
import { CollaborationDate } from 'v2/components/filter/collaboration/CollaborationDate';
import { CollaborationSearch } from 'v2/components/filter/collaboration/CollaborationSearch';
import { CollaborationSocial } from 'v2/components/filter/collaboration/CollaborationSocial';
import { CollaborationSort } from 'v2/components/filter/collaboration/CollaborationSort';
import { CollaborationState } from 'v2/components/filter/collaboration/CollaborationState';
import { CollaborationTarget } from 'v2/components/filter/collaboration/CollaborationTarget';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { useQueryParams } from 'v2/hooks/use-query-params';
import { collaborationsPageStore } from 'v2/stores/collaborations';
import { FiltersWrapper } from './styles';

const { events } = collaborationsPageStore;

interface Props {
    children: ReactNode;
    totalCount?: number;
}

export const CollaborationsFilterLayout: FC<Props> = ({ children, totalCount = 0 }) => {
    const collaborationsFilters = useStore(collaborationsPageStore.stores.$collaborationsFilters);
    const [, setQueryParams] = useQueryParams((params: Partial<YecmPaths.Collaborations.Get.QueryParameters>) => {
        collaborationsPageStore.events.overwriteFilters(params);
    });

    useEffect(() => {
        setQueryParams(collaborationsFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collaborationsFilters]);

    const handleReset = () => {
        events.resetFilters();
    };

    return (
        <Column width="100%">
            <FiltersWrapper>
                <SectionBorder borderBottom noWrap padding="12px 16px">
                    <Section maxWidth="500px">
                        <CollaborationSearch store={collaborationsPageStore} />
                    </Section>
                </SectionBorder>

                <SectionBorder borderBottom justifyBetween noWrap padding="12px 16px">
                    <Row alignCenter noWrap>
                        <Row marginRight="8px">
                            <TotalBadge count={totalCount} />
                        </Row>

                        <Row marginRight="24px">
                            <CollaborationState store={collaborationsPageStore} />
                        </Row>

                        <Row marginRight="24px">
                            <CollaborationCategories store={collaborationsPageStore} />
                        </Row>

                        <Row>
                            <CollaborationDate store={collaborationsPageStore} />
                        </Row>
                    </Row>

                    <Button buttonType="text-black" onClick={handleReset}>
                        Reset
                    </Button>
                </SectionBorder>

                <SectionBorder alignCenter noWrap padding="12px 16px">
                    <Row marginRight="24px">
                        <CollaborationSocial store={collaborationsPageStore} />
                    </Row>

                    <Row marginRight="24px">
                        <CollaborationTarget store={collaborationsPageStore} />
                    </Row>

                    {/* TODO add when will be ready back */}
                    {/* <Row marginRight="24px">*/}
                    {/* <Select
                        options={engagementsOptions}
                        title="Engagement"
                        value={engagementsValue}
                        onChange={onChangeEngagement}
                    /> */}

                    <CollaborationSort store={collaborationsPageStore} />
                </SectionBorder>
            </FiltersWrapper>

            {children}
        </Column>
    );
};
