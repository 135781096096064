import styled from 'styled-components';
import { media } from 'v2/constants/styles/media';

export const VideosGrid = styled.div`
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    ${media.laptopMedium(`
        grid-template-columns: repeat(7, 1fr);
    `)};

    ${media.laptop(`
        grid-template-columns: repeat(8, 1fr);
    `)};

    ${media.desktop(`
        grid-template-columns: repeat(9, 1fr);
    `)};

    ${media.desktopLarge(`
        grid-template-columns: repeat(11, 1fr);
    `)};
`;
