import { useStore } from 'effector-react';
import React, { FC, MouseEvent, useEffect } from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { AddEditCommissionModal } from 'v2/components/modals/AddEditCommissionModal';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { agentEffects, agentEvents, agentStores, defaultMembersParams } from 'v2/stores/agent';
import { addInfluencerToAgentModal } from 'v2/stores/initialize-modals';
import { agentVideosDomain } from 'v2/stores/videos';
import { AddInfluencerToAgentModal } from './components/AddInfluencerToAgentModal';
import { AgentMembers } from './components/AgentMembers';
import { AgentVideos } from './components/AgentVideos';
import { InfoBlocks } from './components/InfoBlocks';
import { LoadingWrapper } from './styles';

const { effects, events, stores } = agentVideosDomain;

const { getUserAgentInfoFx, getAgentMembersFx } = agentEffects;
const { resetCurrentUserAgentInfo } = agentEvents;

const { getVideosFx } = effects;
const { resetVideos } = events;

interface Props {
    isAgentRoleAssigned?: boolean;
    userId?: string;
}

export const AgentInfo: FC<Props> = ({ isAgentRoleAssigned, userId }) => {
    const currentUserAgentInfo = useStore(agentStores.$currentUserAgentInfo);

    const videoInfo = useStore(stores.$videos);
    const isLoadingVideos = useStore(getVideosFx.pending);
    const videoFilters = useStore(stores.$videosFilters);
    const isLoadingMembers = useStore(getAgentMembersFx.pending);
    const videoTotalRecords = videoInfo?.totalRecords || 0;

    const isLoadingAgentInfo = useStore(getUserAgentInfoFx.pending);

    const agentMembers = useStore(agentStores.$agentMembers);

    const membersTotalRecords = agentMembers.itemsCount || 0;

    const openAddInfluencerModal = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        addInfluencerToAgentModal.openModal({
            agentId: currentUserAgentInfo.id || ''
        });
    };

    useEffect(() => {
        if (userId && isAgentRoleAssigned) {
            getUserAgentInfoFx(userId);
        }

        return () => {
            resetCurrentUserAgentInfo();
        };
    }, [isAgentRoleAssigned, userId]);

    useEffect(() => {
        if (isAgentRoleAssigned && currentUserAgentInfo.id) {
            getAgentMembersFx({ agentId: currentUserAgentInfo.id, params: defaultMembersParams });
        }
    }, [currentUserAgentInfo, isAgentRoleAssigned]);

    useEffect(() => {
        if (currentUserAgentInfo.id) {
            getVideosFx({ agentId: currentUserAgentInfo.id });
        }

        return () => {
            resetVideos();
        };
    }, [currentUserAgentInfo.id]);

    return (
        <>
            {isLoadingAgentInfo ? (
                <LoadingWrapper alignCenter justifyCenter height="86px" marginBottom="10px">
                    <Spinner />
                </LoadingWrapper>
            ) : (
                <Column marginBottom="10px" width="100%">
                    <InfoBlocks agentInfo={currentUserAgentInfo} />
                </Column>
            )}

            {isLoadingAgentInfo || isLoadingMembers ? (
                <LoadingWrapper alignCenter justifyCenter height="46px" marginBottom="10px">
                    <Spinner />
                </LoadingWrapper>
            ) : (
                <Section marginBottom="10px">
                    <AccordionSection
                        extra={
                            <Button buttonType="text-black" textSize="small" onClick={openAddInfluencerModal}>
                                Add Influencers
                            </Button>
                        }
                        title={`Agent Influencers (${membersTotalRecords})`}
                    >
                        <SectionBorder borderTop>
                            <AgentMembers
                                agentId={currentUserAgentInfo.id}
                                agentMembers={agentMembers}
                                isLoading={isLoadingMembers}
                            />
                        </SectionBorder>
                    </AccordionSection>
                </Section>
            )}

            {isLoadingAgentInfo || isLoadingVideos ? (
                <LoadingWrapper alignCenter justifyCenter height="46px">
                    <Spinner />
                </LoadingWrapper>
            ) : (
                <AccordionSection title={`Agent Videos (${videoTotalRecords})`}>
                    <SectionBorder borderTop>
                        <AgentVideos
                            agentId={currentUserAgentInfo.id}
                            filters={videoFilters}
                            isLoading={isLoadingVideos}
                            videoInfo={videoInfo}
                        />
                    </SectionBorder>
                </AccordionSection>
            )}

            <AddEditCommissionModal />
            <AddInfluencerToAgentModal agentId={currentUserAgentInfo.id} />
        </>
    );
};
