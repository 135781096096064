import { useStore } from 'effector-react';
import Hls from 'hls.js';
import React, { FC, MouseEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { PauseIcon, PlayFillIcon } from 'v2/assets/icons';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Toolbar } from 'v2/components/ui/Video/Toolbar';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { AbsoluteCenterAlignment } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { playerStores } from 'v2/stores/player';
import { Noop } from 'v2/types/types';
import { StyledVideo } from './styes';

interface Props {
    videoSrc?: string | null;
    posterSrc?: string | null;
    cover?: boolean;
    loop?: boolean;
    isFullscreen?: boolean;
    onFullscreen?: Noop;
}

export const Video: FC<Props> = ({ videoSrc, posterSrc, cover, isFullscreen, onFullscreen, loop = true }) => {
    const video = useRef<HTMLVideoElement>(null);

    const isMuted = useStore(playerStores.$isMuted);

    const [totalDuration, setTotalDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [canStartLoading, setCanStartLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const hlsIsSupported = Hls.isSupported();

    const hasVideo = !!videoSrc && hlsIsSupported;
    const playButtonIsVisible = !isLoading && hasVideo && (!isPlaying || (isPlaying && isHovered));
    const toolbarIsVisible = !isLoading && hasVideo && (isFullscreen ? isLoaded : isHovered || isPlaying);

    const play = () => {
        if (video.current) {
            video.current.play();
        }
    };

    const pause = () => {
        if (video.current) {
            video.current.pause();
        }
    };

    const stop = () => {
        if (video.current) {
            video.current.pause();
            video.current.currentTime = 0;
        }
    };

    const setNewTime = (value: number) => {
        if (video.current) {
            video.current.currentTime = value;
        }
    };

    const togglePlay = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!canStartLoading) {
            setCanStartLoading(true);
        } else {
            if (!isPlaying) {
                play();
            } else {
                pause();
            }
        }
    };

    const openFullscreenVideo = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onFullscreen?.();
        stop();
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleVideoLoaded = (e: SyntheticEvent<HTMLVideoElement>) => {
        setTotalDuration(e.currentTarget.duration || 0);
        setIsLoading(false);
        setIsLoaded(true);
        play();
    };

    const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement>) => {
        setCurrentTime(e.currentTarget.currentTime || 0);
    };

    useEffect(() => {
        if (canStartLoading && hlsIsSupported && videoSrc && video.current) {
            const hls = new Hls();

            setIsLoading(true);

            hls.loadSource(videoSrc);
            hls.attachMedia(video.current);
        }
    }, [videoSrc, canStartLoading, hlsIsSupported]);

    return (
        <RelativeWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isLoading && (
                <AbsoluteCenterAlignment zIndex="1">
                    <Spinner />
                </AbsoluteCenterAlignment>
            )}

            {playButtonIsVisible && (
                <AbsoluteCenterAlignment zIndex="1">
                    <IconButton
                        rounded
                        bgColor={brandColors.black}
                        bgHoverColor={grey[800]}
                        color={brandColors.white}
                        size={isFullscreen ? '60px' : '40px'}
                        onClick={togglePlay}
                    >
                        {isPlaying ? <PauseIcon /> : <PlayFillIcon />}
                    </IconButton>
                </AbsoluteCenterAlignment>
            )}

            {toolbarIsVisible && (
                <Toolbar
                    currentTime={currentTime}
                    isFullscreen={isFullscreen}
                    isMuted={isMuted}
                    isPlaying={isPlaying}
                    totalDuration={totalDuration}
                    onChangeTime={setNewTime}
                    onFullscreen={openFullscreenVideo}
                    onTogglePlay={togglePlay}
                />
            )}

            <StyledVideo
                ref={video}
                playsInline
                controls={false}
                cover={cover}
                loop={loop}
                muted={isMuted}
                poster={posterSrc || ''}
                preload="metadata"
                onEnded={handlePause}
                onLoadedData={handleVideoLoaded}
                onPause={handlePause}
                onPlay={handlePlay}
                onTimeUpdate={handleTimeUpdate}
            />
        </RelativeWrapper>
    );
};
