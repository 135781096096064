import styled from 'styled-components';
import { xs } from 'v1/constants/styles/sizes';

export const TitleSpan = styled.span`
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    white-space: nowrap;

    @media (max-width: ${xs}) {
        font-size: 11px;
    }
`;

export const TrendingsTitleSpan = styled.span`
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    white-space: nowrap;
`;
