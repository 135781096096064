import { useStore } from 'effector-react';
import React, { ChangeEvent, useState } from 'react';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { SelectButton } from 'v2/components/ui/buttons/SelectButton';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Input } from 'v2/components/ui/inputs/Input';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { blockUserModal } from 'v2/stores/initialize-modals';
import { singleUserActionsEffects } from 'v2/stores/single-user/actions';
import { blockReasons } from './constants';

const BlockUserModalInner = () => {
    const { userId } = useStore(blockUserModal.$state);
    const [selectedReasons, setSelectedReasons] = useState<YEAY.UserDisablingReason[]>([]);
    const [isAgreed, setIsAgreed] = useState(false);
    const [comment, setComment] = useState('');

    const onSubmit = () => {
        singleUserActionsEffects.blockUserByIdFx({
            userId,
            reasons: selectedReasons,
            comment
        });
    };

    const onReasonClick = (reason: YEAY.UserDisablingReason) => () => {
        if (selectedReasons.includes(reason)) {
            setSelectedReasons(selectedReasons.filter(currentReason => currentReason !== reason));
        } else {
            setSelectedReasons([...selectedReasons, reason]);
        }
    };

    const onCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    return (
        <ModalLayout
            submitDisabled={selectedReasons.length === 0 || !isAgreed}
            submitText="Block"
            title="What is the reason?"
            onCancel={() => blockUserModal.closeModal()}
            onSubmit={onSubmit}
        >
            <RepeatGrid columns={1} gap="8px" marginTop="12px" width="100%">
                {blockReasons.map(reason => (
                    <SelectButton
                        key={reason.value}
                        selected={selectedReasons.includes(reason.value)}
                        onClick={onReasonClick(reason.value)}
                    >
                        {reason.text}
                    </SelectButton>
                ))}
            </RepeatGrid>
            <Section marginBottom="42px" marginTop="16px">
                <Input wide placeholder="Add Comment" value={comment} onChange={onCommentChange} />
            </Section>
            <Checkbox checked={isAgreed} onChange={() => setIsAgreed(prev => !prev)}>
                Are you sure you want to block this user?
            </Checkbox>
        </ModalLayout>
    );
};

export const BlockUserModal = () => {
    const { visible } = useStore(blockUserModal.$modal);

    return (
        <ModalWrapper visible={visible} onClose={() => blockUserModal.closeModal()}>
            <BlockUserModalInner />
        </ModalWrapper>
    );
};
