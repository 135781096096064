import { useStore } from 'effector-react';
import React from 'react';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOptionValue } from 'v2/components/ui/selects/types';
import { submissionsEvents, submissionsStores } from 'v2/stores/collaboration/details/submissions';
import { submissionTypes } from './constants';

const { setOverwriteFilters } = submissionsEvents;

export const SubmissionType = () => {
    const submissionsFilters = useStore(submissionsStores.$submissionsFilters);

    const onChangeSubmissionType = (submissionType: SelectOptionValue[]) => {
        setOverwriteFilters({
            SubmissionTypes: submissionType
        });
    };

    return (
        <MultiSelect
            options={submissionTypes}
            title="Submission Type"
            values={submissionsFilters?.SubmissionTypes}
            onChange={onChangeSubmissionType}
        />
    );
};
