import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { transactionsEvents, transactionsStores } from 'v2/stores/transactions';

export const TransactionsSearch = () => {
    const { UserId } = useStore(transactionsStores.$transactionsFilters);
    const [searchText, setSearchText] = useState('');

    const searchItems = [
        {
            option: {
                value: 'userId',
                label: 'User ID'
            },
            onSearch: (userId: string) =>
                transactionsEvents.setOverwriteFilters({
                    UserId: userId || undefined
                })
        }
    ];

    useEffect(() => {
        setSearchText(UserId || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserId]);

    return <SearchInputSelect defaultSearchText={searchText} items={searchItems} />;
};
