import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { routePaths } from 'v2/constants/routes';
import { authStores } from 'v2/stores/auth';

export const PublicRoute: FC<RouteProps> = props => {
    const isAuth = useStore(authStores.$isAuth);

    if (isAuth) {
        return <Redirect to={routePaths.videos} />;
    }

    return <Route {...props} />;
};
