import { createEffect } from 'effector';
import { API } from 'v1/services';
import { message } from 'v1/stores/alerts';

const errorDeletionUsersMessage = 'User/Users have not been deleted';

const enableUsersById = createEffect({
    handler: async (data: YEAY.AdminEnableUsersRequest) => {
        try {
            const { isSuccess, message: messageResponse = '' } = await API.adminUsers.enableUsersById(data);

            //* show success notification only for bulk user deleting, for single user deleting have StatusModal:
            isSuccess && !!data.users?.length && messageResponse && message.success(messageResponse);

            if (isSuccess && !!data.users?.length) {
                return data.users.map(item => item);
            }

            return [];
        } catch {
            message.error(errorDeletionUsersMessage);
            return [];
        }
    }
});

export const usersEffects = {
    enableUsersById
};
