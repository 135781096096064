import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cardStylesMixin } from 'v2/constants/styles/mixins';

export const CardLink = styled(Link)`
    ${cardStylesMixin};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const VideoWrapper = styled.div`
    width: 100%;
    max-width: 125px;
    height: 190px;
    border-radius: 16px;
    overflow: hidden;
`;
