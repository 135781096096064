import { createEffect, createEvent, createStore } from 'effector';
import { yecmAPI } from 'v2/services/yecm';

//Events
const resetStore = createEvent();

//Effects
const getCollaborationFx = createEffect({
    handler: async (id: string) => await yecmAPI.collaboration.getCollaboration(id)
});

//Stores
const $collaborationInfo = createStore<YECM.CollaborationDetailedResponseApiResponse>({})
    .on(getCollaborationFx.doneData, (_, newState) => newState)
    .reset(resetStore);

const $collaborationId = $collaborationInfo.map(item => item?.data?.id || '');

//Exports
export const collaborationEffects = {
    getCollaborationFx
};

export const collaborationStores = {
    $collaborationInfo,
    $collaborationId
};

export const collaborationEvents = {
    resetStore
};
