import { isNumber, isUndefined } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Dropdown } from 'v2/components/ui/Dropdown';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { RangeInputValuesType, RangeInputs } from 'v2/components/ui/inputs/RangeInputs';
import { OptionsItem } from 'v2/components/ui/selects/MultiSelect/styles';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { useDebounce } from 'v2/hooks/use-debounce';
import { RangeWrapper, TitleWrapper } from './styles';
import { validateValues } from './utils';

enum SelectedCheckbox {
    All,
    Range
}

interface Props {
    fromValue?: number | null;
    toValue?: number | null;
    minLimit?: number;
    maxLimit?: number;
    dropdownTitle?: string;
    unit?: string;
    onChange?: (values: RangeInputValuesType) => void;
}

export const RangeFilter: FC<Props> = ({
    dropdownTitle,
    fromValue,
    toValue,
    onChange,
    minLimit = 0,
    maxLimit = 100,
    unit = ''
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const from = isNumber(fromValue) ? fromValue : undefined;
    const to = isNumber(toValue) ? toValue : undefined;

    const dropdownValue = isNumber(from) && isNumber(to) ? `${from}${unit}-${to}${unit}` : '';

    const initialSelectedCheckbox =
        !isUndefined(from) && !isUndefined(to) ? SelectedCheckbox.Range : SelectedCheckbox.All;
    const [selectedCheckbox, setSelectedCheckbox] = useState(initialSelectedCheckbox);

    const [values, setValues] = useState<RangeInputValuesType>([from, to]);
    const debouncedValues = useDebounce(values);

    const [error, setError] = useState('');

    const handleChangeValue = (value: SelectedCheckbox) => () => {
        setSelectedCheckbox(value);

        if (value === SelectedCheckbox.All && onChange) {
            setValues([undefined, undefined]);
        }
    };

    const handleChangeRange = ([min, max]: RangeInputValuesType) => {
        setValues([min, max]);
    };
    useEffect(() => {
        setError(() => '');

        const error = validateValues(values, minLimit, maxLimit);
        if (error) {
            setError(() => error);
        }
    }, [maxLimit, minLimit, values]);

    useEffect(() => {
        const [min, max] = debouncedValues;

        if (onChange && (min !== from || max !== to)) {
            if (isUndefined(min) && isUndefined(max)) {
                onChange([undefined, undefined]);
            } else if (
                !isUndefined(min) &&
                !isUndefined(max) &&
                max > min &&
                min >= minLimit &&
                max >= minLimit &&
                min <= maxLimit &&
                max <= maxLimit
            ) {
                onChange(debouncedValues);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValues]);

    useEffect(() => {
        handleChangeRange([from, to]);
    }, [from, to]);

    useEffect(() => {
        if (!isOpened) {
            setSelectedCheckbox(initialSelectedCheckbox);
        }
    }, [initialSelectedCheckbox, isOpened]);

    return (
        <Dropdown
            isOpened={isOpened}
            label={dropdownValue}
            placeholder="All"
            title={dropdownTitle}
            onOpenedChange={setIsOpened}
        >
            <Column width="100%">
                <TitleWrapper>
                    <Text isSemibold size="-2">
                        {dropdownTitle}
                    </Text>
                </TitleWrapper>

                <Section>
                    <OptionsItem
                        disabled={selectedCheckbox === SelectedCheckbox.All}
                        type="button"
                        onClick={handleChangeValue(SelectedCheckbox.All)}
                    >
                        <Text noTextWrap size="-2">
                            All
                        </Text>
                        <Column marginLeft="8px">
                            <Checkbox checked={selectedCheckbox === SelectedCheckbox.All} />
                        </Column>
                    </OptionsItem>
                </Section>
                <Section>
                    <OptionsItem
                        disabled={selectedCheckbox === SelectedCheckbox.Range}
                        type="button"
                        onClick={handleChangeValue(SelectedCheckbox.Range)}
                    >
                        <Text noTextWrap size="-2">
                            Specific Range {unit ? `(${unit})` : ''}
                        </Text>
                        <Column marginLeft="8px">
                            <Checkbox checked={selectedCheckbox === SelectedCheckbox.Range} />
                        </Column>
                    </OptionsItem>
                </Section>

                {selectedCheckbox === SelectedCheckbox.Range && (
                    <RangeWrapper>
                        <RangeInputs
                            error={error}
                            max={maxLimit}
                            min={minLimit}
                            values={values}
                            onChange={handleChangeRange}
                        />
                    </RangeWrapper>
                )}
            </Column>
        </Dropdown>
    );
};
