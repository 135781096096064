import React, { FC } from 'react';
import { VideosFilterProps } from 'v2/components/filter/videos/types';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { useVideosSearch } from './hooks';

export const VideosSearch: FC<VideosFilterProps> = ({ domain }) => {
    const {
        searchItems,
        values: { collaborationId, creatorId, videoId }
    } = useVideosSearch({ domain });

    return <SearchInputSelect defaultSearchText={collaborationId || creatorId || videoId || ''} items={searchItems} />;
};
