import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { CloseIcon, PlusIcon } from 'v2/assets/icons';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Avatar } from 'v2/components/ui/Avatar';
import { RadioButton } from 'v2/components/ui/buttons/RadioButton';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { AgentList } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo/AgentList';
import { addAgentEffects } from 'v2/stores/agent/add';
import { editAgentModal } from 'v2/stores/initialize-modals';

enum AgentAction {
    None = 'none',
    Remove = 'remove',
    Change = 'change'
}

interface Props {
    user: YEAY.AdminGetUserCommon;
}

const EditAgentModalInner: FC<Props> = ({ user }) => {
    const [selectedAction, setSelectedAction] = useState(AgentAction.None);
    const [selectedAgentId, setSelectedAgentId] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const onSubmit = () => {
        if (selectedAction === AgentAction.Remove) {
            addAgentEffects.removeMemberFromAgentFx({
                agentId: selectedAgentId,
                memberUserId: user?.id || ''
            });
        }
        if (selectedAction === AgentAction.Change) {
            addAgentEffects.changeAgentFx({
                currentAgentId: user.agentInfo?.agentId || '',
                newAgentUserId: selectedAgentId,
                memberUserId: user?.id || ''
            });
        }
    };

    const onCheckboxChange = () => {
        setIsAgreed(prev => !prev);
    };

    const onSelectAgent = (agentId: string) => {
        setSelectedAgentId(agentId);
    };

    const handleActionChange = (action: AgentAction) => () => {
        if (action === AgentAction.Remove) {
            setSelectedAgentId(user.agentInfo?.agentId || '');
        } else {
            setSelectedAgentId('');
        }
        setSelectedAction(action);
    };

    return (
        <ModalLayout
            noBodyPadding
            submitDisabled={!isAgreed || !selectedAgentId}
            submitText="Apply"
            title="Edit Agent"
            onCancel={() => editAgentModal.closeModal()}
            onSubmit={onSubmit}
        >
            <Section padding="16px 20px">
                <Row alignCenter marginBottom="16px">
                    <Row marginRight="8px">
                        <Avatar size="32px" src={user.agentInfo?.profileImageUrl || ''} />
                    </Row>
                    <Text size="-1">@{user.agentInfo?.username}</Text>
                </Row>
                <RepeatGrid columns={2} gap="16px" width="100%">
                    <RadioButton
                        wide
                        checked={selectedAction === AgentAction.Remove}
                        colorType="red"
                        icon={<CloseIcon height={14} width={14} />}
                        onClick={handleActionChange(AgentAction.Remove)}
                    >
                        Remove
                    </RadioButton>
                    <RadioButton
                        wide
                        checked={selectedAction === AgentAction.Change}
                        icon={<PlusIcon />}
                        onClick={handleActionChange(AgentAction.Change)}
                    >
                        Change Agent
                    </RadioButton>
                </RepeatGrid>
                {selectedAction === AgentAction.Remove && (
                    <Section marginTop="16px">
                        <Checkbox checked={isAgreed} onChange={onCheckboxChange}>
                            <Text size="-2">Are you sure you want to remove this Agent?</Text>
                        </Checkbox>
                    </Section>
                )}
            </Section>
            {selectedAction === AgentAction.Change && (
                <>
                    <AgentList selectAgent={onSelectAgent} selectedAgentId={selectedAgentId} />
                    <SectionBorder borderTop padding="10px 16px">
                        <Checkbox checked={isAgreed} onChange={onCheckboxChange}>
                            <Text size="-2">Are you sure you want to change the Agent?</Text>
                        </Checkbox>
                    </SectionBorder>
                </>
            )}
        </ModalLayout>
    );
};

export const EditAgentModal = () => {
    const { visible } = useStore(editAgentModal.$modal);
    const { user } = useStore(editAgentModal.$state);

    return (
        <ModalWrapper visible={visible} onClose={() => editAgentModal.closeModal()}>
            <EditAgentModalInner user={user} />
        </ModalWrapper>
    );
};
