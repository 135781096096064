import styled from 'styled-components';
import { brightRed, powderPink } from 'v1/constants/styles/colors';
import { flexCenter } from 'v1/constants/styles/mixins';
import { BackgroundColor, BorderRadius, HorizontalPadding, Padding, Sizes, TextProperties } from 'v1/types/styles';

export interface NotificationBadgeProps
    extends Sizes,
        Padding,
        BackgroundColor,
        BorderRadius,
        TextProperties,
        HorizontalPadding {
    children: string;
}

export const NotificationBadge = styled.div<NotificationBadgeProps>`
    font-size: ${({ fontSize }) => fontSize || '8px'};
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ color }) => color || brightRed};
    ${({ width }) => width && `width: ${width}`};
    height: ${({ height }) => height || '17px'};
    border-radius: ${({ borderRadius }) => borderRadius || '4px'};
    background-color: ${({ backgroundColor }) => backgroundColor || powderPink};
    padding: 0 ${({ horizontalPadding }) => horizontalPadding || '4px'};
    white-space: nowrap;
    ${flexCenter};
`;
