import React from 'react';
import { BrandsIcon, CollaborationsIcon, TransactionsIcon, TrendingIcon, UsersIcon, VideosIcon } from 'v2/assets/icons';
import { UserRole } from 'v2/constants/services/users';
import { RoutesItem } from 'v2/types/global';

// App routes

export const routePaths = {
    main: '/',
    login: '/login',
    dashboard: '/dashboard',
    videos: '/videos',
    video: '/videos/:videoId',
    trending: '/trending',
    collaborations: '/collaborations',
    collaboration: '/collaborations/:id',
    reports: '/reports',
    reportVideo: '/reports/video/:id',
    reportAccount: '/reports/account/:id',
    reportCollaboration: '/reports/collaboration/:id',
    users: '/users',
    user: '/users/:userId',
    brands: '/brands',
    brand: '/brands/:id',
    inactive: '/inactive',
    transactions: '/transactions',
    registeredEvents: '/registered_events',
    blacklistedUsers: '/blacklisted_users',
    deletedUsers: '/deleted_users',
    deletedUser: '/deleted_users/:deletedUserId',
    blockedUsers: '/blocked_users',
    blockedUser: '/blocked_users/:blockedUserId',
    error403: '/403',
    error401: '/401'
};

// Sidebar links

export const routesArray: RoutesItem[] = [
    // {
    //     path: routePaths.dashboard,
    //     name: 'dashboard',
    //     icon: <DashboardIcon color="inherit" height="12px" width="12px" />
    // },
    {
        path: routePaths.videos,
        name: 'videos',
        icon: <VideosIcon color="inherit" height="14px" width="12px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator, UserRole.Curator, UserRole.ContentManager]
    },
    {
        path: routePaths.trending,
        name: 'trending',
        icon: <TrendingIcon color="inherit" height="14px" width="10px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator]
    },
    {
        path: routePaths.collaborations,
        name: 'collaborations',
        icon: <CollaborationsIcon color="inherit" height="14px" width="16px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator]
    },
    // {
    //     path: routePaths.reports,
    //     name: 'reports',
    //     icon: <ReportsIcon color="inherit" height="12px" width="10px" />
    // },
    {
        path: routePaths.users,
        name: 'users',
        icon: <UsersIcon color="inherit" height="12px" width="14px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator, UserRole.ContentManager]
    },
    {
        path: routePaths.brands,
        name: 'brands',
        icon: <BrandsIcon color="inherit" height="16px" width="16px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator]
    },
    // {
    //     path: routePaths.inactive,
    //     name: 'inactive',
    //     icon: <InactiveIcon color="inherit" height="10px" width="12px" />
    // },
    {
        path: routePaths.transactions,
        name: 'transactions',
        icon: <TransactionsIcon color="inherit" height="14px" width="10px" />,
        roles: [UserRole.SuperAdministrator, UserRole.Administrator]
    }
    // {
    //     path: routePaths.products,
    //     name: 'products',
    //     icon: <ProductsIcon color="inherit" height="14px" width="10px" />
    // }
    // {
    //     path: routePaths.registeredEvents,
    //     name: 'registered events',
    //     icon: <RegisteredEventsIcon color="inherit" height="16px" width="16px" />
    // }
];
