import { css, FlattenSimpleInterpolation } from 'styled-components';

export const sizes = {
    phone: '390',
    phoneMedium: '460',
    phoneLarge: '576',
    tabletSmall: '768',
    tablet: '992',
    laptopSmall: '1024',
    laptopMedium: '1200',
    laptop: '1380',
    desktop: '1440',
    desktopLarge: '1600'
};

type KeysType = keyof typeof sizes;
type MediaFunction = (styles: string) => FlattenSimpleInterpolation;

export const media = (Object.keys(sizes) as KeysType[]).reduce((acc, label) => {
    acc[label] = (styles: string) => css`
        @media (min-width: ${sizes[label]}px) {
            ${styles};
        }
    `;

    return acc;
}, {} as { [key in KeysType]: MediaFunction });
