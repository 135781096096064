import { createEffect, createEvent, createStore, guard } from 'effector';
import { isEmpty, isEqual, isUndefined, pickBy } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { defaultUsersParams } from 'v2/constants/services/users';
import { API } from 'v2/services/yasy';

// Types

export interface UsersParams extends Partial<YEAY.QueryAllUsersRequest> {}
export interface UsersParamsWithUserId extends UsersParams {
    userId?: string;
}

// Generic

export const initializeUsersStore = (defaultParams: UsersParamsWithUserId = defaultUsersParams) => {
    const addFilters = createEvent<UsersParamsWithUserId>();
    const setOverwriteFilters = createEvent<UsersParamsWithUserId>();
    const setUserId = createEvent<string | undefined>();
    const resetUsers = createEvent();
    const resetFilters = createEvent();

    const getUsersFx = createEffect({
        handler: async ({ userId, pageIndex, limit, ...params }: UsersParamsWithUserId = defaultParams) => {
            try {
                if (userId) {
                    const user = await API.user.getUserById({ id: userId });

                    const hasUser = !isEmpty(user);
                    const data = hasUser ? [user] : [];
                    const dataCount = hasUser ? 1 : 0;

                    return {
                        queryStatistics: {},
                        currentPageIndex: 0,
                        totalPages: dataCount,
                        returnedRecords: dataCount,
                        totalRecords: dataCount,
                        items: data
                    };
                } else {
                    const data = await API.users.getUsers({
                        pageIndex: pageIndex || defaultUsersParams.pageIndex,
                        limit: limit || defaultUsersParams.limit,
                        ...params
                    });

                    if (!data) {
                        throw new Error();
                    }

                    return data;
                }
            } catch {
                notify('There was a problem getting users, try to refresh the page.', 'error');
                return null;
            }
        }
    });

    const $usersResponse = createStore<YEAY.QueryUsersResponse | null>(null)
        .on(getUsersFx.doneData, (_, users) => users)
        .reset(resetUsers);

    const $usersFilters = createStore<UsersParamsWithUserId>(defaultParams)
        .on(addFilters, (state, newFilters) => {
            const filters: UsersParamsWithUserId = {
                ...(state || defaultParams),
                ...newFilters,
                userId: undefined,
                pageIndex: newFilters.pageIndex || defaultParams.pageIndex
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .on(setOverwriteFilters, (_, newFilters) => {
            const filters: UsersParamsWithUserId = {
                ...defaultParams,
                ...newFilters,
                userId: undefined
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .on(setUserId, (_, userId) => {
            const filters: UsersParamsWithUserId = {
                ...defaultParams,
                userId: userId || undefined
            };

            return pickBy(filters, value => !isUndefined(value));
        })
        .reset(resetFilters);

    const $resetIsAvailable = $usersFilters.map(filters => !isEqual(filters, defaultParams));

    guard({
        clock: $usersFilters,
        filter: data => !!data,
        target: getUsersFx
    });

    const effects = { getUsersFx };

    const stores = {
        $usersResponse,
        $usersFilters,
        $resetIsAvailable
    };

    const events = {
        addFilters,
        setOverwriteFilters,
        setUserId,
        resetFilters,
        resetUsers
    };

    return { stores, effects, events };
};

export type GenericUsersType = ReturnType<typeof initializeUsersStore>;

// Generic stores

export const userPageStore = initializeUsersStore();
export const influencerUsersModalStore = initializeUsersStore({
    ...defaultUsersParams,
    isAgent: false,
    isAgentMember: false
});
export const trendingUsersStore = initializeUsersStore();
