import styled from 'styled-components';
import { Text } from 'v2/components/ui/typography/Text';

export const TextWrapper = styled.span`
    display: table;
    table-layout: fixed;
    width: fit-content;
    max-width: 100%;
`;

export const StyledText = styled(Text)`
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
