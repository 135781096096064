import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { RangeFilter } from 'v2/components/filter/RangeFilter';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { RangeInputValuesType } from 'v2/components/ui/inputs/RangeInputs';
import { maxAvailableAge, minAvailableAge } from './constants';

export const AgeFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { fromAge, toAge } = useStore($usersFilters);

    const handleChange = ([minAge, maxAge]: RangeInputValuesType) => {
        addFilters({
            fromAge: minAge,
            toAge: maxAge
        });
    };

    return (
        <RangeFilter
            dropdownTitle="Age"
            fromValue={fromAge}
            maxLimit={maxAvailableAge}
            minLimit={minAvailableAge}
            toValue={toAge}
            onChange={handleChange}
        />
    );
};
