import * as Popper from 'popper.js';
import React, { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ArrowIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { useToggle } from 'v2/hooks/use-toggle';
import { getFormattedDate } from 'v2/utils/datetime';
import { ArrowWrapper, CustomInputWrapper, DataStyle } from './styles';

export type DatePickerType = Date | [Date, Date] | null;

interface Props {
    defaultDate?: string;
    onChange: (date: DatePickerType) => void;
    minDate?: Date;
    startPlaceholder?: string;
    endPlaceholder?: string;
    title?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    disabled?: boolean;
    placement?: Popper.Placement;
}

export const DatePicker: FC<Props> = ({
    onChange,
    startDate,
    endDate,
    startPlaceholder,
    endPlaceholder,
    title,
    minDate,
    disabled,
    placement = 'bottom'
}) => {
    const [isCalendarOpened, updateIsCalendarOpened] = useToggle();

    return (
        <>
            <ReactDatePicker
                selectsRange
                customInput={
                    <CustomInputWrapper alignCenter noWrap isDisabled={disabled}>
                        <Row marginRight="8px">
                            <Title size="-2">{title}</Title>
                        </Row>
                        <Row alignCenter noWrap>
                            {startDate ? (
                                <Row alignCenter noWrap>
                                    <Text color={disabled ? grey[500] : undefined} size="-2">
                                        {getFormattedDate(startDate.toISOString(), 'yyyy-MM-dd')}
                                    </Text>
                                    <Text color={disabled ? grey[500] : undefined} size="-2">
                                        -
                                    </Text>
                                </Row>
                            ) : (
                                <Text color={grey[500]} size="-2">
                                    {startPlaceholder}
                                </Text>
                            )}

                            {endDate ? (
                                <Text color={disabled ? grey[500] : undefined} size="-2">
                                    {getFormattedDate(endDate.toISOString(), 'yyyy-MM-dd')}
                                </Text>
                            ) : (
                                <Text color={grey[500]} size="-2">
                                    {endPlaceholder}
                                </Text>
                            )}
                        </Row>

                        <ArrowWrapper isOpen={isCalendarOpened}>
                            <ArrowIcon color={startDate && endDate && !disabled ? brandColors.black : grey[400]} />
                        </ArrowWrapper>
                    </CustomInputWrapper>
                }
                disabled={disabled}
                disabledKeyboardNavigation={disabled}
                endDate={endDate}
                minDate={minDate}
                open={isCalendarOpened}
                popperPlacement={placement}
                startDate={startDate}
                onChange={onChange}
                onClickOutside={updateIsCalendarOpened}
                onInputClick={updateIsCalendarOpened}
            />
            <DataStyle />
        </>
    );
};
