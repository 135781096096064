import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { ReviewReportModal } from 'v2/components/modals/ReviewReportModal';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ContentWrapper } from 'v2/pages/Report/common/styles';
import { reportsListEffects } from 'v2/stores/report/details/reports-list';
import { reportUserEffects, reportUserEvents, reportUserStores } from 'v2/stores/report/details/user';
import { userNetworksEffects, userNetworksEvents } from 'v2/stores/user-networks';
import { ReportsTable } from '../../../common/ReportsTable';
import { ReportUserAdditionalInfo } from './ReportUserAdditionalInfo';
import { ReportUserMainInfo } from './ReportUserMainInfo';
import { Wrapper } from './styles';

interface UserPageParams {
    id: string | undefined;
}

const { getReportsListFx } = reportsListEffects;

export const ReportInfo = () => {
    const { id } = useParams<UserPageParams>();

    const reportUserInfo = useStore(reportUserStores.$reportUserInfo);
    const isLoading = useStore(reportUserEffects.getReportUserInfoFx.pending);

    const isLoadingReports = useStore(getReportsListFx.pending);

    useEffect(() => {
        if (id) {
            reportUserEffects.getReportUserInfoFx({ userId: id });
            userNetworksEffects.getUserNetworksFx(id);
        }

        return () => {
            reportUserEvents.resetReportUser();
            userNetworksEvents.resetUserNetworks();
        };
    }, [id]);

    return (
        <ContentWrapper>
            {isLoading && (
                <Section justifyCenter>
                    <Spinner />
                </Section>
            )}

            {reportUserInfo && (
                <Section>
                    <Wrapper>
                        <ReportUserMainInfo user={reportUserInfo} />
                        <ReportUserAdditionalInfo user={reportUserInfo} />
                    </Wrapper>
                </Section>
            )}

            {!isLoading && !reportUserInfo && <Empty title="No Report info" />}

            <Section marginTop="10px">
                <AccordionSection title="Reports">
                    <ReportsTable id={id} isLoading={isLoadingReports} />
                </AccordionSection>
            </Section>

            <ReviewReportModal />
        </ContentWrapper>
    );
};
