import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    store: GenericCollaborationsType;
}

export const CollaborationDate: FC<Props> = ({ store }) => {
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);
    const [dateRange, setDateRange] = useState<DatePickerType>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange as Date[];

    useEffect(() => {
        if (collaborationsFilters?.utcStart && collaborationsFilters?.utcEnd) {
            setDateRange([new Date(collaborationsFilters?.utcStart), new Date(collaborationsFilters?.utcEnd)]);
        } else {
            setDateRange([new Date(), new Date()]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collaborationsFilters?.utcStart, collaborationsFilters?.utcEnd]);

    const onChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            store.events.overwriteFilters({
                utcStart: start.toISOString(),
                utcEnd: end.toISOString()
            });
        }
    };

    return (
        <DatePicker
            endDate={endDate}
            endPlaceholder=" 2020-12-26"
            startDate={startDate}
            startPlaceholder="2020-12-25 -"
            title="Dates"
            onChange={onChangeDate}
        />
    );
};
