import React, { FC } from 'react';
import { LoadingIcon } from 'v2/assets/icons';
import { SpinnerWrapper } from './styles';

interface Props {
    size?: number;
}

export const Spinner: FC<Props> = ({ size = 24 }) => (
    <SpinnerWrapper size={size}>
        <LoadingIcon height={size} width={size} />
    </SpinnerWrapper>
);
