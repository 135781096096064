import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Pagination } from 'v2/components/ui/Pagination';
import { PropertyLayout } from 'v2/components/ui/PropertyLayout';
import { Table } from 'v2/components/ui/Table';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { referralUsersEffects, referralUsersEvents, referralUsersStores } from 'v2/stores/referral-users';
import { getPageIndex } from 'v2/utils/services';
import { columns } from './constants';
import { BlockWrapper, GridWrapper } from './styles';
import { prepareDataForTable } from './utils';

interface Props {
    data: YEAY.UserReferralInfo;
}

const { getInvitedUsersFx } = referralUsersEffects;
const { updateParams } = referralUsersEvents;

export const DropdownReferrals: FC<Props> = ({ data }) => {
    const { id, referralLink, downloadsCount, signUpsCount } = data;

    const referralUsers = useStore(referralUsersStores.$invitedUsers);
    const usersIsLoading = useStore(getInvitedUsersFx.pending);
    const totalRecords = referralUsers?.totalCount || 0;

    const params = useStore(referralUsersStores.$referralUsersParams);
    const limit = params.limit;
    const currentPage = (params.pageIndex || 0) + 1;

    const handlePageChange = (page: number) => {
        updateParams({ pageIndex: getPageIndex(page) });
    };

    const handleLimitChange = (size: number) => {
        updateParams({ limit: size });
    };

    const tableData = prepareDataForTable(referralUsers?.items || []);

    useEffect(() => {
        id && getInvitedUsersFx({ referralId: id, params: { pageIndex: currentPage - 1, limit: limit } });
    }, [id, currentPage, limit]);

    return (
        <Column noWrap width="100%">
            <SectionBorder borderBottom borderTop>
                <GridWrapper>
                    {referralLink && (
                        <BlockWrapper>
                            <PropertyLayout textCopy={referralLink} title="User Referral Link">
                                <Text isSemibold size="-2">
                                    {referralLink}
                                </Text>
                            </PropertyLayout>
                        </BlockWrapper>
                    )}

                    <SectionBorder borderLeft>
                        <PropertyLayout title="Total Registered Count">
                            <Text isSemibold size="-2">
                                {signUpsCount || 0}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>

                    <SectionBorder borderLeft>
                        <PropertyLayout title="Total Download Count">
                            <Text isSemibold size="-2">
                                {downloadsCount || 0}
                            </Text>
                        </PropertyLayout>
                    </SectionBorder>
                </GridWrapper>
            </SectionBorder>

            {usersIsLoading ? (
                <Section justifyCenter marginTop="12px">
                    <Spinner />
                </Section>
            ) : (
                tableData.length > 0 && (
                    <Column noWrap width="100%">
                        <Table
                            cellCenter
                            bottomExtra={
                                <SectionBorder borderTop padding="0 8px">
                                    <Pagination
                                        currentPage={currentPage}
                                        size={limit}
                                        totalItems={totalRecords}
                                        onChange={handlePageChange}
                                        onPageSizeChange={handleLimitChange}
                                    />
                                </SectionBorder>
                            }
                            columns={columns}
                            data={tableData}
                            emptyMessage="No data Found"
                            isLoading={usersIsLoading}
                        />
                    </Column>
                )
            )}
        </Column>
    );
};
