import { useStore } from 'effector-react';
import { isUndefined } from 'lodash';
import React, { FC } from 'react';
import { Select } from 'v2/components/ui/selects/Select';
import { SelectOption } from 'v2/components/ui/selects/types';
import { Target, TargetAudience, collaborationsTargetOptions } from 'v2/constants/services/collaborations';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    options?: SelectOption[];
    store: GenericCollaborationsType;
}

export const CollaborationTarget: FC<Props> = ({ options, store }) => {
    const { target, autoApproveParticipants } = useStore(store.stores.$collaborationsFilters);

    const value = (() => {
        if (isUndefined(target)) {
            return Target.All;
        }

        if (target === TargetAudience.Everyone) {
            return Target.AllYeayUsers;
        } else {
            return autoApproveParticipants ? Target.VerifiedOnly : Target.VerifiedApproval;
        }
    })();

    const handleChange = (state: SelectOption) => {
        if (state.value?.toString() === Target.All) {
            store.events.overwriteFilters({
                autoApproveMedia: undefined,
                autoApproveParticipants: undefined,
                target: undefined
            });
        } else {
            store.events.overwriteFilters({
                autoApproveMedia: state.value?.toString() !== Target.AllYeayUsers,
                autoApproveParticipants: state.value?.toString() !== Target.VerifiedApproval,
                target:
                    state.value?.toString() === Target.AllYeayUsers ? TargetAudience.Everyone : TargetAudience.Trusted
            });
        }
    };

    return (
        <Select
            wide
            options={options || collaborationsTargetOptions}
            title="Targeting"
            value={value}
            onChange={handleChange}
        />
    );
};
