import styled, { css } from 'styled-components';
import { CheckMarkProps } from 'v2/components/ui/inputs/Checkbox';
import { brandColors, green, grey, textColors } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

// Functions

const getBackgroundColor = (props: CheckMarkProps) => {
    if (props.disabled) {
        return grey[400];
    }

    if (props.checked) {
        return brandColors.green;
    }

    return 'transparent';
};

const getBorderColor = (props: CheckMarkProps) => {
    if (props.hasError) {
        return brandColors.red;
    }

    if (props.checked) {
        return brandColors.green;
    }

    return grey[400];
};

const getTextColor = (props: CheckMarkProps) => {
    if (props.hasError) {
        return brandColors.red;
    }

    return textColors.main;
};

// Components

export const HiddenCheckbox = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    border: none;
    opacity: 0;
`;

export const StyledMark = styled.div<CheckMarkProps>`
    width: ${({ isLarge }) => (isLarge ? '24px' : '16px')};
    height: ${({ isLarge }) => (isLarge ? '24px' : '16px')};
    flex-shrink: 0;
    ${flexCenter};
    background-color: ${getBackgroundColor};
    border: 1px solid ${getBorderColor};
    border-radius: ${({ isRounded }) => (isRounded ? '50px' : '2px')};
    transition: background-color ${transitionTime} linear, border ${transitionTime} linear;
`;

export const Label = styled.label<CheckMarkProps>`
    width: fit-content;
    display: flex;
    align-items: center;
    color: ${getTextColor};
    position: relative;
    user-select: none;
    cursor: not-allowed;
    transition: color ${transitionTime} linear;

    ${({ disabled }) =>
        !disabled &&
        css`
            cursor: pointer;

            &:hover ${StyledMark} {
                border-color: ${grey[600]};
            }

            &:active ${StyledMark} {
                background-color: ${green[200]};
                border-color: ${green[200]};
            }
        `}
`;
