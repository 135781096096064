import styled from 'styled-components';
import { CustomImage } from 'v1/components/common/imgComponents/CustomImg/styles';
import { CheckboxProps } from 'v1/components/common/inputs/Checkbox/types';
import { Row } from 'v1/components/wrappers/grid/FlexWrapper/styles';
import { black, grey2, white } from 'v1/constants/styles/colors';
import { transitionTime } from 'v1/constants/styles/others';
import { borderWidth } from 'v1/constants/styles/sizes';

export const Wrapper = styled(Row)`
    align-items: center;
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`;

export const VisibleLabel = styled.span`
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-left: 8px;

    cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const VisibleCheckbox = styled.div<CheckboxProps>`
    position: relative;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 2px;
    border: ${borderWidth} solid ${black};
    transition: ${transitionTime};
    cursor: pointer;
    ${({ disabled }) => (disabled ? `background: '${white}'; border-color: ${grey2}; cursor: not-allowed` : ``)};
    ${CustomImage} {
        visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
    }
`;
