import React, { FC } from 'react';
import { ButtonProps } from 'v2/components/ui/buttons/Button';
import { StyledButton } from './styles';

type ColorType = 'green' | 'red';

export interface RadioButtonProps extends Omit<ButtonProps, 'type' | 'align' | 'buttonType' | 'href' | 'isExternal'> {
    checked?: boolean;
    colorType?: ColorType;
}

export const RadioButton: FC<RadioButtonProps> = ({ checked, disabled, children, colorType = 'green', ...props }) => (
    <StyledButton
        align="start"
        buttonType="text-reset"
        checked={checked}
        colorType={colorType}
        disabled={disabled}
        {...props}
    >
        {children}
    </StyledButton>
);
