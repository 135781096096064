import React, { FC } from 'react';
import { Disabled, HTMLButtonType } from 'v2/types/form';
import { ReactClick } from 'v2/types/react';
import { StyledButton } from './styles';

interface Props extends ReactClick<HTMLButtonElement>, HTMLButtonType, Disabled {
    size?: string;
    color?: string;
    hoverColor?: string;
    bgColor?: string;
    bgHoverColor?: string;
    rotate?: number;
    rounded?: boolean;
}

export const IconButton: FC<Props> = ({ children, type = 'button', ...props }) => (
    <StyledButton type={type} {...props}>
        {children}
    </StyledButton>
);
