import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { defaultReportsListParams } from 'v2/constants/services/report';
import { API } from 'v2/services/yasy';

// Types

export type ReportsListParams = Partial<Paths.ReportsReportList.Get.QueryParameters>;

// Events

const resetReportsList = createEvent();
const setReportListItems = createEvent<YEAY.ReportDetailResponse[] | null>();

// Effects

const getReportsListFx = createEffect({
    handler: async (params?: ReportsListParams) => {
        try {
            const { isSuccess, data } = await API.report.getReportsList({
                ...defaultReportsListParams,
                ...params
            });

            if (!isSuccess || !data) {
                throw new Error();
            }
            return data;
        } catch (error) {
            notify('There was a problem receiving reports list, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $reportsList = createStore<YEAY.GetReportDetailsResponse | null>(null)
    .on(getReportsListFx.doneData, (state, reports) => {
        if (!reports) return;

        return {
            ...(state || {}),
            ...reports,
            items: reports.pageIndex === 0 ? reports.items : [...(state?.items || []), ...(reports.items || [])]
        };
    })
    .on(getReportsListFx.doneData, (state, payload) => ({
        ...state,
        ...payload,
        items: payload?.pageIndex === 0 ? payload?.items : [...(state?.items || []), ...(payload?.items || [])]
    }))
    .on(setReportListItems, (state, payload) => {
        if (!state) {
            return null;
        }

        return {
            ...state,
            items: payload
        };
    })
    .reset(resetReportsList);

const $reportsListItems = $reportsList.map(state => state?.items || []);
const $reportsListHasNext = $reportsList.map(state => (!state || state.hasNext === undefined ? true : state.hasNext));
const $reportsListCurrentPage = $reportsList.map(state =>
    !state || state.pageIndex === undefined ? -1 : state.pageIndex
);

// Exports

export const reportsListEvents = { resetReportsList, setReportListItems };

export const reportsListEffects = { getReportsListFx };

export const reportsListStores = {
    $reportsList,
    $reportsListItems,
    $reportsListHasNext,
    $reportsListCurrentPage
};
