import {
    autoUpdate,
    flip,
    FloatingPortal,
    Placement,
    shift,
    size,
    useDismiss,
    useFloating,
    useInteractions
} from '@floating-ui/react';
import React, { FC, ReactNode, useRef } from 'react';
import { ArrowIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Title } from 'v2/components/ui/typography/Title';
import { Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { ArrowWrapper, DropdownWrapper, FloatingWrapper, SelectTitle } from './styles';

interface Props {
    isOpened?: boolean;
    titleComponent?: ReactNode;
    title?: string;
    label?: string;
    placeholder?: string;
    placement?: Placement;
    wide?: boolean;
    disabled?: boolean;
    withBackground?: boolean;
    icon?: ReactNode;
    onOpenedChange?: (isOpen: boolean) => void;
    minWidth?: number;
}

export const Dropdown: FC<Props> = ({
    isOpened,
    children,
    title,
    label,
    disabled,
    wide,
    withBackground,
    titleComponent,
    icon,
    minWidth,
    onOpenedChange,
    placeholder = 'All',
    placement = 'bottom-start'
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const { x, y, strategy, refs, context } = useFloating({
        open: isOpened,
        onOpenChange: onOpenedChange,
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [
            flip(),
            shift(),
            size({
                apply({ rects, elements, availableHeight }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                        maxHeight: `${availableHeight}px`
                    });
                }
            })
        ]
    });
    const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const toggleIsOpen = () => {
        onOpenedChange?.(!isOpened);
    };

    return (
        <DropdownWrapper ref={parentRef} width={wide ? '100%' : undefined}>
            <SelectTitle
                ref={refs.setReference}
                disabled={disabled}
                isOpened={isOpened}
                type="button"
                withBackground={withBackground}
                onClick={toggleIsOpen}
                {...getReferenceProps()}
            >
                {titleComponent || (
                    <Section alignCenter justifyBetween noWrap>
                        {icon && <Row marginRight="8px">{icon}</Row>}

                        {title && (
                            <Row marginRight="8px">
                                <Title noTextWrap size="-2">
                                    {title}
                                </Title>
                            </Row>
                        )}

                        <Row alignCenter noWrap justifyBetween={!title} width={!title ? '100%' : undefined}>
                            <Text noTextWrap color={!!label ? brandColors.black : grey[400]} size="-2">
                                {label || placeholder}
                            </Text>

                            <ArrowWrapper isOpened={isOpened}>
                                <ArrowIcon color={label ? brandColors.black : grey[400]} />
                            </ArrowWrapper>
                        </Row>
                    </Section>
                )}
            </SelectTitle>

            {isOpened && (
                <FloatingPortal>
                    <FloatingWrapper
                        ref={refs.setFloating}
                        left={x || 0}
                        minWidth={minWidth}
                        position={strategy}
                        top={y || 0}
                        {...getFloatingProps()}
                    >
                        {children}
                    </FloatingWrapper>
                </FloatingPortal>
            )}
        </DropdownWrapper>
    );
};
