import styled from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const OptionWrapper = styled(Section)`
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
`;

interface Props {
    isActive?: boolean;
}

export const StyledButton = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    height: 100%;
    padding: 5px 8px;
    text-align: left;
    background-color: ${({ isActive }) => (isActive ? brandColors.grey : brandColors.white)};
    transition: background-color ${transitionTime} linear;

    &:disabled {
        cursor: default;
    }

    &:hover:not(:disabled) {
        background-color: ${brandColors.grey};
    }
`;
