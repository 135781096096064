import styled, { css } from 'styled-components';
import { ModalSize } from 'v2/components/wrappers/ModalWrapper';
import { brandColors } from 'v2/constants/styles/colors';
import { fixedCenterAlignmentMixin, scrollbarMixin } from 'v2/constants/styles/mixins';
import { ZIndexNumber } from 'v2/types/styles';

export const ModalBackground = styled.div<ZIndexNumber>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: ${brandColors.black};
    opacity: 0.7;
    z-index: ${({ zIndex }) => zIndex || 1000};
`;

interface Props extends ZIndexNumber {
    size?: ModalSize;
}

const getStyleForModalSize = (props: Props) => {
    switch (props.size) {
        case 'large': {
            return css`
                width: 100%;
                max-width: 800px;
                max-height: 90vh;
            `;
        }
        default:
        case 'default': {
            return css`
                width: 100%;
                max-width: 450px;
                max-height: 90vh;
            `;
        }
    }
};

export const ModalContentWrapper = styled.div<Props>`
    ${fixedCenterAlignmentMixin};
    ${scrollbarMixin};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: ${brandColors.white};
    overflow-y: auto;
    overflow-x: hidden;
    z-index: ${({ zIndex }) => (zIndex ? zIndex + 1 : 1001)};
    ${props => getStyleForModalSize(props)};
`;
