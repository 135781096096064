import { SelectOption } from 'v2/components/ui/selects/types';
import { StatusType } from 'v2/pages/Reports/tabs/components/ReportStatus/constants';

export enum ReportSearchType {
    ReportId,
    VideoId,
    AccountId,
    CollabId
}

export enum ReportCampaignManagerSearchType {
    ReportId,
    VideoId,
    PostId
}

export const reportStatusOptions: SelectOption[] = [
    { value: undefined, label: 'All' },
    { value: StatusType.Review, label: 'Review' },
    { value: StatusType.Accepted, label: 'Accepted' },
    { value: StatusType.Rejected, label: 'Rejected' }
];
