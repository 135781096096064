import React, { FC } from 'react';
import { NetworkIcon } from 'v2/components/common/NetworkIcon';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { RewardWrapper } from './styles';

interface Props {
    count?: number;
    type?: number;
}

export const Reward: FC<Props> = ({ count, type }) => (
    <RewardWrapper alignCenter noWrap>
        <Text size="-2">${count || 0}</Text>

        <Row marginLeft="6px">
            <NetworkIcon postType={type || 0} />
        </Row>
    </RewardWrapper>
);
