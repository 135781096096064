import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { SelectButton } from 'v2/components/ui/buttons/SelectButton';
import { Checkbox } from 'v2/components/ui/inputs/Checkbox';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { UserRole } from 'v2/constants/services/users';
import { authStores } from 'v2/stores/auth';
import { editUserRoleModal } from 'v2/stores/initialize-modals';
import { singleUserActionsEffects } from 'v2/stores/single-user/actions';
import { roleOptions } from './constants';

const EditUserRoleModalInner = () => {
    const isSuperAdmin = useStore(authStores.$isSuperAdmin);

    const { userId, roles } = useStore(editUserRoleModal.$state);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [isAgreed, setIsAgreed] = useState(false);

    useEffect(() => {
        setSelectedRoles(roles);
    }, [roles]);

    const onSubmit = () => {
        singleUserActionsEffects.editUserRolesFx({
            userId,
            roles: selectedRoles
        });
    };

    const onReasonClick = (role: string) => () => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles(selectedRoles.filter(currentRole => currentRole !== role));
        } else {
            setSelectedRoles([...selectedRoles, role]);
        }
    };

    return (
        <ModalLayout
            submitDisabled={!isAgreed}
            submitText="Apply"
            title="Edit Role"
            onCancel={() => editUserRoleModal.closeModal()}
            onSubmit={onSubmit}
        >
            <RepeatGrid columns={1} gap="8px" marginBottom="20px" marginTop="12px" width="100%">
                {roleOptions.map(currentRole => (
                    <SelectButton
                        key={currentRole.value}
                        disabled={!isSuperAdmin && currentRole.value === UserRole.SuperAdministrator}
                        selected={selectedRoles.includes(currentRole.value)}
                        onClick={onReasonClick(currentRole.value)}
                    >
                        {currentRole.text}
                    </SelectButton>
                ))}
            </RepeatGrid>
            <Checkbox checked={isAgreed} onChange={() => setIsAgreed(prev => !prev)}>
                Are you sure you want to edit this?
            </Checkbox>
        </ModalLayout>
    );
};

export const EditUserRoleModal = () => {
    const { visible } = useStore(editUserRoleModal.$modal);

    return (
        <ModalWrapper visible={visible} onClose={() => editUserRoleModal.closeModal()}>
            <EditUserRoleModalInner />
        </ModalWrapper>
    );
};
