import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { ButtonSort } from 'v2/components/filter/videos/ButtonSort';
import { CategoryFilter } from 'v2/components/filter/videos/CategoryFilter';
import { DateFilter } from 'v2/components/filter/videos/DateFilter';
import { EngagementFilter } from 'v2/components/filter/videos/EngagementFilter';
import { StatusFilter } from 'v2/components/filter/videos/StatusFilter';
import { TrustedFilter } from 'v2/components/filter/videos/TrustedFilter';
import { VideosSearch } from 'v2/components/filter/videos/VideosSearch';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Pagination } from 'v2/components/ui/Pagination';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { useQueryParams } from 'v2/hooks/use-query-params';
import { VideosParamsWithVideoId, videosPageDomain } from 'v2/stores/videos';
import { getPageIndex } from 'v2/utils/services';
import { BottomWrapper, ChildrenWrapper, FiltersWrapper } from './styles';

const { events, stores } = videosPageDomain;

const { $videos, $videosFilters, $resetIsAvailable } = stores;
const { addFilters, resetFilters, setOverwriteFilters, setVideoId } = events;

interface Props {
    isLoading?: boolean;
}

export const VideosFilterLayout: FC<Props> = ({ children, isLoading }) => {
    const filters = useStore($videosFilters);
    const videosResponse = useStore($videos);
    const resetIsAvailable = useStore($resetIsAvailable);

    const totalPages = videosResponse?.totalPages || 0;
    const currentPage = (videosResponse?.currentPageIndex || 0) + 1;
    const totalItems = videosResponse?.totalRecords || 0;
    const limit = filters.limit;

    const handlePageChange = (page: number) => {
        addFilters({ pageIndex: getPageIndex(page) });
    };

    const handleLimitChange = (size: number) => {
        addFilters({ limit: size });
    };

    const handleResetFilters = () => {
        resetFilters();
    };

    const [, setQueryParams] = useQueryParams(({ videoId, ...params }: VideosParamsWithVideoId) => {
        if (videoId) {
            setVideoId(videoId);
        } else {
            setOverwriteFilters(params);
        }
    });

    useEffect(() => {
        if (filters.videoId) {
            setQueryParams({
                videoId: filters.videoId
            });
        } else {
            setQueryParams(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <FiltersWrapper isLoading={isLoading}>
                <SectionBorder alignCenter borderBottom noWrap padding="12px 16px">
                    <Section maxWidth="500px">
                        <VideosSearch domain={videosPageDomain} />
                    </Section>
                    <Row marginLeft="16px">
                        <TrustedFilter domain={videosPageDomain} />
                    </Row>
                </SectionBorder>

                <SectionBorder alignCenter borderBottom justifyBetween noWrap padding="8px 16px">
                    <Row alignCenter>
                        <Column marginRight="8px">
                            <TotalBadge count={totalItems} />
                        </Column>

                        <Column marginRight="8px">
                            <CategoryFilter domain={videosPageDomain} />
                        </Column>

                        <Column marginRight="8px">
                            <StatusFilter domain={videosPageDomain} />
                        </Column>

                        <Column marginRight="8px">
                            <EngagementFilter domain={videosPageDomain} />
                        </Column>

                        <Column marginRight="8px">
                            <DateFilter domain={videosPageDomain} />
                        </Column>
                    </Row>

                    <Row alignCenter>
                        <Row marginRight="24px">
                            <ButtonSort domain={videosPageDomain} />
                        </Row>

                        <Button
                            buttonType="text-black"
                            disabled={!resetIsAvailable || isLoading}
                            textSize="small"
                            onClick={handleResetFilters}
                        >
                            Reset
                        </Button>
                    </Row>
                </SectionBorder>
            </FiltersWrapper>

            <ChildrenWrapper>
                {children}

                {!isLoading && totalPages > 1 && (
                    <BottomWrapper>
                        <Pagination
                            currentPage={currentPage}
                            size={limit}
                            totalPages={totalPages}
                            onChange={handlePageChange}
                            onPageSizeChange={handleLimitChange}
                        />
                    </BottomWrapper>
                )}
            </ChildrenWrapper>
        </>
    );
};
