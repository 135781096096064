import React, { FC } from 'react';
import {
    AccountIcon,
    ClicksIcon,
    DollarIcon,
    EyesIcon,
    SharesIcon,
    StarIcon,
    ViewsIcon,
    YeayIcon
} from 'v2/assets/icons';
import { CollaborationTooltip } from 'v2/components/common/CollaborationTooltip';
import { Reward } from 'v2/components/common/Reward';
import { Badge } from 'v2/components/ui/Badge';
import { Category } from 'v2/components/ui/Category';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { StartEllipsisProperty } from 'v2/components/ui/PropertyLayout/components/StartEllipsisProperty';
import { Tooltip } from 'v2/components/ui/Tooltip';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { LanguageType, languages } from 'v2/constants/languages';
import { routePaths } from 'v2/constants/routes';
import { TargetAudience, collaborationStatusTitles } from 'v2/constants/services/collaborations';
import { brandColors } from 'v2/constants/styles/colors';
import { CollaborationCardWrapper, EllipsisText, ImageWrapper, RewardWrapper } from './styles';

interface Props {
    data: YECM.CollaborationResponse;
}

export const CollaborationCard: FC<Props> = ({ data }) => {
    const {
        coverImageUrl,
        name,
        categoryInfo,
        organizationInfo,
        engagements,
        targetAudience,
        autoApproveMedia,
        autoApproveParticipants,
        state,
        participationRequirements,
        participantsEnrolledCount,
        participationLimits,
        budget,
        networks,
        utcStart,
        utcEnd,
        id
    } = data;

    const isApproveBrandCreators =
        !autoApproveParticipants && autoApproveMedia && targetAudience === TargetAudience.Trusted;
    const isApprove = autoApproveParticipants && autoApproveMedia && targetAudience === TargetAudience.Trusted;

    const categoryEmoji = categoryInfo?.categoryEmoji !== null ? categoryInfo?.categoryEmoji : '';
    const categoryInfoTitle = categoryInfo?.categoryId !== null ? categoryInfo?.categoryId : '';

    const currentLanguages = participationRequirements?.languages;

    return (
        <CollaborationCardWrapper to={`${routePaths.collaborations}/${id}`}>
            <SectionBorder alignCenter borderBottom justifyBetween noWrap minHeight="54px" padding="12px 8px">
                <EllipsisText isSemibold size="-1">
                    {name}
                </EllipsisText>

                <Row alignCenter noWrap>
                    {isApproveBrandCreators && (
                        <Row noWrap marginRight="8px">
                            <CollaborationTooltip
                                icon={<EyesIcon />}
                                placement="topRight"
                                text="Brand set approved creators only"
                            />
                            <Row marginLeft="8px">
                                <CollaborationTooltip
                                    icon={<StarIcon height="100%" width="100%" />}
                                    text="Brand set verified creators only"
                                />
                            </Row>
                        </Row>
                    )}

                    {isApprove && (
                        <Row marginRight="8px">
                            <CollaborationTooltip
                                icon={<StarIcon height="100%" width="100%" />}
                                text="Brand set verified creators only"
                            />
                        </Row>
                    )}

                    {state && (
                        <Badge isUppercase status={state}>
                            {collaborationStatusTitles[state]}
                        </Badge>
                    )}
                </Row>
            </SectionBorder>

            <SectionBorder justifyCenter padding="8px 0">
                {coverImageUrl ? (
                    <ImageWrapper borderRadius="16px" height="190px" image={coverImageUrl} width="124px" />
                ) : (
                    <ImageWrapper borderRadius="16px" height="190px" width="124px">
                        <Row alignCenter justifyCenter height="100%">
                            <YeayIcon height="30px" width="20px" />
                        </Row>
                    </ImageWrapper>
                )}
                <Section alignCenter justifyCenter marginTop="10px">
                    <Row alignCenter noWrap marginRight="8px">
                        <ImageWrapper
                            borderRadius="50%"
                            height="24px"
                            image={organizationInfo?.profileImageUrl || ''}
                            width="24px"
                        />
                        <Row marginLeft="8px">
                            <Text isSemibold size="-2">
                                {organizationInfo?.title}
                            </Text>
                        </Row>
                    </Row>
                    {categoryInfo && <Category text={`${categoryEmoji} ${categoryInfoTitle}`} />}
                </Section>
            </SectionBorder>

            <SectionBorder borderBottom borderTop justifyCenter padding="8px 0">
                <IconTextItem icon={<ViewsIcon />} text={engagements?.totalViews || 0}></IconTextItem>
                <Row marginLeft="24px">
                    <IconTextItem icon={<ClicksIcon />} text={engagements?.totalClicks || 0}></IconTextItem>
                </Row>
                <Row marginLeft="24px">
                    <IconTextItem icon={<SharesIcon />} text={engagements?.totalShares || 0}></IconTextItem>
                </Row>
            </SectionBorder>

            <SectionBorder justifyBetween padding="8px">
                {(!currentLanguages || currentLanguages.length === 0) && <Text size="-2">-</Text>}
                {currentLanguages && currentLanguages.length === 1 && (
                    <EllipsisText maxWidth="50px" size="-2">
                        {languages[currentLanguages.toString() as LanguageType]}
                    </EllipsisText>
                )}
                {currentLanguages && currentLanguages.length > 1 && (
                    <Tooltip
                        maxWidth="fit-content"
                        overlay={
                            <Column marginBottom="-8px">
                                {currentLanguages.map(item => (
                                    <Section key={item} marginBottom="8px">
                                        <Text color={brandColors.white} size="-2">
                                            {languages[item as LanguageType]}
                                        </Text>
                                    </Section>
                                ))}
                            </Column>
                        }
                        placement="top"
                    >
                        <Row>
                            <EllipsisText maxWidth="50px" size="-2">
                                {languages[currentLanguages.slice(0, 1).toString() as LanguageType]}
                            </EllipsisText>
                            <Text size="-2"> +{currentLanguages.length - 1}</Text>
                        </Row>
                    </Tooltip>
                )}

                <IconTextItem
                    icon={<AccountIcon />}
                    iconColor={brandColors.black}
                    text={`${participantsEnrolledCount || 0}/${participationLimits?.enrolled || 0}`}
                />

                <IconTextItem
                    icon={<DollarIcon />}
                    iconColor={brandColors.black}
                    text={`${budget?.priceWithFee || 0} in escrow`}
                />
            </SectionBorder>

            <SectionBorder borderBottom borderTop marginBottom="-8px" padding="6px 8px">
                {networks && networks.length > 0
                    ? networks.map(item => (
                          <RewardWrapper key={`${item?.networkId} ${item?.postType}`}>
                              <Reward count={item.rewardOnSubmitted} type={item.postType} />
                          </RewardWrapper>
                      ))
                    : '-'}
            </SectionBorder>

            <SectionBorder>
                <DateProperty date={utcStart} direction="row" title="From" />
            </SectionBorder>

            <SectionBorder borderBottom borderTop>
                <DateProperty date={utcEnd} direction="row" title="Until" />
            </SectionBorder>

            <SectionBorder borderTop noWrap>
                <StartEllipsisProperty id={id} title="Collab ID" />

                <SectionBorder borderLeft>
                    <StartEllipsisProperty id={organizationInfo?.id} title="Brand ID" />
                </SectionBorder>
            </SectionBorder>
        </CollaborationCardWrapper>
    );
};
