import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { AccountsFilter } from 'v2/components/filter/users/AccountsFilter';
import { ActivitySort } from 'v2/components/filter/users/ActivitySort';
import { AgeFilter } from 'v2/components/filter/users/AgeFilter';
import { DateFilter } from 'v2/components/filter/users/DateFilter';
import { GenderFilter } from 'v2/components/filter/users/GenderFilter';
import { LocaleFilter } from 'v2/components/filter/users/LocaleFilter';
import { RegisteredTypeFilter } from 'v2/components/filter/users/RegisteredTypeFilter';
import { TrustedFilter } from 'v2/components/filter/users/TrustedFilter';
import { UserRoleFilter } from 'v2/components/filter/users/UserRoleFilter';
import { UsersSearch } from 'v2/components/filter/users/UsersSearch';
import { TotalBadge } from 'v2/components/ui/Badge';
import { Pagination } from 'v2/components/ui/Pagination';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, FlexGrow, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { UsersDateType, defaultUsersParams } from 'v2/constants/services/users';
import { trendingUsersStore } from 'v2/stores/users';
import { getPageIndex } from 'v2/utils/services';

const { events, stores } = trendingUsersStore;

const { $usersFilters, $usersResponse, $resetIsAvailable } = stores;
const { addFilters, resetFilters } = events;

interface Props {
    isLoading?: boolean;
}

export const FilterLayout: FC<Props> = ({ children, isLoading }) => {
    const filters = useStore($usersFilters);
    const usersResponse = useStore($usersResponse);
    const resetIsAvailable = useStore($resetIsAvailable);

    const totalPages = usersResponse?.totalPages || 0;
    const currentPage = (usersResponse?.currentPageIndex || 0) + 1;
    const totalItems = usersResponse?.totalRecords || 0;
    const pageSize = filters.limit || defaultUsersParams.limit;

    const handlePageChange = (page: number) => {
        addFilters({ pageIndex: getPageIndex(page) });
    };

    const handleResetFilters = () => {
        resetFilters();
    };

    return (
        <Column noWrap width="100%">
            <Section alignCenter justifyBetween paddingBottom="12px" paddingTop="12px">
                <FlexGrow>
                    <Section alignCenter noWrap>
                        <Section maxWidth="500px">
                            <UsersSearch store={trendingUsersStore} />
                        </Section>
                        <Row marginLeft="8px">
                            <TrustedFilter store={trendingUsersStore} />
                        </Row>
                    </Section>
                </FlexGrow>

                <Row alignCenter justifyEnd noWrap>
                    <Column marginRight="8px">
                        <ActivitySort store={trendingUsersStore} />
                    </Column>

                    <Button
                        buttonType="text-black"
                        disabled={!resetIsAvailable || isLoading}
                        textSize="small"
                        onClick={handleResetFilters}
                    >
                        Reset
                    </Button>
                </Row>
            </Section>

            <Section alignCenter paddingBottom="8px" paddingTop="8px">
                <Column marginRight="8px">
                    <TotalBadge count={totalItems} />
                </Column>

                <Column marginRight="8px">
                    <RegisteredTypeFilter store={trendingUsersStore} />
                </Column>

                <Column marginRight="8px">
                    <AccountsFilter store={trendingUsersStore} />
                </Column>

                <DateFilter defaultType={UsersDateType.UtcCreated} store={trendingUsersStore} title="Dates" />
            </Section>

            <Section alignCenter paddingBottom="8px" paddingTop="8px">
                <Column marginRight="8px">
                    <LocaleFilter store={trendingUsersStore} />
                </Column>

                <Column marginRight="8px">
                    <AgeFilter store={trendingUsersStore} />
                </Column>

                <Column marginRight="8px">
                    <GenderFilter store={trendingUsersStore} />
                </Column>

                <Column marginRight="8px">
                    <UserRoleFilter store={trendingUsersStore} />
                </Column>
            </Section>

            <Section marginTop="8px">{children}</Section>

            {!isLoading && totalPages > 1 && (
                <Section justifyCenter marginTop="8px">
                    <Pagination
                        currentPage={currentPage}
                        size={pageSize}
                        totalPages={totalPages}
                        onChange={handlePageChange}
                    />
                </Section>
            )}
        </Column>
    );
};
