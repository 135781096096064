import { AdaptiveFixedWidthWrapper } from 'v1/components/wrappers/AdaptiveFixedWidthWrapper/styles';
import { OverflowAutoWrapper } from 'v1/components/wrappers/grid/OverflowAutoWrapper/styles';
import React, { FC } from 'react';

export interface Props {}

export const OverflowAutoLayout: FC<Props> = ({ children }) => (
    <OverflowAutoWrapper>
        <AdaptiveFixedWidthWrapper>{children}</AdaptiveFixedWidthWrapper>
    </OverflowAutoWrapper>
);
