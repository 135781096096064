import styled from 'styled-components';
import { Column } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors } from 'v2/constants/styles/colors';

export const ContentWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 32px;
`;

export const FiltersWrapper = styled(Column)`
    width: 100%;
    flex-wrap: nowrap;
    background-color: ${brandColors.white};
`;
