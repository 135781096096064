import React, { FC, ReactNode } from 'react';
import { ModalFooter } from 'v2/components/layouts/ModalLayout/styles';
import { Button } from 'v2/components/ui/buttons/Button';
import { Title } from 'v2/components/ui/typography/Title';
import { Column, FlexGrow, Section } from 'v2/components/wrappers/FlexWrapper/styles';

interface Props {
    title: string;
    submitText?: string;
    cancelText?: string;
    onSubmit: () => void;
    onCancel: () => void;
    submitDisabled?: boolean;
    cancelDisabled?: boolean;
    noBodyPadding?: boolean;
    footerExtra?: ReactNode;
}

export const ModalLayout: FC<Props> = ({
    submitText = 'Apply',
    cancelText = 'Cancel',
    title,
    children,
    onSubmit,
    onCancel,
    cancelDisabled,
    submitDisabled,
    footerExtra,
    noBodyPadding
}) => (
    <Column noWrap width="100%">
        <Column noWrap padding="20px 16px 0 20px" width="100%">
            <Title size="-1">{title}</Title>
        </Column>
        <Column noWrap padding={noBodyPadding ? undefined : '0 16px'} width="100%">
            {children}
        </Column>
        <ModalFooter>
            {/* <div /> for justify-content: space-between */}
            {footerExtra || <div />}

            <FlexGrow>
                <Section alignCenter justifyEnd noWrap>
                    <Button buttonType="text-black" disabled={cancelDisabled} textSize="small" onClick={onCancel}>
                        {cancelText}
                    </Button>

                    <Section marginLeft="20px" maxWidth="120px">
                        <Button wide disabled={submitDisabled} onClick={onSubmit}>
                            {submitText}
                        </Button>
                    </Section>
                </Section>
            </FlexGrow>
        </ModalFooter>
    </Column>
);
