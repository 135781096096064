import { forward, sample } from 'effector';
import { StatusType } from 'v2/pages/Reports/tabs/components/ReportStatus/constants';
import { loaderEffects } from 'v2/stores/loader';
import { singleVideoEvents, singleVideoStores } from 'v2/stores/single-video';
import { videoActionEffects } from 'v2/stores/single-video/actions';
import { videosEffects } from 'v2/stores/videos';

forward({
    from: [
        videoActionEffects.editVideoCategoryFx.pending,
        videosEffects.curateVideoFx.pending,
        videoActionEffects.editVideoHashtagsFx.pending
    ],
    to: loaderEffects.loaderFx
});

sample({
    clock: videosEffects.curateVideoFx.done,
    source: singleVideoStores.$video,
    fn: (video, { params }) => {
        if (video && params) {
            const newVideo: YEAY.AdminGetVideoResponse = {
                ...video,
                validation: {
                    ...video?.validation,
                    yeay: {
                        ...video?.validation?.yeay,
                        curationState: params.reason === StatusType.NotSet ? StatusType.Accepted : StatusType.Rejected,
                        curationEndedReason: params.reason || video?.validation?.yeay?.curationEndedReason
                    }
                }
            };
            return { ...newVideo };
        }
        return { ...video };
    },
    target: singleVideoEvents.setVideo
});

sample({
    clock: videoActionEffects.editVideoHashtagsFx.done,
    source: singleVideoStores.$video,
    fn: (video, { result }) => {
        if (video && result) {
            const newVideo: YEAY.AdminGetVideoResponse = {
                ...video,
                hashTags: result.hashTags
            };
            return { ...newVideo };
        }
        return { ...video };
    },
    target: singleVideoEvents.setVideo
});

sample({
    clock: videoActionEffects.editWebsiteFx.doneData,
    source: singleVideoStores.$videoFromPlaylist,
    fn: (video, newWebsiteUrl) => {
        if (video && newWebsiteUrl) {
            const newVideo: YEAY.CollaborationPlaylistContent = {
                ...video,
                websiteUrl: newWebsiteUrl
            };
            return newVideo;
        }
        return { ...video };
    },
    target: singleVideoEvents.setVideoFromPlaylist
});
