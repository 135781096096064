import { useStore } from 'effector-react';
import React, { FC, useLayoutEffect } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { DropdownVideos } from 'v2/pages/User/UserInfo/components/DropdownVideos';
import { UserAdditionalInfo } from 'v2/pages/User/UserInfo/components/UserAdditionalInfo';
import { UserMainInfo } from 'v2/pages/User/UserInfo/components/UserMainInfo';
import { singleUserEffects, singleUserEvents, singleUserStores } from 'v2/stores/single-user';
import { userNetworksEffects, userNetworksEvents } from 'v2/stores/user-networks';
import { Wrapper } from './styles';

interface Props {
    userId?: string;
}

export const UserTab: FC<Props> = ({ userId }) => {
    const user = useStore(singleUserStores.$user);
    const isLoading = useStore(singleUserEffects.getUserByIdFx.pending);

    useLayoutEffect(() => {
        if (userId) {
            singleUserEffects.getUserByIdFx(userId);
            userNetworksEffects.getUserNetworksFx(userId);
        }

        return () => {
            singleUserEvents.resetUser();
            userNetworksEvents.resetUserNetworks();
        };
    }, [userId]);

    return (
        <Section>
            {user && (
                <>
                    <Wrapper>
                        <UserMainInfo user={user} />
                        <UserAdditionalInfo isSingleVideo user={user} />
                    </Wrapper>
                    <Section marginTop="8px">
                        <DropdownVideos id={user?.id || ''} />
                    </Section>
                </>
            )}
            {isLoading && !user && (
                <Section justifyCenter marginTop="16px">
                    <Spinner />
                </Section>
            )}
            {!isLoading && !user && <Empty title="User Not Found" />}
        </Section>
    );
};
