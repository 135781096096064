import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOption, SelectOptionValue } from 'v2/components/ui/selects/types';
import { GenericCollaborationsType } from 'v2/stores/collaborations';
import { externalNetworksEffects, externalNetworksStores } from 'v2/stores/external-networks';

interface Props {
    store: GenericCollaborationsType;
}

export const CollaborationSocial: FC<Props> = ({ store }) => {
    const externalNetworks = useStore(externalNetworksStores.$externalNetworks);
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);

    useEffect(() => {
        externalNetworksEffects.getExternalNetworksFx();
    }, []);

    const socialOptions: SelectOption[] = externalNetworks.map(externalNetwork => ({
        label: externalNetwork.title || '',
        value: externalNetwork.id
    }));

    const onChangeSocialAccounts = (id: SelectOptionValue[]) => {
        store.events.overwriteFilters({
            publishingNetworkIds: id as string[]
        });
    };

    return (
        <MultiSelect
            wide
            options={socialOptions}
            title="Social Accounts"
            values={collaborationsFilters?.publishingNetworkIds}
            onChange={onChangeSocialAccounts}
        />
    );
};
