import React from 'react';
import { CollaborationCategories } from 'v2/components/filter/collaboration/CollaborationCategories';
import { CollaborationDate } from 'v2/components/filter/collaboration/CollaborationDate';
import { CollaborationSearch } from 'v2/components/filter/collaboration/CollaborationSearch';
import { CollaborationSocial } from 'v2/components/filter/collaboration/CollaborationSocial';
import { CollaborationSort } from 'v2/components/filter/collaboration/CollaborationSort';
import { CollaborationState } from 'v2/components/filter/collaboration/CollaborationState';
import { CollaborationTarget } from 'v2/components/filter/collaboration/CollaborationTarget';
import { Button } from 'v2/components/ui/buttons/Button';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { MarginWrapper } from 'v2/components/wrappers/MarginWrapper/styles';
import { trendingCollaborationsStateOptions } from 'v2/constants/services/collaborations';
import { trendingCollaborationsPageStore } from 'v2/stores/collaborations';

export const Filters = () => {
    const handleReset = () => {
        trendingCollaborationsPageStore.events.resetFilters();
    };

    return (
        <Column noWrap width="100%">
            <Section alignCenter justifyBetween>
                <Section maxWidth="500px">
                    <CollaborationSearch store={trendingCollaborationsPageStore} />
                </Section>
                <Row alignCenter>
                    <MarginWrapper marginRight="24px">
                        <CollaborationSort store={trendingCollaborationsPageStore} />
                    </MarginWrapper>
                    <Button buttonType="text-black" onClick={handleReset}>
                        Reset
                    </Button>
                </Row>
            </Section>

            <Section alignCenter noWrap paddingTop="8px">
                <Row marginRight="8px">
                    <CollaborationState
                        options={trendingCollaborationsStateOptions}
                        store={trendingCollaborationsPageStore}
                    />
                </Row>
                <Row marginRight="8px">
                    <CollaborationCategories store={trendingCollaborationsPageStore} />
                </Row>
                <Row marginRight="8px">
                    <CollaborationDate store={trendingCollaborationsPageStore} />
                </Row>
                <Row marginRight="8px">
                    <CollaborationSocial store={trendingCollaborationsPageStore} />
                </Row>
            </Section>

            <Section alignCenter noWrap paddingTop="8px">
                <Row marginRight="8px">
                    <CollaborationTarget store={trendingCollaborationsPageStore} />
                </Row>
            </Section>

            {/* TODO: Engagement sort */}
        </Column>
    );
};
