import React, { FC } from 'react';
import { YeayIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { textColors } from 'v2/constants/styles/colors';
import { Noop } from 'v2/types/types';
import { CardLayout } from '../CardLayout';
import { Wrapper } from './styles';

interface Props {
    positionIndex: number;
    textIsVisible?: boolean;
    isHovered?: boolean;
    onClick?: Noop;
    onDragEnd?: Noop;
    onDragOver?: Noop;
}

export const DragSpot: FC<Props> = ({ positionIndex, textIsVisible = true, ...props }) => (
    <CardLayout positionIndex={positionIndex}>
        <Wrapper {...props}>
            {textIsVisible ? (
                <Text alignTextCenter isSemibold color={textColors.disabled} size="-2">
                    Drag Here
                </Text>
            ) : (
                <YeayIcon height={31} width={20} />
            )}
        </Wrapper>
    </CardLayout>
);
