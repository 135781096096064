import React, { FC, MouseEvent } from 'react';
import { ReactComponent as CopyIcon } from 'v1/assets/copy_icon.svg';
import greyCopyIcon from 'v1/assets/grey_copy_icon.svg';
import { CustomImg } from 'v1/components/common/imgComponents/CustomImg';
import { MarginWrapper } from 'v1/components/wrappers/grid/MarginWrapper/styles';
import { defaultMongoDBId } from 'v1/constants/defaults/formats';
import { grey25 } from 'v1/constants/styles/colors';
import { message } from 'v1/stores/alerts';
import { Success } from 'v1/types/data';
import { Disabled } from 'v1/types/form';
import { triggerCopy } from 'v1/utils/usefulFunctions';
import { Button, CopyButtonWrapper } from './styles';

export interface CopyButtonProps extends Success {
    subject?: string | null;
    customCopyIcon?: string;
    diameter?: string;
}

export const CopyButton = ({ subject, success, diameter }: CopyButtonProps) => {
    const disabled = subject === defaultMongoDBId || !subject || subject === '-';

    const triggerButtonCopy = (e: MouseEvent<SVGSVGElement>) => {
        if (!disabled) {
            triggerCopy(subject || '');
            message.success(success);
            e.stopPropagation();
        }
    };

    return (
        <CopyButtonWrapper pointer disabled={disabled} height={diameter} width={diameter}>
            <CopyIcon onClick={triggerButtonCopy} />
        </CopyButtonWrapper>
    );
};

interface CopyTextButtonProps extends CopyButtonProps, Disabled {}

export const CopyTextButton: FC<CopyTextButtonProps> = ({
    subject,
    disabled,
    success,
    customCopyIcon,
    diameter,
    children
}) => {
    const disabledButton = subject === defaultMongoDBId || !subject || disabled;

    const triggerButtonCopy = () => {
        if (!disabled) {
            triggerCopy(subject || '');
            message.success(success);
        }
    };
    return (
        <Button background={grey25} disabled={disabledButton} padding="10px 14px 10px 12px" onClick={triggerButtonCopy}>
            <MarginWrapper marginRight="8px">
                <CustomImg height={diameter} src={customCopyIcon || greyCopyIcon} width={diameter} />
            </MarginWrapper>
            {children}
        </Button>
    );
};
