export const columns = [
    {
        title: 'USER',
        dataIndex: 'user',
        key: 'user',
        width: '15%'
    },
    {
        title: 'ADDRESS',
        dataIndex: 'address',
        key: 'address',
        width: '35%'
    },
    {
        title: 'OPTIONS',
        dataIndex: 'options',
        key: 'options',
        width: '30%'
    },
    {
        title: 'SHIPPING STATUS',
        dataIndex: 'status',
        key: 'status',
        width: '20%'
    }
];
