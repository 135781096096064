import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    background-color: ${brandColors.grey};
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
    }

    &:focus-visible {
        outline: 1px solid ${brandColors.black};
    }

    &:disabled {
        background-color: ${grey[50]};
        color: ${grey[400]};
        cursor: not-allowed;
    }
`;
