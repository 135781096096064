import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { MultiSelect } from 'v2/components/ui/selects/MultiSelect';
import { SelectOptionValue } from 'v2/components/ui/selects/types';
import { UserGender, genderFilterOptions } from 'v2/constants/services/users';

export const GenderFilter: FC<UsersFilterProps> = ({ store }) => {
    const { $usersFilters } = store.stores;
    const { addFilters } = store.events;

    const { genders } = useStore($usersFilters);

    const handleChange = (state: SelectOptionValue[]) => {
        addFilters({
            genders: state as UserGender[]
        });
    };

    return (
        <MultiSelect
            wide
            options={genderFilterOptions}
            placement="bottom-start"
            title="Gender"
            values={genders || undefined}
            onChange={handleChange}
        />
    );
};
