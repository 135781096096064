import React, { FC, ReactNode } from 'react';
import { InstagramIcon, TikTokIcon, TwitterIcon, YeayIcon, YeayRedIcon, YoutubeIcon } from 'v2/assets/icons';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { ExternalNetworkKey, NetworksType } from 'v2/constants/services/networks';
import { brandColors } from 'v2/constants/styles/colors';
import { StyledLink } from './styles';

export interface StatusBadgeProps {
    isReview?: boolean;
    networkName?: string;
    userId?: string;
    viewLinkRoute?: string;
    mediaAssets?: YECM.MediaAssetResponse[] | null;
    isVideo?: boolean;
    postUrl?: string;
}

interface IconTextBlockProps {
    icon: ReactNode;
    color: string;
    isReview?: boolean;
}

const IconTextBlock: FC<IconTextBlockProps> = ({ icon, color, isReview }) => (
    <Row alignCenter>
        <Column marginRight="2px">{icon}</Column>

        <Text isSemibold color={color} size="-2">
            {isReview ? 'Review' : 'View'}
        </Text>
    </Row>
);

export const StatusBadge: FC<StatusBadgeProps> = ({
    isReview,
    postUrl,
    networkName = ExternalNetworkKey.Yeay,
    mediaAssets,
    isVideo
}) => {
    const name = networkName.toLocaleLowerCase();

    const shareSocialPost = mediaAssets?.find(item => item.postType !== NetworksType.YeayVideo)?.postUri;
    const remoteId = mediaAssets?.find(item => item.postType === NetworksType.YeayVideo)?.remoteId;
    const linkVideo = `${routePaths.videos}/${remoteId}`;

    const color = isReview ? brandColors.red : brandColors.black;
    const icon = (() => {
        switch (name) {
            case ExternalNetworkKey.Instagram:
                return <InstagramIcon color={color} width={10} />;
            case ExternalNetworkKey.Tiktok:
                return <TikTokIcon color={color} width={10} />;
            case ExternalNetworkKey.Twitter:
                return <TwitterIcon color={color} width={10} />;
            case ExternalNetworkKey.Youtube:
                return <YoutubeIcon color={color} width={12} />;
            case ExternalNetworkKey.Yeay:
            default:
                return isReview ? <YeayRedIcon width={7} /> : <YeayIcon width={7} />;
        }
    })();

    if (linkVideo && isVideo) {
        return (
            <StyledLink href={linkVideo} isReview={isReview}>
                <IconTextBlock color={color} icon={icon} isReview={isReview} />
            </StyledLink>
        );
    }

    if (shareSocialPost || postUrl) {
        return (
            <StyledLink href={shareSocialPost || postUrl} isReview={isReview} rel="noopener noreferrer" target="_blank">
                <IconTextBlock color={color} icon={icon} isReview={isReview} />
            </StyledLink>
        );
    }

    return <IconTextBlock color={color} icon={icon} isReview={isReview} />;
};
