import { CommonVideoStateType, VideoStatusTitles } from 'v2/components/common/VideoStatus/types';
import { MediaAssetState } from 'v2/constants/enum';

export const submissionVideoState: Record<MediaAssetState, CommonVideoStateType> = {
    [MediaAssetState.None]: CommonVideoStateType.None,
    [MediaAssetState.Pending]: CommonVideoStateType.Pending,
    [MediaAssetState.Valid]: CommonVideoStateType.Valid,
    [MediaAssetState.Invalid]: CommonVideoStateType.Invalid
};

export const submissionVideoStateTitles: VideoStatusTitles = {
    [CommonVideoStateType.None]: 'None',
    [CommonVideoStateType.Pending]: 'Pending',
    [CommonVideoStateType.Valid]: 'Valid',
    [CommonVideoStateType.Invalid]: 'Invalid'
};

export const defaultSubmissionsQueryParams: YecmPaths.Collaborations$CollaborationIdMediaAssets.Get.QueryParameters = {
    pageIndex: 0,
    limit: 30
};
