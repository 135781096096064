import styled from 'styled-components';
import { grey12 } from 'v1/constants/styles/colors';
import { cardMargin, containerBorderRadius, descriptionPadding, xs, xss } from 'v1/constants/styles/sizes';

export const CatalogContainerWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    background-color: ${grey12};
    border-radius: ${containerBorderRadius};
    padding-left: ${cardMargin};
    padding-right: ${cardMargin};
    padding-top: ${cardMargin};
    padding-bottom: 48px;

    @media (max-width: ${xs}) {
        border-radius: 0;
        padding-bottom: 110px;
    }

    @media (max-width: ${xss}) {
        padding-left: ${descriptionPadding};
        padding-right: ${descriptionPadding};
        padding-top: ${descriptionPadding};
    }
`;
