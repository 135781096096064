import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DetailsPageLayout } from 'v2/components/layouts/DetailsPageLayout';
import { MainLayout } from 'v2/components/layouts/MainLayout';
import { Tabs } from 'v2/components/ui/Tabs';
import { CollaborationInfo } from 'v2/pages/Collaboration/CollaborationInfo';
import { ContentWrapper } from 'v2/pages/Report/common/styles';
import { collaborationEffects } from 'v2/stores/collaboration';
import { reportVideoStores } from 'v2/stores/report/details/video';
import { Id } from 'v2/types/data';
import { ReportInfo } from './tabs/ReportInfo';

enum ReportVideoPageTab {
    ReportInfo,
    CollaborationInfo
}

const { getCollaborationFx } = collaborationEffects;

export const ReportSingleVideo = () => {
    const { id } = useParams<Partial<Id>>();
    const reportVideoInfo = useStore(reportVideoStores.$reportVideoInfo);

    const [activeTab, setActiveTab] = useState(ReportVideoPageTab.ReportInfo);

    const handleChangeTab = (tab: ReportVideoPageTab) => {
        setActiveTab(tab);
    };

    const collaborationId = reportVideoInfo?.collaborationId || '';

    useEffect(() => {
        if (collaborationId) {
            getCollaborationFx(collaborationId);
        }
    }, [collaborationId]);

    return (
        <MainLayout isHideHeader>
            <DetailsPageLayout>
                <Tabs
                    activeTab={activeTab}
                    tabs={[
                        {
                            id: ReportVideoPageTab.ReportInfo,
                            title: 'REPORT INFO',
                            content: <ReportInfo id={id} />
                        },
                        {
                            id: ReportVideoPageTab.CollaborationInfo,
                            title: 'COLLABORATION INFO',
                            content: (
                                <ContentWrapper>
                                    <CollaborationInfo hideEngagements id={collaborationId} />
                                </ContentWrapper>
                            )
                        }
                    ]}
                    onChange={handleChangeTab}
                />
            </DetailsPageLayout>
        </MainLayout>
    );
};
