import React, { FC } from 'react';
import { NetworkIcon } from 'v2/components/common/NetworkIcon';
import { Text } from 'v2/components/ui/typography/Text';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { NetworksType, networksShortType } from 'v2/constants/services/networks';
import { RewardWrapper } from './styles';

interface Props {
    count?: number;
    type?: number;
}

export const NetworkReward: FC<Props> = ({ count, type }) => (
    <RewardWrapper alignCenter noWrap>
        <NetworkIcon postType={type || 0} />

        <Row marginLeft="6px">
            <Text size="-2">{networksShortType[type as NetworksType]}</Text>
        </Row>

        <Row marginLeft="4px">
            <Text size="-2">${count || 0}</Text>
        </Row>
    </RewardWrapper>
);
