import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerType } from 'v2/components/ui/DataPicker';
import { inactiveUsersEvents, inactiveUsersStores } from 'v2/stores/inactive-users';

export const InactiveUsersDate = () => {
    const { fromUtcDisabled, toUtcDisabled } = useStore(inactiveUsersStores.$inactiveUsersFilters);
    const [dateRange, setDateRange] = useState<DatePickerType>([new Date(), new Date()]);
    const [startDate, endDate] = dateRange as Date[];

    useEffect(() => {
        const dates: [Date, Date] =
            fromUtcDisabled && toUtcDisabled
                ? [new Date(fromUtcDisabled), new Date(toUtcDisabled)]
                : [new Date(), new Date()];

        setDateRange(dates);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromUtcDisabled, toUtcDisabled]);

    const onChangeDate = (date: DatePickerType) => {
        setDateRange(date);
        const [start, end] = date as Date[];

        if (start && end) {
            inactiveUsersEvents.setOverwriteFilters({
                fromUtcDisabled: start.toISOString(),
                toUtcDisabled: end.toISOString()
            });
        }
    };

    return (
        <DatePicker
            endDate={endDate}
            endPlaceholder=" 2020-12-26"
            startDate={startDate}
            startPlaceholder="2020-12-25 -"
            title="Dates"
            onChange={onChangeDate}
        />
    );
};
