import styled, { css } from 'styled-components';
import { black, grey11, white } from 'v1/constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'v1/constants/styles/mixins';
import { transitionTime } from 'v1/constants/styles/others';
import { primaryBorderRadius, tableBorder } from 'v1/constants/styles/sizes';
import { BorderProperties, BorderRadius, Sizes } from 'v1/types/styles';

export interface DropdownSectionProps extends Sizes, BorderRadius, Pick<BorderProperties, 'border'> {
    isOpened?: boolean;
}

export const DropdownSectionWrapper = styled.div<DropdownSectionProps>`
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || 'fit-content'};
    border: ${({ border }) => (border ? border : tableBorder)};
    background-color: ${white};
    transition: background-color ${transitionTime} linear;
    padding: 0 20px;
    ${({ isOpened }) => isOpened && 'padding-bottom: 20px;'};

    &:first-child {
        border-top-left-radius: ${primaryBorderRadius};
        border-top-right-radius: ${primaryBorderRadius};
    }
    &:last-child {
        border-bottom-left-radius: ${primaryBorderRadius};
        border-bottom-right-radius: ${primaryBorderRadius};
    }

    ${({ isOpened }) =>
        !isOpened &&
        css`
            &:hover {
                background-color: ${grey11};
            }
        `};
`;

export const DropdownSectionButton = styled.button<DropdownSectionProps>`
    ${disableDefaultButtonStyleMixin};
    border-radius: inherit;
    border-top-left-radius: inherit;
    width: 100%;
    height: 60px;
    color: ${black};
    background-color: transparent;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;
`;
