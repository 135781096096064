import { createEffect, createEvent, createStore } from 'effector';
import { notify } from 'v2/components/ui/Toasts';
import { yecmAPI } from 'v2/services/yecm';

// Types

type TransactionsParams = Partial<YecmPaths.AdminTransactions.Get.QueryParameters>;

// Constants

export const defaultTransactionsParams: TransactionsParams = {
    pageIndex: 0,
    limit: 7
};

// Events

const setDefaultFilters = createEvent();
const addFilters = createEvent<TransactionsParams>();
const setOverwriteFilters = createEvent<TransactionsParams>();
const resetTransactionsStores = createEvent();

// Effects

const getTransactionsFx = createEffect({
    handler: async (params?: TransactionsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.transactions.getTransactions({
                ...defaultTransactionsParams,
                ...params
            });

            if (isSuccess && data) {
                return data;
            } else {
                throw new Error();
            }
        } catch (error) {
            notify('There was a problem receiving transactions, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $transactions = createStore<YECM.AdminTransactionsResponse | null>(null)
    .on(getTransactionsFx.doneData, (state, response) => {
        if (!response) return;

        return {
            ...(state || {}),
            ...response,
            items: response.pageIndex === 0 ? response.items : [...(state?.items || []), ...(response.items || [])]
        };
    })
    .reset(resetTransactionsStores);

const $transactionsFilters = createStore<TransactionsParams>(defaultTransactionsParams)
    .on(setDefaultFilters, () => defaultTransactionsParams)
    .on(addFilters, (state, newFilters) => ({
        ...(state || defaultTransactionsParams),
        ...newFilters
    }))
    .on(setOverwriteFilters, (state, newFilters) => ({
        ...(state || defaultTransactionsParams),
        ...newFilters,
        pageIndex: 0
    }));

// Exports

export const transactionsEvents = {
    setDefaultFilters,
    addFilters,
    setOverwriteFilters,
    resetTransactionsStores
};

export const transactionsEffects = {
    getTransactionsFx
};

export const transactionsStores = {
    $transactions,
    $transactionsFilters
};
