import React, { FC } from 'react';
import { useBodyScroll } from 'v2/hooks/use-body-scroll';
import { Visibility, ZIndexNumber } from 'v2/types/styles';
import { Noop } from 'v2/types/types';
import { ModalBackground, ModalContentWrapper } from './styles';

export type ModalSize = 'default' | 'large';

export interface ModalWrapperProps extends Visibility, ZIndexNumber {
    onClose?: Noop;
    size?: ModalSize;
}

export const ModalWrapper: FC<ModalWrapperProps> = ({ children, visible, onClose, zIndex, size = 'default' }) => {
    useBodyScroll(visible);

    if (!visible) {
        return null;
    }

    return (
        <>
            <ModalBackground onClick={onClose} />
            <ModalContentWrapper size={size} zIndex={zIndex}>
                {children}
            </ModalContentWrapper>
        </>
    );
};
