import { maxAvailableAge } from 'v2/components/filter/users/AgeFilter/constants';
import { getCompactNumber } from 'v2/utils/formats';

export const getRangeAge = (minAge: number, maxAge: number) => {
    if (maxAge === maxAvailableAge) return '55+';
    return `${minAge}-${maxAge}`;
};

export const getRangeRequirements = (minFollowerCount?: number | null, maxFollowerCount?: number | null) => {
    const min = minFollowerCount ? `${getCompactNumber(minFollowerCount)}` : 0;
    const max = maxFollowerCount ? `${getCompactNumber(maxFollowerCount)}` : '500k+';

    return `${min}-${max}`;
};
