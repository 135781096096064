import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { DoneIcon } from 'v2/assets/icons';
import { VideoStatus } from 'v2/components/common/VideoStatus';
import { Category } from 'v2/components/ui/Category';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { Image } from 'v2/components/ui/Image';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { StartEllipsisProperty } from 'v2/components/ui/PropertyLayout/components/StartEllipsisProperty';
import { Tooltip, TooltipText } from 'v2/components/ui/Tooltip';
import { Video } from 'v2/components/ui/Video';
import { Button } from 'v2/components/ui/buttons/Button';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { RepeatGrid } from 'v2/components/wrappers/GridWrapper';
import { LanguageType, languages } from 'v2/constants/languages';
import { routePaths } from 'v2/constants/routes';
import { VideoCurationState, videoState, videoStateTitles } from 'v2/constants/services/videos';
import { curateVideoModal, videoModal } from 'v2/stores/initialize-modals';
import { getCategoryForOutput } from 'v2/utils/categories';
import { getCompactNumber } from 'v2/utils/formats';
import { getLanguageImage } from 'v2/utils/languages';
import { metrics } from './constants';
import { CardLink, VideoWrapper } from './styles';

interface Props {
    data: YEAY.AdminGetVideoResponse;
}

// TODO report state
// TODO edit category/tags

export const VideoCard: FC<Props> = ({ data }) => {
    const history = useHistory();

    const {
        id,
        username,
        ownerId,
        isTrusted,
        streaming,
        engagementStatistics,
        utcUploaded,
        audioLanguages,
        category,
        categoryDetails,
        hashTags,
        validation
    } = data;

    const language = audioLanguages && audioLanguages.length > 0 ? audioLanguages[0] : '';
    const tags = [
        getCategoryForOutput(categoryDetails, category),
        ...(hashTags
            ? hashTags
                  .slice(1)
                  .filter(hashTag => !!hashTag)
                  .map(hashTag => `#${hashTag}`)
            : [])
    ].filter(hashTag => !!hashTag);
    const curationState = (validation?.yeay?.curationState || VideoCurationState.None) as VideoCurationState;

    const goToUserPage = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push(`${routePaths.users}/${ownerId}`);
    };

    const handleCurate = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (id) {
            curateVideoModal.openModal(id);
        }
    };

    const openVideoFullscreen = () => {
        if (data.id) {
            videoModal.openModal(data.id);
        }
    };

    return (
        <CardLink to={`${routePaths.videos}/${id}`}>
            <Column noWrap width="100%">
                <SectionBorder alignCenter borderBottom justifyBetween padding="8px">
                    <Button
                        noPadding
                        buttonType="text-black"
                        icon={
                            isTrusted ? (
                                <Tooltip overlay={<TooltipText text="Account is trusted" />} placement="top">
                                    <DoneIcon height={14} width={14} />
                                </Tooltip>
                            ) : undefined
                        }
                        iconPosition="right"
                        onClick={goToUserPage}
                    >
                        @{username}
                    </Button>

                    <Row alignCenter justifyEnd noWrap>
                        <VideoStatus status={videoState[curationState]} titles={videoStateTitles} />

                        {/* TODO */}
                        {/* <ReportIcon height={24} width={24} /> */}
                    </Row>
                </SectionBorder>

                <Section justifyCenter padding="6px 0">
                    <VideoWrapper>
                        <Video
                            cover
                            posterSrc={streaming?.details?.screenGrabUrl}
                            videoSrc={streaming?.details?.hlsUrl}
                            onFullscreen={openVideoFullscreen}
                        />
                    </VideoWrapper>
                </Section>

                <SectionBorder alignCenter borderBottom justifyCenter noWrap padding="8px">
                    {metrics.map(item => (
                        <Row key={item.key} marginLeft="3px" marginRight="3px">
                            <IconTextItem icon={item.icon} text={getCompactNumber(engagementStatistics?.[item.key])} />
                        </Row>
                    ))}
                </SectionBorder>

                <SectionBorder alignCenter borderBottom borderTop justifyBetween noWrap paddingRight="8px">
                    <DateProperty date={utcUploaded} direction="row" />

                    {language && (
                        <Row alignCenter noWrap>
                            <Image height="12px" src={getLanguageImage(language)} width="16px" />

                            <Row marginLeft="4px">
                                <Text size="-2">{languages[language as LanguageType]}</Text>
                            </Row>
                        </Row>
                    )}
                </SectionBorder>

                {tags.length > 0 && (
                    <SectionBorder alignCenter borderBottom justifyBetween noWrap padding="6px 8px">
                        <Row noWrap overflowX="auto" paddingRight="8px">
                            {tags.map(tag => (
                                <Row key={tag} marginRight="4px">
                                    <Category text={tag} />
                                </Row>
                            ))}
                        </Row>

                        {/* TODO */}
                        {/* <Button buttonType="text-black" noPadding textSize="small">
                            Edit
                        </Button> */}
                    </SectionBorder>
                )}

                <SectionBorder borderBottom>
                    <RepeatGrid columns={2} width="100%">
                        <SectionBorder borderRight>
                            <StartEllipsisProperty id={id} title="Video ID" />
                        </SectionBorder>

                        <StartEllipsisProperty id={ownerId} title="User ID" />
                    </RepeatGrid>
                </SectionBorder>
            </Column>

            <Section padding="8px 16px">
                <Button wide disabled={curationState !== VideoCurationState.Processing} onClick={handleCurate}>
                    Curate
                </Button>
            </Section>
        </CardLink>
    );
};
