import React, { FC } from 'react';
import { ErrorIcon, PendingWithoutBackgroundIcon, SuccessIcon } from 'v2/assets/icons';
import { Row } from 'v2/components/wrappers/FlexWrapper/styles';
import { green, grey, red } from 'v2/constants/styles/colors';
import { StatusType, reportStatus, reportTableStatus } from './constants';
import { TextWrapper } from './styles';

interface Props {
    status: StatusType;
    isTableReport?: boolean;
}

const Icon = ({ status }: { status: StatusType }) => {
    switch (status) {
        case StatusType.Review:
            return <PendingWithoutBackgroundIcon color={grey[700]} height="12px" width="10px" />;
        case StatusType.Accepted:
            return <SuccessIcon color={green[700]} height="8px" width="10px" />;
        case StatusType.Rejected:
            return <ErrorIcon color={red[300]} height="10px" width="10px" />;
        case StatusType.NotSet:
        default:
            return null;
    }
};

export const ReportStatus: FC<Props> = ({ status, isTableReport }) => (
    <Row alignCenter noWrap>
        {status && <Icon status={status || 0} />}
        <TextWrapper isSemibold size="-2" type={status}>
            {isTableReport ? reportTableStatus[status] : reportStatus[status]}
        </TextWrapper>
    </Row>
);
