import styled from 'styled-components';
import { ClickableWrapper } from 'v1/components/wrappers/ClickableWrapper/styles';
import { transitionTime } from 'v1/constants/styles/others';
import { Active } from 'v1/types/global';

export const SearchButtonWrapper = styled(ClickableWrapper)<Active>`
    opacity: 0.5;
    height: 24px;
    width: 24px;
    margin-right: 5px;
    transition: opacity ${transitionTime};

    :hover {
        opacity: 1;
    }

    ${({ active }) => active && `opacity: 1;`};
`;
