import { useStore } from 'effector-react';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'v2/components/common/Empty';
import { ModalLayout } from 'v2/components/layouts/ModalLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { Text } from 'v2/components/ui/typography/Text';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { ModalWrapper } from 'v2/components/wrappers/ModalWrapper';
import { maximumCreatorsCount } from 'v2/constants/services/trendings';
import { addTrendingCreatorsModal } from 'v2/stores/initialize-modals';
import { trendingCreatorsEffects } from 'v2/stores/trendings/creators';
import { trendingUsersStore } from 'v2/stores/users';
import { getArrayWithPositions } from 'v2/utils/array';
import { CreatorCard } from './CreatorCard';
import { FilterLayout } from './FilterLayout';
import { UsersGrid, Wrapper } from './styles';

const { effects, stores, events } = trendingUsersStore;

const { getUsersFx } = effects;
const { $usersResponse } = stores;
const { resetFilters } = events;

const { deleteCreatorsFromTrendingFx, addCreatorsToTrendingFx } = trendingCreatorsEffects;

interface Props {
    trendingCreators: YEAY.GetTrendingOverridesResponse[];
}

const AddCollaborationModalInner: FC<Props> = ({ trendingCreators }) => {
    const trendingCreatorsIds = trendingCreators.map(item => item.user?.userId || '').filter(item => !!item);

    const [selectedIds, setSelectedIds] = useState<string[]>(trendingCreatorsIds);
    const [deleteUserIds, setDeleteUserIds] = useState<string[]>([]);

    const isLoading = useStore(getUsersFx.pending);
    const usersResponse = useStore($usersResponse);
    const users = usersResponse?.items || [];

    const submitIsDisabled =
        selectedIds.length > maximumCreatorsCount || isEqual(trendingCreatorsIds.sort(), selectedIds.sort());

    const close = () => {
        addTrendingCreatorsModal.closeModal();
    };

    const handleSelect = (userId?: string) => () => {
        if (userId) {
            if (trendingCreatorsIds.includes(userId)) {
                const newDeleteIds = deleteUserIds.includes(userId)
                    ? deleteUserIds.filter(id => id !== userId)
                    : [...deleteUserIds, userId];

                setDeleteUserIds(newDeleteIds);
            }

            if (selectedIds.includes(userId)) {
                setSelectedIds(ids => ids.filter(id => id !== userId));
            } else if (selectedIds.length < maximumCreatorsCount) {
                setSelectedIds(ids => [...ids, userId]);
            }
        }
    };

    const deleteUsers = async () => {
        if (deleteUserIds.length === 0) {
            return;
        }

        const ids = deleteUserIds
            .map(id => trendingCreators.find(item => item.user?.userId === id)?.id || '')
            .filter(id => !!id);

        await deleteCreatorsFromTrendingFx(ids);
    };

    const addUsers = async () => {
        if (selectedIds.length === 0) {
            return;
        }

        const currentPositions =
            trendingCreators
                .filter(item => !!item.user?.userId && !deleteUserIds.includes(item.user?.userId))
                .map(item => item.position) || [];
        const allPositions = getArrayWithPositions(maximumCreatorsCount);

        const newIds = selectedIds.filter(id => !trendingCreatorsIds.includes(id));

        const requestData = newIds.map(id => {
            const newPosition = allPositions.find(position => !currentPositions.includes(position));
            currentPositions.push(newPosition);

            return {
                position: newPosition,
                targetId: id
            };
        });

        await addCreatorsToTrendingFx(requestData);
    };

    const handleSubmit = async () => {
        if (submitIsDisabled) {
            return;
        }

        await deleteUsers();
        await addUsers();

        close();
    };

    useEffect(() => {
        getUsersFx();

        return () => {
            resetFilters();
        };
    }, []);

    return (
        <ModalLayout
            footerExtra={
                <Text>
                    {selectedIds.length}/{maximumCreatorsCount}
                </Text>
            }
            submitDisabled={submitIsDisabled}
            submitText={deleteUserIds.length > 0 ? 'Save' : 'Add'}
            title="Add Creators"
            onCancel={close}
            onSubmit={handleSubmit}
        >
            <FilterLayout isLoading={isLoading}>
                <Wrapper>
                    {isLoading ? (
                        <Section justifyCenter>
                            <Spinner />
                        </Section>
                    ) : (
                        <>
                            {users.length > 0 ? (
                                <UsersGrid>
                                    {users.map(user => (
                                        <CreatorCard
                                            key={user.id}
                                            data={user}
                                            disabled={selectedIds.length >= maximumCreatorsCount}
                                            selected={!!user.id && selectedIds.includes(user.id)}
                                            onClick={handleSelect(user.id)}
                                        />
                                    ))}
                                </UsersGrid>
                            ) : (
                                <Empty yPadding="0" />
                            )}
                        </>
                    )}
                </Wrapper>
            </FilterLayout>
        </ModalLayout>
    );
};

export const AddCreatorsModal: FC<Props> = ({ trendingCreators }) => {
    const { visible } = useStore(addTrendingCreatorsModal.$modal);

    const close = () => {
        addTrendingCreatorsModal.closeModal();
    };

    return (
        <ModalWrapper size="large" visible={visible} onClose={close}>
            <AddCollaborationModalInner trendingCreators={trendingCreators} />
        </ModalWrapper>
    );
};
