import { sample } from 'effector';
import { singleUserStores } from 'v2/stores/single-user';
import { userTransactionsEffects, userTransactionsStores } from 'v2/stores/transactions/user';

sample({
    clock: userTransactionsStores.$userTransactionsFilters,
    source: singleUserStores.$user,
    fn: (source, params) => ({
        remoteUserId: source?.id || '',
        params
    }),
    target: userTransactionsEffects.getUserTransactionsFx
});
