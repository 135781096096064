import { useStore } from 'effector-react';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoadMoreSection } from 'v2/components/common/LoadMoreSection';
import { Table } from 'v2/components/ui/Table';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { routePaths } from 'v2/constants/routes';
import { AppReportTypeState } from 'v2/constants/services/reports';
import { appReportsEffects, appReportsEvents, appReportsStores } from 'v2/stores/reports/app';
import { ContentWrapper } from '../../styles';
import { ReportsYeayAppTabFilterLayout } from './ReportsYeayAppTabFilterLayout';
import { columns } from './constants';
import { prepareReportsForTable } from './utils';

const { getAppReportsFx } = appReportsEffects;
const { addFilters } = appReportsEvents;

export const ReportsYeayAppTab = () => {
    const appReportsResponse = useStore(appReportsStores.$appReportsResponse);
    const isLoading = useStore(getAppReportsFx.pending);
    const hasError = useStore(appReportsStores.$hasError);

    const totalCount = appReportsResponse?.totalCount || 0;
    const hasNext = appReportsResponse.hasNext;

    const history = useHistory();

    const loadMoreReports = () => {
        if (!hasNext) return;

        const newPageIndex = (appReportsResponse?.pageIndex || 0) + 1;

        addFilters({ pageIndex: newPageIndex });
    };

    const tableData = prepareReportsForTable(appReportsResponse?.items || []);

    const handleRowClick = (record: DefaultRecordType) => {
        const type = record.type.props.type;

        const singlePageType = (type: number) => {
            switch (type) {
                case AppReportTypeState.Video:
                default:
                    return 'video';
                case AppReportTypeState.Collab:
                    return 'collaboration';

                case AppReportTypeState.Account:
                    return 'account';
            }
        };

        history.push(`${routePaths.reports}/${singlePageType(type)}/${record.key}`);
    };

    return (
        <ReportsYeayAppTabFilterLayout isLoading={isLoading} totalCount={totalCount}>
            <ContentWrapper noWrap width="100%">
                <Table
                    cellCenter
                    rowPointer
                    columns={columns}
                    data={tableData}
                    emptyMessage="No Reports Found"
                    isLoading={isLoading}
                    onRow={record => ({
                        onClick: () => handleRowClick(record)
                    })}
                />

                {!isLoading && hasNext && (
                    <Section marginTop="16px">
                        <LoadMoreSection disabled={hasError} onClick={loadMoreReports} />
                    </Section>
                )}
            </ContentWrapper>
        </ReportsYeayAppTabFilterLayout>
    );
};
