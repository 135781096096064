import React, { FC } from 'react';
import { UsersFilterProps } from 'v2/components/filter/users/types';
import { SearchInputSelect } from 'v2/components/ui/inputs/SearchInputSelect';
import { useUsersSearch } from './hooks';

export const UsersSearch: FC<UsersFilterProps> = ({ store }) => {
    const {
        searchItems,
        values: { userId, username, mobileNumber, email }
    } = useUsersSearch({ store });

    return (
        <SearchInputSelect defaultSearchText={userId || username || mobileNumber || email || ''} items={searchItems} />
    );
};
