import { createEffect, createEvent, createStore } from 'effector';
import { defaultBrandsParams } from 'v2/constants/services/brands';
import { yecmAPI } from 'v2/services/yecm';

// Types

type BrandsParams = Partial<YECM.QueryOrganizationRequest>;

// Events

const setDefaultFilters = createEvent();
const addFilters = createEvent<BrandsParams>();
const overwriteFilters = createEvent<BrandsParams>();

const resetStores = createEvent();

// Effects

const getBrandsFx = createEffect({
    handler: async (params?: BrandsParams) => {
        try {
            const { isOk, response } = await yecmAPI.brands.brandsQuery({
                ...defaultBrandsParams,
                ...params
            });

            if (!isOk || !response) {
                throw new Error();
            }

            return response;
        } catch {
            return null;
        }
    }
});

// Stores

const $brandsResponse = createStore<YECM.OrganizationsResponse | null>(null)
    .on(getBrandsFx.doneData, (state, payload) => {
        if (!payload) return;

        return {
            ...(state || {}),
            ...payload,
            items: payload.currentPageIndex === 0 ? payload.items : [...(state?.items || []), ...(payload.items || [])]
        };
    })
    .reset(resetStores);

const $brandsFilters = createStore<BrandsParams | null>(null)
    .on(setDefaultFilters, () => defaultBrandsParams)
    .on(addFilters, (state, payload) => ({
        ...(state || defaultBrandsParams),
        ...payload
    }))
    .on(overwriteFilters, (_, payload) => ({
        ...defaultBrandsParams,
        ...payload
    }))
    .reset(resetStores);

// Exports

export const brandsEvents = {
    setDefaultFilters,
    addFilters,
    overwriteFilters,
    resetStores
};

export const brandsEffects = {
    getBrandsFx
};

export const brandsStores = {
    $brandsResponse,
    $brandsFilters
};
