import styled, { css } from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { flexCenter } from 'v2/constants/styles/mixins';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    isHovered?: boolean;
}

export const Wrapper = styled.div<Props>`
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: ${grey[100]};
    border: 1px solid transparent;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${brandColors.grey};
    }

    ${({ isHovered }) =>
        isHovered &&
        css`
            border-color: ${brandColors.black};
        `}
`;
