import React, { ChangeEvent, FC, InputHTMLAttributes, MouseEvent } from 'react';
import { CloseIcon, SearchIcon } from 'v2/assets/icons';
import { IconButton } from 'v2/components/ui/buttons/IconButton';
import { AbsoluteWrapper } from 'v2/components/wrappers/AbsoluteWrapper/styles';
import { IconWrapper } from 'v2/components/wrappers/IconWrapper/styles';
import { RelativeWrapper } from 'v2/components/wrappers/RelativeWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { StyledInput } from './styles';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange' | 'value'> {
    value?: string;
    onChange: (value: string) => void;
}

export const SearchInput: FC<Props> = ({ onChange, value, ...props }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    const handleReset = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onChange('');
    };

    return (
        <RelativeWrapper width="100%">
            <AbsoluteWrapper left="12px" top="9px" zIndex="1">
                <IconWrapper size="16px">
                    <SearchIcon height={16} width={16} />
                </IconWrapper>
            </AbsoluteWrapper>

            <StyledInput type="text" value={value} onChange={handleChange} {...props} />

            {!!value && (
                <AbsoluteWrapper right="8px" top="9px" zIndex="1">
                    <IconButton color={grey[500]} hoverColor={brandColors.black} size="16px" onClick={handleReset}>
                        <CloseIcon />
                    </IconButton>
                </AbsoluteWrapper>
            )}
        </RelativeWrapper>
    );
};
