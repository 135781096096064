import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { VideoCard } from 'v2/components/cards/VideoCard';
import { Empty } from 'v2/components/common/Empty';
import { CardsGridLayout } from 'v2/components/layouts/CardsGridLayout';
import { Spinner } from 'v2/components/loaders/Spinner';
import { AccordionSection } from 'v2/components/ui/AccordionSection';
import { Pagination } from 'v2/components/ui/Pagination';
import { Column, Section, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { userVideosDomain } from 'v2/stores/videos';
import { Id } from 'v2/types/data';
import { getPageIndex } from 'v2/utils/services';
import { PaginationWrapper } from './styles';

const { effects, events, stores } = userVideosDomain;

const { $videosFilters, $videos } = stores;
const { getVideosFx } = effects;
const { addFilters, resetVideos } = events;

export const DropdownVideos: FC<Id> = ({ id }) => {
    const videos = useStore($videos);
    const isLoading = useStore(getVideosFx.pending);
    const totalRecords = videos?.totalRecords || 0;
    const totalPages = videos?.totalPages || 0;

    const filters = useStore($videosFilters);
    const limit = filters.limit;
    const currentPage = (filters.pageIndex || 0) + 1;

    const handlePageChange = (page: number) => {
        addFilters({ creatorId: id, pageIndex: getPageIndex(page) });
    };

    const handleLimitChange = (size: number) => {
        addFilters({ creatorId: id, limit: size });
    };

    useEffect(() => {
        id && getVideosFx({ creatorId: id, pageIndex: currentPage - 1, limit });

        return () => {
            resetVideos();
        };
    }, [id, currentPage, limit]);

    return (
        <AccordionSection title={`User Videos (${totalRecords})`}>
            <Column noWrap width="100%">
                {isLoading && (
                    <Section justifyCenter marginTop="12px">
                        <Spinner />
                    </Section>
                )}

                {videos?.items && videos.items.length > 0 && (
                    <SectionBorder borderTop>
                        <CardsGridLayout>
                            {videos.items.map(item => (
                                <VideoCard key={item.id} data={item} />
                            ))}
                        </CardsGridLayout>

                        <PaginationWrapper>
                            <SectionBorder borderTop padding="0 8px">
                                <Pagination
                                    currentPage={currentPage}
                                    size={limit}
                                    totalPages={totalPages}
                                    onChange={handlePageChange}
                                    onPageSizeChange={handleLimitChange}
                                />
                            </SectionBorder>
                        </PaginationWrapper>
                    </SectionBorder>
                )}

                {!videos && !isLoading && <Empty title="No videos Found" yPadding="100px" />}
            </Column>
        </AccordionSection>
    );
};
