import queryString from 'query-string';
import ycmAxios from './yecm.axios';

export const getCollaborations = (params: YecmPaths.Collaborations.Get.QueryParameters) =>
    ycmAxios<YECM.CollaborationsResponseApiResponse>({
        url: '/collaborations',
        method: 'GET',
        params,
        paramsSerializer: params => queryString.stringify(params)
    });
