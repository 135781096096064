import styled, { css } from 'styled-components';
import { Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

interface Props {
    isHovered?: boolean;
}

export const DragSpot = styled(Section)<Props>`
    width: 100%;
    height: 48px;
    align-items: center;
    background-color: ${grey[100]};
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color ${transitionTime} linear;

    &:hover {
        background-color: ${brandColors.grey};
    }

    ${({ isHovered }) =>
        isHovered &&
        css`
            border-color: ${brandColors.black};
        `}
`;
