import styled from 'styled-components';
import { Span } from 'v1/components/common/typography/Span/styles';
import { pastelPurple, violet } from 'v1/constants/styles/colors';

export const TableDataSpan = styled(Span)`
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
`;

export const Reason = styled.div`
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    color: ${violet};

    padding: 8px 16px;
    background: ${pastelPurple};
    border-radius: 32px;

    margin-right: 4px;
`;

export const TableWrapper = styled.div`
    min-width: 100%;
    overflow-x: auto;
`;
