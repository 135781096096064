import React, { FC } from 'react';
import { ClicksIcon, SharesIcon, ViewsIcon } from 'v2/assets/icons';
import { VideoWrapper } from 'v2/components/cards/VideoCard/styles';
import { VideoStatus } from 'v2/components/common/VideoStatus';
import { Category } from 'v2/components/ui/Category';
import { Hashtag } from 'v2/components/ui/Hashtag';
import { IconTextItem } from 'v2/components/ui/IconTextItem';
import { Image } from 'v2/components/ui/Image';
import { DateProperty } from 'v2/components/ui/PropertyLayout/components/DateProperty';
import { StartEllipsisProperty } from 'v2/components/ui/PropertyLayout/components/StartEllipsisProperty';
import { Video } from 'v2/components/ui/Video';
import { Text } from 'v2/components/ui/typography/Text';
import { Row, SectionBorder } from 'v2/components/wrappers/FlexWrapper/styles';
import { LanguageType, languages } from 'v2/constants/languages';
import { submissionVideoState, submissionVideoStateTitles } from 'v2/constants/services/submissions';
import { videoModal } from 'v2/stores/initialize-modals';
import { getLanguageImage } from 'v2/utils/languages';
import { SubmissionCardWrapper } from './styles';

interface Props {
    data: YECM.GetMediaAssetResponse;
    collaborationInfo: YECM.CollaborationDetailedResponse;
}

export const SubmissionCard: FC<Props> = ({ data, collaborationInfo }) => {
    const {
        creatorProfile,
        mediaAssetState,
        utcCreated,
        hashtags,
        id,
        creatorId,
        mediaAssetThumbnailUrl,
        mediaAssetUri
    } = data;
    const { categoryInfo, spokenLanguage } = collaborationInfo;

    const categoryEmoji = categoryInfo?.categoryEmoji !== null ? categoryInfo?.categoryEmoji : '';
    const categoryInfoTitle = categoryInfo?.categoryId !== null ? categoryInfo?.categoryId : '';

    const openSubmissionFullscreen = () => {
        if (data.remoteId) {
            videoModal.openModal(data.remoteId);
        }
    };

    return (
        <SubmissionCardWrapper>
            <SectionBorder alignCenter borderBottom justifyBetween noWrap padding="8px">
                <Text isSemibold size="-1">
                    {creatorProfile?.userName}
                </Text>

                {mediaAssetState && (
                    <VideoStatus status={submissionVideoState[mediaAssetState]} titles={submissionVideoStateTitles} />
                )}
            </SectionBorder>

            <SectionBorder justifyCenter padding="6px 0">
                <VideoWrapper>
                    <Video
                        cover
                        posterSrc={mediaAssetThumbnailUrl}
                        videoSrc={mediaAssetUri}
                        onFullscreen={openSubmissionFullscreen}
                    />
                </VideoWrapper>
            </SectionBorder>

            {/* TODO add this blocks from back */}
            <SectionBorder borderBottom justifyCenter padding="6px 0">
                <IconTextItem icon={<ViewsIcon />} text={0} />
                <Row marginLeft="24px">
                    <IconTextItem icon={<ClicksIcon />} text={0} />
                </Row>
                <Row marginLeft="24px">
                    <IconTextItem icon={<SharesIcon />} text={0} />
                </Row>
            </SectionBorder>

            <SectionBorder alignCenter borderBottom borderTop justifyBetween noWrap paddingRight="8px">
                <DateProperty date={utcCreated} direction="row" />
                {spokenLanguage && (
                    <Row alignCenter noWrap>
                        <Image height="12px" src={getLanguageImage(spokenLanguage)} width="16px" />

                        <Row marginLeft="4px">
                            <Text size="-2">{languages[spokenLanguage as LanguageType]}</Text>
                        </Row>
                    </Row>
                )}
            </SectionBorder>

            <SectionBorder borderBottom padding="6px 8px">
                {categoryInfo && <Category text={`${categoryEmoji} ${categoryInfoTitle}`} />}
                {hashtags && hashtags.length > 0 && hashtags.map(item => <Hashtag key={item} id={item} text={item} />)}
            </SectionBorder>

            <SectionBorder noWrap>
                <StartEllipsisProperty id={id} title="Video ID" />

                <SectionBorder borderLeft borderTop>
                    <StartEllipsisProperty id={creatorId} title="User ID" />
                </SectionBorder>
            </SectionBorder>
        </SubmissionCardWrapper>
    );
};
