import React, { FC } from 'react';
import { DollarIcon } from 'v2/assets/icons';
import { Badge } from 'v2/components/ui/Badge';
import { Text } from 'v2/components/ui/typography/Text';
import { Column, Row, Section } from 'v2/components/wrappers/FlexWrapper/styles';
import { CollaborationStatusesUi, collaborationStatusTitles } from 'v2/constants/services/collaborations';
import { brandColors } from 'v2/constants/styles/colors';
import { DraggableProps } from 'v2/types/react';
import { Avatar, AvatarsWrapper, CollaborationCardWrapper, PriceBadge, TextWrapperEllipsis } from './styles';

interface Props extends DraggableProps {
    budget?: number | null;
    name?: string | null;
    coverImageUrl?: string | null;
    state?: CollaborationStatusesUi;
    participants?: YEAY.ParticipantInfoDto[] | null;
    participantsEnrolledCount?: number | null;
    participantsEnrolledLimit?: number | null;
    draggable?: boolean;
}

export const TrendingCollaborationCard: FC<Props> = ({
    budget,
    coverImageUrl,
    name,
    state,
    participants,
    participantsEnrolledCount,
    participantsEnrolledLimit,
    ...draggableProps
}) => (
    <CollaborationCardWrapper image={coverImageUrl || ''} {...draggableProps}>
        <Column justifyBetween height="100%">
            <Section alignCenter justifyBetween>
                {state && <Badge status={state}>{collaborationStatusTitles[state]}</Badge>}
            </Section>

            <Column noWrap>
                <Section>
                    <PriceBadge>
                        <DollarIcon color={brandColors.white} height={14} width={6} />
                        <Row marginLeft="6px">
                            <Text color={brandColors.white} size="-2">
                                {budget || 0}
                            </Text>
                        </Row>
                    </PriceBadge>
                </Section>
                <Section marginTop="8px">
                    <TextWrapperEllipsis isSemibold size="-2">
                        {name}
                    </TextWrapperEllipsis>
                </Section>
                <Section alignCenter marginTop="8px">
                    {participants && participants.length > 0 && (
                        <AvatarsWrapper>
                            {participants.slice(0, 3).map(item => (
                                <Avatar
                                    key={item.remoteCreatorId}
                                    hasBorder
                                    size="18px"
                                    src={item.profileImageUrl || ''}
                                />
                            ))}
                        </AvatarsWrapper>
                    )}
                    <Text color={brandColors.white} size="-2">
                        {participantsEnrolledCount || 0}/{participantsEnrolledLimit || 0}
                    </Text>
                </Section>
            </Column>
        </Column>
    </CollaborationCardWrapper>
);
