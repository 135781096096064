import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';
import {
    sortAssociation,
    sortByDate,
    SortValues
} from 'v2/components/filter/collaboration/CollaborationSort/constants';
import { SortButton } from 'v2/components/ui/buttons/SortButton';
import { SortState } from 'v2/components/ui/buttons/SortButton/constants';
import { GenericCollaborationsType } from 'v2/stores/collaborations';

interface Props {
    store: GenericCollaborationsType;
}

export const CollaborationSort: FC<Props> = ({ store }) => {
    const collaborationsFilters = useStore(store.stores.$collaborationsFilters);
    const [sortByDateState, setSortByDateState] = useState(SortState.Desc);

    useEffect(() => {
        if (collaborationsFilters?.sort) {
            setSortByDateState(sortAssociation[collaborationsFilters?.sort as SortValues]);
        } else {
            setSortByDateState(SortState.Desc);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collaborationsFilters?.sort]);

    const getItems = (value: string) => {
        store.events.overwriteFilters({ sort: value });
    };

    const onSortClick = () => {
        if (sortByDateState === SortState.Desc) {
            getItems(sortByDate[SortState.Asc]);
        } else {
            getItems(sortByDate[SortState.Desc]);
        }
    };

    return <SortButton sortState={sortByDateState} onClick={onSortClick} />;
};
