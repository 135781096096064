import { createEffect, createEvent, createStore } from 'effector';
import { isEqual } from 'lodash';
import { notify } from 'v2/components/ui/Toasts';
import { yecmAPI } from 'v2/services/yecm';

// Types

type CampaignManagerReportsParams = Partial<YecmPaths.Reports.Get.QueryParameters>;

// Constants

const defaultCampaignManagerReportsParams: YecmPaths.Reports.Get.QueryParameters = {
    pageIndex: 0,
    limit: 20,
    count: true
};

// Events

const addFilters = createEvent<CampaignManagerReportsParams>();
const setOverwriteFilters = createEvent<CampaignManagerReportsParams>();

const resetCampaignManagerReports = createEvent();
const resetCampaignManagerFilters = createEvent();

// Effects

const getCampaignManagerReportsFx = createEffect({
    handler: async (params?: CampaignManagerReportsParams) => {
        try {
            const { isSuccess, data } = await yecmAPI.reports.getReports({
                ...defaultCampaignManagerReportsParams,
                ...params
            });

            if (!isSuccess || !data) {
                throw new Error();
            }

            return data;
        } catch (error) {
            notify('There was a problem receiving reports, try to refresh the page.', 'error');
            return null;
        }
    }
});

// Stores

const $campaignManagerReportsResponse = createStore<YECM.QueryReportsResponse>({})
    .on(getCampaignManagerReportsFx.doneData, (state, reports) => {
        if (!reports) return;

        return {
            ...(state || {}),
            ...reports,
            items: reports.pageIndex === 0 ? reports.items : [...(state?.items || []), ...(reports.items || [])]
        };
    })
    .reset(resetCampaignManagerReports);

const $campaignManagerReportsFilters = createStore<CampaignManagerReportsParams>(defaultCampaignManagerReportsParams)
    .on(addFilters, (state, reports) => ({
        ...(state || defaultCampaignManagerReportsParams),
        ...reports
    }))
    .on(setOverwriteFilters, (state, reports) => ({
        ...(state || defaultCampaignManagerReportsParams),
        ...reports,
        pageIndex: 0
    }))
    .reset(resetCampaignManagerFilters);

const $hasError = createStore<boolean>(false)
    .on(getCampaignManagerReportsFx.fail, () => true)
    .reset(getCampaignManagerReportsFx.done);

const $resetIsAvailable = $campaignManagerReportsFilters.map(
    filters => !isEqual(filters, defaultCampaignManagerReportsParams)
);

// Exports

export const campaignManagerReportsEvents = {
    resetCampaignManagerReports,
    addFilters,
    setOverwriteFilters,
    resetCampaignManagerFilters
};

export const campaignManagerReportsEffects = {
    getCampaignManagerReportsFx
};

export const campaignManagerReportsStores = {
    $campaignManagerReportsResponse,
    $hasError,
    $campaignManagerReportsFilters,
    $resetIsAvailable
};
