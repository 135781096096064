import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { brandColors, grey } from 'v2/constants/styles/colors';
import { transitionTime } from 'v2/constants/styles/special';

export const GridWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    background-color: ${brandColors.white};
`;

export const BlockWrapper = styled.div`
    padding-left: 8px;
`;

export const LinkUserInfo = styled(Link)`
    padding: 4px;
    border-radius: 4px;
    display: flex;
    width: 100%;
    transition: background-color ${transitionTime} linear, text-decoration ${transitionTime} linear;

    &:hover {
        background-color: ${grey[100]};
        text-decoration: underline;
        text-decoration-color: ${brandColors.black};
    }
`;
