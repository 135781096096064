import React, { ReactNode } from 'react';
import { BookmarkIcon, ClicksIcon, CommentIcon, HeartIcon, SharesIcon, ViewsIcon } from 'v2/assets/icons';

interface MetricsItem {
    icon: ReactNode;
    key: keyof YEAY.VideoDetailsEngagementDeltas;
}

export const metrics: MetricsItem[] = [
    {
        icon: <ViewsIcon />,
        key: 'views'
    },
    {
        icon: <SharesIcon />,
        key: 'shares'
    },
    {
        icon: <HeartIcon />,
        key: 'likes'
    },
    {
        icon: <BookmarkIcon />,
        key: 'saves'
    },
    {
        icon: <CommentIcon />,
        key: 'commentCount'
    },
    {
        icon: <ClicksIcon />,
        key: 'clicks'
    }
];
